import React, { memo, useEffect, useState } from "react";
import { Tooltip } from "react-tooltip";
import { Col, Row, } from "react-bootstrap";
import ReactSpeedometer from "react-d3-speedometer";
import { CircularProgressbar } from "react-circular-progressbar";
import { FaSquareMinus, FaSquarePlus } from "react-icons/fa6";
import { BsFillInfoCircleFill, BsInfoCircleFill } from "react-icons/bs";
import GradientSVG from "../ui/GradientSVG";

export default memo(function SeoScorePreview(props) {
  const { contentScore, show, article } = props;

  const word_score = (contentScore["Word count"]["SEO Score"] * 100) / contentScore["Word count"]["Max SEO score available"];
  const related_keywords_score = (contentScore["Related keywords"]["SEO Score"] * 100) / contentScore["Related keywords"]["Max SEO score available"];

  const [relatedKeywords, setRelatedKeywords] = useState([]);
  const [missingKeywords, setMissingKeywords] = useState([]);
  const [seoAnalysisData, setSeoAnalysisData] = useState([]);
  const [analysisChartData, setAnalysisChartData] = useState([]);
  const [readabilityData, setReadabilityData] = useState(null);
  const [isReadabilityShow, setIsReadabilityShow] = useState(false);
  const [wordCount, setWordCount] = useState([]);
  const [totalTags, setTotalTags] = useState([]);

  useEffect(() => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(article, 'text/html');

    const h1Count = doc.querySelectorAll('h1').length;
    const h2Count = doc.querySelectorAll('h2').length;
    const h3Count = doc.querySelectorAll('h3').length;

    const bodyText = doc.body.textContent;
    setWordCount(bodyText.trim().split(/\s+/).length);
    setTotalTags({ h1: h1Count, h2: h2Count, h3: h3Count });
  }, [article])

  useEffect(() => {
    if (contentScore && contentScore["Related keywords"] && contentScore["Related keywords"]["Related keywords found"]) {
      setRelatedKeywords([...contentScore["Related keywords"]["Related keywords found"].split(",").filter((item) => item)]);
    }

    if (contentScore && contentScore["Related keywords"] && contentScore["Related keywords"]["Related keywords not found"]) {
      setMissingKeywords([...contentScore["Related keywords"]["Related keywords not found"].split(",").filter((item) => item)]);
    }

    /* if (contentScore && contentScore["Readability Overview"] && Object.keys(contentScore["Readability Overview"])?.length > 0) {
      setReadabilityData(contentScore["Readability Overview"]);
    } */

    /* if (contentScore["SEO Analysis"] && contentScore["SEO Analysis"].detailed_analysis) {
      const seoAnalysisArray = [];

      if (contentScore["SEO Analysis"].detailed_analysis.addllys_secret_sauce) {
        seoAnalysisArray.push({
          label: "Addllys Secret Sauce", value: contentScore["SEO Analysis"].detailed_analysis.addllys_secret_sauce.score || 0,
          sortLabel: "AS", backgroundColor: "rgba(255, 99, 132, 0.4)", borderColor: "rgba(255, 99, 132, 1)",
        });
      }

      if (contentScore["SEO Analysis"].detailed_analysis.content_structure) {
        seoAnalysisArray.push({
          label: "Content Structure", value: contentScore["SEO Analysis"].detailed_analysis.content_structure.score || 0,
          sortLabel: "CS", backgroundColor: "rgba(54, 162, 235, 0.4)", borderColor: "rgba(54, 162, 235, 1)",
        });
      }

      if (contentScore["SEO Analysis"].detailed_analysis.images) {
        seoAnalysisArray.push({
          label: "Images", value: contentScore["SEO Analysis"].detailed_analysis.images.score || 0,
          sortLabel: "IM", backgroundColor: "rgba(153, 102, 255, 0.4)", borderColor: "rgba(153, 102, 255, 1)",
        });
      }

      if (contentScore["SEO Analysis"].detailed_analysis.keywords) {
        seoAnalysisArray.push({
          label: "Keywords", value: contentScore["SEO Analysis"].detailed_analysis.keywords.score || 0,
          sortLabel: "KW", backgroundColor: "rgba(255, 159, 64, 0.4)", borderColor: "rgba(255, 159, 64,1)",
        });
      }

      seoAnalysisArray.sort((a, b) => a.value - b.value);
      setSeoAnalysisData([...seoAnalysisArray]);

      const tempArray = [...seoAnalysisArray.map((a) => a.value)];
      for (let i = 0; i < tempArray.length - 1; i++) {
        tempArray[i + 1] += tempArray[i];
      };
      setAnalysisChartData([0, ...tempArray, 100]);
    } */
  }, [contentScore]);

  return (
    <div className="innerBlock">
      <GradientSVG />
      <div className="content-score">
        {!show && (
          <h3 className="text-start">
            SEO score
            <BsInfoCircleFill
              className="infoIconSvg" data-tooltip-id="seo-score"
              data-tooltip-content="Valid only for articles generated in “English”" data-tooltip-place="bottom"
            />
            <Tooltip id="seo-score" style={{ borderRadius: "6px", fontSize: "11px", padding: "4px 12px", zIndex: 9999 }} />
          </h3>
        )}
        <div className="countsGraph">
          <div className="wordCount">
            <h4 className="">Heading and Word Count</h4>
            <div className="heading-summary mt-2 d-flex align-items-center  justify-content-center">
              <div className="d-grid">
                <p>{totalTags.h1}</p>
                <h6 className="fw-bolder">H1</h6>
              </div>
              <div className="d-grid">
                <p>{totalTags.h2}</p>
                <h6 className="fw-semibold" style={{ color: '#1C2133' }}>H2</h6>
              </div>
              <div className="d-grid">
                <p>{totalTags.h3}</p>
                <h6 style={{ color: '#1C2133' }}>H3</h6>
              </div>
              <div className="d-grid">
                {/* <p className="fw-bolder">{contentScore["Word count"]["Word count total"]}</p> */}
                <p className="fw-bolder">{wordCount}</p>
                <h6 className="text-wrap-none word-count" style={{ color: '#2E3343' }}>Word Count</h6>
              </div>
            </div>

            <div className="progress-bars">
              <Row className="mt-3 justify-content-center" >
                <Col xs={6}>
                  <div className="progress-bar-container position-relative">
                    <CircularProgressbar
                      strokeWidth={5}
                      value={word_score}
                      styles={{
                        path: { stroke: `url(#${"progressive-gradient"})`, height: "100%" },
                        trail: {}
                      }}
                    />
                    <div className="position-absolute top-50 start-50 translate-middle text-center w-100">
                      <h3 className="mb-0">{Math.round(word_score)}%</h3>
                      <p>Word Score</p>
                    </div>
                  </div>
                </Col>
                <Col xs={6}>
                  <div className="progress-bar-container position-relative">
                    <CircularProgressbar
                      strokeWidth={5}
                      value={related_keywords_score}
                      width="90px"
                      styles={{
                        path: { stroke: `url(#${"progressive-gradient"})`, height: "100%" },
                        trail: {}
                      }}
                    />
                    <div className="position-absolute top-50 start-50 translate-middle text-center w-100">
                      <h3 className="mb-0">{Math.round(related_keywords_score)}%</h3>
                      <p>Related Keywords</p>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>

          {readabilityData && Object.keys(readabilityData).length > 0 && (
            <div className="wordCount mt-3">
              <div className="d-flex justify-content-between">
                <h4 className="">Readability Analysis</h4>
                {isReadabilityShow ? (
                  <FaSquareMinus className="cursor-pointer" style={{ width: "24px", height: "24px", fill: "#0039ff" }}
                    onClick={() => setIsReadabilityShow(false)}
                  />
                ) : (
                  <FaSquarePlus className="cursor-pointer" style={{ width: "24px", height: "24px", fill: "#0039ff" }}
                    onClick={() => setIsReadabilityShow(true)}
                  />
                )}
              </div>
              {isReadabilityShow && (
                <div className="heading-summary mt-3">
                  {[
                    { label: "Coleman Liau", value: readabilityData["COLEMAN_LIAU"] },
                    { label: "Complex Words", value: readabilityData["COMPLEXWORDS"] },
                    { label: "Flesch Kincaid", value: readabilityData["FLESCH_KINCAID"] },
                    { label: "Flesch Reading", value: readabilityData["FLESCH_READING"] },
                    { label: "Gunning Fog", value: readabilityData["GUNNING_FOG"] },
                    { label: "Smog", value: readabilityData["SMOG"] },
                    { label: "Smog Index", value: readabilityData["SMOG_INDEX"] },
                    { label: "Syllables", value: readabilityData["SYLLABLES"] },
                  ].map((item, index) => (
                    <div className="d-flex justify-content-between mb-2" key={index}>
                      <p className="w-25">{item.value}</p> <h6 className="fw-bolder justify-content-start w-75 ms-3">{item.label}</h6>
                    </div>
                  ))}
                </div>
              )}
            </div>
          )}

          {analysisChartData && analysisChartData.length > 0 && (
            <div className="wordCount mt-3">
              <h4 className="">SEO Analysis</h4>
              <ReactSpeedometer
                width={280} height={200} maxValue={100} value={contentScore["SEO Analysis"].seo_score}
                customSegmentStops={[...analysisChartData]} needleColor={"#000000"} textColor={"#000000"}
                segmentColors={[...seoAnalysisData.map((item) => item.backgroundColor), "#E0E0E0"]}
                currentValueText={`${contentScore["SEO Analysis"].seo_score.toString()} / 100`}
                ringWidth={47} needleTransitionDuration={3333} needleTransition="easeElastic"
                customSegmentLabels={[
                  { text: seoAnalysisData[0]?.value ? `${seoAnalysisData[0]?.value}%` : "", position: "INSIDE" },
                  { text: seoAnalysisData[1] ? `${seoAnalysisData[1]?.value}%` : "", position: "INSIDE" },
                  { text: seoAnalysisData[2] ? `${seoAnalysisData[2]?.value}%` : "", position: "INSIDE" },
                  { text: seoAnalysisData[3] ? `${seoAnalysisData[3]?.value}%` : "", position: "INSIDE" },
                  { text: `${100 - contentScore["SEO Analysis"].seo_score}%`, position: "INSIDE" },
                ]}
              />
              <div className="">
                {seoAnalysisData && seoAnalysisData.length > 0 && seoAnalysisData.map((item, index) => (
                  <div className="d-flex align-items-center mt-3" key={index}>
                    <div
                      className="d-flex align-items-center justify-content-center"
                      style={{ padding: "4px 8px", background: item.backgroundColor, border: `1px solid ${item.borderColor}`, borderRadius: "6px", width: "40px" }}
                    >
                      {item.sortLabel}
                    </div>
                    <div className="fs-5 ms-3">{item.label}</div>
                    <div className="text-primary fs-5 ms-auto">{item.value}%</div>
                  </div>
                ))}
              </div>
            </div>
          )}

          <div className="position-relative d-flex">
            <h4 className="mt-3 ">Related keywords {""} <BsFillInfoCircleFill id="my-anchor-element-id" data-tooltip-place='bottom' className="infoIconSvg" /></h4>
            <Tooltip anchorSelect="#my-anchor-element-id" className=" rounded-2" style={{ backgroundColor: '#1C2133' }} >
              <div className="d-flex flex-column justify-content-center  align-items-start  align-self-stretch  keyWordToopTip">
                <div className="kWords d-flex align-items-center "><span style={{ backgroundColor: "#08F7AD" }} className=" " />Keywords included</div>
                <div className="kWords d-flex align-items-center"><span style={{ backgroundColor: "#FF6666" }} className=" " />Keywords not included</div>
                {/* <div><LuRectangleHorizontal fill="#FF6666" className="fs-4 rounded-2" />Keywords not included</div> */}
              </div>
            </Tooltip>
          </div>
          <div className="tags mt-2 pb-0">
            {relatedKeywords?.map((it, index) => (
              <div className="tag status-success m-1 border-0 rounded" key={`${it}-${index}`}>
                {it}
              </div>
            ))}
            {missingKeywords?.map((it, index) => (
              <div className="tag status-danger m-1 border-0 rounded" key={`${it}-${index}`}>
                {it}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
})
