import React, { useEffect, useMemo, useState } from "react";
import Swal from "sweetalert";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-hot-toast";
import { IoIosRefresh } from "react-icons/io";
import { AiOutlineEdit, AiOutlineDelete } from "react-icons/ai";
import { getHistory } from "../utility/apiService";
import { setGeneratedFullArticle, setHistoryData, setLoader, setTopResultsStep } from "../store/reducer";
import CustomDataTable from "./ui/DataTable";
import TableLoader from "./ui/TableLoader";
import { articleHistoryIcons } from "../Assets/Icons";
import { SortArrow, sorting, tableDateFormatter } from "../utility/common";
import classNames from "classnames";
import DropdownMenu from "./ui/Menu";
import { MenuItem } from "@mui/material";
import { invokeAxiosGet, invokeAxiosPost } from "../utility/invokeAxiosFunction";

const UserArticleHistory = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const userRes = useSelector(({ userRes }) => userRes);
  const historyData = useSelector(({ historyData }) => historyData);
  const [historyBlogType, setHistoryBlogType] = useState("");
  const [tableLoader, setTableLoader] = useState(false);
  const [sortField, setSortField] = useState({ column: {}, direction: "" });
  const [page, setPage] = useState(1);

  useEffect(() => {
    if (location && location.pathname && location.pathname.indexOf("/pdf-to-article") !== -1) {
      setHistoryBlogType("PDF");
    }
  }, [location]);

  const handleChangePageLoadData = (limit, offset) => {
    if (offset >= 0) {
      dispatch(getHistory(userRes?.id, limit, offset, "artical_history", historyBlogType));
    }
  };

  const historyDelete = (id) => {
    Swal({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning", dangerMode: true,
      buttons: { cancel: "Cancel", confirm: "Yes, delete it!" },
    }).then((isConfirmed) => {
      if (isConfirmed) {
        dispatch(setLoader(true));
        invokeAxiosPost(`/article-history/get-or-save/articleHistoryDeleteById?id=${id}`).then((response) => {
          dispatch(getHistory(userRes?.id, 10, 0, "artical_history", historyBlogType));
          dispatch(setLoader(false));
          Swal("Deleted!", "Your record has been deleted.", "success");
        }).catch((error) => {
          dispatch(setLoader(false));
          toast.error("Something went wrong.", { id: "Toast-01" });
          console.log(error);
        });
      }
    });
  };

  const handleGetArticleById = (id) => {
    dispatch(setLoader(true));
    invokeAxiosGet(`/article-history/get-or-save/articleHistoryGetById?id=${id}`).then((res) => {
      const item = res.data && res.data.data ? res.data.data : {};
      dispatch(setGeneratedFullArticle({
        ...item,
        FAQschema: item?.FAQschema?.replace(/(\r\n|\n|\r)/gm, ""),
        linkedIn_post: item?.linkedIn_post, facebook_post: item?.facebook_post,
        twitter_post: item?.twitter_post, instagram_post: item?.instagram_post, instagram_reels: item?.instagram_reels,
        SEO_score_long_article: item?.SEO_score_long_article ? JSON.parse(item?.SEO_score_long_article).data : "",
        popular_hashtags: item?.popular_hashtags ? JSON.parse(item.popular_hashtags) : [],
        article_html_chart: item?.article_html_chart ? JSON.parse(item?.article_html_chart)?.data : null,
        articleId: item?.id,
      }));
      dispatch(setTopResultsStep(2));
      dispatch(setLoader(false));
      const scrollWrapper = document.getElementById('scroll-wrapper');
      if (scrollWrapper) { scrollWrapper.scrollTo({ top: 0, behavior: 'smooth' }); }

      if (item.pdf_info && item.pdf_info != "") {
        dispatch(setTopResultsStep(item.status === "completed" ? 3 : 2));
        navigate(`/pdf-to-article/${item?.id}`);
      } else {
        navigate(`/1-click-blog/${item?.id}`);
      }
    }).catch((e) => {
      console.log(e, "eee");
      dispatch(setLoader(false));
    });
  }

  const columns = useMemo(() => {
    const tempColumns = [
      {
        id: "title",
        name: <div className="d-flex">Detail {SortArrow(sortField, "title")}</div>,
        selector: (row) => (
          <div className="d-flex align-items-center gap-3" onClick={() => handleGetArticleById(row?.id)}>
            <div className={classNames('tableValues', { showFullDes: false })}>
              <h6>{row.topic || "-"}</h6>
            </div>
          </div>
        ),
        sortable: true,
        sortField: "topic",
      },
      {
        id: "AI Type",
        name: <div className="d-flex">AI Type {SortArrow(sortField, "AI Type")}</div>,
        selector: (row) => (
          <div className="d-flex align-items-center gap-3" onClick={() => handleGetArticleById(row?.id)}>
            <div className={classNames('tableValues', { showFullDes: false })}>
              <h6>{row.AI_type || "-"}</h6>
            </div>
          </div>
        ),
        sortable: true,
        sortField: "AI Type",
      },
      {
        id: "status",
        name: <div className="d-flex ">Status {SortArrow(sortField, "status")}</div>,
        selector: (row) => (
          <div className="d-flex justify-content-center align-items-center" onClick={() => handleGetArticleById(row?.id)}>
            <div className="dotStatus">
              <span
                className={`d-block`}
                style={{
                  width: "8px", height: "8px", borderRadius: "50%",
                  backgroundColor: row.status === "completed" ? "#84CC16" : row.status === "pending" ? "#E7E721" : row.status === "processing" ? "#F59E0B" : "#EF4444",
                }}
              />
            </div>
            {row.status === "completed" ? "Done" : row.status === "pending" ? "Processing ..." : row.status === "processing" ? "In Progress" : "Error"}
          </div>
        ),
        sortable: true,
        sortField: "status",
      },
      {
        id: "created_at",
        name: <div className="d-flex">Created On {SortArrow(sortField, "created_at")}</div>,
        selector: (row) => (
          <div className="d-flex align-items-center gap-3" onClick={() => handleGetArticleById(row?.id)}>
            <div className={classNames('tableValues', { showFullDes: false })}>
              <h6>{tableDateFormatter(row?.created_at)}</h6>
            </div>
          </div>
        ),
        sortable: true,
        sortField: "created_at",
      },

      {
        id: "sort",
        name: 'Action',
        selector: (row) => {
          return (
            <div className="popup">
              <DropdownMenu>
                {row?.status === "pending" ? (
                  <IoIosRefresh
                    size={18}
                    onClick={() => dispatch(getHistory(userRes?.id, 10, 0, "artical_history", historyBlogType))}
                  />
                ) : (
                  <MenuItem
                    eventKey="3"
                    onClick={() => historyDelete(row?.id)}
                  >
                    <AiOutlineDelete /> Delete
                  </MenuItem>
                ) ? (
                  <React.Fragment>
                    <MenuItem
                      eventKey="1"
                      onClick={() => handleGetArticleById(row?.id)}
                    >
                      <AiOutlineEdit /> Edit
                    </MenuItem>
                    <MenuItem
                      eventKey="3"
                      onClick={() => historyDelete(row?.id)}
                    >
                      <AiOutlineDelete /> Delete
                    </MenuItem>
                  </React.Fragment>
                ) : null}
              </DropdownMenu>
            </div>
          );
        },
      },
    ];

    if (userRes && userRes.role && userRes.role === "OrgAdmin") {
      tempColumns.splice(1, 0, {
        id: "created_by",
        name: <div className="d-flex">Created By {SortArrow(sortField, "created_by")}</div>,
        selector: (row) => (
          <div className="user-info-column">
            {row?.profileUrl && row?.profileUrl !== "" ? (
              <img src={row?.profileUrl} alt="user" className="userIcon" />
            ) : (
              <div className="avatar">{row?.first_name?.[0]}</div>
            )}
            <span>{row?.first_name + " " + row?.last_name || ""}</span>
          </div>
        ),
        sortable: true,
        sortField: "first_name",
      });
    }
    return tempColumns;
  }, [sortField]);

  const handleSort = (column, sortDirection) => {
    setPage(1);
    setSortField(pre => ({ ...pre, column: column, direction: sortDirection }));
    const newData = sorting(historyData, column?.sortField, sortDirection);
    dispatch(setHistoryData(newData));
  };

  return (
    <div className=" d-flex gap-2 flex-column tableHeading">
      <div className=" d-flex justify-content-between mb-4">
        <span className=" ">Recent History</span>
        <Link to="/history" className="">
          View All
        </Link>
      </div>
      <div className="historyTableBlock mt-2">
        <CustomDataTable
          progressPending={tableLoader} progressComponent={<TableLoader limit={10} />}
          columns={columns}
          totalCount={historyData.length || 0} pagination={true} data={historyData}
          itemsPerPage={10} selectableRows={false} noDataComponent={<NoDataFound />}
          onSelectedRowsChange={() => { }}
          setPage={setPage} page={page} onSort={handleSort}
          handleTableRowClick={(row) => { handleGetArticleById(row?.id) }} clearSelectedRows={() => { }}
        />
      </div>
    </div>
  );
};

const NoDataFound = () => {
  return (
    <div className="withoutData  d-flex justify-content-center align-items-center">
      <div className="genrateNewBlock d-flex justify-content-center flex-column align-items-center">
        <div className="icon d-flex justify-content-center flex-column align-items-center">
          <img src={articleHistoryIcons.add} alt="add"></img>
        </div>
        <p>History is not available yet!</p>
      </div>
    </div>
  );
};
export default UserArticleHistory;
