import Editor from '@monaco-editor/react';

const CodeEditor = (props) => {
  const { setDemoEdit, faqSchema } = props;

  const handleEditorChange = (value) => {
    try {
      const parsedSchema = JSON.parse(value);
      const faqHTML = parsedSchema?.mainEntity.map((faq) => {
        const question = faq.name;
        const answer = faq.acceptedAnswer.text;
        return `
          <h2>${question}</h2>
          <p>${answer}</p>
        `;
      });
      setDemoEdit(faqHTML.join(''));
    } catch (error) {
      console.error('Invalid FAQ Schema JSON');
    }
  };

  return (
    <Editor
      theme="vs-dark"
      height="100%"
      minHeight="100vh"
      className="editorMinHeight"
      defaultLanguage="json"
      value={faqSchema}
      options={{
        formatOnPaste: true,
        formatOnType: true,
      }}
      onChange={handleEditorChange}
    />
  );
};

export default CodeEditor;
