import React, { useMemo, useState } from "react";
import classNames from "classnames";
import Heading from "../Components/ui/Title";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import CustomDataTable from "../Components/ui/DataTable";
import HistoryFilters from "../Components/History/HistoryFilters";
import withHistoryHandler from "../Hoc/HistoryHoc";
import TableLoader from "../Components/ui/TableLoader";
import { IoEyeOutline } from "react-icons/io5";
import { RiInboxUnarchiveFill } from "react-icons/ri";
import { articleHistoryIcons, dashboardIcon, dataTableIcons } from "../Assets/Icons";
import { TfiTrash } from "react-icons/tfi";
import { SortArrow, tableArticleStatusHandle, tableDateFormatter } from "../utility/common";
import ArticlePreview from "../Components/commonComponents/ArticlePreview";
import MenuItem from '@mui/material/MenuItem';
import DropdownMenu from "../Components/ui/Menu";
import { ReactSVG } from "react-svg";

const History = (props) => {
  const {
    handlePinArticle, historyData, textWrap, setTextWrap, type, setType, handleNavigateArticleOrPostWriter, setSearchFilter,
    loading, filters, setFilters, selectedRows, setSelectedRows, getArticleAndPostHistoryData, totalCount, sortField,
    setPage, limit, page, handleSort, handleDelete, handleArchive, handleRestore, handleRestoreAll, handleDeleteAll, userRes, getHistoryData,
    clearRow, isFetching, searchFilter
  } = props;

  const [article, setArticle] = useState({ id: '', type: '' });
  const [showOffset, setShowOffset] = useState(false);

  const handleOpenArticle = (row) => {
    setArticle(pre => ({ ...pre, id: row?.id, type: row?.type }));
    setShowOffset(true);
  }

  const columns = useMemo(() => {
    const tempColumns = [
      {
        id: "title",
        name: <div className="d-flex">Title {SortArrow(sortField, "title")}</div>,
        selector: (row) => (
          <div className=" d-flex align-items-center gap-2" onClick={() => handleTableRowClick(row)}>
            <div className={classNames("tableValues", { showFullDes: textWrap })}>
              <OverlayTrigger placement="bottom" flip overlay={<Tooltip id="tooltip">{row.topic}</Tooltip>}>
                <h6>{row.topic || "-"}</h6>
              </OverlayTrigger>
              <p>{row.keyword}</p>
            </div>
            <OverlayTrigger placement="bottom" flip overlay={<Tooltip style={{ zIndex: 9999 }} id="tooltip">
              {row.is_pinned ? "Remove from Starred" : "Add to Starred"}
            </Tooltip>}>
              <img className={classNames({ 'hover-star': type === 'all' || type === 'archive' })} onClick={(e) => { e.stopPropagation(); handlePinArticle(row.type, row.id, row?.is_pinned) }}
                src={row.is_pinned ? dashboardIcon.starFill : dashboardIcon.star}
                alt="star" loading="lazy" />
            </OverlayTrigger>
          </div>
        ),
        sortField: "topic",
        sortable: true,
      },
      {
        id: "type",
        name: <div className="d-flex">Type {SortArrow(sortField, "type")}</div>,
        selector: (row) => <div style={{ whiteSpace: "nowrap" }} onClick={() => handleTableRowClick(row)}>{row?.display_type}</div>,
        sortable: true,
        sortField: "display_type",
      },
      {
        id: "created_at",
        name: <div className="d-flex">Created On {SortArrow(sortField, "created_at")}</div>,
        selector: (row) => <div onClick={() => handleTableRowClick(row)}>{tableDateFormatter(row?.created_at)}</div>,
        sortable: true,
        sortField: "created_at",
      },
      {
        id: "AI_type",
        name: <div className="d-flex"> AI Type {SortArrow(sortField, "AI_type")}</div>,
        selector: (row) => row.AI_type,
        sortable: true,
        sortField: "AI_type",
      },
      {
        id: "Status",
        name: <div className="d-flex recentArticleHistoryStatus">Status {SortArrow(sortField, "Status")}</div>,
        selector: (row) => tableArticleStatusHandle(row, handleTableRowClick),
        sortable: true,
        sortField: "status",
      },
      {
        id: "sort",
        name: <div className="w-auto">Action</div>,
        width: '60px',
        selector: (row) => {
          return (
            <div className="popup">
              {type === "archive" ? (
                <DropdownMenu>
                  <MenuItem onClick={() => handleRestore(row?.id, row?.type, "archive")}><RiInboxUnarchiveFill /> Restore</MenuItem>
                  <MenuItem onClick={() => handleDelete(row?.id, row?.type, "delete")}> <TfiTrash /> Delete</MenuItem>
                </DropdownMenu>
              ) : (
                <DropdownMenu>
                  {row.status !== "pending" && row.status !== "notAcceptable" && (
                    <MenuItem onClick={() => handleOpenArticle(row)}><IoEyeOutline /> Preview article</MenuItem>
                  )}
                  {row.status !== "notAcceptable" && (
                    <MenuItem onClick={() => handleNavigateArticleOrPostWriter(row.id, row.type)}><ReactSVG src={dataTableIcons.pencil} /> Edit</MenuItem>
                  )}
                  <MenuItem onClick={() => handleDelete(row?.id, row?.type, "archive")}><RiInboxUnarchiveFill /> Archive</MenuItem>
                </DropdownMenu>
              )}
            </div>
          );
        },
      },
    ];

    if (userRes && userRes.role && userRes.role === "OrgAdmin") {
      tempColumns.splice(1, 0, {
        id: "created_by",
        name: <div className="d-flex">Created By {SortArrow(sortField, "created_by")}</div>,
        selector: (row) => (
          <div className="user-info-column">
            {row?.profileUrl && row?.profileUrl !== "" ? (
              <img src={row?.profileUrl} alt="user" className="userIcon" />
            ) : (
              <div className="avatar">{row?.username?.[0]}</div>
            )}
            <span>{row?.username || ""}</span>
          </div>
        ),
        sortable: true,
        sortField: "username",
      });
    }

    return tempColumns;
  }, [sortField, textWrap, handlePinArticle, type, handleRestore, handleDelete, handleNavigateArticleOrPostWriter]);

  const handleTableRowClick = (row) => {
    if (row.status === "completed") {
      handleOpenArticle(row);
    } else if (row.status === "notAcceptable") {
    } else {
      handleNavigateArticleOrPostWriter(row.id, row?.type);
    }
  };

  return (
    <div className="d-flex  gap-2  flex-column">
      <div className="breadcrumbsBlock pt-4">
        <Heading className="fw-bolder mainHeading" size="3">History</Heading>
        <p>View and manage all your drafts generated by Addlly.ai</p>
      </div>
      <div className="filters">
        <HistoryFilters
          type={type} setType={setType} textWrap={textWrap} setSearchFilter={setSearchFilter}
          setTextWrap={setTextWrap} setFilters={setFilters} filters={filters} selectedRows={selectedRows}
          getArticleAndPostHistoryData={getArticleAndPostHistoryData} setSelectedRows={setSelectedRows}
          setPage={setPage} handleArchive={handleArchive} handleRestoreAll={handleRestoreAll}
          handleDeleteAll={handleDeleteAll} isFetching={isFetching}
        />
      </div>
      <div className="historyTableBlock">
        <CustomDataTable clearSelectedRows={clearRow} progressPending={loading || isFetching}
          progressComponent={<TableLoader limit={limit} />}
          columns={columns} totalCount={totalCount} pagination={true}
          data={historyData} itemsPerPage={limit} selectableRows={true}
          noDataComponent={<NoDataFound type={type} searchFilter={searchFilter} />} onSelectedRowsChange={setSelectedRows}
          setPage={setPage} page={page} sortServer={false} onSort={handleSort}
          handleTableRowClick={handleTableRowClick}
        />
      </div>
      <ArticlePreview
        userRes={userRes} articleId={article.id} articleType={article.type} show={showOffset}
        setShow={setShowOffset} getHistoryData={getHistoryData}
        handleNavigateArticleOrPostWriter={handleNavigateArticleOrPostWriter}
      />
    </div>
  );
};
export default withHistoryHandler(History);

const NoDataFound = ({ type, searchFilter }) => {
  return (
    <div className="withoutData  d-flex justify-content-center align-items-center">
      <div className="genrateNewBlock d-flex justify-content-center flex-column align-items-center">
        {!searchFilter ? (
          <>
            <div className="icon d-flex justify-content-center flex-column align-items-center">
              <img src={articleHistoryIcons.add} alt="add"></img>
            </div>
            {type === "starred" ? (
              <p>Articles you pinned will be shown here</p>
            ) : type === "archive" ? (
              <p>Articles you archive will be shown here</p>
            ) : (
              <p>Articles you created will be shown here</p>
            )}
          </>
        ) : (
          <span>Oops... Search not found.</span>
        )}
      </div>
    </div>
  );
};
