import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  resetSEOFLow, setFinalContent, setGoogleSuggestKeyword, setHeadline,
  setKeywordTopic, setLoader, setNoOfClick, setOutlineRes, setSelectedGoogleWebsite,
  setSelectedTopic, setSeoGenerateTopic, setSeoOptBlogStep, setSeoOptBlogTopic, setSeoOptimizedSets
} from "../store/reducer";

import classNames from "classnames";
import Heading from "../Components/ui/Title";
import FirstStepSeoOptBlog from "../Components/SeoOptimizedBlog/FirstStepSeoOptBlog";
import SecondStepSeoOptBlog from "../Components/SeoOptimizedBlog/SecondStepSeoOptBlog";
import ThirdStepSeoOptBlog from "../Components/SeoOptimizedBlog/ThirdStepSeoOptBlog";
import FourthStepSeoOptBlog from "../Components/SeoOptimizedBlog/FourthStepSeoOptBlog";
import FifthStepSeoOptBlog from "../Components/SeoOptimizedBlog/FifthStepSeoOptBlog";
import SixthStepSeoOptBlog from "../Components/SeoOptimizedBlog/SixthStepSeoOptBlog";
import SecondStepSeoOptBlogType from "../Components/SeoOptimizedBlog/SecondStepSeoOptBlogType";
import SeoOptimizedBlogHistory from "../Components/SeoOptimizedBlog/SeoOptimizedBlogHistory";
import SeoOptimizedBlogHead from "../Components/SeoOptimizedBlog/SeoOptimizedBlogHead";
import { invokeAxiosGet } from "../utility/invokeAxiosFunction";

const SeoOptBlog = () => {
  const seoOptBlogStep = useSelector(({ seoOptBlogStep }) => seoOptBlogStep);
  const seoOptBlogTopic = useSelector(({ seoOptBlogTopic }) => seoOptBlogTopic);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id: articleId } = useParams();

  useEffect(() => {
    if (!articleId) {
      if (seoOptBlogTopic && !seoOptBlogTopic?.isDefaultSet) {
        dispatch(resetSEOFLow());
        dispatch(setSeoOptBlogStep(1));
      } else {
        dispatch(setSeoOptBlogTopic({
          selectedCard: seoOptBlogTopic.selectedCard,
          generateLink: seoOptBlogTopic.generateLink
        }));
      }
    } else {
      handleGetArticleById(articleId, false);
    }
  }, [articleId]);

  const handleGetArticleById = useCallback((id, isSetStep = false) => {
    dispatch(setLoader(true));
    invokeAxiosGet(`/article-history/get-or-save/articleHistoryGetById?id=${id}&tableName=artical_long_flow`).then((res) => {
      dispatch(setLoader(false));
      dispatch(resetSEOFLow());
      const item = res.data && res.data.data ? res.data.data : {};
      item.current_step += 1;
      if (item && item.current_step && item.current_step >= 3) {
        dispatch(setSeoGenerateTopic(item?.topic));
        dispatch(setSeoOptBlogTopic({
          Topics: "",
          data: item.search_response ? JSON.parse(item.search_response) : [],
          articleId: item.id,
          geoLocation: item?.geoLocation ? item.geoLocation : "sg",
          blog_type: item?.blog_type ? item.blog_type : "Topic",
          topic_desc: item?.topic_desc ? item.topic_desc : "",
          keyword: item?.keyword ? item.keyword : "",
          stepDone: item?.current_step ? item.current_step : 3,
          generateLink: item?.generate_links,
          pdf_link: item?.pdf_link ? item.pdf_link : "",
          lan: item?.lan ? item.lan : "English",
          aiType: item?.AI_type ? item.AI_type : "",
          toneOfVoice: item?.toneOfVoice ? item.toneOfVoice : "",
          is_check_reviewer: item?.is_check_reviewer ? item.is_check_reviewer : 0,
        }));
        if (item.selectedGoogleWebsite) {
          dispatch(setSelectedGoogleWebsite(JSON.parse(item.selectedGoogleWebsite)));
        } else {
          dispatch(setSelectedGoogleWebsite([]));
        }
      }

      if (item && item.current_step && item.current_step >= 4) {
        if (item.generated_keywords && typeof JSON.parse(item.generated_keywords) !== typeof undefined) {
          dispatch(setKeywordTopic(JSON.parse(item.generated_keywords)));
        }
        dispatch(setSelectedTopic(item?.topic));
        if (item.selectedGoogleWebsite) {
          dispatch(setSelectedGoogleWebsite(JSON.parse(item.selectedGoogleWebsite)));
        } else {
          dispatch(setSelectedGoogleWebsite([]));
        }
      }

      if (item && item.current_step && item.current_step >= 5) {
        dispatch(setGoogleSuggestKeyword(item.keyword));
        dispatch(setSeoOptimizedSets({
          seoOptimizedData: JSON.parse(item.setsOfSeoOptimized),
          summaryDescription: item.top_summary
        }));
      }

      if (item && item.current_step && item.current_step >= 6) {
        dispatch(setSeoOptimizedSets({
          seoOptimizedData: JSON.parse(item.setsOfSeoOptimized),
          summaryDescription: item.top_summary,
          headline: JSON.parse(item.selected_seoOptimized_set)?.headline,
          keyword: JSON.parse(item.selected_seoOptimized_set)?.keyword
        }));
        if (item.headline_sets && typeof JSON.parse(item.headline_sets) !== typeof undefined) {
          dispatch(setHeadline(JSON.parse(item.headline_sets)));
          dispatch(setOutlineRes(JSON.parse(item.headline_sets)));
        }
      }

      if (item && item.current_step && item.current_step === 7) {
        dispatch(setFinalContent({
          article: item.article_html, article_html: item.article_html, meta_title: item.meta_title,
          meta_dec: item.meta_dec, faq_regenerate_left: item.faq_regenerate_left,
          SEO_score_response: item?.SEO_score_response ? JSON.parse(item?.SEO_score_response).data : "",
          FAQschema: item?.FAQschema || "", FAQHTML: item?.FAQHTML || "",
          linkedIn_post: item?.linkedIn_post, linkedIn_post_img: item?.linkedIn_post_img,
          facebook_post: item?.facebook_post, facebook_post_img: item?.facebook_post_img,
          twitter_post: item?.twitter_post, twitter_post_img: item?.twitter_post_img,
          instagram_post: item?.instagram_post, instagram_post_img: item?.instagram_post_img,
          instagram_reels: item?.instagram_reels, googleAdCopy: item?.googleAdCopy,
          allSelectedOutline: item?.selected_headline_set ? JSON.parse(item?.selected_headline_set) : [],
          optimizedArticle: item?.optimized_article ? item.optimized_article : "",
          article_html_chart: item?.article_html_chart ? JSON.parse(item?.article_html_chart)?.data : null,
          toneOfVoice: item?.toneOfVoice ? item.toneOfVoice : "", h2_images: item?.h2_images ? item.h2_images : "",
          logs: item?.logs ? item.logs : "", isTyping: false, isTypingDone: true,
          popular_hashtags: item?.popular_hashtags ? JSON.parse(item?.popular_hashtags) : [],
        }));
      }

      if (item && item.keyword_regenerate_left >= 0) {
        dispatch(setNoOfClick(item.keyword_regenerate_left));
      }

      if (isSetStep) {
        dispatch(setSeoOptBlogStep(item?.current_step ? item.current_step : 3));
        if (isSetStep && isSetStep === "refundRequests") {
          navigate(`/blog-co-pilot/${item?.id}?tab=refundRequests`);
        } else {
          navigate(`/blog-co-pilot/${item?.id}`);
        }
      }
      const scrollWrapper = document.getElementById('scroll-wrapper');
      if (scrollWrapper) { scrollWrapper.scrollTo({ top: 0, behavior: 'smooth' }); }
    }).catch((e) => {
      console.log(e, "eee");
      dispatch(setLoader(false));
    });
  }, [dispatch, navigate])

  const steps = [
    <FirstStepSeoOptBlog />,
    <SecondStepSeoOptBlogType selectedCard={seoOptBlogTopic?.selectedCard} />,
    <SecondStepSeoOptBlog />,
    <ThirdStepSeoOptBlog />,
    <FourthStepSeoOptBlog />,
    <FifthStepSeoOptBlog />,
    <SixthStepSeoOptBlog handleGetArticleById={() => handleGetArticleById(articleId, false)} />,
  ];

  const stepsClassList = [
    "position-relative border-radius-12 bg-white AIWriterBlog blog-writer-content",
    "border-radius-12 bg-white AIWriterBlog maingenrateBlock p-0 m-0",
    "border-radius-12 bg-white AIWriterBlog overflow-hidden",
    "border-radius-12 bg-white AIWriterBlog overflow-hidden",
    "border-radius-12 bg-white AIWriterBlog overflow-hidden",
    "border-radius-12 bg-white AIWriterBlog overflow-hidden",
  ];

  return (
    <React.Fragment>
      {seoOptBlogStep !== 7 ? (
        <div className="oneClickHead d-flex flex-column gap-3 pt-4">
          <div className="heading">
            <Heading className="fw-bolder mainHeading" size="3">SEO Blog Co-Pilot</Heading>
            <p>Custom create in-depth, SEO-friendly blogs with geolocation targeted topics, keyword data, and personalized writing styles in minutes.</p>
          </div>
        </div>
      ) : null}
      <div className={classNames({ "stepWraper": seoOptBlogStep !== 7 })}>
        {seoOptBlogStep === 7 ? (
          <React.Fragment>
            {steps[seoOptBlogStep - 1]}
          </React.Fragment>
        ) : (
          <div className={stepsClassList[seoOptBlogStep - 1]}>
            <SeoOptimizedBlogHead />
            {steps[seoOptBlogStep - 1]}
          </div>
        )}
      </div>
      {seoOptBlogStep === 1 ? (
        <div className=" d-flex gap-2 flex-column tableHeading mt-5">
          <div className="mt-2">
            <SeoOptimizedBlogHistory handleGetArticleById={handleGetArticleById} />
          </div>
        </div>
      ) : null}
    </React.Fragment>
  );
};

export default SeoOptBlog;
