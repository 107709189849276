import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa6";
import { setSocialMediaPostStep } from "../../store/reducer";

const SocialMediaPostHeader = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const socialMediaPostStep = useSelector(({ socialMediaPostStep }) => socialMediaPostStep);
  const socialMediaPostInfo = useSelector(({ socialMediaPostInfo }) => socialMediaPostInfo);
  const isShowLinks = socialMediaPostInfo && socialMediaPostInfo.selectedCard && socialMediaPostInfo.selectedCard !== "topic" ? false : true;
  const [isMobileView, setIsMobileView] = useState(false);
  const [isMobileHead, setIsMobileHead] = useState(false);
  const stepArr = [
    { "step": 1, "label": "Select Media" },
    { "step": 2, "label": "Describe Post" },
    { "step": 3, "label": "Select Links" },
    { "step": 4, "label": "Post Generation" },
    { "step": 5, "label": "Hashtags" },
    { "step": 6, "label": "Review & Enhance" },
    { "step": 7, "label": "Preview / Share" }
  ];

  useEffect(() => {
    const handleResize = () => {
      setIsMobileHead(window.innerWidth <= 1178);
    };

    setTimeout(() => {
      handleResize();
    }, 500);

    window.addEventListener('resize', handleResize);
    window.addEventListener('load', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('load', handleResize);
    };
  }, []);

  useEffect(() => {
    function mobileViewUpdate() {
      var viewportWidth = window.innerWidth;
      if (viewportWidth <= 770) {
        setIsMobileView(true);
      } else {
        setIsMobileView(false);
      }
    }
  }, []);

  const handleOnChangeStep = (no) => {
    if (no >= 1 && no <= 7) {
      if (socialMediaPostInfo?.stepDone && no <= socialMediaPostInfo.stepDone) {
        // if (no === 2) {
        //   if (socialMediaPostInfo?.insert_link) {
        //     dispatch(setSocialMediaStepsInfo({ ...socialMediaPostInfo, selectedCard: "link" }));
        //   } else if (socialMediaPostInfo?.pdf_link) {
        //     dispatch(setSocialMediaStepsInfo({ ...socialMediaPostInfo, selectedCard: "pdf" }));
        //   } else {
        //     dispatch(setSocialMediaStepsInfo({ ...socialMediaPostInfo, selectedCard: "topic" }));
        //   }
        // }
        dispatch(setSocialMediaPostStep(no));
      }
    }
  }

  return (
    <div className="AIWriterBlogHead SocialMediaHeader">
      <div className="AiWriterBlogHeadTop justify-content-center AIWriterStepper">
        {isMobileHead ? (
          <ul>
            <li
              className={`${socialMediaPostStep === stepArr[socialMediaPostStep - 1].step ? "" : ""} ${stepArr[socialMediaPostStep - 1].step < socialMediaPostStep ? "complete" : ""} ${stepArr[socialMediaPostStep - 1].step <= socialMediaPostInfo?.stepDone ? "is-clickable" : ""}`}
              onClick={() => handleOnChangeStep(stepArr[socialMediaPostStep - 1].step - 1)}
            >
              <span className="d-flex justify-content-center align-items-center">
                <FaAngleLeft />
              </span>
              <p>Previous</p>
            </li>
            <li
              className={`${socialMediaPostStep === stepArr[socialMediaPostStep - 1].step ? "active" : ""} ${stepArr[socialMediaPostStep - 1].step < socialMediaPostStep ? "complete" : ""} ${stepArr[socialMediaPostStep - 1].step <= socialMediaPostInfo?.stepDone ? "is-clickable" : ""}`}
            >
              <span className="d-flex justify-content-center align-items-center">
                {!isShowLinks && stepArr[socialMediaPostStep - 1].step > 3 ? `0${stepArr[socialMediaPostStep - 1].step - 1}` : `0${stepArr[socialMediaPostStep - 1].step}`}
              </span>
              <p>{stepArr[socialMediaPostStep - 1].label}</p>
            </li>
            <li
              className={`${socialMediaPostStep === stepArr[socialMediaPostStep - 1].step ? "" : ""} ${stepArr[socialMediaPostStep - 1].step < socialMediaPostStep ? "complete" : ""} ${stepArr[socialMediaPostStep - 1].step <= socialMediaPostInfo?.stepDone ? "is-clickable" : ""}`}
              onClick={() => handleOnChangeStep(stepArr[socialMediaPostStep - 1].step + 1)}
            >
              <span className="d-flex justify-content-center align-items-center">
                <FaAngleRight />
              </span>
              <p className={``}>Next</p>
            </li>
          </ul>
        ) : (
          <ul>
            {stepArr && stepArr.map((step, index) => (
              (!isShowLinks && step.step === 3) ? null : (
                <li
                  className={`${socialMediaPostStep === step.step ? "active" : ""} ${step.step < socialMediaPostStep ? "complete" : ""} ${step.step <= socialMediaPostInfo?.stepDone ? "is-clickable" : ""}`}
                  onClick={() => handleOnChangeStep(step.step)} onKeyDown={() => handleOnChangeStep(step.step)} key={index}
                >
                  <span className="d-flex justify-content-center align-items-center">
                    {!isShowLinks && step.step > 3 ? `0${step.step - 1}` : `0${step.step}`}
                  </span>
                  <p className={``}>{step.label}</p>
                </li>
              )
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default SocialMediaPostHeader;