import React, { useEffect, useRef, useState } from "react";
import * as Yup from "yup";
import Select from "react-select";
import { yupResolver } from "@hookform/resolvers/yup";
import { FileUploader } from "react-drag-drop-files";
import { Controller, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-hot-toast";
import { Col, Form, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { BiSolidErrorCircle } from "react-icons/bi";
import { BsArrowLeft, BsArrowRight, BsCloudUploadFill, BsInfoCircleFill, BsStars } from "react-icons/bs";
import { setLoader, setNewsletterWriterInfo, setNewsletterWriterStep, setUserRes } from "../../store/reducer";
import { invokeAxiosPost } from "../../utility/invokeAxiosFunction";
import { handleCustomSelectOption, supportedLanguageList, trimedInputValue } from "../../utility/hepler";
import { uploadPdfFileOnS3 } from "../../utility/uploadPdfFileOnS3";
import { AiOutlineFilePdf } from "react-icons/ai";

const validationSchema = Yup.object().shape({
  topic: Yup.string().required("Write a brief description is required").min(2, "Write a brief description must be at least 2 characters")
    .test('no-white-space', 'Write a brief description should not contain white spaces', (value) => !/\s{2,}/.test(value)),
  url1: Yup.string().url("URL 1 is not valid url.").required("URL 1 is required").matches(/^(?!\s)(?!.*\s{2})[^\s](.*[^\s])?$/, "Url1 should not contain multiple consecutive white spaces"),
  url2: Yup.string().url("URL 2 is not valid url.").required("URL 2 is required").matches(/^(?!\s)(?!.*\s{2})[^\s](.*[^\s])?$/, "Url2 should not contain multiple consecutive white spaces"),
  url3: Yup.string().url("URL 3 is not valid url.").required("URL 3 is required").matches(/^(?!\s)(?!.*\s{2})[^\s](.*[^\s])?$/, "Url3 should not contain multiple consecutive white spaces"),
});

const productShowcaseTypeOptions = [
  { label: "Insert Own Content", value: "Content" },
  { label: "Insert Website Link", value: "Link" },
  { label: "Upload your PDF", value: "PDF" },
];

const SecondStepNewsletters = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id: articleId } = useParams();
  const fileInputRef = useRef(null);

  const userRes = useSelector(({ userRes }) => userRes);
  const newsletterWriterInfo = useSelector(({ newsletterWriterInfo }) => newsletterWriterInfo);

  const [isDisabledInput, setIsDisabledInput] = useState(articleId ? true : false);
  const [currentLan, setCurrentLan] = useState("English");
  const [isProductShowcase, setIsProductShowcase] = useState(false);
  const [uploadFileDragging, setUploadFileDragging] = useState(null);
  const [uploadFileError, setUploadFileError] = useState(null);
  const [uploadedFile, setUploadedFile] = useState(null);

  const { register, handleSubmit, formState: { errors }, setValue, clearErrors, control, watch, reset } = useForm({
    defaultValues: {},
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    setIsDisabledInput(articleId ? true : false);
    if (articleId && newsletterWriterInfo?.isProductShowcase) {
      setIsProductShowcase(newsletterWriterInfo.isProductShowcase);
    }

    if (articleId && articleId !== "") {
      reset({
        topic: newsletterWriterInfo?.topic || "",
        cta: newsletterWriterInfo?.cta || "",
        url1: newsletterWriterInfo?.url1 || "",
        url2: newsletterWriterInfo?.url2 || "",
        url3: newsletterWriterInfo?.url3 || "",
      })
      if (newsletterWriterInfo?.isProductShowcase) {
        setValue("type", newsletterWriterInfo?.productShowcaseInfo?.type);
      }
      if (newsletterWriterInfo?.isProductShowcase && newsletterWriterInfo?.productShowcaseInfo && newsletterWriterInfo?.productShowcaseInfo.content) {
        setValue("content", newsletterWriterInfo?.productShowcaseInfo?.content);
      }
      if (newsletterWriterInfo?.isProductShowcase && newsletterWriterInfo?.productShowcaseInfo && newsletterWriterInfo?.productShowcaseInfo.link) {
        setValue("link", newsletterWriterInfo?.productShowcaseInfo?.link);
      }
    }
  }, [articleId])

  const onFormSubmit = async (value) => {
    const data = trimedInputValue(value)
    try {
      if (Number(userRes.credits) < (userRes?.deductCredits?.["NewsLetters"] || 3)) {
        toast.error("Sorry, you don't have enough credits to perform this action.", { id: "Toast-01" });
        return false;
      }

      if (articleId && articleId !== "") {
        dispatch(setNewsletterWriterStep(3));
        return false;
      }

      dispatch(setLoader(true));
      const payload = {
        ...data, language: currentLan, userId: userRes.id,
        isProductShowcase: isProductShowcase ? 1 : 0,
        template: newsletterWriterInfo?.template || "",
      };

      const response = await invokeAxiosPost("/news-letters/get-or-generate/createHtmlTextForNewsletters", payload);
      dispatch(setLoader(false));
      if (response && response.success === 1 && response.data && response.data.content) {
        dispatch(setNewsletterWriterStep(3));
        dispatch(setNewsletterWriterInfo({ ...newsletterWriterInfo, ...data, ...response.data, stepDone: 3 }));
        dispatch(setUserRes({ ...userRes, credits: userRes.credits - (userRes?.deductCredits?.["NewsLetters"] || 3) }));
        navigate(`/newsletters/${response.data?.id}`);
        const scrollWrapper = document.getElementById('scroll-wrapper');
        if (scrollWrapper) { scrollWrapper.scrollTo({ top: 0, behavior: 'smooth' }); }
      } else {
        toast.error("Newsletter content not generated, Please try with different topic.", { id: "Toast-01" });
      }
    } catch (error) {
      toast.error("Something went wrong, Please try again later.", { id: "Toast-01" });
      dispatch(setLoader(false));
      console.log('Error : ', error);
    }
  };

  const handleOnSelectPdfFile = async (files) => {
    if (articleId && articleId !== "") {
      return false;
    }

    const file = files[0];
    setUploadedFile(files);
    const MAX_FILE_SIZE = 6144; // 6 MB
    const fileSizeKiloBytes = file.size / 1024;
    if (fileSizeKiloBytes > MAX_FILE_SIZE) {
      toast.error("File size exceeds limit. Please upload a file no larger than 6 MB.", { id: "Toast-01" });
      if (fileInputRef.current) { fileInputRef.current.value = null; }
      setValue("link", "");
      return false;
    }

    const extension = file.name.split('.').pop().toLowerCase();
    if (extension !== 'pdf') {
      toast.error("Uploaded file is not a PDF, Please upload a valid PDF file.", { id: "Toast-01" });
      if (fileInputRef.current) { fileInputRef.current.value = null; }
      setValue("link", "");
      return false;
    }

    if (file.type !== 'application/pdf') {
      toast.error("Uploaded file is not a PDF, Please upload a valid PDF file.", { id: "Toast-01" });
      if (fileInputRef.current) { fileInputRef.current.value = null; }
      setValue("link", "");
      return false;
    }

    dispatch(setLoader(true));
    const filePath = await uploadPdfFileOnS3(file, "pressRelease");
    dispatch(setLoader(false));

    if (filePath && filePath !== "") {
      clearErrors("link");
      setValue("link", filePath);
    } else {
      toast.error("File not uploaded successfully, please try again.", { id: "Toast-01" });
      setValue("link", "");
    }
  }

  const FileUploaderCustomUi = (
    <div className={` position-relative ${uploadFileDragging ? "active" : ""}`}>
      <BsCloudUploadFill className="uploadIcon" />
      <h4 className="fw-normal form-label file-upload-header">
        <strong>Choose a file</strong> or drag it here
      </h4>
      <p className="m-0 file-upload-pera">
        Supported formats: PDF; <br /> maximum file size: 6MB.
      </p>
      {uploadFileError && uploadFileError !== "" ? (
        <React.Fragment>
          <hr className="w-100 mt-4" />
          <h5 className="fw-normal text-danger d-flex align-items-center">
            <BiSolidErrorCircle className="fs-4 me-2" /> {uploadFileError}
          </h5>
        </React.Fragment>
      ) : uploadedFile ? (
        <h5 className="fw-normal text-primary d-flex align-items-center justify-content-center text-center">
          {uploadedFile && uploadedFile[0].name ? uploadedFile[0].name : ""}
        </h5>
      ) : null}
    </div>
  );

  return (
    <form onSubmit={handleSubmit(onFormSubmit)}>
      <div className="selectBlock d-flex flex-wrap gap-3 align-items-center justify-content-between mx-2 mx-md-auto">
        <div className="">
          <b>Enter newsletter details</b>
        </div>
        <div className="nxtGenBtn d-flex flex-wrap gap-3 align-items-center align-items-center gap-2">
          <button
            type="button" className="d-flex align-items-center gap-2 bg-transparent border-0 selectBbutton"
            onClick={() => {
              dispatch(setNewsletterWriterStep(1));
              // dispatch(setNewsletterWriterInfo({})); navigate(`/newsletters`);
              const scrollWrapper = document.getElementById('scroll-wrapper');
              if (scrollWrapper) { scrollWrapper.scrollTo({ top: 0, behavior: 'smooth' }); }
            }}
          >
            <BsArrowLeft className="" /> Previous
          </button>
          <span style={{ opacity: 0.1 }}>|</span>
          <button type="submit" className="d-flex align-items-center gap-2 bg-transparent border-0 selectBbutton"
            disabled={!watch("topic") || !watch("url1") || !watch("url2") || !watch("url3")}
          >
            Generate Newsletter <BsArrowRight className="" />
          </button>
        </div>
      </div>
      <Row className="genrateFields pb-0">
        <Col sm="12" className="summeryBlock">
          <div className="form-group fields mb-4">
            <label>
              What do you want to write about Newsletter? Write a brief description.
              <span className="required-astrick fs-6 ms-1">*</span>
            </label>
            <textarea
              name="topic" type="text" {...register("topic")} rows="3" disabled={isDisabledInput}
              className={`addllyForm-control w-100 ${errors.topic ? "is-invalid" : ""}`} style={{ borderRadius: "5px" }}
              placeholder="Example: My company has reached the Q4 financial goals. I want to let my employees knows about it."
            />
            <div className="invalid-feedback">{errors.topic?.message}</div>
          </div>
        </Col>
        <Col sm="12" md="12">
          <div className="form-group fields mb-4">
            <label>Add Call to Action</label>
            <div className="inputField">
              <input
                name="cta" type="text" placeholder="Example: Read More" {...register("cta")}
                className={`addllyForm-control w-100 ${errors.cta ? "is-invalid" : ""}`}
                disabled={isDisabledInput}
              />
              <div className="invalid-feedback">{errors.cta?.message}</div>
            </div>
          </div>
        </Col>
        {[1, 2, 3].map((no) => (
          <Col sm="12" md="4" key={no}>
            <div className="form-group fields mb-4">
              <label>Insert URL {no} <span className="required-astrick fs-6 ms-1">*</span></label>
              <div className="inputField">
                <input
                  name={`url${no}`} type="text" {...register(`url${no}`)} placeholder="Example: https://www.addlly.ai"
                  className={`addllyForm-control w-100 ${errors[`url${no}`] ? "is-invalid" : ""}`} disabled={isDisabledInput}
                />
                <div className="invalid-feedback">{errors[`url${no}`]?.message}</div>
              </div>
            </div>
          </Col>
        ))}
        <Col sm="12" md="4">
          <div className="form-group mb-4">
            <label>Choose a Language <span className="required-astrick fs-6 ms-1">*</span></label>
            <Select
              className={`custom-select ${errors.language ? "is-invalid" : ""}`}
              classNamePrefix="custom-select" placeholder="Select Language"
              options={supportedLanguageList} isClearable={false} isSearchable={false}
              value={supportedLanguageList.find(c => c.value === currentLan) || ""}
              onChange={(e) => setCurrentLan(e.value)} isDisabled={isDisabledInput}
              getOptionLabel={handleCustomSelectOption}
            />
          </div>
        </Col>
        <hr className="grayLine"></hr>
        <Col sm="12" md="4">
          <div className="form-group">
            <label>Include Product Showcase? <span className="required-astrick fs-6 ms-1">*</span></label>
            <ul className="selectLang" style={{ height: "30px" }}>
              {[{ label: "Yes", value: true }, { label: "No", value: false }].map((element, index) => (
                <Controller
                  control={control} name="lan" {...register("lan")} key={index}
                  render={({ field: { onChange, value, name, ref } }) => (
                    <Form.Check
                      type={"radio"} id={`radio-${element.label}`} className="radioType difRadio cursor-pointer me-2"
                      label={<div className="cursor-pointer ms-1">{element.label}</div>} disabled={isDisabledInput}
                      name="post" value={element.value} checked={element.value === isProductShowcase}
                      onChange={(e) => { setIsProductShowcase(element.value); if (!element.value) { setValue("type", "") }; }}
                    />
                  )}
                />
              ))}
            </ul>
          </div>
        </Col>
        {isProductShowcase && (
          <Col sm="12" md="8" className="chooseModel">
            <div className="form-group fields">
              <label>Product Showcase Type</label>
              <Controller
                control={control} name="type" {...register("type")}
                render={({ field: { onChange, value, name, ref } }) => (
                  <Select
                    className={`custom-select ${errors.type ? "is-invalid" : ""}`}
                    classNamePrefix="custom-select" placeholder="Select Type"
                    options={productShowcaseTypeOptions} isClearable={false} inputRef={ref}
                    value={productShowcaseTypeOptions.find(c => c.value === value) || ""}
                    onChange={(e) => { onChange(e.value); }} isDisabled={isDisabledInput}
                    isSearchable={false}
                  />
                )}
              />
              <div className="invalid-feedback">{errors.type?.message}</div>
            </div>
          </Col>
        )}
        {watch("type") && watch("type") !== "" && (
          <React.Fragment>
            {watch("type") === "Link" ? (
              <Col sm="12" md="12" className="mt-4">
                <div className="form-group fields">
                  <label>Need a post crafted around your website link? <span className="text-danger fs-6 ms-1">*</span></label>
                  <div className="inputField">
                    <input
                      name="link" type="text" placeholder="Insert your link here" {...register("link")}
                      className={`addllyForm-control w-100 ${errors.link ? "is-invalid" : ""}`}
                      disabled={isDisabledInput}
                    />
                  </div>
                  <div className="invalid-feedback">{errors.link?.message}</div>
                </div>
              </Col>
            ) : watch("type") === "PDF" ? (
              <Col sm="12" md="12" className="mt-4">
                <div className="form-group">
                  <label>Upload your PDF<span className="required-astrick fs-6 ms-1">*</span></label>
                  <div className="fileUploadBlock lightBg">
                    <FileUploader
                      name="file" types={["pdf"]} maxSize={6} children={FileUploaderCustomUi}
                      multiple={true} classes={`w-100`} handleChange={handleOnSelectPdfFile} disabled={isDisabledInput}
                      onDraggingStateChange={(dragging) => setUploadFileDragging(dragging)} dropMessageStyle={{ display: "none" }}
                      onSizeError={() => setUploadFileError("File size exceeds the allowable limit. Please upload a smaller file.")}
                      onTypeError={() => setUploadFileError("Invalid file type. Please upload a file with a supported file type.")}
                    />
                  </div>
                </div>
                {isDisabledInput && (
                  <div className="d-flex align-items-center mt-2">
                    <AiOutlineFilePdf className="fs-5 me-3" />
                    <a href={newsletterWriterInfo?.productShowcaseInfo?.link} target="_blank" rel="noreferrer" className="text-decoration-none">
                      {newsletterWriterInfo?.productShowcaseInfo?.link.split("/")[newsletterWriterInfo?.productShowcaseInfo?.link.split("/").length - 1]}
                    </a>
                  </div>
                )}
              </Col>
            ) : (
              <Col sm="12" md="12" className="summeryBlock mt-4">
                <div className="form-group fields">
                  <label>Insert own content for product showcase. <span className="text-danger fs-6 ms-1">*</span></label>
                  <textarea
                    name="content" type="text" {...register("content")} rows="3" disabled={isDisabledInput}
                    className={`addllyForm-control w-100 ${errors.content ? "is-invalid" : ""}`}
                    style={{ borderRadius: "5px" }} placeholder="Insert product showcase description here"
                  />
                  <div className="invalid-feedback">{errors.content?.message}</div>
                </div>
              </Col>
            )}
          </React.Fragment>
        )}
      </Row>
      <div className="d-flex justify-content-center align-items-center gap-2 bottomButton">
        <div>
          <button type="submit" className="addlly-primary mw-100 px-4"
            disabled={!watch("topic") || !watch("url1") || !watch("url2") || !watch("url3")}
          >
            <span style={{ fontSize: "14px" }}><BsStars /> Generate Newsletter<BsArrowRight /></span>
          </button>
        </div>
        {!articleId && (
          <BsInfoCircleFill
            className="infoIconSvg" data-tooltip-id="my-tooltip" data-tooltip-place="right"
            data-tooltip-content={`Will Utilize ${userRes?.deductCredits?.["NewsLetters"] || 3} Addlly Credits`}
          />
        )}
      </div>
    </form>
  )
}

export default SecondStepNewsletters;
