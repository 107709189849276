import classNames from "classnames";
import { useEffect, useState } from "react";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import ReactPaginate from "react-paginate";

const CustomPagination = ({ length, handlePageChange, itemsPerPage, currentPage }) => {
  const pageCount = Math.ceil(length / itemsPerPage) || 1;

  const handleChangePageLoadData = (event) => {
    if (typeof event.nextSelectedPage === typeof undefined) return false;
    const selectedPage = event.nextSelectedPage ? event.nextSelectedPage : 0;
    const newOffset = (selectedPage * itemsPerPage);
    if (newOffset >= 0) {
      handlePageChange(selectedPage + 1, newOffset);
    }
  };

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className="pagenationBlock bg-white d-flex justify-content-between align-items-center">
      <div className="tableValuesNo">
        {length ? `Drafts ${(currentPage - 1) * itemsPerPage + 1} to ${currentPage * itemsPerPage > length ? length : currentPage * itemsPerPage} of ${length}` : "No data found"}
      </div>
      <div className="pagenationCount">
        <ReactPaginate
          forcePage={currentPage - 1}
          pageCount={pageCount}
          breakLabel="..."
          nextLabel={<span>{isMobile ? <IoIosArrowForward /> : 'NEXT'}</span>}
          onClick={handleChangePageLoadData}
          pageRangeDisplayed={2}
          marginPagesDisplayed={0}
          className="historyPaginate"
          previousLabel={<span>{isMobile ? <IoIosArrowBack /> : 'PREV'}</span>}
          renderOnZeroPageCount={null}
          activeLinkClassName={classNames({ selected: currentPage })}
        />
      </div>
    </div>
  );
};

export default CustomPagination;
