import React from "react";
import { ReactSVG } from "react-svg";
import { Editor } from "react-editor";
import { copyIcon } from "../../Assets/Icons";

const InstagramReels = (props) => {
  const { generatedFullArticle, value, setEdit, edit, handleCopyHtmlArticleText } = props;

  return (
    <div className="textEditerArea mw-100">
      <div className="genratePost">
        <div className="textArea position-relative">
          <div className="top-header-bar">
            <div className="topButton d-flex">
              {generatedFullArticle?.instagram_reels && (
                <button
                  type="button" className="blogButton border-0 copyBtn" onClick={(e) => handleCopyHtmlArticleText(value)}
                  data-tooltip-id="my-tooltip" data-tooltip-content="Copy to clipboard" data-tooltip-place="bottom"
                >
                  <ReactSVG src={copyIcon} />
                </button>
              )}
            </div>
            {edit?.instagram_reels && edit?.instagram_reels?.length >= 3000 ? <div className="status-message">
              <span className="red-dot"></span>
              <span>You exceeded 3000 characters</span>
            </div> : null}
          </div>
          <Editor
            disabled={generatedFullArticle?.instagram_reels && generatedFullArticle?.instagram_reels?.length > 0 ? false : true}
            className="text-editor-block" value={value} onChange={(value) => { setEdit({ ...edit, instagram_reels: value }); }}
          />
        </div>
      </div>
    </div>
  );
};

export default InstagramReels;
