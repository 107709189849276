import classNames from "classnames";
import React, { memo, useState } from "react";
import { GoChevronDown } from "react-icons/go";


const Accordion = memo(({ buttonChildren, children }) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <>
            <div className=" bg-transparent">
                <div className="position-relative dropDown_" onClick={() => setIsOpen(!isOpen)}>
                    <button className="w-100 bg-transparent p-0 border-0 ">{buttonChildren}</button>
                    <GoChevronDown
                        style={{ transform: isOpen ? "rotate(180deg) " : "none", top: "14px", right: "7px" }}
                        className={classNames("position-absolute ", "end-5", "fs-4", "text-light", { "fill": isOpen })}
                    />
                </div>
                {isOpen ? <div>{children}</div> : null}
            </div>
        </>
    );
});

export default Accordion;
