import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useSearchParams } from "react-router-dom";
import { getHistory } from "../utility/apiService";
import Heading from "../Components/ui/Title";
import { setGeneratedFullArticle, setLoader, setTopResultsStep } from "../store/reducer";
import FirstStepPdfArticleWriter from "../Components/PdfArticleWriter/FirstStepPdfArticleWriter";
import SecondStepPdfArticleWriter from "../Components/PdfArticleWriter/SecondStepPdfArticleWriter";
import { invokeAxiosGet } from "../utility/invokeAxiosFunction";

const PdfArticleWriter = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const topResultsStep = useSelector(({ topResultsStep }) => topResultsStep);
  const userRes = useSelector(({ userRes }) => userRes);

  useEffect(() => {
    if (userRes?.id && !id) {
      dispatch(getHistory(userRes?.id, 10, 0, "artical_history", "PDF"));
      dispatch(setTopResultsStep(1));
      dispatch(setGeneratedFullArticle({}));
    }
  }, [id]);

  useEffect(() => {
    if (userRes?.id && id) {
      dispatch(setLoader(true));
      dispatch(setGeneratedFullArticle({}));
      invokeAxiosGet(`/article-history/get-or-save/articleHistoryGetById?id=${id}`).then((res) => {
        const item = res.data && res.data.data ? res.data.data : {};
        dispatch(setGeneratedFullArticle({
          ...item, articleId: item?.id,
          linkedIn_post: item?.linkedIn_post, facebook_post: item?.facebook_post,
          twitter_post: item?.twitter_post, instagram_post: item?.instagram_post,
          instagram_reels: item?.instagram_reels, FAQschema: item?.FAQschema?.replace(/(\r\n|\n|\r)/gm, ""),
          SEO_score_long_article: item?.SEO_score_long_article ? JSON.parse(item?.SEO_score_long_article).data : "",
          popular_hashtags: item?.popular_hashtags ? JSON.parse(item.popular_hashtags) : [],
          article_html_chart: item?.article_html_chart ? JSON.parse(item?.article_html_chart)?.data : null,
        }));
        dispatch(setTopResultsStep(3));
        dispatch(setLoader(false));
      }).catch((e) => {
        dispatch(setLoader(false));
      });
    }
  }, []);

  return (
    <React.Fragment>
      {topResultsStep === 1 && (
        <React.Fragment>
          <div className=" d-flex flex-column gap-3 pt-4">
            <div className="heading">
              <Heading className="fw-bolder mainHeading" size="3">PDF-to-Article Quick Writer</Heading>
              <p>Transform PDFs to concise articles with the latest insights</p>
            </div>
          </div>
          <FirstStepPdfArticleWriter />
        </React.Fragment>
      )}
      {topResultsStep === 3 && <SecondStepPdfArticleWriter />}
    </React.Fragment >
  );
};

export default PdfArticleWriter;
