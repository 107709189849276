import React, { useEffect, useMemo, useState } from "react";
import CodeEditor from "../SeoBlogWriter/CodeEditor";
import toast from "react-hot-toast";
import copy from "copy-to-clipboard";
import parse from 'html-react-parser';
import js_beautify from 'js-beautify';
import { copyIcon } from "../../Assets/Icons";
import { ReactSVG } from "react-svg";

const FaqSchemaArtical = (props) => {
  const { setEdit, edit } = props
  const [faqSchema, setFaqSchema] = useState('');
  const headLineType = useMemo(() => (["h1", 'h2', 'h3', 'h4', 'h5', 'h6']), []);

  useEffect(() => {
    const generateFAQSchema = () => {
      const questions = parse(edit?.FAQHTML)?.length ? parse(edit?.FAQHTML ?? '')?.filter(
        (element) => headLineType.includes(element.type)
      ) : [];
      const answers = parse(edit?.FAQHTML)?.length ? parse(edit?.FAQHTML ?? '')?.filter(
        (element) => element.type === 'p'
      ) : []
      const faqSchemaData = {
        '@context': 'https://schema.org',
        '@type': 'FAQPage',
        mainEntity: questions.length ? questions.map((question, index) => ({
          '@type': 'Question',
          name: question.props.children ?? '',
          acceptedAnswer: {
            '@type': 'Answer',
            text: answers[index]?.props?.children ?? ''
          }
        })) : ["Please write questions in H tag and Answers in P tag"]
      };
      setFaqSchema(js_beautify(JSON.stringify(faqSchemaData, null, 2)));
    };

    if (edit?.FAQHTML) {
      generateFAQSchema();
    }
  }, [edit?.FAQHTML, headLineType]);

  const handleCopy = () => {
    toast.success("Text has been copied to clipboard.");
    copy(faqSchema);
  };

  return (
    <>
      <div className={`toggleData ${!edit?.FAQHTML && 'notContent'}`}>
        <div className="html-content">
          <label className="mb-2 d-flex gap-3  ">
            <span className="labelHeading d-block">{`FAQ's`} Markup Schema</span>
            <span className="svgCopy">
              <ReactSVG src={copyIcon} className="copy" onClick={() => handleCopy()}
                data-tooltip-id="my-tooltip" data-tooltip-content={"Copy"} data-tooltip-place="right"
              />
            </span>
          </label>
          <CodeEditor faqSchema={faqSchema} edit={edit} setDemoEdit={(e) => setEdit({ ...edit, FAQHTML: e })} />
        </div>
      </div>
    </>
  );
};

export default FaqSchemaArtical;
