import checkedIcon from "../Icons/Checked.svg";
import uncheckedIcon from "../Icons/Unchecked.svg";
import houseDoor from "../Icons/Sidebar/HouseDoor.svg";
import addllyLogo from "../Icons/HeaderMenus/headerLogo.svg";
import arrowIcon from "../Icons/HeaderMenus/ArrowRight.svg";
import profilePic from "../Icons/Sidebar/profilePic.svg";
import sidenavLogoImages from "../Icons/Sidebar/Images.svg";
import megaPhone from "../Icons/Sidebar/Megaphone.svg";
import newspaper from "../Icons/Sidebar/Newspaper.svg";
import history from "../Icons/Sidebar/ClockHistory.svg";
import DescribeNews from "../Icons/Sidebar/DescribeNews.svg";
import share from "../Icons/Sidebar/share.svg";
import cardHeading from "../Icons/Sidebar/CardHeading.svg";
import emptyWallet from "../Icons/Sidebar/empty-wallet-add.svg";
import pencilSquare from "../Icons/DashboardIcons/PencilSquare.svg";
import messageIcon from "../Icons/DashboardIcons/messageIcon.svg";
import click from "../Icons/DashboardIcons/Click.svg";
import pdf from "../Icons/DashboardIcons/Download pdf.svg";
import hasTag from "../Icons/DashboardIcons/Hastag.svg";
import instagram from "../Icons/DashboardIcons/Instagram.svg";
import facebook from "../Icons/DashboardIcons/Facebook.svg";
import linkedin from "../Icons/DashboardIcons/Linkedin.svg";
import newsletter from "../Icons/DashboardIcons/Newsletter.svg";
import loudSpeaker from "../Icons/DashboardIcons/Loud speaker.svg";
import images from "../Icons/DashboardIcons/Images.svg";
import starFill from "../Icons/DashboardIcons/StarFill.svg";
import cardHeadings from "../Icons/DashboardIcons/CardHeading.svg";
import shareIcon from "../Icons/DashboardIcons/share.svg";
import newspaperIcon from "../Icons/DashboardIcons/Newspaper.svg";
import megaPhoneIcon from "../Icons/DashboardIcons/Megaphone.svg";
import filterIcon from "../Icons/TableIcons/FilterLeft.svg";
import redoIcon from "../Icons/TableIcons/Redo.svg";

import add from "../Icons/ArticleHistoryIcons/Add.svg";
import filter from "../Icons/ArticleHistoryIcons/Filter.svg";
import star from "../Icons/DashboardIcons/Star.svg";
import glitterStar from "./BlogWriterIcons/Glitter Stars.svg";
import spaceRocket from "./BlogWriterIcons/Space Rocket Launch.svg";
import clickOneClickBlog from "./BlogWriterIcons/Click.svg";
import loadingOne from "./BlogWriterIcons/step-1.svg";
import loadingTwo from "./BlogWriterIcons/step-2.svg";
import loadingThree from "./BlogWriterIcons/step-3.svg";
import commentShare from "./BlogWriterIcons/CommentShares.svg";
import linkedInMobileLoading from "./BlogWriterIcons/LinkedInMobileLoading.svg";
import mobilescreen from "./BlogWriterIcons/mobilescreen.svg";
import liked from "./BlogWriterIcons/Liked.svg";
import tabMenu from "./BlogWriterIcons/TabMenu.svg";
import copyIcon from "./copySvg.svg";
import adCopy from "./BlogWriterIcons/Adwords.svg";
import facebookT from "./BlogWriterIcons/Facebook.svg";
import instaTop from "./BlogWriterIcons/instaTop.svg";
import instaStory from "./BlogWriterIcons/Story 2.svg";
import instaAvtar from "./BlogWriterIcons/avtar.png";
import instaFrame from "./BlogWriterIcons/Avatar Frame.svg";
import postsIcons from "./BlogWriterIcons/Post Icons.svg";
import instaMenu from "./BlogWriterIcons/Menu.svg";
import DotttedBorder from "./BlogWriterIcons/DotttedBorder.svg";
import facebookTop from "./BlogWriterIcons/facebookTop.svg";
import homeIcons from "./BlogWriterIcons/Home icons.svg";
import faceBookSearch from "./BlogWriterIcons/searchBar.svg";
import imageIconFb from "./BlogWriterIcons/imgSvgfb.svg";
import fbBlankImg from "./BlogWriterIcons/fbuser.jpg";
import linkComments from "./BlogWriterIcons/Likes & Comments.svg";
import blogIcon from "./BlogWriterIcons/Blog.svg";
import contractIcon from "./BlogWriterIcons/Contract.svg";
import pdfIcon from "./BlogWriterIcons/Pdf.svg";
import referenceLinkIcon from "./BlogWriterIcons/referenceLinkIcon.svg";
import bubblesIcon from "./BlogWriterIcons/Speech bubble.svg";
import transaction from "./BlogWriterIcons/Transaction history.svg";
import transla from "./BlogWriterIcons/transla.svg";
import informative from "./BlogWriterIcons/informative.svg";
import Documents from "./BlogWriterIcons/Documents.svg";
import msgIconSvg from "./BlogWriterIcons/msgSvg.svg";
import searchIconSvg from "./BlogWriterIcons/searchIconSvg.svg";
import pencil from "./TableIcons/Pencil.svg";

import AnnuallyArrow from "../Icons/PlanDashboardIcons/annually-arrow.svg";
import RoundBlueDotIcon from "../Icons/PlanDashboardIcons/dot-icon.svg";
import PlanCheckedIcon from "../Icons/PlanDashboardIcons/checked-icon.svg";
import PlanUnCheckedIcon from "../Icons/PlanDashboardIcons/unchecked-icon.svg";
import PlanNoteIcon from "../Icons/PlanDashboardIcons/note-pen-icon.svg";
import AddllyBlueLogo from "./Authentication/AddllyLogo.svg";
import twitter from "./DashboardIcons/Twitter.svg";
import skipIcon from "./ChevronBarRight.svg";
import ExclamationTriangleFill from "./HeaderMenus/ExclamationTriangleFill.svg";
import ClockHistory from "./HeaderMenus/ClockHistory.svg";

const ICONS = {
  checkedIcon,
  uncheckedIcon,
  skipIcon,
  ExclamationTriangleFill,
  ClockHistory,
};

const headerIcons = {
  addllyLogo,
  arrowIcon,
};

const sideBarIcons = {
  images: sidenavLogoImages,
  houseDoor,
  profilePic,
  megaPhone,
  newspaper,
  share,
  cardHeading,
  emptyWallet,
  history,
  DescribeNews,
};

const dashboardIcon = {
  pencilSquare,
  messageIcon,
  click,
  pdf,
  hasTag,
  instagram,
  facebook,
  linkedin,
  newsletter,
  loudSpeaker,
  images,
  starFill,
  cardHeadings,
  shareIcon,
  newspaperIcon,
  megaPhoneIcon,
  star,
  twitter,
};

const dataTableIcons = {
  filterIcon,
  redoIcon,
  pencil,
};

const articleHistoryIcons = {
  add,
  filterIcon,
  redoIcon,
  filter,
};

const oneClickBlogIcon = {
  glitterStar,
  spaceRocket,
  clickOneClickBlog,
  loadingOne,
  loadingTwo,
  loadingThree,
  commentShare,
  liked,
  linkedInMobileLoading,
  tabMenu,
  mobilescreen,
  adCopy,
  facebookT,
  instaTop,
  instaStory,
  instaAvtar,
  instaFrame,
  postsIcons,
  instaMenu,
  DotttedBorder,
  facebookTop,
  homeIcons,
  faceBookSearch,
  imageIconFb,
  fbBlankImg,
  linkComments,
  msgIconSvg,
  searchIconSvg,
};

const blogCoPilotIcon = {
  blogIcon,
  contractIcon,
  pdfIcon,
  referenceLinkIcon,
  bubblesIcon,
  transaction,
  transla,
  informative,
  Documents,
};

const planDashBoardIcon = {
  AnnuallyArrow,
  RoundBlueDotIcon,
  PlanCheckedIcon,
  PlanUnCheckedIcon,
  PlanNoteIcon,
};

const authenticateIcon = {
  AddllyBlueLogo
};

export {
  ICONS, headerIcons, sideBarIcons, dashboardIcon, articleHistoryIcons, dataTableIcons, oneClickBlogIcon,
  copyIcon, blogCoPilotIcon, planDashBoardIcon, authenticateIcon,
};
