import React from "react";

import { ReactSVG } from "react-svg";
import { headerIcons } from "../../Assets/Icons";
import { signUpText } from "../../data";
import Slider from "react-slick";
const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  autoplay: true,
  autoplaySpeed: 3000,
  adaptiveHeight: true,
};

const AuthenticateLeft = () => {
  return (
    <div className="leftPart">
      <div className="logos">
        <ReactSVG src={headerIcons.addllyLogo} alt="addlly logo" />
      </div>
      <div className="slider-sec">
        <Slider {...settings}>
          {signUpText?.map(({ userName, userDesc, userImage, text }, i) => (
            <div key={i + userName}>
              <p><span>“</span>{text}<span>”</span></p>
              <div className="user-details d-flex align-items-center gap-3">
                <img src={userImage} alt={userName} loading="lazy" />
                <div>
                  <h5>{userName}</h5>
                  <span>{userDesc}</span>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>

    </div>
  );
};

export default AuthenticateLeft;
