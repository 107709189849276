import { memo } from "react";
import { dashboardIcon } from "../../Assets/Icons";

import { useNavigate } from "react-router-dom";
import { ReactSVG } from "react-svg";
import SkeletonLoader from "../ui/SkeletonLoader";

const FeatureCard = (props) => {
  const { icon, pinned, title, description, showNew, id, to, value, type, callback, handlePinnedFeature, handleRemovePinnedFeature, isLoading } = props
  const navigate = useNavigate();

  const handleNavigate = () => {
    if (to) {
      navigate(to);
    } else {
      callback(value, type);
    }
  };

  const SkeletonLoading = () => {
    return (
      <div className="card position-relative text-start cursor skeleton-loader" style={{ pointerEvents: 'none' }}>
        <div className="icon skeleton-loader">
          <SkeletonLoader height={50} width={50} />
        </div>
        <div>
          <h4 className="mt-0 d-flex align-items-center cursor-pointer skeleton-loader">
            <SkeletonLoader width={100} />
          </h4>
          <p className="m-0 pe-auto skeleton-loader">
            <SkeletonLoader count={3} />
          </p>
        </div>
        <div className="star">
          <div className="skeleton-loader">
            <SkeletonLoader circle={true} height={30} width={30} />
          </div>
        </div>
      </div>
    );
  };

  if (isLoading) return <SkeletonLoading />;

  return (
    <div className="card position-relative text-start cursor " onClick={handleNavigate}>
      <div className="icon text-start justify-content-center d-flex align-items-center">
        <ReactSVG src={icon} useRequestCache />
      </div>
      <div>
        <h4 className="mt-0 d-flex align-items-center cursor-pointer">
          {title}
          {showNew ? (
            <span className="d-flex align-items-center justify-content-center text-white blueButton">New</span>
          ) : null}
        </h4>
        <p className="m-0 pe-auto">{description}</p>
      </div>
      <div className="star">
        {pinned ? (
          <ReactSVG
            onClick={(e) => { e.stopPropagation(); handleRemovePinnedFeature(id) }}
            src={dashboardIcon.starFill} alt="startIconFill" useRequestCache
          />
        ) : (
          <ReactSVG
            onClick={(e) => { e.stopPropagation(); handlePinnedFeature(id) }}
            src={dashboardIcon.star} alt="startIcon" useRequestCache
          />
        )}
      </div>
    </div>
  );
};

export default memo(FeatureCard);
