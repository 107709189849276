import React, { memo } from "react";
import { cardsDetails } from "../../data";
import Heading from "../ui/Title";
import FeatureCard from "./FeatureCard";

const TopFeatures = (props) => {
  const { handleNavigate, filteredFeaturesData, pinnedFeaturesData, handlePinnedFeature, handleRemovePinnedFeature, isLoadingCards } = props;

  return (
    <div className="writer-option-box text-center dashboardInfoBlock">
      <Heading size={5} className={"fw-bold"}>Top Features</Heading>
      <div className="cardsBlock d-flex flex-wrap topCardBlock">
        {filteredFeaturesData?.map(({ title, is_new, description, id, to, value, type }, index) => (
          <FeatureCard
            id={id}
            key={title + " " + index}
            title={title}
            showNew={is_new}
            callback={handleNavigate}
            description={description}
            to={to}
            value={value}
            type={type}
            icon={cardsDetails.filter((card) => card.title === title)[0]?.icon}
            handleRemovePinnedFeature={handleRemovePinnedFeature}
            handlePinnedFeature={handlePinnedFeature}
            pinned={pinnedFeaturesData?.some((pinnedFeature) => pinnedFeature.feature_id === id)}
            isLoading={isLoadingCards}
          />
        ))}
      </div>
    </div>
  );
};

export default memo(TopFeatures);
