import React, { useMemo } from "react";
import ReactDataTable from "react-data-table-component";
import CustomPagination from "./Pagination";
import "../../Assets/scss/datatableStyle.scss";

const CustomDataTable = ({
  columns = [], data = [], pagination = false, itemsPerPage = 10, handleTableRowClick,
  userRes, totalCount, page, setPage = () => { }, onSort = () => { }, ...props
}) => {

  const skip = useMemo(() => ((page - 1) * itemsPerPage), [itemsPerPage, page])
  const handlePageChange = (pageNo, offset) => {
    setPage(pageNo);
  };

  return (
    <div className="custom-data-table">
      <div className={`tableBlock ${!props?.selectableRows && 'no-checkbox'}`}>
        <ReactDataTable
          fixedHeader={true} onSort={onSort} columns={columns}
          persistTableHead={((data && data.length > 0) || props?.progressPending) ? true : false}
          paginationPerPage={itemsPerPage} data={data?.slice(skip, skip + itemsPerPage)} {...props}
          className={data && data.length > 0 ? "fillData" : "blankTable"} onRowClicked={handleTableRowClick}
          highlightOnHover
        />
      </div>
      <div className="pagenation">
        {pagination && data && data.length > 0 ? (
          <CustomPagination
            length={data.length} handlePageChange={handlePageChange}
            itemsPerPage={itemsPerPage} currentPage={page || 1}
          />
        ) : null}
      </div>
    </div>
  );
};

export default CustomDataTable;
