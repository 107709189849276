import React, { useCallback, useEffect, useRef, useState } from "react";
import Swal from "sweetalert";
import classNames from "classnames";
import { toast } from "react-hot-toast";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { GrClose } from "react-icons/gr";
import { FaSquareMinus, FaSquarePlus } from "react-icons/fa6";
import { BsInfoCircleFill, BsTrash, BsList, BsArrowLeft, BsArrowRight, BsArrowCounterclockwise } from "react-icons/bs";
import {
  setSeoOptBlogStep, setFinalContent, setLoader, setSeoOptBlogTopic,
  setHeadline, setSeoOptimizedSets, setHeadlineData
} from "../../store/reducer";
import { invokeAxiosGet } from "../../utility/invokeAxiosFunction";

const FifthStepSeoOptBlog = () => {
  const dispatch = useDispatch();
  const userRes = useSelector(({ userRes }) => userRes);
  const headline = useSelector(({ headline }) => headline);
  const outlineRes = useSelector(({ outlineRes }) => outlineRes);
  const selectedTopic = useSelector(({ selectedTopic }) => selectedTopic);
  const setsOfSeoOptimized = useSelector(({ setsOfSeoOptimized }) => setsOfSeoOptimized);
  const googleSuggestKeyword = useSelector(({ googleSuggestKeyword }) => googleSuggestKeyword);
  const seoOptBlogTopic = useSelector(({ seoOptBlogTopic }) => seoOptBlogTopic);
  const finalContent = useSelector(({ finalContent }) => finalContent);
  const headlineData = useSelector(({ headlineData }) => headlineData);

  const [isShowSummary, setIsShowSummary] = useState(true);
  const [allSelectedOutline, setAllSelectedOutline] = useState([]);
  const [showOutlineSets, setShowOutlineSets] = useState({ index: 0, item: "", name: 'Outline Set 1' });
  const [topSummary, setTopSummary] = useState(setsOfSeoOptimized?.summaryDescription);
  const [isResetDisabled, setIsResetDisabled] = useState(true);
  const [allOutlets, setAllOutlets] = useState({ outletOne: [], outletTwo: [], outletThree: [] });

  useEffect(() => {
    if (showOutlineSets && showOutlineSets.item === "") {
      // const obj = headline?.split(new RegExp(/Set .:/, "i")).filter((item) => item);
      if (headline && Object.keys(headline)?.length > 0 && typeof headline[`Set-1`] !== typeof undefined) {
        setShowOutlineSets({ index: 0, item: headline && headline[`Set-1`] ? headline[`Set-1`] : "", name: 'Outline Set 1' });
      }
    }

    if (finalContent && finalContent.allSelectedOutline && finalContent.allSelectedOutline.length > 0) {
      setAllSelectedOutline(finalContent.allSelectedOutline);

      let getSelectedSet = { index: 0, item: "" }, index = 0;
      for (const item in headline) {
        const findIndex = finalContent.allSelectedOutline.findIndex(outline => headline[item].includes(outline));
        if (findIndex !== -1) {
          getSelectedSet = { index, item };
          break;
        }
        index++;
      }
      setShowOutlineSets({
        index: getSelectedSet.index, name: `Outline Set ${getSelectedSet.index + 1}`,
        item: headline && headline[getSelectedSet.item] ? headline[getSelectedSet.item] : ""
      });

      const key = getSelectedSet?.item === 'Set-2' ? 'outletTwo' : getSelectedSet?.item === 'Set-3' ? 'outletThree' : 'outletOne';
      setAllOutlets(pre => ({ ...pre, [key]: finalContent.allSelectedOutline }));
    }
  }, []);

  const handleNext = async () => {
    try {
      if (allSelectedOutline.length > 3) {
        dispatch(setLoader(true));
        dispatch(setSeoOptimizedSets({ ...setsOfSeoOptimized, summaryDescription: topSummary }));
        invokeAxiosGet(`/article-history/get-or-save/articleHistoryGetById?id=${seoOptBlogTopic.articleId}?tableName=artical_long_flow`).then((res) => {
          dispatch(setLoader(false));
          const item = res.data && res.data.data ? res.data.data : {};
          let isTyping = false, isShowPopup = false;
          const oldSelectedOutline = item?.selected_headline_set ? JSON.parse(item?.selected_headline_set) : [];
          if (oldSelectedOutline && oldSelectedOutline.length === 0) {
            isTyping = true;
          }

          if (oldSelectedOutline && oldSelectedOutline.length > 0) {
            if (oldSelectedOutline.length === allSelectedOutline.length) {
              for (let index = 0; index < allSelectedOutline.length; index++) {
                if (!oldSelectedOutline[index] || allSelectedOutline[index] !== oldSelectedOutline[index]) {
                  isTyping = true; isShowPopup = true;
                }
              }
            } else {
              isTyping = true; isShowPopup = true;
            }
          }

          if (isTyping && isShowPopup) {
            Swal({
              title: "Are you sure to generate article?",
              text: `Will utilize ${userRes?.deductCredits?.["Blog Co-Pilot"] || 4} Addlly credits`,
              icon: "warning", dangerMode: true,
              buttons: { cancel: "Not Now", confirm: "Generate Article" },
            }).then((isConfirmed) => {
              if (isConfirmed) {
                handleGenerateArticleOrMoveNextStep(item, isTyping);
              } else {
                handleGenerateArticleOrMoveNextStep(item, false);
              }
            });
          } else {
            handleGenerateArticleOrMoveNextStep(item, isTyping);
          }
        }).catch((e) => {
          console.log(e, "eee");
          dispatch(setLoader(false));
        });
      } else {
        toast.error("Select any four outlines.", { id: "Toast-01" });
      }
    } catch (error) {
      console.log("error", error);
      toast.error(error?.response?.data?.error?.message || error?.response?.data?.error || "Server is overloaded, try again after sometime.", { id: "Toast-01" });
    }
  };

  const handleGenerateArticleOrMoveNextStep = (item, isTyping) => {
    if (isTyping) {
      dispatch(setFinalContent({
        meta_title: item.meta_title, meta_dec: item?.meta_dec,
        toneOfVoice: seoOptBlogTopic?.toneOfVoice || "",
        allSelectedOutline: allSelectedOutline, isTyping: true,
        oldArticleHtml: item?.article_html ? item?.article_html : "",
        faq_regenerate_left: item.faq_regenerate_left,
      }));
    } else {
      dispatch(setFinalContent({
        article_html: item.article_html, meta_title: item.meta_title, meta_dec: item.meta_dec,
        SEO_score_response: item?.SEO_score_long_article ? JSON.parse(item?.SEO_score_long_article).data : "",
        FAQschema: item?.FAQschema || "", FAQHTML: item?.FAQHTML || "",
        linkedIn_post: item?.linkedIn_post, linkedIn_post_img: item?.linkedIn_post_img,
        facebook_post: item?.facebook_post, facebook_post_img: item?.facebook_post_img,
        twitter_post: item?.twitter_post, twitter_post_img: item?.twitter_post_img,
        instagram_post: item?.instagram_post, instagram_post_img: item?.instagram_post_img,
        instagram_reels: item?.instagram_reels, googleAdCopy: item?.googleAdCopy || "",
        allSelectedOutline: item?.selected_headline_set ? JSON.parse(item?.selected_headline_set) : [],
        optimizedArticle: item?.optimized_article || "", faq_regenerate_left: item.faq_regenerate_left,
        article_html_chart: item?.article_html_chart ? JSON.parse(item?.article_html_chart)?.data : null,
        toneOfVoice: item?.toneOfVoice ? item.toneOfVoice : "", h2_images: item?.h2_images ? item.h2_images : "",
        logs: item?.logs ? item.logs : "", isTyping: false, isTypingDone: true,
      }));
    }
    dispatch(setSeoOptBlogTopic({ ...seoOptBlogTopic, stepDone: 7 }));
    dispatch(setSeoOptBlogStep(7));
  }

  const handleResetHeadlineSet = () => {
    setIsResetDisabled(true);
    dispatch(setHeadline(outlineRes));
    dispatch(setHeadlineData([]));
    setShowOutlineSets({ index: showOutlineSets.index, item: "", name: `Outline Set ${showOutlineSets.index + 1}` });
    setTimeout(() => {
      if (outlineRes && Object.keys(outlineRes)?.length > 0 && typeof outlineRes[`Set-${showOutlineSets.index + 1}`] !== typeof undefined) {
        setShowOutlineSets({
          index: showOutlineSets.index, name: `Outline Set ${showOutlineSets.index + 1}`,
          item: outlineRes && outlineRes[`Set-${showOutlineSets.index + 1}`] ? outlineRes[`Set-${showOutlineSets.index + 1}`] : ""
        });
      }
    }, 200);
  }

  const handleClearSelectedOutline = (val) => {
    const newArray2 = allSelectedOutline.filter((item) => item !== val);
    setAllSelectedOutline(newArray2);
    dispatch(setFinalContent({ ...finalContent, allSelectedOutline: newArray2 }));
    dispatch(setHeadlineData([...headlineData.slice(0, headlineData.length - 1)]));
  };

  const moveOutlineListItem = useCallback((item, monitor, index, ref) => {
    const dragIndex = item.index;
    const hoverIndex = index;
    const hoverBoundingRect = ref.current?.getBoundingClientRect();
    const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
    const hoverActualY = monitor.getClientOffset().y - hoverBoundingRect.top;

    if (dragIndex < hoverIndex && hoverActualY < hoverMiddleY) return;
    if (dragIndex > hoverIndex && hoverActualY > hoverMiddleY) return;

    if (dragIndex !== hoverIndex) {
      const dragItem = allSelectedOutline[dragIndex];
      const hoverItem = allSelectedOutline[hoverIndex];

      setAllSelectedOutline(outlines => {
        const newOutlineVal = [...outlines];
        newOutlineVal[dragIndex] = hoverItem;
        newOutlineVal[hoverIndex] = dragItem;
        return newOutlineVal;
      });
    }
    item.index = hoverIndex;
  }, [allSelectedOutline])

  return (
    <div className="topicBlockBottom">
      <div className="selectBlock seoselected ms-0 mw-100">
        <h5>Outline Selection (Please update the heading later)</h5>
      </div>
      <Row>
        <Col xl={4} style={{ height: '100%' }}>
          <div className="optimizationBlock h-100">
            <div className="cardOptimiz">
              <div className="topicBlock">
                <span className="d-block">Topic</span>
                <h5 className="fw-normal">{selectedTopic}</h5>
              </div>
              <div className="topicBlock">
                <span className="d-block">Keyword</span>
                <h5 className="fw-normal">{googleSuggestKeyword}</h5>
              </div>
              <div className="topicBlock">
                <span className="d-block">Headline</span>
                <h5 className="fw-normal">{setsOfSeoOptimized?.headline}</h5>
              </div>
              <div className="topicBlock">
                <span className="d-flex align-items-center justify-content-between gap-2">
                  {seoOptBlogTopic && seoOptBlogTopic.blog_type === "Pdf" ? "Pdf Summary" : "Summary Google Search results of the topic"}
                  {isShowSummary ? (
                    <FaSquareMinus className="cursor-pointer" style={{ width: "20px", height: "20px", fill: "#0039ff" }}
                      onClick={() => setIsShowSummary(false)}
                    />
                  ) : (
                    <FaSquarePlus className="cursor-pointer" style={{ width: "20px", height: "20px", fill: "#0039ff" }}
                      onClick={() => setIsShowSummary(true)}
                    />
                  )}
                </span>
                {isShowSummary && (
                  <textarea col="6" style={{ height: "145px" }} value={topSummary} onChange={(e) => setTopSummary(e.target.value)} />
                )}
              </div>
              <div className="topicBlock">
                <span className="d-block">Your Selection</span>
                {allSelectedOutline && allSelectedOutline.length > 0 ? (
                  <DndProvider backend={HTML5Backend}>
                    <div className="outline-selection-wrapper">
                      {allSelectedOutline.map((outline, index) => (
                        <OutlineListItemComponent
                          key={index} index={index} outline={outline}
                          moveOutlineListItem={moveOutlineListItem}
                          handleClearSelectedOutline={handleClearSelectedOutline}
                        />
                      ))}
                    </div>
                  </DndProvider>
                ) : (
                  <h5 className="fw-normal text-danger">
                    You don’t have any outline selected. Please select few headlines to continue
                  </h5>
                )}
              </div>
            </div>
          </div>
        </Col>
        <Col xl={8} className="letFormSide">
          <div className="columnLine ">
            <div className="">
              <h5 className="outline-headimg">Choose your outline set below.</h5>
              <h6 className="font-weight-500 color-light-text">
                The article will be generated based on the outline set you choose. Select a minimum of four outline sections from only one set.
                You can also add your own outline sections.
              </h6>
            </div>
            <div className=" outlineSet d-flex flex-wrap align-items-center gap-3 mt-3 justify-content-between">
              <div className=" d-flex flex-wrap align-items-center ">
                <div className="outlineBtn d-flex align-items-center" >
                  {headline && Object.keys(headline)?.length > 0 && Object.keys(headline).filter((item) => item).map((item, i) => (
                    <label
                      key={i}
                      htmlFor={`outline${i}`}
                      className={classNames("button d-flex align-items-center justify-content-center gap-2 cursor-pointer ", { active: i === showOutlineSets?.index })}
                      data-tooltip-id={i === showOutlineSets?.index ? "my-tooltip" : ''}
                      data-tooltip-place="bottom-start" data-tooltip-content={`Outline Set ${i + 1} is selected`}
                    >
                      <div className="radioType">
                        <input id={`outline${i}`} name="outline" type="radio"
                          checked={i === showOutlineSets?.index}
                          onChange={() => setShowOutlineSets({ index: i, item: headline[item], name: `Outline Set ${i + 1}` })}
                        />
                      </div>
                      <p>Outline Set {i + 1}</p>
                    </label>
                  ))}
                </div>
              </div>

              <div className="restoreBtn">
                <button
                  className="deleteBtn py-1" type="button" disabled={isResetDisabled}
                  onClick={() => !isResetDisabled ? handleResetHeadlineSet() : null}
                >
                  <BsArrowCounterclockwise className="" /> Reset
                </button>
              </div>
            </div>

            <ul className="list-unstyled setOfOutline" style={{ minHeight: "470px" }}>
              {showOutlineSets && showOutlineSets.item && (
                <DndProvider backend={HTML5Backend}>
                  <Outline
                    item={showOutlineSets.item} i={showOutlineSets.index} setIsResetDisabled={setIsResetDisabled}
                    allSelectedOutline={allSelectedOutline} setAllSelectedOutline={setAllSelectedOutline}
                    allOutlets={allOutlets} setAllOutlets={setAllOutlets} selectedOutlet={showOutlineSets}
                    moveOutlineListItem={moveOutlineListItem}
                  />
                </DndProvider>
              )}
            </ul>
          </div>
        </Col>
      </Row>
      <div className="w-100">
        <div className="nextPrewBtn d-flex justify-content-center gap-3">
          <button
            type="button" className="addlly-outline"
            onClick={() => {
              dispatch(setSeoOptBlogStep(5));
              const scrollWrapper = document.getElementById('scroll-wrapper');
              if (scrollWrapper) { scrollWrapper.scrollTo({ top: 0, behavior: 'smooth' }); }
            }}
          >
            <BsArrowLeft /> <span>Previous</span>
          </button>
          <div className="buttonWinFo d-flex gap-2 align-items-center ">
            <button
              type="button" onClick={() => handleNext()} className="addlly-primary"
              disabled={allSelectedOutline && allSelectedOutline.length > 0 ? false : true}
            >
              <span>Let’s Go!</span> <BsArrowRight />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FifthStepSeoOptBlog;

const Outline = ({ item, i, setIsResetDisabled, allSelectedOutline, setAllSelectedOutline, selectedOutlet, allOutlets, setAllOutlets, moveOutlineListItem }) => {
  const dispatch = useDispatch();
  const headline = useSelector(({ headline }) => headline);
  const headlineData = useSelector(({ headlineData }) => headlineData);
  const finalContent = useSelector(({ finalContent }) => finalContent);
  const [outlineVal, setOutlineVal] = useState([]);
  const [selectOutline, setSelectOutline] = useState([]);
  const [isShowSelectedData, setIsShowSelectedData] = useState(false);

  useEffect(() => {
    if (outlineVal) {
      setSelectOutline(outlineVal.map(() => false));
      const data = { ...headline };
      data[`Set-${i + 1}`] = outlineVal;
      dispatch(setHeadline(data));
    };
  }, [outlineVal]);

  useEffect(() => {
    setOutlineVal(item);
  }, [item]);

  useEffect(() => {
    setIsShowSelectedData(false);
    if (outlineVal && outlineVal.length > 0 && allSelectedOutline && allSelectedOutline.length > 0) {
      const getStr = outlineVal.find((ele1) => {
        const findIndex = allSelectedOutline.findIndex((ele2) => ele1 === ele2);
        if (findIndex !== -1) {
          return ele1;
        }
        return false;
      });
      if (getStr) {
        setIsShowSelectedData(true);
      }
    }
  }, [allSelectedOutline, outlineVal]);

  const handleSaveInputOutlineVal = (ind, addOutlineVal) => {
    const newArray = [...outlineVal];
    const oldVal = newArray[ind];

    if (addOutlineVal === oldVal) {
      dispatch(setFinalContent({ ...finalContent, allSelectedOutline }));
      return false;
    }

    setIsResetDisabled(false);
    newArray[ind] = addOutlineVal;
    setOutlineVal(newArray);

    const newArray2 = [...allSelectedOutline];
    const getIndex = newArray2.findIndex((val) => val === oldVal);
    newArray2[getIndex] = addOutlineVal;

    setAllSelectedOutline(newArray2);
    dispatch(setFinalContent({ ...finalContent, allSelectedOutline: newArray2 }));
  }

  return (
    <li key={i}>
      <div style={{ whiteSpace: "pre-line", textAlign: "justify" }}>
        <div className="mb-3 d-flex justify-content-between align-items-center">
          <div className="d-flex align-items-center justify-content-between w-100">
            <span className="d-flex align-items-center justify-content-center">
              <span className="outline-heading ">Outline Set </span>
              <BsInfoCircleFill className="infoIconSvg" data-tooltip-id={"my-tooltip"}
                data-tooltip-place="bottom-start" data-tooltip-content={`You can re-arrange or add new outline set`}
              />
            </span>
            {headlineData && headlineData.length < 3 && (
              <button className="AddOutline text-primary ms-3" onClick={() => {
                setOutlineVal(["", ...outlineVal]);
                dispatch(setHeadlineData([...headlineData, ""]));
                setIsResetDisabled(false);
              }}>
                + Add Outline
              </button>
            )}
          </div>
        </div>
        {isShowSelectedData && allSelectedOutline && allSelectedOutline.length > 0 && (
          <React.Fragment>
            <div className="outline-header-wrapper">Your Selection</div>
            {allSelectedOutline.map((it, ind) => (
              <OutlineList
                it={it} key={ind + ' ' + ind} handleSaveInputOutlineVal={handleSaveInputOutlineVal}
                outlineVal={outlineVal} setOutlineVal={setOutlineVal}
                selectOutline={selectOutline} setSelectOutline={setSelectOutline}
                allSelectedOutline={allSelectedOutline} setAllSelectedOutline={setAllSelectedOutline}
                setIsResetDisabled={setIsResetDisabled} selectedOutlet={selectedOutlet} allOutlets={allOutlets}
                setAllOutlets={setAllOutlets} isSelectedOutline={true} moveOutlineListItem={moveOutlineListItem}
              />
            ))}

            <div className="outline-line-wrapper"></div>
            <div className="outline-header-wrapper">Outline Headings</div>
          </React.Fragment>
        )}

        {outlineVal.map((it, ind) => (!allSelectedOutline?.includes(it) ? (
          <OutlineList
            it={it} key={ind + ' ' + ind} handleSaveInputOutlineVal={handleSaveInputOutlineVal}
            outlineVal={outlineVal} setOutlineVal={setOutlineVal}
            selectOutline={selectOutline} setSelectOutline={setSelectOutline}
            allSelectedOutline={allSelectedOutline} setAllSelectedOutline={setAllSelectedOutline}
            setIsResetDisabled={setIsResetDisabled} selectedOutlet={selectedOutlet} allOutlets={allOutlets}
            setAllOutlets={setAllOutlets} moveOutlineListItem={moveOutlineListItem}
          />
        ) : null))}
      </div>
    </li>
  );
};

const OutlineList = ({
  it, handleSaveInputOutlineVal, outlineVal, setOutlineVal, selectOutline, setSelectOutline, allSelectedOutline, setAllSelectedOutline,
  setIsResetDisabled, selectedOutlet, allOutlets, setAllOutlets, isSelectedOutline = false, moveOutlineListItem,
}) => {
  const dispatch = useDispatch();
  const headlineData = useSelector(({ headlineData }) => headlineData);
  const ind = outlineVal.findIndex((ele) => ele === it);
  const [addOutlineVal, setAddOutlineVal] = useState(it);
  const textAreaRef = useRef(null);

  const key = selectedOutlet?.name === 'Outline Set 2' ? 'outletTwo' : selectedOutlet?.name === 'Outline Set 3' ? 'outletThree' : 'outletOne';
  useEffect(() => {
    setAddOutlineVal(it);
  }, [it]);

  // useEffect(() => {
  //   // adjusting textarea size according to content size
  //   if (textAreaRef.current) {
  //     textAreaRef.current.style.height = "0px";
  //     const scrollHeight = textAreaRef.current.scrollHeight;
  //     textAreaRef.current.style.height = scrollHeight + "px";
  //   }
  // }, [addOutlineVal]);

  const checkValueExist = () => {
    for (let index = 0; index < Object.keys(allOutlets)?.length; index++) {
      if (Object.keys(allOutlets).includes(key)) {
        if (allOutlets[Object.keys(allOutlets).filter(i => i !== key)[index]]?.length) {
          return true;
        }
      }
    }
    return false;
  };

  const handleOnSelectOutline = (e) => {
    if (!addOutlineVal) {
      return toast.error("You can't choose empty outline.", { id: "Toast-01" });
    }

    if (outlineVal && outlineVal.length > 0 && checkValueExist()) {
      if (checkValueExist()) {
        Swal({
          title: "You can't choose outline from different outline set.",
          text: `Do you want to clear the selected item?`,
          icon: "warning", dangerMode: true, className: "warning-alert-popup",
          buttons: { cancel: "Cancel", confirm: `Yes, Clear it!` },
        }).then((isConfirmed) => {
          if (isConfirmed) {
            setSelectOutline(selectOutline.map((bool, j) => (j === ind ? true : bool)));
            const newAllOutlets = { outletOne: [], outletTwo: [], outletThree: [] };
            if (it === "") {
              newAllOutlets[key] = [...newAllOutlets[key], addOutlineVal];
              setAllSelectedOutline([addOutlineVal]);
            } else {
              newAllOutlets[key] = [...newAllOutlets[key], it];
              setAllSelectedOutline([it]);
            }
            setAllOutlets(newAllOutlets);
          }
        });

        return false;
      }
    }

    if (!allSelectedOutline?.includes(addOutlineVal || it)) {
      setSelectOutline(selectOutline.map((bool, j) => (j === ind ? true : bool)));
      const newAllOutlets = { ...allOutlets };
      if (it === "") {
        newAllOutlets[key] = [...newAllOutlets[key], addOutlineVal];
        setAllOutlets(newAllOutlets);
        setAllSelectedOutline((prevState) => [...prevState, addOutlineVal]);
      } else {
        newAllOutlets[key] = [...newAllOutlets[key], it];
        setAllOutlets(newAllOutlets);
        setAllSelectedOutline((prevState) => [...prevState, it]);
      }
    } else {
      const newAllOutlets = { ...allOutlets };
      setSelectOutline(selectOutline.map((bool, j) => (j === ind ? false : bool)));
      if (it === "") {
        const filteredOutlets = newAllOutlets[key]?.filter((item) => item !== addOutlineVal);
        setAllOutlets(pre => ({ ...pre, [key]: filteredOutlets }));
        const newArray = allSelectedOutline.filter((item) => item !== addOutlineVal);
        setAllSelectedOutline(newArray);
      } else {
        const filteredOutlets = newAllOutlets[key]?.filter((item) => item !== it);
        setAllOutlets(pre => ({ ...pre, [key]: filteredOutlets }));
        const newArray = allSelectedOutline.filter((item) => item !== it);
        setAllSelectedOutline(newArray);
      }
    }
  };

  const handleOnDeleteOutline = () => {
    setOutlineVal(outlineVal.filter((el, i) => i !== ind)); setIsResetDisabled(false);
    dispatch(setHeadlineData([...headlineData.slice(0, headlineData.length - 1)]));
    if (it === "") {
      const newArray = allSelectedOutline.filter((item) => item !== addOutlineVal);
      setAllSelectedOutline(newArray);
    } else {
      const newArray = allSelectedOutline.filter((item) => item !== it);
      setAllSelectedOutline(newArray);
    }
  };

  const ind2 = allSelectedOutline.findIndex((ele) => ele === it);
  const [{ isDragging }, dragRef] = useDrag({
    type: "item",
    item: { index: ind2 },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [spec, dropRef] = useDrop({
    accept: "item",
    hover: (item, monitor) => {
      moveOutlineListItem(item, monitor, ind2, ref);
    },
  });

  const ref = useRef(null);
  const dragDropRef = dragRef(dropRef(ref));

  const handleTextChange = (e) => {
    setAddOutlineVal(e.target.value);
  };

  return (
    <React.Fragment>
      {isSelectedOutline ? (
        <div key={ind} ref={dragDropRef}
          className={classNames("checkListItem py-2 flex-nowrap", { "active": allSelectedOutline.find((item1) => (item1 === it || item1 === addOutlineVal)) })}
        >
          <div className="d-flex align-items-center w-100">
            <input
              type="checkbox" className="custom-checkbox cursor-pointer" onChange={handleOnSelectOutline}
              checked={allSelectedOutline.find((item1) => (item1 === it || item1 === addOutlineVal)) ? true : false}
            />
            <textarea
              ref={textAreaRef} className="ms-3 addedOutline w-100 overflow-y-auto"
              onChange={(e) => { e.stopPropagation(); handleTextChange(e) }}
              onBlur={() => handleSaveInputOutlineVal(ind, addOutlineVal)}
              placeholder="Type here outline" value={addOutlineVal}
              onLoad={handleTextChange} style={{ height: '45px' }}
            />
          </div>
          <div className="d-flex align-items-center gap-3 ml-2">
            <button className="p-0 btn" onClick={handleOnDeleteOutline}>
              <BsTrash className="btn-trash" />
            </button>
            <BsList className="cursor-pointer" />
          </div>
        </div>
      ) : (
        <div key={ind} className={classNames("checkListItem py-2")}>
          <div className="d-flex align-items-center w-100">
            <input
              type="checkbox" className="custom-checkbox cursor-pointer" onChange={handleOnSelectOutline}
              checked={allSelectedOutline.find((item1) => (item1 === it || item1 === addOutlineVal)) ? true : false}
            />
            <textarea
              ref={textAreaRef} className="ms-3 addedOutline w-100 overflow-y-auto"
              onChange={(e) => { e.stopPropagation(); handleTextChange(e) }}
              onBlur={() => handleSaveInputOutlineVal(ind, addOutlineVal)}
              placeholder="Type here outline" style={{ height: '45px' }} value={addOutlineVal}
              onLoad={handleTextChange}
            />
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

const OutlineListItemComponent = ({ outline, index, moveOutlineListItem, handleClearSelectedOutline }) => {
  const [{ isDragging }, dragRef] = useDrag({
    type: "item",
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [spec, dropRef] = useDrop({
    accept: "item",
    hover: (item, monitor) => {
      moveOutlineListItem(item, monitor, index, ref);
    },
  });

  const ref = useRef(null);
  const dragDropRef = dragRef(dropRef(ref));

  return (
    <div className="mb-2" ref={dragDropRef}>
      <BsList />
      <p data-tooltip-id={"my-tooltip"} data-tooltip-place="bottom" data-tooltip-content={outline}>
        {outline}
      </p>
      <GrClose onClick={() => handleClearSelectedOutline(outline)} style={{ cursor: "pointer" }} />
    </div>
  )
};
