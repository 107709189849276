import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row } from "react-bootstrap";
import { FiPlusSquare } from "react-icons/fi";
import { BsArrowLeft, BsArrowRight, BsEyeFill, BsStars } from "react-icons/bs";
import { invokeAxiosPost } from "../../utility/invokeAxiosFunction";
import { setLoader, setECommerceBlogInfo, setECommerceBlogStep } from "../../store/reducer";
import ProductViewModal from "../ECommerceBlogWriter/ProductViewModal";

const SecondStepElixirEComBlog = () => {
  const dispatch = useDispatch();
  const userRes = useSelector(({ userRes }) => userRes);
  const eCommerceBlogInfo = useSelector(({ eCommerceBlogInfo }) => eCommerceBlogInfo);

  const [topicList, setTopicList] = useState(eCommerceBlogInfo?.allTopics || []);
  const [topicCountNo, setTopicCountNo] = useState(15);
  const [themeText, setThemeText] = useState(eCommerceBlogInfo?.theme_text || []);
  const [selectedTopics, setSelectedTopics] = useState([]);
  const [isDisabledInput, setIsDisabledInput] = useState(false);
  const [viewProduct, setViewProduct] = useState({ open: false, data: null });

  useEffect(() => {
    if (eCommerceBlogInfo && eCommerceBlogInfo.allTopics && eCommerceBlogInfo.allTopics.length > 0) {
      setTopicList(eCommerceBlogInfo.allTopics);
    }

    if (eCommerceBlogInfo && eCommerceBlogInfo.theme_text && eCommerceBlogInfo.theme_text !== "") {
      setThemeText(eCommerceBlogInfo.theme_text);
    }

    if (eCommerceBlogInfo && eCommerceBlogInfo.selectedSetTopics && eCommerceBlogInfo.selectedSetTopics !== "") {
      setSelectedTopics(eCommerceBlogInfo.selectedSetTopics);
      setIsDisabledInput(true);
    }
  }, [])

  const handleTopicSelection = (event, topic = null) => {
    if (!topic || topic === "") {
      return toast.error("Please insert a topic", { id: "Toast-01" });
    }

    if (selectedTopics && selectedTopics.length >= 3 && event.target.checked) {
      return toast.error("Select Up to three questions!", { id: "Toast-01" });
    }

    if (event.target.checked) {
      setSelectedTopics((prevState) => [...prevState, topic]);
    } else {
      const newArray = selectedTopics.filter((item) => item !== topic);
      setSelectedTopics(newArray);
    }
  };

  const handleChangeTopicValue = (value, index) => {
    const tempObj = [...topicList];
    const oldVal = tempObj[index];
    const newArray = selectedTopics.filter((item) => item !== value && item !== oldVal);
    tempObj[index] = value;

    setTopicList(tempObj);
    setSelectedTopics(newArray);
  };

  const handleRegenerateTopicSets = async () => {
    if (isDisabledInput || eCommerceBlogInfo.trendsRegenerateLeft === 0) {
      return false;
    }

    try {
      dispatch(setLoader(true));
      const payload = {
        articleId: eCommerceBlogInfo?.articleId ? eCommerceBlogInfo.articleId : "",
        isRegenerate: true, theme_text: themeText || "", user_id: userRes.id, selectedProduct: [{}],
        type: "generate-elixir-blog-trends",
      };

      const response = await invokeAxiosPost("/elixir-e-commerce/get-or-generate/generateElixirBlogTrends", payload);
      dispatch(setLoader(false));
      if (response && response.success === 1 && response.data && response.data.allTopics && response.data.allTopics.length > 0) {
        dispatch(setECommerceBlogStep(2)); setTopicList(response.data.allTopics); setSelectedTopics([]);
        dispatch(setECommerceBlogInfo({ ...eCommerceBlogInfo, ...response.data, stepDone: 2 }));
      } else {
        toast.error("Topics not generated, Please try again.", { id: "Toast-01" });
      }
    } catch (error) {
      dispatch(setLoader(false));
      toast.error(error?.response?.data?.error?.message || error?.response?.data?.error || "Server is overloaded, try again after sometime.", { id: "Toast-01" });
    }
  };

  const handleGenerateTopicOptimizationSet = async () => {
    try {
      if (eCommerceBlogInfo && eCommerceBlogInfo.topics && eCommerceBlogInfo.topics.length > 0) {
        dispatch(setECommerceBlogStep(3));
        return false;
      }

      dispatch(setLoader(true));
      const payload = {
        articleId: eCommerceBlogInfo.articleId, selectedTopics: selectedTopics,
        allTopics: topicList, type: "generate-elixir-blog-topics",
      };

      const response = await invokeAxiosPost("/elixir-e-commerce/get-or-generate/generateElixirBlogTopics", payload);
      dispatch(setLoader(false));
      if (response && response.success === 1 && response.data && response.data.topics && response.data.topics.length > 0) {
        setSelectedTopics([]); setIsDisabledInput(true); dispatch(setECommerceBlogStep(3));
        dispatch(setECommerceBlogInfo({
          ...eCommerceBlogInfo, ...response.data, stepDone: 3,
          selectedSetTopics: selectedTopics, allTopics: topicList,
        }));
        const scrollWrapper = document.getElementById('scroll-wrapper');
        if (scrollWrapper) { scrollWrapper.scrollTo({ top: 0, behavior: 'smooth' }); }
      } else {
        toast.error("Topic not generated, Please try again.", { id: "Toast-01" });
      }
    } catch (error) {
      dispatch(setLoader(false));
      toast.error(error?.response?.data?.error?.message || error?.response?.data?.error || "Server is overloaded, try again after sometime.", { id: "Toast-01" });
    }
  };

  return (
    <div className="eCommerce-blog-wrapper">
      <div className="selectBlock d-flex flex-wrap gap-3 align-items-center justify-content-between mx-2 mx-md-auto mw-100">
        <div>
          <b>Real-Time Trend Insights for Your Products</b>
        </div>
        <button
          type="button" onClick={() => handleGenerateTopicOptimizationSet()}
          disabled={selectedTopics.length > 0 && selectedTopics.length <= 3 ? false : true}
          className="d-flex align-items-center gap-2 bg-transparent border-0 selectBbutton"
        >
          Continue <BsArrowRight />
        </button>
      </div>
      <Row className="list-unstyled setOfOutline mw-100 mb-3 m-0">
        <Col sm="12" md="3" lg="3" className="side-menu px-0 border-end">
          <div className="sideBarInner">
            <div className="mb-4">
              <label>Theme ( Promotion text )</label>
              <h5 className="pe-3">{eCommerceBlogInfo?.promotionText || "-"}</h5>
            </div>
            <div className="d-flex align-items-center justify-content-between backButton">
              <label>Selected Product</label>
            </div>
            <div className="mt-3 backButton pe-3" style={{ maxHeight: "815px", overflow: "auto" }}>
              {eCommerceBlogInfo && eCommerceBlogInfo?.selectedProducts && eCommerceBlogInfo.selectedProducts?.map((selectedProduct) => (
                <div className={`image-wrapper d-flex align-items-center flex-column w-100 mb-3 border cursor-pointer`} key={selectedProduct?.id}>
                  <img src={selectedProduct?.image} alt="" />
                  <div className="card-product-info text-left pb-2">
                    <p>{selectedProduct?.name}</p>
                  </div>
                  <div className="card-button">
                    <div className="sub-div gap-2">
                      <button
                        type="button" className="btn bg-white text-primary" onClick={() => setViewProduct({ open: true, data: selectedProduct })}
                        data-tooltip-id={"my-tooltip"} data-tooltip-place="bottom" data-tooltip-content={`View Details`}
                      >
                        <BsEyeFill className='fs-5' />
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </Col>
        <Col sm="12" md="9" lg="9">
          <div className="d-flex flex-wrap align-items-end justify-content-start mb-2 pb-2 gap-3 border-bottom">
            <Col sm="12" md="6" className="addllyFormWrap mb-2">
              <div className="form-group fields">
                <label>Choose Your Questions ( You can insert your own questions )</label>
                <textarea
                  type="text" className="addllyForm-control addedOutline w-100 h-auto" placeholder="Insert your theme text here"
                  value={themeText} rows={2} onChange={(e) => setThemeText(e.target.value)} disabled={isDisabledInput}
                />
              </div>
            </Col>
            <button
              type="button" className={"addlly-primary mb-3"} style={{ padding: "13px 5px" }} onClick={handleRegenerateTopicSets}
              disabled={isDisabledInput || themeText === eCommerceBlogInfo?.theme_text || eCommerceBlogInfo.trendsRegenerateLeft === 0}
            >
              <BsStars /> Re-Generate ( {eCommerceBlogInfo.trendsRegenerateLeft || 0} / 3 )
            </button>
          </div>
          <div className="form-group fields">
            <label style={{ fontStyle: 'italic' }}>Select Up to three questions: Pick questions that are currently trending and relevant to your main keyword or product. This helps you target the right audience. Below, you'll find popular search queries to guide your selection. You can also add custom questions if needed.</label>
          </div>
          <Row className="pt-2">
            {topicList && topicList.length > 0 && (topicList.slice(0, topicCountNo).map((element, index) => (
              <Col sm="12" md="12" lg="6" xxl="4">
                <li className=" p-0 border-0 blockTopics" key={index}>
                  <div className={`checkListItem py-2 ${selectedTopics.find((item) => item === element) ? "active" : ""}`}>
                    <div className="d-flex align-items-center w-100">
                      <input
                        type="checkbox" className="custom-checkbox cursor-pointer"
                        onChange={(event) => handleTopicSelection(event, element)} disabled={isDisabledInput}
                        checked={selectedTopics.find((item) => item === element) ? true : false}
                      />
                      <textarea
                        type="text" className="ms-3 addedOutline w-100 overflow-auto" placeholder="Insert your topic here" value={element}
                        onChange={(e) => handleChangeTopicValue(e.target.value, index)} disabled={isDisabledInput}
                        rows={2} style={{ resize: "none" }}
                      />
                    </div>
                  </div>
                </li>
              </Col>
            )))}
            {topicCountNo < topicList.length && (
              <Col sm="12" className="d-flex justify-content-end mt-3">
                <button type="button" className="addlly-outline" onClick={() => setTopicCountNo((prev) => prev + 9)}>
                  <span><FiPlusSquare /> Show More</span>
                </button>
              </Col>
            )}
          </Row>
        </Col>
      </Row>

      <div className="d-flex align-items-start justify-content-center  align-items-center flex-row  gap-2 bottomButton">
        <button type="button" className="addlly-outline" onClick={() => { dispatch(setECommerceBlogStep(1)); }}        >
          <span><BsArrowLeft /> Previous</span>
        </button>
        <div className='d-flex align-items-center'>
          <button
            type="button" className="addlly-primary" onClick={() => handleGenerateTopicOptimizationSet()}
            disabled={selectedTopics.length > 0 && selectedTopics.length <= 3 ? false : true} style={{ whiteSpace: "nowrap" }}
          >
            <span>Continue</span> <BsArrowRight />
          </button>
        </div>
      </div>

      {viewProduct && viewProduct.open && (
        <ProductViewModal viewProduct={viewProduct} setViewProduct={setViewProduct} />
      )}
    </div>
  )
}

export default SecondStepElixirEComBlog;
