import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { HiPlus } from "react-icons/hi";
import { fetchStep4thData } from "../../utility/apiService";
import { setGoogleSuggestKeyword, setKeywordTopic, setLoader, setSelectedTopic, setSeoOptBlogStep, setSeoOptBlogTopic, updateKeywordTopic } from "../../store/reducer";
import { invokeAxiosPost } from "../../utility/invokeAxiosFunction";
import { BsInfoCircleFill } from "react-icons/bs";
import { BsArrowLeft } from "react-icons/bs";
import { BsArrowRight } from "react-icons/bs";
import classNames from "classnames";

const ThirdStepSeoOptBlog = () => {
  const dispatch = useDispatch();

  const keywordTopic = useSelector(({ keywordTopic }) => keywordTopic);
  const selectedTopic = useSelector(({ selectedTopic }) => selectedTopic);
  const selectedGoogleWebsite = useSelector(({ selectedGoogleWebsite }) => selectedGoogleWebsite);
  const seoOptBlogTopic = useSelector(({ seoOptBlogTopic }) => seoOptBlogTopic);
  const setsOfSeoOptimized = useSelector(({ setsOfSeoOptimized }) => setsOfSeoOptimized);

  const [checkVal, setCheckVal] = useState(false);
  const [isAddedKeyword, setIsAddedKeyword] = useState(false);
  const [selectGoogleKeyword, setSelectGoogleKeyword] = useState(undefined);
  const [isDisabledInput, setIsDisabledInput] = useState(false);
  const [oldGeneratedKeyword, setOldGeneratedKeyword] = useState(keywordTopic || []);
  const [bahasaText, setBahasaText] = useState("");

  useEffect(() => {
    if (keywordTopic && keywordTopic.length > 0) {
      const findIndex = keywordTopic.findIndex((item) => item?.isSelected === true);
      setSelectGoogleKeyword(findIndex >= 0 ? findIndex : undefined);

      const findIndex1 = keywordTopic.findIndex((item) => item?.added === "true");
      setIsAddedKeyword(findIndex1 >= 0 ? true : false);
    }

    if (setsOfSeoOptimized && setsOfSeoOptimized.seoOptimizedData && setsOfSeoOptimized.seoOptimizedData.length > 0) {
      setIsDisabledInput(true);
    }

    handleTranslateTextEngToBahasa();
  }, [keywordTopic, setsOfSeoOptimized])

  const handleNext = async () => {
    if ((selectGoogleKeyword >= 0 && selectGoogleKeyword !== undefined) || (seoOptBlogTopic && seoOptBlogTopic.generateLink === 0)) {
      const keywordObj = keywordTopic.map((item, index) => {
        let tempItem = { ...item };
        if (oldGeneratedKeyword.findIndex((ele) => ele.keyword === item.keyword) === -1) {
          const { cpc, volume, ...itemRest } = tempItem;
          tempItem = { ...itemRest };
        }

        if (index === selectGoogleKeyword) {
          return Object.assign({}, tempItem, { isSelected: true });
        } else {
          const { isSelected, ...itemRest } = tempItem;
          return itemRest;
        }
      });
      dispatch(setKeywordTopic(keywordObj));

      if (setsOfSeoOptimized && setsOfSeoOptimized.seoOptimizedData && setsOfSeoOptimized.seoOptimizedData.length > 0) {
        dispatch(setSeoOptBlogStep(5));
        return false;
      }

      const selectedKeyword = (selectGoogleKeyword >= 0) ? keywordTopic[selectGoogleKeyword]?.keyword : seoOptBlogTopic?.keyword ? seoOptBlogTopic.keyword : selectedTopic;
      dispatch(setGoogleSuggestKeyword(selectedKeyword));
      const response = await dispatch(fetchStep4thData(selectedTopic, selectedKeyword, selectedGoogleWebsite, seoOptBlogTopic.articleId, keywordObj));
      if (response?.data && response.data.seoOptimizedData && response.data.seoOptimizedData.length > 0) {
        dispatch(setSeoOptBlogTopic({ ...seoOptBlogTopic, stepDone: seoOptBlogTopic.stepDone < 5 ? 5 : seoOptBlogTopic.stepDone }));
        dispatch(setSeoOptBlogStep(5));
      } else {
        toast.error("Headline set not generated, Please try again.", { id: "Toast-01" });
      }
    } else {
      toast.error("Select any one keyword to generate headlines for SEO optimization.", { id: "Toast-01" });
    }
  };

  const regenerateKeywords = async () => {
    dispatch(setLoader(true));
    const payload = {
      queryStringParameters: {
        articleId: seoOptBlogTopic.articleId,
        topic: seoOptBlogTopic?.keyword ? seoOptBlogTopic.keyword : selectedTopic,
        geoLocation: seoOptBlogTopic.geoLocation,
        isRegenerate: true,
      }
    };
    const response = await invokeAxiosPost("/seo-blog-co-pilot/get-or-generate/getKeywords", payload);
    dispatch(setLoader(false));
    if (response && response.success === 1) {
      dispatch(setKeywordTopic(response.data));
      setOldGeneratedKeyword(response.data);
      setIsAddedKeyword(false);
      setSelectGoogleKeyword(undefined);
    } else {
      toast.error(response?.error || "Server is overloaded, try again after sometime.", { id: "Toast-01" });
    };
  };

  const handleKeywordSelection = (index, keyword = null) => {
    setSelectGoogleKeyword(index);
    if (!keyword || keyword === "") {
      toast.error("Please insert a keyword", { id: "Toast-01" });
      setSelectGoogleKeyword(undefined);
    }
  };

  const handleAddNewKeyword = () => {
    const obj = [{ "keyword": "", "added": "true" }, ...keywordTopic];
    setIsAddedKeyword(true);
    dispatch(setKeywordTopic(obj));
    setSelectGoogleKeyword(undefined);
  }

  const handleTranslateTextEngToBahasa = async () => {
    if (selectedTopic && selectedTopic !== "" && seoOptBlogTopic && seoOptBlogTopic?.lan === "Bahasa Indonesia") {
      setBahasaText(bahasaText ? `${bahasaText} ...` : "Translating ...");
      invokeAxiosPost(`/seo-blog-co-pilot/get-or-generate/generateHeadline`, {
        textTranslate: selectedTopic,
      }).then((res) => {
        if (res.data && res.data.text && res.data.text !== "") {
          setBahasaText(res.data.text);
        } else {
          setBahasaText("");
        }
      }).catch((error) => {
        setBahasaText("");
      });
    } else {
      setBahasaText("");
    }
  };

  return (
    <React.Fragment>
      <div className="addllyFormWrap topicLinks">
        <div className="selectBlock seoselected mw-100 m-0 mb-3">
          <h5>Select Keyword (Please update the heading later)</h5>
        </div>
        {(seoOptBlogTopic.blog_type === "Summary" || seoOptBlogTopic.blog_type === "Pdf") ? (
          <React.Fragment>
            <div className="form-group mb-3">
              <label>Generated Topic</label>
              <input
                name="topic" type="text" value={selectedTopic} disabled={isDisabledInput}
                className={`addllyForm-control addllyForm-control-outline mw-100`}
                onChange={(e) => {
                  if (!isDisabledInput) {
                    setCheckVal(true); dispatch(setSelectedTopic(e.target.value));
                  }
                }}
              />
            </div>
            <div className="form-group">
              <label>Generated Keyword</label>
              <div className="d-flex align-items-center">
                <input
                  name="topic" type="text" defaultValue={seoOptBlogTopic?.keyword} disabled={isDisabledInput}
                  className={`addllyForm-control addllyForm-control-outline`}
                  onBlur={(e) => {
                    if (!isDisabledInput) {
                      dispatch(setSeoOptBlogTopic({ ...seoOptBlogTopic, keyword: e.target.value }))
                    }
                  }}
                />
                {seoOptBlogTopic && seoOptBlogTopic.generateLink === 1 && (
                  <button
                    disabled={!checkVal} onClick={() => { if (checkVal && !isDisabledInput) { regenerateKeywords() } }}
                    className="addlly-primary " type="button" style={{ whiteSpace: "nowrap" }}
                  >
                    Re-Generate Keywords
                  </button>
                )}
              </div>
            </div>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <div className="form-group mb-3">
              <label>Your topic
                <BsInfoCircleFill
                  className="ms-2 infoIconSvg" data-tooltip-id={"my-tooltip"}
                  data-tooltip-place="right" data-tooltip-content={`Edit topic to re-generate topic`}
                />
              </label>
              <div className="d-flex flex-wrap align-items-center gap-1">
                <input
                  name="topic" type="text" value={selectedTopic} disabled={isDisabledInput}
                  className={`addllyForm-control addllyForm-control-outline`}
                  onBlur={handleTranslateTextEngToBahasa}
                  style={{ minWidth: '100px' }}
                  onChange={(e) => {
                    if (!isDisabledInput) {
                      setCheckVal(true); dispatch(setSelectedTopic(e.target.value));
                    }
                  }}
                />
                <button
                  disabled={!checkVal} onClick={() => { if (checkVal && !isDisabledInput) { regenerateKeywords() } }}
                  className="addlly-primary" type="button" style={{ whiteSpace: "nowrap", margin: 0 }}
                >
                  Re-Generate Keywords
                </button>
              </div>
            </div>
            {seoOptBlogTopic && seoOptBlogTopic?.lan === "Bahasa Indonesia" && bahasaText && bahasaText !== "" && (
              <div className="form-group">
                <label>Translated Topic To Bahasa Indonesia</label>
                <input
                  name="topic" type="text" disabled placeholder=""
                  className={`addllyForm-control`} value={bahasaText}
                />
              </div>
            )}
          </React.Fragment>
        )}
      </div>
      {seoOptBlogTopic && seoOptBlogTopic.generateLink === 1 && (
        <div className="googleSearchResult">
          <div className="addUrlBar d-flex align-items-center justify-content-between mb-3">
            <p className=""> Keyword Suggestions
              <BsInfoCircleFill className="ms-2 infoIconSvg" data-tooltip-id={"my-tooltip"}
                data-tooltip-place="bottom-start" data-tooltip-content={`You can select upto only 1 keyword`}
              />
            </p>
            {keywordTopic && keywordTopic.length > 0 && !isAddedKeyword && !isDisabledInput && (
              <p className="d-flex align-items-center text-primary cursor-pointer" onClick={handleAddNewKeyword} onKeyDown={handleAddNewKeyword}>
                <HiPlus className="me-2" /> Add Keyword
              </p>
            )}
          </div>
          <ul className="seoOptBlogTopic addllyFormWrap">
            {keywordTopic && keywordTopic.length > 0 ? (keywordTopic.slice(0, (isAddedKeyword ? 6 : 5))?.map((item, index) => (
              <li key={index} className={classNames("suggestionBox", { active: selectGoogleKeyword === index })} >
                <div className="keyWordSuggestion d-flex justify-content-between align-items-center">
                  <div className="suggestionInput d-flex align-items-center gap-2">
                    <div className="radioType">
                      <input type="radio" checked={selectGoogleKeyword === index} disabled={isDisabledInput}
                        onChange={(e) => e.target.checked ? handleKeywordSelection(index, item.keyword) : handleKeywordSelection(null, item.keyword)}
                      />
                    </div>
                    <div className="keywordInput w-100">
                      <input
                        type="text" placeholder="Insert your keyword here" disabled={isDisabledInput}
                        className={`addllyForm-control ${selectGoogleKeyword !== index && "listInput"}`}
                        value={item.keyword} onChange={(e) => dispatch(updateKeywordTopic({ index, txt: e.target.value }))}
                      />
                    </div>
                  </div>
                  {item.keyword && (oldGeneratedKeyword.findIndex((ele) => ele.keyword === item.keyword) !== -1) && (
                    <div className="keyWVolum">
                      <div className="innerKeyVBlock d-flex align-items-center mb-2 ">
                        <div className="keyText">
                          <p>CPC</p>
                        </div>
                        {item.cpc && (
                          <div className="valumPrice">
                            <strong>{item.cpc}</strong>
                          </div>
                        )}
                      </div>
                      <div className="innerKeyVBlock d-flex align-items-center  ">
                        <div className="keyText">
                          <p>Keyword’s volume</p>
                        </div>
                        {item.volume && (
                          <div className="valumPrice">
                            <strong>{item.volume}</strong>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </li>
            ))) : (
              <li className="text-center my-5">
                {/* <h5 className="mb-3 fw-normal">
                  Keyword suggestions not available. Add your keyword otherwise skip ahead. <br />
                  If you skip this step after your topic will be considered as a keyword.
                </h5> */}
                {!isAddedKeyword && (
                  <React.Fragment>
                    <h5 className="mb-3 fw-normal">
                      Keyword suggestions not available. Add your keyword. <br />
                      Otherwise change the topic and regenerate keywords.
                    </h5>
                    <button
                      type="button" onClick={handleAddNewKeyword} style={{ maxWidth: "fit-content" }}
                      className="addlly-outline btn-sm py-2 px-3 fs-6 mx-auto"
                    >
                      <HiPlus /> <span>Add Keyword</span>
                    </button>
                  </React.Fragment>
                )}
              </li>
            )}
          </ul>
        </div>
      )}

      <div className="w-100">
        <div className="nextPrewBtn d-flex justify-content-center gap-3">
          <button
            type="button" className="addlly-outline"
            onClick={() => {
              const scrollWrapper = document.getElementById('scroll-wrapper');
              if (scrollWrapper) { scrollWrapper.scrollTo({ top: 0, behavior: 'smooth' }); }
              if (seoOptBlogTopic && seoOptBlogTopic.generateLink === 1) {
                dispatch(setSeoOptBlogStep(3));
              } else {
                dispatch(setSeoOptBlogStep(2));
              }
            }}
          >
            <BsArrowLeft /> <span>Previous</span>
          </button>
          <div className="buttonWinFo d-flex gap-2 align-items-center ">
            <button
              type="button" onClick={() => handleNext()} className="addlly-primary"
            // disabled={selectGoogleKeyword >= 0 && selectGoogleKeyword !== undefined ? false : true}
            >
              <span>Optimise for SEO</span> <BsArrowRight />
            </button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ThirdStepSeoOptBlog;
