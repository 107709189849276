import React from 'react';

export default function Heading({ size, className, children }) {
    const headingClasses = `${className || ''} text-start`;
    const type = `h${size || 1}`
    switch (type) {
        case 'h6':
            return <h6 className={headingClasses}>{children}</h6>;
        case 'h5':
            return <h5 className={headingClasses}>{children}</h5>;
        case 'h4':
            return <h4 className={headingClasses}>{children}</h4>;
        case 'h3':
            return <h3 className={headingClasses}>{children}</h3>;
        case 'h2':
            return <h2 className={headingClasses}>{children}</h2>;
        default:
            return <h1 className={headingClasses}>{children}</h1>;
    }
};
