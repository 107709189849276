import React, { useEffect } from "react";
import { Modal } from "react-bootstrap";
import { GrAnnounce } from "react-icons/gr";
import { BsPencilSquare, BsSearch } from "react-icons/bs";
import { navigationsOption } from "../data";
import Navigations from "../Components/dashboard/Navigations";
import PinnedFeatures from "../Components/dashboard/PinnedFeatures";
import TopFeatures from "../Components/dashboard/TopFeatures";
import RecentArticles from "../Components/dashboard/RecentArticles";
import withDashboardHandler from "../Hoc/DashboardHoc";
import AddllyLogo from "../Assets/Images/AddllyLogo.png";

const Dashboard = (props) => {
  const {
    handleNavigate, handleClose, showMobileModal, setSearchFilter, userRes, filteredFeaturesData, pinnedFeaturesData,
    filteredPinnedFeatures, handlePinnedFeature, handleRemovePinnedFeature, isLoadingCards
  } = props;

  useEffect(() => {
    if (filteredFeaturesData && filteredFeaturesData.length > 0 && !isLoadingCards) {
      window.onload = function () { applyBackground() };
      window.onresize = applyBackground;
      applyBackground();
    }
  }, [filteredFeaturesData, isLoadingCards]);

  function applyBackground() {
    const classNameArray = ["topCardBlock", "pinnedCardBlock"];
    for (let index = 0; index < classNameArray.length; index++) {
      const className = classNameArray[index];
      if (document.querySelector(`.${className}`)) {
        const divWidth = document.querySelector(`.${className} > div`).clientWidth;
        const containerWidth = document.querySelector(`.${className}`).clientWidth;
        const divPerRow = Math.floor(containerWidth / divWidth);
        const div = document.querySelectorAll(`.${className} > div`);
        for (let i = 0; i < div.length; i++) {
          div[i].classList = "card position-relative text-start cursor highlight-3";
        }
        for (let i = 0; i < divPerRow; i++) {
          if (div[i] && div[i].classList) {
            div[i].classList = "card position-relative text-start cursor highlight-1";
          }
          if (div[i + divPerRow] && div[i + divPerRow].classList) {
            div[i + divPerRow].classList = "card position-relative text-start cursor highlight-2";
          }
        }
      }
    }
  }

  return (
    <React.Fragment>
      <div className="dashboard unselectable">
        <div className="header-menu align-items-center d-flex mt-0 mb-5">
          <div className="d-flex align-items-center justify-content-end main-header w-100 justify-content-center pt-0">
            <div className="notificationBlock rounded-3 w-100 shadow-sm p-4" style={{ background: "rgb(10, 11, 92)" }}>
              <div className="d-flex align-items-center gap-3 mb-3">
                <div className="d-flex align-items-center justify-content-center bg-white" style={{ width: "48px", height: "48px", borderRadius: "50px" }}>
                  <GrAnnounce className="fs-3" color="#00f" />
                </div>
                <h3 className="text-white fw-bold m-0">Welcome to <span>Addlly AI!</span></h3>
              </div>
              <h4 className="m-0 fw-normal text-white">
                While we build your custom AI playground, you can explore the standard version of our platform here and get familiar with different features for a sense of what’s possible.
                <br /> Your custom AI playground will be ready soon, but feel free to reach out to <a href="mailto:support@addlly.ai" className="text-white">support@addlly.ai</a> should you have any queries.
              </h4>
            </div>
          </div>
        </div>

        <div className="useNameBlock d-flex justify-content-between align-items-center mb-4">
          <h3>Welcome, <span className="ms-2">{userRes?.first_name} 🎉</span></h3>
          {userRes.company !== "azgo" && (
            <div className="searchBar d-flex justify-content-between align-items-center w-50 ">
              <div className="searchField position-relative">
                <input type="search" placeholder="Search by title or content" onChange={(e) => setSearchFilter(e.target.value)} />
                <div className="searchIcon position-absolute">
                  <BsSearch />
                </div>
              </div>
              <Navigations
                navigationsOption={navigationsOption}
                button={<button className="rounded-circle p-0"><BsPencilSquare /></button>}
              />
            </div>
          )}
        </div>

        <div className="features-section">
          <PinnedFeatures isLoadingCards={isLoadingCards} handleNavigate={handleNavigate} filteredPinnedFeatures={filteredPinnedFeatures} handlePinnedFeature={handlePinnedFeature} handleRemovePinnedFeature={handleRemovePinnedFeature} />
          <TopFeatures isLoadingCards={isLoadingCards} handleNavigate={handleNavigate} filteredFeaturesData={filteredFeaturesData} pinnedFeaturesData={pinnedFeaturesData} handlePinnedFeature={handlePinnedFeature} handleRemovePinnedFeature={handleRemovePinnedFeature} />
        </div>

        {userRes.company !== "azgo" && <div className="article-history">
          <RecentArticles />
        </div>}
      </div>

      {showMobileModal && (
        <Modal className="note-modal" centered show={showMobileModal} onHide={handleClose}>
          <Modal.Body className="text-center p-4">
            <div className="h-100 text-start my-3 mb-4">
              <img src={AddllyLogo} alt="addlly logo" />
            </div>
            <div className="text-start">
              <h3 className="fw-normal mb-4">
                Addlly AI is not yet optimized for mobile devices. We apologies for any inconveniences.
              </h3>
            </div>
            <div className="d-flex align-items-center justify-content-end mt-3">
              <button onClick={handleClose} type="button" className="addlly-primary ms-3">
                <span>Close</span>
              </button>
            </div>
          </Modal.Body>
        </Modal>
      )}
    </React.Fragment>
  );
};

export default withDashboardHandler(Dashboard);
