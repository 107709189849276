import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { Buffer } from 'buffer';
import Swal from "sweetalert";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Modal, Tab, Tabs } from "react-bootstrap";
import { toast } from "react-hot-toast";
import { FaEye, FaRegSave } from "react-icons/fa";
import { ReactSVG } from "react-svg";
import { FaLinkedin } from "react-icons/fa6";
import { LuCheckCircle } from "react-icons/lu";
import { MdModelTraining } from "react-icons/md";
import { BsArrowLeft, BsArrowRight, BsPersonCheck } from "react-icons/bs";
import { setLoader, setSocialMediaPostStep, setSocialMediaStepsInfo, setUserRes } from "../../store/reducer";
import GoDashboard from "../Modal/GoDashboard";
import { invokeAxiosGet, invokeAxiosPost } from "../../utility/invokeAxiosFunction";
import ReviewTextEditor from "../commonComponents/ReviewTextEditor/Index";
import TwitterPostPreview from "../commonComponents/TwitterPostPreview";
import FacebookPostPreview from "../commonComponents/FacebookPostPreview";
import InstagramPostPreview from "../commonComponents/InstagramPostPreview";
import LinkedinPostPreview from "../commonComponents/LinkedinPostPreview";
import MediaLibraryDrawer from "../commonComponents/MediaLibrary/MediaLibraryDrawer";
import VersionHistory from "../commonComponents/VersionHistory";
import copyIcon from "../../Assets/Icons/copySvg.svg";
import { handleShareSocialMediaTypePost } from "../../utility/common";

const formFieldArray = {
  "Twitter": [
    { name: "text", label: "Enter Any One Tweet", placeholder: "Enter your tweet here" },
  ],
}

const SixthStepSocialMediaPost = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const socialMediaPostInfo = useSelector(({ socialMediaPostInfo }) => socialMediaPostInfo);
  const userRes = useSelector(({ userRes }) => userRes);
  const [editFinalPost, setEditFinalPost] = useState(socialMediaPostInfo?.final_post);
  const [savedModal, setSavedModal] = useState(false);
  const [inputModal, setInputModal] = useState(false);
  const [inputs, setInputs] = useState({ text: "" });
  const [errors, setErrors] = useState({});
  const [showPdfModal, setShowPdfModal] = useState(false);
  const [uploadFileModal, setUploadFileModal] = useState({ open: false, image: "", currentTab: "AI Generated Image" });
  const [aiGeneratedImages, setAiGeneratedImages] = useState({});
  const [pdfBase64, setPdfBase64] = useState("");
  const [versionHistory, setVersionHistory] = useState([]);
  const [selectedVersionItem, setSelectedVersionItem] = useState({});
  const [selectedCurrentTab, setSelectedCurrentTab] = useState(searchParams && searchParams.get('tab') ? searchParams.get('tab') : "finalPreview");
  const [selectedSubTab, setSelectedSubTab] = useState(searchParams && searchParams.get('subTab') ? searchParams.get('subTab') : "postPreview");
  const [showLength, setShowLength] = useState(0);
  const articleType = "Social Media Post Writer";

  useEffect(() => {
    if (socialMediaPostInfo && socialMediaPostInfo.PostType && socialMediaPostInfo.PostType !== "" && socialMediaPostInfo.postId) {
      getAiGeneratedPostImages();
    }
  }, [socialMediaPostInfo.PostType]);

  useEffect(() => {
    if (selectedCurrentTab && selectedCurrentTab === "finalPreview") {
      setSelectedVersionItem({});
      getArticleAllVersionHistory(`${socialMediaPostInfo.PostType} Post`);
    };
  }, [selectedCurrentTab, selectedSubTab]);

  useEffect(() => {
    if (socialMediaPostInfo && socialMediaPostInfo.pdf_link && pdfBase64 === "") {
      axios.get(socialMediaPostInfo.pdf_link, { responseType: 'arraybuffer' }).then((pdf) => {
        if (pdf && pdf.data) {
          setPdfBase64(`data:application/pdf;base64,${Buffer.from(pdf.data).toString("base64")}`);
        }
      }).catch((error) => {
        console.log('Error : ', error);
      });
    }
  }, [])

  const getAiGeneratedPostImages = async () => {
    try {
      const subType = `${socialMediaPostInfo.PostType} Post`;
      setAiGeneratedImages((prev) => ({ ...prev, subType }));
      setUploadFileModal((prev) => ({
        ...prev, open: false, type: articleType, subType,
        imageHistoryLoader: true, pexelsGenerating: true, aiImageGenerating: true
      }));

      const payload = {
        type: articleType, subType, userId: userRes.id,
        html: editFinalPost, // socialMediaPostInfo?.topic ? socialMediaPostInfo.topic : editFinalPost,
        articleId: socialMediaPostInfo.postId,
      }
      setUploadFileModal((prev) => ({ ...prev, apiParams: payload }));
      // Get uploaded images
      const res_1 = await invokeAxiosGet(`/media-library/get-or-generate/getUploadedImagesList?userId=${userRes.id}&type=${articleType}`);
      setUploadFileModal((prev) => ({ ...prev, imageHistoryLoader: false }));
      if (res_1 && res_1.data && res_1.data.images && res_1.data.images.length > 0) {
        setAiGeneratedImages((prev) => ({ ...prev, imageHistory: res_1.data.images }));
      }

      // Get uploaded product images Or generated background images
      const queryParams = `type=${articleType}&subType=${subType}&userId=${userRes.id}&articleId=${socialMediaPostInfo.postId}`;
      const res_4 = await invokeAxiosGet(`/product-image-library/generate-or-save/getGeneratedProductAndBackgroundImages?${queryParams}`);
      if (res_4 && res_4.data && res_4.data.productImg && res_4.data.backgroundImages) {
        setAiGeneratedImages((prev) => ({ ...prev, ...res_4.data }));
      }

      if (socialMediaPostInfo && (socialMediaPostInfo.aiGeneratedImages)) {
        setAiGeneratedImages((prev) => ({ ...socialMediaPostInfo.aiGeneratedImages, ...prev }));
        setUploadFileModal((prev) => ({ ...prev, imageHistoryLoader: false, pexelsGenerating: false, aiImageGenerating: false }));
        return false;
      }

      // Generate or get stability ai images
      const res_2 = await invokeAxiosPost("/media-library/get-or-generate/getAndGenerateStabilityAiImages", payload);
      setUploadFileModal((prev) => ({ ...prev, aiImageGenerating: false }));
      if (res_2 && res_2.success === 1 && res_2.data && res_2.data.images && res_2.data.images.length > 0) {
        setAiGeneratedImages((prev) => ({
          ...prev, openAiImages: res_2.data.images, openAiImgRegenLeft: res_2.data.regenerateLeft,
          openAiImagesVersions: res_2.data?.oldImages?.length ? res_2.data?.oldImages : []
        }));
      }

      // Generate or get pexels and pixabay images
      const res_3 = await invokeAxiosPost("/media-library/get-or-generate/getAndGeneratePexelsAndPixabayImages", payload);
      setUploadFileModal((prev) => ({ ...prev, pexelsGenerating: false }));
      if (res_3 && res_3.success === 1 && res_3.data && res_3.data.images && res_3.data.images.length > 0) {
        setAiGeneratedImages((prev) => ({
          ...prev, pexelImages: res_3.data.images,
          pixabayImages: res_3.data.pixabayImages,
          unsplashImages: res_3.data.unsplashImages,
        }));
      }

      setAiGeneratedImages((prev) => {
        dispatch(setSocialMediaStepsInfo({ ...socialMediaPostInfo, aiGeneratedImages: prev }));
        return prev;
      });
      setUploadFileModal((prev) => ({ ...prev, imageHistoryLoader: false, pexelsGenerating: false, aiImageGenerating: false }));
    } catch (error) {
      setUploadFileModal((prev) => ({ ...prev, imageHistoryLoader: false, pexelsGenerating: false, aiImageGenerating: false }));
      console.log('Error : ', error);
    }
  }

  const getArticleAllVersionHistory = async (versionSubType) => {
    try {
      if (!socialMediaPostInfo.postId || !versionSubType) {
        return false;
      }

      setVersionHistory([]);
      const params = `articleId=${socialMediaPostInfo.postId}&type=${articleType}&subType=${versionSubType}&isVersionList=true`;
      const res = await invokeAxiosGet(`/analytics-api/get-summary/getPreviousGeneratedContent?userId=${userRes.id}&${params}`);
      if (res && res.data && res.data.data && res.data.data.length > 0) {
        setVersionHistory(res?.data?.data);
      }
      dispatch(setLoader(false));
    } catch (error) {
      dispatch(setLoader(false));
    }
  }

  const handleSaveClose = () => setSavedModal(false);
  const handleInputModalClose = () => {
    setInputModal(false);
    setInputs({ text: "" });
    setErrors({});
  }

  const handleOnInputChangeValue = (e) => {
    const { name, value } = e.target;
    setInputs((prev) => ({ ...prev, [name]: value }));
    setErrors((prev) => ({ ...prev, [name]: null }));
  }

  const handleSaveFinalPostData = async () => {
    dispatch(setLoader(true));
    setSelectedSubTab("");
    const currentSelectedTab = selectedSubTab && selectedSubTab !== "" ? selectedSubTab : "";

    invokeAxiosPost(`/social-media-post/get-or-generate/saveSocialMediaGeneratedFinalPost`, {
      postId: socialMediaPostInfo?.postId,
      finalPost: editFinalPost,
    }).then((response) => {
      dispatch(setLoader(false));
      setSelectedSubTab(currentSelectedTab);
      dispatch(setSocialMediaStepsInfo({ ...socialMediaPostInfo, final_post: editFinalPost, stepDone: 7 }));
      setSavedModal(true);
    }).catch((error) => {
      dispatch(setLoader(false));
      setSelectedSubTab(currentSelectedTab);
      toast.error(error?.response?.data?.error?.message || error?.response?.data?.error || "Server is overloaded, try again after sometime.", { id: "Toast-01" });
    });
  }

  const handleValidateForm = () => {
    let isValidate = true;
    let errorObj = {};

    if (socialMediaPostInfo.PostType === "Twitter") {
      if (inputs.text && inputs.text.length > 280) {
        errorObj = { ...errorObj, text: { message: "Tweet must not be greater than 280 character." } };
        isValidate = false;
      } else if (inputs.text.length === 0) {
        errorObj = { ...errorObj, text: { message: "Tweet is required." } };
        isValidate = false;
      }
    }
    setErrors(errorObj);
    return isValidate;
  }

  const handleShareSocialMediaPost = async () => {
    if (socialMediaPostInfo.PostType === "LinkedIn" || socialMediaPostInfo.PostType === "Instagram" || socialMediaPostInfo.PostType === "Twitter") {
      if (socialMediaPostInfo.PostType === "LinkedIn") {
        if (editFinalPost && editFinalPost.length > 3000) {
          toast.error("Linkedin post must not be greater than 3000 character.");
          setShowLength(10);
          return false;
        }
      }

      const isValidForm = await handleValidateForm();
      if (!isValidForm) {
        return false;
      }

      let uploadImage = "";
      if (typeof socialMediaPostInfo.post_image.data === typeof undefined) {
        uploadImage = "";
      } else {
        uploadImage = JSON.stringify(socialMediaPostInfo?.post_image);
      }

      try {
        setErrors({});
        dispatch(setLoader(true));
        const payload = {
          text: socialMediaPostInfo.PostType === "Twitter" ? inputs.text : editFinalPost,
          keyword: socialMediaPostInfo.keyword,
          postType: socialMediaPostInfo.PostType,
          postId: socialMediaPostInfo.postId,
          image: uploadImage,
          userId: userRes.id,
        };

        const response = await invokeAxiosPost("/social-media-post/get-or-generate/socialMediaPostShare", payload);
        dispatch(setLoader(false));
        if (response && response.success === 1) {
          toast.success("Post share successfully.", { id: "Toast-01" });
          handleInputModalClose();
        } else {
          toast.error(response?.error || "Something went wrong.", { id: "Toast-01" });
          if (response?.error.indexOf("Account not linked") !== -1 || response?.error.indexOf("token has expired") !== -1) {
            navigate("/account/connections");
          }
        }
      } catch (error) {
        dispatch(setLoader(false));
        toast.error(error?.response?.data?.error?.message || error?.response?.data?.error || "Access token is not valid.", { id: "Toast-01" });
      }
    } else if (socialMediaPostInfo.PostType === "Facebook") {

      // Access the selected sharing option
      const selectedOption = fbInputs.sharingOption;
      // Do something with the selectedOption value

      if (selectedOption?.toLowerCase() === "Share Now"?.toLowerCase()) {
        let url;

        invokeAxiosPost(`/user-api/get-or-create/userSocialAccounts`, {
          userId: userRes.id
        }).then(async (response) => {

          /* const meta_client_id = response.data[0].meta_client_id;
          const meta_client_secret = response.data[0].meta_client_secret;
          const access_token = response.data[0].access_token;
          const token_expires_on = response.data[0].token_expires_on;
           
           //toast.success("Page Id is " + meta_page_id, { id: "Toast-01" }); */
          const meta_page_access_token = response.data[0].meta_page_access_token;
          const meta_page_id = response.data[0].meta_page_id;

          if (meta_page_id === "" || meta_page_access_token === "") {
            toast.error("Facebook Account is not Connected", { id: "Toast-01" });
          } else {
            //toast.success("Facebook Account is Connected", { id: "Toast-01" });

            handleInputModalClose();
            dispatch(setLoader(true));

            url = 'https://graph.facebook.com/' + meta_page_id + '/photos?url=' + socialMediaPostInfo?.post_image?.data + '&message=' + encodeURIComponent(editFinalPost);
            //const { data } = await axios.post('https://graph.facebook.com/'+meta_page_id+'/feed?message='+editFinalPost, {},
            const { data } = await axios.post(url, {},
              {
                headers: { 'Authorization': 'Bearer ' + meta_page_access_token },
              }
            );
            dispatch(setLoader(false));

            if (data.status === "error") {
              toast.error("Something went wrong " + data, { id: "Toast-01" });
            } else {

              const dateObj = new Date();

              // Format the date and time manually to get "Y-m-d H:i:s" format
              const year = dateObj.getFullYear();
              const month = String(dateObj.getMonth() + 1).padStart(2, '0');
              const day = String(dateObj.getDate()).padStart(2, '0');
              const hours = String(dateObj.getHours()).padStart(2, '0');
              const minutes = String(dateObj.getMinutes()).padStart(2, '0');
              const seconds = "00";

              const formattedDateTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

              const data = {
                user_id: userRes.id,
                post_type: "Facebook",
                post_id: socialMediaPostInfo.postId,
                final_post: editFinalPost,
                image: socialMediaPostInfo?.post_image?.data,
                is_published: "1",
                is_scheduled: "0",
                scheduled_date: null,
                publish_date: formattedDateTime
              }
              await invokeAxiosPost(`/social-media-post/get-or-generate/saveSocialMediaShareLog`, data);

              toast.success("Post Published Successfully.", { id: "Toast-01" });
            }
          }
        }).catch((error) => {
          //console.log(error);
          dispatch(setLoader(false));
          toast.error("Something went wrong, try again after sometime. " + url, { id: "Toast-01" });
        });
      } else if (selectedOption?.toLowerCase() === "Share Later"?.toLowerCase()) {
        //toast.success("Share Later", { id: "Toast-01" });
        const scheduledDateTime = fbInputs.scheduledDateTime;
        //console.log("Scheduled Date and Time:", scheduledDateTime);
        const dateObj = new Date(scheduledDateTime);

        // Format the date and time manually to get "Y-m-d H:i:s" format
        const year = dateObj.getFullYear();
        const month = String(dateObj.getMonth() + 1).padStart(2, '0');
        const day = String(dateObj.getDate()).padStart(2, '0');
        const hours = String(dateObj.getHours()).padStart(2, '0');
        const minutes = String(dateObj.getMinutes()).padStart(2, '0');
        const seconds = "00";

        const formattedDateTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

        // Convert formattedDateTime to Unix time - Assuming formattedDateTime is UTC 0
        //const unixTime = Math.floor(Date.parse(formattedDateTime) / 1000);

        let url;

        invokeAxiosPost(`/user-api/get-or-create/userSocialAccounts`, {
          userId: userRes.id
        }).then(async (response) => {

          /*const meta_client_id = response.data[0].meta_client_id;
          const meta_client_secret = response.data[0].meta_client_secret;
          const access_token = response.data[0].access_token;
          const token_expires_on = response.data[0].token_expires_on;
          
          //toast.success("Page Id is " + meta_page_id, { id: "Toast-01" }); */
          const meta_page_access_token = response.data[0].meta_page_access_token;
          const meta_page_id = response.data[0].meta_page_id;

          if (meta_page_id === "" || meta_page_access_token === "") {
            toast.error("Facebook Account is not Connected");
          } else {
            //toast.success("Facebook Account is Connected", { id: "Toast-01" });

            handleInputModalClose();
            dispatch(setLoader(true));

            url = 'https://graph.facebook.com/' + meta_page_id + '/feed?published=false&scheduled_publish_time=' + formattedDateTime + '&url=' + socialMediaPostInfo?.post_image?.data + '&message=' + encodeURIComponent(editFinalPost);
            //const { data } = await axios.post('https://graph.facebook.com/'+meta_page_id+'/feed?message='+editFinalPost, {},
            const { data } = await axios.post(url, {},
              {
                headers: { 'Authorization': 'Bearer ' + meta_page_access_token },
              }
            );
            dispatch(setLoader(false));

            if (data.status === "error") {
              toast.error("Something went wrong " + data, { id: "Toast-01" });
            } else {

              const dateObj = new Date();

              // Format the date and time manually to get "Y-m-d H:i:s" format
              const year = dateObj.getFullYear();
              const month = String(dateObj.getMonth() + 1).padStart(2, '0');
              const day = String(dateObj.getDate()).padStart(2, '0');
              const hours = String(dateObj.getHours()).padStart(2, '0');
              const minutes = String(dateObj.getMinutes()).padStart(2, '0');
              const seconds = "00";

              const publishDateTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

              const data = {
                user_id: userRes.id,
                post_type: "Facebook",
                post_id: socialMediaPostInfo.postId,
                final_post: editFinalPost,
                image: socialMediaPostInfo?.post_image?.data,
                is_published: "0",
                is_scheduled: "1",
                scheduled_date: formattedDateTime,
                publish_date: publishDateTime
              }
              await invokeAxiosPost(`/social-media-post/get-or-generate/saveSocialMediaShareLog`, data);
              toast.success("Post Scheduled Successfully.", { id: "Toast-01" });
            }
          }
        }).catch((error) => {
          console.log(error);
          dispatch(setLoader(false));
          toast.error("Something went wrong, try again after sometime. " + url, { id: "Toast-01" });
        });
      } else {
        toast.error("Invalid Option Selected", { id: "Toast-01" });
      }
    }
  }

  const handleCopyHtmlArticleText = (text) => {
    if (text && text !== "") {
      navigator.clipboard.writeText(text);
      toast.success("Text has been copied to clipboard.", { id: "Toast-01" });
    }
  }

  const [fbInputs, setFbInputs] = useState({
    text: "",
    sharingOption: "Share Now", // Default value
    scheduledDateTime: "", // State to hold the selected date and time
  });

  const [fbErrors, setFbErrors] = useState({});

  const handleFbInputChangeValue = (event) => {
    const { name, value } = event.target;
    setFbInputs((prevInputs) => ({ ...prevInputs, [name]: value }));

    // Clear the scheduledDateTime value if "Share Now" is selected
    if (name === "sharingOption" && value === "Share Now") {
      setFbInputs((prevInputs) => ({ ...prevInputs, scheduledDateTime: "" }));
    }
  };

  // Function to handle date and time selection
  const handleDateTimeChange = (event) => {
    const { name, value } = event.target;
    setFbInputs((prevInputs) => ({ ...prevInputs, [name]: value }));
  };

  // Get the current date and time in the format required by the input (yyyy-MM-ddThh:mm)
  const getCurrentDateTime = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0");
    const day = String(now.getDate()).padStart(2, "0");
    const hours = String(now.getHours()).padStart(2, "0");
    const minutes = String(now.getMinutes()).padStart(2, "0");
    return `${year}-${month}-${day}T${hours}:${minutes}`;
  };

  const handleSendArticleToReviewer = async (data) => {
    try {
      dispatch(setLoader(true));

      const sentences = editFinalPost.split('\n');
      let output = '<p>' + sentences.join('</p><p>') + '</p>';
      output = output.replaceAll("<p></p>", "<p>&nbsp;</p>");

      const payload = {
        userId: userRes.id, articleType: articleType, articleSubType: `${socialMediaPostInfo.PostType} Post`,
        articleId: socialMediaPostInfo?.postId ? socialMediaPostInfo.postId : "",
        articleText: output && output !== "" ? output : editFinalPost, ...data
      };

      const response = await invokeAxiosPost(`/reviewer-editor/get-or-create/sendArticleOrPostToReviewerAdmin`, payload);
      if (response && response.data && response.data.success === 1) {
        dispatch(setLoader(false));
        if (data.isSentToReviewer && data.isSentToReviewer === true) {
          toast.success("Post sent to reviewer successfully.", { id: "Toast-01" });
          setSelectedCurrentTab("reviewPost");
          dispatch(setSocialMediaStepsInfo({ ...socialMediaPostInfo, is_check_reviewer: 1 }));
          dispatch(setUserRes({ ...userRes, credits: userRes.credits - data.deductCredits }));
        }

        return response.data;
      } else {
        dispatch(setLoader(false));
        toast.error("Something went wrong!", { id: "Toast-01" });
      }
    } catch (error) {
      dispatch(setLoader(false));
      toast.error("Something went wrong!", { id: "Toast-01" });
    }
  }

  const handleOnChangeWriterTabOrSubTab = (tab, subTab) => {
    let tabVal = selectedCurrentTab, subTabVal = selectedSubTab;
    if (tab && tab !== "") {
      tabVal = tab;
    }

    if (subTab && subTab !== "") {
      subTabVal = subTab;
    } else if (tab === "finalPreview") {
      subTabVal = "postPreview";
    } else {
      subTabVal = "comments";
    }

    setSelectedCurrentTab(tabVal);
    setSelectedSubTab(subTabVal);

    let queryParams = `tab=${tabVal}`;
    if (subTabVal && subTabVal !== "") {
      queryParams += `&subTab=${subTabVal}`;
    }

    navigate(`/social-media-post-writer/${socialMediaPostInfo?.postId}?${queryParams}`);
  }

  const [sendToEditorForm, setSendToEditorForm] = useState(false);
  const reviewEditorChildRef = useRef();

  const handleSaveReviewArticleText = () => {
    if (reviewEditorChildRef.current) {
      reviewEditorChildRef.current.handleSaveArticleTextAndNewVersion();
    }
  };

  return (
    <div className="addllyFormWrap finalPreview" style={searchParams.get('subTab') === "version" ? { zIndex: "1000" } : {}}>
      <div className="selectBlock d-flex align-items-center justify-content-between mx-2 mx-md-auto">
        <div className="">
          <b>Preview post</b>
        </div>
        <div className="nxtGenBtn d-flex align-items-center align-items-center gap-2">
          <button
            type="button" className="d-flex align-items-center gap-2 bg-transparent border-0 selectBbutton"
            onClick={() => {
              dispatch(setSocialMediaPostStep(6));
              const scrollWrapper = document.getElementById('scroll-wrapper');
              if (scrollWrapper) { scrollWrapper.scrollTo({ top: 0, behavior: 'smooth' }); }
            }}
          >
            <BsArrowLeft />Previous
          </button>
        </div>
      </div>
      <Tabs defaultActiveKey={selectedCurrentTab} activeKey={selectedCurrentTab} className="custom-nav-tabs p-32"
        style={{ width: "50%", position: "relative", zIndex: 2 }}
        onSelect={(event) => handleOnChangeWriterTabOrSubTab(event, "")}
      >
        <Tab eventKey="finalPreview" title={<span className="d-flex align-items-center gap-2"> <FaEye /> Final Preview </span>}>
          {selectedCurrentTab === "finalPreview" && (
            <FinalPostPreview
              editFinalPost={editFinalPost} setEditFinalPost={setEditFinalPost}
              socialMediaPostInfo={socialMediaPostInfo} setUploadFileModal={setUploadFileModal}
              handleCopyHtmlArticleText={handleCopyHtmlArticleText} showLength={showLength}
              articleType={articleType} selectedSubTab={selectedSubTab} userRes={userRes}
              versionHistory={versionHistory} selectedVersionItem={selectedVersionItem}
              setSelectedVersionItem={setSelectedVersionItem} setSelectedSubTab={setSelectedSubTab}
            />
          )}
        </Tab>
        {/* {socialMediaPostInfo.PostType === "LinkedIn" && (
          <Tab eventKey="linkedinCarousel" title={<span className="d-flex align-items-center gap-2"> <BsImage /> Linkedin Carousel </span>}>
            {selectedCurrentTab === "linkedinCarousel" && (
              <LinkedInCarousel articleType={articleType} articleSubType={"LinkedIn Post"}
                articleInfo={{
                  articleId: socialMediaPostInfo?.postId, keyword: socialMediaPostInfo?.keyword,
                  linkedIn_post: socialMediaPostInfo?.final_post,
                }}
              />
            )}
          </Tab>
        )} */}
        <Tab eventKey="reviewPost" title={<span className="d-flex align-items-center gap-2"> <BsPersonCheck /> Review Post </span>}>
          {selectedCurrentTab === "reviewPost" && (
            <div className="position-relative mb-5 mt-4">
              <div className="review-post-wrapper">
                <button type="button" className="blogButton border-0" data-bs-toggle="offcanvas" data-bs-target="#commentVersionHistory"
                  aria-controls="commentVersionHistory" onClick={() => handleOnChangeWriterTabOrSubTab("reviewPost", "version")}
                >
                  Version History
                </button>
                <span className="line"></span>
                <div className="rightSideButton d-flex gap-2">
                  {socialMediaPostInfo?.is_check_reviewer === 0 && selectedCurrentTab === "reviewPost" && (
                    <button className="blogButton border-0" onClick={() => setSendToEditorForm(true)}>
                      <LuCheckCircle /> Submit for Review
                    </button>
                  )}
                  <button data-tooltip-id="my-tooltip" data-tooltip-content="Save" data-tooltip-place="bottom"
                    className="blogButton border-0 bg-black saveButton" onClick={handleSaveReviewArticleText}
                  >
                    <FaRegSave />
                  </button>
                </div>
              </div>

              <div className="position-relative">
                <ReviewTextEditor
                  isSelectedCurrentTab={selectedCurrentTab === "reviewPost" ? true : false} articleType={articleType}
                  articleId={socialMediaPostInfo?.postId} articleSubType={`${socialMediaPostInfo.PostType} Post`}
                  isReviewArticle={socialMediaPostInfo?.is_check_reviewer || 0} toggleRightSideBar={false}
                  handleSendArticleToReviewer={handleSendArticleToReviewer} sendToEditorForm={sendToEditorForm}
                  setSendToEditorForm={setSendToEditorForm} ref={reviewEditorChildRef}
                />
              </div>
            </div>
          )}
        </Tab>
      </Tabs>

      {selectedCurrentTab !== "linkedinCarousel" && selectedCurrentTab !== "reviewPost" && (
        <div className="d-flex align-items-start justify-content-center  align-items-center flex-row   gap-2 bottomButton">
          <button type="button" className="addlly-outline "
            onClick={() => {
              dispatch(setSocialMediaPostStep(6));
              const scrollWrapper = document.getElementById('scroll-wrapper');
              if (scrollWrapper) { scrollWrapper.scrollTo({ top: 0, behavior: 'smooth' }); }
            }}
          >
            <span><BsArrowLeft /> Previous</span>
          </button>
          <div className='d-flex align-items-center gap-3'>
            <button type="button" onClick={handleSaveFinalPostData} className="addlly-primary" style={{ whiteSpace: "nowrap" }}>
              <span>Save & Continue <BsArrowRight /></span>
            </button>
          </div>
        </div>
        // <div className="d-flex justify-content-between mt-30">
        //   <div className="d-flex align-items-center">
        //     <button type="button" className="addlly-outline" onClick={() => {
        //       dispatch(setSocialMediaPostStep(5));
        //       const scrollWrapper = document.getElementById('scroll-wrapper');
        //       if (scrollWrapper) { scrollWrapper.scrollTo({ top: 0, behavior: 'smooth' }); }
        //     }}>
        //       <IoIosArrowBack /> <span>Back</span>
        //     </button>
        //     {socialMediaPostInfo && socialMediaPostInfo.pdf_link !== "" && pdfBase64 && (
        //       <button type="button" className="addlly-outline ms-3" onClick={() => setShowPdfModal(true)}>
        //         <span>View PDF</span>
        //       </button>
        //     )}
        //   </div>
        //   <div className="d-flex align-items-center">
        //     {(socialMediaPostInfo.PostType === "LinkedIn" || socialMediaPostInfo.PostType === "Instagram1") && (
        //       <button type="button" onClick={() => handleShareSocialMediaPost()} className="addlly-outline me-3 h-100">
        //         <span className="d-flex align-items-center">
        //           {socialMediaPostInfo.PostType === "LinkedIn" && (<FaLinkedin className="me-2 fs-5" />)}
        //           {socialMediaPostInfo.PostType === "Instagram" && (<FaInstagram className="me-2 fs-5" />)}
        //           Share Post
        //         </span>
        //       </button>
        //     )}
        //     {(socialMediaPostInfo.PostType === "Facebook1" || socialMediaPostInfo.PostType === "Twitter") && (
        //       <button type="button" onClick={() => setInputModal(true)} className="addlly-outline me-3 h-100">
        //         <span className="d-flex align-items-center">
        //           {socialMediaPostInfo.PostType === "Facebook" && (<FaFacebookF className="me-2 fs-4" />)}
        //           {socialMediaPostInfo.PostType === "Twitter" && (<FaTwitter className="me-2 fs-4" />)}
        //           Share Post
        //         </span>
        //       </button>
        //     )}
        //     <button type="button" onClick={handleSaveFinalPostData} className="addlly-primary">
        //       <span>Save</span>
        //     </button>
        //   </div>
        // </div>
      )}

      <Modal className="" size="xl" centered show={showPdfModal} onHide={() => setShowPdfModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            <span className="me-4">Uploaded PDF</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-0">
          <div className="p-0 overflow-hidden">
            <embed width="100%" style={{ height: "86vh" }} src={pdfBase64} type="application/pdf" />
          </div>
        </Modal.Body>
      </Modal>

      <Modal className="" centered show={inputModal} onHide={handleInputModalClose}>
        <Modal.Body className="p-4">
          <div className="text-start">
            {/* <h4 className="fw-normal">Enter your account details here</h4> */}
            <div className="addllyFormWrap mt-3">
              {formFieldArray && socialMediaPostInfo.PostType && formFieldArray[socialMediaPostInfo.PostType]?.map((field, index) => (
                <div className="form-group mb-4" key={index}>
                  <label className="mb-0">{field.label}</label>
                  {field.name === "text" ? (
                    <React.Fragment>
                      <textarea
                        name={field.name} type="text" rows="3" placeholder={field.placeholder}
                        value={inputs[field.name]} onChange={handleOnInputChangeValue}
                        className={`addllyForm-control rounded ${errors[field.name] ? "is-invalid" : ""}`}
                      />
                      <div className="float-end">
                        <label className="mb-0">{inputs[field.name].length} / 280</label>
                      </div>
                    </React.Fragment>
                  ) : (
                    <input
                      name={field.name} type="text" placeholder={field.placeholder}
                      value={inputs[field.name]} onChange={handleOnInputChangeValue}
                      className={`addllyForm-control rounded ${errors[field.name] ? "is-invalid" : ""}`}
                    />
                  )}
                  <div className="invalid-feedback">{errors[field.name]?.message}</div>
                </div>
              ))}

              {socialMediaPostInfo.PostType === "Facebook" && (
                <React.Fragment>
                  <div className="form-group mb-4">
                    <h4>Sharing Options</h4><hr></hr>

                    {/* Radio button for Share Now */}
                    <label className="d-block">
                      <input
                        type="radio"
                        name="sharingOption"
                        value="Share Now"
                        checked={fbInputs.sharingOption === "Share Now"}
                        onChange={handleFbInputChangeValue}
                      />{" "}
                      Share Now
                    </label>
                    <label className="d-block">
                      <input
                        type="radio"
                        name="sharingOption"
                        value="Share Later"
                        checked={fbInputs.sharingOption === "Share Later"}
                        onChange={handleFbInputChangeValue}
                      />{" "}
                      Share Later
                    </label>
                  </div>

                  {/* Conditionally render the date and time picker */}
                  {fbInputs.sharingOption === "Share Later" && (
                    <div className="form-group mb-4">
                      <label className="mb-0">Select Date and Time</label>
                      <input
                        type="datetime-local"
                        name="scheduledDateTime"
                        value={fbInputs.scheduledDateTime}
                        onChange={handleDateTimeChange}
                        min={getCurrentDateTime()} // Set the minimum date and time to the current date and time
                      />
                    </div>
                  )}
                  <div className="invalid-feedback">{fbErrors.text?.message}</div>
                </React.Fragment>
              )}
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-end pt-4">
            <button onClick={handleInputModalClose} type="button" className="addlly-outline ms-3">
              <span>Close</span>
            </button>
            <button onClick={handleShareSocialMediaPost} type="button" className="addlly-primary ms-3">
              <span>Share</span>
            </button>
          </div>
        </Modal.Body>
      </Modal>

      {uploadFileModal && uploadFileModal.open && (
        <div className="genrateAiImages">
          <MediaLibraryDrawer
            postImagesModal={uploadFileModal} setPostImagesModal={setUploadFileModal}
            postGeneratedImages={aiGeneratedImages} setPostGeneratedImages={setAiGeneratedImages}
            articleType={articleType} userRes={userRes} articleId={socialMediaPostInfo?.postId}
          />
        </div>
      )}

      <GoDashboard
        show={savedModal}
        onHide={handleSaveClose}
        goDashboard={() => {
          handleSaveClose();
          dispatch(setSocialMediaStepsInfo({}));
          dispatch(setSocialMediaPostStep(1));
          navigate("/social-media-post-writer");
        }}
      />
    </div>
  )
}

const FinalPostPreview = ({
  editFinalPost, setEditFinalPost, socialMediaPostInfo, setUploadFileModal, handleCopyHtmlArticleText,
  showLength, articleType, selectedSubTab, userRes, versionHistory,
  selectedVersionItem, setSelectedVersionItem, setSelectedSubTab
}) => {
  const dispatch = useDispatch();
  const handleOpenUploadFileModal = () => {
    const subType = `${socialMediaPostInfo.PostType} Post`;
    const params = {
      type: articleType, subType, userId: userRes.id,
      html: editFinalPost, articleId: socialMediaPostInfo.postId,
    };

    setUploadFileModal((prev) => ({ ...prev, open: true, apiParams: params, image: "" }));
  };

  const handleSetRestoredOldVersionText = async (fieldName, text) => {
    dispatch(setSocialMediaStepsInfo({ ...socialMediaPostInfo, [fieldName]: text }));
    setEditFinalPost(text);
  };

  const handleShareSocialMediaPost = async () => {
    if (socialMediaPostInfo.PostType === "Facebook") {
      try {
        dispatch(setLoader(true));
        const payload = {
          text: editFinalPost,
          postType: "Facebook",
          postId: socialMediaPostInfo?.postId ? socialMediaPostInfo?.postId : "",
          image: "",
          userId: userRes.id,
          saveImage: false,
        };

        const response = await invokeAxiosPost("/social-media-post/get-or-generate/socialMediaPostShare", payload);
        dispatch(setLoader(false));
        if (response && response.success === 1) {
          toast.success("Post share successfully.", { id: "Toast-01" });
        } else {
          toast.error(response?.error || "Something went wrong.", { id: "Toast-01" });
        }
      } catch (error) {
        dispatch(setLoader(false));
        toast.error(error?.response?.data?.error?.message || error?.response?.data?.error || "Access token is not valid.", { id: "Toast-01" });
      }
    } else if (socialMediaPostInfo.PostType === "LinkedIn") {
      const uploadImage = socialMediaPostInfo?.post_image || "";
      const params = {
        postType: "LinkedIn", text: editFinalPost || "",
        articleId: socialMediaPostInfo?.postId ? socialMediaPostInfo?.postId : "",
        keyword: "", userId: userRes.id, dispatch,
        image: uploadImage ? JSON.stringify({ data: uploadImage }) : "",
      };
      await handleShareSocialMediaTypePost(params);
    }
  };

  const handleTrainOrUnTrainArticle = () => {
    const id = socialMediaPostInfo?.postId ? socialMediaPostInfo?.postId : "";
    const type = socialMediaPostInfo?.is_trained ? "unTrain" : "train";

    Swal({
      title: "Are you sure?",
      text: `Do you want to ${type} the selected item?`,
      icon: "warning", dangerMode: true,
      buttons: { cancel: "Cancel", confirm: `Yes, ${type} it!` },
    }).then(async (isConfirmed) => {
      if (isConfirmed) {
        try {
          dispatch(setLoader(true));
          const payload = { type: type, userId: userRes.id, articleType: "Social Media Post Writer" };
          const response = await invokeAxiosPost(`/article-history/get-or-save/articleTrainOrUnTrainedByUser?articleId=${id}`, payload);
          if (response && response.data && response.data.success === 1) {
            dispatch(setLoader(false)); toast.success(response.data.msg, { id: "Toast-01" });
            dispatch(setSocialMediaStepsInfo({
              ...socialMediaPostInfo, is_trained: socialMediaPostInfo?.is_trained ? 0 : 1
            }));
          } else {
            dispatch(setLoader(false));
            toast.error("Something went wrong, Please try again!", { id: "Toast-01" });
          }
        } catch (error) {
          dispatch(setLoader(false));
        }
      }
    });
  };

  return (
    <div className="">
      {/* <div className="addUrlBlockS d-flex justify-content-end pe-3 gap-3 flex-wrap" style={{
        position: "relative", marginTop: "-45px", marginRight: "-20px", paddingBottom: "10px",
      }}>
        {socialMediaPostInfo.PostType === "LinkedIn" && (
          <button type="button" className="blogButton border-0"
            onClick={() => editFinalPost ? handleShareSocialMediaPost() : null}
            disabled={editFinalPost && editFinalPost !== "" ? false : true}
          >
            <FaLinkedin className="fs-5" /> Share Post
          </button>
        )}
        <button type="button" className="blogButton border-0" onClick={() => handleTrainOrUnTrainArticle()}>
          <MdModelTraining /> {socialMediaPostInfo.is_trained ? "UnTrain Post" : "Train Post"}
        </button>
        <VersionHistory
          versionHistory={versionHistory} selectedVersionItem={selectedVersionItem} setSelectedVersionItem={setSelectedVersionItem}
          handleSetRestoredOldVersionText={handleSetRestoredOldVersionText} handleCopyHtmlArticleText={handleCopyHtmlArticleText}
          selectedSubTab={selectedSubTab} setSelectedSubTab={setSelectedSubTab} articleType={articleType}
        />
      </div> */}
      <div className="reviewArticalBlock">
        <div className=" social-media-wrapper">
          <div className="finalPreviewPost">
            <div className="socialPostEditor text-copy-wrapper h-100 ">
              {/* <label className="mb-2">Final Post Preview</label>
              <textarea
                className="w-100 outlineTextarea" value={editFinalPost} style={{ height: "60vh" }}
                onChange={(e) => setEditFinalPost(e.target.value)} disabled={true}
              /> */}
              <div className="custom-text-editor  h-100 position-relative ">
                {/* <div className="textarea-header d-flex align-items-center justify-content-between">
                  <span>Edit final post</span>
                  {showLength && (<span className="mx-5">{editFinalPost.length} / {showLength}</span>)}
                </div> */}
                <textarea
                  className="w-100 h-100 outlineTextarea textarea-content" value={editFinalPost}
                  onChange={(e) => setEditFinalPost(e.target.value)}
                />
              </div>
              <button
                type="button" className=" copy-textarea-btn d-flex" onClick={(e) => handleCopyHtmlArticleText(editFinalPost)}
              >
                <ReactSVG src={copyIcon} className='copy' />
              </button>
            </div>
          </div>
          <div className="pb-2 finalViewMobile">
            <div className="addUrlBlockS d-flex flex-wrap justify-content-end pe-3 gap-3 flex-wrap">
              {socialMediaPostInfo.PostType === "LinkedIn" && (
                <button type="button" className="blogButton border-0"
                  onClick={() => editFinalPost ? handleShareSocialMediaPost() : null}
                  disabled={editFinalPost && editFinalPost !== "" ? false : true}
                >
                  <FaLinkedin className="fs-5" /> Share Post
                </button>
              )}
              <button type="button" className="blogButton border-0" onClick={() => handleTrainOrUnTrainArticle()}>
                <MdModelTraining /> {socialMediaPostInfo.is_trained ? "UnTrain Post" : "Train Post"}
              </button>
              <VersionHistory
                versionHistory={versionHistory} selectedVersionItem={selectedVersionItem} setSelectedVersionItem={setSelectedVersionItem}
                handleSetRestoredOldVersionText={handleSetRestoredOldVersionText} handleCopyHtmlArticleText={handleCopyHtmlArticleText}
                selectedSubTab={selectedSubTab} setSelectedSubTab={setSelectedSubTab} articleType={articleType}
              />
            </div>

            <div className="mt-4">
              {socialMediaPostInfo.PostType === "Facebook" ? (
                <FacebookPostPreview
                  text={editFinalPost} preview_img={socialMediaPostInfo?.post_image?.data || socialMediaPostInfo?.post_image}
                  isUploadImage={true} setUploadFileModal={() => handleOpenUploadFileModal()} userRes={userRes}
                />
              ) : socialMediaPostInfo.PostType === "Twitter" ? (
                <TwitterPostPreview
                  text={editFinalPost} preview_img={socialMediaPostInfo?.post_image?.data || socialMediaPostInfo?.post_image}
                  isUploadImage={true} setUploadFileModal={() => handleOpenUploadFileModal()} userRes={userRes}
                />
              ) : socialMediaPostInfo.PostType === "Instagram" ? (
                <InstagramPostPreview
                  text={editFinalPost} preview_img={socialMediaPostInfo?.post_image?.data || socialMediaPostInfo?.post_image}
                  isUploadImage={true} setUploadFileModal={() => handleOpenUploadFileModal()} userRes={userRes}
                />
              ) : (
                <LinkedinPostPreview
                  text={editFinalPost} preview_img={socialMediaPostInfo?.post_image?.data || socialMediaPostInfo?.post_image}
                  isUploadImage={true} setUploadFileModal={() => handleOpenUploadFileModal()} userRes={userRes}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
};

export default SixthStepSocialMediaPost;
