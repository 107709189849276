import React, { useEffect, useState } from "react";
import ReactFlagsSelect from "react-flags-select";
import Select from "react-select";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { FiCheck } from "react-icons/fi";
import { MdOutlineClose } from "react-icons/md";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { BsArrowRight, BsEyeFill, BsInfoCircleFill } from "react-icons/bs";
import { Col, ListGroup, Row } from "react-bootstrap";
import { AiTypeOptions } from "../../data";
import ProductViewModal from "../ECommerceBlogWriter/ProductViewModal";
import { invokeAxiosGet, invokeAxiosPost } from "../../utility/invokeAxiosFunction";
import { handleCustomSelectOption, supportedLanguageList } from "../../utility/hepler";
import { setLoader, setECommerceBlogInfo, setECommerceBlogStep, setUserRes } from "../../store/reducer";
import { handleSetLanguageAndGeoLocation } from "../../utility/common";
import "react-quill/dist/quill.snow.css";

const ContentTonesOptions = [
  { label: "Professional", value: "Professional" },
  { label: "Casual", value: "Casual" },
  { label: "Straightforward", value: "Straightforward" },
  { label: "Confident", value: "Confident" },
  { label: "Friendly", value: "Friendly" },
];

const FirstStepElixirEComBlog = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userRes = useSelector(({ userRes }) => userRes);
  const eCommerceBlogInfo = useSelector(({ eCommerceBlogInfo }) => eCommerceBlogInfo);
  const selectedProductsName = eCommerceBlogInfo?.selectedProducts?.map(({ name }) => name);

  const [aiType, setAiType] = useState(eCommerceBlogInfo?.AI_type || "Claude 3.5 Sonnet");
  const [promotionText, setPromotionTextText] = useState("");
  const [geoLocation, setGeoLocation] = useState(eCommerceBlogInfo?.geoLocation || "SG");
  const [contentTone, setContentTone] = useState(eCommerceBlogInfo?.content_tone || "");
  const [generateType, setGenerateType] = useState("direct");
  const [language, setLanguage] = useState(eCommerceBlogInfo?.language || "English");
  const [categoryList, setCategoryList] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [allProductList, setAllProductList] = useState([]);
  const [productList, setProductList] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(selectedProductsName || []);
  const [viewProduct, setViewProduct] = useState({ open: false, data: null });
  const [productBtn, setProductBtn] = useState(false);
  const [otherCategory, setOtherCategory] = useState();
  const [isDisabledInput, setIsDisabledInput] = useState(eCommerceBlogInfo?.articleId ? true : false);
  const [showSelectList, setShowSelectList] = useState([true, false]);
  const [showArticleSpecs, setShowArticleSpecs] = useState(false);

  useEffect(() => {
    historyFetchApi();
  }, []);

  useEffect(() => {
    const location = eCommerceBlogInfo?.geoLocation;
    setGeoLocation(location?.toUpperCase() || "SG");
    setPromotionTextText(eCommerceBlogInfo?.promotionText || "");
    setAiType(eCommerceBlogInfo?.AI_type || "Claude 3.5 Sonnet");
    setLanguage(eCommerceBlogInfo?.language || "English");
    setSelectedCategory("");
    setContentTone(eCommerceBlogInfo?.content_tone || "");
    setGenerateType(eCommerceBlogInfo?.generate_type || "direct");
    setIsDisabledInput(eCommerceBlogInfo?.articleId ? true : false);
    setSelectedProduct(selectedProductsName || []);
  }, [eCommerceBlogInfo?.articleId])

  const historyFetchApi = () => {
    if (productList.length === 0) {
      dispatch(setLoader(true));
      invokeAxiosGet(`/e-commerce/get-or-generate/getProductCatalogProductList`).then((res) => {
        dispatch(setLoader(false));
        const productRes = res.data && res.data.data ? res.data.data : [];
        setOtherCategory(res.data.otherCategoryList);

        if (productRes && productRes.length > 0) {
          setAllProductList(productRes);
          handleSelectCategory("Custom", "All", productRes);
        }

        const categoryRes = res.data && res.data.categoryList ? res.data.categoryList : [];
        if (categoryRes && categoryRes.length > 0) {
          setCategoryList(categoryRes);
        }
      }).catch((e) => {
        dispatch(setLoader(false));
      });
    }
  };

  const handleSelectCategory = (company, category, data) => {
    let filteredProducts = [];
    if (company === "Custom") {
      setSelectedCategory(category);
      filteredProducts = data.filter(element => {
        return element.company === company && (category === "All" || element.category === category) && element.user_id === userRes.id;
      });
    } else {
      setSelectedCategory(category);
      filteredProducts = data.filter(element => {
        return element.company === company && element.category === category;
      });
    }

    setProductList(filteredProducts);
  };

  const handleSelectOrDeselect = (product, isCheck) => {
    if (selectedProduct.length >= 3 && isCheck) {
      toast.error("Select at least 1 and at most 3 Products.");
      return false;
    }

    if (selectedProduct && selectedProduct.includes(product.name)) {
      const newArray = selectedProduct.filter((item1) => item1 !== product.name);
      setSelectedProduct([...newArray]);
    } else {
      setSelectedProduct([...selectedProduct, product.name]);
    }
  };

  const handleGenerateTopicsAndSummary = async () => {
    if (eCommerceBlogInfo.stepDone > 1) {
      return dispatch(setECommerceBlogStep(2))
    }

    if (Number(userRes.credits) < (userRes?.deductCredits?.["E-Commerce Blog"] || 3)) {
      toast.error("Sorry, you don't have enough credits to perform this action.", { id: "Toast-01" });
      return false;
    }

    const selectedFinalProducts = [];
    for (let index = 0; index < selectedProduct.length; index++) {
      const element = selectedProduct[index];
      const product = allProductList.find((item) => item.name === element);
      selectedFinalProducts.push(product);
    }

    try {
      dispatch(setLoader(true));
      const payload = {
        selectedProduct: selectedFinalProducts,
        geoLocation: geoLocation?.toLowerCase() || "sg",
        promotionText: promotionText || "",
        aiType: aiType ? aiType : "Claude 3.5 Sonnet", language,
        user_id: userRes.id, content_tone: contentTone || "",
        generateType: generateType,
        type: "generate-elixir-blog-trends",
      };

      const response = await invokeAxiosPost("/elixir-e-commerce/get-or-generate/generateElixirBlogTrends", payload);
      dispatch(setLoader(false));
      if (response && response.success === 1 && response.data && response.data.allTopics && response.data.allTopics.length > 0) {
        setSelectedProduct([]); dispatch(setECommerceBlogStep(2));
        dispatch(setECommerceBlogInfo({ ...eCommerceBlogInfo, ...response.data, stepDone: 2 }));
        dispatch(setUserRes({ ...userRes, credits: userRes.credits - (userRes?.deductCredits?.["E-Commerce Blog"] || 3) }));
        navigate(`/e-commerce-blog/${response.data?.articleId}`);
        const scrollWrapper = document.getElementById("scroll-wrapper");
        if (scrollWrapper) { scrollWrapper.scrollTo({ top: 0, behavior: "smooth" }); }
      } else {
        toast.error("Topics not generated, Please try again.", { id: "Toast-01" });
      }
    } catch (error) {
      dispatch(setLoader(false));
      toast.error(error?.response?.data?.error?.message || error?.response?.data?.error || "Server is overloaded, try again after sometime.", { id: "Toast-01" });
    }
  };

  return (
    <div className="addllyFormWrap maingenrateBlock p-0 mx-3">
      <div className="selectBlock seoselected d-flex flex-wrap align-items-center justify-content-between">
        <div>
          <b>Select Products</b> <span>(Select any 1)</span>
        </div>
        <button
          type="button" disabled={selectedProduct && selectedProduct.length > 0 ? false : true}
          onClick={() => handleGenerateTopicsAndSummary()} className="d-flex align-items-center gap-2 bg-transparent border-0 selectBbutton"
        >
          Start <BsArrowRight />
        </button>
      </div>
      <Row className={`eCommerce-blog-wrapper mt-3 ${productBtn && 'pb-5'}`}>
        <Col sm="12" lg="3" className="side-menu p-0">
          <div className="sideBarInner">
            {categoryList && categoryList.length > 0 && (
              <React.Fragment>
                {otherCategory.length !== 0 ? (
                  <React.Fragment>
                    <div className="d-flex align-items-center justify-content-between">
                      <label>Own Category's</label>
                      <button className="btn text-primary p-0" onClick={() => setShowSelectList((pre) => ([pre[0], !pre[1]]))}>
                        {showSelectList[1] ? <IoIosArrowUp /> : <IoIosArrowDown />}
                      </button>
                    </div>
                    {showSelectList[1] && (
                      <ListGroup>
                        {categoryList && categoryList.length > 0 && categoryList.map((categoryItem, index) => (
                          <ListGroup.Item
                            active={selectedCategory === categoryItem.category} key={index} disabled={isDisabledInput}
                            action onClick={() => { handleSelectCategory(categoryItem.company, categoryItem.category, allProductList); setProductBtn(false); }}
                          >
                            {categoryItem.category}
                          </ListGroup.Item>
                        ))}
                      </ListGroup>
                    )}
                  </React.Fragment>
                ) : (
                  <ListGroup className="mt-3">
                    {categoryList && categoryList.length > 0 && categoryList.map((categoryItem, index) => (
                      <ListGroup.Item
                        active={selectedCategory === categoryItem.category} key={index} disabled={isDisabledInput}
                        action onClick={() => { handleSelectCategory(categoryItem.company, categoryItem.category, allProductList); setProductBtn(false); }}
                      >
                        {categoryItem.category}
                      </ListGroup.Item>
                    ))}
                  </ListGroup>
                )}
              </React.Fragment>
            )}

            {selectedProduct && selectedProduct.length > 0 && (
              <div className=" selected-products mt-3">
                <div className="d-flex justify-content-between align-content-center mb-3">
                  <label className="m-0">Selected Product</label>
                  <button className="btn  p-0 " onClick={() => setSelectedProduct([])} disabled={isDisabledInput}>
                    Clear All
                  </button>
                </div>
                <ol>
                  {selectedProduct.map((product, index) => (
                    <li className="mb-1" key={index}>{product}</li>
                  ))}
                </ol>
              </div>
            )}
          </div>
        </Col>
        <Col sm="12" lg="9" className="genrateFields">
          <Row className="ps-4">
            <div
              className="d-flex align-items-center justify-content-between py-2 px-3 mb-4 rounded cursor-pointer"
              style={{ background: "linear-gradient(102.77deg, rgba(0, 0, 255, 0.05) 0%, rgba(255, 0, 0, 0.05) 121.74%)" }}
              onClick={() => setShowArticleSpecs((pre) => (!pre))}
            >
              <label className="m-0"><b>Article Specifications</b></label>
              <button className="btn text-primary p-0" type="button">
                {showArticleSpecs ? <IoIosArrowUp /> : <IoIosArrowDown />}
              </button>
            </div>
            {showArticleSpecs && (
              <React.Fragment>
                <Col sm="12" md="12" className="addllyFormWrap mb-2 p-0 px-md-2">
                  <div className="form-group fields">
                    <label>Theme ( Describe your promotion text )</label>
                    <textarea
                      type="text" className="addllyForm-control addedOutline w-100 h-auto"
                      placeholder="Insert your theme text here" rows={2} disabled={isDisabledInput}
                      value={promotionText} onChange={(e) => setPromotionTextText(e.target.value)}
                    />
                  </div>
                </Col>
                <Col sm="12" md="6" className="mb-4  p-0 px-md-2">
                  <div className="position-relative languageDrop fields">
                    <label>Select your geo-location</label>
                    <ReactFlagsSelect
                      className={"reactFlag p-0 w-100"} searchable={true} selected={geoLocation}
                      onSelect={(val) => setGeoLocation(val)} disabled={isDisabledInput}
                    />
                    <div className="arrowIcon">
                      <IoIosArrowDown className="downArrowIcon" />
                    </div>
                  </div>
                </Col>
                <Col sm="12" md="6" className="mb-3 p-0 px-md-2">
                  <div className="fields">
                    <label>Choose your AI Model</label>
                    <Select isSearchable={false}
                      className={`custom-select`} classNamePrefix="custom-select" placeholder="Select AI Type"
                      options={AiTypeOptions} isClearable={false} defaultValue={AiTypeOptions[0]}
                      value={AiTypeOptions.find((c) => c.value === aiType)}
                      onChange={(e) => setAiType(e.value)} menuPosition="fixed" isDisabled={isDisabledInput}
                    />
                  </div>
                </Col>
                <Col sm="12" md="6" className="mb-3  p-0 px-md-2">
                  <div className="fields">
                    <label>Select Content Tone</label>
                    <Select
                      className={`custom-select`} classNamePrefix="custom-select" placeholder="Select Content Tone"
                      options={ContentTonesOptions} isClearable={false} isSearchable={false}
                      value={ContentTonesOptions.find((c) => c.value === contentTone) || ""}
                      onChange={(e) => setContentTone(e.value)} menuPosition="fixed" isDisabled={isDisabledInput}
                    />
                  </div>
                </Col>
                <Col sm="12" md="6" className="mb-3  p-0 px-md-2">
                  <div className="fields form-group">
                    <label>Choose a Language</label>
                    <Select
                      className={`custom-select`} classNamePrefix="custom-select" placeholder="Select Language"
                      options={supportedLanguageList} isClearable={false} isSearchable={false}
                      getOptionLabel={handleCustomSelectOption} menuPosition="fixed" isDisabled={isDisabledInput}
                      value={supportedLanguageList.find(c => c.value === language) || ""}
                      onChange={(event) => {
                        setLanguage(event.value);
                        handleSetLanguageAndGeoLocation(event, () => { }, setGeoLocation);
                      }}
                    />
                  </div>
                </Col>
              </React.Fragment>
            )}

            {productList && productList.length > 0 && (
              <Col sm="12" className="mb-3  p-0 px-md-2">
                <h4 className="mb-0">Select product to generate article ( Max 5 Products ) </h4>
              </Col>
            )}
            {productList && productList.length > 0 ? productList.map((product, index) => (
              <Col sm="6" md="6" lg="4" xxl="4" className="pb-3" key={index}>
                <div className={`image-wrapper ${selectedProduct && selectedProduct.includes(product.name) ? "active" : ""}`} >
                  <img src={product.image} alt={product.name} />
                  <div className="card-product-info">
                    <p>{product.name}</p>
                  </div>
                  <div className="card-button">
                    <div className="sub-div gap-2">
                      <button
                        type="button" className="btn bg-white text-primary"
                        onClick={() => setViewProduct({ open: true, data: product })}
                        data-tooltip-id={"my-tooltip"} data-tooltip-place="bottom" data-tooltip-content={`View Details`}
                      >
                        <BsEyeFill className="fs-5" />
                      </button>

                      <button
                        type="button" className={`btn bg-white text-primary ${selectedProduct && selectedProduct.includes(product.name) && "btn-primary"}`}
                        onClick={() => handleSelectOrDeselect(product, selectedProduct.includes(product.name) ? false : true)}
                        data-tooltip-id={"my-tooltip"} data-tooltip-place="bottom" data-tooltip-content={selectedProduct.includes(product.name) ? "Unselect Product" : "Select Product"}
                      >
                        {selectedProduct && selectedProduct.includes(product.name) ? (
                          <MdOutlineClose className="fs-5" />
                        ) : (
                          <FiCheck className="fs-5" />
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </Col>
            )) : null}
            {eCommerceBlogInfo?.selectedProducts && eCommerceBlogInfo.selectedProducts.length > 0 ? eCommerceBlogInfo.selectedProducts.map((product, index) => (
              <Col sm="6" md="6" lg="4" xxl="4" className="pb-3" key={index}>
                <div className={`image-wrapper ${selectedProduct && selectedProduct.includes(product.name) ? "active" : ""}`}>
                  <img src={product.image} alt={product.name} />
                  <div className="card-product-info">
                    <p>{product.name}</p>
                  </div>
                  <div className="card-button">
                    <div className="sub-div gap-2">
                      <button
                        type="button" className="btn bg-white text-primary" onClick={() => setViewProduct({ open: true, data: product })}
                        data-tooltip-id={"my-tooltip"} data-tooltip-place="bottom" data-tooltip-content={`View Details`}
                      >
                        <BsEyeFill className='fs-5' />
                      </button>
                    </div>
                  </div>
                </div>
              </Col>
            )) : null}
          </Row>
        </Col>
      </Row>
      <div className="d-flex align-items-start justify-content-center  align-items-center flex-row gap-2 bottomButton">
        <div className='d-flex align-items-center gap-2'>
          <button
            disabled={selectedProduct && selectedProduct.length > 0 ? false : true} type="button"
            onClick={() => handleGenerateTopicsAndSummary()} className="addlly-primary" style={{ whiteSpace: "nowrap" }}
          >
            <span>Start <BsArrowRight /></span>
          </button>
          <BsInfoCircleFill
            className="text-decoration-none cursor-pointer outline-0 infoIconSvg" data-tooltip-id="my-tooltip" data-tooltip-place="right"
            data-tooltip-content={`Will Utilize ${userRes?.deductCredits?.["E-Commerce Blog"] || "3"} Addlly Credits`}
          />
        </div>
      </div>

      {viewProduct && viewProduct.open && (
        <ProductViewModal viewProduct={viewProduct} setViewProduct={setViewProduct} />
      )}
    </div>
  );
};

export default FirstStepElixirEComBlog;
