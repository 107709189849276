import React, { useEffect, useRef, useState } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { FaRegComment } from "react-icons/fa6";
import { EditorInitObj, addEditorCustomButtons } from "../../../utility/hepler";

const CommentsTextEditor = (props) => {
  const {
    oldArticleText, editorText, setEditorText, editorRef, articleType, setSelectedCommentId, handleCloseSelectedComment,
    commentModal, setCommentModal, userRes, commentText, setCommentText, handleCloseAddCommentModal, handleAddNewComment,
    handleCopyHtmlArticleText
  } = props;

  const [editorKey, setEditorKey] = useState(0);
  const textareaRef = useRef(null);

  useEffect(() => {
    if (oldArticleText && oldArticleText !== "") {
      setEditorKey(editorKey + 1);
    }
  }, [oldArticleText]);

  const handleSelectOrRemoveEditorComment = async (e) => {
    const iframe = document.querySelector("iframe");
    const getId = e.target.getAttribute("data-group");
    if (e.target.tagName === "SPAN" && getId && getId !== "") {
      setSelectedCommentId(getId);
      await new Promise(resolve => setTimeout(resolve, 200));
      if (iframe && iframe.contentWindow && iframe.contentWindow.document) {
        const activeSpans = iframe.contentWindow.document.querySelectorAll("span.active");
        activeSpans.forEach((span) => { span.classList.remove("active") });

        const elements = iframe.contentWindow.document.querySelectorAll(`[data-group="${getId}"]`);
        elements.forEach(element => { element.classList.add("active") });
      }
    } else if (iframe && iframe.contentWindow && iframe.contentWindow.document) {
      handleCloseSelectedComment();
    }
  };

  const handleOnAddCommentPopup = () => {
    setCommentModal(true);
    setTimeout(() => {
      if (textareaRef && textareaRef.current) {
        textareaRef.current.focus();
      }
    }, 1000);

    const elements = document.getElementsByClassName("custom-tooltip");
    for (let i = 0; i < elements.length; i++) {
      const topPosition = Number(elements[i].style.top?.replace("px", "")) - 150;
      if (topPosition < 60) {
        elements[i].classList.add("top-arrow");
        elements[i].style.top = topPosition + 220 + "px";
      } else {
        elements[i].style.top = topPosition + "px";
      }

      const leftPosition = Number(elements[i].style.left?.replace("px", "")) - 150;
      if (leftPosition < 10) {
        elements[i].style.left = 160 + "px";
        const iframe = document.querySelector(".tox-edit-area").querySelector("iframe");
        if (iframe && iframe.contentWindow && iframe.contentWindow.document && editorRef.current.selection) {
          const iframeDoc = iframe.contentWindow;
          const selection = iframeDoc.getSelection();
          if (selection && selection.toString().trim() !== "") {
            const range = selection.getRangeAt(0);
            const rect = range.getBoundingClientRect();

            const elements2 = document.getElementsByClassName("arrow-content");
            elements2[0].style.left = (rect.left + (rect.width / 2)) + 26 + "px";
          }
        }
      }
    }
  };

  return (
    <div className="position-relative custom-text-editor reviewer-editor form-group">
      <Editor
        apiKey={process.env.REACT_APP_TINYMCE_EDITOR_KEY}
        onInit={(evt, editor) => (editorRef.current = editor)} key={editorKey}
        value={editorText} onEditorChange={(e) => setEditorText(e)}
        init={{
          ...EditorInitObj, height: "auto", plugins: [...EditorInitObj.plugins, "autoresize"],
          autoresize_bottom_margin: 20, autoresize_min_height: 100,
          content_style: `${(oldArticleText?.match(/<style>([\s\S]*?)<\/style>/i)?.[1] || "")}
            ${articleType === "Social Media Post Writer" ? "p { margin: 0; }" : ""}`,
          toolbar: 'copyContent |' + EditorInitObj.toolbar,
          setup: function (editor) {
            editor.on("click", (e) => handleSelectOrRemoveEditorComment(e));
            addEditorCustomButtons(editor, "", handleCopyHtmlArticleText);
          },
          forced_root_block: articleType === "Social Media Post Writer" ? 'div' : false,
          forced_root_block_attrs: articleType === "Social Media Post Writer" ? { 'data-mce-bogus': '1' } : {},
          valid_children: articleType === "Social Media Post Writer" ? '-h1' : '',
        }}
      />

      <div className="custom-tooltip">
        <div className="arrow-content"></div>
        {commentModal ? (
          <div className="comment-form-wrapper">
            <div className="addllyFormWrap">
              <div className="form-group mb-3">
                <textarea
                  name="comment" type="text" rows="4" placeholder="Add Comment"
                  className={`addllyForm-control rounded-2 px-3 h-auto`} value={commentText}
                  onChange={(e) => setCommentText(e.target.value)} ref={textareaRef}
                />
              </div>
              <div className="d-flex justify-content-between mb-2">
                <div className="user-info d-flex align-items-center">
                  {userRes?.profile_url ? (
                    <img src={userRes.profile_url} alt="user" className="rounded-circle userIcon" />
                  ) : (
                    <div className="avatar text-white">{userRes.username[0]}</div>
                  )}
                </div>
                <div className="d-flex align-items-center gap-2">
                  <button className="addlly-outline" type="button" onClick={handleCloseAddCommentModal}>
                    Close
                  </button>
                  <button className="addlly-primary" type="button" onClick={handleAddNewComment}
                    disabled={commentText && commentText !== "" ? false : true}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <span className="d-flex align-items-center gap-2 cursor-pointer text-nowrap" onClick={handleOnAddCommentPopup}>
            <FaRegComment /> Add Comment
          </span>
        )}
      </div>
    </div>
  )
}

export default CommentsTextEditor;
