import React, { useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import { Bar } from "react-chartjs-2";
import Chart from "chart.js/auto";
import { CategoryScale } from "chart.js";
import { useSelector } from "react-redux";
import NoDataAvailable from "../NoDataAvailable";
import LinkedinPostPreview from "../commonComponents/LinkedinPostPreview";
import InstagramPostPreview from "../commonComponents/InstagramPostPreview";
import FacebookPostPreview from "../commonComponents/FacebookPostPreview";
import TwitterPostPreview from "../commonComponents/TwitterPostPreview";
import { invokeAxiosGet } from "../../utility/invokeAxiosFunction";
Chart.register(CategoryScale);

export const GoogleAdPreview = ({ googleAdCopy, keywordCPC, col = 6 }) => {
  let keywordData = [];
  try {
    keywordData = keywordCPC ? JSON.parse(keywordCPC) : [];
  } catch (error) {
    //console.log(error);
  }

  return (
    <Col xl={col}>
      <div className="preview-container">
        {keywordData && keywordData.length > 0 && (
          <table className="table">
            <thead>
              <tr>
                <th>Keyword</th>
                <th>CPC</th>
              </tr>
            </thead>
            <tbody>
              {keywordData.map(item => (
                <tr key={item.keyword}>
                  <td>{item.keyword}</td>
                  <td>{item.cpc}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}

        {[1, 2, 3].map((ele) => (
          <div className="mb-4" key={ele}>
            <h4 className="text-primary">
              {googleAdCopy.substring(
                googleAdCopy.indexOf(`Headline ${ele}`), googleAdCopy.indexOf(ele === 3 ? 'Description 1' : `Headline ${ele + 1}`)
              ).replace(`Headline ${ele}: `, "")}
            </h4>
            <h6 className="text-success"> <span className="badge bg-success me-2">AD</span>
              {googleAdCopy.substring(
                googleAdCopy.indexOf("Display Path"), googleAdCopy.indexOf("Call to Action")
              ).replace("Display Path: ", "")}
            </h6>
            <p>
              {googleAdCopy.substring(
                googleAdCopy.indexOf(`Description ${ele}`), googleAdCopy.indexOf(ele === 2 ? 'Display Path' : `Description ${ele + 1}: `)
              ).replace(`Description ${ele}: `, "")}
            </p>
            <p className="text-primary">
              {googleAdCopy.substring(
                googleAdCopy.indexOf("Call to Action")
              ).replace("Call to Action: ", "")}
            </p>
          </div>
        ))}
      </div>
    </Col>
  )
}

export const SuggestionPopularPostsPreview = ({ postInfo = {}, postType, index, selectedSuggestionPost, setSelectedSuggestionPost }) => {
  const [loaded, setLoaded] = useState(false);
  const [previewImg, setPreviewImg] = useState("");
  const [isShowFullPost, setIsShowFullPost] = useState(false);
  const [isShowBtn, setIsShowBtn] = useState(true);
  const userRes = useSelector(({ userRes }) => userRes);

  useEffect(() => {
    if (postInfo && postInfo.text && postInfo.text.length < 220) {
      setIsShowBtn(false);
      setIsShowFullPost(true);
    }
  }, [])

  useEffect(() => {
    let imgKey = "images";
    let previewImgStr = "";
    if (postType === "Facebook") {
      imgKey = "attachments";
    } else if (postType === "Instagram") {
      imgKey = "displayUrl";
    } else if (postType === "LinkedIn") {
      imgKey = "image";
    } else if (postType === "Twitter") {
      imgKey = "media";
    }

    if (postInfo && postInfo[imgKey]) {
      if (typeof postInfo[imgKey] === "object") {
        previewImgStr = (postInfo[imgKey][0]?.image?.uri || postInfo[imgKey][0]?.thumbnail || postInfo[imgKey][0]?.url || postInfo[imgKey][0]);
      } else {
        previewImgStr = postInfo[imgKey];
      }
    }

    if (postType === "Instagram") {
      getInstagramPostImage(previewImgStr);
    } else {
      setPreviewImg(previewImgStr);
    }

    if (postInfo && postInfo.text && postInfo.text.length < 200 && postType !== "Instagram") {
      setIsShowFullPost(true);
    }
  }, [])

  const getInstagramPostImage = async (url) => {
    invokeAxiosGet(`/social-media-post/get-or-generate/getSocialMediaPostBase64Image`, {
      params: { url }
    }).then((response) => {
      setPreviewImg(response.data.data);
    }).catch((error) => {
      setPreviewImg("");
      console.log(error);
    });
  };

  return (
    <React.Fragment>
      {postInfo && postType === "LinkedIn" ? (
        <LinkedinPostPreview
          userRes={userRes} text={postInfo && postInfo?.text}
          preview_img={previewImg} isUploadImage={false}
        />
      ) : postType === "Facebook" ? (
        <FacebookPostPreview
          userRes={userRes} text={postInfo && postInfo?.text}
          preview_img={previewImg} isUploadImage={false}
        />
      ) : postType === "Instagram" ? (
        <InstagramPostPreview
          userRes={userRes} text={postInfo && postInfo?.text}
          preview_img={previewImg} isUploadImage={false}
        />
      ) : (
        <TwitterPostPreview
          userRes={userRes} text={postInfo && postInfo?.text}
          preview_img={previewImg} isUploadImage={false}
        />
      )}
    </React.Fragment>
  )
}

export const RenderBarChart = ({ chartData, chartRef = null }) => {
  return (
    <Col sm="12" xl="8" className="preview-container px-4 py-2">
      {chartData && chartData.X_Data && chartData.X_Data.data && chartData.X_Data.data.length > 0 ? (
        <div className="chart-container mt-4">
          <Bar
            ref={chartRef}
            data={{
              labels: chartData.X_Data.data,
              datasets: [
                {
                  label: chartData.Y_Data.label,
                  data: chartData.Y_Data.data,
                  backgroundColor: [
                    'rgba(255, 99, 132, 0.25)',
                    'rgba(255, 159, 64, 0.25)',
                    'rgba(255, 205, 86, 0.25)',
                    'rgba(75, 192, 192, 0.25)',
                    'rgba(54, 162, 235, 0.25)',
                    'rgba(153, 102, 255, 0.25)'
                  ],
                  borderColor: [
                    'rgb(255, 99, 132)',
                    'rgb(255, 159, 64)',
                    'rgb(255, 205, 86)',
                    'rgb(75, 192, 192)',
                    'rgb(54, 162, 235)',
                    'rgb(153, 102, 255)'
                  ],
                  borderWidth: 1,
                  borderRadius: 4,
                  maxBarThickness: 70,
                }
              ]
            }}
            plugins={[{
              beforeDraw: (chartCtx) => {
                const ctx = chartCtx.canvas.getContext('2d');
                ctx.save();
                ctx.fillStyle = '#fff';
                ctx.fillRect(0, 0, chartCtx.width, chartCtx.height);
                ctx.restore();
              }
            }]}
            options={{
              layout: {
                backgroundColor: '#fff',
                padding: 20
              },
              plugins: {
                title: { display: false },
                legend: { display: false }
              },
              scales: {
                x: {
                  title: {
                    display: true,
                    text: chartData.X_Data.label,
                    font: { size: 20 }
                  }
                },
                y: {
                  title: {
                    display: true,
                    text: chartData.Y_Data.label,
                    font: { size: 20 }
                  }
                }
              }
            }}
          />
        </div>
      ) : (
        <NoDataAvailable text={"No Chart Available"} />
      )}
    </Col>
  )
}
