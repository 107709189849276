import React, { forwardRef, useEffect, useState } from "react";
import { googleLogout } from "@react-oauth/google";
import { IoIosArrowForward } from "react-icons/io";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import classNames from "classnames";
import { Dropdown } from "react-bootstrap";
import { resetReduxData, setPassword, setUserRes, setUsername } from "../../store/reducer";
import { invokeAxiosPost } from "../../utility/invokeAxiosFunction";

export default function ProfileCard({ userRes, toggleLogo, setToggleLogo }) {
  const { first_name, last_name, profile_url, username } = userRes;
  const [isMobileScreen, setIsMobileScreen] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      setIsMobileScreen(window.innerWidth <= 477);
    };

    setTimeout(() => {
      handleResize();
    }, 1000)

    window.addEventListener('resize', handleResize);
    window.addEventListener('load', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('load', handleResize);
    };
  }, []);

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const logout = async () => {
    dispatch(resetReduxData());
    if (userRes?.password === "google") {
      googleLogout();
    }
    localStorage.removeItem("persist:root");
    localStorage.clear();
    dispatch(setPassword("AddllyStage!07*"));
    dispatch(setUsername(""));
    dispatch(setUserRes({}));
    invokeAxiosPost(`/user-api/get-or-create/userLogin`, { id: userRes?.id, isLogOut: true });

    navigate("/");
    setTimeout(() => {
      window.location.reload();
    }, 600);
  };

  const CustomToggle = forwardRef(({ onClick }, ref) => (
    <div className="profileInfo d-flex align-items-center unselectable " id="dropdown-basic" ref={ref} onClick={(e) => { e.preventDefault(); onClick(e); }}>
      <div className="profilePic position-relative">
        {profile_url ? (
          <img src={userRes.profile_url} alt="user" className="rounded-circle userIcon" />
        ) : (
          <div className="profile-avatar">
            {username?.[0]?.toUpperCase()}
          </div>
        )}
      </div>
      <div className={classNames("profileName open")}>
        <h6>{first_name || username || "..."}{' '}{last_name && String(last_name)?.length > 5 ? last_name?.slice(0, 5) + "..." : (last_name || "")}</h6>
        <Link className="text-uppercase text-decoration-none" id="dropdown-basic" ref={ref} onClick={(e) => { e.preventDefault(); onClick(e); }}>
          {userRes && userRes.role && userRes.role === "OrgAdmin" ? "Admin" : "User"}
        </Link>
      </div>
      <div className="profileToggle" onClick={(e) => { e.stopPropagation(); setToggleLogo(!toggleLogo) }}>
        <IoIosArrowForward className="cursor-pointer" />
      </div>
    </div>
  ));

  return (
    <Dropdown drop={isMobileScreen ? "top" : "end"}>
      <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components" className="cursor-pointer" title={` Drop end `}></Dropdown.Toggle>
      <Dropdown.Menu className="p-0 overflow-hidden">
        <Dropdown.Item as={Link} to="/account/my-profile" className="border-bottom py-2">Manage Account</Dropdown.Item>
        <Dropdown.Item onClick={logout} className="py-2">Logout</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}
