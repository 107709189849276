import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { FileUploader } from "react-drag-drop-files";
import { useDispatch, useSelector } from "react-redux";
import { GrClose } from "react-icons/gr";
import { HiOutlineUpload, HiShoppingBag } from "react-icons/hi";
import { BiSolidErrorCircle } from "react-icons/bi";
import { BsCloudUploadFill, BsImages, BsTrashFill } from "react-icons/bs";
import ImageDisplayContent from "../commonComponents/MediaLibrary/ImageDisplayContent";
import NoDataAvailable from "../NoDataAvailable";
import { setLoader } from "../../store/reducer";
import { invokeAxiosPost } from "../../utility/invokeAxiosFunction";

const menus = [
  { name: "Free Images", icon: <BsImages /> },
  { name: "Product Images", icon: <HiShoppingBag /> },
  { name: "Upload Image", icon: <BsCloudUploadFill className="uploadIcon" /> },
];

const EdmMediaLibraryDrawer = (props) => {
  const { blogImagesModal, setBlogImagesModal, postGeneratedImages, setPostGeneratedImages, editorRef, articleId = 0, isDisplayProductImgTab } = props;

  const dispatch = useDispatch();
  const userRes = useSelector(({ userRes }) => userRes);

  const mediaFileTypes = ["JPG", "PNG", "JPEG"];
  const [activeTab, setActiveTab] = useState("Upload from computer");
  const [freeSkinCareImages, setFreeSkinCareImages] = useState([]);
  const [uploadFiles, setUploadFiles] = useState({ files: [], previewUrl: [] });
  const [uploadFileDragging, setUploadFileDragging] = useState(null);
  const [uploadFileError, setUploadFileError] = useState(null);

  useEffect(() => {
    if (blogImagesModal?.currentTab && blogImagesModal.currentTab === "Free Images") {
      const tempImages = [];
      for (let index = 0; index < 42; index++) {
        const checkImgArray = [12, 17, 18, 21, 22, 23, 24, 25, 4, 8];
        if (!checkImgArray.includes(index + 1)) {
          tempImages.push(`https://ecommerce-edm-products.s3.ap-southeast-1.amazonaws.com/skincare-model-images/${index + 1}.jpg`);
        }
      }
      setFreeSkinCareImages(tempImages);
    }
  }, [blogImagesModal?.currentTab])

  const handleCloseBlogImagesModal = () => {
    setBlogImagesModal({ open: false, type: "", currentTab: !isDisplayProductImgTab ? "Free Images" : "Product Images" });
  };

  const handleSaveSelectedImage = async (postImagesObj) => {
    if (!postImagesObj?.image) {
      return false;
    }

    if (editorRef.current) {
      const editor = editorRef.current;
      const selectedNode = editor.selection.getNode();
      if (selectedNode.nodeName === "H1") {
        selectedNode.setAttribute("src", postImagesObj?.image);
      } else {
        if (document.querySelector('[aria-label^="Insert/edit image"]')) {
          document.querySelector('[aria-label^="Insert/edit image"]').click();
        } else {
          document.querySelector('[aria-label^="Reveal or hide additional toolbar items"]')?.click();
          await new Promise(resolve => setTimeout(resolve, 100));
          document.querySelector('[aria-label^="Insert/edit image"]')?.click();
          document.querySelector('[aria-label^="Reveal or hide additional toolbar items"]')?.click();
        }

        await new Promise(resolve => setTimeout(resolve, 500));
        if (document.querySelector('input[role^="combobox"][type="url"]')) {
          document.querySelector('input[role^="combobox"][type="url"]').value = postImagesObj?.image;

          const label = Array.from(document.querySelectorAll(`label[class="tox-label"]`)).find(label => label.textContent === "Image title");
          if (label) {
            const inputId = label.getAttribute("for");
            const tempValue = `${postImagesObj?.imageSubTab ? `${postImagesObj?.imageSubTab} ` : ""}${postImagesObj.currentTab}`;
            document.querySelector(`input[id="${inputId}"]`).value = tempValue;
          }

          const widthLabelElement = Array.from(document.querySelectorAll('label')).find(label => label.textContent === "Width");
          if (widthLabelElement) {
            const widthInputElement = widthLabelElement.nextElementSibling;
            if (widthInputElement && widthInputElement.tagName === "INPUT") {
              widthInputElement.value = 512;
            }
          }
        }
      }
      handleCloseBlogImagesModal();
    }
  };

  const handleOnSelectImageFile = async (file) => {
    if (!file) { return false; }
    const allFiles = [], previewUrl = [];
    allFiles.push(file);
    previewUrl.push(URL.createObjectURL(file));
    setUploadFiles({ files: allFiles, previewUrl: previewUrl });
    setUploadFileError(null);
  };

  const handleOnUploadSelectedImageFile = async () => {
    if (uploadFiles.files && uploadFiles.files.length === 0) {
      return false;
    }

    dispatch(setLoader(true));
    const file = uploadFiles.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      invokeAxiosPost(`/media-library/get-or-generate/saveUploadedImageData`, {
        userId: userRes.id, articleId: articleId,
        imageBase64: reader.result,
        file_name: file.name,
        type: blogImagesModal.type,
        subType: blogImagesModal.subType
      }).then((response) => {
        if (response && response.data && response.data.images && response.data.images.length > 0) {
          setPostGeneratedImages((prev) => ({ ...prev, imageHistory: response.data.images }));
          handleSaveSelectedImage({ ...blogImagesModal, image: response.data.images[0] });
        }
        setUploadFiles({ files: [], previewUrl: [] });
        setUploadFileDragging(null);
        setUploadFileError(null);
        dispatch(setLoader(false));
      }).catch((error) => {
        dispatch(setLoader(false));
      });
    };
  };

  const FileUploaderCustomUi = (
    <div
      className={`fileUploadBlock position-relative ${uploadFileDragging ? "active" : ""}`}
      style={uploadFiles && uploadFiles.files && uploadFiles.files.length > 0 ? { height: "400px" } : {}}
    >
      <BsCloudUploadFill className="uploadIcon" />
      <h4 className="form-label">
        <strong>Choose a file</strong> or drag it here
      </h4>
      <p className="m-0 image-types">
        Supported formats: jpg, jpeg, png; maximum file size: 5MB.
      </p>
      {uploadFileError && uploadFileError !== "" ? (
        <React.Fragment>
          <hr className="w-100 mt-4" />
          <h5 className="fw-normal text-danger d-flex align-items-center">
            <BiSolidErrorCircle className="fs-4 me-2" /> {uploadFileError}
          </h5>
        </React.Fragment>
      ) : null}
    </div>
  );

  return (
    <div className="popupSideBar d-flex flex-column">
      <div className="headerTop d-flex justify-content-between align-items-center gap-3 w-100">
        <p className="m-0">
          <BsImages /> Generate or Upload Image
        </p>
        <button className="bg-white border-0" onClick={handleCloseBlogImagesModal}>
          <GrClose />
        </button>
      </div>
      <div className="genrateBlockImages d-flex flex-wrap w-100">
        <div className="blogSideBar">
          <ul className="mt-4 p-0">
            {menus.map((item, index) => (
              <>
                {item.name === "Free Images" && isDisplayProductImgTab ? (<> </>) : (
                  <li key={item.name + " " + index} className={classNames({ "active": blogImagesModal?.currentTab === item.name })}
                    onClick={(k) => setBlogImagesModal((prev) => ({ ...prev, image: "", currentTab: item.name }))}
                  >
                    <span>{item?.icon} {item.name}</span>
                  </li>
                )}
              </>
            ))}
          </ul>
        </div>
        <div className="tabsData">
          {blogImagesModal.currentTab === "Free Images" && !isDisplayProductImgTab ? (
            <div className="genrateImagesCards d-flex flex-wrap m-0">
              {(freeSkinCareImages && freeSkinCareImages.length > 0) ? (freeSkinCareImages.map((image, index) => (
                <ImageDisplayContent
                  image={image} key={index} viewImgHistory={freeSkinCareImages}
                  postImagesModal={blogImagesModal} setPostImagesModal={setBlogImagesModal}
                  handleSaveSelectedImage={handleSaveSelectedImage} imageIndex={index}
                />
              ))) : (
                <NoDataAvailable text="Images not available yet!" />
              )}
            </div>
          ) : blogImagesModal.currentTab === "Product Images" ? (
            <div className="genrateImagesCards d-flex flex-wrap m-0">
              {(postGeneratedImages && postGeneratedImages.selectedProductImages && postGeneratedImages.selectedProductImages.length > 0) ? (
                postGeneratedImages.selectedProductImages.map((image, index) => (
                  <ImageDisplayContent
                    image={image} key={index} viewImgHistory={postGeneratedImages.selectedProductImages}
                    postImagesModal={blogImagesModal} setPostImagesModal={setBlogImagesModal}
                    handleSaveSelectedImage={handleSaveSelectedImage} imageIndex={index}
                  />
                ))
              ) : (
                <NoDataAvailable text="Product Images not available yet!" />
              )}
            </div>
          ) : blogImagesModal.currentTab === "Upload Image" ? (
            <div className="uploadImageB">
              <div className="d-flex header flex-wrap justify-content-between align-items-center gap-3 mb-4">
                <div className="d-flex flex-wrap tabsClick ">
                  {["Upload from computer", "Gallery"].map((name, index) => (
                    <button onClick={() => setActiveTab(name)} className={classNames({ active: name === activeTab })} key={index + name}>
                      {name}
                      {name === "Gallery" ? <span className="total-images-circle">
                        <span className="total-images">
                          {postGeneratedImages?.imageHistory?.length < 10 ?
                            `0${postGeneratedImages?.imageHistory?.length || 0}`
                            : (postGeneratedImages?.imageHistory?.length || "00")}
                        </span>
                      </span> : null}
                    </button>
                  ))}
                </div>
                <div className="selectIamgesB d-flex align-items-center">
                  {activeTab !== "Gallery" && (
                    <button
                      type="button" className="blogButton border-0" onClick={handleOnUploadSelectedImageFile}
                      disabled={uploadFiles && uploadFiles.previewUrl && uploadFiles.previewUrl.length > 0 ? false : true}
                    >
                      <HiOutlineUpload className="fs-5" /> <span>Upload Images</span>
                    </button>
                  )}
                </div>
              </div>
              {activeTab === "Gallery" ? (
                <div className="genrateImagesCards d-flex flex-wrap">
                  {postGeneratedImages?.imageHistory?.map((images, index) => (
                    <ImageDisplayContent
                      image={images} imageIndex={index} viewImgHistory={postGeneratedImages?.imageHistory}
                      postImagesModal={blogImagesModal} setPostImagesModal={setBlogImagesModal}
                      handleSaveSelectedImage={handleSaveSelectedImage}
                    />
                  ))}
                </div>
              ) : (
                <React.Fragment>
                  <div className="brandchooseFile ai model pe-2 overflow-auto" style={{ height: "calc(100vh - 275px)" }}>
                    <div className={classNames("d-flex gap-3 flex-wrap genrateButton_ genrateImagesCards ", { adddUploadedImages: !!uploadFiles.previewUrl.length })}>
                      <div className="brandAi-filUploader chooseCard">
                        <FileUploader
                          name="file" types={mediaFileTypes} maxSize={5} children={FileUploaderCustomUi}
                          multiple={false} classes={`w-100`} handleChange={handleOnSelectImageFile}
                          onDraggingStateChange={(dragging) => setUploadFileDragging(dragging)} dropMessageStyle={{ display: "none" }}
                          onSizeError={() => setUploadFileError("File size exceeds the allowable limit. Please upload a smaller file.")}
                          onTypeError={() => setUploadFileError("Invalid file type. Please upload a file with a supported file type.")}
                        />
                      </div>
                      {uploadFiles && uploadFiles.previewUrl && uploadFiles.previewUrl.length > 0 && uploadFiles.previewUrl.map((file, index) => (
                        <div className="image-preview-content chooseCard position-relative card">
                          <img src={file} alt="Uploaded-img" />
                          <div className="remove-btn">
                            <button className="btn" type="button" onClick={(e) => {
                              setUploadFiles({
                                files: uploadFiles.files.filter((e1, i1) => i1 !== index),
                                previewUrl: uploadFiles.previewUrl.filter((e1, i1) => i1 !== index),
                              })
                            }}>
                              <BsTrashFill />
                            </button>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </React.Fragment>
              )}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  )
}

export default EdmMediaLibraryDrawer;
