import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Select from "react-select";
import Tree from "react-d3-tree";
import { Tooltip } from "react-tooltip";
import { Col, Form, Modal, Row } from "react-bootstrap";
import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { Controller } from "react-hook-form";
import ReactFlagsSelect from "react-flags-select";
import { IoIosArrowDown } from "react-icons/io";
import { BsInfoCircleFill, BsStars } from "react-icons/bs";
import { AiTypeOptions } from "../../../data";
import { oneClickBlogIcon } from "../../../Assets/Icons";
import { handleCustomSelectOption, supportedLanguageList } from "../../../utility/hepler";
import { handleSetLanguageAndGeoLocation } from "../../../utility/common";
import toast from "react-hot-toast";
import { LuZoomIn } from "react-icons/lu";
import { FiZoomOut } from "react-icons/fi";
import { setLoader, setUserRes } from "../../../store/reducer";
import { invokeAxiosPost } from "../../../utility/invokeAxiosFunction";

const ContentForm = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { register, watch, setValue, errors, onContinue, onSubmit, handleSubmit, control } = props;
  const userRes = useSelector(({ userRes }) => userRes);
  const [suggestedTopic, setsuggestedTopic] = useState([]);
  const [showModel, setShowModel] = useState(false);
  const canvasWrapper = useRef(null);
  const [treeGraphData, setTreeGraphData] = useState({});
  const [zoom, setZoom] = useState(0.5);
  const [isMobileView, setIsMobileView] = useState(false)
  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 432);
    };

    setTimeout(() => {
      handleResize();
    }, 500);

    window.addEventListener('resize', handleResize);
    window.addEventListener('load', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('load', handleResize);
    };
  }, [])

  const handleSetAndConvertTreeGraphData = (data) => {
    const convertToArrayStructure = (data) => {
      if (!data || typeof data !== 'object') {
        return null;
      }

      if (Array.isArray(data)) {
        return {
          name: 'Parent',
          children: data.map(convertToArrayStructure)
        };
      }

      const result = {
        name: (data?.term || data?.question),
        children: []
      };

      if (data.results && Array.isArray(data.results)) {
        result.children = data.results.map(convertToArrayStructure);
      }

      return result;
    };

    let convertedObject = {};
    if (data && data.length > 0) {
      const singleTerm = data[0];
      convertedObject = convertToArrayStructure(singleTerm);
    }
    setTreeGraphData(convertedObject);
    localStorage.setItem("historyGeneratedGraph", JSON.stringify(convertedObject));
    setShowModel(true);
  }

  const generateTopicSuggestion = async () => {
    try {
      if (watch('topic').length < 2) {
        toast.error('Topic should have minimum 2 characters required');
        return
      }

      const hidtoryGraph = localStorage.getItem("historyGeneratedGraph");
      if (hidtoryGraph !== null) {
        setTreeGraphData(JSON.parse(hidtoryGraph));
        setShowModel(true);
        return;
      }

      if (Number(userRes.credits) < (userRes?.deductCredits?.["Topic Suggestions"] || 2)) {
        toast.error("Sorry, you don't have enough credits to perform this action.", { id: "Toast-01" });
        return;
      }

      console.log(watch('geoLocation').toLowerCase());
      let newlanguage;
      const languageScrap = watch('lan');
      if (languageScrap === "Thai") {
        newlanguage = "th";
      } else if (languageScrap === "Bahasa Melayu" || languageScrap === "Bahasa Indonesia") {
        newlanguage = "id";
      } else if (languageScrap === "Mandarin") {
        newlanguage = "zh-CN";
      } else if (languageScrap === "Vietnamese") {
        newlanguage = "vi";
      } else {
        newlanguage = "en";
      }

      dispatch(setLoader(true));
      const payload = { topic: watch("topic"), geoLocation: watch("geoLocation").toLowerCase(), language: newlanguage };

      const response = await invokeAxiosPost("/topic-suggestion/get-or-generate/createOrUpdateTopicSuggestions", payload);
      dispatch(setLoader(false));
      if (response && response.success === 1 && response.data && response.data.data && response.data.data.length > 0) {
        handleSetAndConvertTreeGraphData(response.data.data);

        dispatch(setUserRes({ ...userRes, credits: userRes.credits - (userRes?.deductCredits?.["Topic Suggestions"] || 2) }));
        const scrollWrapper = document.getElementById('scroll-wrapper');
        if (scrollWrapper) { scrollWrapper.scrollTo({ top: 0, behavior: 'smooth' }); }
      } else {
        toast.error("Topic Suggestions not generated, Please try with different topic.", { id: "Toast-01" });
      }
    } catch (error) {
      toast.error("Something went wrong, Please try again later.", { id: "Toast-01" });
      dispatch(setLoader(false));
    }
  };

  const handleZoomInOut = (value) => {
    if (value === "in") {
      if (zoom < 1) {
        setZoom((Number(zoom) + 0.1)?.toFixed(1));
      }
    } else if (value === "out") {
      if (zoom > 0.1) {
        setZoom((Number(zoom) - 0.1)?.toFixed(1))
      }
    }
  }

  const renderCustomNode = ({ nodeDatum }) => (
    <React.Fragment>
      <circle r="15" data-tooltip-id={`my-tooltip`} data-tooltip-content="Click to use 🚀" data-tooltip-place="bottom" onClick={() => { setValue("topic", nodeDatum.name); setShowModel(false) }} ></circle>
      <g class="rd3t-label">
        <text class="rd3t-label__title" text-anchor="start" x="40" onClick={() => { setValue("topic", nodeDatum.name); setShowModel(false) }}>
          {nodeDatum.name}
        </text>
      </g>
    </React.Fragment>
  );

  return (
    <React.Fragment>
      <form onSubmit={handleSubmit(onContinue)}>
        <div className="genrateFields">
          <div className="fields">
            <label htmlFor="">
              Tell me your topic, and let's create an engaging article together. <span className="astrick">*</span>
            </label>
            <div className="inputField">
              <input name="topic" type="text" {...register("topic")} placeholder="Enter your topic" className={classNames("w-100 ", { 'is-invalid': !!errors?.topic?.message })} style={{ paddingRight: '50px' }} />
              <div className="glitterStar cursor-pointer" onClick={generateTopicSuggestion} data-tooltip-id="my-tooltip" data-tooltip-content="Get Suggestions" data-tooltip-place="bottom"></div>
              <div className="invalid">{errors?.topic?.message}</div>
            </div>
            {suggestedTopic && suggestedTopic.length > 0 && (
              <div className="searchSuggestionBlock">
                <ul className="p-0">
                  {suggestedTopic.map((item, index) => (
                    <li key={index} className="position-relative">
                      <Link
                        className="text-decoration-none"
                        data-tooltip-id={`nt-tooltion`} data-tooltip-content="Click to use 🚀" data-tooltip-place="right"
                      >
                        {item}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
          <div className="fields">
            <label htmlFor="">
              Provide one or two word keyword for effective assistance. <span className="astrick">*</span>
            </label>
            <div className="inputField">
              <input
                name="keywords" type="text" {...register("keywords")} className={`w-100 ${errors.topic ? "is-invalid" : ""}`}
                placeholder="Enter keywords (e.g. Financial growth)"
              />
              <div className="text-danger">{errors?.keywords?.message}</div>
            </div>
          </div>
          <Row className="chooseModel">
            <Col className="fields m-0 mb-3" sm={12} md={6} lg={4}>
              <div className="form-group">
                <label>
                  Choose your AI Model
                  <BsInfoCircleFill className="infoIconSvg"
                    data-tooltip-id="my-tooltip" data-tooltip-place="right"
                    data-tooltip-content="AI model suggestions is based on user inputs"
                  />
                </label>
                <Controller
                  control={control} name="aiType" {...register("aiType")}
                  render={({ field: { onChange, value, name, ref } }) => (
                    <Select
                      name={name} placeholder="Select AI Type" isSearchable={false}
                      options={AiTypeOptions} isClearable={false} inputRef={ref}
                      className={`custom-select ${errors.aiType ? "is-invalid" : ""} cust-blog-se`}
                      classNamePrefix="custom-select"
                      value={AiTypeOptions.find((c) => c.value === value)}
                      onChange={(e) => onChange(e.value)}
                    />
                  )}
                />
                {/* {watch("aiType") !== "" && watch("aiType") !== "GPT 3.5" && (
                  <div className="selectedOptions">
                    <span>Addlly suggests using <strong>GPT 3.5</strong></span>
                  </div>
                )} */}
              </div>
            </Col>
            <Col className="fields m-0 mb-3" sm={12} md={6} lg={4}>
              <div className="form-group position-relative languageDrop">
                <label>Select your geo-location</label>
                <Controller
                  control={control} name="geoLocation" {...register("geoLocation")}
                  render={({ field: { onChange, value, name, ref } }) => (
                    <ReactFlagsSelect
                      className={'reactFlag p-0 w-100'} searchable={true} selected={value}
                      onSelect={(val) => onChange(val)}
                    />
                  )}
                />
                <div className='arrowIcon'>
                  <IoIosArrowDown className='downArrowIcon' />
                </div>
              </div>
            </Col>
            <Col className="fields m-0 mb-3" sm={12} md={6} lg={4}>
              <label htmlFor="">Choose a Language <span className="astrick">*</span></label>
              <Controller
                control={control} name="lan" {...register("lan")}
                render={({ field: { onChange, value, name, ref } }) => (
                  <Select
                    className={`custom-select ${errors.language ? "is-invalid" : ""}`}
                    classNamePrefix="custom-select" placeholder="Select Language"
                    options={supportedLanguageList} isClearable={false} isSearchable={false}
                    value={supportedLanguageList.find(c => c.value === value) || ""}
                    onChange={(event) => { onChange(event.value); handleSetLanguageAndGeoLocation(event, setValue); }}
                    getOptionLabel={handleCustomSelectOption}
                  />
                )}
              />
              <div className="invalid">{errors.lan?.message}</div>
            </Col>
          </Row>
        </div>
        {/* <div className="generateChart gap-3">
          <Controller
            control={control} name="generateChart" {...register("generateChart")}
            render={({ field: { onChange, value, name, ref } }) => (
              <Form.Check
                type={"checkbox"} id={`checkbox-generateChart`} name="generateChart"
                onChange={(e) => onChange(e.target.checked)} checked={value}
                label={<div className="textBlock ms-3">
                  <h6>Generate Chart?</h6>
                </div>}
              />
            )}
          />
          <p className="GenerateChart">
            Generate a chart based on numerical data in your article. Note: Chart generation may take an extra 60
            seconds, and it requires available numeric data.
          </p>
        </div> */}
        <div className="genrateBtn activeBtn d-flex align-items-center">
          <button type="submit"
            className={classNames({ activeBtn: watch("topic") !== "" && watch("keywords") !== "" && Object.keys(errors).length === 0 ? true : false })}
            disabled={watch("topic") !== "" && watch("keywords") !== "" && Object.keys(errors).length === 0 ? false : true}
          >
            {true ? <BsStars /> : <img src={oneClickBlogIcon.spaceRocket} alt="spaceRocket" />}
            Generate a blog
          </button>
          <BsInfoCircleFill
            className="text-decoration-none outline-0  infoIconSvg " data-tooltip-id={"my-tooltip"} data-tooltip-place={isMobileView ? 'bottom' : 'right'}
            data-tooltip-content={`Will utilize ${userRes?.deductCredits?.["1-Click Blog"] || 2} Addlly credits`}
          />
        </div>
      </form>

      {showModel && (
        <Modal className="maingenrateBlock" centered size="xl" show={showModel} onHide={() => setShowModel(false)}>
          <Modal.Header closeButton>
            <Modal.Title>
              <span className="me-4">Topic suggestions</span>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="p-0 overflow-hidden">
            <Row className="m-3 chooseModel">
              <Col sm="3" className="topicBlockBottom side-menu p-0">
                <div className="optimizationBlock shadow-sm h-100">
                  <div className="genrateFields cardOptimiz">
                    <div className="topicBlock mb-3">
                      <span className="d-block mb-1">Topic </span>
                      <textarea
                        type="text" className="addllyForm-control h-auto" placeholder="Insert your topic here"
                        {...register("topic")} rows={3}
                      />
                    </div>
                    <div className="fields mb-3">
                      <div className="form-group position-relative  languageDrop">
                        <label>Select your geo-location</label>
                        <Controller
                          control={control} name="geoLocation" {...register("geoLocation")}
                          render={({ field: { onChange, value, name, ref } }) => (
                            <ReactFlagsSelect
                              className={' bg-white rounded reactFlag p-0 w-100'} searchable={true} selected={value}
                              onSelect={(val) => onChange(val)}
                            />
                          )}
                        />
                        <div className='arrowIcon'>
                          <IoIosArrowDown className='downArrowIcon' />
                        </div>
                      </div>
                    </div>
                    <div className="fields mb-3">
                      <div className="form-group">
                        <label htmlFor="">Choose a Language <span className="astrick">*</span></label>
                        <Controller
                          control={control} name="lan" {...register("lan")}
                          render={({ field: { onChange, value, name, ref } }) => (
                            <Select
                              className={`custom-select ${errors.language ? "is-invalid" : ""}`}
                              classNamePrefix="custom-select" placeholder="Select Language"
                              options={supportedLanguageList} isClearable={false} isSearchable={false}
                              value={supportedLanguageList.find(c => c.value === value) || ""}
                              onChange={(event) => { onChange(event.value); handleSetLanguageAndGeoLocation(event, setValue); }}
                              getOptionLabel={handleCustomSelectOption}
                            />
                          )}
                        />
                        <div className="invalid">{errors.lan?.message}</div>
                      </div>
                    </div>
                    <div>
                      <div className="d-flex justify-content-end align-items-center gap-2">
                        <BsInfoCircleFill
                          className="text-decoration-none cursor-pointer outline-0 infoIconSvg" data-tooltip-id="my-tooltip" data-tooltip-place="left"
                          data-tooltip-content={`Will utilize ${userRes?.deductCredits?.["Topic Suggestions"] || 2} Addlly credits`}
                        />
                        <button className="btn d-flex align-items-center" style={{ backgroundColor: "#3439FF", color: "white" }} onClick={() => generateTopicSuggestion()} >
                          Regenrate</button>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
              <Col sm="9" style={{ position: 'relative' }}>
                <span className="zoomInOut" style={{ position: 'absolute', top: 20, right: 30 }} >
                  <LuZoomIn size={30} className="p-1 border rounded shadow-sm mx-1 cursor-pointer bg-white" onClick={() => handleZoomInOut("in")} />
                  <FiZoomOut size={30} className="p-1 border rounded shadow-sm mx-1 cursor-pointer bg-white" onClick={() => handleZoomInOut("out")} />
                </span>
                <div className="tree-graph-wrapper shadow-sm" style={{ height: '70vh' }} ref={canvasWrapper} >
                  <Tree
                    data={treeGraphData} renderCustomNodeElement={renderCustomNode}
                    rootNodeClassName="node__root" branchNodeClassName="node__branch" leafNodeClassName="node__leaf"
                    collapsible={false} zoomable={true} draggable={true} nodeSize={{ x: 500, y: 50 }}
                    zoom={zoom} scaleExtent={{ min: 0.1, max: 1 }} translate={{ x: 200, y: 400 }}
                  />
                </div>
              </Col>
            </Row>
          </Modal.Body>
          <Tooltip id="my-tooltip" style={{ borderRadius: "6px", fontSize: "13px", padding: "4px 12px", maxWidth: "340px", zIndex: 9999 }} />
        </Modal>
      )}
    </React.Fragment>
  );
};

export default ContentForm;
