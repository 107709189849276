import React from "react";

const ButtonLoader = () => {
  return (
    <div className="btn-custom-loader">
      <div className="stage">
        <div className="dot-falling"></div>
      </div>
    </div>
  )
}

export default ButtonLoader;
