import SignIn from "./Page/SignIn";
import Account from "./Page/Account";
import History from "./Page/History";
import Dashboard from "./Page/Dashboard";
import SeoOptBlog from "./Page/SeoOptBlog";
import LandingPage from "./Page/LandingPage";
import Newsletters from "./Page/Newsletters";
import ResetPassword from "./Page/ResetPassword";
import SeoBlogWriter from "./Page/SeoBlogWriter";
import ForgotPassword from "./Page/ForgotPassword";
import PdfArticleWriter from "./Page/PdfArticleWriter";
import TopicSuggestions from "./Page/TopicSuggestions";
import AiOverviewWriter from "./Page/AiOverviewWriter";
import PressReleaseWriter from "./Page/PressReleaseWriter";
import ProductImageLibrary from "./Page/ProductImageLibrary";
import ECommerceBlogWriter from "./Page/ECommerceBlogWriter";
import SocialMediaPostWriter from "./Page/SocialMediaPostWriter";

const ROUTES = [
  {
    path: "/",
    Component: SignIn,
    isPrivate: false,
  },
  {
    path: "/landing-page",
    Component: LandingPage,
    isPrivate: false,
  },
  {
    path: "/dashboard",
    Component: Dashboard,
    isPrivate: true,
  },
  {
    path: "/history",
    Component: History,
    isPrivate: true,
  },
  {
    path: "/1-click-blog",
    Component: SeoBlogWriter,
    isPrivate: true,
  },
  {
    path: "/1-click-blog/:id",
    Component: SeoBlogWriter,
    isPrivate: true,
  },
  {
    path: "/blog-co-pilot",
    Component: SeoOptBlog,
    isPrivate: true,
  },
  {
    path: "/blog-co-pilot/:id",
    Component: SeoOptBlog,
    isPrivate: true,
  },
  {
    path: "/pdf-to-article",
    Component: PdfArticleWriter,
    isPrivate: true,
  },
  {
    path: "/pdf-to-article/:id",
    Component: PdfArticleWriter,
    isPrivate: true,
  },
  {
    path: "/social-media-post-writer",
    Component: SocialMediaPostWriter,
    isPrivate: true,
  },
  {
    path: "/social-media-post-writer/:id",
    Component: SocialMediaPostWriter,
    isPrivate: true,
  },
  {
    path: "/account/:tab",
    Component: Account,
    isPrivate: true,
  },
  {
    path: "/account/:tab/:type",
    Component: Account,
    isPrivate: true,
  },
  {
    path: "/product-image-library",
    Component: ProductImageLibrary,
    isPrivate: true,
  },
  {
    path: "/newsletters",
    Component: Newsletters,
    isPrivate: true,
  },
  {
    path: "/newsletters/:id",
    Component: Newsletters,
    isPrivate: true,
  },
  {
    path: "/press-release",
    Component: PressReleaseWriter,
    isPrivate: true,
  },
  {
    path: "/press-release/:id",
    Component: PressReleaseWriter,
    isPrivate: true,
  },
  {
    path: "/topic-suggestions",
    Component: TopicSuggestions,
    isPrivate: true,
  },
  {
    path: "/e-commerce-blog",
    Component: ECommerceBlogWriter,
    isPrivate: true,
  },
  {
    path: "/e-commerce-blog/:id",
    Component: ECommerceBlogWriter,
    isPrivate: true,
  },
  {
    path: "/ai-overview",
    Component: AiOverviewWriter,
    isPrivate: true,
  },
  {
    path: "/forgot-password",
    Component: ForgotPassword,
  },
  {
    path: "/reset-password",
    Component: ResetPassword,
  },
];

export { ROUTES };
