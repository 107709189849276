import React, { useState } from "react";
import toast from "react-hot-toast";
import { BsArrowRight } from "react-icons/bs";
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card } from "react-bootstrap";
import { ReactSVG } from "react-svg";
import { blogCoPilotIcon } from "../../Assets/Icons";
import ButtonLoader from "../ui/ButtonLoader";
import { setPressReleaseWriterInfo, setPressReleaseWriterStep } from "../../store/reducer";

const FirstStepPressRelease = ({ isButtonLoader, setIsButtonLoader }) => {
  const dispatch = useDispatch();
  const userRes = useSelector(({ userRes }) => userRes);
  const pressReleaseWriterInfo = useSelector(({ pressReleaseWriterInfo }) => pressReleaseWriterInfo);

  const [selectedCard, setSelectedCard] = useState(pressReleaseWriterInfo?.selectedCard);
  const [selectedType, setSelectedType] = useState(pressReleaseWriterInfo?.type || pressReleaseWriterInfo?.selectedCard);

  const handleType = (value) => {
    setSelectedType(value);
    dispatch(setPressReleaseWriterInfo({
      ...pressReleaseWriterInfo, selectedCard: value,
      stepDone: pressReleaseWriterInfo.stepDone < 2 ? 2 : (pressReleaseWriterInfo.stepDone || 1),
    }));
  }

  const handleOnChangeCardType = async (type) => {
    if (selectedCard === type) {
      dispatch(setPressReleaseWriterStep(2));
      return false;
    };

    if (Number(userRes.credits) < (userRes?.deductCredits?.["Press Release"] || 3)) {
      toast.error("Sorry, you don't have enough credits to perform this action.", { id: "Toast-01" });
      return;
    }

    setIsButtonLoader(true);
    await new Promise(resolve => setTimeout(resolve, 1000));
    setIsButtonLoader(false);

    setSelectedCard(type); dispatch(setPressReleaseWriterStep(2));
    dispatch(setPressReleaseWriterInfo({ ...pressReleaseWriterInfo, selectedCard: type, stepDone: pressReleaseWriterInfo.stepDone < 2 ? 2 : pressReleaseWriterInfo.stepDone }));
  }

  return (
    <div className="addllyFormWrap">
      <div className="selectBlock d-flex flex-wrap align-items-center justify-content-between mx-2 mx-md-auto">
        <div className="">
          <b>Select Type</b> <span>(Select any 1)</span>
        </div>
        <button
          type="button" className="d-flex align-items-center gap-2 bg-transparent border-0 selectBbutton"
          onClick={() => handleOnChangeCardType(selectedType)} disabled={!selectedType || selectedType === ""}
        >
          Describe Details <BsArrowRight />
        </button>
      </div>
      <div className={`social-media-images ${pressReleaseWriterInfo.stepDone > 2 ? "not-selectable" : ""}`}>
        {[
          {
            label: "Provide a Brief Description", value: "Desc", icon: blogCoPilotIcon.contractIcon, iconColor: "#E6EBFF",
            desc: "Describe in one sentence the topic you’d like to write about.",
          },
          {
            label: "Insert Your Reference Link", value: "Link", icon: blogCoPilotIcon.referenceLinkIcon, iconColor: "#FFBE9D33",
            desc: "Insert a reference link as a reference for the topic you’d like to write about.",
          },
          {
            label: "Upload Your PDF", value: "PDF", icon: blogCoPilotIcon.pdfIcon, iconColor: "#A7FF1733",
            desc: "Use the PDF file as a reference for the press release.",
          },
        ].map((ele, index) => (
          <div className={`social-media-card text-center ${selectedType === ele.value ? "active" : ""}`}
            key={index} onClick={() => { handleType(ele.value) }}
          >
            <div className={`radioBtn`}>
              <div className={`radioBtnIn`}></div>
            </div>
            <div className="imageFrame lightBlue ms-auto me-auto" style={{ backgroundColor: ele.iconColor, border: `1px solid ${ele.iconColor}` }}>
              <ReactSVG src={ele.icon} />
            </div>
            <h5 className="card-title w-100" type="button"><strong>{ele.label}</strong></h5>
            <p className="text-center">{ele.desc}</p>
          </div>
        ))}
      </div>
      <div className="buttonDiv">
        <Button className="button" onClick={() => handleOnChangeCardType(selectedType)}
          disabled={!selectedType || selectedType === "" || isButtonLoader}
        >
          {isButtonLoader ? <>Please wait <ButtonLoader /></> : <>Describe Details <BsArrowRight /></>}
        </Button>
      </div>
    </div>
  )
}

export default FirstStepPressRelease;
