import React, { useEffect, useMemo, useState } from "react";
import Swal from "sweetalert";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { MenuItem } from "@mui/material";
import { ReactSVG } from "react-svg";
import { toast } from "react-hot-toast";
import { useDispatch } from "react-redux";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { IoEyeOutline } from "react-icons/io5";
import { RiInboxUnarchiveFill } from "react-icons/ri";
import DropdownMenu from "../ui/Menu";
import TableLoader from "../ui/TableLoader";
import CustomDataTable from "../ui/DataTable";
import { setLoader } from "../../store/reducer";
import Filters from "../commonComponents/Filters";
import withECommerceHandler from "../../Hoc/ECommerceHoc";
import ArticlePreview from "../commonComponents/ArticlePreview";
import { SortArrow, tableDateFormatter } from "../../utility/common";
import { articleHistoryIcons, dashboardIcon, dataTableIcons } from "../../Assets/Icons";
import { invokeAxiosPost } from "../../utility/invokeAxiosFunction";

const ECommerceBlogHistory = (props) => {
  const {
    historyData, textWrap, setTextWrap, starred, setStarred, setSearch, loading, filters, setFilters, selectedRows, clearRow,
    setSelectedRows, handleArchiveAll, getArticleAndPostHistoryData, totalCount, setPage, limit, page,
    handlePinArticle, handleDelete, handleSort, sortField, userRes, getHistoryShortFlowData, isFetching, search, handleGetProductDetailsById
  } = props;

  const dispatch = useDispatch();
  const [article, setArticle] = useState({ id: "", type: "" });
  const [trainedHistoryData, setTrainedHistoryData] = useState([]);
  const [showOffset, setShowOffset] = useState(false);

  useEffect(() => {
    if (historyData && historyData.length > 0) {
      const historyTempData = [];
      for (let index = 0; index < historyData.length; index++) {
        const element = historyData[index];
        if (element.is_trained && element.is_trained === 1) {
          historyTempData.push(element);
        }
      }
      setTrainedHistoryData(historyTempData);
    }
  }, [historyData])

  const handleOpenArticle = (id, type) => {
    setArticle(pre => ({ ...pre, id: id, type }));
    setShowOffset(true);
  };

  const handleTrainOrUnTrainArticle = (id, type) => {
    if (type === "train" && trainedHistoryData && trainedHistoryData.length >= 4) {
      toast.error("You have already trained 4 articles.");
      return false;
    }

    Swal({
      title: "Are you sure?",
      text: `Do you want to ${type} the selected item?`,
      icon: "warning", dangerMode: true,
      buttons: { cancel: "Cancel", confirm: `Yes, ${type} it!` },
    }).then(async (isConfirmed) => {
      if (isConfirmed) {
        try {
          dispatch(setLoader(true));
          const payload = { type: type, userId: userRes.id, articleType: "E-Commerce Blog" };
          const response = await invokeAxiosPost(`/article-history/get-or-save/articleTrainOrUnTrainedByUser?articleId=${id}`, payload);
          if (response && response.data && response.data.success === 1) {
            dispatch(setLoader(false));
            toast.success(response.data.msg, { id: "Toast-01" });
            getHistoryShortFlowData();
          } else {
            dispatch(setLoader(false));
            toast.error("Something went wrong, Please try again!", { id: "Toast-01" });
          }
        } catch (error) {
          dispatch(setLoader(false));
        }
      }
    });
  };

  const columns = useMemo(() => [
    {
      id: "title",
      name: <div className="d-flex">Title {SortArrow(sortField, "title")}</div>,
      selector: (row) => (
        <div className=" d-flex align-items-center gap-2" onClick={() => { handleTableRowClick(row) }}>
          <div className={classNames("tableValues", { showFullDes: textWrap })}>
            {row.topic && row.topic !== "" ? (
              <OverlayTrigger placement="bottom" flip overlay={<Tooltip id="tooltip">{row.topic}</Tooltip>}>
                <h6>{row.topic || "-"}</h6>
              </OverlayTrigger>
            ) : (
              <h6>{row.topic || "-"}</h6>
            )}
            <p>{row.keyword}</p>
          </div>
          <OverlayTrigger placement="bottom" flip overlay={<Tooltip style={{ zIndex: 9999 }} id="tooltip">
            {row.is_pinned ? "Remove from Starred" : "Add to Starred"}
          </Tooltip>}>
            <img className={classNames({ 'hover-star': !starred })} onClick={(e) => { e.stopPropagation(); handlePinArticle(row.type, row.id, row?.is_pinned) }}
              src={row.is_pinned ? dashboardIcon.starFill : dashboardIcon.star} alt="star" loading="lazy"
            />
          </OverlayTrigger>
        </div>
      ),
      sortField: "topic",
      sortable: true,
    },
    {
      id: "created_at",
      name: <div className="d-flex">Created On {SortArrow(sortField, "created_at")}</div>,
      selector: (row) => (
        <div onClick={() => { handleTableRowClick(row) }}>{tableDateFormatter(row?.created_at)}</div>
      ),
      sortable: true,
      sortField: "created_at",
    },
    {
      id: "AI_type",
      name: <div className="d-flex"> AI Type {SortArrow(sortField, "AI_type")}</div>,
      selector: (row) => row.AI_type,
      sortable: true,
      sortField: "AI_type",
    },
    {
      id: "language",
      name: <div className="d-flex"> Language {SortArrow(sortField, "language")}</div>,
      selector: (row) => row.language,
      sortable: true,
      sortField: "language",
    },
    {
      id: "status",
      name: <div className="d-flex ">Status {SortArrow(sortField, "status")}</div>,
      selector: (row) => (
        <div className="d-flex justify-content-center align-items-center" onClick={() => { handleTableRowClick(row) }}>
          <div className="dotStatus">
            <span
              className={`d-block`}
              style={{
                width: "8px", height: "8px", borderRadius: "50%",
                backgroundColor: row.status === "completed" ? "#84CC16" : row.status === "pending" ? "#F59E0B" : "#EF4444",
              }}
            />
          </div>
          {row.status === "completed" ? "Done" : row.status === "pending" ? "In Progress" : "Error"}
        </div>
      ),
      sortable: true,
      sortField: "status",
    },
    {
      id: "trainedStatus",
      name: "Trained Status",
      selector: (row) => (
        <React.Fragment>
          {row.is_trained && row.is_trained === 1 ? (
            <div className="d-flex justify-content-center align-items-center">
              <div className="dotStatus">
                <span
                  className={`d-block`}
                  style={{ width: "8px", height: "8px", borderRadius: "50%", backgroundColor: "#84CC16" }}
                />
              </div> Trained
            </div>
          ) : row.status === "completed" ? (
            <button className="refund-button" type="button" onClick={() => handleTrainOrUnTrainArticle(row?.id, "train")}>
              Train Article
            </button>
          ) : (<h6>-</h6>)}
        </React.Fragment>
      ),
    },
    {
      id: "sort",
      name: 'Action',
      width: '60px',
      selector: (row) => {
        return (
          <div className="popup">
            <DropdownMenu>
              {row.status !== 'pending' && (
                <MenuItem onClick={() => handleOpenArticle(row.id, row?.type)}><IoEyeOutline /> Preview Article</MenuItem>
              )}
              <MenuItem onClick={() => handleGetProductDetailsById(row.id, true)}><ReactSVG src={dataTableIcons.pencil} /> Edit</MenuItem>
              <MenuItem onClick={() => handleDelete(row?.id, row?.type, "archive")}>
                <RiInboxUnarchiveFill /> Archive</MenuItem>
            </DropdownMenu>
          </div>
        );
      },
    },
  ], [handleDelete, handleGetProductDetailsById, handlePinArticle, sortField, starred, textWrap])

  const trainedColumns = useMemo(() => {
    const tempColumns = [...columns];
    const getIndex = tempColumns.findIndex((column) => column.id === "trainedStatus");
    if (getIndex !== -1) {
      tempColumns[getIndex] = {
        id: "trainedStatus",
        name: "Trained Status",
        selector: (row) => (
          <button className="refund-button" type="button" onClick={() => handleTrainOrUnTrainArticle(row?.id, "unTrain")}>
            UnTrain
          </button>
        ),
      };
    }
    return tempColumns;
  }, [columns])

  const handleTableRowClick = (row) => {
    if (row.status !== 'pending') {
      handleOpenArticle(row.id, row?.type);
    } else {
      handleGetProductDetailsById(row.id, true)
    }
  };

  return (
    <React.Fragment>
      <div className=" d-flex gap-2 flex-column tableHeading">
        <div className=" d-flex justify-content-between mb-4">
          <span className="">Recent History</span>
          <Link to="/history" className="">View All</Link>
        </div>
        <div className="filters">
          <Filters
            starred={starred} setStarred={setStarred} textWrap={textWrap} setSearchFilter={setSearch}
            setTextWrap={setTextWrap} setFilters={setFilters} filters={filters} selectedRows={selectedRows}
            getArticleAndPostHistoryData={getArticleAndPostHistoryData} setSelectedRows={setSelectedRows}
            handleArchiveAll={handleArchiveAll} setPage={setPage} isFetching={isFetching}
          />
        </div>

        <div className="historyTableBlock">
          <CustomDataTable
            progressPending={loading} progressComponent={<TableLoader limit={limit} />}
            columns={columns} totalCount={totalCount} pagination={true} data={historyData}
            itemsPerPage={limit} selectableRows={true} noDataComponent={<NoDataFound search={search} />}
            onSelectedRowsChange={setSelectedRows} setPage={setPage} page={page} onSort={handleSort}
            handleTableRowClick={handleTableRowClick} clearSelectedRows={clearRow}
          />
        </div>
      </div>

      {trainedHistoryData && trainedHistoryData.length > 0 && (
        <div className="d-flex gap-2 flex-column tableHeading mt-5">
          <div className=" d-flex justify-content-between mb-3">
            <span className="">Trained Article History ( Select Max 4 )</span>
          </div>
          <div className="historyTableBlock">
            <CustomDataTable
              progressPending={loading} progressComponent={<TableLoader limit={limit} />}
              columns={trainedColumns} totalCount={totalCount} pagination={false} data={trainedHistoryData}
              itemsPerPage={10} selectableRows={false} noDataComponent={<NoDataFound search={search} />}
              onSelectedRowsChange={() => { }} setPage={() => { }} page={1} onSort={() => { }}
              handleTableRowClick={handleTableRowClick} clearSelectedRows={clearRow}
            />
          </div>
        </div>
      )}

      <ArticlePreview
        userRes={userRes} articleId={article.id} articleType={article.type || 'ecommerce_blog'} show={showOffset}
        setShow={setShowOffset} getHistoryData={getHistoryShortFlowData}
        handleNavigateArticleOrPostWriter={(articleId, articleType) => handleGetProductDetailsById(articleId, true)}
      />
    </React.Fragment>
  )
}

export default withECommerceHandler(ECommerceBlogHistory);

const NoDataFound = ({ search }) => {
  return (
    <div className="withoutData  d-flex justify-content-center align-items-center">
      <div className="genrateNewBlock d-flex justify-content-center flex-column align-items-center">
        {!search ? (
          <>
            <div className="icon d-flex justify-content-center flex-column align-items-center">
              <img src={articleHistoryIcons.add} alt="add"></img>
            </div>
            <p>Articles you create will be shown here</p>
          </>
        ) : (
          <span>Oops... Search not found.</span>
        )}
      </div>
    </div>
  );
};
