import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { ToastBar, Toaster, toast } from "react-hot-toast";
import { BrowserRouter, Navigate, Route, Routes, useLocation } from "react-router-dom";
import TawkMessengerReact from "@tawk.to/tawk-messenger-react";
import { Tooltip } from "react-tooltip";

import "bootstrap/dist/css/bootstrap.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "react-tooltip/dist/react-tooltip.css";
import 'react-circular-progressbar/dist/styles.css';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import "./Assets/scss/style.scss";

import Loader from "./Components/Loader";
import { AxiosApi } from "./utility/axios";
import Error404Page from "./Components/Error404Page";
import HeaderSidebar from "./Components/HeaderSidebar";
import { resetReduxData, setPassword, setUserRes, setUsername } from "./store/reducer";
import { ROUTES } from "./routes";
import { getUserDetail } from "./utility/apiService";
import { invokeAxiosPost } from "./utility/invokeAxiosFunction";

const App = () => {
  const dispatch = useDispatch();
  const userRes = useSelector(({ userRes }) => userRes);
  const organizationId = useSelector(({ userRes }) => userRes.organization_id);
  const username = useSelector(({ username }) => username);
  const password = useSelector(({ password }) => password);
  const [isShow404, setIsShow404] = useState(false);

  useEffect(() => {
    document.body.classList.add('openSlide');
    if (password === null || password === "" || password !== "Password123") {
      dispatch(setPassword("Password123"));
    } else if (userRes?.id) {
      dispatch(getUserDetail(userRes?.id))
    }
  }, [dispatch, password, userRes?.id]);

  AxiosApi.interceptors.response.use((response) => response, async (error) => {
    if (error.response && error.response.status === 401) {
      try {
        const userId = localStorage.getItem("persist:root") ? JSON.parse(JSON.parse(localStorage.getItem("persist:root")).userRes).id : 0;
        invokeAxiosPost(`/user-api/get-or-create/userLogin`, { id: userId, isLogOut: true });
      } catch (error) {
      }

      dispatch(resetReduxData());
      localStorage.removeItem("persist:root");
      localStorage.clear();
      dispatch(setPassword("Password123"));
      dispatch(setUsername(""));
      dispatch(setUserRes({}));

      setTimeout(() => {
        window.location.href = "/";
      }, 600);
      return Promise.reject(error);
    } else {
      return Promise.reject(error);
    }
  });

  return (
    <div>
      {password !== null && password !== "" ? (
        <React.Fragment>
          <BrowserRouter>
            <div className="mainContentWrapper position-relative">
              {password !== null && password !== "" && (
                <Routes>
                  {ROUTES.map(({ Component, path, isPrivate }, index) => (
                    <React.Fragment key={index}>
                      {organizationId && (path === "/buy-more-credits" || path === "/plans") ? null : (
                        <Route
                          key={path} path={path}
                          element={
                            isPrivate ? (
                              <PrivateRoute username={username}>
                                {["/plans"].includes(path) ? (
                                  <Component />
                                ) : (
                                  <HeaderSidebar>
                                    <Component />
                                  </HeaderSidebar>
                                )}
                              </PrivateRoute>
                            ) : (
                              <Component />
                            )
                          }
                        />
                      )}
                    </React.Fragment>
                  ))}
                  <Route path="*" element={<Navigate replace to="/dashboard" />} />
                </Routes>
              )}
            </div>
          </BrowserRouter>

          <Toaster
            position="top-right"
            transitionDuration={300}
            transitionDelay={500}
            toastOptions={{
              error: { className: "alert error toast-contain" },
              success: { className: "alert success toast-contain" },
            }}
          >
            {(t) => (
              <ToastBar
                toast={t}
                style={{ ...t.style, animation: t.visible ? "enter 2s" : "exit 2s" }}
                children={(props) => (
                  <div>
                    {props.message}
                    <p className="close-error">
                      {props.icon}
                    </p>
                  </div>
                )}
              />
            )}
          </Toaster>

          <Tooltip id="my-tooltip" style={{ borderRadius: "6px", fontSize: "13px", padding: "4px 12px", maxWidth: "340px", zIndex: 9999 }} />
          <TawkMessengerReact propertyId="6446263a4247f20fefed5bf5" widgetId="1gup0all1" />
          <Loader />
        </React.Fragment>
      ) : (
        <React.Fragment>
          {isShow404 ? <Error404Page /> : null}
        </React.Fragment>
      )}
    </div>
  );
};

const PrivateRoute = ({ children, username }) => {
  if (username === "") {
    return <Navigate to="/" />;
  }
  return children;
};

export default App;
