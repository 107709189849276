import React, { memo, useMemo } from "react";
import classNames from "classnames";
import LinkedinPostPreview from "./LinkedinPostPreview";
import { ReactSVG } from "react-svg";
import { copyIcon } from "../../Assets/Icons";
import { Editor } from "react-editor";

const LinkedinArticle = (props) => {
  const { setUploadFileModal, generatedFullArticle, value, setEdit, edit, getFullData, handleCopyHtmlArticleText, userRes, generateHashtag = true, handleSetSelectedHashtagsOnPosts } = props;

  const valueWithHashTag = useMemo(() => {
    const parse = new DOMParser();
    const html = parse.parseFromString(value, 'text/html');
    const spanTags = html.querySelectorAll('span');
    spanTags.forEach((span) => {
      const parent = span.parentNode;
      while (span.firstChild) {
        parent.insertBefore(span.firstChild, span);
      }
      parent.removeChild(span);
    });
    return html.body.innerHTML?.split(' ');
  }, [value]);

  return (
    <React.Fragment>
      <div className="textEditerArea">
        <div className="genratePost">
          <div className="textArea position-relative">
            <div className="top-header-bar">
              <div className="topButton d-flex">
                {generateHashtag && (
                  <React.Fragment>
                    {generatedFullArticle?.linkedIn_post && !generatedFullArticle?.popular_hashtags?.length ? (
                      <button className=" generate-hashtag border-0" onClick={() => getFullData("popular_hashtags")}>
                        # Generate popular hashtags
                      </button>
                    ) : generatedFullArticle?.linkedIn_post && generatedFullArticle?.popular_hashtags?.length ? (
                      <button disabled className="blogButton border-0 ">
                        # hashtags generated
                      </button>
                    ) : null}
                  </React.Fragment>
                )}

                {generatedFullArticle?.linkedIn_post && (
                  <button
                    type="button" className="blogButton border-0 copyBtn" onClick={(e) => handleCopyHtmlArticleText(value)}
                    data-tooltip-id="my-tooltip" data-tooltip-content="Copy to clipboard" data-tooltip-place="bottom"
                  >
                    <ReactSVG src={copyIcon} />
                  </button>
                )}
              </div>
              {edit?.linkedIn_post && edit?.linkedIn_post?.length >= 3000 ? <div className="status-message">
                <span className="red-dot"></span>
                <span>You exceeded 3000 characters  </span>
              </div> : null}
            </div>

            <Editor
              disabled={generatedFullArticle?.linkedIn_post && generatedFullArticle?.linkedIn_post?.length > 0 ? false : true}
              className="text-editor-block" value={value} onChange={(value) => { setEdit({ ...edit, linkedIn_post: value }); }}
            />
          </div>
          <div
            className={classNames("hashTagBlock d-flex gap-3 align-items-center flex-wrap", {
              "d-none": !generatedFullArticle?.popular_hashtags?.length || !generatedFullArticle?.linkedIn_post,
            })}
          >
            {generatedFullArticle?.linkedIn_post && generatedFullArticle?.popular_hashtags?.length > 0 && generatedFullArticle?.popular_hashtags.map((ele, index) => (
              <div
                onClick={(e) => handleSetSelectedHashtagsOnPosts(e, ele.tag, "li", valueWithHashTag.some(value => value.toLowerCase() === ele.tag?.toLowerCase()))} key={index + ""}
                className={classNames("hashTagPost ", {
                  activeHash: valueWithHashTag.some(value => value.toLowerCase() === ele.tag?.toLowerCase())
                })}
              >
                <span>{ele.tag}</span>
                <p>{ele.volume?.toLocaleString("en-US")} Followers</p>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className={`${!edit?.linkedIn_post && 'notContent'}`}>
        <LinkedinPostPreview
          setUploadFileModal={setUploadFileModal} userRes={userRes} text={value}
          preview_img={generatedFullArticle?.linkedIn_post_img} isUploadImage={true}
        />
      </div>
    </React.Fragment>
  );
};

export default memo(LinkedinArticle);
