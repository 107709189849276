import React, { memo, useState } from "react";
import { Col } from "react-bootstrap";
import { BsImages } from "react-icons/bs";
import { Editor } from "@tinymce/tinymce-react";
import { useDispatch, useSelector } from "react-redux";
import { EditorInitObj, addEditorCustomButtons } from "../../../utility/hepler";
import { setGeneratedFullArticle } from "../../../store/reducer";

export default memo(function MainContent(props) {
  const dispatch = useDispatch();
  const userRes = useSelector(({ userRes }) => userRes);
  const generatedFullArticle = useSelector(({ generatedFullArticle }) => generatedFullArticle);
  const { column, value, finalHtmlText, editorRef, editorKey, handleCopyHtmlArticleText, setEdit, setToggle, articleId, handleGetAllImagesAndOpenModal } = props;

  const exportData = { text: value, finalText: finalHtmlText, dispatch, editorRef, finalHtmlText, articleId, userRes };
  const [selectedImage, setSelectedImage] = useState("");

  const handleOnInitTextEditor = (editor) => {
    editorRef.current = editor;
    if (column !== "article_html" && column !== "article_detail_html") {
      return editor;
    }

    editor.on("mouseover", (e) => {
      const target = e.target;
      if (target.nodeName === "IMG" && target.src && target.src.indexOf("ai-tool-generated-images") !== -1) {
        const clickEvent = new MouseEvent("click", {
          bubbles: true,
          cancelable: true,
          view: window
        });
        target.dispatchEvent(clickEvent);

        const rect = target.getBoundingClientRect();
        const elements = document.getElementsByClassName("custom-tooltip");
        for (let i = 0; i < elements.length; i++) {
          elements[i].style.display = "block";
          elements[i].style.width = "max-content";
          elements[i].style.top = rect.top + (rect.height / 2) + 30 + "px";
          elements[i].style.left = rect.left + (rect.width / 2) + "px";
        }
      } else {
        const elements = document.getElementsByClassName("custom-tooltip");
        for (let i = 0; i < elements.length; i++) {
          elements[i].style = "";
        }
      }
    });
  };

  const handleOnOpenMediaLibraryForBlog = async () => {
    const elements = document.getElementsByClassName("custom-tooltip");
    for (let i = 0; i < elements.length; i++) {
      elements[i].style = "";
    }

    let imageSubType = "";
    if (column === "article_html") {
      imageSubType = "Short Article Images";
    } else if (column === "article_detail_html") {
      imageSubType = "Long Article Images";
    }

    let prompt = await findPrompt(selectedImage);
    dispatch(setGeneratedFullArticle({ ...generatedFullArticle, selectedImage: selectedImage, imagePrompt: prompt }));
    handleGetAllImagesAndOpenModal(imageSubType, value, "AI Generated Image", selectedImage, prompt);
  };

  const findPrompt = (url) => {
    const data = JSON.parse(generatedFullArticle?.h2_images);
    const item = data.find(obj => obj.image === url);
    return item ? item.prompt : "Prompt not found";
  };

  return (
    <div className="h-100">
      <Col sm="12" md="8" className="position-relative w-100 h-100">
        <div className="position-relative custom-text-editor h-100">
          <Editor
            apiKey={process.env.REACT_APP_TINYMCE_EDITOR_KEY}
            onInit={(evt, editor) => handleOnInitTextEditor(editor)}
            value={value ? value : ""} key={editorKey}
            onEditorChange={(e) => setEdit((prev) => ({ ...prev, [column]: e }))}
            init={{
              ...EditorInitObj,
              content_style: `${finalHtmlText.match(/<style>([\s\S]*?)<\/style>/i)?.[1] || ""}`,
              toolbar: 'viewHtml | copyContent exportToPdf exportToDocx exportToGoogle | ' + EditorInitObj.toolbar,
              setup: function (editor) {
                addEditorCustomButtons(editor, setToggle, handleCopyHtmlArticleText, exportData);
                editor.on("click", function (e) {
                  if (e.target?.nodeName === "IMG") {
                    setSelectedImage(e.target.src);
                  }
                })
              },
            }}
          />
          {(column === "article_html" || column === "article_detail_html") && (
            <div className="custom-tooltip">
              <div className="arrow-content"></div>
              <span className="d-flex align-items-center gap-2 cursor-pointer" onClick={handleOnOpenMediaLibraryForBlog}>
                <BsImages /> Change or Upload Image
              </span>
            </div>
          )}
        </div>
      </Col>
    </div>
  );
})
