import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setPressReleaseWriterHistory, setPressReleaseWriterInfo, setPressReleaseWriterStep } from "../../store/reducer";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa6";

const PressReleaseHeader = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const pressReleaseWriterStep = useSelector(({ pressReleaseWriterStep }) => pressReleaseWriterStep);
  const pressReleaseWriterInfo = useSelector(({ pressReleaseWriterInfo }) => pressReleaseWriterInfo);
  const [isMobileView, setIsMobileView] = useState(false);
  const [isMobileHead, setIsMobileHead] = useState(false);
  const isShowKeywordStep = pressReleaseWriterInfo && pressReleaseWriterInfo.selectedCard !== "PDF" ? true : false;

  const stepArr = [
    { "step": 1, "label": "Select Type" },
    { "step": 2, "label": "Describe Details" },
    { "step": 3, "label": "Select Keywords" },
    { "step": 4, "label": "Edit & Preview" },
  ];

  useEffect(() => {
    const handleResize = () => {
      setIsMobileHead(window.innerWidth <= 425);
    };

    setTimeout(() => {
      handleResize();
    }, 900)

    window.addEventListener('resize', handleResize);
    window.addEventListener('load', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('load', handleResize);
    };
  }, []);

  useEffect(() => {
    function mobileViewUpdate() {
      const viewportWidth = window.innerWidth;
      if (viewportWidth <= 770) {
        setIsMobileView(true);
      } else {
        setIsMobileView(false);
      }
    }
  }, []);

  const handleOnChangeStep = async (no) => {
    if (no >= 1 && no <= 4) {
      if (pressReleaseWriterInfo?.stepDone && no <= pressReleaseWriterInfo.stepDone) {
        // if (no === 1) {
        //   dispatch(setPressReleaseWriterStep(0));
        //   dispatch(setPressReleaseWriterInfo({}));
        //   dispatch(setPressReleaseWriterHistory({}));
        //   navigate("/press-release");
        // }
        dispatch(setPressReleaseWriterStep(no));
      }
    }
  }

  return (
    <div className="AIWriterBlogHead SocialMediaHeader">
      <div className="AiWriterBlogHeadTop justify-content-center AIWriterStepper">
        {isMobileHead ? (
          <ul>
            <li
              className={`${pressReleaseWriterStep === stepArr[pressReleaseWriterStep - 1].step ? "" : ""} ${stepArr[pressReleaseWriterStep - 1].step < pressReleaseWriterStep ? "complete" : ""} ${stepArr[pressReleaseWriterStep - 1].step <= pressReleaseWriterInfo?.stepDone ? "is-clickable" : ""}`}
              onClick={() => handleOnChangeStep(stepArr[pressReleaseWriterStep - 1].step - 1)}
            >
              <span className="d-flex justify-content-center align-items-center">
                <FaAngleLeft />
              </span>
              <p>Previous</p>
            </li>
            <li
              className={`${pressReleaseWriterStep === stepArr[pressReleaseWriterStep - 1].step ? "active" : ""} ${stepArr[pressReleaseWriterStep - 1].step < pressReleaseWriterStep ? "complete" : ""} ${stepArr[pressReleaseWriterStep - 1].step <= pressReleaseWriterInfo?.stepDone ? "is-clickable" : ""}`}
            >
              <span className="d-flex justify-content-center align-items-center">
                {stepArr[pressReleaseWriterStep - 1].step > 4 ? `0${stepArr[pressReleaseWriterStep - 1].step - 1}` : `0${stepArr[pressReleaseWriterStep - 1].step}`}
              </span>
              <p>{stepArr[pressReleaseWriterStep - 1].label}</p>
            </li>
            <li
              className={`${pressReleaseWriterStep === stepArr[pressReleaseWriterStep - 1].step ? "" : ""} ${stepArr[pressReleaseWriterStep - 1].step < pressReleaseWriterStep ? "complete" : ""} ${stepArr[pressReleaseWriterStep - 1].step <= pressReleaseWriterInfo?.stepDone ? "is-clickable" : ""}`}
              onClick={() => handleOnChangeStep(stepArr[pressReleaseWriterStep - 1].step + 1)}
            >
              <span className="d-flex justify-content-center align-items-center">
                <FaAngleRight />
              </span>
              <p>Next</p>
            </li>
          </ul>
        ) : (
          <ul>
            {stepArr && stepArr.map((step, index) => (
              <li
                className={`${pressReleaseWriterStep === step.step ? "active" : ""} ${step.step < pressReleaseWriterStep ? "complete" : ""} ${step.step <= pressReleaseWriterInfo?.stepDone ? "is-clickable" : ""}`}
                onClick={() => handleOnChangeStep(step.step)} onKeyDown={() => handleOnChangeStep(step.step)} key={index}
              >
                <span className="d-flex justify-content-center align-items-center">
                  {step.step > 4 ? `0${step.step - 1}` : `0${step.step}`}
                </span>
                <p className={``}>{step.label}</p>
              </li>
            ))}
          </ul>
        )}
        {/* <ul>
          <li
            className={`${pressReleaseWriterStep === 1 ? "active" : ""} ${1 < pressReleaseWriterStep ? "complete" : ""} ${1 <= pressReleaseWriterInfo?.stepDone ? "is-clickable" : ""}`}
            onClick={() => handleOnChangeStep(1)} onKeyDown={() => handleOnChangeStep(1)}
          >
            <span className="d-flex justify-content-center align-items-center">01</span>
            <p className={`${isMobileView ? "step-border" : ""}`}>{isMobileView ? "" : "Select Type"}</p>
          </li>
          <li
            className={`${pressReleaseWriterStep === 2 ? "active" : ""} ${2 < pressReleaseWriterStep ? "complete" : ""} ${2 <= pressReleaseWriterInfo?.stepDone ? "is-clickable" : ""}`}
            onClick={() => handleOnChangeStep(2)} onKeyDown={() => handleOnChangeStep(2)}
          >
            <span className="d-flex justify-content-center align-items-center">02</span>
            <p className={`${isMobileView ? "step-border" : ""}`}>{isMobileView ? "" : "Describe Details"}</p>
          </li>
          {isShowKeywordStep && (
            <li
              className={`${pressReleaseWriterStep === 3 ? "active" : ""} ${3 < pressReleaseWriterStep ? "complete" : ""} ${3 <= pressReleaseWriterInfo?.stepDone ? "is-clickable" : ""}`}
              onClick={() => handleOnChangeStep(3)} onKeyDown={() => handleOnChangeStep(3)}
            >
              <span className="d-flex justify-content-center align-items-center">03</span>
              <p className={`${isMobileView ? "step-border" : ""}`}>{isMobileView ? "" : "Select Keywords"}</p>
            </li>
          )}
          <li
            className={`${pressReleaseWriterStep === 4 ? "active" : ""} ${4 < pressReleaseWriterStep ? "complete" : ""} ${4 <= pressReleaseWriterInfo?.stepDone ? "is-clickable" : ""}`}
            onClick={() => handleOnChangeStep(4)} onKeyDown={() => handleOnChangeStep(4)}
          >
            <span className="d-flex justify-content-center align-items-center">{!isShowKeywordStep ? "03" : "04"}</span>
            <p className={`${isMobileView ? "step-border" : ""}`}>{isMobileView ? "" : "Edit & Preview"}</p>
          </li>
        </ul> */}
      </div>
    </div>
  )
}

export default PressReleaseHeader;
