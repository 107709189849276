import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import toast from "react-hot-toast";
import Heading from "../Components/ui/Title";
import { setLoader, setPressReleaseWriterInfo, setPressReleaseWriterStep } from "../store/reducer";
import PressReleaseHeader from "../Components/PressReleaseComponents/PressReleaseHeader";
import PressReleaseHistory from "../Components/PressReleaseComponents/PressReleaseHistory";
import FirstStepPressRelease from "../Components/PressReleaseComponents/FirstStepPressRelease";
import SecondStepPressRelease from "../Components/PressReleaseComponents/SecondStepPressRelease";
import ThirdStepPressRelease from "../Components/PressReleaseComponents/ThirdStepPressRelease";
import FourthStepPressRelease from "../Components/PressReleaseComponents/FourthStepPressRelease";
import { invokeAxiosGet } from "../utility/invokeAxiosFunction";

const PressReleaseWriter = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const pressReleaseWriterStep = useSelector(({ pressReleaseWriterStep }) => pressReleaseWriterStep);
  const { id } = useParams();

  const [searchParams] = useSearchParams();
  const isFullScreen = searchParams.get("full-screen");
  const [isButtonLoader, setIsButtonLoader] = useState(false);

  useEffect(() => {
    if (id && id !== "") {
      handleGetPressReleaseDetailsById(id, false);
    } else {
      dispatch(setPressReleaseWriterInfo({}));
      dispatch(setPressReleaseWriterStep(1));
      navigate("/press-release");
    }
  }, [id]);

  const handleGetPressReleaseDetailsById = async (id, isSetStep = false) => {
    try {
      dispatch(setLoader(true));
      dispatch(setPressReleaseWriterInfo({}));
      const response = await invokeAxiosGet(`/press-release/get-or-generate/getPressReleaseDetailsById?id=${id}`);
      if (response && response.data) {
        dispatch(setLoader(false));
        const item = response.data && response.data.id ? response.data : {};
        const pressReleaseDataObj = { ...item };
        pressReleaseDataObj.selectedCard = item.type;
        pressReleaseDataObj.stepDone = item?.currentStep ? item.currentStep : 1;

        dispatch(setPressReleaseWriterInfo({ ...pressReleaseDataObj }));
        if (isSetStep) {
          dispatch(setPressReleaseWriterStep(item?.currentStep ? item.currentStep : 1));
          navigate(`/press-release/${item?.id}`);
        } else {
          dispatch(setPressReleaseWriterStep(pressReleaseWriterStep));
        }
        const scrollWrapper = document.getElementById("scroll-wrapper");
        if (scrollWrapper) { scrollWrapper.scrollTo({ top: 0, behavior: "smooth" }); }
      } else {
        dispatch(setLoader(false));
      }
    } catch (error) {
      dispatch(setLoader(false));
      toast.error(error?.response?.data?.error || "something went wrong");
      dispatch(setPressReleaseWriterInfo({}));
      dispatch(setPressReleaseWriterStep(1));
      navigate("/press-release");
    }
  };

  const steps = useMemo(() => [
    <FirstStepPressRelease isButtonLoader={isButtonLoader} setIsButtonLoader={setIsButtonLoader} />,
    <SecondStepPressRelease isButtonLoader={isButtonLoader} setIsButtonLoader={setIsButtonLoader} />,
    <ThirdStepPressRelease isButtonLoader={isButtonLoader} setIsButtonLoader={setIsButtonLoader} />,
    <FourthStepPressRelease isButtonLoader={isButtonLoader} setIsButtonLoader={setIsButtonLoader} />,
  ], [isButtonLoader]);

  return (
    <div style={isButtonLoader ? { pointerEvents: "none" } : {}}>
      {!isFullScreen && (
        <div className="oneClickHead d-flex flex-column pt-4">
          <div className="">
            <Heading className="fw-bolder mainHeading" size="3">Press Release Quick Draft</Heading>
            <p>Get the word out fast. Generate impactful, SEO-optmized press releases in minutes.</p>
          </div>
        </div>
      )}
      <div className={`stepWraper ${isFullScreen ? "mt-0" : "mt-4"}`}>
        <div className="border-radius-12 bg-white AIWriterBlog AISocialMediaWriter SocialMediaWriter maingenrateBlock p-0 m-0">
          <PressReleaseHeader />
          {steps[pressReleaseWriterStep - 1]}
        </div>
      </div>

      {pressReleaseWriterStep === 1 && !id && (
        <div className="d-flex gap-2 flex-column tableHeading mt-5">
          <div className="mt-2">
            <PressReleaseHistory handleGetDetailsById={handleGetPressReleaseDetailsById} />
          </div>
        </div>
      )}
    </div>
  )
}

export default PressReleaseWriter;
