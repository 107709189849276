import React, { useEffect, useRef, useState } from "react";
import Swal from "sweetalert";
import copy from "copy-to-clipboard";
import { toast } from "react-hot-toast";
import { Col, Modal, Row } from "react-bootstrap";
import { Editor } from "@tinymce/tinymce-react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { BiSave, BiSolidEditAlt } from "react-icons/bi";
import { MdLaptopMac } from "react-icons/md";
import { GrClose } from "react-icons/gr";
import { LuCheckCircle } from "react-icons/lu";
import { HiDevicePhoneMobile } from "react-icons/hi2";
import { BsArrowLeft, BsArrowsAngleContract, BsArrowsFullscreen, BsImages, BsInfoCircleFill, BsPersonCheck, BsStars } from "react-icons/bs";
import { setLoader, setNewsletterWriterInfo, setNewsletterWriterStep, setUserRes } from "../../store/reducer";
import { invokeAxiosGet, invokeAxiosPost } from "../../utility/invokeAxiosFunction";
import { EditorInitObj, addEditorCustomButtons, handleGetOrGenerateAllTypeImages } from "../../utility/hepler";
import HtmlViewer from "../SeoBlogWriter/HtmlViewer";
import VersionHistory from "../commonComponents/VersionHistory";
import MediaLibraryDrawer from "../commonComponents/MediaLibrary/MediaLibraryDrawer";
import ReviewTextEditor from "../commonComponents/ReviewTextEditor/Index";
import GoDashboard from "../Modal/GoDashboard";
const beautify_html = require("js-beautify").html;

const ThirdStepNewsletters = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id: articleId } = useParams();
  const editorRef = useRef(null);

  const userRes = useSelector(({ userRes }) => userRes);
  const newsletterWriterInfo = useSelector(({ newsletterWriterInfo }) => newsletterWriterInfo);

  const [searchParams, setSearchParams] = useSearchParams();
  const [htmlText, setHtmlText] = useState("");
  const [finalHtmlText, setFinalHtmlText] = useState("");
  const [toggle, setToggle] = useState(false);
  const [editorKey, setEditorKey] = useState(0);
  const [previewNewslettersModal, setPreviewNewslettersModal] = useState({ open: false, type: "" });
  const [postImagesModal, setPostImagesModal] = useState({ open: false, type: "", currentTab: "AI Generated Image" });
  const [postGeneratedImages, setPostGeneratedImages] = useState({});
  const [showFeedbackModel, setShowFeedbackModel] = useState({ open: false, feedback: "" });
  const [selectedCurrentTab, setSelectedCurrentTab] = useState(searchParams && searchParams.get('tab') ? searchParams.get('tab') : "finalPreview");
  const [selectedSubTab, setSelectedSubTab] = useState(searchParams && searchParams.get('subTab') ? searchParams.get('subTab') : "inputInfo");
  const [versionHistory, setVersionHistory] = useState([]);
  const [selectedVersionItem, setSelectedVersionItem] = useState({});
  const isFullScreen = searchParams.get("full-screen");
  const articleType = "NewsLetters", subType = "Article Images";

  const [sendToEditorForm, setSendToEditorForm] = useState(false);
  const [savedModal, setSavedModal] = useState(false);
  const reviewEditorChildRef = useRef();

  useEffect(() => {
    setSelectedVersionItem({});
    setHtmlText(newsletterWriterInfo?.content || newsletterWriterInfo?.resText || "");
    setFinalHtmlText(newsletterWriterInfo?.content || newsletterWriterInfo?.resText || "");
    setEditorKey(editorKey + 1);
  }, []);

  useEffect(() => {
    getArticleAllVersionHistory("NewsLetter Article");
  }, [articleId]);

  const getArticleAllVersionHistory = async (versionSubType) => {
    try {
      if (!articleId || !versionSubType) {
        return false;
      }

      setVersionHistory([]);
      const params = `articleId=${articleId}&type=${articleType}&subType=${versionSubType}&isVersionList=true`;
      const res = await invokeAxiosGet(`/analytics-api/get-summary/getPreviousGeneratedContent?userId=${userRes.id}&${params}`);
      if (res && res.data && res.data.data && res.data.data.length > 0) {
        setVersionHistory(res?.data?.data);
      }
      dispatch(setLoader(false));
    } catch (error) {
      dispatch(setLoader(false));
    }
  };

  const handleFullScreen = () => {
    if (isFullScreen) {
      setSearchParams();
    } else {
      setSearchParams("full-screen=true");
    }
  };

  const handleSetFinalNewsletters = async () => {
    const currentSelectedTab = selectedSubTab && selectedSubTab !== "" ? selectedSubTab : "";
    try {
      dispatch(setLoader(true));
      setSelectedSubTab("");
      const Obj = {
        id: newsletterWriterInfo?.id,
        type: "save",
        text: htmlText,
      };

      if (finalHtmlText && finalHtmlText !== "" && /<html[^>]*>/.test(finalHtmlText)) {
        const parser = new DOMParser();
        const bodyText = parser.parseFromString(htmlText, 'text/html').body.outerHTML;
        Obj.text = `${finalHtmlText.substring(0, finalHtmlText.indexOf("<body"))} ${bodyText} </html>`
          .replaceAll("\n", " ").replaceAll("\t", " ").replace(/\s+/g, ' ').trim();
      }

      const payload = { body: Obj };
      const response = await invokeAxiosPost("/news-letters/get-or-generate/saveOrRegenerateNewslettersById", payload);
      dispatch(setLoader(false));
      setSelectedSubTab(currentSelectedTab);
      if (response && response.success === 1 && response.data && response.data.content) {
        setHtmlText(response.data.content);
        setFinalHtmlText(response.data.content);
        setEditorKey(editorKey + 1);
        getArticleAllVersionHistory("NewsLetter Article");
        setSavedModal(true);
      } else {
        toast.error("Something went wrong, Please try again later.", { id: "Toast-01" });
      }
    } catch (error) {
      toast.error("Something went wrong, Please try again later.", { id: "Toast-01" });
      dispatch(setLoader(false));
      setSelectedSubTab(currentSelectedTab);
    }
  };

  const handleRegenerateNewsletters = async () => {
    try {
      dispatch(setLoader(true));
      const Obj = {
        id: newsletterWriterInfo?.id, type: "regenerate", text: htmlText,
        feedback: showFeedbackModel?.feedback || ""
      };

      if (finalHtmlText && finalHtmlText !== "" && /<html[^>]*>/.test(finalHtmlText)) {
        const htmlStr = htmlText.indexOf("<body>") !== -1 ? htmlText.substring(htmlText.indexOf("<body>") + 6, htmlText.indexOf("</body>")) : htmlText;
        Obj.text = `${finalHtmlText.substring(0, finalHtmlText.indexOf("<body>"))} <body> ${htmlStr} </body> </html>`
          .replaceAll("\n", " ").replaceAll("\t", " ").replace(/\s+/g, ' ').trim();
      }

      const payload = { body: Obj };
      const response = await invokeAxiosPost("/news-letters/get-or-generate/saveOrRegenerateNewslettersById", payload);
      dispatch(setLoader(false));
      setShowFeedbackModel({ open: false });
      if (response && response.success === 1 && response.data && response.data.content) {
        setHtmlText(response.data.content);
        setFinalHtmlText(response.data.content); setEditorKey(editorKey + 1);
        getArticleAllVersionHistory("NewsLetter Article");
        dispatch(setNewsletterWriterInfo({ ...newsletterWriterInfo, ...response.data }));
        dispatch(setNewsletterWriterStep(3));
      } else {
        toast.error(response?.data?.error || "Newsletter content not generated, Please try again", { id: "Toast-01" });
      }
    } catch (error) {
      toast.error("Something went wrong, Please try again later.", { id: "Toast-01" });
      setShowFeedbackModel({ open: false });
      dispatch(setLoader(false));
    }
  };

  const handleGetAllImagesAndOpenModal = async () => {
    try {
      const params = {
        html: finalHtmlText, type: articleType, subType, userId: userRes.id,
        articleId: newsletterWriterInfo?.id ? newsletterWriterInfo.id : "",
        topic: newsletterWriterInfo?.topic ? newsletterWriterInfo.topic : "",
      };

      const queryParams = new URLSearchParams({
        type: articleType, subType, userId: userRes.id,
        articleId: newsletterWriterInfo?.id ? newsletterWriterInfo.id : "",
      }).toString();

      const paramObj = { setPostImagesModal, postGeneratedImages, setPostGeneratedImages, articleType, subType, userRes, params, queryParams };
      await handleGetOrGenerateAllTypeImages(paramObj);
      setPostImagesModal((prev) => ({ ...prev, imageHistoryLoader: false, pexelsGenerating: false, aiImageGenerating: false }));
    } catch (error) {
      setPostImagesModal((prev) => ({ ...prev, imageHistoryLoader: false, pexelsGenerating: false, aiImageGenerating: false }));
    }
  };

  const handleCopyHtmlArticleText = (text) => {
    if (text && text !== "") {
      const options = { indent_size: 2, space_in_empty_paren: true };
      copy(beautify_html(text, options), { format: "text/html" });
      toast.success("Text has been copied to clipboard.", { id: "Toast-01" });
    }
  };

  const handleSetRestoredOldVersionText = async (fieldName, text) => {
    setHtmlText(text); setFinalHtmlText(text);
    setEditorKey(editorKey + 1);
    setNewsletterWriterInfo((prev) => ({ ...prev, resText: text }));
    getArticleAllVersionHistory("NewsLetter Article");
  };

  const handleOpenArticlePreviewModal = (type) => {
    const tempObj = { open: true, type: type, text: finalHtmlText };

    if (finalHtmlText && finalHtmlText !== "" && /<html[^>]*>/.test(finalHtmlText)) {
      const parser = new DOMParser();
      const bodyText = parser.parseFromString(htmlText, 'text/html').body.outerHTML;
      tempObj.text = `${finalHtmlText.substring(0, finalHtmlText.indexOf("<body"))} ${bodyText} </html>`
        .replaceAll("\n", " ").replaceAll("\t", " ").replace(/\s+/g, ' ').trim();
    }

    setPreviewNewslettersModal(tempObj);
  };

  const handleOnChangeWriterTabOrSubTab = (tab, subTab) => {
    let tabVal = selectedCurrentTab, subTabVal = selectedSubTab;
    if (tab && tab !== "") { tabVal = tab; };
    if (subTab && subTab !== "") { subTabVal = subTab; };

    setSelectedCurrentTab(tabVal); setSelectedSubTab(subTabVal);
    setSearchParams(isFullScreen ? `tab=${tabVal}&subTab=${subTabVal}&full-screen=${isFullScreen}` : `tab=${tabVal}&subTab=${subTabVal}`);
  };

  const handleSendArticleToReviewer = async (data) => {
    try {
      dispatch(setLoader(true));
      const payload = {
        userId: userRes.id, articleType: articleType, articleSubType: "Generate Article",
        articleId: newsletterWriterInfo?.id ? newsletterWriterInfo.id : "",
        articleText: htmlText ? htmlText : "", ...data
      };

      if (finalHtmlText && finalHtmlText !== "" && /<html[^>]*>/.test(finalHtmlText)) {
        const parser = new DOMParser();
        const bodyText = parser.parseFromString(htmlText, 'text/html').body.outerHTML;
        payload.articleText = `${finalHtmlText.substring(0, finalHtmlText.indexOf("<body"))} ${bodyText} </html>`
          .replaceAll("\n", " ").replaceAll("\t", " ").replace(/\s+/g, ' ').trim();
      }

      const response = await invokeAxiosPost(`/reviewer-editor/get-or-create/sendArticleOrPostToReviewerAdmin`, payload);
      if (response && response.data && response.data.success === 1) {
        dispatch(setLoader(false));
        if (data.isSentToReviewer && data.isSentToReviewer === true) {
          toast.success("Article sent to reviewer successfully.", { id: "Toast-01" });
          setSelectedCurrentTab("reviewArticle");
          setNewsletterWriterInfo({ ...newsletterWriterInfo, isCheckReviewer: 1 });
          dispatch(setUserRes({ ...userRes, credits: userRes.credits - data.deductCredits }));
        }

        return response.data;
      } else {
        dispatch(setLoader(false));
        toast.error("Something went wrong!", { id: "Toast-01" });
      }
    } catch (error) {
      dispatch(setLoader(false));
      toast.error("Something went wrong!", { id: "Toast-01" });
    }
  };

  const handleSaveReviewArticleText = () => {
    if (reviewEditorChildRef.current) {
      reviewEditorChildRef.current.handleSaveArticleTextAndNewVersion();
    }
  };

  const handleSaveClose = () => setSavedModal(false);

  const exportData = { text: htmlText, finalText: finalHtmlText, dispatch, editorRef, finalHtmlText, articleId, userRes };

  return (
    <React.Fragment>
      <div className="selectBlock d-flex flex-wrap gap-2 align-items-center justify-content-between mx-2 mx-md-auto">
        <div className="d-flex flex-wrap align-items-center gap-2">
          <button
            type="button" className="d-flex align-items-center gap-2 bg-transparent border-0 selectBbutton"
            onClick={() => handleOnChangeWriterTabOrSubTab("finalPreview", "inputInfo")} disabled={selectedCurrentTab === "finalPreview"}
          >
            <BiSolidEditAlt className="fs-5" /> Edit Newsletter
          </button>
          <span style={{ opacity: 0.4 }}>|</span>
          <button
            type="button" className="d-flex align-items-center gap-2 bg-transparent border-0 selectBbutton"
            onClick={() => handleOnChangeWriterTabOrSubTab("reviewArticle", "comments")} disabled={selectedCurrentTab === "reviewArticle"}
          >
            <BsPersonCheck className="fs-5" /> Review Article
          </button>
        </div>
        <button
          type="button" className="d-flex align-items-center gap-2 bg-transparent border-0 selectBbutton"
          onClick={() => {
            dispatch(setNewsletterWriterStep(2));
            const scrollWrapper = document.getElementById('scroll-wrapper');
            if (scrollWrapper) { scrollWrapper.scrollTo({ top: 0, behavior: 'smooth' }); }
          }}
        >
          <BsArrowLeft className="" /> Previous
        </button>
      </div>
      <div className="topicBlockBottom mb-4">
        {selectedCurrentTab === "finalPreview" ? (
          <Row>
            <Col md={4} style={{ height: '100%' }}>
              <div className="optimizationBlock h-100 m-0 m-md-3">
                <div className="cardOptimiz">
                  {[
                    { label: "Topic", value: newsletterWriterInfo?.topic || "", isLink: false },
                    { label: "Call to Action", value: newsletterWriterInfo?.cta || "", isLink: false },
                    { label: "URL 1", value: newsletterWriterInfo?.url1 || "", isLink: true },
                    { label: "URL 2", value: newsletterWriterInfo?.url2 || "", isLink: true },
                    { label: "URL 3", value: newsletterWriterInfo?.url3 || "", isLink: true },
                  ].map((element, index) => (
                    <div className="topicBlock mb-3" key={index + 1}>
                      <span className="d-block mb-1">{element.label}</span>
                      {element.isLink ? (
                        <a className="fw-normal text-decoration-none" href={element?.value} target="_blank" rel="noreferrer">
                          {element?.value || "-"}
                        </a>
                      ) : (
                        <p className="fw-normal overflow-auto" style={{ maxHeight: "200px" }}>{element?.value || "-"}</p>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            </Col>
            <Col md={8} className="news-letters-last-step">
              <div className="header-content flex-wrap mt-2 mt-md-0">
                <div className="d-flex flex-wrap align-items-center gap-2">
                  <VersionHistory
                    versionHistory={versionHistory} selectedVersionItem={selectedVersionItem} setSelectedVersionItem={setSelectedVersionItem}
                    handleSetRestoredOldVersionText={handleSetRestoredOldVersionText} handleCopyHtmlArticleText={handleCopyHtmlArticleText}
                    selectedSubTab={""} setSelectedSubTab={() => { }} articleType={articleType}
                  />
                  <button
                    type="button" disabled={!newsletterWriterInfo?.regenerateLeft ? true : false}
                    className={"blogButton border-0"} onClick={() => setShowFeedbackModel({ open: true })}
                  >
                    <BsStars /> Re-Generate ( {newsletterWriterInfo?.regenerateLeft || 0} / 3 )
                  </button>
                  <BsInfoCircleFill
                    className="text-decoration-none outline-0 ms-0 fs-5 infoIconSvg" data-tooltip-id={"my-tooltip"} data-tooltip-place="bottom"
                    data-tooltip-content={`You can Re-Generate article only three times.`}
                  />
                </div>
                <div className="d-flex flex-wrap align-items-center gap-2 rightSideButton">
                  <button
                    type="button" className="blogButton border-0 previewButton px-2" onClick={(e) => handleOpenArticlePreviewModal("desktop")}
                    data-tooltip-id="my-tooltip" data-tooltip-content={"Desktop Preview"} data-tooltip-place="bottom"
                  >
                    <MdLaptopMac className="fs-5 mx-1" />
                  </button>
                  <button
                    type="button" className="blogButton border-0 previewButton px-2" onClick={(e) => handleOpenArticlePreviewModal("mobile")}
                    data-tooltip-id="my-tooltip" data-tooltip-content={"Mobile Preview"} data-tooltip-place="bottom"
                  >
                    <HiDevicePhoneMobile className="fs-5 mx-1" />
                  </button>
                  <button type="button" className="blogButton border-0" onClick={handleGetAllImagesAndOpenModal} disabled={!newsletterWriterInfo?.id}>
                    <BsImages /> Image Library
                  </button>
                  <button
                    type="button" className="blogButton border-0 bg-black saveButton px-2" onClick={handleSetFinalNewsletters}
                    data-tooltip-id="my-tooltip" data-tooltip-content={"Save"} data-tooltip-place="bottom"
                  >
                    <BiSave className="fs-5 mx-1" /> {isFullScreen ? "Save" : ""}
                  </button>
                  <button onClick={handleFullScreen} className="blogButton border-1 bg-white fulscreen">
                    {!isFullScreen ? <BsArrowsFullscreen /> : <BsArrowsAngleContract />}
                  </button>
                </div>
              </div>
              <div className="article-body-wrapper">
                <div className="position-relative custom-text-editor">
                  {toggle ? (
                    <div className="html-content " style={{ height: "75vh" }}>
                      <HtmlViewer setToggle={setToggle} value={htmlText} html={finalHtmlText} onEditorChange={(e) => setHtmlText(e)} />
                    </div>
                  ) : (
                    <React.Fragment>
                      <Editor
                        apiKey={process.env.REACT_APP_TINYMCE_EDITOR_KEY}
                        onInit={(evt, editor) => (editorRef.current = editor)} value={htmlText}
                        onEditorChange={(e) => setHtmlText(e)} key={editorKey}
                        init={{
                          ...EditorInitObj, height: "75vh",
                          content_style: `${(finalHtmlText.match(/<style>([\s\S]*?)<\/style>/i)?.[1] || "")}`,
                          toolbar: 'viewHtml | copyContent exportToPdf | ' + EditorInitObj.toolbar,
                          setup: function (editor) {
                            addEditorCustomButtons(editor, setToggle, handleCopyHtmlArticleText, exportData);
                          },
                        }}
                      />
                    </React.Fragment>
                  )}
                </div>
              </div>
            </Col>
          </Row>
        ) : selectedCurrentTab === "reviewArticle" ? (
          <div className="news-letters-last-step">
            <div className="header-content justify-content-end">
              <div className="d-flex align-items-center gap-3 rightSideButton">
                <button type="button" className="blogButton border-0" data-bs-toggle="offcanvas" data-bs-target="#commentVersionHistory"
                  aria-controls="commentVersionHistory" onClick={() => handleOnChangeWriterTabOrSubTab("reviewArticle", "version")}
                  style={{ background: 'var(--Blue-B50)', color: 'var(--Blue-B300)' }}
                >
                  Version History
                </button>
                <span className="line"></span>
                {newsletterWriterInfo?.isCheckReviewer === 0 && selectedCurrentTab === "reviewArticle" && (
                  <button className="blogButton border-0" onClick={() => setSendToEditorForm(true)}>
                    <LuCheckCircle /> Submit for Review
                  </button>
                )}
                <button data-tooltip-id="my-tooltip" data-tooltip-content="Save" data-tooltip-place="bottom"
                  className="blogButton border-0 bg-black saveButton" onClick={handleSaveReviewArticleText}
                >
                  <BiSave className="fs-5" /> {isFullScreen ? "Save" : ""}
                </button>
                <button onClick={handleFullScreen} className="blogButton border-1 bg-white fulscreen">
                  {!isFullScreen ? <BsArrowsFullscreen /> : <BsArrowsAngleContract />}
                </button>
              </div>
            </div>

            <div className="position-relative">
              <ReviewTextEditor
                isSelectedCurrentTab={selectedCurrentTab === "reviewArticle" ? true : false} articleType={articleType}
                articleId={newsletterWriterInfo?.id} articleSubType={"Generate Article"}
                isReviewArticle={newsletterWriterInfo?.isCheckReviewer || 0} toggleRightSideBar={false}
                handleSendArticleToReviewer={handleSendArticleToReviewer} sendToEditorForm={sendToEditorForm}
                setSendToEditorForm={setSendToEditorForm} ref={reviewEditorChildRef}
              />
            </div>
          </div>
        ) : null}
      </div>

      {postImagesModal && postImagesModal.open && (
        <div className="genrateAiImages">
          <MediaLibraryDrawer
            postImagesModal={postImagesModal} setPostImagesModal={setPostImagesModal}
            postGeneratedImages={postGeneratedImages} setPostGeneratedImages={setPostGeneratedImages}
            articleType={articleType} isDisplayProductImgTab={false} isDisplayWebsiteImgTab={true}
            articleId={newsletterWriterInfo?.id}
          />
        </div>
      )}

      {showFeedbackModel && showFeedbackModel.open && (
        <Modal size="lg" centered show={showFeedbackModel.open} onHide={() => setShowFeedbackModel({ open: false })}>
          <Modal.Body className="text-start p-0 overflow-auto" style={showFeedbackModel.article ? { height: "90vh" } : {}}>
            <div className="maingenrateBlock m-0 p-3 h-100">
              <div className="d-flex align-items-center justify-content-between">
                <h4>What would you like to change?</h4>
                <button className="btn btn-primary" onClick={() => handleRegenerateNewsletters()}>
                  <BsStars /> Re-Generate
                </button>
              </div>
              <div className="genrateFields mt-3">
                <div className="fields m-0">
                  <label>Customize your content before hitting the 'Re-Generate' button. </label>
                  <textarea
                    name="feedback" type="text" rows="3" placeholder="Insert feedback ..."
                    className={`addlly-textarea w-100`} value={showFeedbackModel?.feedback}
                    onChange={(e) => setShowFeedbackModel({ ...showFeedbackModel, feedback: e.target.value })}
                  />
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      )}

      {previewNewslettersModal && previewNewslettersModal.open && (
        <Modal centered className="newsLetter-view-modal" show={previewNewslettersModal.open}
          size={previewNewslettersModal.type === "desktop" ? "lg" : ""}
          onHide={() => setPreviewNewslettersModal({ open: false, type: "" })}
        >
          <Modal.Body className="">
            <div className="modal-header-wrapper">
              <div className="d-flex align-items-center gap-2 header-text">
                {previewNewslettersModal.type === "desktop" ? (
                  <React.Fragment>
                    <MdLaptopMac className="fs-4 mx-1" /> Desktop Preview
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <HiDevicePhoneMobile className="fs-4 mx-1" /> Mobile Preview
                  </React.Fragment>
                )}
              </div>
              <button className="btn p-0" onClick={() => setPreviewNewslettersModal({ open: false, type: "" })}>
                <GrClose className="fs-4" />
              </button>
            </div>
            <div className="modal-content-wrapper">
              <iframe
                title="Embedded Content" frameBorder="0" allowFullScreen className="newsLetter-preview-iframe"
                src={`data:text/html;charset=utf-8,${encodeURIComponent(
                  previewNewslettersModal.text?.replace("<style>", `<link href="https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap" rel="stylesheet">
                    <style> ::-webkit-scrollbar { width: 2px; height: 2px; } ::-webkit-scrollbar-track { border-radius: 10px; background-color: #f4f4f9; }
                    ::-webkit-scrollbar-thumb { background: #0039ff; border-radius: 10px; } body { font-family: 'Inter'; }`)
                )}`}
              />
            </div>
          </Modal.Body>
        </Modal>
      )}
      <GoDashboard
        show={savedModal}
        onHide={handleSaveClose}
        goDashboard={() => {
          handleSaveClose()
          setHtmlText("")
          setFinalHtmlText("")
          setToggle(false);
          dispatch(setNewsletterWriterInfo({}))
          dispatch(setNewsletterWriterStep(1))
          navigate("/newsletters");
        }}
      />
    </React.Fragment>
  )
}

export default ThirdStepNewsletters;
