import classNames from "classnames";
import React, { useEffect, useRef, useState } from "react";
import { BsChevronDown } from "react-icons/bs";

const DropdownMenu = ({ options, selectedOption, onSelect = () => { }, label, placeholder, selectedLabel, noDataMessage, disabled = false, showSelectedCount = false }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selected, setSelected] = useState({});
  const ref = useRef();

  const handleSelectOption = (option) => {
    setIsOpen(false);
    onSelect(option);
    setSelected(option);
  };

  useEffect(() => {
    if (selectedOption) {
      setSelected(selectedOption);
    }
    const handleClick = (event) => {
      event.stopPropagation();
      if (ref.current && !ref.current.contains(event.target)) {
        if (!event.target.closest(".drop")) {
          setIsOpen(false);
        }
      }
    };

    document.addEventListener("click", handleClick);
    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, [ref, selectedOption]);

  return (
    <div ref={ref} className="drop">
      <button className="" onClick={() => setIsOpen(!isOpen)} disabled={disabled}>
        {selectedOption ? (selectedOption[selectedLabel] || selectedOption[label]) : selected[label] ? selected[label] : placeholder}
        {showSelectedCount && selected[label] && `/0${options.length}`}
        <BsChevronDown />
      </button>
      {isOpen && (options && options.length ? (
        <ul className="dropdown-menu_ bg-white">
          {options && options.length > 0 ? options.map((option, index) => (
            <li
              className={classNames({ "active": selected[label] === option[label] })} key={index}
              onClick={() => handleSelectOption(option)}
            >
              <span className="dropdown-item_">{option[label]} {selected[label] === option[label]}</span>
            </li>
          )) : (
            <li>
              <span className="dropdown-item_">{noDataMessage || `Oops no data found`}</span>
            </li>
          )}
        </ul>
      ) : null)}
    </div>
  );
};

export default DropdownMenu;
