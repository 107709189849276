import React, { useCallback, useEffect, useState } from "react";
import classNames from "classnames";
import { Form, Modal } from "react-bootstrap";
import { BsDownload, BsEyeFill } from "react-icons/bs";
import { GrClose } from "react-icons/gr";
import { LuDownload } from "react-icons/lu";
import { HiArrowPath } from "react-icons/hi2";
import { FaCaretLeft, FaCaretRight } from "react-icons/fa";
import GIFLoader from "../../../Assets/Gif/8080laoder.gif";

const ImageDisplayContent = ({
  image, imageIndex, viewImgHistory, postImagesModal, setPostImagesModal, handleSaveSelectedImage,
  oonaImgGenerate = false, handleSetImageAndPrompt = () => { },
}) => {
  const [loaded, setLoaded] = useState(false);
  const [isMultiSelectImg, setIsMultiSelectImg] = useState(false);
  const [viewImgModal, setViewImgModal] = useState({ open: false, img: "", index: 0, history: [] });

  useEffect(() => {
    if (postImagesModal && postImagesModal.subType) {
      // const checkTypeArray = ["LinkedIn Post", "Facebook Post", "Twitter Post", "Instagram Post"];
      // if (checkTypeArray.includes(postImagesModal.subType)) {
      if (postImagesModal.type === "E-Commerce Blog" && postImagesModal.subType !== "Article Images") {
        setIsMultiSelectImg(true);
      }
    }
  }, [postImagesModal.subType])

  const handleDownloadImage = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setLoaded(false);
    const link = document.createElement('a');
    link.download = `image-${Date.now()}.png`;

    fetch(image).then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.blob();
    }).then(blob => {
      setLoaded(true);
      link.href = URL.createObjectURL(blob);
      link.click();
      URL.revokeObjectURL(link.href)
    }).catch(() => {
      setLoaded(true);
    });
  }

  const handleOnChangeViewImage = useCallback((index) => {
    if (viewImgModal.history && viewImgModal.history.length > 0) {
      if (index !== -1 && viewImgModal.history.length >= index) {
        if (viewImgModal.history[index] && viewImgModal.history[index] !== "" && viewImgModal.history[index].backgroundImage) {
          setViewImgModal((prev) => ({ ...prev, open: true, img: viewImgModal.history[index].backgroundImage, index }));
        } else if (viewImgModal.history[index] && viewImgModal.history[index] !== "" && viewImgModal.history[index].image) {
          setViewImgModal((prev) => ({ ...prev, open: true, img: viewImgModal.history[index].image, index }));
        } else {
          setViewImgModal((prev) => ({ ...prev, open: true, img: viewImgModal.history[index], index }));
        }
      }
    }
  }, [viewImgModal.history])

  const handleKeyDown = useCallback((e) => {
    if (e.key === 'ArrowLeft') {
      handleOnChangeViewImage(viewImgModal.index - 1);
    } else if (e.key === 'ArrowRight') {
      if (viewImgModal.history?.length) {
        handleOnChangeViewImage(viewImgModal.history?.length === viewImgModal.index + 1 ? viewImgModal.history?.length - 1 : viewImgModal.index + 1);
      }
    }
  }, [handleOnChangeViewImage, viewImgModal]);

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown)
    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [handleKeyDown])

  const handleUploadImage = () => {
    if (isMultiSelectImg) {
      const carouselImg = postImagesModal?.carouselImg ? postImagesModal.carouselImg : [];
      setPostImagesModal((prev) => ({ ...prev, image, carouselImg: [...carouselImg, image] }));
    } else {
      const obj = { ...postImagesModal, image };
      setPostImagesModal(obj); handleSaveSelectedImage(obj);
    }
  };

  const onSelectOrUnSelectImages = (e) => {
    let carouselImg = postImagesModal?.carouselImg ? postImagesModal.carouselImg : [];
    let finalImage = "";
    if (e.target.checked) {
      carouselImg.push(image); finalImage = image;
    } else {
      carouselImg = carouselImg.filter(item => item !== image);
      if (carouselImg && carouselImg.length > 0) {
        finalImage = carouselImg[carouselImg.length - 1];
      }
    }
    setPostImagesModal((prev) => ({ ...prev, image: finalImage, carouselImg }));
  };

  return (
    <React.Fragment>
      <div className="card border-0" onClick={() => handleUploadImage()}>
        <img src={image} alt="Post pictures" onLoad={() => setLoaded(true)} onError={() => setLoaded(true)} />
        {!loaded && image && (
          <div className="custom-post-loader">
            <img src={GIFLoader} className="w-100 h-75" alt="loading..." />
          </div>
        )}
        <div className={classNames("showHoverButtons", {
          "active": (postImagesModal && (postImagesModal?.image === image || postImagesModal?.carouselImg?.includes(image)))
        })}>
          <div className="vdButton d-flex">
            {isMultiSelectImg && (
              <input
                type={"checkbox"} id={`checkbox-select-img`} name="select-img" className="form-check-input"
                checked={(postImagesModal && postImagesModal?.carouselImg?.includes(image)) ? true : false}
                onChange={onSelectOrUnSelectImages}
              />
            )}
            {oonaImgGenerate && (
              <button
                className="bg-white border-0 rounded ms-2" data-tooltip-id={"my-tooltip"} data-tooltip-place="bottom-end" data-tooltip-content={`Re-Generate`}
                onClick={(e) => { handleSetImageAndPrompt(image); e.stopPropagation(); e.preventDefault(); }}
              >
                <HiArrowPath />
              </button>
            )}
            <button className="bg-white border-0 rounded ms-auto"
              data-tooltip-id={"my-tooltip"} data-tooltip-place="left" data-tooltip-content={`View`}
              onClick={(e) => {
                e.stopPropagation(); e.preventDefault();
                setViewImgModal({ open: true, img: image, index: imageIndex, history: viewImgHistory });
              }}
            >
              <BsEyeFill />
            </button>
            <button className="bg-white border-0 rounded ms-2" onClick={handleDownloadImage}
              data-tooltip-id={"my-tooltip"} data-tooltip-place="bottom-end" data-tooltip-content={`Download`}
            >
              <BsDownload />
            </button>
          </div>
          <p className="text-center">{isMultiSelectImg ? "Click to select" : "Select to upload"}</p>
        </div>
      </div>

      {viewImgModal && viewImgModal.open && (
        <Modal centered fullscreen className="image-history-modal" backdrop="static"
          show={viewImgModal.open} onHide={() => setViewImgModal({ open: false, img: "" })}
        >
          <Modal.Body className="">
            <div className="image-content">
              <button className="btn arrow-btn"
                onClick={() => handleOnChangeViewImage(viewImgModal.index - 1)}
                disabled={viewImgModal.index !== 0 ? false : true}
              >
                <FaCaretLeft className="" />
              </button>
              <div className="image-view-wrapper">
                <img src={viewImgModal.img} alt="" />
              </div>
              <button className="btn arrow-btn" onClick={() => handleOnChangeViewImage(viewImgModal.index + 1)}
                disabled={viewImgModal.index !== (viewImgModal.history.length - 1) ? false : true}
              >
                <FaCaretRight className="" />
              </button>
              <button className="btn download-btn me-3" onClick={(e) => handleDownloadImage(e, viewImgModal.img)}>
                <LuDownload className="fs-4" /> Download Image
              </button>
              <label className="img-label">
                <span className="activeSliderCount">{viewImgModal.index < 9 ? 0 : ""}{viewImgModal.index + 1}</span> / {viewImgModal.history.length < 10 ? 0 : ""}{viewImgModal.history.length}
              </label>
            </div>
            <button className="btn close-btn" onClick={() => setViewImgModal({ open: false, img: "", index: 0 })}>
              <GrClose className="fs-4" />
            </button>
          </Modal.Body>
        </Modal>
      )}
    </React.Fragment>
  )
}

export default ImageDisplayContent;
