import React, { useEffect, useState } from "react";
import { ReactSVG } from "react-svg";
import classNames from "classnames";
import { IoIosWifi } from "react-icons/io";
import { FaSignal } from "react-icons/fa6";
import { IoBatteryFull } from "react-icons/io5";
import { BsStars, BsUpload } from "react-icons/bs";
import { BsThreeDots } from "react-icons/bs";
import { oneClickBlogIcon } from "../../Assets/Icons";
import GIFLoader from "../../Assets/Gif/8080laoder.gif";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";

const ImageLoader = () => {
  return (
    <div className="custom-post-loader">
      <img src={GIFLoader} alt="loading..." />
    </div>
  )
}

const InstagramPostPreview = (props) => {
  const { setUploadFileModal, userRes, text, isUploadImage = false } = props;
  const [previewImg, setPreviewImg] = useState("");
  const [carouselImages, setCarouselImages] = useState([]);
  const [carouselIndex, setCarouselIndex] = useState(0);
  const [isShowFullPost, setIsShowFullPost] = useState(false);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (!props.preview_img) {
      setLoaded(true);
    } else if (props.preview_img && props.preview_img !== "") {
      try {
        const imageData = JSON.parse(props.preview_img);
        if (imageData.type === "url") {
          setPreviewImg(imageData.data);
        } else if (imageData.type === "carousel") {
          setPreviewImg(imageData.data[0]);
          setCarouselImages(imageData.data);
        }
      } catch (error) {
        setPreviewImg(props.preview_img);
      }
    }
  }, [props.preview_img]);

  return (
    <div className="instagramMediaView text-center position-relative">
      <ReactSVG src={oneClickBlogIcon.mobilescreen} />
      <div className="mediaLayout">
        <div className="topBar d-flex justify-content-between align-items-center ">
          <div className="signalTime d-flex justify-content-between align-items-center">
            <div className="signal position-relative">
              <FaSignal />
            </div>
            <div className="timeDate">
              <p className="m-0">9:41</p>
            </div>
          </div>
          <div className="chargeing d-flex justify-content-between align-items-center gap-1">
            <IoIosWifi />
            <IoBatteryFull />
          </div>
        </div>
        <div className="instaPage">
          <div className="searchBar d-flex justify-content-between gap-2">
            <div className="instaTop">
              <img src={oneClickBlogIcon.instaTop} alt="instagram Top" />
            </div>
          </div>
          <div className="instaStory">
            <div className="youStory d-flex align-items-cneter gap-1  overflow-hidden" style={{ paddingBottom: "5px" }}>
              <div className="story cardIn ">
                <div className="instaframe position-relative">
                  <img src={oneClickBlogIcon.instaFrame} alt="instagram frame" className="w-100" />
                  <div className="instaProfile">
                    <img src={userRes?.profile_url || oneClickBlogIcon.instaAvtar} alt="instagram avatar" className="w-100" />
                  </div>
                </div>
                <p>Your Story</p>
              </div>
              <div className="story cardIn  noStory">
                <div className="instaframe position-relative">
                  <img src={oneClickBlogIcon.instaFrame} alt="instagram frame" className="w-100" />
                </div>
                <p></p>
              </div>
              <div className="story cardIn  noStory">
                <div className="instaframe position-relative">
                  <img src={oneClickBlogIcon.instaFrame} alt="instaFrame" className="w-100" />
                </div>
                <p></p>
              </div>
              <div className="story cardIn  noStory">
                <div className="instaframe position-relative">
                  <img src={oneClickBlogIcon.instaFrame} alt="instaFrame" className="w-100" />
                </div>
                <p></p>
              </div>
              <div className="story cardIn  noStory">
                <div className="instaframe position-relative">
                  <img src={oneClickBlogIcon.instaFrame} alt="instaFrame" className="w-100" />
                </div>
                <p></p>
              </div>
            </div>
          </div>
          <div className="postsBlock">
            <div className="userNameProfile d-flex justify-content-between align-items-center gap-3">
              <div className="userInfo d-flex justify-content-center align-items-center gap-2">
                <div className="story cardIn ">
                  <div className="instaframe position-relative">
                    <img src={oneClickBlogIcon.instaFrame} className="w-100" alt="instaFrame" />
                    <div className="instaProfile">
                      <img src={userRes?.profile_url || oneClickBlogIcon.instaAvtar} alt="instaAvtar" />
                    </div>
                  </div>
                </div>
                <div className="userName">
                  <span><strong>{userRes?.username}</strong></span>
                </div>
              </div>
              <BsThreeDots />
            </div>

            <div className={classNames("postedText ", { notContent: !text?.length })}>
              <div className="genrateAiBlock position-relative">
                {carouselImages && carouselImages.length > 0 ? (
                  <div className={`img-wrapper carousel-wrapper position-relative ${!previewImg ? "bg-dark opacity-75" : ""}`}>
                    <div className="slides" id="scroll-div">
                      {carouselImages.map((image, index) => (
                        <div className="" key={index}>
                          <img src={carouselImages[index]} alt="Post pictures" onLoad={() => setLoaded(true)} onError={() => setLoaded(true)} />
                        </div>
                      ))}
                    </div>
                    <div className="arrow-button">
                      {carouselIndex !== 0 && (
                        <div className="left-arrow-button" type="button"
                          onClick={() => {
                            setCarouselIndex(carouselIndex - 1);
                            const content = document.querySelector('#scroll-div');
                            content.scrollLeft -= 300;
                          }}
                          onKeyDown={() => { }}
                        >
                          <MdKeyboardArrowLeft className='fs-3' />
                        </div>
                      )}
                      {(carouselIndex + 1) !== carouselImages.length && (
                        <div className="right-arrow-button" type="button"
                          onClick={() => {
                            setCarouselIndex(carouselIndex + 1);
                            const content = document.querySelector('#scroll-div');
                            content.scrollLeft += 300;
                          }} onKeyDown={() => { }}
                        >
                          <MdKeyboardArrowRight className='fs-3' />
                        </div>
                      )}
                    </div>
                  </div>
                ) : previewImg ? (
                  <img src={previewImg} alt="Post pictures" onLoad={() => setLoaded(true)} onError={() => setLoaded(true)} className=" object-fit-cover w-100 h-100 " />
                ) : null}
                {!loaded && previewImg && (<ImageLoader />)}
                {text && isUploadImage && !previewImg ? (
                  <div className="fieldUpload position-relative">
                    <input className="form-control border-0 shadow" type="buttton" id="formFileMultiple" multiple />
                    <button className="blogButton border-0 bg-white" onClick={setUploadFileModal}>
                      <BsStars /> Generate AI Image
                      <div className="dot">
                        <div className="circle"></div>
                      </div>
                    </button>
                  </div>
                ) : isUploadImage && previewImg ? (
                  <button type="button" onClick={() => setUploadFileModal(true)} className="addlly-primary image-upload-btn">
                    <BsUpload />
                  </button>
                ) : null}
              </div>
            </div>
            <div className="postIcons">
              <img src={oneClickBlogIcon.postsIcons} alt="postsIcons" />
            </div>
            <div className="instaPostText d-flex px-2">
              <pre
                className="m-0" style={isShowFullPost ? { display: "block", paddingRight: "0px" } : {}}
                dangerouslySetInnerHTML={{ __html: `<b className="userName me-2">${userRes?.username}</b> ${text}` }}
              />
              <span className={classNames("more-btn", { "d-none": !text })} onClick={() => setIsShowFullPost(!isShowFullPost)}>
                {isShowFullPost ? "less" : "    more"}
              </span>
            </div>
            <div className="commentsBlock px-2">
              <p className="text-start">View all 0 comments...</p>
            </div>

            <div className="addCommentsB d-flex  align-items-center gap-1 px-2 flex-wrap">
              <div className="story cardIn ">
                <div className="instaframe position-relative">
                  <img src={oneClickBlogIcon.instaFrame} alt="insta frame" className="w-100" />
                  <div className="instaProfile">
                    <img src={oneClickBlogIcon.instaAvtar} alt="insta avatar" className="w-100" />
                  </div>
                </div>
              </div>
              <p className="m-0">Add a comment</p>
              <p className="m-0 dayAgo text-start w-100"><span>2 days</span></p>
            </div>
            <div className="instaMenus">
              <img src={oneClickBlogIcon.instaMenu} alt="instaMenu" className="w-100" />
              <div className="story cardIn ">
                <div className="instaframe position-relative">
                  <div className="instaProfile">
                    {/* <img src={oneClickBlogIcon.instaAvtar} alt="insta Avatar" className="w-100" /> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InstagramPostPreview;
