import React, { useEffect, useState } from "react";
import Editor from "@monaco-editor/react";
import js_beautify from "js-beautify";
import copy from "copy-to-clipboard";
import toast from "react-hot-toast";

const HtmlViewer = ({ value, html, onEditorChange, setToggle, style = {} }) => {
  const [code, setCode] = useState(js_beautify.html_beautify(value));

  useEffect(() => {
    if (html && html !== "" && /<html[^>]*>/.test(html)) {
      const parser = new DOMParser();
      const bodyText = parser.parseFromString(value, 'text/html').body.outerHTML;
      const finalHtml = `${html.substring(0, html.indexOf("<body"))} ${bodyText} </html>`
        .replaceAll("\n", " ").replaceAll("\t", " ").replace(/\s+/g, ' ').trim();
      setCode(js_beautify.html_beautify(finalHtml));
    }
  }, [html, value]);

  const handleCopyHtmlArticleText = (code) => {
    const isCoped = copy(code, {
      debug: true,
      format: code ? "text/plain" : "text/html",
    });
    if (isCoped) {
      toast.success("HTML has been copied to clipboard.", { id: "Toast-01" });
    }
  };

  return (
    <div className="textEditerArea html-content position-relative" style={style}>
      <div className="goBackBtn">
        <button type="button" className="blogButton border-0 mb-3" onClick={() => setToggle(false)}>
          <span>Go back to content</span>
        </button>
        <button type="button" className="blogButton border-0 mb-3" onClick={() => handleCopyHtmlArticleText(code)}>
          Copy HTML
        </button>
      </div>
      <Editor
        theme="vs-dark"
        height="100%"
        minHeight="100vh"
        className="editorMinHeight editorMinHeightHtml"
        defaultLanguage="html"
        value={code}
        onChange={onEditorChange}
        options={{
          formatOnPase: true,
          formatOnType: true,
        }}
      />
    </div>
  );
};
export default HtmlViewer;
