import { invokeAxiosPost } from "./invokeAxiosFunction";

export const uploadPdfFileOnS3 = async (file, type) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = async (event) => {
      try {
        const base64Data = event.target.result;
        const params = {
          imageBase64: base64Data,
          file_name: file.name,
          type: type,
        }

        const res = await invokeAxiosPost(`/template-generator/get-or-generate/uploadImageAndFileOnS3AndGetUrl`, params);
        if (res && res.data && res.data.path) {
          resolve(res.data.path);
        } else {
          resolve("");
        }
      } catch (error) {
        console.log('Error : ', error);
        resolve("");
      }
    }
  });
};
