import toast from "react-hot-toast";

const toasterSuccess = (message, toasterId) => {
  toast.success(message, { id: toasterId, duration: 4000 });
};

const toasterError = (message, toasterId) => {
  toast.error(message, { id: toasterId, duration: 4000 });
};

export { toasterSuccess, toasterError };
