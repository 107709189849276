import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import copy from "copy-to-clipboard";
import { ImEarth } from "react-icons/im";
import { MdDeleteOutline } from "react-icons/md";
import { BsInfoCircleFill, BsTrash } from "react-icons/bs";
import { FiPlus } from "react-icons/fi";
import { IoIosLink } from "react-icons/io";
import { BsArrowLeft } from "react-icons/bs";
import { BsArrowRight } from "react-icons/bs";

import { setKeywordTopic, setLoader, setSelectedGoogleWebsite, setSelectedTopic, setSeoGenerateTopic, setSeoOptBlogStep, setSeoOptBlogTopic } from "../../store/reducer";
import { invokeAxiosPost } from "../../utility/invokeAxiosFunction";
import classNames from "classnames";

const SecondStepSeoOptBlog = () => {
  const dispatch = useDispatch();

  const selectedGoogleWebsite = useSelector(({ selectedGoogleWebsite }) => selectedGoogleWebsite);
  const seoOptBlogTopic = useSelector(({ seoOptBlogTopic }) => seoOptBlogTopic);
  const seoGenerateTopic = useSelector(({ seoGenerateTopic }) => seoGenerateTopic);
  const keywordTopic = useSelector(({ keywordTopic }) => keywordTopic);
  const userRes = useSelector(({ userRes }) => userRes);

  const [checkVal, setCheckVal] = useState(false);
  const [allSelectedGoogleWebsite, setAllSelectedGoogleWebsite] = useState([]);
  const [addNewUrl, setAddNewUrl] = useState([]);
  const [isDisabledInput, setIsDisabledInput] = useState(false);

  useEffect(() => {
    let obj = [];
    if (seoOptBlogTopic && seoOptBlogTopic.addedNewUrl) {
      obj = [...seoOptBlogTopic.addedNewUrl];
    }
    if (seoOptBlogTopic && seoOptBlogTopic.data) {
      obj = [...obj, ...seoOptBlogTopic.data.slice(0, 10)];
    }
    setAddNewUrl(obj);

    if (selectedGoogleWebsite && selectedGoogleWebsite.length > 0) {
      setAllSelectedGoogleWebsite(selectedGoogleWebsite);
    }

    if (keywordTopic && keywordTopic.length > 0) {
      setIsDisabledInput(true);
    }
  }, [])

  useEffect(() => {
    const applyTooltipDataAttributes = (tooltipSpan) => {
      if (tooltipSpan) {
        tooltipSpan.setAttribute('data-tooltip-id', 'my-tooltip');
        tooltipSpan.setAttribute('data-tooltip-place', 'right');
        tooltipSpan.setAttribute('data-tooltip-content', 'Copy Link');
      }
    };
    setTimeout(() => {
      const tooltipSpans = document.querySelectorAll('.tooltip-span');
      tooltipSpans.forEach(applyTooltipDataAttributes);
    }, 0);
  }, [addNewUrl]);

  const handleTopicChange = (txt) => {
    dispatch(setSeoGenerateTopic(txt));
  };

  const handleAddInput = () => {
    const addedOldUrl = seoOptBlogTopic && seoOptBlogTopic.addedNewUrl ? seoOptBlogTopic.addedNewUrl : [];
    if (addedOldUrl && addedOldUrl.length >= 3) {
      return toast.error("You can add only 3 new url.", { id: "Toast-01" });
    }
    dispatch(setSeoOptBlogTopic({ ...seoOptBlogTopic, addedNewUrl: [{ content: '', url: '' }, ...addedOldUrl] }));
    setAddNewUrl([{ content: '', url: '' }, ...addNewUrl]);
  };

  const handleInputChange = (event, index, key) => {
    const newData = [...addNewUrl];
    newData[index] = { content: '', url: event.target.value };
    setAddNewUrl([...newData]);

    const addedUrl = [...seoOptBlogTopic.addedNewUrl];
    addedUrl[index] = { content: '', url: event.target.value };
    dispatch(setSeoOptBlogTopic({ ...seoOptBlogTopic, addedNewUrl: addedUrl }));
  };

  const handleSelectOrUnselectLink = (item, isCheck) => {
    if (item.url && item.url !== "") {
      if (allSelectedGoogleWebsite.length >= 4 && isCheck) {
        toast.error("Select at least 1 and at most 4 Links.", { id: "Toast-01" });
        return false;
      }

      const getObjIndex = allSelectedGoogleWebsite?.findIndex((item1) => item1.url === item.url);
      if (getObjIndex === -1 && allSelectedGoogleWebsite.length < 4) {
        setAllSelectedGoogleWebsite((prevState) => [...prevState, { ...item }]);
      } else {
        const newArray = allSelectedGoogleWebsite.filter((item1) => item1.url !== item.url);
        setAllSelectedGoogleWebsite(newArray);
      }
    }
  };

  const handleRemoveNewUrl = (index, url) => {
    const newArray = allSelectedGoogleWebsite.filter((item1) => item1.url !== url);
    setAllSelectedGoogleWebsite(newArray);
    const newData = [...addNewUrl?.filter((ele, i) => i !== index)];
    setAddNewUrl([...newData]);
    const blogTopicNewurls = seoOptBlogTopic.addedNewUrl?.filter((ele, i) => i !== index);
    if (blogTopicNewurls) {
      const addedUrl = [...blogTopicNewurls];
      dispatch(setSeoOptBlogTopic({ ...seoOptBlogTopic, addedNewUrl: addedUrl }));
    }
  }

  const reGenerate = async () => {
    if (seoGenerateTopic !== "") {
      dispatch(setLoader(true));
      const payload = {
        topic: seoGenerateTopic,
        user_id: userRes.id,
        articleId: seoOptBlogTopic.articleId,
        geoLocation: seoOptBlogTopic.geoLocation,
        blog_type: "Topic",
        pdfBase64: "",
        isGenerateLinks: true,
      };
      const response = await invokeAxiosPost("/seo-blog-co-pilot/get-or-generate/generateTopics", payload);
      dispatch(setLoader(false));
      if (response && response.success === 1) {
        setAllSelectedGoogleWebsite([]);
        dispatch(setSeoOptBlogTopic({ ...seoOptBlogTopic, ...response.data }));
        if (response.data && response.data.data) {
          setAddNewUrl([...response.data.data.slice(0, 10)]);
        }
      } else {
        toast.error(response?.error, { id: "Toast-01" });
      }
    } else {
      toast.error('Please enter the topic', { id: "Toast-01" });
    }
  };

  const handleNext = async () => {
    if (allSelectedGoogleWebsite.length >= 1) {
      if (keywordTopic && keywordTopic.length > 0) {
        dispatch(setSeoOptBlogStep(4));
        return false;
      }

      dispatch(setLoader(true));
      const payload = {
        queryStringParameters: {
          articleId: seoOptBlogTopic.articleId,
          topic: seoOptBlogTopic?.keyword ? seoOptBlogTopic.keyword : seoGenerateTopic,
          geoLocation: seoOptBlogTopic.geoLocation,
        }
      };
      generateSummaryAndMetaFaqHtml();
      const response = await invokeAxiosPost("/seo-blog-co-pilot/get-or-generate/getKeywords", payload);
      dispatch(setLoader(false));
      if (response && response.success === 1) {
        dispatch(setKeywordTopic(response.data));
        dispatch(setSelectedTopic(seoGenerateTopic));
        dispatch(setSelectedGoogleWebsite(allSelectedGoogleWebsite));
        dispatch(setSeoOptBlogTopic({ ...seoOptBlogTopic, stepDone: seoOptBlogTopic.stepDone < 4 ? 4 : seoOptBlogTopic.stepDone }));
        dispatch(setSeoOptBlogStep(4));
      } else {
        toast.error(response?.error || "Server is overloaded, try again after sometime.", { id: "Toast-01" });
      };
    } else {
      toast.error("Select at least 1 and at most 4.", { id: "Toast-01" });
    }
  };

  const generateSummaryAndMetaFaqHtml = async () => {
    const payload = {
      topic: seoOptBlogTopic?.topic ? seoOptBlogTopic.topic : seoGenerateTopic ? seoGenerateTopic : "",
      keyword: seoOptBlogTopic?.keyword ? seoOptBlogTopic.keyword : seoGenerateTopic,
      selectedGoogleWebsite: allSelectedGoogleWebsite,
      articleId: seoOptBlogTopic.articleId,
    };
    invokeAxiosPost("/seo-blog-co-pilot/get-or-generate/generateSummaryAndMetaFaqHTML", payload);
  };

  const handleCopyLink = (data) => {
    toast.success("Link has been copied to clipboard.");
    copy(data);
  };

  return (
    <React.Fragment>
      <div className="addllyFormWrap topicLinks">
        <div className="selectBlock seoselected mw-100">
          <h5>Select Links (Please update the heading later)</h5>
        </div>
        <div className="form-group ">
          <label> Your topic
            <BsInfoCircleFill
              className="ms-2 outline-0 infoIconSvg " data-tooltip-id="my-tooltip" data-tooltip-place="right"
              data-tooltip-content={`Edit topic to re-generate links`}
            />
          </label>
          <div className="d-flex flex-wrap align-items-center justify-content-center gap-1">
            <input
              name="topic" type="text" placeholder="Topic" value={seoGenerateTopic}
              onChange={(e) => { setCheckVal(true); handleTopicChange(e.target.value); }}
              className={`addllyForm-control`} disabled={isDisabledInput}
              style={{ minWidth: '100px' }}
            />
            <button
              type="button" disabled={!checkVal || !seoGenerateTopic || isDisabledInput}
              className="addlly-primary" style={{ margin: 0 }} onClick={reGenerate}
            >
              Regenerate Addlly 10 Links
            </button>
          </div>
        </div>
      </div>
      <Row className="">
        <Col md={12}>
          <div className="googleSearchResult">
            <div className={"addUrlBar"} style={{ display: 'flex', justifyContent: "space-between", alignItems: "center", flexWrap: 'wrap', gap: '10px' }}>
              <h6 className="font-weight-500 color-light-text mb-0">Top 10 Google Search Results (Select at least 1 and at most 4)</h6>
              <button className="addlly-outline btn-sm py-0 px-0" onClick={handleAddInput} disabled={isDisabledInput}>
                <FiPlus /> Add New URL
              </button>
            </div>
            <ul className="seoOptBlogTopic google-web-wrapper">
              {addNewUrl && addNewUrl.length > 0 && addNewUrl.map((item, ind) => (
                <React.Fragment>
                  <li key={ind} className={classNames("overflow-hidden", { addnewUrl: !item.content })}>
                    <div className={`checkListItem  py-2 flex-column ${allSelectedGoogleWebsite?.find((item1) => item1.url === item.url) ? "active" : ""}`}>
                      <div className={`d-flex align-items-center w-100 linkImageBlock ${!item.content ? "border-0 m-0 p-0" : ""}`}>
                        <input type="checkbox" className="custom-checkbox cursor-pointer" disabled={!item.url || item.url === "" || isDisabledInput}
                          checked={allSelectedGoogleWebsite.find((item1) => item1.url === item.url) ? true : false}
                          onChange={(e) => handleSelectOrUnselectLink(item, e.target.checked)}
                        />
                        <div className="ms-3 w-100 ">
                          {!item.content ? (
                            <input
                              className="w-100 pe-4" placeholder="Place URL here" value={item.url} onClick={(e) => e.stopPropagation()}
                              style={{ background: "transparent", outline: "none", border: '0', fontSize: "14px", marginBottom: "0" }}
                              type="text" name={`url-${ind}`} onChange={(event) => handleInputChange(event, ind, 'url')} disabled={isDisabledInput}
                            />
                          ) : (
                            <a href={item.url} target="_blank" rel="noreferrer" className="link-content" onClick={(e) => e.stopPropagation()}>
                              <div className="d-flex align-items-center mb-1">
                                <div className="link-image">
                                  <DisplayWebUrlImage url={item.url} faviconLink={item?.faviconLink} />
                                </div>
                                <div>
                                  <p className="linkIconText">
                                    {item.url.split("://")[1].split("/")[0]?.replace("www.", "")}
                                    <IoIosLink className="ms-2 outline-0 tooltip-span"
                                      onClick={(e) => { e.stopPropagation(); e.preventDefault(); handleCopyLink(item.url); }}
                                    />
                                  </p>
                                  <p>{item.url.split("?")[0]}</p>
                                </div>
                              </div>
                            </a>
                          )}
                        </div>
                        {!item.content && (
                          <button className="p-0 ms-3 btn" onClick={(e) => { e.stopPropagation(); handleRemoveNewUrl(ind, item.url); }}>
                            {/* <MdDeleteOutline size={24} style={{ fill: "#FF0000" }} /> */}<BsTrash style={{
                              fill: "#FF0000", width: '16px', height: "16px", position: 'relative',
                              top: '-1px'
                            }} />
                          </button>
                        )}
                      </div>
                      {item?.title && item?.title !== "" && (
                        <h4 className="color-dark-blue fw-normal" dangerouslySetInnerHTML={{ __html: item.title }}></h4>
                      )}
                      {item?.description && item?.description !== "" && (
                        <h5 className="google-content mt-1" dangerouslySetInnerHTML={{ __html: item.description }}></h5>
                      )}
                    </div>
                  </li>

                  {!item.content && ind === (addNewUrl?.filter(value => value.content === '')?.length - 1) ? (
                    <div className="divderLine"></div>
                  ) : null}
                </React.Fragment>
              ))}
            </ul>
          </div>
        </Col>
      </Row>
      <div className="w-100">
        <div className="nextPrewBtn d-flex justify-content-center gap-3">
          <button
            type="button" className="addlly-outline"
            onClick={() => {
              dispatch(setSeoOptBlogStep(2));
              const scrollWrapper = document.getElementById('scroll-wrapper');
              if (scrollWrapper) { scrollWrapper.scrollTo({ top: 0, behavior: 'smooth' }); }
            }}
          >
            <BsArrowLeft /> <span>Previous</span>
          </button>
          <div className="buttonWinFo d-flex align-items-center ">
            <button
              type="button" onClick={() => handleNext()} className="addlly-primary"
              disabled={!allSelectedGoogleWebsite || allSelectedGoogleWebsite.length === 0}
            >
              <span>Continue & Select Keywords</span> <BsArrowRight />
            </button>
            <BsInfoCircleFill
              className="ms-2 infoIconSvg" data-tooltip-id="my-tooltip" data-tooltip-place="bottom"
              data-tooltip-content={`Select a minimum of 1 and a maximum of 4 URLs to proceed`}
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

const DisplayWebUrlImage = ({ url, faviconLink }) => {
  const [isImageExit, setIsImageExit] = useState(false);
  const [imageUrl, setImageUrl] = useState('');

  useEffect(() => {
    if (faviconLink && (faviconLink !== 'favicon.ico' && faviconLink !== '/favicon.ico')) {
      if (faviconLink[0] === '/' && faviconLink[1] !== '/') {
        const getOrigin = new URL(url)?.origin;
        setImageUrl(`${getOrigin}${faviconLink}`);
      } else {
        setImageUrl(faviconLink);
      }
      setIsImageExit(true);
    } else if (url) {
      const getOrigin = new URL(url)?.origin;
      setImageUrl(`${getOrigin}/favicon.ico`);
      setIsImageExit(true);
    }
  }, [])

  return (
    <React.Fragment>
      {isImageExit ? (
        <img src={imageUrl} onError={() => setIsImageExit(false)} alt="" />
      ) : (
        <ImEarth className="text-primary" />
      )}
    </React.Fragment>
  )
}

export default SecondStepSeoOptBlog;
