import React from 'react';

function GradientSVG() {
    const idCSS = "progressive-gradient";
    const gradientTransform = `rotate(90)`;

    return (
        <svg style={{ height: 0 }}>
            <defs>
                <linearGradient id={idCSS} gradientTransform={gradientTransform}>
                    <stop offset="0%" stopColor="#00F" />
                    <stop offset="100%" stopColor="#F00" />
                </linearGradient>
            </defs>
        </svg>
    );
}

export default GradientSVG;