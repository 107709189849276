import React, { memo, useEffect, useMemo, useRef, useState } from "react";
import moment from "moment";
import classNames from "classnames";
import Swal from "sweetalert";
import copy from "copy-to-clipboard";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { Offcanvas } from "react-bootstrap";
import { Editor } from "@tinymce/tinymce-react";
import { BsPen, BsTrash } from "react-icons/bs";
import { AiOutlineExpandAlt } from "react-icons/ai";
import { MdCloseFullscreen } from "react-icons/md";
import { IoIosArrowDown } from "react-icons/io";
import { setLoader } from "../../store/reducer";
import { copyIcon } from "../../Assets/Icons";
import { EditorInitObj } from "../../utility/hepler"
import { deleteOrArchiveArticle } from "../../utility/apiService";
import { invokeAxiosGet } from "../../utility/invokeAxiosFunction";
const beautify_html = require("js-beautify").html;

export default memo(function ArticlePreview({ userRes, articleId, show = false, setShow, getHistoryData, articleType, handleNavigateArticleOrPostWriter }) {
    const editorRef = useRef(null);
    const dispatch = useDispatch();
    const [articleData, setArticleData] = useState({});
    const [loading, setLoading] = useState(false);
    const [isShow, setIsShow] = useState(false);
    const [isFullScreen, setIsFullScreen] = useState(false);
    const iframeFor = useMemo(() => ['ecommerce_blog', 'newsletters_history'], []);

    useEffect(() => {
        if (articleId) {

            let url = "";
            if (articleType === "long_flow" || articleType === "oona_blog") {
                url = `/article-history/get-or-save/articleHistoryGetById?id=${articleId}?tableName=artical_long_flow`;
            } else if (articleType === "social_media") {
                url = `/social-media-post/get-or-generate/socialMediaPostHistoryGetById?id=${articleId}`;
            } else if (articleType === "short_flow" || articleType === "oona_short" || articleType === "oona_long") {
                url = `/article-history/get-or-save/articleHistoryGetById?id=${articleId}`;
            } else if (articleType === "newsletters_history") {
                url = `/news-letters/get-or-generate/getNewsletterDetailByNewsId?id=${articleId}`;
            } else if (articleType === "press-release") {
                url = `/press-release/get-or-generate/getPressReleaseDetailsById?id=${articleId}`;
            } else if (articleType === 'ecommerce_blog') {
                url = `/e-commerce/get-or-generate/getProductCatalogHistoryOrDetails?id=${articleId}`
            }

            if (url) {
                setLoading(true);
                dispatch(setLoader(true));
                invokeAxiosGet(url).then((res) => {
                    const finalResult = res.data && res.data.data ? res.data.data : res.data ? res.data : {};
                    const tempObj = {
                        topic: finalResult?.topic ? finalResult.topic : "",
                        keyword: finalResult?.keyword ? finalResult.keyword : "",
                        AI_type: finalResult?.AI_type ? finalResult.AI_type : "GPT 3.5",
                        created_at: finalResult?.created_at ? finalResult.created_at : "",
                    };
                    if (
                        articleType === "short_flow" || articleType === "oona_short" || articleType === "oona_long" ||
                        articleType === "long_flow" || articleType === "oona_blog"
                    ) {
                        tempObj.article_html = finalResult.article_html;
                    } else if (articleType === "social_media") {
                        const sentences = finalResult.final_post.split('\n');
                        let output = '<p>' + sentences.join('</p><p>') + '</p>';
                        tempObj.article_html = output.replaceAll("<p></p>", "");
                    } else if (articleType === "newsletters_history") {
                        tempObj.keyword = finalResult.cta;
                        tempObj.article_html = finalResult.resText;
                    } else if (articleType === "press-release") {
                        tempObj.topic = finalResult.subType;
                        tempObj.keyword = finalResult.type;
                        tempObj.article_html = finalResult.resText;
                        tempObj.created_at = finalResult.created_at;
                    } else if (articleType === 'ecommerce_blog') {
                        tempObj.topic = finalResult.meta_title;
                        tempObj.keyword = finalResult?.type;
                        tempObj.article_html = finalResult.article;
                        tempObj.created_at = finalResult.created_at;
                    }
                    setArticleData(tempObj);
                }).catch((e) => {
                    console.error("Error : ", e);
                }).finally(() => {
                    dispatch(setLoader(false));
                    setLoading(false);
                });
            }
        }
    }, [articleId, dispatch, articleType])

    const handleCopyHtmlArticleText = (text) => {
        if (text && text !== "") {
            const options = { indent_size: 2, space_in_empty_paren: true };
            copy(beautify_html(text, options), { format: "text/html" });
            toast.success("Text has been copied to clipboard.", { id: "Toast-01" });
        }
    };

    const handleArchive = () => {
        Swal({
            title: "Are you sure?",
            text: "Do you want to delete the selected item?",
            icon: "warning", dangerMode: true,
            buttons: { cancel: "Cancel", confirm: "Yes, delete!" },
        }).then((isConfirmed) => {
            if (isConfirmed) {
                const callback = (resp) => {
                    getHistoryData();
                    setShow(false);
                }
                deleteOrArchiveArticle([{ id: articleId, type: articleType }], 'delete', callback);
            }
        });
    };

    const onHide = () => {
        setShow(false);
        setIsFullScreen(false);
    }

    if (loading) {
        return "";
    }

    return (
        <Offcanvas placement='end' className={classNames({ fullScreen: isFullScreen })} show={show} onHide={onHide}>
            <div className='articalDetailsPopUp'>
                <Offcanvas.Header className='justify-content-end align-items-center'>
                    <div className='buttons d-flex gap-2 align-items-center'>
                        <button className='btn' onClick={handleArchive}><BsTrash /></button>
                        <button onClick={() => handleCopyHtmlArticleText(articleData?.article_html)} className='btn'>
                            <img src={copyIcon} alt="copy" />
                        </button>
                        <button className='btn' onClick={() => handleNavigateArticleOrPostWriter(articleId, articleType)}><BsPen /></button>
                        <button onClick={() => setIsFullScreen(pre => !pre)} className='btn'>{isFullScreen ? <MdCloseFullscreen /> : <AiOutlineExpandAlt />}</button>
                        <button type="button" className="btn-close text-reset p-0" onClick={onHide} ></button>
                    </div>
                </Offcanvas.Header>
                <Offcanvas.Body className={`p-0 overflow-hidden`}>
                    <div className='types'>
                        <div className="article-type blogInfo">
                            <p>Type</p>
                            <span>
                                {articleType === "social_media" ? "Social Media Post Writer" : articleType === "long_flow" ? "SEO Blog Co-Pilot" :
                                    articleType === "newsletters_history" ? "Newsletter Builder" : articleType === "press-release" ? "Press Release Quick Draft" :
                                        articleType === 'ecommerce_blog' ? 'E-Commerce Blog Writer' : articleType === "oona_short" ? "Oona 1-Click Blog Writer" :
                                            articleType === "oona_long" ? "Oona Long Blog" : articleType === "oona_blog" ? "Oona Blog Co-Pilot" : "1-Click Blog Writer"}
                            </span>
                        </div>
                        <div className="createdOn blogInfo">
                            <p>Generated On</p>
                            <span>{moment(articleData?.created_at).format("MMM DD, YYYY, HH:MM A")}</span>
                        </div>
                        <div className="createdBy blogInfo" >
                            <p>Created By</p>
                            <span>{userRes?.first_name}{' '}{userRes?.last_name}</span>
                        </div>
                    </div>
                    <div className='showhide'>
                        <button onClick={() => setIsShow(pre => !pre)} className='blogButton rounded-pill border-0'>{isShow ? 'Hide' : 'Show'} Inputs
                            <span className={classNames({ rotate: isShow })}><IoIosArrowDown className={classNames({ rotate: isShow })} /></span>
                        </button>
                        {isShow && (
                            <div className='inputs show'>
                                <div>
                                    <p htmlFor='topic'>What do you want to write about?</p>
                                    <span children={articleData?.topic} id='topic' type="text" />
                                </div>
                                <div>
                                    <p htmlFor='keyword'>Keyword</p>
                                    <span children={articleData?.keyword} id='keyword' type="text" />
                                </div>
                                <div>
                                    <p htmlFor='AI_type'>AI Model</p>
                                    <span children={articleData?.AI_type} id='AI_type' type="text" />
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="content mt-4 pe-2 " style={{ height: "calc(100vh - 300px)" }}>
                        <div className={`textarea-article-html h-100  ${!!!iframeFor.includes(articleType) && 'overflow-auto'} `}>
                            <Editor
                                apiKey={process.env.REACT_APP_TINYMCE_EDITOR_KEY}
                                onInit={(evt, editor) => (editorRef.current = editor)}
                                value={articleData?.article_html ? articleData?.article_html : ""} disabled
                                init={{
                                    ...EditorInitObj, height: "100%",
                                    content_style: `${(articleData?.article_html?.match(/<style>([\s\S]*?)<\/style>/i)?.[1] || "")} img { width: 100% }`,
                                    setup: function (editor) {
                                        editor.on("init", function () {
                                            editor.getDoc().body.addEventListener("click", function (e) {
                                                if (e.target.tagName === "A") {
                                                    e.preventDefault();
                                                    const target = e.target.getAttribute("target");
                                                    if (target && target === "_blank") {
                                                        window.open(e.target.href, "_blank");
                                                    } else {
                                                        window.open(e.target.href, "_self");
                                                    }
                                                }
                                            });
                                        });
                                    },
                                }}
                            />
                        </div>
                    </div>
                </Offcanvas.Body>
            </div>
        </Offcanvas>
    )
})  
