const PressReleaseInputFields = {
  "Product Launch": [
    {
      label: "Product Name",
      name: "productName",
      placeholder: "Enter product’s name",
      type: "textarea",
      required: true,
    },
    {
      label: "Product’s Key Features",
      name: "keyFeatures",
      placeholder: "Enter key features",
      type: "textarea",
      required: true,
    },
    {
      label: "Unique Benefits",
      name: "uniqueBenefits",
      placeholder: "Example: My company has reached the Q4 financial goals. I want to let my employees knows about it.",
      type: "textarea",
      required: true,
    },
    {
      label: "Target Market",
      name: "targetMarket",
      placeholder: "Enter target market",
    },
    {
      label: "Launch Date",
      name: "launchDate",
      placeholder: "dd / mm / yyyy",
      type: "date",
    },
    {
      label: "Pricing",
      name: "pricing",
      placeholder: "Example: INR 3500",
    },
    {
      label: "Availability",
      name: "availability",
      placeholder: "Enter availability",
    },
    {
      label: "Company Name",
      name: "companyName",
      placeholder: "Enter company name",
    },
    {
      label: "Company’s CEO",
      name: "ceoName",
      placeholder: "Enter CEO’s name",
    },
  ],
  "Partnership Announcement": [
    {
      label: "Names Of The Companies",
      name: "namesOfTheCompanies",
      placeholder: "Enter company names",
      type: "textarea",
      required: true,
    },
    {
      label: "Nature Of The Partnership",
      name: "natureOfThePartnership",
      placeholder: "Enter nature of partnership",
      type: "textarea",
      required: true,
    },
    {
      label: "Mutual Benefits",
      name: "mutualBenefits",
      placeholder: "Example: My company has reached the Q4 financial goals. I want to let my employees knows about it.",
      type: "textarea",
      required: true,
    },
    {
      label: "Goals",
      name: "goals",
      placeholder: "Enter goals",
      type: "textarea",
      col: "6",
    },
    {
      label: "Brief Background Of Each Company",
      name: "briefBackgroundOfEachCompany",
      placeholder: "Enter background",
      type: "textarea",
      col: "6",
    },
    {
      label: "Company Name",
      name: "companyName",
      placeholder: "Enter company name",
      col: "6",
    },
    {
      label: "Company’s CEO",
      name: "ceoName",
      placeholder: "Enter CEO’s name",
      col: "6",
    },
  ],
  "Corporate Event Announcement": [
    {
      label: "Event Name",
      name: "eventName",
      placeholder: "Enter name",
    },
    {
      label: "Event Date",
      name: "date",
      placeholder: "dd / mm / yyyy",
      type: "date",
    },
    {
      label: "Event Location",
      name: "location",
      placeholder: "Enter location",
    },
    {
      label: "Purpose of the Event",
      name: "purpose",
      placeholder: "Enter purpose",
      type: "textarea",
      required: true,
    },
    {
      label: "Key Speakers / Highlights",
      name: "keySpeakersOrHighlights",
      placeholder: "Enter name of key speakers / event’s highlights",
      type: "textarea",
      required: true,
    },
    {
      label: "Target Audience",
      name: "targetAudience",
      placeholder: "Describe target audience",
      type: "textarea",
      required: true,
    },
    {
      label: "Company Name",
      name: "companyName",
      placeholder: "Enter company name",
      col: "6",
    },
    {
      label: "Company’s CEO",
      name: "ceoName",
      placeholder: "Enter CEO’s name",
      col: "6",
    },
  ],
  "Financial or Earnings Report": [
    {
      label: "Key Financial Figures",
      name: "keyFinancialFigures",
      placeholder: "Enter financial figures",
      type: "textarea",
      required: true,
    },
    {
      label: "Growth Indicators",
      name: "growthIndicators",
      placeholder: "Enter growth indicators",
      type: "textarea",
      required: true,
    },
    {
      label: "Market Impact",
      name: "marketImpact",
      placeholder: "Enter market impact",
      type: "textarea",
      required: true,
    },
    {
      label: "Future Outlook",
      name: "futureOutlook",
      placeholder: "Enter future outlook",
    },
    {
      label: "Company Name",
      name: "companyName",
      placeholder: "Enter company name",
    },
    {
      label: "Company’s CEO",
      name: "ceoName",
      placeholder: "Enter CEO’s name",
    },
  ],
  "New Executive Hire": [
    {
      label: "Executive’s Name",
      name: "executiveName",
      placeholder: "Enter executive’s name",
      col: "6",
    },
    {
      label: "Position",
      name: "position",
      placeholder: "Enter position",
      col: "6",
    },
    {
      label: "Professional Background",
      name: "professionalBackground",
      placeholder: "Enter professional background",
      type: "textarea",
      required: true,
    },
    {
      label: "Role In The Company",
      name: "roleInTheCompany",
      placeholder: "Describe role",
      type: "textarea",
      required: true,
    },
    {
      label: "Expected Impact",
      name: "expectedImpact",
      placeholder: "Enter impact",
      type: "textarea",
      required: true,
    },
    {
      label: "Company Name",
      name: "companyName",
      placeholder: "Enter company name",
      col: "6",
    },
    {
      label: "Company’s CEO",
      name: "ceoName",
      placeholder: "Enter CEO’s name",
      col: "6",
    },
  ],
  "Company Milestone": [
    {
      label: "Nature Of The Milestone",
      name: "natureOfTheMilestone",
      placeholder: "Enter nature of the milestone",
      type: "textarea",
      col: "6",
      required: true,
    },
    {
      label: "Historical Context",
      name: "historicalContext",
      placeholder: "Enter historical context",
      type: "textarea",
      col: "6",
      required: true,
    },
    {
      label: "Impact On The Company",
      name: "impactOnTheCompany",
      placeholder: "Enter impact on the company",
      type: "textarea",
      col: "6",
      required: true,
    },
    {
      label: "Future Implications",
      name: "futureImplications",
      placeholder: "Enter future implications",
      type: "textarea",
      col: "6",
      required: true,
    },
    {
      label: "Company Name",
      name: "companyName",
      placeholder: "Enter company name",
    },
    {
      label: "Company’s CEO",
      name: "ceoName",
      placeholder: "Enter CEO’s name",
    },
  ],
  "Award Win": [
    {
      label: "Award Name",
      name: "awardName",
      placeholder: "Enter award name",
      type: "textarea",
      col: "6",
      required: true,
    },
    {
      label: "Criteria",
      name: "criteria",
      placeholder: "Enter criteria",
      type: "textarea",
      col: "6",
      required: true,
    },
    {
      label: "Significance To The Company",
      name: "significanceToTheCompany",
      placeholder: "Enter significance to the company",
      type: "textarea",
      col: "6",
      required: true,
    },
    {
      label: "Future Goals",
      name: "futureGoals",
      placeholder: "Enter future goals",
      type: "textarea",
      col: "6",
      required: true,
    },
    {
      label: "Company Name",
      name: "companyName",
      placeholder: "Enter company name",
    },
    {
      label: "Company’s CEO",
      name: "ceoName",
      placeholder: "Enter CEO’s name",
    },
  ],
  "Media Contact Info": [
    {
      label: "Name",
      name: "mediaContactName",
      placeholder: "Enter name",
    },
    {
      label: "Email",
      name: "mediaContactEmail",
      placeholder: "Enter email",
    },
    {
      label: "Phone Number",
      name: "mediaContactPhone",
      placeholder: "Enter contact number",
    },
    {
      label: "Website",
      name: "companyWebsiteLink",
      placeholder: "Enter website",
    },
    {
      label: "Address",
      name: "mediaContactAddress",
      placeholder: "Enter address",
      col: "8",
    },
  ],
};

export default PressReleaseInputFields;
