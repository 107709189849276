import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import Select from "react-select";
import { toast } from "react-hot-toast";
import momentTimezone from "moment-timezone";
import { Col, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSelector, useDispatch } from "react-redux";
import { BsCameraFill } from "react-icons/bs";

import { getUserDetail } from "../../utility/apiService";
import { setLoader, setUserRes } from "../../store/reducer";
import { uploadPdfFileOnS3 } from "../../utility/uploadPdfFileOnS3";
import { invokeAxiosPost } from "../../utility/invokeAxiosFunction";

const validationSchema = Yup.object().shape({
  first_name: Yup.string()
    .required("First name is required")
    .min(2, "First name must be at least 2 characters")
    .max(20, "First Name cannot be more than 20 Characters Long")
    .matches(/^[a-zA-Z]+$/, 'First Name should contain only letters')
    .matches(/^(?!\s)(?!.*\s{2})[^\s](.*[^\s])?$/, "Topic should not contain multiple consecutive white spaces"),
  username: Yup.string()
    .required("Username is required")
    .min(2, "Username must be at least 2 characters")
    .max(20, "Username must not exceed 20 characters")
    .matches(/^(?!\s)(?!.*\s{2})[^\s](.*[^\s])?$/, "Username should not contain multiple consecutive white spaces"),
  phone_number: Yup.string()
    .required("Phone number is required")
    .matches(/^[0-9]+$/, 'Phone number should contain only number')
    .min(8, "Phone number must be at least 8 digits")
    .max(13, "Phone number must not exceed 13 digits")
    .matches(/^(?!\s)(?!.*\s{2})[^\s](.*[^\s])?$/, "phone Number should not contain multiple consecutive white spaces"),
  email: Yup.string()
    .email("Must be a valid email")
    .required("Email is required")
    .min(2, "Email must be at least 2 characters")
    .matches(/^(?!\s)(?!.*\s{2})[^\s](.*[^\s])?$/, "Email should not contain multiple consecutive white spaces"),
  timezone: Yup.string().required("Timezone is required").min(2, "Timezone must be at least 2 characters"),
});

const MyProfileTab = () => {
  const dispatch = useDispatch();
  const userRes = useSelector(({ userRes }) => userRes);
  const username = useSelector(({ username }) => username);

  const [timezoneOptions, setTimezoneOptions] = useState([]);

  const { control, register, handleSubmit, formState: { errors }, setValue, reset } = useForm({
    defaultValues: {},
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    reset({
      first_name: userRes.first_name,
      last_name: userRes.last_name,
      username: userRes.username,
      email: userRes.email,
      phone_number: userRes.phone_number,
      timezone: userRes.timezone,
      industry: userRes.industry,
      company: userRes.company,
    });

    const timeZones = momentTimezone.tz.names();
    const offsetTmz = [];
    for (const i in timeZones) {
      const timeZone = `${timeZones[i]} (GMT ${momentTimezone.tz(timeZones[i]).format('Z')})`;
      offsetTmz.push({ label: timeZone, value: timeZone });
    }
    setTimezoneOptions(offsetTmz);

    if (!userRes?.timezone) {
      const getCurrTimezone = `${momentTimezone.tz.guess()} (GMT ${momentTimezone.tz(momentTimezone.tz.guess()).format('Z')})`;
      setValue("timezone", getCurrTimezone);
    }
  }, [])

  const onSubmit = (data) => {
    dispatch(setLoader(true));
    for (let key in data) {
      if (key !== "enabled_2FA") {
        if (data.hasOwnProperty(key)) {
          data[key] = data[key] ? data[key].trim() : "";
        }
      }
    }
    invokeAxiosPost(`/user-api/get-or-create/userUpdate`, { ...data, id: userRes.id }).then((res) => {
      dispatch(setLoader(false));
      dispatch(getUserDetail(userRes?.id));
      toast.success("User profile updated successfully.");
    }).catch((error) => {
      dispatch(setLoader(false));
      toast.error("Something went wrong.", { id: "Toast-01" });
      console.log(error);
    });
  };

  const handleAvatar = async (e) => {
    const file = e.target.files[0];
    const MAX_FILE_SIZE = 5120; // 5MB
    const fileSizeKiloBytes = file.size / 1024;
    if (fileSizeKiloBytes > MAX_FILE_SIZE) {
      toast.error("File size is greater than maximum limit", { id: "Toast-01" });
      return;
    }

    dispatch(setLoader(true));
    const filePath = await uploadPdfFileOnS3(file, "profileImage");
    dispatch(setUserRes({ ...userRes, profile_url: filePath }));
    invokeAxiosPost(`/user-api/get-or-create/userProfile`, { userId: userRes?.id, profile: filePath }).then((res) => {
      dispatch(setLoader(false));
    }).catch((error) => {
      dispatch(setLoader(false));
      toast.error("Something went wrong.", { id: "Toast-01" });
    });
  };

  return (
    <div className="d-flex flex-column" style={{ gap: "32px" }}>
      <div className="accountCard">
        <div className="cardHead">
          <h3>My Profile</h3>
        </div>
        <div className="cardBody">
          <div className="profile-card-header d-flex justify-content-between">
            <div className="d-flex gap-2">
              <div className="profile-image">
                {userRes?.profile_url ? (
                  <img src={userRes?.profile_url} alt="user" className="img-fluid rounded-circle box-shadow-1" />
                ) : (
                  <div className="profile-avatar box-shadow-1">{username[0].toUpperCase()}</div>
                )}
                <div className="addImg">
                  <button className="rounded-circle">
                    <BsCameraFill />
                  </button>
                  <input type="file" onChange={(e) => handleAvatar(e)} accept="image/png, image/jpg, image/jpeg" />
                </div>
              </div>
              <div className="profile-name">
                <div className="name-title">{userRes?.first_name || ""} {userRes?.last_name || ""}</div>
                <div className="username-title">{username ? `@${username}` : ""}</div>
              </div>
            </div>
          </div>
          <form className="addllyFormWrap profile-form" onSubmit={handleSubmit(onSubmit)}>
            <Row className="">
              <Col sm={6} className="mb-sm-4 mb-3">
                <div className="form-group">
                  <label>First Name <span className="required-astrick" style={{ color: "red" }}>*</span></label>
                  <input type="text" {...register("first_name")} className="addllyForm-control" placeholder="Enter First Name" />
                </div>
                <div className="text-danger">{errors.first_name?.message}</div>
              </Col>
              <Col sm={6} className="mb-sm-4 mb-3">
                <div className="form-group">
                  <label>Last Name</label>
                  <input type="text" {...register("last_name")} className="addllyForm-control" placeholder="Enter Last Name" />
                </div>
                <div className="text-danger">{errors.last_name?.message}</div>
              </Col>
              <Col sm={6} className="mb-sm-4 mb-3">
                <div className="form-group">
                  <label>Username</label>
                  <input type="text" {...register("username")} value={userRes.username} className="addllyForm-control" placeholder="Enter Username" disabled />
                </div>
                <div className="text-danger">{errors.username?.message}</div>
              </Col>
              <Col sm={6} className="mb-sm-4 mb-3">
                <div className="form-group">
                  <label>Email</label>
                  <input type="text" {...register("email")} value={userRes.email} className="addllyForm-control" placeholder="Enter Email" disabled />
                </div>
                <div className="text-danger">{errors.email?.message}</div>
              </Col>
              <Col sm={6} className="mb-sm-4 mb-3">
                <div className="form-group">
                  <label>Phone Number <span className="required-astrick" style={{ color: "red" }}>*</span></label>
                  <input type="text" {...register("phone_number")} className="addllyForm-control" placeholder="Enter Phone Number" />
                </div>
                <div className="text-danger">{errors.phone_number?.message}</div>
              </Col>
              <Col sm={6} className="mb-sm-4 mb-3">
                <div className="form-group">
                  <label>Timezone</label>
                  <Controller
                    control={control} {...register("timezone")}
                    render={({ field: { onChange, value, name, ref } }) => (
                      <Select
                        className={`custom-select ${errors.toneOfVoice ? "is-invalid" : ""}`}
                        classNamePrefix="custom-select" placeholder="Select Tone of Voice"
                        options={timezoneOptions} isClearable={false}
                        value={timezoneOptions.find(c => c.value === value)}
                        onChange={(e) => onChange(e.value)} isSearchable={false}
                      />
                    )}
                  />
                </div>
                <div className="text-danger">{errors.timezone?.message}</div>
              </Col>
              <Col sm={6} className="mb-sm-4 mb-3">
                <div className="form-group">
                  <label>Industry</label>
                  <input type="text" {...register("industry")} className="addllyForm-control" placeholder="Enter Industry" />
                </div>
                <div className="text-danger">{errors.industry?.message}</div>
              </Col>
              <Col sm={6} className="mb-sm-4 mb-3">
                <div className="form-group">
                  <label>Company</label>
                  <input
                    type="text" {...register("company")} className="addllyForm-control"
                    placeholder="Enter Company" disabled={userRes?.organization_id ? true : false}
                  />
                </div>
                <div className="text-danger">{errors.company?.message}</div>
              </Col>
            </Row>
            <div className="d-flex">
              <button type="submit" className="addlly-primary w-auto" style={{ padding: "16px 24px" }}>
                Save Changes
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default MyProfileTab;
