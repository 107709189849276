import React from "react";
import { Modal } from "react-bootstrap";
import LeavePageImg from "../../Assets/Images/LeavePage.png";

const LeaveWithoutSaving = ({ show, onHide, leavePage }) => {
  return (
    <Modal className="confirmationModal" centered show={show} onHide={onHide}>
      <Modal.Body className="text-center">
        <img className="mb-4" src={LeavePageImg} alt="addlly.ai" />
        <h3 className="mb-3">Leave Page Without Saving.</h3>
        <p className="mb-3">You have unsaved changes that will be lost if you decide to continue.</p>
        <p className="mb-4">Are you sure you want to leave this page?</p>
        <div className="d-sm-flex justify-content-between ">
          <button onClick={() => leavePage()} type="button" className="addlly-secondary px-4">
            Leave this Page
          </button>
          <button onClick={onHide} type="button" className="addlly-primary px-4">
            Stay on this Page
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default LeaveWithoutSaving;
