import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setNewsletterWriterInfo, setNewsletterWriterStep } from "../../store/reducer";

const NewslettersHeader = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const newsletterWriterStep = useSelector(({ newsletterWriterStep }) => newsletterWriterStep);
  const newsletterWriterInfo = useSelector(({ newsletterWriterInfo }) => newsletterWriterInfo);
  const [isMobileView, setIsMobileView] = useState(false);
  const [isMobileHead, setIsMobileHead] = useState()

  useEffect(() => {
    const handleResize = () => {
      setIsMobileHead(window.innerWidth <= 320);
    };

    setTimeout(() => {
      handleResize();
    }, 1000)

    window.addEventListener('resize', handleResize);
    window.addEventListener('load', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('load', handleResize);
    };
  }, []);

  useEffect(() => {
    function mobileViewUpdate() {
      var viewportWidth = window.innerWidth;
      if (viewportWidth <= 770) {
        setIsMobileView(true);
      } else {
        setIsMobileView(false);
      }
    }
  }, []);

  const handleOnChangeStep = (no) => {
    if (newsletterWriterInfo?.stepDone && no <= newsletterWriterInfo.stepDone) {
      dispatch(setNewsletterWriterStep(no));
    }
  }

  return (
    <div className="AIWriterBlogHead SocialMediaHeader">
      <div className="AiWriterBlogHeadTop justify-content-center AIWriterStepper">
        <ul>
          <li
            className={`${newsletterWriterStep === 1 ? "active" : ""} ${1 < newsletterWriterStep ? "complete" : ""} ${1 <= newsletterWriterInfo.stepDone ? "is-clickable" : ""}`}
            onClick={() => handleOnChangeStep(1)} onKeyDown={() => handleOnChangeStep(1)}
          >
            <span className="d-flex justify-content-center align-items-center">01</span>
            <p className={`${isMobileView ? "step-border" : ""}`} style={{ fontSize: isMobileHead && '10px' }}>{isMobileView ? "" : "Select Template"}</p>
          </li>
          <li
            className={`${newsletterWriterStep === 2 ? "active" : ""} ${2 < newsletterWriterStep ? "complete" : ""} ${2 <= newsletterWriterInfo.stepDone ? "is-clickable" : ""}`}
            onClick={() => handleOnChangeStep(2)} onKeyDown={() => handleOnChangeStep(2)}
          >
            <span className="d-flex justify-content-center align-items-center">02</span>
            <p className={`${isMobileView ? "step-border" : ""}`} style={{ fontSize: isMobileHead && '10px' }}>{isMobileView ? "" : "Describe Topic"}</p>
          </li>
          <li
            className={`${newsletterWriterStep === 3 ? "active" : ""} ${3 < newsletterWriterStep ? "complete" : ""} ${3 <= newsletterWriterInfo.stepDone ? "is-clickable" : ""}`}
            onClick={() => handleOnChangeStep(3)} onKeyDown={() => handleOnChangeStep(3)}
          >
            <span className="d-flex justify-content-center align-items-center">03</span>
            <p className={`${isMobileView ? "step-border" : ""}`} style={{ fontSize: isMobileHead && '10px' }}>{isMobileView ? "" : "Edit Newsletter"}</p>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default NewslettersHeader;
