import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row } from "react-bootstrap";
import { toast } from "react-hot-toast";
import { Editor } from "react-editor";
import { BsArrowLeft, BsArrowRight, BsInfoCircleFill, BsStars } from "react-icons/bs";
import DropdownMenu from "../ui/Dropdown";
import { invokeAxiosPost } from "../../utility/invokeAxiosFunction";
import { setLoader, setSocialMediaPostStep, setSocialMediaStepsInfo } from "../../store/reducer";

const FourthStepSocialMediaPost = () => {
  const dispatch = useDispatch();
  const socialMediaPostInfo = useSelector(({ socialMediaPostInfo }) => socialMediaPostInfo);
  const [selectedHashTags, setSelectedHashTags] = useState(socialMediaPostInfo?.selected_hashtags || []);
  const [generatedHashTags, setGeneratedHashTags] = useState([]);
  const [editPost, setEditPost] = useState(socialMediaPostInfo?.selectedPost || "");
  const [selectGenerateHashtag, setSelectGenerateHashtag] = useState(1);

  useEffect(() => {
    if (
      socialMediaPostInfo?.generated_hashtags && typeof JSON.parse(socialMediaPostInfo.generated_hashtags) !== typeof undefined &&
      JSON.parse(socialMediaPostInfo.generated_hashtags)?.length > 0
    ) {
      setGeneratedHashTags(JSON.parse(socialMediaPostInfo.generated_hashtags));

      try {
        const selectedHashTags = socialMediaPostInfo?.selected_hashtags || [];
        for (let index = 0; index < selectedHashTags.length; index++) {
          const element = selectedHashTags[index];
          if (editPost.indexOf(element) === -1) {
            setEditPost((prev) => prev + ` <span class="hash-tags">${element}</span> `);
          } else {
            setEditPost((prev) => prev.replace(` ${element} `, ` <span class="hash-tags">${element}</span> `));
          }
        }
      } catch (error) {
      }
    }
  }, [])

  useEffect(() => {
    const applyTooltipDataAttributes = (tooltipSpan) => {
      if (tooltipSpan) {
        tooltipSpan.setAttribute('data-tooltip-id', 'my-tooltip');
        tooltipSpan.setAttribute('data-tooltip-place', 'bottom-end');
        tooltipSpan.setAttribute('data-tooltip-content', 'You can regenerate hashtags only two times.');
      }
    };
    setTimeout(() => {
      const tooltipSpans = document.querySelectorAll('.tooltip-span');
      tooltipSpans.forEach(applyTooltipDataAttributes);
    }, 0);
  }, [generatedHashTags?.length]);

  const versionOptions = useMemo(() => {
    const newDropVersion = [];
    const totalLength = (generatedHashTags.length / 5);
    for (let index = 0; index < totalLength; index++) {
      newDropVersion.push({ name: `Generation 0${index + 1}`, label: `Generation 0${index + 1}/0${totalLength}`, value: index + 1 });
    }
    return newDropVersion;
  }, [generatedHashTags]);

  const handleSetOldGeneratedHashtags = (obj) => {
    if (obj && obj.value && obj.value !== "") {
      setSelectGenerateHashtag(obj.value);
    }
  };

  const handleNext = async () => {
    dispatch(setLoader(true));
    const finalEditPost = editPost.replace(/<span[^>]*>(.*?)<\/span>/gi, '$1');
    invokeAxiosPost(`/social-media-post/get-or-generate/generateSummaryOrSaveHashtags`, {
      postId: socialMediaPostInfo?.postId,
      selectedHashTags: selectedHashTags,
      selectedPost: finalEditPost,
    }).then((response) => {
      dispatch(setLoader(false));
      dispatch(setSocialMediaStepsInfo({
        ...socialMediaPostInfo, selected_hashtags: selectedHashTags, ...response.data,
        stepDone: socialMediaPostInfo.stepDone < 6 ? 6 : socialMediaPostInfo.stepDone,
      }));
      dispatch(setSocialMediaPostStep(6));
    }).catch((error) => {
      dispatch(setLoader(false));
      toast.error(error?.response?.data?.error?.message || error?.response?.data?.error || "Server is overloaded, try again after sometime.", { id: "Toast-01" });
    });
  }

  const regeneratePostHashtags = async () => {
    if (generatedHashTags && generatedHashTags.length >= 15) {
      toast.error("Hashtags regenerate limit is over, Click to insert popular hashtags if not already included in the post.", { id: "Toast-01" });
      return false;
    }

    dispatch(setLoader(true));
    const payload = {
      postId: socialMediaPostInfo?.postId,
      selectedPost: socialMediaPostInfo?.selectedPost,
      oldHashtags: socialMediaPostInfo?.oldHashtags || [],
    };
    const response = await invokeAxiosPost("/social-media-post/get-or-generate/generateSocialMediaPostHashTags", payload);
    dispatch(setLoader(false));
    if (response && response.success === 1) {
      dispatch(setSocialMediaStepsInfo({ ...socialMediaPostInfo, ...response.data }));
      if (
        response.data?.generated_hashtags && typeof JSON.parse(response.data.generated_hashtags) !== typeof undefined &&
        JSON.parse(response.data.generated_hashtags)?.length > 0
      ) {
        setGeneratedHashTags(JSON.parse(response.data.generated_hashtags));
        setSelectGenerateHashtag(JSON.parse(response.data.generated_hashtags).length / 5);
      }
    } else {
      toast.error(response?.error, { id: "Toast-01" });
    }
  }

  const handleInputChange = (event, index) => {
    const newData = [...generatedHashTags];
    setSelectedHashTags(selectedHashTags.filter((ele1) => ele1 !== newData[index].tag));
    newData[index] = { ...newData[index], tag: event.target.value };
    setGeneratedHashTags([...newData]);
    dispatch(setSocialMediaStepsInfo({ ...socialMediaPostInfo, generated_hashtags: JSON.stringify(newData) }));
  };

  const handleOnSelectHashtags = (event, ele) => {
    if (event.target.checked) {
      setSelectedHashTags([...selectedHashTags, ele.tag]);

      const hashtags = editPost.match(/#[^\s#]*/g);
      if (hashtags && hashtags.length > 0) {
        const lastHashtagIndex = editPost.lastIndexOf(hashtags[hashtags.length - 1]);
        const startPost = editPost.slice(0, lastHashtagIndex + hashtags[hashtags.length - 1].length);
        const endPost = editPost.slice(lastHashtagIndex + hashtags[hashtags.length - 1].length);
        setEditPost(`${startPost} <span class="hash-tags">${ele.tag}</span>${endPost}`);
      } else {
        setEditPost((prev) => prev + ` <span class="hash-tags">${ele.tag}</span>`);
      }
    } else {
      setSelectedHashTags(selectedHashTags.filter((ele1) => ele1 !== ele.tag));
      const regex = new RegExp(`(^|\\s)<span class="hash-tags">${ele.tag}</span>(\\s|$)`, 'g');
      setEditPost((prev) => prev.replace(regex, " "));
    }
  }

  return (
    <div>
      <div className="selectBlock d-flex flex-wrap gap-3 align-items-center justify-content-between mx-2 mx-md-auto">
        <div className="">
          <b>
            Select the suitable hashtags
            <BsInfoCircleFill
              className="infoIconSvg" data-tooltip-id="my-tooltip" data-tooltip-place="bottom-start"
              data-tooltip-content={`Using real-time trending hashtags can boost your post's reach.`}
            />
          </b>
        </div>
        <div className="nxtGenBtn d-flex flex-wrap gap-3 align-items-center align-items-center gap-2">
          <button
            type="button" className="d-flex align-items-center gap-2 bg-transparent border-0 selectBbutton"
            onClick={() => {
              dispatch(setSocialMediaPostStep(4));
              const scrollWrapper = document.getElementById('scroll-wrapper');
              if (scrollWrapper) { scrollWrapper.scrollTo({ top: 0, behavior: 'smooth' }); }
            }}
          >
            <BsArrowLeft /> Previous
          </button>
          <span style={{ opacity: 0.1 }}>|</span>
          <button type="button" className="d-flex align-items-center gap-2 bg-transparent border-0 selectBbutton" onClick={() => handleNext()}>
            Review Post <BsArrowRight />
          </button>
        </div>
      </div>
      <div className="p-32">
        <div className="hashTagBlock">
          <Row>
            <Col sm={12} md={6} className="hashTagTextArea">
              <p>NOTE: Before you continue, you can edit your post manually. These changes will be reflected in the preview.</p>
              <div className="form-group mb-4 ">
                <div className="custom-textarea-editor position-relative h-100 ">
                  {/* <textarea
                    className="w-100 outlineTextarea textarea-content" value={editPost}
                    onChange={(e) => setEditPost(e.target.value)}
                  /> */}
                  <Editor className="w-100 outlineTextarea textarea-content" value={editPost} onChange={(e) => setEditPost(e)} />
                </div>
              </div>
            </Col>

            <Col sm={12} md={6} className="hastagCards">
              <div className="buttonsBlock d-flex flex-wrap gap-2 align-items-center justify-content-end ">
                {generatedHashTags?.length > 0 && (
                  <React.Fragment>
                    <div className="dropdown social-dropdown me-1">
                      <DropdownMenu
                        options={versionOptions} label={"name"} placeholder={"Generations"} selectedLabel={"label"}
                        onSelect={handleSetOldGeneratedHashtags} selectedOption={{
                          name: `Generation 0${selectGenerateHashtag}`, value: selectGenerateHashtag,
                          label: `Generation 0${selectGenerateHashtag}/0${versionOptions.length}`,
                        }}
                      />
                    </div>
                    <button onClick={() => regeneratePostHashtags()} disabled={generatedHashTags && generatedHashTags.length >= 15 ? true : false} type="button" className="blogButton border-0">
                      <BsStars /> <span>Re-Generate Hashtags</span>
                    </button>
                    <BsInfoCircleFill className="infoIconSvg ms-1 tooltip-span" />
                  </React.Fragment>
                )}
              </div>
              <div className="columnLine">
                <div className="genHeading">
                  <p>Generation {selectGenerateHashtag || 1}</p>
                </div>
                <Row className={`pt-1`} style={{ overflow: "auto" }} >
                  {generatedHashTags && generatedHashTags.length > 0 ? (
                    <React.Fragment>
                      {generatedHashTags.slice(Number(selectGenerateHashtag - 1) * 5, Number(selectGenerateHashtag - 1) * 5 + 5).map((ele, index) => (
                        <Col sm={12} key={index}>
                          <div className={`hashtag-content mb-2 d-flex  shadow-none align-items-center ${selectedHashTags.includes(ele.tag) && 'active'}`}>
                            <input name={ele.tag} id={ele.tag} type="checkbox" className="custom-input cursor-pointer"
                              checked={selectedHashTags.includes(ele.tag) ? true : false}
                              onChange={(e) => handleOnSelectHashtags(e, ele)}
                            />
                            <div className="ms-3 hashtag-input">
                              <input value={ele.tag} type="text" name={`hashtag-${index}`} onChange={(e) => handleInputChange(e, Number((selectGenerateHashtag - 1) * 5) + index)} />
                              <p>{ele.volume?.toLocaleString("en-US")} Followers</p>
                            </div>
                          </div>
                        </Col>
                      ))}
                    </React.Fragment>
                  ) : (
                    <div className="my-5 text-center">
                      <h3 className="mt-4 fw-normal opacity-50">
                        No relevant hashtags.
                      </h3>
                      <h4 className="fw-normal opacity-50">
                        You can edit your post manually otherwise skip ahead.
                      </h4>
                    </div>
                  )}
                </Row>
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <div className="d-flex align-items-start justify-content-center  align-items-center flex-row gap-2 bottomButton">
        <button type="button" className="addlly-outline "
          onClick={() => {
            dispatch(setSocialMediaPostStep(4));
            const scrollWrapper = document.getElementById('scroll-wrapper');
            if (scrollWrapper) { scrollWrapper.scrollTo({ top: 0, behavior: 'smooth' }); }
          }}
        >
          <span><BsArrowLeft /> Previous</span>
        </button>
        <div className='d-flex align-items-center gap-3'>
          <button type="button" onClick={() => handleNext()} className="addlly-primary" style={{ whiteSpace: "nowrap" }}>
            <span>Review Post  <BsArrowRight /></span>
          </button>
        </div>
      </div>
    </div>
  )
}

export default FourthStepSocialMediaPost;
