import React, { useEffect, useMemo, useState } from "react";
import moment from "moment";
import { toast } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { BiMinus, BiPlus } from "react-icons/bi";
import TableLoader from "../ui/TableLoader";
import CustomDataTable from "../ui/DataTable";
import { setLoader } from "../../store/reducer";
import { articleHistoryIcons } from "../../Assets/Icons";
import { tableDateFormatter } from "../../utility/common";
import { Modal } from "react-bootstrap";
import { GrClose } from "react-icons/gr";
import { getUserDetail } from "../../utility/apiService";
import { invokeAxiosGet, invokeAxiosPost } from "../../utility/invokeAxiosFunction";

const RefundRequestsHistory = ({ articleType, articleId }) => {
  const dispatch = useDispatch();
  const userRes = useSelector(({ userRes }) => userRes);
  const [page, setPage] = useState(1);
  const [isTableLoader, setIsTableLoader] = useState(true);
  const [requestsHistory, setRequestsHistory] = useState([]);
  const [showRefundModal, setShowRefundModal] = useState(false);
  const [reqRefundData, setReqRefundData] = useState({ id: "", comment: "" });

  useEffect(() => {
    getRefundRequestsHistory();
  }, []);

  const getRefundRequestsHistory = async () => {
    try {
      setIsTableLoader(true); setRequestsHistory([]);
      if (articleType && articleType !== "" && articleId && articleId !== "") {
        const params = `userId=${userRes.id}&articleType=${articleType}&articleId=${articleId}`;
        const response = await invokeAxiosGet(`/user-api/get-or-create/getCreditTransactionListByUserId?${params}`);
        setIsTableLoader(false);
        if (response && response.data && response.data.data) {
          dispatch(getUserDetail(userRes?.id))
          setRequestsHistory(response.data.data);
        }
      } else {
        setIsTableLoader(false);
      }
    } catch (error) {
      setIsTableLoader(false);
    }
  };

  const handleOpenRefundModal = (row) => {
    setShowRefundModal(true);
    setReqRefundData({ id: row?.id, comment: "", data: row });
  };

  const handleCloseRefundModal = () => {
    setShowRefundModal(false);
    setReqRefundData({ id: "", comment: "" });
  };

  const handleSendRefundCreditRequest = () => {
    if (!reqRefundData?.comment) {
      toast.error("Please fill up all the required details.", { id: "Toast-01" });
      return false;
    }

    let commentText = reqRefundData?.comment ? reqRefundData.comment : "";
    commentText = commentText.replace(/[^\S\n]+/g, " ").trim();
    setReqRefundData({ ...reqRefundData, comment: commentText });

    if (commentText !== "") {
      dispatch(setLoader(true));
      const postData = {
        comment: commentText, articleId: reqRefundData?.data?.article_id || "",
        articleType: reqRefundData?.data?.type || "",
        articleSubType: reqRefundData?.data?.sub_type || ""
      };
      invokeAxiosPost(`/user-api/get-or-create/createRefundCreditRequest?id=${reqRefundData.id}`, postData).then(() => {
        dispatch(setLoader(false)); handleCloseRefundModal();
        getRefundRequestsHistory();
        toast.success("Request sended successfully.", { id: "Toast-01" });
      }).catch((error) => {
        dispatch(setLoader(false));
        toast.error(error?.response?.data?.msg || "Something went wrong, please try again.", { id: "Toast-01" });
      });
    } else {
      toast.error("Comment is required", { id: "Toast-01" });
    }
  };

  const columns = useMemo(() => ([
    {
      id: "title",
      name: <div className="d-flex px-3">Type</div>,
      selector: (row) => <div style={{ whiteSpace: "nowrap" }}>{row?.sub_type || "-"}</div>,
      sortable: false,
    },
    {
      id: "credit",
      name: <div className="d-flex px-3">Credit</div>,
      selector: (row) => <div>
        <div className="d-flex align-items-center gap-2">
          {row?.credit > 0 ? (
            <span className="credit-status"><BiPlus className="fs-5" /> {row?.credit}</span>
          ) : (
            <span className="debit-status"><BiMinus className="fs-5" /> {row?.debit}</span>
          )}
        </div>
      </div>,
      sortable: false,
    },
    {
      id: "comment",
      name: <div className="d-flex">Comment</div>,
      selector: (row) => (
        <div className="tableValues">
          {row.comment && row.comment !== "" ? (
            <h6 className="w-100" data-tooltip-id="my-tooltip" data-tooltip-content={row.comment} data-tooltip-place="bottom">{row.comment || "-"}</h6>
          ) : (
            <h6>-</h6>
          )}
        </div>
      ),
      sortable: false,
    },
    {
      id: "admin_comment",
      name: <div className="d-flex">Admin Comment</div>,
      selector: (row) => (
        <div className="tableValues">
          {row.admin_comment && row.admin_comment !== "" && row.admin_comment !== `""` ? (
            <h6 className="w-100" data-tooltip-id="my-tooltip" data-tooltip-content={row.admin_comment} data-tooltip-place="bottom">{row.admin_comment || "-"}</h6>
          ) : (
            <h6>-</h6>
          )}
        </div>
      ),
      sortable: false,
    },
    {
      id: "button",
      name: "Refund Status",
      selector: (row) => {
        return (
          <div className="">
            {!row?.refund_status && row?.credit === 0 && row?.created_at && moment().diff(moment(row.created_at), 'h') <= 24 ? (
              <button className="refund-button" type="button" onClick={() => handleOpenRefundModal(row)}>Refund</button>
            ) : row?.refund_status ? (
              <div className="d-flex justify-content-center align-items-center">
                <div className="dotStatus">
                  <span className={`d-block`}
                    style={{
                      width: "8px", height: "8px", borderRadius: "50%",
                      backgroundColor: row.refund_status === "approved" ? "#84CC16" : row.refund_status === "refund" ? "#84CC16" :
                        row.refund_status === "rejected" ? "#EF4444" : "#F59E0B",
                    }}
                  />
                </div>
                {row.refund_status === "approved" ? "Approved" : row.refund_status === "refund" ? "Refund" :
                  row.refund_status === "rejected" ? "Rejected" : "Pending"}
              </div>
            ) : '-'}
          </div>
        );
      },
    },
    {
      id: "created_at",
      name: <div className="d-flex">Created At</div>,
      selector: (row) => <div>{tableDateFormatter(row?.created_at)}</div>,
      sortable: false,
    },
  ]), []);

  return (
    <div className="profile-page w-100">
      <div className="accountCardContainer">
        <div className="accountCard padding-0 border-0">
          <div className="card-note">
            <b>NOTE:</b> Refunds are only available for credits used within the past 24 hours.
            Please ensure your posts are less than a day old to be eligible for a refund request.
          </div>
          <div className="cardBody">
            <div className={`historyTableBlock mt-2 ${isTableLoader ? "is-loading" : ""}`}>
              <CustomDataTable
                progressPending={isTableLoader} progressComponent={<TableLoader limit={10} />}
                columns={columns} totalCount={requestsHistory?.length} pagination={true} data={requestsHistory}
                itemsPerPage={10} selectableRows={false} noDataComponent={<NoDataFound />}
                onSelectedRowsChange={() => { }} setPage={setPage} page={page} onSort={() => { }}
                handleTableRowClick={() => { }} clearSelectedRows={() => { }}
              />
            </div>
          </div>
        </div>
      </div>

      {showRefundModal && (
        <Modal className="" size="" centered show={showRefundModal} onHide={handleCloseRefundModal}>
          <Modal.Body className="maingenrateBlock m-0 p-4">
            <div className="d-flex align-items-center justify-content-between mb-3 pb-3 border-bottom">
              <strong>Refund Credit Request</strong>
              <button className="btn close-btn p-0" onClick={handleCloseRefundModal}><GrClose /></button>
            </div>
            <div className="genrateFields">
              <div className="fields m-0">
                <div className="form-group">
                  <label>Comment <span className="astrick">*</span></label>
                  <textarea
                    name="comment" type="text" rows="3" placeholder="Insert comment ..."
                    className={`addlly-textarea w-100`} value={reqRefundData?.comment}
                    onChange={(e) => setReqRefundData({ ...reqRefundData, comment: e.target.value })}
                  />
                </div>
              </div>
            </div>
            <div className="mt-4 d-flex justify-content-end">
              <button
                className="addlly-primary w-auto" type="button" variant="primary" onClick={handleSendRefundCreditRequest}
                disabled={reqRefundData?.comment ? false : true}
              >
                Send Request
              </button>
            </div>
          </Modal.Body>
        </Modal>
      )}
    </div>
  );
};

export default RefundRequestsHistory;

const NoDataFound = () => {
  return (
    <div className="withoutData d-flex justify-content-center align-items-center">
      <div className="genrateNewBlock d-flex justify-content-center flex-column align-items-center">
        <div className="icon d-flex justify-content-center flex-column align-items-center">
          <img src={articleHistoryIcons.add} alt="add"></img>
        </div>
        <p>Transaction history is not available yet!</p>
      </div>
    </div>
  );
};
