import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import Logo from "../Assets/Images/Brand.png";
import { setGenerateData, setLoader, setStep, setUsername, setUserRes } from "../store/reducer";
import { invokeAxiosPost } from "../utility/invokeAxiosFunction";

const LandingPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [subResData, setSubResData] = useState(null);

  useEffect(() => {
    if (searchParams && searchParams.get("token") && searchParams.get("token") !== "") {
      const token = searchParams.get("token");
      handleResolveAndActivateSubscription(token);
    } else {
      navigate("/dashboard");
    }
  }, [searchParams])

  const handleResolveAndActivateSubscription = async (token) => {
    try {
      dispatch(setLoader(true));
      const response = await invokeAxiosPost(`/azure-subscriptions/market-place/resolve-subscription`, { token: token });
      dispatch(setLoader(false));
      if (response && response.data && response.data.subResData && response.data.subResData.subscription) {
        setSubResData(response.data.subResData.subscription);

        if (response.data.isCallSaveApi) {
          const paramsData = { subscriptionId: response.data.subResData.id, subscriptionData: response.data.subResData };
          const userInfo = await invokeAxiosPost(`/azure-subscriptions/market-place/create-user-and-subscription`, paramsData);
          if (userInfo.data && userInfo.data.data && userInfo.data.data.id) {
            handleNavigateToDashboard(userInfo.data.data);
          }
        } else if (response.data.userRes && response.data.userRes.id) {
          await new Promise(resolve => setTimeout(resolve, 10000));
          handleNavigateToDashboard(response.data.userRes);
        }
      }
    } catch (error) {
      dispatch(setLoader(false));
      console.log('#### error --->', error);
    }
  };

  const handleNavigateToDashboard = async (response) => {
    if (response && response.id && response.token) {
      localStorage.setItem("token", response.token);
      dispatch(setUsername(response.username));
      dispatch(setUserRes({ id: response.id }));
      dispatch(setStep(1));
      dispatch(setGenerateData({}));
      const detectDeviceType = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ? "Mobile" : "Desktop";
      if (detectDeviceType === "Mobile") {
        localStorage.setItem("isCheckDevice", true);
      }
      navigate("/dashboard");
    }
  };

  return (
    <div className="landing-page">
      <nav class="navbar navbar-expand-lg navbar-dark bg-dark">
        <div className="container text-white py-2 px-5" style={{ maxWidth: "1000px" }}>
          <img src={Logo} alt="addlly.ai" className="res-logo" />
        </div>
      </nav>
      {subResData && subResData.id && (
        <div className="container mt-5 px-5" style={{ maxWidth: "1000px" }}>
          <div className="mt-3 mb-5">
            <h3>Thank you for your subscription</h3>
            <p>Please verify the following information is correct before submitting your subscription for activation by Addlly Ai.</p>
          </div>
          <table className="w-100 mb-4">
            {[
              { label: "Current User", value: subResData?.purchaser?.emailId || "-" },
              { label: "Email", value: subResData?.purchaser?.emailId || "-" },
              { label: "Subscription name", value: subResData?.name || "-" },
              { label: "Plan Name", value: subResData?.planId || "-" },
              { label: "Subscription State", value: subResData?.saasSubscriptionStatus || "-" },
              { label: "Tenant ID", value: subResData?.purchaser?.tenantId || "-" },
            ].map((item, index) => (
              <tr className="" key={index}>
                <td className="w-25 pb-2">{item.label}</td>
                <td className="w-75 pb-2">{item.value}</td>
              </tr>
            ))}
          </table>
          <hr />
          <div className="">
            <h2>You're Almost There!</h2>
            <p className="mb-1">We're redirecting you to our Addlly Ai site in Sometime.</p>
            <p>If you are not logged in, please sign-in with microsoft into Addlly Ai.</p>
          </div>
        </div>
      )}
    </div>
  )
}

export default LandingPage;
