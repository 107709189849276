import React, { useEffect, useMemo, useState } from "react";
import { Form, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import classNames from "classnames";
import { toast } from "react-hot-toast";
import { BiMinus, BiPlus } from "react-icons/bi";
import { BsSearch } from "react-icons/bs";
import { FaPlus } from "react-icons/fa";
import { setLoader } from "../../store/reducer";
import CustomDataTable from "../ui/DataTable";
import TableLoader from "../ui/TableLoader";
import { articleHistoryIcons } from "../../Assets/Icons";
import { SortArrow, sorting, tableDateFormatter } from "../../utility/common";
import { invokeAxiosGet, invokeAxiosPost } from "../../utility/invokeAxiosFunction";

const TransactionHistoryTab = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userRes = useSelector(({ userRes }) => userRes);

  const [currentItems, setCurrentItems] = useState([]);
  const [show, setShow] = useState(false);
  const [reqData, setReqData] = useState({ id: "", comment: "" });
  const [radioValue, setRadioValue] = useState("all");
  const [searchTxt, setSearchTxt] = useState("");
  const [page, setPage] = useState(1);
  const [textWrap, setTextWrap] = useState(false);
  const [tableLoader, setTableLoader] = useState(false);
  const [sortField, setSortField] = useState({ column: {}, direction: "" });

  const handleClose = () => {
    setShow(false);
    setReqData({ id: "", comment: "" });
  };

  useEffect(() => {
    if (userRes && userRes.id) {
      setRadioValue("all");
      handleGetUserTransactions("");
    }
  }, [])

  const handleGetUserTransactions = (type) => {
    setTableLoader(true);
    setCurrentItems([]);
    setPage(1);

    const url = `/user-api/get-or-create/getCreditTransactionListByUserId?userId=${userRes.id}&type=${type}`;
    invokeAxiosGet(url).then((response) => {
      setCurrentItems(response.data?.data);
      setTableLoader(false);
    }).catch((error) => {
      setTableLoader(false);
    });
  };

  const handleRequestForRefundCredit = () => {
    let commentText = reqData?.comment ? reqData.comment : "";
    commentText = commentText.replace(/[^\S\n]+/g, " ").trim();
    setReqData({ ...reqData, comment: commentText });

    if (commentText !== "") {
      dispatch(setLoader(true));
      const postData = {
        comment: commentText, articleId: reqData?.data?.article_id || "",
        articleType: reqData?.data?.type || "",
        articleSubType: reqData?.data?.sub_type || ""
      };
      const url = `/user-api/get-or-create/createRefundCreditRequest?id=${reqData.id}`;
      invokeAxiosPost(url, { ...postData }).then((response) => {
        setCurrentItems(currentItems.map((item) => {
          if (item.id === reqData.id) {
            return { ...item, refund_status: "pending" };
          }
          return item;
        }));
        dispatch(setLoader(false));
        handleClose();
        toast.success("Request sended successfully.", { id: "Toast-01" });
      }).catch((error) => {
        toast.error(error?.response?.data?.msg || "Something went wrong, please try again.", { id: "Toast-01" });
        dispatch(setLoader(false));
      });
    } else {
      toast.error("Comment is required", { id: "Toast-01" });
    }
  };

  const handleOnChangeTransactionList = (val) => {
    if (radioValue === val) {
      return false;
    }

    setRadioValue(val);
    let type = val;
    if (val === "all") {
      type = "";
    }

    handleGetUserTransactions(type);
  };

  const handleSort = (column, sortDirection) => {
    setPage(1);
    setSortField(pre => ({ ...pre, column: column, direction: sortDirection }));
    const newData = sorting(currentItems, column?.sortField, sortDirection);
    setCurrentItems(newData);
  };

  const filteredData = useMemo(() => (
    currentItems?.filter(item =>
      Object.values(item).some(value => typeof value === "string" && value?.split(' ').join('').toLowerCase().includes(searchTxt?.split(' ').join('').toLowerCase()))
    )
  ), [currentItems, sortField, searchTxt]);

  const handleTitle = (row) => {
    let title = '-';
    if (row?.type) {
      if (row?.type === '1-Click Blog') {
        title = '1-Click Blog Writer';
      } else if (row?.type === 'Blog Co-Pilot') {
        title = 'SEO Blog Co-Pilot';
      } else if (row?.type === 'NewsLetters') {
        title = 'Newsletter Builder';
      } else if (row?.type === 'Press Release') {
        title = 'Press Release Quick Draft';
      } else if (row?.type === 'Social Media Post Writer') {
        title = 'Social Media Posts';
      } else {
        title = row?.type;
      }
    }

    return title;
  }

  const columns = useMemo(() => {
    const tempColumns = [
      {
        id: "title",
        name: <div className="d-flex px-3 w-100" style={{ wordBreak: "normal" }}>
          Type {SortArrow(sortField, "title")}
        </div>,
        selector: (row) => (
          <div className={classNames("tableValues", { showFullDes: textWrap })}>
            <h6 style={{ maxWidth: "140px" }}>{handleTitle(row)}</h6>
          </div>
        ),
        sortField: "type",
        sortable: true,
      },
      {
        id: "sub_type",
        name: <div className="d-flex px-2">Sub Type {SortArrow(sortField, "sub_type")}</div>,
        selector: (row) => (
          <div className={classNames("tableValues", { showFullDes: textWrap })}>
            <h6 style={{ fontWeight: "400" }}>{row?.sub_type || "-"}</h6>
          </div>
        ),
        sortField: "sub_type",
        sortable: true,
      },
      {
        id: "document",
        name: <div className="d-flex px-2">Document {SortArrow(sortField, "document")}</div>,
        selector: (row) => (
          <div className="d-flex align-items-center gap-2">
            <div className={classNames("tableValues", { showFullDes: textWrap })}>
              {row.topic && row.topic !== "" ? (
                <h6 className="w-100" data-tooltip-id="my-tooltip" data-tooltip-content={row.topic} data-tooltip-place="bottom">{row.topic || "-"}</h6>
              ) : (
                <h6>-</h6>
              )}
              <p>{row.keyword || "-"}</p>
            </div>
          </div>
        ),
        sortField: "topic",
        sortable: true,
      },
      {
        id: "Status",
        name: <div className="d-flex px-2">Credit / Debit</div>,
        selector: (row) => (
          <div className="d-flex justify-content-center align-items-center">
            {row?.credit > 0 ? (
              <span className="credit-status"><BiPlus className="fs-5" /> {row?.credit}</span>
            ) : (
              <span className="debit-status"><BiMinus className="fs-5" /> {row?.debit}</span>
            )}
          </div>
        ),
        sortable: false,
      },
      {
        id: "AI_type",
        name: <div className="d-flex">Addlly Credit Balance</div>,
        selector: (row) => <div>{row?.balance}</div>,
        sortable: false,
      },
      {
        id: "created_at",
        name: <div className="d-flex">Date {SortArrow(sortField, "created_at")}</div>,
        selector: (row) => <div>{tableDateFormatter(row?.created_at)}</div>,
        sortable: true,
        sortField: "created_at",
      },
      {
        id: "button",
        name: "Refund Status",
        selector: (row) => {
          return (
            <div className="">
              {!row?.refund_status && row?.credit === 0 && row?.type !== "Addlly Credits" && row?.created_at && moment().diff(moment(row.created_at), 'h') <= 24 ? (
                <button className="btn btn-primary" type="button" onClick={() => {
                  setShow(true); setReqData({ id: row?.id, comment: "", data: row })
                }}>Refund</button>
              ) : row?.refund_status ? (
                <div className="d-flex justify-content-center align-items-center">
                  <div className="dotStatus">
                    <span className={`d-block`}
                      style={{
                        width: "8px", height: "8px", borderRadius: "50%",
                        backgroundColor: row.refund_status === "approved" ? "#84CC16" : row.refund_status === "refund" ? "#84CC16" :
                          row.refund_status === "rejected" ? "#EF4444" : "#F59E0B",
                      }}
                    />
                  </div>
                  {row?.refund_status === "approved" ? "Approved" : row.refund_status === "refund" ? "Refund" :
                    row.refund_status === "rejected" ? "Rejected" : "Pending"}
                </div>
              ) : '-'}
            </div>
          );
        },
      },
    ];

    if (userRes && userRes.role && userRes.role === "OrgAdmin") {
      tempColumns.splice(3, 0, {
        id: "created_by",
        name: <div className="d-flex">Created By {SortArrow(sortField, "created_by")}</div>,
        selector: (row) => (
          <div className="user-info-column">
            {row?.profileUrl && row?.profileUrl !== "" ? (
              <img src={row?.profileUrl} alt="user" className="userIcon" />
            ) : (
              <div className="avatar">{row?.username?.[0]}</div>
            )}
            <span>{row?.username || ""}</span>
          </div>
        ),
        sortable: true,
        sortField: "username",
      });
    }

    return tempColumns;
  }, [sortField, textWrap]);

  return (
    <div className="accountCard">
      <div className="cardHead flex-row flex-wrap align-items-center justify-content-between w-100">
        <h3>Transaction History</h3>
        <div className="side-bar-btn flex-wrap">
          <div className="credit-btn">
            <span>{userRes?.credits || "00"}</span> Credits Available
          </div>
          <button type="button" className="addlly-primary buy-btn" onClick={() => navigate("/buy-more-credits")}>
            <FaPlus className="" /> Buy More
          </button>
        </div>
      </div>
      <div className="card-note">
        <b>NOTE:</b> Refunds are only available for credits used within the past 24 hours.
        Please ensure your posts are less than a day old to be eligible for a refund request.
      </div>
      <div className="cardBody">
        <div className="filters">
          <div className="historyTopBar d-flex flex-wrap justify-content-between bg-white align-items-center gap-x-3 flex-wrap p-0">
            <div className="d-flex align-items-center flex-wrap gap-3">
              <div className="buttonTab overflow-hidden">
                <button className={`${"all" === radioValue ? "active" : ""}`} onClick={() => handleOnChangeTransactionList("all")}>
                  All
                </button>
                <button className={`border-start border-end ${"Debit" === radioValue ? "active" : ""}`} onClick={() => handleOnChangeTransactionList("Debit")}>
                  Debit
                </button>
                <button className={`${"Credit" === radioValue ? "active" : ""}`} onClick={() => handleOnChangeTransactionList("Credit")}>
                  Credit
                </button>
              </div>
              <span className="line d-none d-md-block"></span>
              <div className="filterRedu rounded overflow-hidden">
                <button
                  className={classNames({ active: !textWrap })} onClick={() => setTextWrap(false)}
                  data-tooltip-id="my-tooltip" data-tooltip-content="Unwrap Text" data-tooltip-place="bottom"
                >
                  <img src={articleHistoryIcons.filterIcon} alt="filterIcon" />
                </button>
                <button
                  className={classNames({ active: textWrap })} onClick={() => setTextWrap(true)}
                  data-tooltip-id="my-tooltip" data-tooltip-content="Wrap Text" data-tooltip-place="bottom"
                >
                  <img src={articleHistoryIcons.redoIcon} alt="redoIcon" />
                </button>
              </div>
            </div>
            <div className="searchField position-relative">
              <input type="search" placeholder="Type to search" onChange={(e) => setSearchTxt(e.target.value)} />
              <div className="searchIcon position-absolute">
                <BsSearch />
              </div>
            </div>
          </div>
        </div>
        <div className="historyTableBlock mt-2">
          <CustomDataTable
            progressPending={tableLoader} progressComponent={<TableLoader limit={10} />}
            columns={columns} totalCount={filteredData?.length} pagination={true} data={filteredData}
            itemsPerPage={10} selectableRows={false} noDataComponent={<NoDataFound search={searchTxt} />}
            onSelectedRowsChange={() => { }} setPage={setPage} page={page} onSort={handleSort}
            handleTableRowClick={() => { }} clearSelectedRows={() => { }}
          />
        </div>
      </div>

      <Modal centered show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Refund Credit Request</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="addllyFormWrap">
            <div className="form-group mb-4">
              <label className="mt-2 d-block">Comment</label>
              <Form.Control
                as="textarea" rows={3} name="comment" type="text" value={reqData?.comment}
                placeholder="Type the Comment" onChange={(e) => setReqData({ ...reqData, comment: e.target.value })}
                className={`addllyForm-control d-block w-100 h-auto`} style={{ borderRadius: "10px" }}
              />
            </div>
            <div className="mt-4 d-flex justify-content-between">
              <button className="addlly-primary w-auto" type="button" variant="secondary" onClick={() => handleClose()}>
                Close
              </button>
              <button className="addlly-primary w-auto" type="button" variant="primary" onClick={() => handleRequestForRefundCredit()}>
                Send Request
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default TransactionHistoryTab;

const NoDataFound = ({ search }) => {
  return (
    <div className="withoutData d-flex justify-content-center align-items-center">
      <div className="genrateNewBlock d-flex justify-content-center flex-column align-items-center">
        {!search ? (<><div className="icon d-flex justify-content-center flex-column align-items-center">
          <img src={articleHistoryIcons.add} alt="add"></img>
        </div>
          <p>Transaction history is not available yet!</p></>) : (<span>Oops... Search not found.</span>)}
      </div>
    </div>
  );
};
