import React from "react";
import classNames from "classnames";
import { ReactSVG } from "react-svg";
import { useSearchParams } from "react-router-dom";
import { BiChip, BiMoviePlay } from "react-icons/bi";
import { RiHistoryLine } from "react-icons/ri";
import { SlSocialLinkedin, SlSocialInstagram } from "react-icons/sl";
import {
  BsBlockquoteLeft, BsCardHeading, BsCheck2All,
  BsPatchQuestion, BsPersonCheck, BsTwitterX
} from "react-icons/bs";
import { oneClickBlogIcon } from "../../../Assets/Icons";
import "../../../Assets/scss/BlogSideBarStyle.scss";

export default function Sidebar(props) {
  const { setSelectedCurrentTab } = props
  const [searchParams, setSearchParams] = useSearchParams();
  const selectedTab = searchParams.get("tab") || "article";

  const menus = [
    { name: "Article", value: "article", subTab: "seoScore", icon: <BsCardHeading /> },
    { name: "Long Article", value: "longArticle", subTab: "seoScore", icon: <BsBlockquoteLeft /> },
    { name: "FAQs", value: "faqSchema", subTab: "", icon: <BsPatchQuestion /> },
    { name: "AI Overview", value: "aiOverview", subTab: "", icon: <BiChip /> },
    { name: "LinkedIn Post", value: "linkedIn", subTab: "postPreview", icon: <SlSocialLinkedin /> },
    { name: "Facebook Post", value: "facebook", subTab: "postPreview", icon: oneClickBlogIcon.facebookT },
    { name: "X (Twitter) Post", value: "twitter", subTab: "postPreview", icon: <BsTwitterX /> },
    { name: "Instagram Post", value: "instagram", subTab: "postPreview", icon: <SlSocialInstagram /> },
    { name: "Instagram Reels", value: "igReels", subTab: "postPreview", icon: <BiMoviePlay /> },
    { name: "GoogleAd Copy", value: "googleAdCopy", subTab: "postPreview", icon: oneClickBlogIcon.adCopy },
    { name: "Fact Checker", value: "factChecker", subTab: "", icon: <BsCheck2All /> },
    { name: "Review Article", value: "reviewArticle", subTab: "comments", icon: <BsPersonCheck /> },
    // { name: "Chart", value: "chart", subTab: "", icon: <BsBarChartLine /> },
    { name: "Refund Requests", value: "refundRequests", subTab: "", icon: <RiHistoryLine /> },
  ];

  const handleTabs = (tab) => {
    setSearchParams("tab=" + tab.value + (tab.subTab && "&subTab=" + tab.subTab));
    setSelectedCurrentTab(tab.value)
  };

  return (
    <div className="blogSideBar">
      <ul className="">
        {menus.map((item, index) => (
          <div key={index}>
            <li
              onClick={() => handleTabs(item)}
              className={classNames("d-flex align-items-center gap-3", { active: item.value === selectedTab })}
            >
              {typeof item.icon === 'string' ? <ReactSVG src={item.icon} /> : item.icon}
              <span className="d-block w-100">
                {item.name}
              </span>
            </li>
            {(index === 3 || index === 9) && <div className="divider"></div>}
          </div>
        ))}
      </ul>
    </div>
  );
}
