import moment from "moment";
import Swal from "sweetalert";
import { toast } from "react-hot-toast";
import { GoArrowDown, GoArrowUp } from "react-icons/go";
import { setLoader } from "../store/reducer";
import { invokeAxiosGet, invokeAxiosPost } from "./invokeAxiosFunction";

const sorting = (array = [], sortParam = "created_at", sortOrder = "asc") => {
  const newArray = [...array];
  return newArray?.sort((a, b) => {
    if (sortParam === "created_at" || sortParam === "updated_at") {
      if (sortOrder === "asc") {
        return new Date(a[sortParam]) - new Date(b[sortParam]);
      } else {
        return new Date(b[sortParam]) - new Date(a[sortParam]);
      }
    } else if (sortParam === "total_amount" || sortParam === "credits") {
      const columA = a[sortParam];
      const columB = b[sortParam];
      if (sortOrder === "asc") {
        if (columA < columB) { return -1; }
        if (columA > columB) { return 1; }
        return 0;
      } else {
        if (columA > columB) { return -1; }
        if (columA < columB) { return 1; }
        return 0;
      }
    } else {
      const columA = String(a[sortParam])?.trim()?.toLowerCase();
      const columB = String(b[sortParam])?.trim()?.toLowerCase();
      if (sortOrder === "asc") {
        if (columA < columB) {
          return -1;
        }
        if (columA > columB) {
          return 1;
        }
        return 0;
      } else {
        if (columA > columB) {
          return -1;
        }
        if (columA < columB) {
          return 1;
        }
        return 0;
      }
    }
  });
};

const SortArrow = (sortField, columnId) => {
  const arrow = sortField?.column?.id === columnId && sortField?.direction === "asc" ? (
    <GoArrowUp style={{ color: "#0039FF", marginLeft: "2px", fontSize: "1.1rem" }} />
  ) : (
    <GoArrowDown style={{ marginLeft: "2px", fontSize: "1.1rem" }} />
  );

  return <div>{arrow}</div>;
};

const tableDateFormatter = (date) => {
  if (!date) return "Invalid format";

  const startOfToday = moment().startOf("day");

  const day = moment(date).startOf("day");
  const event = moment(date);

  if (day.diff(startOfToday, "days") === 0) {
    return "Today " + event.format("HH:mm:ss");
  } else if (day.diff(startOfToday, "days") === -1) {
    return "Yesterday " + event.format("HH:mm:ss");
  } else {
    return event.format("DD/MM/YYYY HH:mm:ss");
  }
};

const tableArticleStatusHandle = (row, handleTableRowClick) => {
  let backgroundColor = "", status = "";
  if (row.status === "completed") {
    backgroundColor = "#84CC16";
    status = "Done";
  } else if (row.status === "pending" && row.type !== "short_flow") {
    backgroundColor = "#F59E0B";
    status = "In Progress";
  } else if (row.status === "pending") {
    backgroundColor = "#E7E721";
    status = "Processing ...";
  } else if (row.status === "processing") {
    backgroundColor = "#F59E0B";
    status = "In Progress";
  } else if (row.status === "notAcceptable") {
    backgroundColor = "#EF4444";
    status = "Not Acceptable";
  } else {
    backgroundColor = "#EF4444";
    status = "Error";
  }

  return (
    <div className="d-flex justify-content-center align-items-center" onClick={() => handleTableRowClick(row)}>
      <div className="dotStatus">
        <span className={`d-block`} style={{ width: "8px", height: "8px", borderRadius: "50%", backgroundColor: backgroundColor }} />
      </div> {status}
    </div>
  )
};

const handleSetLanguageAndGeoLocation = (
  event, setValue = () => { }, setGeoLocation = () => { }, handleTranslateTextEngToBahasa = () => { }, setBahasaText = () => { }
) => {
  if (event.value === "Bahasa Indonesia" || event.value === "Bahasa Melayu") {
    setValue("geoLocation", "ID");
    setGeoLocation("ID");
    handleTranslateTextEngToBahasa("Bahasa Indonesia");
  } else if (event.value === "Bahasa Melayu") {
    setValue("geoLocation", "MY");
    setGeoLocation("MY");
  } else if (event.value === "Mandarin") {
    setValue("geoLocation", "CN");
    setGeoLocation("CN");
  } else if (event.value === "Thai") {
    setValue("geoLocation", "TH");
    setGeoLocation("TH");
  } else if (event.value === "Tagalog") {
    setValue("geoLocation", "PH");
    setGeoLocation("PH");
  } else if (event.value === "Vietnamese") {
    setValue("geoLocation", "VN");
    setGeoLocation("VN");
  } else {
    setBahasaText("");
  }
};

const handleShareSocialMediaTypePost = async ({ postType, articleId, text, keyword, image, userId, dispatch }) => {
  let uploadImage = "";
  if (image && image !== "") {
    uploadImage = image;
  }

  if (postType === "LinkedIn" && text && text.length > 3000) {
    toast.error("Linkedin post must not be greater than 3000 character.");
    return false;
  }

  try {
    dispatch(setLoader(true));
    const payload = {
      text, keyword, postType, userId,
      postId: articleId ? articleId : "",
      image: uploadImage, saveImage: false,
    };

    const response = await invokeAxiosPost("/social-media-post/get-or-generate/socialMediaPostShare", payload);
    dispatch(setLoader(false));
    if (response && response.success === 1) {
      toast.success("Post share successfully.", { id: "Toast-01" });
    } else {
      toast.error(response?.error || "Something went wrong.", { id: "Toast-01" });
    }
  } catch (error) {
    dispatch(setLoader(false));
    toast.error(error?.response?.data?.error?.message || error?.response?.data?.error || "Access token is not valid.", { id: "Toast-01" });
  }
};

const handleExportWordToGoogleDoc = async ({ editorRef, finalHtmlText, articleId, userRes, dispatch }) => {
  try {
    dispatch(setLoader(true)); let isGoogleAccountLinked = false;
    const getList = await invokeAxiosGet(`/social-account-connect/get-or-save/saveSocialAccountInformation?userId=${userRes.id}`);
    if (getList.data && getList.data.length > 0) {
      const findGoogleAcc = getList.data.findIndex((ele) => ele.type === "Google");
      if (findGoogleAcc !== -1) {
        isGoogleAccountLinked = true;
      }
    }

    if (!isGoogleAccountLinked) {
      dispatch(setLoader(false));
      Swal({
        title: "Google Account Not Linked",
        text: `Please connect your google account then export google docs.`,
        icon: "warning", dangerMode: true,
        buttons: { cancel: "Cancel", confirm: `Yes, Connect it!` },
      }).then((isConfirmed) => {
        if (isConfirmed) {
          window.open("/account/connections", "_self");
        }
      });
      return false;
    }

    if (!articleId || !editorRef.current) {
      dispatch(setLoader(false));
      return false;
    }

    let articleTempText = `<body> ${editorRef.current.getContent()} </body>`;
    if (finalHtmlText && finalHtmlText !== "" && /<html[^>]*>/.test(finalHtmlText)) {
      const parser = new DOMParser();
      const bodyText = parser.parseFromString(articleTempText, "text/html").body.outerHTML;
      articleTempText = `${finalHtmlText.substring(0, finalHtmlText.indexOf("<body"))} ${bodyText} </html>`
        .replaceAll("\n", " ").replaceAll("\t", " ").replace(/\s+/g, " ").trim();
    }

    const defaultStyle = `h1 { display: block; font-size: 2em; margin-block-start: 0.67em; margin-block-end: 0.67em; margin-inline-start: 0px; margin-inline-end: 0px; font-weight: bold; }
    h2 { display: block; font-size: 1.5em; margin-block-start: 0.83em; margin-block-end: 0.83em; margin-inline-start: 0px; margin-inline-end: 0px; font-weight: bold; }
    h3 { display: block; font-size: 1.17em; margin-block-start: 1em; margin-block-end: 1em; margin-inline-start: 0px; margin-inline-end: 0px; font-weight: bold; }
    ul { display: block; list-style-type: disc; margin-block-start: 1em; margin-block-end: 1em; margin-inline-start: 0px; margin-inline-end: 0px; padding-inline-start: 40px; }
    p { display: block; margin-block-start: 1em; margin-block-end: 1em; margin-inline-start: 0px; margin-inline-end: 0px; }
    img { height: 100%; object-fit: cover; }`.replace(/\s+/g, " ").trim();
    if (articleTempText.indexOf("<style>") === -1) {
      articleTempText = articleTempText.replace("</head>", `<style> ${defaultStyle} </style> </head>`);
    } else {
      articleTempText = articleTempText.replace("<style>", `<style> ${defaultStyle}`);
    }

    const parser = new DOMParser();
    const doc = parser.parseFromString(articleTempText, "text/html");
    const imgTags = doc.querySelectorAll("img");

    for (const img of imgTags) {
      const imageUrl = img.getAttribute("src");
      if (imageUrl.startsWith("data:")) { continue; }
      try {
        const response = await invokeAxiosGet(`/social-media-post/get-or-generate/getSocialMediaPostBase64Image`, { params: { url: imageUrl } });
        if (response && response.data && response.data.data) {
          img.setAttribute("src", "data:image/png;base64," + response.data.data.split(",")[1]);
        }
      } catch (error) {
      }
    }

    const finalHtml = doc.documentElement.outerHTML;

    const payload = {
      text: finalHtml, keyword: "", postType: "Google", userId: userRes.id,
      postId: articleId ? articleId : "", image: "", saveImage: false,
    };

    const response = await invokeAxiosPost("/social-media-post/get-or-generate/socialMediaPostShare", payload);
    dispatch(setLoader(false));
    if (response && response.success === 1) {
      toast.success("Docs file uploaded to google docs.", { id: "Toast-01" });
      if (response.data && response.data.data) {
        setTimeout(() => {
          window.open(`https://docs.google.com/document/d/${response.data.data}/edit`, "_blank");
        }, 1000);
      }
    } else {
      if (response && response.error && response.error.indexOf("Authentication token") !== -1) {
        Swal({
          title: "Token Expired",
          text: `Authentication token has been expired, Please reconnect google app.`,
          icon: "error", dangerMode: true,
          buttons: { cancel: "Cancel", confirm: `Yes, ReConnect it!` },
        }).then((isConfirmed) => {
          if (isConfirmed) {
            window.open("/account/connections", "_self");
          }
        });
      } else {
        toast.error(response?.error || "Something went wrong.", { id: "Toast-01" });
      }
    }
  } catch (error) {
    dispatch(setLoader(false));
    toast.error(error?.response?.data?.error?.message || error?.response?.data?.error || "Access token is not valid.", { id: "Toast-01" });
  }
};

export {
  sorting, SortArrow, tableDateFormatter, tableArticleStatusHandle,
  handleSetLanguageAndGeoLocation, handleShareSocialMediaTypePost, handleExportWordToGoogleDoc
};
