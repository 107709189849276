import React, { useEffect, useState } from "react";
import { Link, useLocation, useSearchParams } from "react-router-dom";
import classNames from "classnames";
import { useSelector } from "react-redux";
import { ReactSVG } from "react-svg";
import { useDispatch } from "react-redux";
import { IoIosArrowForward } from "react-icons/io";
import ProfileCard from "./ProfileCard";
import CreditsCard from "./CreditsCard";
import { menuItems } from "../../data";
import Accordion from "../ui/Accordion";
import { headerIcons } from "../../Assets/Icons";
import { getHistory } from "../../utility/apiService";
import {
  resetSEOFLow, setActiveMenu, setBrandProductImageInfo, setBrandProductImageStep, setECommerceBlogInfo, setECommerceBlogStep,
  setGeneratedFullArticle, setHistoryData, setHistoryDataLength, setNewsletterWriterHistory, setNewsletterWriterInfo,
  setNewsletterWriterStep, setPressReleaseWriterHistory, setPressReleaseWriterInfo, setPressReleaseWriterStep, setSeoOptBlogStep,
  setSocialMediaPostHistory, setSocialMediaPostStep, setSocialMediaStepsInfo, setTatlerSeoBlogInfo, setTatlerSeoBlogStep, setTopResultsStep,
  setAzgoNewsletterInfo, setAzgoNewsletterStep
} from "../../store/reducer";

const SideBarMenu = ({ toggleLogo, setToggleLogo, isMobileScreen, setIsMobileScreen }) => {
  const dispatch = useDispatch();
  const userRes = useSelector(({ userRes }) => userRes);
  const topResultsStep = useSelector(({ topResultsStep }) => topResultsStep);
  const seoOptBlogStep = useSelector(({ seoOptBlogStep }) => seoOptBlogStep);
  const socialMediaPostInfo = useSelector(({ socialMediaPostInfo }) => socialMediaPostInfo);
  const socialMediaPostStep = useSelector(({ socialMediaPostStep }) => socialMediaPostStep);

  const { pathname } = useLocation();
  const [URLSearchParams] = useSearchParams();
  const isFullScreen = URLSearchParams.get("full-screen");
  const [isHoverClass, setIsHoverClass] = useState(false);

  useEffect(() => {
    const timeOut = setTimeout(() => {
      if (pathname.includes("/1-click-blog") && (topResultsStep - 1) === 2) {
        setToggleLogo(false); setIsHoverClass(true);
      } else {
        const checkPath = [
          "/pdf-to-article/", "/blog-co-pilot/", "/social-media-post-writer/",
          "/newsletters/", "/press-release/", "/e-commerce-edm/", "/edm-template/", "/e-commerce-blog/",
          "/oona-blog-co-pilot/"
        ];
        const index = checkPath.findIndex((ele) => pathname.indexOf(ele) !== -1);
        if (index !== -1) {
          setToggleLogo(false); setIsHoverClass(true);
        }
      }
    });
    dispatch(setSocialMediaStepsInfo({}))

    return () => {
      clearTimeout(timeOut)
    }
  }, [pathname, setToggleLogo, topResultsStep, dispatch]);

  const handleOnChangeSocialMediaCardType = (type, defaultCard) => {
    dispatch(setActiveMenu(4));
    dispatch(setSocialMediaStepsInfo({ PostType: type, selectedCard: defaultCard, isDefaultSet: true, stepDone: 1 }));
    dispatch(setSocialMediaPostHistory({}));
    dispatch(setSocialMediaPostStep(2));
  };

  const handleNavigateAndRemoveData = async (item, name) => {
    if ((item.id === 1 || item.id === 2 || item.id === 14) && topResultsStep === 2) {
      dispatch(setTopResultsStep(1));
      dispatch(setGeneratedFullArticle({}));
    }

    if (item.id === 2) {
      dispatch(getHistory(userRes?.id));
    }

    if (item.id === 3 && seoOptBlogStep !== 1) {
      dispatch(setSeoOptBlogStep(0));
      dispatch(resetSEOFLow());
      dispatch(setHistoryData([]));
      dispatch(setHistoryDataLength(0));
      dispatch(setSeoOptBlogStep(1));
    }

    if (item.id === 3.1) {
      dispatch(setTatlerSeoBlogStep(0));
      dispatch(setTatlerSeoBlogInfo({}));
      dispatch(setTatlerSeoBlogStep(1));
    }

    if (item.id === 4 && socialMediaPostStep !== 1) {
      dispatch(setSocialMediaPostStep(0));
      dispatch(setSocialMediaStepsInfo({}));
      dispatch(setSocialMediaPostHistory({}));
      dispatch(setSocialMediaPostStep(1));
    }

    if (item.id === 10) {
      dispatch(setBrandProductImageInfo({}));
      dispatch(setBrandProductImageStep(1));
    }

    if (item.id === 11) {
      dispatch(setNewsletterWriterStep(0));
      dispatch(setNewsletterWriterInfo({}));
      dispatch(setNewsletterWriterHistory({}));
      dispatch(setNewsletterWriterStep(1));
    }

    if (item.id === 12 || name === "E-commerce little farms") {
      dispatch(setPressReleaseWriterStep(0));
      dispatch(setPressReleaseWriterInfo({}));
      dispatch(setPressReleaseWriterHistory({}));
      dispatch(setPressReleaseWriterStep(1));
    }

    if (item.id === 15) {
      dispatch(setECommerceBlogStep(0));
      dispatch(setECommerceBlogInfo({}));
      dispatch(setECommerceBlogStep(1));
    }

    if (item.id === 22) {
      dispatch(setAzgoNewsletterStep(1));
      dispatch(setAzgoNewsletterInfo({}));
    }

    dispatch(setActiveMenu(item.id)); setToggleLogo(!toggleLogo ? true : toggleLogo);
    const scrollWrapper = document.getElementById('scroll-wrapper');
    if (scrollWrapper) { scrollWrapper.scrollTo({ top: 0, behavior: 'smooth' }); }

    if (isMobileScreen) {
      handleToggleLogoValSet(!toggleLogo);
    }
  };

  const handleToggleLogoValSet = (val) => {
    setIsMobileScreen(false);
    if (!isMobileScreen) {
      setToggleLogo(val);
      setIsHoverClass(false);
    }
    if (!val) {
      setTimeout(() => setIsHoverClass(true), 500);
    }
  };

  return (
    <div className={classNames("sidebar-block justify-content-between flex-column ", { "d-none": isFullScreen, "sidebar-block-hover": isHoverClass, "d-block": isMobileScreen })}>
      <div className="nav-menu">
        <div className="addllyLogo">
          <div className="logoInner">
            <Link to={"/dashboard"}>
              <img src={headerIcons.addllyLogo} alt="addlly logo" />
            </Link>
            <IoIosArrowForward className="toggle-btn" onClick={() => handleToggleLogoValSet(!toggleLogo)} />
          </div>
        </div>
        <ul className="list-group">
          {menuItems.map((item, index) => (item.name === "Review Articles" && userRes.role === "OrgUser" ? null : (
            <li className={classNames('items', { active: item.children.some((child) => pathname.includes(child.to)) })} key={index}>
              {item.children && item.children.length ? (
                <Accordion
                  buttonChildren={
                    <Link to={item.to} className={classNames({ active: item.children.some((child) => pathname.includes(child.to) || pathname.includes(item.to)) })}>
                      {typeof item.icon === 'string' ? <ReactSVG src={item.icon} useRequestCache /> : item.icon}
                      <div className={`d-flex align-items-center justify-content-center ${item.name === "E-Commerce" ? "gap-0" : "gap-3"} menuText`}>
                        {item.name}{" "}
                        {item.isNew ? (
                          <div className="text-black-b30 leading-trim text-cap font-inter fs-6 fw-normal lh-1 rounded-pill isNew ">
                            <span className="d-flex align-items-center justify-content-center">New</span>
                          </div>
                        ) : null}
                      </div>
                    </Link>
                  }
                >
                  <div className="subMenus">
                    {item?.children.map(({ name, to, ...other }, index) => (
                      <Link to={to} key={name + " " + index}
                        onClick={() => {
                          if (item.name === "Social Media Posts") { handleOnChangeSocialMediaCardType(other?.value, other?.defaultCard); }
                          else { handleNavigateAndRemoveData(item, name); }
                        }}
                      >
                        <div className="d-flex align-items-center justify-content-center gap-3 bg-transparent position-relative ">
                          {item.name === 'Social Media Posts' ? other?.value === socialMediaPostInfo?.PostType ? <span className="dot"></span>
                            : null : pathname.includes(to) ? <span className="dot"></span> : null}
                          <span style={{ lineHeight: "20px" }} className="py-2">{name}</span> {" "}
                        </div>
                      </Link>
                    ))}
                  </div>
                </Accordion>
              ) : (
                <Link to={item.to} onClick={() => handleNavigateAndRemoveData(item)} className={classNames({ active: pathname.includes(item.to) || item.children.some((child) => pathname.includes(child.to)) })}>
                  {typeof item.icon === 'string' ? <ReactSVG src={item.icon} useRequestCache /> : item.icon}
                  <div className="d-flex align-items-center justify-content-center gap-3 menusB">
                    {item.name} {" "}
                    {item.isNew ? (
                      <div className="text-black-b30 leading-trim text-cap font-inter fs-6 fw-normal lh-1 rounded-pill isNew ">
                        <span className="d-flex align-items-center justify-content-center">New</span>
                      </div>
                    ) : null}
                  </div>
                </Link>
              )}
            </li>
          )))}
        </ul>
      </div>
      <div className="upgradeCredits">
        <div className="card border-1" style={{ background: "var(--hoverTabColor)" }}>
          <CreditsCard userRes={userRes} />
        </div>
        <div className="user-profile-card">
          <ProfileCard userRes={userRes} toggleLogo={toggleLogo} setToggleLogo={handleToggleLogoValSet} />
        </div>
      </div>
    </div>
  );
};

export default SideBarMenu;
