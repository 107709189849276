import React, { useEffect, useMemo, useRef, useState } from "react";
import ReactFlagsSelect from "react-flags-select";
import * as Yup from "yup";
import { toast } from "react-hot-toast";
import { Controller, useForm } from "react-hook-form";
import { Col, Form, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from "react-redux";
import Select, { components } from "react-select";
import { BsArrowRight, BsCloudUploadFill, BsStars } from "react-icons/bs";

import {
  setLoader, setSeoOptBlogTopic, setSeoGenerateTopic, setSeoOptBlogStep,
  setKeywordTopic, setSelectedTopic, setSelectedGoogleWebsite, setUserRes, resetSEOFLow
} from "../../store/reducer";
import { invokeAxiosGet, invokeAxiosPost } from "../../utility/invokeAxiosFunction";
import { BsArrowLeft, BsInfoCircleFill } from "react-icons/bs";
import { HiOutlineChevronRight } from "react-icons/hi";
import { uploadPdfFileOnS3 } from "../../utility/uploadPdfFileOnS3";
import { AiOutlineFilePdf } from "react-icons/ai";
import { handleCustomSelectOption, removeSpecificSpecialCharacters, supportedLanguageList, trimedInputValue } from "../../utility/hepler";
import { BiSolidErrorCircle } from 'react-icons/bi';
import { IoIosArrowDown } from 'react-icons/io';
import { FileUploader } from 'react-drag-drop-files';
import { AiTypeOptions } from '../../data';
import { handleSetLanguageAndGeoLocation } from "../../utility/common";

const regexExpression = /^[A-Za-z0-9 \s._&-?,@!:]*[A-Za-z0-9][A-Za-z0-9 \s._&-?,@!:]*$/
const validationSchema = Yup.object().shape({
  topic: Yup.string().required("Topic is required").min(2, "Topic must be at least 2 characters")
    .test('no-white-space', 'Topic should not contain white spaces', (value) => !/\s{2,}/.test(value))
    .matches(/^(?!\s)(?!.*\s{2})[^\s](.*[^\s])?$/, "Topic should not contain multiple consecutive white spaces")
    .test('alpha numeric characters', 'The title should only contain alpha numeric characters and & . _ - ? , @ ! :', (value) => regexExpression.test(value)),
  toneOfVoice: Yup.string().required("Tone of voice is required"),
});

export default function SecondStepSeoOptBlogType({ selectedCard }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id: articleId } = useParams();

  const seoGenerateTopic = useSelector(({ seoGenerateTopic }) => seoGenerateTopic);
  const seoOptBlogTopic = useSelector(({ seoOptBlogTopic }) => seoOptBlogTopic);
  const selectedGoogleWebsite = useSelector(({ selectedGoogleWebsite }) => selectedGoogleWebsite);
  const userRes = useSelector(({ userRes }) => userRes);

  const [geoLocation, setGeoLocation] = useState("SG");
  const [currentLan, setCurrentLan] = useState("English");
  const [toneOfVoiceOptions, setToneOfVoiceOptions] = useState([]);

  const [pdfBase64, setPdfBase64] = useState('');
  const [generateLink, setGenerateLink] = useState(true);
  const [isDisabledInput, setIsDisabledInput] = useState(articleId ? true : false);
  const [bahasaText, setBahasaText] = useState("");
  const fileInputRef = useRef(null);
  const [uploadFileDragging, setUploadFileDragging] = useState(null);
  const [uploadFileError, setUploadFileError] = useState(null);
  const [file, setFile] = useState(null);

  const { register, handleSubmit, formState: { errors }, setValue, getValues, values, control, watch } = useForm({
    defaultValues: {
      topic: (seoOptBlogTopic?.topic_desc || seoGenerateTopic),
      aiType: (seoOptBlogTopic?.aiType || AiTypeOptions[0].value),
      toneOfVoice: (seoOptBlogTopic?.toneOfVoice || ""),
    },
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    setIsDisabledInput(articleId ? true : false);
  }, [articleId])

  useEffect(() => {
    dispatch(setSeoOptBlogTopic({ ...seoOptBlogTopic, selectedCard: seoOptBlogTopic?.selectedCard || seoOptBlogTopic?.blog_type }));
    setGenerateLink(seoOptBlogTopic?.generateLink ? true : false);

    if (seoOptBlogTopic?.geoLocation) {
      setGeoLocation(seoOptBlogTopic?.geoLocation?.toUpperCase());
    }

    if (seoOptBlogTopic?.lan) {
      setCurrentLan(seoOptBlogTopic?.lan);
      if (seoOptBlogTopic?.lan && seoOptBlogTopic?.lan === "Bahasa Indonesia") {
        handleTranslateTextEngToBahasa(seoOptBlogTopic?.lan);
      }
    }
  }, [seoOptBlogTopic?.selectedCard, seoOptBlogTopic?.blog_type])

  useEffect(() => {
    if (toneOfVoiceOptions && toneOfVoiceOptions.length === 0) {
      handleGetToneOfVoiceOptionsList();
    }
  }, []);

  const handleGetToneOfVoiceOptionsList = async () => {
    try {
      setToneOfVoiceOptions([]);
      dispatch(setLoader(true));
      const response = await invokeAxiosGet(`/tone-of-voice/get-or-generate/getToneOfVoiceOptionsByType?type=Blog Co-Pilot`);
      if (response && response.data && response.data.length > 0) {
        setToneOfVoiceOptions(response.data);
        dispatch(setLoader(false));
      } else {
        dispatch(setLoader(false));
      }
    } catch (error) {
      setToneOfVoiceOptions([]);
    }
  }

  const handleOnChange = (value) => {
    setGeoLocation(value);
  }

  const onContinue = async (formValue) => {
    const formData = trimedInputValue(formValue)
    try {
      if (articleId) {
        if (!selectedGoogleWebsite || selectedGoogleWebsite?.length === 0) {
          dispatch(setSelectedGoogleWebsite([]));
        }

        if (!generateLink) {
          dispatch(setSeoOptBlogStep(4));
          dispatch(setSeoOptBlogTopic({
            ...seoOptBlogTopic, generateLink: generateLink ? 1 : 0,
            stepDone: seoOptBlogTopic.stepDone < 4 ? 4 : seoOptBlogTopic.stepDone,
          }));
        } else {
          dispatch(setSeoOptBlogStep(3));
          dispatch(setSeoOptBlogTopic({
            ...seoOptBlogTopic, generateLink: generateLink ? 1 : 0,
            stepDone: seoOptBlogTopic.stepDone < 3 ? 3 : seoOptBlogTopic.stepDone,
          }));
        }
      } else {
        if (!formData?.topic || formData?.topic === "") {
          return false;
        }

        if (Number(userRes.credits) < (userRes?.deductCredits?.["Blog Co-Pilot"] || 3)) {
          toast.error("Sorry, you don't have enough credits to perform this action.", { id: "Toast-01" });
          return false;
        }

        if (selectedCard === "Pdf" && !pdfBase64) {
          toast.error("Please upload PDF file.", { id: "Toast-01" });
          return false;
        }

        dispatch(setLoader(true));
        const topicStr = await removeSpecificSpecialCharacters(formData?.topic);

        const payload = {
          topic: topicStr || "",
          user_id: userRes.id,
          geoLocation: geoLocation?.toLowerCase(),
          blog_type: selectedCard,
          pdfBase64: (selectedCard === "Pdf" && pdfBase64) ? pdfBase64 : "",
          aiType: (formData?.aiType) ? formData.aiType : "GPT 3.5",
          toneOfVoice: (formData?.toneOfVoice) ? formData.toneOfVoice : "",
          isGenerateLinks: generateLink,
          lan: currentLan,
        };
        const response = await invokeAxiosPost("/seo-blog-co-pilot/get-or-generate/generateTopics", payload);
        dispatch(setLoader(false));
        if (response && response.success === 1) {
          dispatch(setUserRes({ ...userRes, credits: userRes.credits - (userRes?.deductCredits?.["Blog Co-Pilot"] || 3) })); // 3 credit points for long flow
          const scrollWrapper = document.getElementById('scroll-wrapper');
          if (scrollWrapper) { scrollWrapper.scrollTo({ top: 0, behavior: 'smooth' }); }
          navigate(`/blog-co-pilot/${response?.data?.articleId}`);

          if (!generateLink) {
            generateKeywordsAndMoveNextStep(response.data, formData);
            // generateSummaryAndMetaFaqHtml(response.data, formData);
          } else {
            dispatch(setSeoGenerateTopic(response.data?.topic));
            dispatch(setSelectedGoogleWebsite([]));
            dispatch(setSeoOptBlogStep(3));
            dispatch(setSeoOptBlogTopic({
              ...response.data, stepDone: 3, blog_type: selectedCard, topic_desc: formData?.topic, lan: currentLan,
              pdf_link: (selectedCard === "Pdf" && pdfBase64) ? pdfBase64 : "", generateLink: generateLink ? 1 : 0,
              aiType: (formData?.aiType) ? formData.aiType : "GPT 3.5",
              toneOfVoice: (formData?.toneOfVoice) ? formData.toneOfVoice : "",
            }));
          }
        } else {
          toast.error(response?.error, { id: "Toast-01" });
        }
      }
    } catch (error) {
      dispatch(setLoader(false));
      toast.error("Something went wrong, try again after sometime.", { id: "Toast-01" });
    }
  };

  const generateKeywordsAndMoveNextStep = async (data, formData) => {
    dispatch(setLoader(true));
    const payload = {
      queryStringParameters: {
        articleId: data.articleId,
        topic: data?.keyword ? data.keyword : data.topic,
        geoLocation: data.geoLocation,
      }
    };
    const response = await invokeAxiosPost("/seo-blog-co-pilot/get-or-generate/getKeywords", payload);
    dispatch(setLoader(false));
    if (response && response.success === 1) {
      dispatch(setKeywordTopic(response.data));
      dispatch(setSelectedTopic(data.topic));
      dispatch(setSelectedGoogleWebsite([]));
      dispatch(setSeoGenerateTopic(data?.topic));
      dispatch(setSeoOptBlogTopic({
        ...seoOptBlogTopic, ...data, generateLink: generateLink ? 1 : 0,
        blog_type: selectedCard, topic_desc: formData?.topic,
        pdf_link: (selectedCard === "Pdf" && pdfBase64) ? pdfBase64 : "",
        stepDone: seoOptBlogTopic.stepDone < 4 ? 4 : seoOptBlogTopic.stepDone,
      }));
      dispatch(setSeoOptBlogStep(4));
    } else {
      toast.error(response?.error || "Server is overloaded, try again after sometime.", { id: "Toast-01" });
    };
  }

  const generateSummaryAndMetaFaqHtml = async (data, formData) => {
    const payload = {
      topic: data?.topic ? data.topic : formData.topic ? formData?.topic : "",
      keyword: data?.keyword ? data.keyword : "",
      selectedGoogleWebsite: [],
      articleId: data.articleId,
    };
    invokeAxiosPost("/seo-blog-co-pilot/get-or-generate/generateSummaryAndMetaFaqHTML", payload);
  }

  const handleOnSelectPdfFile = async (files) => {
    const file = files[0];
    setFile(files);
    const MAX_FILE_SIZE = 5120; // 5MB
    const fileSizeKiloBytes = file.size / 1024;
    if (fileSizeKiloBytes > MAX_FILE_SIZE) {
      toast.error("File size exceeds limit. Please upload a file no larger than 5 MB.", { id: "Toast-01" });
      if (fileInputRef.current) { fileInputRef.current.value = null; }
      setValue("topic", "");
      return false;
    }

    const extension = file.name.split('.').pop().toLowerCase();
    if (extension !== 'pdf') {
      toast.error("Uploaded file is not a PDF, Please upload a valid PDF file.", { id: "Toast-01" });
      if (fileInputRef.current) { fileInputRef.current.value = null; }
      setValue("topic", "");
      return false;
    }

    if (file.type !== 'application/pdf') {
      toast.error("Uploaded file is not a PDF, Please upload a valid PDF file.", { id: "Toast-01" });
      if (fileInputRef.current) { fileInputRef.current.value = null; }
      setValue("topic", "");
      return false;
    }

    setValue("topic", "Pdf");
    dispatch(setLoader(true));
    setUploadFileError(null);
    try {
      const filePath = await uploadPdfFileOnS3(file);
      dispatch(setLoader(false));
      setPdfBase64(filePath);
    } catch (error) {
      toast.error("File not uploaded successfully, please try again.", { id: "Toast-01" });
      fileInputRef.current.value = '';
    }
  }

  const handleTranslateTextEngToBahasa = async (language) => {
    if (getValues("topic") && getValues("topic") !== "" && language && language === "Bahasa Indonesia") {
      setBahasaText(bahasaText ? `${bahasaText} ...` : "Translating ...");
      invokeAxiosPost(`/seo-blog-co-pilot/get-or-generate/generateHeadline`, {
        textTranslate: getValues("topic"),
      }).then((res) => {
        if (res.data && res.data.text && res.data.text !== "") {
          setBahasaText(res.data.text);
        } else {
          setBahasaText("");
        }
      }).catch((error) => {
        setBahasaText("");
      });
    } else {
      setBahasaText("");
    }
  };

  const CaretDownIcon = () => {
    return <IoIosArrowDown className='downArrowIcon' />;
  };

  const DropdownIndicator = props => {
    return (
      <components.DropdownIndicator {...props}>
        <CaretDownIcon />
      </components.DropdownIndicator>
    );
  };

  const FileUploaderCustomUi = useMemo(() => (
    <div className={` position-relative ${uploadFileDragging ? "active" : ""}`}>
      <BsCloudUploadFill className="uploadIcon" />
      <h4 className="fw-normal form-label file-upload-header">
        <strong>Choose a file</strong> or drag it here
      </h4>
      <p className="m-0 file-upload-pera">
        Supported formats: pdf <br /> maximum file size: 5MB.
      </p>
      {uploadFileError ? (
        <React.Fragment>
          <h5 className="fw-normal text-danger d-flex align-items-center">
            <BiSolidErrorCircle className="fs-4 me-2" /> {uploadFileError}
          </h5>
        </React.Fragment>
      ) : file ? (
        <h5 className="fw-normal text-primary text-center">
          {file && file[0].name ? file[0].name : ""}
        </h5>
      ) : null}
    </div>
  ), [file, uploadFileDragging, uploadFileError]);

  return (
    <React.Fragment>
      <div className='selectBlock mx-2'>
        <b>Generate a Blog using {selectedCard}</b>
      </div>
      <div className="genrateFields pb-0">
        {selectedCard && (
          <form onSubmit={handleSubmit(onContinue)} className="">
            <Row className="mt-3">
              {selectedCard === "Summary" ? (
                <Col sm="12" className='mb-3 summeryBlock'>
                  <div className="form-group fields">
                    <label>Describe your points <span className="astrick">*</span></label>
                    <div className="input-with-mic position-relative">
                      <textarea
                        name="topic" type="text" {...register("topic")} rows="3"
                        placeholder="Example: 1. Best way to get the most out of ..."
                        className={`w-100 addllyForm-control ${errors.topic ? "is-invalid" : ""}`}
                        style={{ borderRadius: "5px" }} disabled={isDisabledInput}
                      />
                      <div className="invalid">{errors.topic?.message}</div>
                    </div>
                  </div>
                </Col>
              ) : selectedCard === "Pdf" ? (
                <Col sm="12" md="12">
                  <div className="mb-3">
                    <div className="fileUploadBlock">
                      <FileUploader
                        name="file" types={["pdf"]} maxSize={5} children={FileUploaderCustomUi}
                        multiple={true} classes={`w-100`} handleChange={handleOnSelectPdfFile} isDisabled={isDisabledInput}
                        onDraggingStateChange={(dragging) => setUploadFileDragging(dragging)} dropMessageStyle={{ display: "none" }}
                        onSizeError={() => { setUploadFileError("File size exceeds the allowable limit. Please upload a smaller file."); setPdfBase64('') }}
                        onTypeError={() => { setUploadFileError("Invalid file type. Please upload a file with a supported file type."); setPdfBase64(''); }}
                      />
                    </div>
                    {seoOptBlogTopic && seoOptBlogTopic.pdf_link && seoOptBlogTopic.pdf_link !== "" && (
                      <div className="d-flex align-items-center mt-2">
                        <AiOutlineFilePdf className="fs-5 me-3" />
                        <a href={seoOptBlogTopic.pdf_link} target="_blank" rel="noreferrer" className="text-decoration-none">
                          {seoOptBlogTopic.pdf_link.split("/")[seoOptBlogTopic.pdf_link.split("/").length - 1]}
                        </a>
                      </div>
                    )}
                  </div>
                  <div className="pdf-select-checkbox d-flex gap-1 align-items-center">
                    <input
                      inline name={"post"} id={"post"} type="checkbox" disabled={isDisabledInput}
                      checked={generateLink} onChange={(e) => {
                        setGenerateLink((prev) => !prev);
                        dispatch(setSeoOptBlogTopic({ ...seoOptBlogTopic, generateLink: e.target.checked ? 1 : 0 }));
                      }}
                    />
                    <label className="cursor-pointer ms-2" htmlFor="post">Take into consideration other related news and websites</label>
                  </div>
                </Col>
              ) : (
                <React.Fragment>
                  <Col sm="12" md="12" className='mb-3'>
                    <div className="form-group fields">
                      <label>Tell me your topic, and let's create an engaging article together. <span className="astrick">*</span></label>
                      <div className="inputField">
                        <input
                          name="topic" type="text" {...register("topic")} disabled={isDisabledInput}
                          className={`addllyForm-control w-100 ${errors.topic ? "is-invalid" : ""}`}
                          placeholder="Enter your topic" onBlur={() => handleTranslateTextEngToBahasa(currentLan)}
                        />
                        {/* <div className="glitterStar cursor-pointer" data-tooltip-id="my-tooltip" data-tooltip-content="Get Suggestions" data-tooltip-place="bottom"></div> */}
                        <div className="invalid">{errors.topic?.message}</div>
                      </div>
                    </div>
                  </Col>

                  {currentLan && currentLan === "Bahasa Indonesia" && bahasaText && bahasaText !== "" && (
                    <Col sm="12" md="12" className='mb-4'>
                      <div className="form-group fields">
                        <label>Translated Topic To Bahasa Indonesia</label>
                        <div className="inputField">
                          <input
                            name="topic" type="text" disabled placeholder=""
                            className={`addllyForm-control w-100`} value={bahasaText}
                          />
                        </div>
                      </div>
                    </Col>
                  )}
                </React.Fragment>
              )}

              <Col sm="12" md="4" className="mb-3">
                <div className="form-group position-relative languageDrop">
                  <label>Select your geo-location </label>
                  <BsInfoCircleFill
                    data-tooltip-id="my-tooltip" data-tooltip-place="right"
                    data-tooltip-content="AI model suggestions is based on user inputs"
                    className='infoIconSvg'
                  />
                  <ReactFlagsSelect
                    className={'reactFlag p-0 w-100'} searchable={true} selected={geoLocation}
                    onSelect={handleOnChange} disabled={isDisabledInput}
                  />
                  <div className='arrowIcon'>
                    <IoIosArrowDown className='downArrowIcon' />
                  </div>
                </div>
              </Col>
              <Col sm="12" md="4" className="mb-3">
                <div className="chooseModel d-flex gap-5 align-items-baseline">
                  <div className="fields form-group">
                    <label>
                      Choose your AI Model
                      <BsInfoCircleFill
                        data-tooltip-id="my-tooltip" data-tooltip-place="right"
                        data-tooltip-content="AI model suggestions is based on user inputs"
                        className='infoIconSvg'
                      />
                    </label>
                    <Controller
                      control={control} name="aiType" {...register("aiType")}
                      render={({ field: { onChange, value, name, ref } }) => (
                        <Select
                          className={`custom-select ${errors.aiType ? "is-invalid" : ""}`} inputRef={ref}
                          classNamePrefix="custom-select" placeholder="Select AI Type"
                          options={AiTypeOptions} isClearable={false} defaultValue={AiTypeOptions[0]}
                          value={AiTypeOptions.find(c => c.value === value)}
                          onChange={(e) => onChange(e.value)} isDisabled={isDisabledInput}
                          components={{ DropdownIndicator }} menuPosition="fixed" isSearchable={false}
                        />
                      )}
                    />
                    {/* {watch("aiType") !== "" && watch("aiType") !== "GPT 3.5" && (
                      <div className="selectedOptions">
                        <span className="color-black-B700">Addlly suggests using <strong className="color-black-B700">GPT 3.5</strong></span>
                      </div>
                    )} */}
                  </div>
                </div>
              </Col>
              <Col sm="12" md="4" className="mb-3">
                <div className="chooseModel d-flex gap-5 align-items-baseline">
                  <div className="fields form-group">
                    <label>
                      <span>Tone of Voice <span className="text-danger">*</span></span>
                      <BsInfoCircleFill className="infoIconSvg" data-tooltip-id={"my-tooltip"}
                        data-tooltip-place="bottom-start" data-tooltip-content={`Feel free to adjust tone to better suit our needs or audience!`}
                      />
                    </label>
                    <Controller
                      control={control} name="toneOfVoice" {...register("toneOfVoice")}
                      render={({ field: { onChange, value, name, ref } }) => (
                        <Select
                          className={`custom-select ${errors.toneOfVoice ? "is-invalid" : ""}`}
                          classNamePrefix="custom-select" placeholder="Select Tone of Voice"
                          isClearable={false} name="toneOfVoice" options={toneOfVoiceOptions}
                          value={toneOfVoiceOptions.find(c => c.value === value)}
                          onChange={(e) => onChange(e.value)} isDisabled={isDisabledInput}
                          components={{ DropdownIndicator }} isSearchable={false}
                        />
                      )}
                    />
                    <div className="invalid">{errors.toneOfVoice?.message}</div>
                  </div>
                </div>
              </Col>
              <Col sm="12" md="4" className="mb-3">
                <div className="fields form-group">
                  <label>Choose a Language <span className="astrick">*</span></label>
                  <Select
                    className={`custom-select ${errors.language ? "is-invalid" : ""}`}
                    classNamePrefix="custom-select" placeholder="Select Language"
                    options={supportedLanguageList} isClearable={false} isSearchable={false}
                    value={supportedLanguageList.find(c => c.value === currentLan) || ""}
                    getOptionLabel={handleCustomSelectOption} isDisabled={isDisabledInput}
                    onChange={(event) => {
                      setCurrentLan(event.value);
                      handleSetLanguageAndGeoLocation(event, setValue, setGeoLocation, handleTranslateTextEngToBahasa, setBahasaText);
                    }}
                  />
                </div>
              </Col>
            </Row>
            <div className="d-flex align-items-start justify-content-center  align-items-center flex-row  gap-2 bottomButton">
              <button type="button" className="addlly-outline "
                onClick={() => {
                  dispatch(setSeoOptBlogStep(1));
                  // setValue("topic", ""); setGenerateLink(true); dispatch(resetSEOFLow());
                  // dispatch(setSeoGenerateTopic("")); dispatch(setSeoOptBlogTopic({}));
                  // setGeoLocation("SG"); setCurrentLan("English"); navigate(`/blog-co-pilot`);
                }}
              >
                <span><BsArrowLeft /> Previous</span>
              </button>
              <div className='d-flex align-items-center'>
                <button type="submit" disabled={!watch('topic').trim()} className="addlly-primary" style={{ whiteSpace: "nowrap" }}>
                  {!generateLink ? (
                    <React.Fragment>
                      <span>Next</span> <HiOutlineChevronRight />
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <span><BsStars />Generate Links <BsArrowRight /></span>
                    </React.Fragment>
                  )}
                </button>
                <BsInfoCircleFill
                  className="text-decoration-none cursor-pointer outline-0 infoIconSvg" data-tooltip-id="my-tooltip" data-tooltip-place="right"
                  data-tooltip-content={`Will utilize ${userRes?.deductCredits?.["Blog Co-Pilot"] || 3} Addlly credits`}
                />
              </div>
            </div>
          </form>
        )}
      </div>
    </React.Fragment>
  )
}
