import Heading from "../Components/ui/Title";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { setSocialMediaStepsInfo, setSocialMediaPostStep, setLoader } from "../store/reducer";
import SocialMediaPostHeader from "../Components/SocialMediaPostWriter/SocialMediaPostHeader";
import FirstStepSocialMediaPost from "../Components/SocialMediaPostWriter/FirstStepSocialMediaPost";
import SecondStepSocialMediaPost from "../Components/SocialMediaPostWriter/SecondStepSocialMediaPost";
import ThirdStepSocialMediaPost from "../Components/SocialMediaPostWriter/ThirdStepSocialMediaPost";
import FourthStepSocialMediaPost from "../Components/SocialMediaPostWriter/FourthStepSocialMediaPost";
import FifthStepSocialMediaPost from "../Components/SocialMediaPostWriter/FifthStepSocialMediaPost";
import SocialMediaPostHistory from "../Components/SocialMediaPostWriter/SocialMediaPostHistory";
import SixthStepSocialMediaPost from "../Components/SocialMediaPostWriter/SixthStepSocialMediaPost";
import FirstStepSocialMediaDescribePost from "../Components/SocialMediaPostWriter/FirstStepSocialMediaDescribePost";
import moment from "moment";
import { invokeAxiosGet } from "../utility/invokeAxiosFunction";

const SocialMediaPostWriter = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const socialMediaPostStep = useSelector(({ socialMediaPostStep }) => socialMediaPostStep);
  const socialMediaPostInfo = useSelector(({ socialMediaPostInfo }) => socialMediaPostInfo);
  const [eventOptions, setEventOptions] = useState([]);
  const { id } = useParams();

  useEffect(() => {
    if (!id) {
      if (socialMediaPostInfo && !(socialMediaPostInfo?.isDefaultSet)) {
        dispatch(setSocialMediaStepsInfo({}));
        dispatch(setSocialMediaPostStep(1));
        navigate("/social-media-post-writer");
      } else {
        dispatch(setSocialMediaStepsInfo({
          PostType: socialMediaPostInfo.PostType,
          selectedCard: socialMediaPostInfo.selectedCard,
          stepDone: 2,
        }));
      }
    }
  }, [id]);

  useEffect(() => {
    if (id && id !== "") {
      handleGetPostDetailsById(id, false);
    }
  }, [id]);

  useEffect(() => {
    if (eventOptions.length > 0) {
      return;
    }
    invokeAxiosGet(`/social-media-post/get-or-generate/socialMediaPostHistoryGetById?id=0`).then((res) => {
      const item = res.data ? res.data : {};
      if (item && item.upcomingEvents && item.upcomingEvents.length > 0) {
        const asian_holidays = [];
        const us_holidays = [];
        const european_holidays = [];
        const allRegions = [];

        item.upcomingEvents.map((event, index) => {
          const optionLabel = `${event.name} ( ${moment(event?.date).format("DD MMMM")} )`;
          if (event.us_holidays > 0 && event.european_holidays > 0 && event.asian_holidays > 0) {
            allRegions.push({ label: optionLabel, value: event.name });
          } else {
            if (event.us_holidays > 0) { us_holidays.push({ label: optionLabel, value: event.name }) }
            if (event.european_holidays > 0) { european_holidays.push({ label: optionLabel, value: event.name }) }
            if (event.asian_holidays > 0) { asian_holidays.push({ label: optionLabel, value: event.name }) }
          }
          return event;
        });

        setEventOptions([
          { label: "Asian Holidays & Observances", options: asian_holidays },
          { label: "US Holidays & Observances", options: us_holidays },
          { label: "European Holidays & Observances", options: european_holidays },
          { label: "All Regions Holidays & Observances", options: allRegions }
        ]);
      }
    }).catch((e) => {
      console.log("Error : ", e);
    });
  }, [eventOptions])

  const handleGetPostDetailsById = useCallback((id, isSetStep = false) => {
    dispatch(setLoader(true));
    invokeAxiosGet(`/social-media-post/get-or-generate/socialMediaPostHistoryGetById?id=${id}`).then((res) => {
      dispatch(setLoader(false));
      const item = res.data ? res.data : {};
      item.current_step += 1;
      const postDataObj = {
        postId: item?.id, PostType: item?.post_type, topic: item?.topic, keyword: item?.keyword,
        link: item?.insert_link ? item.insert_link : item?.pdf_link ? item.pdf_link : "",
        insert_link: item?.insert_link ? item.insert_link : "",
        pdf_link: item?.pdf_link ? item.pdf_link : "",
        toneOfVoice: item?.tone_of_voice ? item.tone_of_voice : "",
        aiType: item?.AI_type ? item.AI_type : "",
        geoLocation: item?.geoLocation ? item.geoLocation : "sg", cta: item?.cta ? item.cta : "",
        top10WebRes: item?.search_response && JSON.parse(item?.search_response)?.length > 0 ? JSON.parse(item.search_response) : "",
        selectedGoogleWebsite: item?.selectedGoogleWebsite && JSON.parse(item?.selectedGoogleWebsite)?.length > 0 ? JSON.parse(item.selectedGoogleWebsite) : "",
        summary: item?.summary ? item.summary : "",
        postRegenerateLeft: item?.post_regenerate_left ? item.post_regenerate_left : 0,
        SetsOfPost: item?.sets_of_post ? item.sets_of_post : "",
        selectedPost: item?.selected_post ? item.selected_post : "",
        selected_set_post: item?.selected_set_post ? item.selected_set_post : "",
        generated_hashtags: item?.generated_hashtags ? item.generated_hashtags : "",
        oldHashtags: item?.oldHashtags && JSON.parse(item?.oldHashtags).length > 0 ? JSON.parse(item.oldHashtags) : [],
        selected_hashtags: item?.selected_hashtags && JSON.parse(item?.selected_hashtags).length > 0 ? JSON.parse(item.selected_hashtags) : [],
        upcomingEvents: item?.upcomingEvents ? item.upcomingEvents : [],
        eventName: item?.selected_event ? item.selected_event : "",
        final_post: item?.final_post ? item.final_post : "",
        post_image: item?.post_image ? JSON.parse(item.post_image) : "",
        stepDone: item?.current_step ? item.current_step : 2,
        is_check_reviewer: item?.is_check_reviewer ? item.is_check_reviewer : 0,
        is_trained: item?.is_trained ? item.is_trained : 0,
      };

      postDataObj.trendingPosts = null;
      if (item?.trending_posts && typeof JSON.parse(item.trending_posts) !== typeof undefined && JSON.parse(item.trending_posts)?.length > 0) {
        postDataObj.trendingPosts = JSON.parse(item.trending_posts);
      }

      if (item?.insert_link) {
        postDataObj.selectedCard = "link";
      } else if (item?.pdf_link) {
        postDataObj.selectedCard = "pdf";
      } else {
        postDataObj.selectedCard = "topic";
      }

      dispatch(setSocialMediaStepsInfo({ ...postDataObj }));
      if (isSetStep) {
        dispatch(setSocialMediaPostStep(item?.current_step ? item.current_step : 2));
        navigate(`/social-media-post-writer/${item?.id}`);
      }
      const scrollWrapper = document.getElementById('scroll-wrapper');
      if (scrollWrapper) { scrollWrapper.scrollTo({ top: 0, behavior: 'smooth' }); }
    }).catch((e) => {
      console.log("Error : ", e);
      dispatch(setLoader(false));
    });
  }, [dispatch])

  const steps = useMemo(() => [
    <FirstStepSocialMediaPost />,
    <FirstStepSocialMediaDescribePost eventOptions={eventOptions} />,
    <SecondStepSocialMediaPost />,
    <ThirdStepSocialMediaPost />,
    <FourthStepSocialMediaPost />,
    <FifthStepSocialMediaPost />,
    <SixthStepSocialMediaPost />,
  ], [eventOptions]);

  return (
    <React.Fragment>
      <div className="oneClickHead d-flex flex-column pt-4">
        <div className="">
          <Heading className="fw-bolder mainHeading" size="3">
            {socialMediaPostInfo?.PostType ? `${socialMediaPostInfo.PostType.replace("Twitter", "X (Twitter)")} Post Writer` : "Social Media Post Writer"}
          </Heading>
          <p>
            {socialMediaPostInfo.PostType && socialMediaPostInfo.PostType === "Instagram" ? (
              "Engage your followers with catchy instagram captions and trending hashtags."
            ) : socialMediaPostInfo.PostType && socialMediaPostInfo.PostType === "Facebook" ? (
              "Generate Facebook posts designed to grow your Facebook community."
            ) : socialMediaPostInfo.PostType && socialMediaPostInfo.PostType === "Twitter" ? (
              "Craft concise, impactful posts that get retweeted and shared."
            ) : socialMediaPostInfo.PostType && socialMediaPostInfo.PostType === "LinkedIn" ? (
              "Write professional LinkedIn posts that build thought leadership with high authority hashtags and real-time data."
            ) : (
              "Create thumb-stopping social media content effortlessly. Your ideas, our words, your impact!"
            )}
          </p>
        </div>
      </div>
      <div className="stepWraper">
        <div className="border-radius-12 bg-white AIWriterBlog AISocialMediaWriter SocialMediaWriter maingenrateBlock p-0 m-0">
          <SocialMediaPostHeader />
          {steps[socialMediaPostStep - 1]}
        </div>
      </div>

      {(socialMediaPostStep === 1 || socialMediaPostStep === 2) && !id && (
        <div className=" d-flex gap-2 flex-column tableHeading mt-5">
          <div className="mt-2">
            <SocialMediaPostHistory handleGetPostDetailsById={handleGetPostDetailsById} />
          </div>
        </div>
      )}
    </React.Fragment>
  );
}

export default SocialMediaPostWriter;
