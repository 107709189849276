import React, { memo } from "react";
import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { BsCloudUploadFill, BsImages, BsStars } from "react-icons/bs";
import { GrClose } from "react-icons/gr";
import { HiShoppingBag } from "react-icons/hi";
import { FaRegImage } from "react-icons/fa6";
import AIGeneratedImage from "./AIGeneratedImage";
import AIBrandImages from "./AIBrandImages";
import ImageLibrary from "./ImageLibrary";
import UploadImage from "./UploadImage";
import {
  setECommerceBlogInfo, setFinalContent, setGeneratedFullArticle,
  setLoader, setSocialMediaStepsInfo
} from "../../../store/reducer";
import NoDataAvailable from "../../NoDataAvailable";
import ImageDisplayContent from "./ImageDisplayContent";
import { Placeholder, Row } from "react-bootstrap";
import { invokeAxiosPost } from "../../../utility/invokeAxiosFunction";

const menus = [
  { name: "AI Generated Image", icon: <BsStars /> },
  { name: "AI Brand Images", icon: <BsStars /> },
  { name: "Free Images", icon: <BsImages /> },
  { name: "Upload Image", icon: <BsCloudUploadFill className="uploadIcon" /> },
];

const MediaLibraryDrawer = (props) => {
  const {
    postImagesModal, setPostImagesModal, articleType, isDisplayProductImgTab = true,
    articleId = 0, setFinalArticleContent = () => { }, isDisplayEComImgTab = false, editorRef,
  } = props;

  const dispatch = useDispatch();
  const userRes = useSelector(({ userRes }) => userRes);
  const generatedFullArticle = useSelector(({ generatedFullArticle }) => generatedFullArticle);
  const finalContent = useSelector(({ finalContent }) => finalContent);
  const socialMediaPostInfo = useSelector(({ socialMediaPostInfo }) => socialMediaPostInfo);
  const eCommerceBlogInfo = useSelector(({ eCommerceBlogInfo }) => eCommerceBlogInfo);

  const handleClosePostImagesModal = () => {
    setPostImagesModal({ open: false, type: "", currentTab: "AI Generated Image" });
  };

  const handleSaveSelectedImage = async (postImagesObj) => {
    if (!postImagesObj?.image) {
      return false;
    }

    let saveSocialImage = JSON.stringify({ type: "url", data: postImagesObj?.image || "" });
    if (postImagesObj.carouselImg && postImagesObj.carouselImg.length > 0) {
      saveSocialImage = JSON.stringify({ type: "carousel", data: postImagesObj.carouselImg });
    }

    if (articleType === "Social Media Post Writer") {
      dispatch(setLoader(true));
      invokeAxiosPost(`/social-media-post/get-or-generate/saveSocialMediaGeneratedFinalPost`, {
        postId: socialMediaPostInfo?.postId, postImage: saveSocialImage,
      }).then((response) => {
        dispatch(setLoader(false));
        dispatch(setSocialMediaStepsInfo({ ...socialMediaPostInfo, post_image: saveSocialImage }));
        handleClosePostImagesModal();
      }).catch((error) => {
        dispatch(setLoader(false));
      });
    } else if (postImagesObj && postImagesObj.subType && postImagesObj.subType.indexOf("Article Images") !== -1) {
      handleClosePostImagesModal(false);
      if (
        editorRef && editorRef.current && editorRef.current.selection.getNode() && editorRef.current.selection.getNode().nodeName &&
        editorRef.current.selection.getNode().nodeName === "H1"
      ) {
        const editor = editorRef.current;
        const selectedNode = editor.selection.getNode();
        if (selectedNode.nodeName === "IMG") {
          editor.dom.setAttrib(selectedNode, "src", postImagesObj?.image);
        }
        editorRef.current = editor;
        const clickEvent = new MouseEvent("click", {
          bubbles: true, cancelable: true, view: window
        });
        selectedNode.dispatchEvent(clickEvent);
      } else {
        if (document.querySelector('[aria-label^="Insert/edit image"]')) {
          document.querySelector('[aria-label^="Insert/edit image"]').click();
        } else {
          document.querySelector('[aria-label^="Reveal or hide additional toolbar items"]')?.click();
          await new Promise(resolve => setTimeout(resolve, 100));
          document.querySelector('[aria-label^="Insert/edit image"]')?.click();
          document.querySelector('[aria-label^="Reveal or hide additional toolbar items"]')?.click();
        }

        await new Promise(resolve => setTimeout(resolve, 500));
        if (document.querySelector('input[role^="combobox"][type="url"]')) {
          document.querySelector('input[role^="combobox"][type="url"]').value = postImagesObj?.image;

          const label = Array.from(document.querySelectorAll(`label[class="tox-label"]`)).find(label => label.textContent === "Image title");
          if (label) {
            const inputId = label.getAttribute("for");
            const tempValue = `${postImagesModal?.imageSubTab ? `${postImagesModal?.imageSubTab} ` : ""}${postImagesModal.currentTab}`;
            document.querySelector(`input[id="${inputId}"]`).value = tempValue;
          }

          const widthLabelElement = Array.from(document.querySelectorAll('label')).find(label => label.textContent === "Width");
          if (widthLabelElement) {
            const widthInputElement = widthLabelElement.nextElementSibling;
            if (widthInputElement && widthInputElement.tagName === "INPUT") {
              widthInputElement.value = 512;
            }
          }
        }
      }
    } else {
      dispatch(setLoader(true));
      invokeAxiosPost(`/media-library/get-or-generate/saveSelectedImageData`, {
        userId: userRes.id, articleId: articleId, type: postImagesObj.type,
        subType: postImagesObj.subType, image: saveSocialImage,
      }).then((response) => {
        if (response && response.data && response.data.keyName) {
          if (articleType === "Blog Co-Pilot") {
            dispatch(setFinalContent({ ...finalContent, [response.data.keyName]: saveSocialImage }));
          } else if (articleType === "1-Click Blog") {
            dispatch(setGeneratedFullArticle({ ...generatedFullArticle, [response.data.keyName]: saveSocialImage }));
          } else if (articleType === "E-Commerce Blog") {
            dispatch(setECommerceBlogInfo({ ...eCommerceBlogInfo, [response.data.keyName]: saveSocialImage }));
          } else if (articleType === "E-Commerce EDM") {
            setFinalArticleContent((pre) => ({ ...pre, [response.data.keyName]: saveSocialImage }));
          }
        }
        handleClosePostImagesModal();
        dispatch(setLoader(false));
      }).catch((error) => {
        dispatch(setLoader(false));
      });
    }
  };

  return (
    <div className="popupSideBar d-flex flex-column">
      <div className="headerTop d-flex justify-content-between align-items-center gap-3 w-100">
        <p className="m-0">
          <BsImages /> Generate or Upload Image
        </p>
        <button className="bg-white border-0" onClick={handleClosePostImagesModal}>
          <GrClose />
        </button>
      </div>
      <div className="genrateBlockImages d-flex flex-wrap w-100">
        <div className="blogSideBar">
          <ul className="mt-4 p-0">
            {menus.map((item, index) => (
              <React.Fragment>
                {item.name === "AI Brand Images" && !isDisplayProductImgTab ? (
                  <React.Fragment></React.Fragment>
                ) : (
                  <li
                    key={item.name + ' ' + index} className={classNames({ 'active': postImagesModal?.currentTab === item.name })}
                    onClick={(k) => setPostImagesModal((prev) => ({ ...prev, image: "", currentTab: item.name, imageSubTab: "" }))}
                  >
                    <span>{item?.icon} {item.name}</span>
                  </li>
                )}
              </React.Fragment>
            ))}

            {isDisplayEComImgTab && (
              <li
                className={classNames({ 'active': postImagesModal?.currentTab === "Product Images" })}
                onClick={(k) => setPostImagesModal((prev) => ({ ...prev, image: "", currentTab: "Product Images", imageSubTab: "" }))}
              >
                <span><HiShoppingBag /> {"Product Images"}</span>
              </li>
            )}
          </ul>
        </div>
        <div className="tabsData">
          {postImagesModal.currentTab === "AI Brand Images" && isDisplayProductImgTab && isDisplayProductImgTab === true ? (
            <AIBrandImages props={props} handleSaveSelectedImage={handleSaveSelectedImage} />
          ) : postImagesModal.currentTab === "Free Images" ? (
            <ImageLibrary props={props} handleSaveSelectedImage={handleSaveSelectedImage} />
          ) : postImagesModal.currentTab === "Upload Image" ? (
            <UploadImage props={props} handleSaveSelectedImage={handleSaveSelectedImage} />
          ) : postImagesModal.currentTab === "Product Images" ? (
            <React.Fragment>
              <div className="selectIamgesB d-flex align-items-center justify-content-end">
                <button
                  type="button" className="blogButton border-0" onClick={() => handleSaveSelectedImage(postImagesModal)}
                  disabled={postImagesModal && postImagesModal.carouselImg && postImagesModal.carouselImg.length > 0 ? false : true}
                >
                  <FaRegImage /> Upload Images
                </button>
              </div>
              {postImagesModal && postImagesModal.pexelsGenerating ? (
                <Row className=" mt-4 justify-content-start gap-3">
                  {[1, 2, 3, 4, 5].map((ele, index) => (
                    <div className="placeholderI" key={index}>
                      <Placeholder as="p" animation="glow" className="overflow-hidden rounded-3 mb-3" style={{ height: "190px" }}>
                        <Placeholder xs={12} className="h-100" />
                      </Placeholder>
                    </div>
                  ))}
                </Row>
              ) : (
                <div className="genrateImagesCards d-flex flex-wrap">
                  {(props.postGeneratedImages && props.postGeneratedImages.eComProductImages && props.postGeneratedImages.eComProductImages.length > 0) ? (
                    props.postGeneratedImages.eComProductImages.map((image, index) => (
                      <ImageDisplayContent
                        image={image} key={index} viewImgHistory={props.postGeneratedImages.eComProductImages} imageIndex={index}
                        postImagesModal={postImagesModal} setPostImagesModal={setPostImagesModal}
                        handleSaveSelectedImage={handleSaveSelectedImage}
                      />
                    ))
                  ) : (
                    <NoDataAvailable text="Images not available yet!" />
                  )}
                </div>
              )}
            </React.Fragment>
          ) : (
            <AIGeneratedImage props={props} handleSaveSelectedImage={handleSaveSelectedImage} />
          )}
        </div>
      </div>
    </div>
  );
}

export default memo(MediaLibraryDrawer);
