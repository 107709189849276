import React from "react";
import { Modal } from "react-bootstrap";
import Saved from "../../Assets/Images/Saved.png";

const GoDashboard = ({ show, onHide, goDashboard }) => {
  return (
    <Modal className="confirmationModal" centered show={show} onHide={onHide}>
      <Modal.Body className="text-center">
        <img className="mb-4" src={Saved} alt="addlly.ai" />
        <h3 className="mb-sm-3 mb-2">Saved!</h3>
        <p className="mb-sm-4 mb-2">Your details have been saved successfully</p>
        <div className="d-flex justify-content-center gap-3">
          <button onClick={onHide} type="button" className="px-4" style={{ backgroundColor: '#e6ebff', border: 'none', borderRadius: '5px', color: "#0039ff", fontSize: "14px" }}>
            Stay Here
          </button>
          <button onClick={() => goDashboard()} type="button" className="addlly-primary px-4">
            Go Dashboard
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default GoDashboard;
