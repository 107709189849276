import React, { useEffect, useState } from "react";
import copy from "copy-to-clipboard";
import toast from "react-hot-toast";
import { Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { HiMiniLink } from "react-icons/hi2";
import { ImEarth } from "react-icons/im";

const FactCheckerPreview = ({ searchResponse, col = 12, type = "", selected = [] }) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    try {
      if (selected && selected.length > 0 && selected !== typeof undefined) {
        setData(selected);
      } else {
        if (searchResponse && typeof JSON.parse(searchResponse) !== typeof undefined) {
          if (type && type === "top3") {
            const top3 = JSON.parse(searchResponse).slice(0, 3);
            setData(top3);
          } else {
            setData(JSON.parse(searchResponse));
          }
        } else {
          setData(searchResponse);
        }
      }
    } catch (error) {
      setData(searchResponse);
    }
  }, [])

  const handleCopy = (data) => {
    toast.success("Text has been copied to clipboard.");
    copy(data);
  };

  return (
    <Col xl={col} style={{ width: '100%' }}>
      <div className="preview-container fact-checker-preview px-4 ">
        {data && data.length > 0 && data.map((item, i) => (
          <React.Fragment key={i}>
            {item && item.url && (
              <div className="py-3 cards" style={{ overflow: 'auto' }}>
                <div className="url-wrapper">
                  {/* href={item.url} target="_blank" rel="noreferrer" */}
                  <span className="link-content">
                    <div className="d-flex align-items-end mb-1">
                      <div className="link-image">
                        <DisplayWebUrlImage url={item.url} faviconLink={item?.faviconLink} />
                      </div>
                      <div>
                        <p>{item.url.split("://")[1].split("/")[0]?.replace("www.", "")}
                          <span className="p-2">
                            <HiMiniLink onClick={() => handleCopy(item.url)} className="text-primary"
                              data-tooltip-id={"my-tooltip"} data-tooltip-place="right" data-tooltip-content={`Copy Link`} />
                          </span>
                        </p>
                        <Link to={item.url} target="_blanck" className="linkUrl">{item.url.split("?")[0]}</Link>
                      </div>
                    </div>
                    <h3 className="fw-bold " dangerouslySetInnerHTML={{ __html: item?.title ? item.title : item?.metaDescription }}></h3>
                  </span>
                </div>
                <p className="desc-content mw-100" dangerouslySetInnerHTML={{ __html: item?.description ? item.description : item?.content?.substr(0, 400) }}></p>
              </div>
            )}
          </React.Fragment>
        ))}
      </div>
    </Col>
  )
}

export default FactCheckerPreview;

const DisplayWebUrlImage = ({ url, faviconLink }) => {
  const [isImageExit, setIsImageExit] = useState(false);
  const [imageUrl, setImageUrl] = useState('');

  useEffect(() => {
    if (faviconLink && (faviconLink !== 'favicon.ico' && faviconLink !== '/favicon.ico')) {
      if (faviconLink[0] === '/' && faviconLink[1] !== '/') {
        const getOrigin = new URL(url)?.origin;
        setImageUrl(`${getOrigin}${faviconLink}`);
      } else {
        setImageUrl(faviconLink);
      }
      setIsImageExit(true);
    } else if (url) {
      const getOrigin = new URL(url)?.origin;
      setImageUrl(`${getOrigin}/favicon.ico`);
      setIsImageExit(true);
    }
  }, [faviconLink, url])

  return (
    <React.Fragment>
      {isImageExit ? (
        <img src={imageUrl} onError={() => setIsImageExit(false)} alt="" />
      ) : (
        <ImEarth className="text-primary" />
      )}
    </React.Fragment>
  )
}
