import React, { useEffect, useMemo, useRef, useState } from "react";
import * as Yup from "yup";
import Swal from "sweetalert";
import { yupResolver } from "@hookform/resolvers/yup";
import toast from "react-hot-toast";
import Select from "react-select";
import Tree from "react-d3-tree";
import classNames from "classnames";
import { ReactSVG } from "react-svg";
import { MenuItem } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ReactFlagsSelect from "react-flags-select";
import { Col, Form, ListGroup, Modal, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { IoIosArrowDown } from "react-icons/io";
import { AiOutlineEye } from "react-icons/ai";
import { IoMdClose } from "react-icons/io";
import { LuZoomIn } from "react-icons/lu";
import { FiZoomOut } from "react-icons/fi";
import { PiArrowSquareDownFill, PiArrowSquareUpFill } from "react-icons/pi";
import { BsArrowLeft, BsArrowRight, BsInfoCircleFill } from "react-icons/bs";
import Heading from "../Components/ui/Title";
import DropdownMenu from "../Components/ui/Menu";
import { articleHistoryIcons, blogCoPilotIcon, dataTableIcons } from "../Assets/Icons";
import TableLoader from "../Components/ui/TableLoader";
import CustomDataTable from "../Components/ui/DataTable";
import AddllyLogo from "../Assets/Images/AddllyLogo.png";
import { handleCustomSelectOption } from "../utility/hepler";
import Writer_Blog from "../Assets/Images/Dashboard/Writer_Blog.png";
import { invokeAxiosGet, invokeAxiosPost } from "../utility/invokeAxiosFunction";
import Writer_Content from "../Assets/Images/Dashboard/Writer_Content.png";
import { SortArrow, sorting, tableDateFormatter } from "../utility/common";
import {
  resetSEOFLow, setActiveMenu, setLoader, setSeoGenerateTopic,
  setSeoOptBlogStep, setSeoOptBlogTopic, setTopResultsStep, setUserRes
} from "../store/reducer";

const validationSchema = Yup.object().shape({
  topic: Yup.string().required("Write a topic to generate suggestions is required").min(2, "Write a topic to generate suggestions must be at least 2 characters")
    .trim("white-space", "Topic should not contain white spaces")
    .test("no-white-space", "Write a topic to generate suggestions should not contain white spaces", (value) => !/\s{2,}/.test(value))
    .matches(/^(?!\s)(?!.*\s{2})[^\s](.*[^\s])?$/, "Topic should not contain multiple consecutive white spaces"),
});

const languageOptions = [
  { label: "Afrikaans", value: "af", code: "ZA" },
  { label: "Arabic", value: "ar", code: "SA" },
  { label: "Armenian", value: "hy", code: "AM" },
  { label: "Belarusian", value: "be", code: "BY" },
  { label: "Bulgarian", value: "bg", code: "BG" },
  { label: "Catalan", value: "ca", code: "ES" },
  { label: "Chinese (Simplified)", value: "zh-CN", code: "CN" },
  { label: "Chinese (Traditional)", value: "zh-TW", code: "TW" },
  { label: "Croatian", value: "hr", code: "HR" },
  { label: "Czech", value: "cs", code: "CZ" },
  { label: "Danish", value: "da", code: "DK" },
  { label: "Dutch", value: "nl", code: "NL" },
  { label: "English", value: "en", code: "US" },
  { label: "Estonian", value: "et", code: "EE" },
  { label: "Filipino", value: "tl", code: "PH" },
  { label: "Finnish", value: "fi", code: "FI" },
  { label: "French", value: "fr", code: "FR" },
  { label: "German", value: "de", code: "DE" },
  { label: "Greek", value: "el", code: "GR" },
  { label: "Hebrew", value: "iw", code: "IL" },
  { label: "Hindi", value: "hi", code: "IN" },
  { label: "Hungarian", value: "hu", code: "HU" },
  { label: "Icelandic", value: "is", code: "IS" },
  { label: "Indonesian", value: "id", code: "ID" },
  { label: "Italian", value: "it", code: "IT" },
  { label: "Japanese", value: "ja", code: "JP" },
  { label: "Korean", value: "ko", code: "KR" },
  { label: "Latvian", value: "lv", code: "LV" },
  { label: "Lithuanian", value: "lt", code: "LT" },
  { label: "Norwegian", value: "no", code: "NO" },
  { label: "Persian", value: "fa", code: "IR" },
  { label: "Polish", value: "pl", code: "PL" },
  { label: "Portuguese", value: "pt", code: "PT" },
  { label: "Romanian", value: "ro", code: "RO" },
  { label: "Russian", value: "ru", code: "RU" },
  { label: "Serbian", value: "sr", code: "RS" },
  { label: "Slovak", value: "sk", code: "SK" },
  { label: "Slovenian", value: "sl", code: "SI" },
  { label: "Spanish", value: "es", code: "ES" },
  { label: "Swahili", value: "sw", code: "KE" },
  { label: "Swedish", value: "sv", code: "SE" },
  { label: "Thai", value: "th", code: "TH" },
  { label: "Turkish", value: "tr", code: "TR" },
  { label: "Ukrainian", value: "uk", code: "UA" },
  { label: "Vietnamese", value: "vi", code: "VN" },
];

const defaultValues = { topic: "", language: "en", geoLocation: "sg" };

const TopicSuggestions = ({ topicType = "TopicSuggestions" }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userRes = useSelector(({ userRes }) => userRes);
  const [currStep, setCurrStep] = useState(1);
  const [topicSuggestionInfo, setTopicSuggestionInfo] = useState({});
  const [treeGraphData, setTreeGraphData] = useState({});
  const [tableRankData, setTableRankData] = useState({});
  const [concernsData, setConcernsData] = useState([]);
  const [selectedTopics, setSelectedTopics] = useState("");
  const [selectedKeywords, setSelectedKeywords] = useState("");
  const [topicSetPopup, setTopicSetPopup] = useState({ open: false, topic: "" });
  const [isShowTopics, setIsShowTopics] = useState({});
  const [geoLocation, setGeoLocation] = useState("");
  const [isMobileHead, setIsMobileHead] = useState()

  useEffect(() => {
    const handleResize = () => {
      setIsMobileHead(window.innerWidth <= 768);
    };

    handleResize();

    window.addEventListener('resize', handleResize);
    window.addEventListener('load', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('load', handleResize);
    };
  }, []);

  const handleGetDetailsById = async (id) => {
    try {
      dispatch(setLoader(true));
      setTopicSuggestionInfo({}); setSelectedTopics(""); setSelectedKeywords("");
      setTreeGraphData({}); setTableRankData({}); setConcernsData([]);

      const res = await invokeAxiosGet(`/topic-suggestion/get-or-generate/getTopicSuggestionsById?id=${id}`);
      if (res && res.data && res.data.topicList && res.data.topicList.length > 0) {
        setCurrStep(3); setTopicSuggestionInfo(res.data);
        window.scrollTo(0, 0);
      } else if (res && res.data && res.data.data && res.data.data.length > 0) {
        setCurrStep(2); setTopicSuggestionInfo(res.data);
        handleSetAndConvertTreeGraphData(res.data);
        setGeoLocation(res.data.geoLocation ? res.data.geoLocation : "SG");
        window.scrollTo(0, 0);
      } else if (res && res.data && res.data.concernsData && res.data.concernsData.length > 0) {
        setCurrStep(1); setTopicSuggestionInfo(res.data); setConcernsData(res.data.concernsData);
        setGeoLocation(res.data.geoLocation ? res.data.geoLocation : "SG");
        window.scrollTo(0, 0);
      } else {
        toast.error("Topic suggestions content not generated.", { id: "Toast-01" });
      }
      dispatch(setLoader(false));
    } catch (error) {
      toast.error("Something went wrong, Please try again later.", { id: "Toast-01" });
      dispatch(setLoader(false));
    }
  };

  const handleSetAndConvertTreeGraphData = async (articleData) => {
    if (articleData.rankingTopicData && Object.keys(articleData.rankingTopicData).length) {
      try {
        let rankData = articleData.rankingTopicData;
        setTableRankData(rankData);

        for (let index = 0; index < Object.keys(rankData).length; index++) {
          const topicName = Object.keys(rankData)[index];
          setIsShowTopics((prev) => ({ ...prev, [topicName]: true }));

          if (topicName && (!rankData[topicName] || rankData[topicName] === "")) {
            const payload = {
              generateType: "generate-topic-rank", topic: topicName, articleId: articleData.articleId
            };

            const response = await invokeAxiosPost("/topic-suggestion/get-or-generate/createOrUpdateTopicSuggestions", payload);
            if (response && response.success === 1 && response.data && response.data.rankingTopicData) {
              rankData = response.data.rankingTopicData;
            }
          }

          if (topicName && rankData && rankData[topicName] && rankData[topicName].questions) {
            let questionsList = rankData[topicName].questions ? [...rankData[topicName].questions] : [];
            if (rankData[topicName].additional_questions && rankData[topicName].additional_questions.length > 0) {
              questionsList = [...questionsList, ...rankData[topicName].additional_questions];
            }
            rankData[topicName].questions = [...questionsList];
            rankData[topicName].questions.sort((a, b) => a.answered_count - b.answered_count);
          }
          setTableRankData(rankData);
        }
      } catch (error) {
      }
    } else {
      const convertToArrayStructure = (data) => {
        if (!data || typeof data !== 'object') {
          return null;
        }

        if (Array.isArray(data)) {
          return {
            name: 'Parent',
            children: data.map(convertToArrayStructure)
          };
        }

        const result = {
          name: (data?.term || data?.question),
          children: []
        };

        if (data.results && Array.isArray(data.results)) {
          result.children = data.results.map(convertToArrayStructure);
        }

        return result;
      };

      let convertedObject = {};
      if (articleData.data && articleData.data.length > 0) {
        const singleTerm = articleData.data[0];
        convertedObject = convertToArrayStructure(singleTerm);
      }
      setTreeGraphData(convertedObject);
    }
  };

  const handleMoveToFirstStep = (no) => {
    if (no === 1) {
      setCurrStep(no);
      setTopicSuggestionInfo({}); setConcernsData([]);
      setTreeGraphData({}); setTableRankData({});
      setSelectedTopics(""); setSelectedKeywords("");
    } else if (no === 2) {
      setCurrStep(2); setTopicSuggestionInfo(topicSuggestionInfo);
      handleSetAndConvertTreeGraphData(topicSuggestionInfo);
      setSelectedTopics(topicSuggestionInfo.questionsList); setSelectedKeywords(topicSuggestionInfo.keyword);
      setGeoLocation(topicSuggestionInfo.geoLocation ? topicSuggestionInfo.geoLocation : "SG");
      window.scrollTo(0, 0);
    }
  };

  const handleClickOnTreeTopic = (writer) => {
    setTopicSetPopup({ open: false, topic: "" });
    if (writer === "1-Click Blog Writer") {
      localStorage.setItem("set_topic", topicSetPopup.topic);
      localStorage.setItem("set_keyword", topicSetPopup.keyword);
      localStorage.setItem("set_geoLocation", geoLocation);
      dispatch(setTopResultsStep(1)); dispatch(setActiveMenu(2));
      navigate("/1-click-blog");
    } else if (writer === "SEO Blog Co-Pilot") {
      dispatch(resetSEOFLow()); dispatch(setActiveMenu(3)); dispatch(setSeoOptBlogStep(2));
      dispatch(setSeoOptBlogTopic({ selectedCard: "Topic", generateLink: 1, isDefaultSet: true, stepDone: 2, geoLocation }));
      dispatch(setSeoGenerateTopic(topicSetPopup.topic));
      navigate("/blog-co-pilot");
    }
  };

  return (
    <React.Fragment>
      <div className="oneClickHead d-flex flex-column pt-3">
        <div className="">
          <Heading className="fw-bolder mainHeading" size="3">{topicType === "TatlerSeoBlog" ? 'Blog Ideas Generator' : 'Topic Suggestions'}</Heading>
          <p>{topicType === "TatlerSeoBlog" ?
            'Generate fresh and unique blog topics based on the most commonly asked questions in Google search.' :
            'Generate a suggestions on any topic with just a single click.'}
          </p>
        </div>
      </div>
      <div className={`stepWraper mt-4 border-radius-12 bg-white AIWriterBlog maingenrateBlock p-0 m-0`}>
        <div className=" border-radius-12 bg-white AIWriterBlog AISocialMediaWriter SocialMediaWriter maingenrateBlock p-0 m-0">
          <div className="AIWriterBlogHead SocialMediaHeader d-flex justify-content-center">
            <div className="AiWriterBlogHeadTop d-flex justify-content-center ">
              <div className="AIWriterStepper">
                <ul>
                  <li
                    className={`${currStep === 1 ? "active" : ""} ${1 < currStep ? "complete" : ""} ${1 < currStep ? "is-clickable" : ""}`}
                    onClick={() => 1 < currStep ? handleMoveToFirstStep(1) : null} onKeyDown={() => { }}
                  >
                    <span className="d-flex justify-content-center align-items-center">01</span> <p style={{ fontSize: isMobileHead && '10px' }}>Describe Topic</p>
                  </li>
                  <li
                    className={`${currStep === 2 ? "active" : ""} ${2 < currStep ? "complete" : ""} ${1 < currStep ? "is-clickable" : ""}`}
                    onClick={() => 2 < currStep ? handleMoveToFirstStep(2) : null} onKeyDown={() => { }}
                  >
                    <span className="d-flex justify-content-center align-items-center">02</span> <p style={{ fontSize: isMobileHead && '10px' }}>Topic Suggestions</p>
                  </li>
                  {(!topicSuggestionInfo?.mainType || topicSuggestionInfo?.mainType !== "chart") && (
                    <li className={`${currStep === 3 ? "active" : ""} ${3 < currStep ? "complete" : ""} ${3 < currStep ? "is-clickable" : ""}`}>
                      <span className="d-flex justify-content-center align-items-center">03</span> <p style={{ fontSize: isMobileHead && '10px' }}>Topic Optimization</p>
                    </li>
                  )}
                </ul>
              </div>
            </div>
          </div>
          {currStep === 3 ? (
            <ThirdStepTopicSuggestion topicSuggestionInfo={topicSuggestionInfo} setCurrStep={setCurrStep} setTopicSetPopup={setTopicSetPopup} />
          ) : currStep === 2 ? (
            <SecondStepTopicSuggestion
              topicSuggestionInfo={topicSuggestionInfo} setTopicSuggestionInfo={setTopicSuggestionInfo} treeGraphData={treeGraphData} tableRankData={tableRankData}
              selectedTopics={selectedTopics} setSelectedTopics={setSelectedTopics} selectedKeywords={selectedKeywords} setSelectedKeywords={setSelectedKeywords}
              isShowTopics={isShowTopics} setIsShowTopics={setIsShowTopics} handleMoveToFirstStep={handleMoveToFirstStep}
              setTopicSetPopup={setTopicSetPopup} setCurrStep={setCurrStep}
            />
          ) : (
            <FirstStepTopicSuggestion
              concernsData={concernsData} setConcernsData={setConcernsData} selectedTopics={selectedTopics} setSelectedTopics={setSelectedTopics}
              topicSuggestionInfo={topicSuggestionInfo} setTopicSuggestionInfo={setTopicSuggestionInfo}
              setCurrStep={setCurrStep} setGeoLocation={setGeoLocation} topicType={topicType}
              handleSetAndConvertTreeGraphData={handleSetAndConvertTreeGraphData} handleMoveToFirstStep={handleMoveToFirstStep}
            />
          )}
        </div>
      </div>

      {currStep === 1 && concernsData.length === 0 && (
        <div className="d-flex gap-2 flex-column tableHeading mt-4">
          <div className="mt-2">
            <TopicSuggestionHistory handleGetDetailsById={handleGetDetailsById} topicType={topicType} />
          </div>
        </div>
      )}

      {topicSetPopup && topicSetPopup.open && (
        <Modal centered size="" className="note-modal" show={topicSetPopup.open} onHide={() => setTopicSetPopup({ open: false, topic: "" })}>
          <Modal.Body closeButton className="text-center p-4  bg-white">
            <div className="h-100 modelHeader text-start d-flex justify-content-between align-items-center">
              <img src={AddllyLogo} alt="addlly logo" />
              <IoMdClose onClick={() => setTopicSetPopup({ open: false, topic: "" })} className="closeIcon" size={35} />
            </div>
            <hr />
            <div className="text-start">
              <div className="shadow-sm my-4 p-3" style={{ borderRadius: '12px' }}>
                <h4 className="fw-normal mb-2"><b className="me-3">Topic : </b>{topicSetPopup?.topic || "-"}</h4>
                {topicSetPopup?.keyword && topicSetPopup?.keyword !== "" && (
                  <h4 className="fw-normal mb-2"><b className="me-3">Keyword : </b>{topicSetPopup?.keyword || "-"}</h4>
                )}
                <h4 className="fw-normal"><b className="me-3">Geo Location :</b>{geoLocation}</h4>
              </div>
              <p className="mb-2 text-mutate text-sm" style={{ fontStyle: 'italic' }}>Select any one writer to generate article with this topic.</p>
              <Row>
                {[
                  {
                    label: "1-Click Blog Writer",
                    imgSrc: Writer_Blog,
                  },
                  {
                    label: "SEO Blog Co-Pilot",
                    imgSrc: Writer_Content,
                  },
                ].map((ele, index) => (
                  <Col sm={12} md={6} className="mb-1 text-center " key={index} onClick={() => { handleClickOnTreeTopic(ele.label); }}>
                    <div className="p-30 bg-white border-radius-12 h-100 shadow-sm border selectionCard">
                      <div className="">
                        <img src={ele.imgSrc} style={{ width: "60px" }} alt={ele.label} />
                        <h4 className="mt-3">{ele.label}</h4>
                      </div>
                    </div>
                  </Col>
                ))}
              </Row>
            </div>
          </Modal.Body>
        </Modal>
      )}
    </React.Fragment>
  )
};

const TopicSuggestionHistory = ({ handleGetDetailsById, topicType }) => {
  const userRes = useSelector(({ userRes }) => userRes);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [tableLoader, setTableLoader] = useState(false);
  const [sortField, setSortField] = useState({ column: {}, direction: "" });

  useEffect(() => {
    handleGetTopicSuggestionsList();
  }, []);

  const handleGetTopicSuggestionsList = async (limit, offset) => {
    setData([]);
    const apiUrl = `/topic-suggestion/get-or-generate/getTopicSuggestionsHistory`;
    const queryParams = `userId=${userRes.id}&limit=${limit}&offset=${offset}&topicType=${topicType}`;

    try {
      setTableLoader(true);
      const response = await invokeAxiosGet(`${apiUrl}?${queryParams}`);
      if (response && response.data) {
        setData(response.data.data);

        if (response.data.data && response.data.data.length > 0) {
          const findIndex = response.data.data.findIndex((data) => data.status === "pending");
          if (findIndex !== -1) {
            setTimeout(() => { handleGetTopicSuggestionsList(); }, 20000);
          }
        }
      }

      setTableLoader(false);
    } catch (error) {
      setTableLoader(false);
    }
  }

  const handleSort = (column, sortDirection) => {
    setPage(1);
    setSortField(pre => ({ ...pre, column: column, direction: sortDirection }));
    const newData = sorting(data, column?.sortField, sortDirection);
    setData(newData);
  };

  const columns = useMemo(() => ([
    {
      id: "title",
      name: <div className="d-flex">Title {SortArrow(sortField, "title")}</div>,
      selector: (row) => (
        <div className="d-flex align-items-center gap-3" onClick={() => handleGetDetailsById(row?.id)}>
          <div className={classNames('tableValues', { showFullDes: false })}>
            <h6>{row.topic || "-"}</h6>
          </div>
        </div>
      ),
      sortable: true,
      sortField: "topic",
    },
    {
      id: "language",
      name: <div className="d-flex">Language {SortArrow(sortField, "language")}</div>,
      selector: (row) => (
        <div div className="d-flex align-items-center gap-3" onClick={() => handleGetDetailsById(row?.id)}>
          <div className={classNames('tableValues', { showFullDes: false })}>
            <h6>{row.language ? languageOptions.find((item) => item.value === row.language)?.label : "-"}</h6>
          </div>
        </div>
      ),
      sortable: true,
      sortField: "language",
    },
    {
      id: "geoLocation",
      name: <div className="d-flex">Country {SortArrow(sortField, "geoLocation")}</div>,
      selector: (row) => (
        <div div className="d-flex align-items-center gap-3" onClick={() => handleGetDetailsById(row?.id)}>
          <div className={classNames('tableValues', { showFullDes: false })}>
            <h6>{row.geoLocation || "-"}</h6>
          </div>
        </div>
      ),
      sortable: true,
      sortField: "geoLocation",
    },
    {
      id: "status",
      name: <div className="d-flex ">Status {SortArrow(sortField, "status")}</div>,
      selector: (row) => (
        <div className="d-flex justify-content-center align-items-center" onClick={() => handleGetDetailsById(row?.id)}>
          <div className="dotStatus">
            <span
              className={`d-block`}
              style={{
                width: "8px", height: "8px", borderRadius: "50%",
                backgroundColor: row.status === "completed" ? "#84CC16" : row.status === "pending" ? "#E7E721" : row.status === "processing" ? "#F59E0B" : "#EF4444",
              }}
            />
          </div>
          {row.status === "completed" ? "Done" : row.status === "pending" ? "Processing ..." : row.status === "processing" ? "In Progress" : "Error"}
        </div>
      ),
      sortable: true,
      sortField: "status",
    },
    {
      id: "created_at",
      name: <div className="d-flex">Created On {SortArrow(sortField, "created_at")}</div>,
      selector: (row) => (
        <div className="d-flex align-items-center gap-3" onClick={() => handleGetDetailsById(row?.id)}>
          <div className={classNames('tableValues', { showFullDes: false })}>
            <h6>{tableDateFormatter(row?.created_at)}</h6>
          </div>
        </div>
      ),
      sortable: true,
      sortField: "created_at",
    },
    {
      id: "sort",
      name: 'Action',
      selector: (row) => {
        return (
          <div className="popup">
            <DropdownMenu>
              <MenuItem onClick={() => handleGetDetailsById(row?.id)}>
                {row.status === "completed" ? <><AiOutlineEye /> View</> : <><ReactSVG src={dataTableIcons.pencil} /> Edit</>}
              </MenuItem>
            </DropdownMenu>
          </div>
        );
      },
    },
  ]), [sortField]);

  return (
    <React.Fragment>
      <div className="d-flex justify-content-between gap-2 mb-3">
        <span className="mb-2">Recent History</span>
      </div>
      <div className="historyTableBlock mt-2">
        <CustomDataTable
          progressPending={tableLoader} progressComponent={<TableLoader limit={10} />}
          columns={columns} totalCount={data.length || 0} pagination={true} data={data}
          itemsPerPage={10} selectableRows={false} noDataComponent={<NoDataFound />}
          onSelectedRowsChange={() => { }} setPage={setPage} page={page} onSort={handleSort}
          handleTableRowClick={(row) => { handleGetDetailsById(row?.id) }} clearSelectedRows={() => { }}
        />
      </div>
    </React.Fragment>
  )
};

const NoDataFound = () => {
  return (
    <div className="withoutData  d-flex justify-content-center align-items-center">
      <div className="genrateNewBlock d-flex justify-content-center flex-column align-items-center">
        <div className="icon d-flex justify-content-center flex-column align-items-center">
          <img src={articleHistoryIcons.add} alt="add"></img>
        </div>
        <p>History is not available yet!</p>
      </div>
    </div>
  );
};

const FirstStepTopicSuggestion = ({
  concernsData, setConcernsData, selectedTopics, setSelectedTopics, topicSuggestionInfo, setTopicSuggestionInfo,
  setCurrStep, setGeoLocation, topicType, handleSetAndConvertTreeGraphData, handleMoveToFirstStep
}) => {
  const dispatch = useDispatch();
  const userRes = useSelector(({ userRes }) => userRes);
  const [selectedCard, setSelectedCard] = useState("topic");
  const [productUrl, setProductUrl] = useState("");

  const { register, handleSubmit, formState: { errors }, reset, control, clearErrors } = useForm({
    defaultValues: defaultValues,
    resolver: yupResolver(validationSchema),
  });

  const handleOnChangeCardType = (type) => {
    setSelectedCard(type); setProductUrl("");
    reset({ ...defaultValues }); clearErrors();
    setTopicSuggestionInfo({ ...topicSuggestionInfo, mainType: type });
  };

  const cards = topicType === "TatlerSeoBlog" ? [
    { label: "Provide a Brief Description", value: "topic", icon: blogCoPilotIcon.contractIcon, iconColor: "#E6EBFF" },
    { label: "Topic Suggestions Tree Chart", value: "chart", icon: blogCoPilotIcon.blogIcon, iconColor: "rgba(167, 255, 23, 0.20)" },
  ] : [
    { label: "Provide a Brief Description", value: "topic", icon: blogCoPilotIcon.contractIcon, iconColor: "#E6EBFF" },
    // { label: "Insert Amazon Product Link", value: "link", icon: blogCoPilotIcon.referenceLinkIcon, iconColor: "rgba(255, 190, 157, 0.20)" },
    { label: "Topic Suggestions Tree Chart", value: "chart", icon: blogCoPilotIcon.blogIcon, iconColor: "rgba(167, 255, 23, 0.20)" },
  ];

  const onSubmit = async (data) => {
    try {
      if (Number(userRes.credits) < (userRes?.deductCredits?.["Topic Suggestions"] || 2)) {
        toast.error("Sorry, you don't have enough credits to perform this action.", { id: "Toast-01" });
        return;
      }

      dispatch(setLoader(true));
      const payloadObj = { ...data, topicType };
      payloadObj.isGenerateChart = selectedCard === "chart" ? true : false;
      if (topicSuggestionInfo && topicSuggestionInfo.articleId) {
        payloadObj.articleId = topicSuggestionInfo.articleId;
      }

      const response = await invokeAxiosPost("/topic-suggestion/get-or-generate/createOrUpdateTopicSuggestions", { body: payloadObj });
      dispatch(setLoader(false));
      if (response && response.success === 1 && response.data && response.data.data && response.data.data.length > 0) {
        setCurrStep(2); setTopicSuggestionInfo({ ...response.data, mainType: selectedCard });
        handleSetAndConvertTreeGraphData(response.data);
        setGeoLocation(response.data.geoLocation ? response.data.geoLocation : "SG");

        reset(defaultValues); setConcernsData([]);
        dispatch(setUserRes({ ...userRes, credits: userRes.credits - (userRes?.deductCredits?.["Topic Suggestions"] || 2) }));
        const scrollWrapper = document.getElementById('scroll-wrapper');
        if (scrollWrapper) { scrollWrapper.scrollTo({ top: 0, behavior: 'smooth' }); }
      } else {
        toast.error("Topic Suggestions not generated, Please try with different topic.", { id: "Toast-01" });
      }
    } catch (error) {
      toast.error("Something went wrong, Please try again later.", { id: "Toast-01" });
      dispatch(setLoader(false));
    }
  };

  const handleChangeTopicValue = (value, index) => {
    const tempObj = [...concernsData];
    tempObj[index] = value;

    setConcernsData(tempObj);
    setSelectedTopics("");
  };

  const handleOnSelectConcernTopic = () => {
    const obj = { topic: selectedTopics, language: "en", geoLocation: "sg" };
    reset({ ...obj }); setSelectedTopics("");
    onSubmit({ ...obj });
  };

  const generateProductReviewsConcerns = async () => {
    const expression = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/gi;
    const regex = new RegExp(expression);

    if (productUrl && productUrl.match(regex) && productUrl.indexOf("amazon") !== -1) {
      try {
        dispatch(setLoader(true)); setCurrStep(3);
        const payload = { topic: productUrl, generateType: "get-reviews-generate-concerns" };
        invokeAxiosPost("/topic-suggestion/get-or-generate/createOrUpdateTopicSuggestions", payload);
        await new Promise(resolve => setTimeout(resolve, 3000));

        Swal("Instruction!", "Topic Suggestions generating is in progress. Please wait 2-3 Mins after check history and continue further process.", "info");
        setCurrStep(1); dispatch(setLoader(false)); handleOnChangeCardType("link");
      } catch (error) {
        toast.error("Something went wrong, Please try again later.", { id: "Toast-01" });
        dispatch(setLoader(false));
      }
    } else {
      toast.error("Link is invalid, please insert a valid link");
    }
  };

  return (
    <React.Fragment>
      {concernsData && concernsData.length > 0 ? (
        <React.Fragment>
          <div className="selectBlock"><b>Select concern to generate a topic suggestions</b></div>
          <Row className="list-unstyled setOfOutline mw-100 mb-3 m-0 px-3">
            {concernsData && concernsData.length > 0 && (concernsData.slice(0, 15).map((element, index) => (
              <Col sm="12" md="12" lg="6" xxl="4">
                <li className=" p-0 border-0 blockTopics" key={index}>
                  <div className={`checkListItem py-2 ${selectedTopics === element ? "active" : ""}`}>
                    <div className="d-flex align-items-center w-100">
                      <div className="radioType">
                        <input type="radio" checked={selectedTopics === element ? true : false}
                          onChange={(e) => e.target.checked ? setSelectedTopics(element) : setSelectedTopics("")}
                        />
                      </div>
                      <textarea
                        type="text" className="ms-3 addedOutline w-100 overflow-auto" placeholder="Insert your topic here" value={element}
                        onChange={(e) => handleChangeTopicValue(e.target.value, index)} rows={2} style={{ resize: "none" }}
                      />
                    </div>
                  </div>
                </li>
              </Col>
            )))}
            <div className="d-flex justify-content-center align-items-center gap-3 bottomButton pb-0">
              <button type="button" className="addlly-outline" onClick={() => handleMoveToFirstStep(1)}>
                <span><BsArrowLeft /> Previous</span>
              </button>
              <button
                type="button" className="addlly-primary" style={{ maxWidth: "fit-content", whiteSpace: "nowrap" }}
                disabled={!selectedTopics || selectedTopics === ""} onClick={handleOnSelectConcernTopic}
              >
                Continue
              </button>
            </div>
          </Row>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Row className="social-media-images m-0">
            {cards.map((ele, index) => (
              <Col md="6" lg="3" className={`social-media-card text-center ${selectedCard === ele.value ? "active" : ""}`}
                key={index} onClick={() => handleOnChangeCardType(ele.value, false)}
              >
                <div className={`radioBtn`}>
                  <div className={`radioBtnIn`}></div>
                </div>
                <div className="imageFrame lightBlue ms-auto me-auto" style={{ backgroundColor: ele.iconColor, border: `1px solid ${ele.iconColor}` }}>
                  <img src={ele.icon} alt="" />
                </div>
                <h5 className="card-title w-100" type="button">{ele.label}</h5>
              </Col>
            ))}
          </Row>
          <div className="selectBlock">
            <b> {selectedCard === "link" ? "Generate Topic Suggestions Using Amazon Product Link" : "Generate Topic Suggestions"} </b>
          </div>
          {selectedCard === "link" ? (
            <div className="genrateFields pb-0">
              <Row className="mt-3">
                <Col sm="12" className="mb-3">
                  <div className="form-group fields">
                    <label>Describe Amazon product link <span className="astrick">*</span></label>
                    <div className="inputField">
                      <input
                        name="productUrl" type="text" placeholder="Insert product link here" value={productUrl}
                        className={`addllyForm-control w-100`} onChange={(e) => setProductUrl(e.target.value)}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
              <div className="d-flex justify-content-center align-items-center gap-2 bottomButton pt-0">
                <div className='d-flex align-items-center'>
                  <button type="button" className="addlly-primary" style={{ whiteSpace: "nowrap" }} onClick={generateProductReviewsConcerns}>
                    Continue
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <div className="genrateFields pb-0">
              <form onSubmit={handleSubmit(onSubmit)} className="">
                <Row className="mt-3">
                  <Col sm="12" className="mb-3">
                    <div className="form-group fields">
                      <label>Describe your points <span className="astrick">*</span></label>
                      <div className="inputField">
                        <input
                          name="topic" type="text" {...register("topic")} placeholder="Insert topic here"
                          className={`addllyForm-control w-100 ${errors.topic ? "is-invalid" : ""}`}
                        />
                        <div className="invalid">{errors.topic?.message}</div>
                      </div>
                    </div>
                  </Col>
                  <Col sm="12" md="4" className="mb-4">
                    <div className="form-group position-relative languageDrop">
                      <label>Select your geo-location </label>
                      <BsInfoCircleFill
                        className='infoIconSvg' data-tooltip-id="my-tooltip" data-tooltip-place="right"
                        data-tooltip-content="AI model suggestions is based on user inputs"
                      />
                      <Controller
                        control={control} name="geoLocation" {...register("geoLocation")}
                        render={({ field: { onChange, value, name, ref } }) => (
                          <ReactFlagsSelect
                            className={'reactFlag p-0 w-100'} onSelect={(value) => onChange(value.toLowerCase())}
                            searchable={true} selected={value && value !== "" ? value.toUpperCase() : ""}
                          />
                        )}
                      />
                      <div className='arrowIcon'>
                        <IoIosArrowDown className='downArrowIcon' />
                      </div>
                      <div className="invalid-feedback">{errors.geoLocation?.message}</div>
                    </div>
                  </Col>
                  <Col sm="12" md="4" className="mb-4">
                    <div className="fields form-group">
                      <label>Choose a Language <span className="astrick">*</span></label>
                      <Controller
                        control={control} name="language" {...register("language")}
                        render={({ field: { onChange, value, name, ref } }) => (
                          <Select
                            className={`custom-select ${errors.language ? "is-invalid" : ""}`} classNamePrefix="custom-select" placeholder="Select Language"
                            options={languageOptions} isClearable={false} inputRef={ref} value={languageOptions.find(c => c.value === value)}
                            onChange={(e) => onChange(e.value)} isSearchable={false} getOptionLabel={handleCustomSelectOption}
                          />
                        )}
                      />
                      <div className="invalid-feedback">{errors.language?.message}</div>
                    </div>
                  </Col>
                </Row>
                <div className="d-flex justify-content-center align-items-center gap-2 bottomButton pt-0">
                  <div className='d-flex align-items-center'>
                    <button type="submit" className="addlly-primary" style={{ whiteSpace: "nowrap" }}>
                      Continue
                    </button>
                    <BsInfoCircleFill
                      className="text-decoration-none cursor-pointer outline-0 infoIconSvg" data-tooltip-id="my-tooltip" data-tooltip-place="right"
                      data-tooltip-content={`Will utilize ${userRes?.deductCredits?.["Topic Suggestions"] || 2} Addlly credits`}
                    />
                  </div>
                </div>
              </form>
            </div>
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  )
};

const SecondStepTopicSuggestion = ({
  topicSuggestionInfo, setTopicSuggestionInfo, treeGraphData, tableRankData, selectedTopics, setSelectedTopics,
  selectedKeywords, setSelectedKeywords, isShowTopics, setIsShowTopics, handleMoveToFirstStep, setTopicSetPopup, setCurrStep
}) => {
  const dispatch = useDispatch();
  const [zoom, setZoom] = useState(0.5);
  const [isMostAsked, setIsMostAsked] = useState(false);
  const [isDisabledInput, setIsDisabledInput] = useState(false);
  const canvasWrapper = useRef(null);

  useEffect(() => {
    if (topicSuggestionInfo && topicSuggestionInfo.topicList && topicSuggestionInfo.topicList.length > 0) {
      setIsDisabledInput(true);
    }
  }, [topicSuggestionInfo])

  const renderCustomNode = ({ nodeDatum }) => (
    <React.Fragment>
      <circle r="18" onClick={() => setTopicSetPopup({ open: true, topic: nodeDatum.name })}></circle>
      {nodeDatum?.overall_rank && nodeDatum.overall_rank >= 0 && (
        <text style={{ stroke: "#fff", strokeWidth: 2, transform: `translate(${nodeDatum.overall_rank > 9 ? "-10px" : "-5px"}, 6px)` }}
          data-tooltip-id={`my-tooltip`} data-tooltip-place="top" data-tooltip-content={"Overall Rank"}
        >
          {nodeDatum.overall_rank}
        </text>
      )}
      <g
        class="rd3t-label position-relative" style={{ outline: "none" }} data-tooltip-id={`my-tooltip`} data-tooltip-place="bottom"
        data-tooltip-content={nodeDatum?.answered_count >= 0 ? `Answered Count ${nodeDatum.answered_count} Out Of 10` : ""}
      >
        <text class="rd3t-label__title" text-anchor="start" x="40" y="5" onClick={() => setTopicSetPopup({ open: true, topic: nodeDatum.name })}>
          {nodeDatum.name}
        </text>
      </g>
    </React.Fragment>
  );

  const handleZoomInOut = (value) => {
    if (value === "in") {
      if (zoom < 1) {
        setZoom((Number(zoom) + 0.1)?.toFixed(1));
      }
    } else if (value === "out") {
      if (zoom > 0.1) {
        setZoom((Number(zoom) - 0.1)?.toFixed(1))
      }
    }
  };

  const generateTopicOptimization = async () => {
    try {
      if (topicSuggestionInfo && topicSuggestionInfo.topicList && topicSuggestionInfo.topicList.length > 0) {
        setCurrStep(3); return false;
      }

      dispatch(setLoader(true)); setCurrStep(3);
      const payload = {
        questionsList: selectedTopics, keyword: selectedKeywords,
        articleId: topicSuggestionInfo.articleId || 0,
        generateType: "generate-topic-optimization",
      };

      const response = await invokeAxiosPost("/topic-suggestion/get-or-generate/createOrUpdateTopicSuggestions", payload);
      dispatch(setLoader(false));
      if (response && response.success === 1 && response.data && response.data.topicList && response.data.topicList.length > 0) {
        setCurrStep(3); setTopicSuggestionInfo({
          ...topicSuggestionInfo, ...response.data, questionsList: selectedTopics, keyword: selectedKeywords
        });
        const scrollWrapper = document.getElementById('scroll-wrapper');
        if (scrollWrapper) { scrollWrapper.scrollTo({ top: 0, behavior: 'smooth' }); }
      } else {
        toast.error("Topic optimization not generated, Please try with different topic.", { id: "Toast-01" });
      }
    } catch (error) {
      toast.error("Something went wrong, Please try again later.", { id: "Toast-01" });
      dispatch(setLoader(false));
    }
  };

  return (
    <React.Fragment>
      <div className="selectBlock"><b>Generated topic suggestions</b></div>
      <Row className="eCommerce-blog-wrapper p-0 mb-3 position-relative">
        <Col sm="3" className="topicBlockBottom side-menu ps-0 h-100 position-sticky top-0">
          <div className="optimizationBlock shadow-sm h-auto">
            <div className="cardOptimiz">
              <div className="topicBlock mb-3">
                <span className="d-block mb-1">Topic</span>
                <p className="fw-normal overflow-auto">{topicSuggestionInfo.topic}</p>
              </div>
              <div className="topicBlock mb-3">
                <span className="d-block mb-1">Geo Location</span>
                <p className="fw-normal overflow-auto">{topicSuggestionInfo.geoLocation}</p>
              </div>
              <div className="topicBlock mb-3">
                <span className="d-block mb-1">Language</span>
                <p className="fw-normal overflow-auto">
                  {languageOptions.find((item) => item.value === topicSuggestionInfo.language)?.label}
                </p>
              </div>
            </div>
          </div>
          {((tableRankData && Object.keys(tableRankData).length > 0) || (topicSuggestionInfo?.topicList?.length > 0)) && (
            <React.Fragment>
              <div className="optimizationBlock shadow-sm h-auto mt-3">
                <div className="cardOptimiz">
                  <div className="d-flex flex-wrap justify-content-between align-content-center mb-2">
                    <label className="m-0">Selected Questions ( Max 5 )</label>
                    <button className="btn text-primary p-0 border-0" onClick={() => { setSelectedTopics([]); setSelectedKeywords(""); }}
                      disabled={selectedTopics.length > 0 && !isDisabledInput ? false : true}
                    >
                      Clear All
                    </button>
                  </div>
                  {selectedTopics && selectedTopics.length > 0 ? (
                    <ul>
                      {selectedTopics.map((topic) => (
                        <li className="fw-normal text-dark">{topic}</li>
                      ))}
                    </ul>
                  ) : (
                    <h5 className="fw-normal text-danger">You don’t have any question selected. Please select to continue.</h5>
                  )}
                  <div className="d-flex justify-content-between align-content-center mt-4 mb-2">
                    <label className="m-0">Selected Keyword</label>
                  </div>
                  {selectedKeywords && selectedKeywords !== "" ? (
                    <h5 className="fw-normal text-dark">{selectedKeywords}</h5>
                  ) : (
                    <h5 className="fw-normal text-danger">You don’t have any keyword selected. Please select to continue.</h5>
                  )}
                  <button
                    type="button" className="btn btn-primary mt-4 ms-auto" onClick={() => generateTopicOptimization()}
                    disabled={selectedTopics?.length && selectedKeywords ? false : true}
                  >
                    Continue
                  </button>
                </div>
              </div>
            </React.Fragment>
          )}
        </Col>
        {((tableRankData && Object.keys(tableRankData).length > 0) || (topicSuggestionInfo?.topicList?.length > 0)) ? (
          <Col sm="9" className="position-relative overflow-auto p-0">
            <div className="w-100 d-flex justify-content-end mb-3">
              <div className="btn-group" role="group" aria-label="Basic example">
                <button type="button" onClick={() => setIsMostAsked(false)} className={`btn btn-outline-primary ${!isMostAsked ? "active" : ""}`}>
                  Unique
                </button>
                <button type="button" onClick={() => setIsMostAsked(true)} className={`btn btn-outline-primary ${isMostAsked ? "active" : ""}`}>
                  Most Asked
                </button>
              </div>
            </div>
            {tableRankData && Object.keys(tableRankData).length > 0 && Object.keys(tableRankData).map((topic, index) => (
              <div className="mb-4" key={index}>
                <div
                  className="selectBlock mx-0 mb-3 mw-100 d-flex align-items-center justify-content-between"
                  style={{ background: "linear-gradient(102.77deg, rgba(0, 0, 255, 0.05) 0%, rgba(255, 0, 0, 0.05) 121.74%)" }}
                >
                  <div>
                    <b className="me-2">Topic suggestion {index + 1} :</b><span>{topic}</span>
                  </div>
                  <button
                    type="button" className="btn p-0" style={{ color: "var(--primary-main)" }}
                    onClick={() => setIsShowTopics((prev) => ({ ...prev, [topic]: !prev[topic] }))}
                  >
                    {isShowTopics[topic] ? <PiArrowSquareUpFill className="fs-3" /> : <PiArrowSquareDownFill className="fs-3" />}
                  </button>
                </div>
                {isShowTopics[topic] && isShowTopics[topic] === true && (
                  <Row className="m-0">
                    <Col lg="12">
                      <ul className="question-keyword-table m-0 p-0">
                        <ListGroup horizontal={"sm"} className="">
                          <ListGroup.Item className="list-group-header">
                            {isMostAsked ? "Quotient" : "Uniqueness Quotient"}
                          </ListGroup.Item>
                          <ListGroup.Item className="list-group-header justify-content-start">
                            {isMostAsked ? "Most Asked Questions" : "Unique Questions"}
                          </ListGroup.Item>
                          <ListGroup.Item className="list-group-header justify-content-start">Keywords & Volume</ListGroup.Item>
                        </ListGroup>
                        {tableRankData[topic] && tableRankData[topic].questions && tableRankData[topic].questions.length > 0 ? (
                          <React.Fragment>
                            {tableRankData[topic].questions.sort((a, b) => isMostAsked ? b.answered_count - a.answered_count : a.answered_count - b.answered_count)
                              .slice(0, 5).map((item, index) => (
                                <ShowQuestionAndKeywordItem
                                  item={item} key={index} itemNo={index + 1} selectedTopics={selectedTopics} selectedKeywords={selectedKeywords}
                                  onSelectQuestion={(topics, keyword) => {
                                    if (!isDisabledInput) { setSelectedTopics(topics); setSelectedKeywords(keyword); }
                                  }}
                                />
                              ))}
                          </React.Fragment>
                        ) : (
                          <SkeletonLoader />
                        )}
                      </ul>
                    </Col>
                  </Row>
                )}
              </div>
            ))}
          </Col>
        ) : (
          <Col sm="9" className="position-relative overflow-auto">
            <span className="zoomInOut" style={{ position: 'absolute', top: 10, right: 30 }} >
              <LuZoomIn size={30} className="p-1 border rounded shadow-sm mx-1 cursor-pointer bg-white" onClick={() => handleZoomInOut("in")} />
              <FiZoomOut size={30} className="p-1 border rounded shadow-sm mx-1 cursor-pointer bg-white" onClick={() => handleZoomInOut("out")} />
            </span>
            <div className="tree-graph-wrapper shadow-sm" ref={canvasWrapper}>
              <Tree
                data={treeGraphData} renderCustomNodeElement={renderCustomNode}
                rootNodeClassName="node__root" branchNodeClassName="node__branch" leafNodeClassName="node__leaf"
                collapsible={false} zoomable={true} draggable={true} nodeSize={{ x: 500, y: 50 }}
                zoom={zoom} scaleExtent={{ min: 0.1, max: 1 }} translate={{ x: 200, y: 400 }}
              />
            </div>
          </Col>
        )}
      </Row>
      <div className="d-flex align-items-start justify-content-center  align-items-center flex-row  gap-2 bottomButton">
        <button type="button" className="addlly-outline" onClick={() => handleMoveToFirstStep(1)}>
          <span><BsArrowLeft /> Previous</span>
        </button>
      </div>
    </React.Fragment>
  )
};

const ThirdStepTopicSuggestion = ({ topicSuggestionInfo, setCurrStep, setTopicSetPopup }) => {
  const [questionsList, setQuestionsList] = useState([]);
  const [selectedKeywords, setSelectedKeywords] = useState("");
  const [topicList, setTopicList] = useState([]);
  const [selectedTopic, setSelectedTopic] = useState("");

  useEffect(() => {
    if (topicSuggestionInfo && topicSuggestionInfo.questionsList && topicSuggestionInfo.questionsList.length > 0) {
      setQuestionsList(topicSuggestionInfo.questionsList);
      setSelectedKeywords(topicSuggestionInfo.keyword);
    }

    if (topicSuggestionInfo && topicSuggestionInfo.topicList && topicSuggestionInfo.topicList.length > 0) {
      setTopicList(topicSuggestionInfo.topicList);
    }
  }, [topicSuggestionInfo])

  const handleTopicSelection = (topic = null) => {
    setSelectedTopic(topic);
    if (!topic || topic === "") {
      toast.error("Please insert a topic before selection", { id: "Toast-01" });
      setSelectedTopic("");
    }
  };

  const handleChangeTopicValue = (event, index) => {
    const { name, value } = event.target;
    let tempObj = [...topicList];
    tempObj[index] = { ...tempObj[index], [name]: value };
    setTopicList(tempObj);
    setSelectedTopic("");
  };

  return (
    <React.Fragment>
      <div className="selectBlock"><b>Generated topic Optimization</b></div>
      <Row className="eCommerce-blog-wrapper p-0 mb-3 position-relative">
        <Col sm="12" md="3" className="topicBlockBottom side-menu ps-0 h-100 position-sticky top-0">
          <div className="optimizationBlock shadow-sm h-auto">
            <div className="cardOptimiz">
              <div className="d-flex flex-wrap justify-content-between align-content-center mb-2">
                <label className="m-0">Selected Questions</label>
              </div>
              {questionsList && questionsList.length > 0 ? (
                <ul>
                  {questionsList.map((topic) => (
                    <li className="fw-normal text-dark">{topic}</li>
                  ))}
                </ul>
              ) : (
                <h5 className="fw-normal text-danger">You don’t have any question selected. Please select to continue.</h5>
              )}
              <div className="d-flex justify-content-between align-content-center mt-4 mb-2">
                <label className="m-0">Selected Keyword</label>
              </div>
              {selectedKeywords && selectedKeywords !== "" ? (
                <h5 className="fw-normal text-dark">{selectedKeywords}</h5>
              ) : (
                <h5 className="fw-normal text-danger">You don’t have any keyword selected. Please select to continue.</h5>
              )}
            </div>
          </div>
        </Col>
        <Col sm="12" md="9">
          <div className="optomizeCardBlock mt-0">
            <div className={classNames("card-header-content")}>
              <div className="first-block p-1" style={{ width: "40%" }}>
                <h6 className="fw-bold fs-6">Topic <br></br><span className="ms-0 fw-light fst-italic" style={{ fontSize: "14px" }}>(You can edit the Topic)</span></h6>
              </div>
              <div className="grayBox"></div>
              <div className="last-block">
                <h6 className="fw-bold fs-6">Explanation <br></br><span className="ms-0 ms-0 fw-light fst-italic" style={{ fontSize: "14px" }}>(You can edit the Explanation)</span></h6>
              </div>
            </div>
          </div>
          {topicList && topicList.length > 0 && topicList.map((item, index) => (
            <div key={index} className="optomizeCardBlock list-item-table">
              <div className={classNames(`opCard d-flex gap-3 mb-2 align-items-center list-group-item ${selectedTopic === item.topicName && "active"}`)}>
                <div className="radioTextBlock d-flex align-items-center gap-3 inputBoxField" style={{ width: "40%" }}>
                  <Form.Check
                    className="radioType difRadio"
                    type={"radio"} checked={selectedTopic === item.topicName}
                    onChange={(e) => handleTopicSelection(item.topicName)}
                  />
                  <textarea
                    name="topicName" className="w-100 list-item-textarea overflow-auto" rows={3}
                    value={item.topicName} onChange={(event) => handleChangeTopicValue(event, index)}
                  />
                </div>
                <div className="grayBox"></div>
                <div className="inputBoxField">
                  <textarea
                    name="explanation" className="w-100 list-item-textarea overflow-auto" rows={3}
                    value={item.explanation} onChange={(event) => handleChangeTopicValue(event, index)}
                  />
                </div>
              </div>
            </div>
          ))}
        </Col>
      </Row>
      <div className="d-flex align-items-start justify-content-center  align-items-center flex-row  gap-2 bottomButton">
        <button type="button" className="addlly-outline" onClick={() => setCurrStep(2)}>
          <span><BsArrowLeft /> Previous</span>
        </button>
        <button type="button" className="addlly-primary" onClick={() => setTopicSetPopup({ open: true, topic: selectedTopic, keyword: selectedKeywords })} disabled={selectedTopic === '' ? true : false}>
          <span>Continue <BsArrowRight /></span>
        </button>
      </div>
    </React.Fragment>
  );
}

const SkeletonLoader = ({ isLast = false }) => {
  return (
    <div className="optomizeCardBlock m-0 position-sticky top-0">
      {[1, 2, 3, 4, 5].map((ele) => (
        <div className={classNames("card-header-content placeholder-glow overflow-hidden mt-3 bg-transparent")} key={ele}>
          {isLast ? (
            <React.Fragment>
              <p className="last-block placeholder rounded" style={{ height: "24px" }}></p>
              <div className="grayBox"></div>
              <p className="first-block placeholder rounded" style={{ height: "24px" }}></p>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <p className="first-block placeholder rounded" style={{ height: "24px", width: "20%" }}></p>
              <p className="last-block placeholder rounded" style={{ height: "24px", width: "40%" }}></p>
              <p className="last-block placeholder rounded" style={{ height: "24px", width: "40%" }}></p>
            </React.Fragment>
          )}
        </div>
      ))}
    </div>
  )
};

const ShowQuestionAndKeywordItem = ({ item, itemNo, selectedTopics, selectedKeywords, onSelectQuestion }) => {
  const [keywords, setKeywords] = useState([]);

  useEffect(() => {
    if (item && item.keywords && Object.keys(item.keywords).length > 0) {
      const tempKeywordArray = [];
      for (let index = 0; index < Object.keys(item.keywords).length; index++) {
        const element = Object.keys(item.keywords)[index];
        if (element && item.keywords[element] && item.keywords[element].length > 0) {
          const findIndex = item.keywords[element].findIndex((ele) => ele.keyword === element.toLowerCase());
          if (findIndex !== -1) {
            tempKeywordArray.push(item.keywords[element][findIndex]);
          } else {
            tempKeywordArray.push(item.keywords[element][0]);
          }
        }
      }
      setKeywords([...tempKeywordArray]);
    } else {
      setKeywords([]);
    }
  }, [item])

  const handleSelectQuestion = () => {
    const questionList = selectedTopics && selectedTopics.length > 0 ? [...selectedTopics] : [];
    if (selectedTopics.includes(item.question)) {
      onSelectQuestion([...questionList.filter((topic) => topic !== item.question)], selectedKeywords);
    } else {
      if (questionList.length < 5) {
        questionList.push(item.question);
        onSelectQuestion([...new Set(questionList)], selectedKeywords);
      } else {
        toast.error("You can select upto only 5 question!");
      }
    }
  };

  return (
    <ListGroup horizontal={"sm"}>
      <ListGroup.Item className="listGroup text-center">
        <h5 className="fw-normal w-100">{itemNo}</h5>
      </ListGroup.Item>
      <ListGroup.Item className={`listGroup listHover ${selectedTopics && selectedTopics.includes(item.question) ? "active" : ""}`} onClick={handleSelectQuestion}>
        {item.question}
      </ListGroup.Item>
      <ListGroup.Item
        className={`listGroup listHover flex-column align-items-start ${keywords.length > 0 && selectedKeywords === keywords.map((e) => e.keyword).join(", ") ? "active" : ""}`}
        onClick={() => onSelectQuestion(selectedTopics, keywords.map((e) => e.keyword).join(", "))}
      >
        {keywords && keywords.length > 0 ? keywords.map((keyword) => (
          <h6 className={`d-flex w-100 mb-2 list-keyword`}>
            {keyword.keyword} <b className="ms-auto">{keyword.volume}</b>
          </h6>
        )) : "-"}
      </ListGroup.Item>
    </ListGroup>
  )
};

export default TopicSuggestions;
