import React, { memo, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { ReactSVG } from "react-svg";

const Navigations = ({ navigationsOption, button }) => {
  const ref = useRef();
  const [filterSearch, setFilterSearch] = useState(false);

  const handleFilterSearch = () => {
    setFilterSearch(!filterSearch);
  };

  useEffect(() => {
    const handleClick = (event) => {
      event.stopPropagation();
      if (ref.current && !ref.current.contains(event.target)) {
        if (!event.target.closest(".editIcon")) {
          setFilterSearch(false);
        }
      }
    };
    document.addEventListener("click", handleClick);
    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, [ref]);

  return (
    <div ref={ref} className="editIcon position-relative">
      <div className="click" onClick={handleFilterSearch}>
        {button}
      </div>
      {filterSearch ? (
        <div className="dropDown ">
          <ul className="m-0 bg-white shadow rounded">
            {navigationsOption.map(({ name, image, to }, index) => (
              <li key={index + name} className="item">
                <Link onClick={handleFilterSearch} to={to} className="d-flex align-items-center">
                  {/* <img src={image} alt="iconsAlt" /> */}
                  <ReactSVG src={image} />
                  {name}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      ) : null}
    </div>
  );
};
export default memo(Navigations);
