import React, { useEffect, useMemo, useState } from "react";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { Col, Row, Modal } from "react-bootstrap";
import { setLoader, setSocialMediaPostStep, setSocialMediaStepsInfo, setUserRes } from "../../store/reducer";
import { toast } from "react-hot-toast";
import { invokeAxiosGet, invokeAxiosPost } from "../../utility/invokeAxiosFunction";
import { TypeAnimation } from "react-type-animation";
import { BsArrowLeft, BsArrowRight, BsInfoCircleFill, BsSignStop, BsStars } from "react-icons/bs";
import DropdownMenu from "../ui/Dropdown";
let isStopGeneratePost = false;

const validationSchema = Yup.object().shape({
  topic: Yup.string().required("Topic is required").min(2, "Topic must be at least 2 characters").matches(/^(?!\s)(?!.*\s{2})[^\s](.*[^\s])?$/, "Topic should not contain multiple consecutive white spaces"),
  keyword: Yup.string().required("Keyword is required").trim("white-space", "Keyword should not contain white spaces").matches(/^(?!\s)(?!.*\s{2})[^\s](.*[^\s])?$/, "Keyword should not contain multiple consecutive white spaces"),
});

const ThirdStepSocialMediaPost = () => {
  const dispatch = useDispatch();

  const userRes = useSelector(({ userRes }) => userRes);
  const socialMediaPostInfo = useSelector(({ socialMediaPostInfo }) => socialMediaPostInfo);

  const [data, setData] = useState([]);
  const [setsOfPost, setSetsOfPost] = useState([]);
  const [oldSetsOfPost, setOldSetsOfPost] = useState([]);
  const [selectedPost, setSelectedPost] = useState({ item: "", index: null });
  const [isPostGenerateLoader, setIsPostGenerateLoader] = useState({ isLoader: false, posts: [], generatePost: 0 });
  const [isPostTyping, setIsPostTyping] = useState({ 0: false, 1: false, 2: false });
  const [previousKeyword, setPreviousKeyword] = useState(socialMediaPostInfo.keyword);
  const [showFeedbackModel, setShowFeedbackModel] = useState({ open: false, feedback: "" });
  const articleType = "Social Media Post Writer";

  const { register, handleSubmit, getValues, watch } = useForm({
    defaultValues: { topic: socialMediaPostInfo?.topic, keyword: socialMediaPostInfo?.keyword },
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    if (socialMediaPostInfo && socialMediaPostInfo.isCallApi && socialMediaPostInfo.isCallApi === "true") {
      const topic = socialMediaPostInfo?.topic ? socialMediaPostInfo.topic : "";
      const keyword = socialMediaPostInfo?.keyword ? socialMediaPostInfo.keyword : "";
      handleGeneratePostOneByOne(topic, keyword, 1, [], socialMediaPostInfo);
    } else if (
      socialMediaPostInfo && socialMediaPostInfo.SetsOfPost && typeof JSON.parse(socialMediaPostInfo.SetsOfPost) !== typeof undefined
    ) {
      const SetsOfPostArray = JSON.parse(socialMediaPostInfo.SetsOfPost);
      setSetsOfPost(SetsOfPostArray);
      handleSetPostGenerateLoader(SetsOfPostArray.length);

      if (socialMediaPostInfo.selected_set_post && socialMediaPostInfo.selected_set_post !== "") {
        const findIndex = SetsOfPostArray.findIndex((post) => post === socialMediaPostInfo.selected_set_post);
        if (findIndex !== -1) { setSelectedPost({ item: socialMediaPostInfo.selected_set_post, index: findIndex }); }
      }
    } else if (socialMediaPostInfo && !socialMediaPostInfo.SetsOfPost) {
      setIsPostGenerateLoader({ isLoader: true, posts: [1, 2, 3], generatePost: 0 });
    }
  }, []);

  useEffect(() => {
    const applyTooltipDataAttributes = (tooltipSpan) => {
      if (tooltipSpan) {
        tooltipSpan.setAttribute('data-tooltip-id', 'my-tooltip');
        tooltipSpan.setAttribute('data-tooltip-place', 'bottom-end');
        tooltipSpan.setAttribute('data-tooltip-content', `Re-generation will utilize ${userRes?.deductCredits?.["Social Media Post"] || 1} Addlly credit`);
      }
    };
    setTimeout(() => {
      const tooltipSpans = document.querySelectorAll('.tooltip-span');
      tooltipSpans.forEach(applyTooltipDataAttributes);
    }, 0);
  }, [setsOfPost.length, isPostGenerateLoader, isPostGenerateLoader?.generatePost]);

  const handleGeneratePostOneByOne = async (topic, keyword, index, beforeGeneratedPosts, socialMediaPostInfoObj) => {
    if (index === 1) {
      if (Number(userRes.credits) < (userRes?.deductCredits?.["Social Media Post"] || 1)) {
        toast.error("Sorry, you don't have enough credits to perform this action.", { id: "Toast-01" });
        return;
      }
    }

    if (index > 3) { return false; }

    if (index === 1) {
      setIsPostGenerateLoader({ isLoader: true, posts: [1, 2, 3], generatePost: 1 });
    } else if (index === 2) {
      setIsPostGenerateLoader({ isLoader: true, posts: [2, 3], generatePost: 2 });
    } else if (index === 3) {
      setIsPostGenerateLoader({ isLoader: true, posts: [3], generatePost: 3 });
    }

    dispatch(setSocialMediaStepsInfo({ ...socialMediaPostInfoObj, isCallApi: "false" }));
    let generatedPosts = [...beforeGeneratedPosts];
    const payload = {
      topic, keyword, currIndex: index, generatedPosts, feedback: showFeedbackModel?.feedback || "",
      isDeductCredit: index === 1 && socialMediaPostInfoObj?.isDeductCredit ? true : false,
      postId: socialMediaPostInfo?.postId ? socialMediaPostInfo?.postId : "",
    };

    const response = await invokeAxiosPost("/social-media-post/get-or-generate/generateSinglePostOfSocialMediaPost", payload);
    if (response && response.success === 1) {
      if (response.data && response.data.currentPost && response.data.currentPost !== "") {
        generatedPosts = response.data?.generatedPostsObj ? [...response.data.generatedPostsObj] : generatedPosts;
        setSetsOfPost((prev) => [...prev, response.data?.currentPost]);
        setIsPostTyping((prev) => ({ ...prev, [Number(index) - 1]: true }));
        const socialMediaPostInfoObj1 = { ...socialMediaPostInfoObj, SetsOfPost: JSON.stringify(generatedPosts), isCallApi: "false" };
        dispatch(setSocialMediaStepsInfo(socialMediaPostInfoObj1));

        if (index === 1 && socialMediaPostInfoObj?.isDeductCredit) {
          dispatch(setUserRes({ ...userRes, credits: userRes.credits - (userRes?.deductCredits?.["Social Media Post"] || 1) }));
        }

        if (index !== 3 && !isStopGeneratePost) {
          const topic = getValues("topic") !== "" ? getValues("topic") : socialMediaPostInfo?.topic;
          const keyword = getValues("keyword") !== "" ? getValues("keyword") : socialMediaPostInfo?.keyword;
          handleGeneratePostOneByOne(topic, keyword, index + 1, generatedPosts, socialMediaPostInfoObj1);
        } else {
          handleSetPostGenerateLoader(generatedPosts.length);
          getPreviousGeneratedList();
        }

        if (index === 3) {
          getPreviousGeneratedList();
        }
      } else {
        toast.error("Generating post is same as previous post please try again.", { id: "Toast-01" });
        handleSetPostGenerateLoader(generatedPosts.length);
      }
    } else {
      toast.error(response?.error || "Server is overloaded, try again after sometime.", { id: "Toast-01" });
      handleSetPostGenerateLoader(generatedPosts.length);
    }
  }

  const handleSetPostGenerateLoader = (postLength) => {
    if (postLength === 0) {
      setIsPostGenerateLoader({ isLoader: true, posts: [1, 2, 3], generatePost: 0 });
    } else if (postLength === 1) {
      setIsPostGenerateLoader({ isLoader: true, posts: [2, 3], generatePost: 0 });
    } else if (postLength === 2) {
      setIsPostGenerateLoader({ isLoader: true, posts: [3], generatePost: 0 });
    } else {
      setIsPostGenerateLoader({ isLoader: false, posts: [], generatePost: 0 });
    }
  }

  const handleNext = async () => {
    if (selectedPost.item && selectedPost.item !== "") {
      if (socialMediaPostInfo?.oldHashtags && socialMediaPostInfo.oldHashtags.length >= 15) {
        dispatch(setSocialMediaPostStep(5));
        return;
      }

      dispatch(setLoader(true));
      const payload = {
        postId: socialMediaPostInfo?.postId,
        selectedPost: selectedPost.item,
        oldHashtags: socialMediaPostInfo?.oldHashtags || [],
        isGenerateHashtag: socialMediaPostInfo?.oldHashtags && socialMediaPostInfo.oldHashtags.length > 0 ? false : true,
      };
      const response = await invokeAxiosPost("/social-media-post/get-or-generate/generateSocialMediaPostHashTags", payload);
      dispatch(setLoader(false));
      if (response && response.success === 1) {
        dispatch(setSocialMediaStepsInfo({
          ...socialMediaPostInfo, selectedPost: selectedPost.item, ...response.data,
          stepDone: socialMediaPostInfo.stepDone < 5 ? 5 : socialMediaPostInfo.stepDone,
          selected_set_post: selectedPost.item,
        }));
        dispatch(setSocialMediaPostStep(5));
      } else {
        toast.error(response?.error, { id: "Toast-01" });
      }
    } else {
      toast.error("Please Select Any One Post.", { id: "Toast-01" });
    }
  }

  const regenerateSetsOfPost = async (data) => {
    if (getValues("topic") !== "" && getValues("keyword") !== "") {
      if (Number(userRes.credits) < (userRes?.deductCredits?.["Social Media Post"] || 1)) {
        toast.error("Sorry, you don't have enough credits to perform this action.", { id: "Toast-01" });
        return;
      }

      isStopGeneratePost = false;
      setSetsOfPost([]);
      setOldSetsOfPost([]);
      const socialMediaPostInfoObj = { ...socialMediaPostInfo, SetsOfPost: null, isDeductCredit: true };
      dispatch(setSocialMediaStepsInfo(socialMediaPostInfoObj));
      const topic = getValues("topic") !== "" ? getValues("topic") : socialMediaPostInfo?.topic;
      const keyword = getValues("keyword") !== "" ? getValues("keyword") : socialMediaPostInfo?.keyword;
      handleGeneratePostOneByOne(topic, keyword, 1, [], socialMediaPostInfoObj);
      setPreviousKeyword(() => getValues('keyword'))
    } else {
      toast.error("Topic or keyword field is required.", { id: "Toast-01" });
      return false;
    }
  }

  const handleGenerateSinglePost = async (ele) => {
    isStopGeneratePost = false;
    const topic = getValues("topic") !== "" ? getValues("topic") : socialMediaPostInfo?.topic;
    const keyword = getValues("keyword") !== "" ? getValues("keyword") : socialMediaPostInfo?.keyword;
    handleGeneratePostOneByOne(topic, keyword, ele, setsOfPost, socialMediaPostInfo);
  }

  const handleSetOldGeneratedPost = (posts) => {
    const filterPost = data.filter((i) => i.id === posts.id)[0].content
    setOldSetsOfPost([...JSON.parse(filterPost)]);
  }

  const handleStopGenerateSocialPost = () => {
    isStopGeneratePost = true;
    dispatch(setSocialMediaStepsInfo({ ...socialMediaPostInfo, isCallApi: "false" }));
  }

  useEffect(() => {
    if (socialMediaPostInfo?.postId && articleType) {
      getPreviousGeneratedList();
    }
  }, [])

  const getPreviousGeneratedList = async () => {
    try {
      const params = `articleId=${socialMediaPostInfo?.postId}&type=${articleType}&subType=${`Generate ${socialMediaPostInfo?.PostType} Post`}`;
      const res = await invokeAxiosGet(`/analytics-api/get-summary/getPreviousGeneratedContent?userId=${userRes.id}&${params}`);
      if (res && res.data && res.data.data && res.data.data.length > 0) {
        setData(res.data.data);
      }
    } catch (error) {
    }
  };

  const versions = useMemo(() => {
    const newDropVersion = [];
    for (let index = 0; index < data.length; index++) {
      const dataId = data[index].id;
      newDropVersion.push({ name: `Generation ${index > 8 ? '' : '0'}${index + 1}`, value: `version${index + 1}`, id: dataId });
    }
    return newDropVersion;
  }, [data]);

  return (
    <React.Fragment>
      <form onSubmit={handleSubmit(regenerateSetsOfPost)} className="addllyFormWrap">
        <div className="selectBlock flex-wrap gap-3 d-flex align-items-center justify-content-between mx-2 mx-md-auto">
          <div>
            <b>Select any one post from the generated post </b>
          </div>
          <div className="nxtGenBtn flex-wrap gap-3 d-flex align-items-center align-items-center gap-2">
            <button
              type="button" className="d-flex align-items-center gap-2 bg-transparent border-0 selectBbutton "
              onClick={() => {
                const scrollWrapper = document.getElementById('scroll-wrapper');
                if (scrollWrapper) { scrollWrapper.scrollTo({ top: 0, behavior: 'smooth' }); }
                if (socialMediaPostInfo && socialMediaPostInfo.top10WebRes && socialMediaPostInfo.top10WebRes.length > 0) {
                  dispatch(setSocialMediaPostStep(3));
                } else {
                  dispatch(setSocialMediaPostStep(2));
                }
              }}
            >
              <BsArrowLeft /> Previous
            </button>
            <span style={{ opacity: 0.1 }}>|</span>
            <button
              type="button" onClick={() => handleNext()} className="d-flex align-items-center gap-2 bg-transparent border-0 selectBbutton"
              disabled={selectedPost.item && selectedPost.item !== "" ? false : true}
            >
              Select Hashtags <BsArrowRight />
            </button>
          </div>
        </div>

        <div className="postDetails">
          <div className="textA">
            <p>Post Description</p>
            <span name="topic" type="text" {...register("topic")} >{socialMediaPostInfo.topic}</span>
          </div>
          <div className="textA">
            <p>Post hashtags
              <BsInfoCircleFill
                className="infoIconSvg" data-tooltip-id="my-tooltip"
                data-tooltip-place="right" data-tooltip-content={`You can edit the Hashtag`}
              />
            </p>
            <input name="keyword" type="text" {...register("keyword")} className=" w-100 border-0" />
          </div>
        </div>
      </form>

      <div className="p-32">
        <div className="buttonsBlock d-flex gap-2 align-items-center justify-content-end mt-3 mb-3">
          {isPostGenerateLoader && (!isPostGenerateLoader.isLoader || !isPostGenerateLoader.generatePost) && versions && versions.length > 0 && (
            <div className="dropdown">
              <DropdownMenu onSelect={handleSetOldGeneratedPost} label={`name`} placeholder={'Generations'} options={versions} showSelectedCount={true} />
            </div>
          )}
          {setsOfPost && setsOfPost.length > 0 && isPostGenerateLoader && isPostGenerateLoader.generatePost === 0 && (
            <React.Fragment>
              <button type="button" className="blogButton border-0" onClick={() => setShowFeedbackModel({ open: true })}>
                <BsStars /> <span>Re-Generate Posts</span>
              </button>
              <BsInfoCircleFill className="infoIconSvg tooltip-span" />
            </React.Fragment>
          )}
          {(
            isPostGenerateLoader && isPostGenerateLoader.isLoader && isPostGenerateLoader.generatePost !== 0 &&
            !isStopGeneratePost && isPostGenerateLoader.generatePost !== 3
          ) && (
              <button type="button" onClick={handleStopGenerateSocialPost} className="blogButton border-0">
                <span>Stop Generation </span> <BsSignStop className="fs-5" />
              </button>
            )}
        </div>
        <Row>
          {setsOfPost.map((item, index) => (
            <Col sm={12} md={6} lg={4} key={index} className="mb-3">
              <div className="optionHeading text-start d-flex justify-content-between gap-2 align-items-center">
                Option {index + 1}
              </div>
              {isPostTyping && isPostTyping[index] ? (
                <TypeAnimation
                  sequence={[item, () => setIsPostTyping((prev) => ({ ...prev, [index]: false }))]}
                  className="custom-type-writer m-0 fw-normal" wrapper="pre" speed={100} repeat={0}
                  style={{ height: "40vh", padding: "12px" }}
                />
              ) : (
                <div className={`media-post-content ${selectedPost.index === index ? "active" : ""}`}
                  style={{ height: "40vh" }}
                  onClick={() => setSelectedPost({ item, index })}
                  onKeyDown={() => setSelectedPost({ item, index })}
                >
                  <input type="checkbox" checked={selectedPost.index === index} />
                  <pre>{item}</pre>
                </div>
              )}
            </Col>
          ))}

          {isPostGenerateLoader && isPostGenerateLoader.isLoader && isPostGenerateLoader?.posts?.length > 0 && (
            <React.Fragment>
              {isPostGenerateLoader.posts.map((ele, index) => (
                <Col sm={12} md={6} lg={4} key={ele} className="mb-0">
                  <div className="optionHeading text-start d-flex justify-content-between gap-2 align-items-center">
                    Option {ele}
                    {isPostGenerateLoader.generatePost !== ele && (
                      <button
                        type="button" className="border-0 bg-transparent" onClick={() => handleGenerateSinglePost(ele)}
                        disabled={isPostGenerateLoader.generatePost || setsOfPost.length + 1 < ele ? true : false}
                      >
                        <BsStars /> Generate
                      </button>
                    )}
                  </div>
                  <div className={`media-post-content overflow-hidden`} style={{ height: "40vh" }}>
                    {isPostGenerateLoader && isPostGenerateLoader.generatePost === ele && (
                      <p className="placeholder-glow overflow-hidden">
                        {[1, 2, 3, 4].map((ele1) => (<div className="my-4" key={ele1}>
                          {[11, 12, 13].map((ele2) => (
                            <p className="placeholder rounded col-12 mb-2" key={ele2}></p>
                          ))}
                        </div>))}
                      </p>
                    )}
                  </div>
                </Col>
              ))}
            </React.Fragment>
          )}

          {oldSetsOfPost && oldSetsOfPost.length > 0 && oldSetsOfPost.map((item, index) => (
            <Col sm={12} md={6} lg={4} key={index} className="mt-4">
              <div className="optionHeading text-start d-flex justify-content-between gap-2 align-items-center">
                Option {index + 4}
              </div>
              <div className={`media-post-content ${selectedPost.index === index + 3 ? "active" : ""}`}
                style={{ height: "40vh" }}
                onClick={() => setSelectedPost({ item, index: index + 3 })}
                onKeyDown={() => setSelectedPost({ item, index: index + 3 })}
              >
                <pre>{item}</pre>
              </div>
            </Col>
          ))}
        </Row>

        <div className="d-flex align-items-start justify-content-center  align-items-center flex-row  gap-2 bottomButton">
          <button type="button" className="addlly-outline "
            onClick={() => {
              const scrollWrapper = document.getElementById('scroll-wrapper');
              if (scrollWrapper) { scrollWrapper.scrollTo({ top: 0, behavior: 'smooth' }); }
              if (socialMediaPostInfo && socialMediaPostInfo.top10WebRes && socialMediaPostInfo.top10WebRes.length > 0) {
                dispatch(setSocialMediaPostStep(3));
              } else {
                dispatch(setSocialMediaPostStep(2));
              }
            }}
          >
            <span><BsArrowLeft /> Previous</span>
          </button>
          <div className='d-flex align-items-center '>
            <button
              type="button" onClick={() => handleNext()} className="addlly-primary" style={{ whiteSpace: "nowrap" }}
              disabled={selectedPost.item && selectedPost.item !== "" ? false : true}
            >
              <span>Select Hashtags <BsArrowRight /></span>
            </button>
            <BsInfoCircleFill
              className="infoIconSvg" data-tooltip-id="my-tooltip"
              data-tooltip-place="right" data-tooltip-content={`Select any one post to continue`}
            />
          </div>
        </div>
      </div>

      {showFeedbackModel && showFeedbackModel.open && (
        <Modal size="lg" centered show={showFeedbackModel.open} onHide={() => setShowFeedbackModel({ open: false })}>
          <Modal.Body className="text-start p-0 overflow-auto" style={showFeedbackModel.article ? { height: "90vh" } : {}}>
            <div className="maingenrateBlock m-0 p-3 h-100">
              <div className="d-flex align-items-center justify-content-between">
                <h4>What would you like to change?</h4>
                <button type="button" className="btn btn-primary" onClick={() => { regenerateSetsOfPost(); setShowFeedbackModel({ open: false }); }}>
                  <BsStars /> Re-Generate
                </button>
              </div>
              <div className="genrateFields mt-3">
                <div className="fields m-0">
                  <label>Customize your content before hitting the 'Re-Generate' button. </label>
                  <textarea
                    name="feedback" type="text" rows="3" placeholder="Insert feedback ..."
                    className={`addlly-textarea w-100`} value={showFeedbackModel?.feedback}
                    onChange={(e) => setShowFeedbackModel({ ...showFeedbackModel, feedback: e.target.value })}
                  />
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      )}
    </React.Fragment>
  )
}

export default ThirdStepSocialMediaPost;
