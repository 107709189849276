import React, { useEffect, useState } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { setSeoOptBlogTopic, setSeoOptBlogStep } from "../../store/reducer";
import { BsArrowRight } from "react-icons/bs";
import { blogCoPilotIcon } from "../../Assets/Icons";
import { ReactSVG } from "react-svg";
import classNames from "classnames";

const FirstStepSeoOptBlog = ({ handleGetArticleById }) => {
  const dispatch = useDispatch();

  const seoOptBlogTopic = useSelector(({ seoOptBlogTopic }) => seoOptBlogTopic);
  const [selectedCard, setSelectedCard] = useState(seoOptBlogTopic?.selectedCard || seoOptBlogTopic?.blog_type);

  useEffect(() => {
    setSelectedCard(seoOptBlogTopic?.selectedCard || seoOptBlogTopic?.blog_type);
  }, [seoOptBlogTopic?.selectedCard, seoOptBlogTopic?.blog_type])

  const handleOnChangeCardType = (type) => {
    if (selectedCard === type) {
      return false;
    }

    let generateLink = true;
    if (type === "Pdf") {
      generateLink = false;
    }
    dispatch(setSeoOptBlogTopic({ ...seoOptBlogTopic, selectedCard: type, generateLink: generateLink ? 1 : 0 }));
  }

  return (
    <React.Fragment>
      <div className="selectBlock mx-2 mx-md-auto">
        <b>Embark on your blogging journey</b> <span className="">(Select any 1)</span>
      </div>
      <div className="addllyFormWrap">
        <Row className="cards">
          {[
            {
              label: <span>Generate a Blog <strong>using Topic</strong> </span>, value: "Topic", icon: blogCoPilotIcon.blogIcon, iconColor: "#0000ff",
              desc: "Describe in one sentence the topic you’d like to write about.", backG: '#E6EBFF'
            },
            {
              label: <span>Generate a Blog <strong>using Summary</strong></span>, value: "Summary", icon: blogCoPilotIcon.contractIcon, iconColor: "#aa00ff",
              desc: "Describe in multiple sentences, bullet points, or quotes the topic you’d like to write about.", backG: 'rgba(255, 190, 157, 0.20)'
            },
            {
              label: <span>Generate a Blog <strong>using PDF</strong></span>, value: "Pdf", icon: blogCoPilotIcon.pdfIcon, iconColor: "#00aaff",
              desc: "Use a PDF file as a reference for the topic you’d like to write about.", backG: 'rgba(167, 255, 23, 0.20)'
            },
          ].map((ele, index) => (
            <Col xxl={4} xl={4} lg={4} md={6} sm={6} className="mb-3" key={index}>
              <Card className={`custom-select-card ${selectedCard === ele.value ? "active" : ""} ${seoOptBlogTopic.stepDone > 1 ? "not-selectable" : ""}`}
                onClick={(e) => { e.preventDefault(); handleOnChangeCardType(ele.value) }} >
                <Card.Body className="text-center p-0 ">
                  <div className="radioButtonB">
                    <Form.Check type={"radio"} className="radioType" checked={selectedCard === ele.value}
                      // disabled = {seoOptBlogTopic.stepDone > 1}
                      onChange={(e) => { e.preventDefault(); handleOnChangeCardType(ele.value) }} />
                  </div>
                  <div className={classNames("card-icon-content mb-4")} style={{ backgroundColor: ele.backG }}>
                    {/* <ele.icon className="" style={{ color: ele.iconColor }} /> */}
                    <ReactSVG src={ele.icon} />
                  </div>
                  <h5>{ele.label}</h5>
                  <Card.Text className="text-center">{ele.desc}</Card.Text>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
        <Col className="buttonBlock_ mt-3">
          <Button className="m-auto w-25 startWriting" onClick={() => {
            dispatch(setSeoOptBlogStep(2));
            // dispatch(setSeoOptBlogTopic({
            //   ...seoOptBlogTopic,
            //   stepDone: 2
            // }));
          }} disabled={!selectedCard}>
            Start Writing <BsArrowRight />
          </Button>
        </Col>
      </div>
    </React.Fragment>
  );
};

export default FirstStepSeoOptBlog;
