import React, { useEffect, useMemo, useRef, useState } from "react";
import { fabric } from "fabric";
import Select from "react-select";
import classNames from "classnames";
import toast from "react-hot-toast";
import { Col, Modal, Row } from "react-bootstrap";
import { FileUploader } from "react-drag-drop-files";
import { useDispatch, useSelector } from "react-redux";
import { MdClose } from "react-icons/md";
import { LuFileAudio, LuMonitorPlay } from "react-icons/lu";
import { IoImagesOutline } from "react-icons/io5";
import { FaMinus, FaPlus } from "react-icons/fa6";
import { BiSolidErrorCircle } from "react-icons/bi";
import { FaBold, FaItalic, FaUnderline } from "react-icons/fa";
import { BsCloudUploadFill, BsImage, BsInfoCircleFill, BsStars } from "react-icons/bs";
import { PiArrowSquareDownFill, PiArrowSquareUpFill, PiTextTBold } from "react-icons/pi";
import { sorting } from "../../utility/common";
import { setLoader, setUserRes } from "../../store/reducer";
import { invokeAxiosGet, invokeAxiosPost } from "../../utility/invokeAxiosFunction";

const AgeGroupOptions = [
  { label: "18 - 24", value: "18 - 24" },
  { label: "25 - 34", value: "25 - 34" },
  { label: "35 - 44", value: "35 - 44" },
  { label: "45 +", value: "45 +" },
];

const ContentTonesOptions = [
  { label: "Professional", value: "Professional" },
  { label: "Casual", value: "Casual" },
  { label: "Straightforward", value: "Straightforward" },
  { label: "Confident", value: "Confident" },
  { label: "Friendly", value: "Friendly" },
];

const FontFamilyOptions = [
  { label: "Arial", value: "Arial" },
  { label: "Courier", value: "Courier" },
  { label: "Courier New", value: "Courier New" },
  { label: "Consolas", value: "Consolas" },
  { label: "Georgia", value: "Georgia" },
  { label: "Helvetica", value: "Helvetica" },
  { label: "Inter", value: "Inter" },
  { label: "Lucida Console", value: "Lucida Console" },
  { label: "Monaco", value: "Monaco" },
  { label: "Monospace", value: "Monospace" },
  { label: "Sans-serif", value: "Sans-serif" },
  { label: "Serif", value: "Serif" },
  { label: "Tahoma", value: "Tahoma" },
  { label: "Times", value: "Times" },
  { label: "Times New Roman", value: "Times New Roman" },
  { label: "Trebuchet MS", value: "Trebuchet MS" },
  { label: "Verdana", value: "Verdana" }
];

const InstagramReelVideoComponent = (props) => {
  const { articleType, articleId } = props;

  const dispatch = useDispatch();
  const fabricRef = useRef();
  const canvasRef = useRef();
  const audioRef = useRef();
  const userRes = useSelector(({ userRes }) => userRes);

  const [instagramReelInfo, setInstagramReelInfo] = useState(null);
  const [reelsData, setReelsData] = useState([]);
  const [versionHistory, setVersionHistory] = useState([]);
  const [versionOptions, setVersionOptions] = useState([]);
  const [selectedReelObj, setSelectedReelObj] = useState({});
  const [accordionState, setAccordionState] = useState({});
  const [ageGroup, setAgeGroup] = useState("");
  const [contentTone, setContentTone] = useState("");
  const [activeTab, setActiveTab] = useState("Images");
  const [isDisabledText, setIsDisabledText] = useState(true);
  const [textInfo, setTextInfo] = useState({
    fill: "#ffffff", bold: false, italic: false, underline: false,
    fontFamily: "Inter", fontSize: 24,
  });
  const [audioSrc, setAudioSrc] = useState("");
  const [userAudioSrc, setUserAudioSrc] = useState("");
  const [changeImgLoader, setChangeImgLoader] = useState(false);
  const [videoModal, setVideoModal] = useState({ open: false, video: "" });

  useEffect(() => {
    if (!instagramReelInfo) {
      handleGetInstagramReelDetails();
    }
  }, []);

  const handleGetInstagramReelDetails = () => {
    dispatch(setLoader(true));
    const apiUrl = "/instagram-reel/get-or-generate/getInstagramReel?articleType=get-instagram-reel";
    invokeAxiosGet(`${apiUrl}?articleId=${articleId}&articleType=${articleType}`).then((res) => {
      dispatch(setLoader(false));
      const resData = res.data ? res.data : {};
      if (resData && resData.scripts && resData.scripts.length > 0) {
        setInstagramReelInfo(resData);
        setSelectedReelObj({ open: true, reel: resData.scripts || [] });
        handleCheckOrGenerateReelAudio(resData.selectedReel, resData.id);
        handleSelectImageForEditText(resData.scripts, 0);
      } else if (resData && resData.id) {
        setInstagramReelInfo(resData);
        handleGetInstagramReelVersionHistory();
        handleSetInstagramReelsData(resData.reels, resData.id, false);
      }
    }).catch((e) => {
      dispatch(setLoader(false));
    });
  };

  const handleSetInstagramReelsData = (instagramReels, reelId, isRegenerate = false) => {
    if (instagramReels) {
      if (!isRegenerate && reelsData.length > 0) {
        return false;
      }

      try {
        const reelsObj = typeof instagramReels === "string" ? JSON.parse(instagramReels) : instagramReels;
        if (reelsObj && reelsObj.length > 0) {
          setReelsData(reelsObj);
          setAccordionState((prev) => ({ ...prev, [reelsObj[0].title]: true }));
          if (reelsObj.length > 0) {
            handleGenerateAiImageForReels(reelsObj, reelId);
          }
        }
      } catch (error) {
        console.log("#### error --->", error);
      }
    }
  };

  const handleGetInstagramReelVersionHistory = async () => {
    try {
      setVersionHistory([]); setVersionOptions([]);
      const params = `articleId=${articleId}&type=Instagram Reels Writer&subType=Instagram Reels&isVersionList=true`;
      const res = await invokeAxiosGet(`/analytics-api/get-summary/getPreviousGeneratedContent?userId=${userRes.id}&${params}`);
      if (res && res.data && res.data.data && res.data.data.length > 0) {
        setVersionHistory(res.data.data);

        const versionOptions = [];
        const sortedValue = await sorting(res.data.data, "name", "asc");
        for (let index = 0; index < sortedValue.length; index++) {
          const element = sortedValue[index];
          if (element && element.is_regenerated) {
            versionOptions.push({ label: `Version ${versionOptions.length + 1}`, value: element.name });
          }
        }

        if (versionOptions && versionOptions.length > 0) {
          versionOptions.push({ label: "Current Version", value: "Current Version" });
        }

        setVersionOptions([...versionOptions]);
      }
      dispatch(setLoader(false));
    } catch (error) {
      console.log("#### error --->", error);
      dispatch(setLoader(false));
    }
  };

  const handleChangeInstagramReelVersion = (val) => {
    try {
      let reelsObj = null;
      const getVersion = versionHistory.find((version) => version.name === val);
      if (getVersion && getVersion.old_article) {
        reelsObj = JSON.parse(getVersion.old_article);
      } else if (val === "Current Version") {
        reelsObj = JSON.parse(instagramReelInfo.reels);
      }

      if (reelsObj && reelsObj.length > 0) {
        setReelsData(reelsObj);
        setAccordionState((prev) => ({ ...prev, [reelsObj[0].title]: true }));
      }
    } catch (error) {
      console.log("#### error --->", error);
    }
  };

  const handleGenerateAiImageForReels = async (reelsArray, reelId) => {
    let generateImageArray = [];
    if (reelsArray && reelsArray.length > 0) {
      for (let index = 0; index < reelsArray.length; index++) {
        const element = reelsArray[index];
        if (!element.isGeneratedImg || element.isGeneratedImg === "") {
          generateImageArray.push(element);
        }
      }
    }

    if (generateImageArray.length === 0) { return false; }
    if (!reelId || reelId <= 0) { return false; }

    for (let index = 0; index < generateImageArray.length; index++) {
      const element = generateImageArray[index];
      const payload = { reelTitle: element.title };

      const response = await invokeAxiosPost(`/media-library/get-or-generate/generateAiImagesForInstagramReels?articleId=${reelId || 0}`, payload);
      if (response && response.success === 1 && response.data && response.data.reels) {
        setReelsData(response.data.reels);
      } else {
      }
    }
  };

  const handleGenerateInstagramReels = async (isRegenerate) => {
    try {
      if (Number(userRes.credits) < (userRes?.deductCredits?.["Social Media Post"] || 1)) {
        toast.error("Sorry, you don't have enough credits to perform this action.", { id: "Toast-01" });
        return false;
      }

      dispatch(setLoader(true));
      const payload = {
        articleId, articleType, ageGroup: ageGroup || "", contentTone: contentTone || "",
        reelId: instagramReelInfo && instagramReelInfo.id ? instagramReelInfo.id : 0,
      };

      const response = await invokeAxiosPost(`/instagram-reel/get-or-generate/generateInstagramReel?type=generate-instagram-reel`, payload);
      dispatch(setLoader(false));
      if (response && response.success === 1 && response.data) {
        const reels = response.data.reels;
        if (reels && reels !== "") {
          handleSetInstagramReelsData(reels, response.data.id, isRegenerate);
        }

        setInstagramReelInfo(response.data);
        handleGetInstagramReelVersionHistory();
        dispatch(setUserRes({ ...userRes, credits: userRes.credits - (userRes?.deductCredits?.["Social Media Post"] || 1) }));
      } else {
        toast.error("Reels not generated, Please try again.", { id: "Toast-01" });
      }
    } catch (error) {
      dispatch(setLoader(false));
      toast.error("Reels not generated, Please try again.", { id: "Toast-01" });
    }
  };

  const handleCheckOrGenerateReelAudio = async (selectReel, reelId) => {
    if (selectReel && selectReel.title && selectReel.script && selectReel.script.length > 0) {
      let isGenerateAudio = false;
      for (let index = 0; index < selectReel.script.length; index++) {
        const element = selectReel.script[index];
        if (!element.audio || element.audio === "") {
          isGenerateAudio = true;
          break;
        }
      }

      if (!isGenerateAudio) {
        return true;
      }

      try {
        dispatch(setLoader(true));
        const payload = { selectedReel: selectReel, reelId: reelId };

        const response = await invokeAxiosPost(`/instagram-reel/get-or-generate/selectInstagramReel?type=select-instagram-reel`, payload);
        dispatch(setLoader(false));
        if (response && response.success === 1 && response.data && response.data.scripts && response.data.scripts.length > 0) {
          setSelectedReelObj({ open: true, reel: response.data.scripts || [] });
          handleSelectImageForEditText(response.data.scripts, 0);
        } else {
          toast.error("Something wen wrong, Please try again!", { id: "Toast-01" });
        }
      } catch (error) {
        dispatch(setLoader(false));
        toast.error("Something wen wrong, Please try again!", { id: "Toast-01" });
      }
    }
  };

  const handleSelectInstagramReel = async (event, reel) => {
    event.stopPropagation();
    event.preventDefault();

    if (reel && reel.title && reel.script && reel.script.length > 0) {
      try {
        dispatch(setLoader(true));
        const payload = {
          selectedReel: reel, reelId: instagramReelInfo?.id || 0,
        };

        const response = await invokeAxiosPost(`/instagram-reel/get-or-generate/selectInstagramReel?type=select-instagram-reel`, payload);
        dispatch(setLoader(false));
        if (response && response.success === 1 && response.data && response.data.scripts && response.data.scripts.length > 0) {
          setSelectedReelObj({ open: true, reel: response.data.scripts || [] });
          handleSelectImageForEditText(response.data.scripts, 0);
        } else {
          toast.error("Something wen wrong, Please try again!", { id: "Toast-01" });
        }
      } catch (error) {
        dispatch(setLoader(false));
        toast.error("Something wen wrong, Please try again!", { id: "Toast-01" });
      }
    }
  };

  const loadImage = (data) => {
    return new Promise((resolve, reject) => {
      const image = new Image();
      image.src = data;
      image.onload = () => resolve(image);
      image.onerror = () => resolve(image);
    });
  };

  const handleSetImageAndReelText = async (currIndex, reel) => {
    try {
      const generatedImageObj = [...reel];
      const currImgInfo = generatedImageObj[currIndex].info || {};
      const textArray = fabricRef && fabricRef.current ? fabricRef.current.getObjects() : [];

      for (let index = 0; index < textArray.length; index++) {
        const ele = textArray[index];
        const width = Math.round(ele.getScaledWidth()), top = Math.round(ele.top), left = Math.round(ele.left);
        let fontSize = ele.fontSize;
        fontSize *= ele.scaleX;
        fontSize = Math.round(fontSize);

        generatedImageObj[currIndex].text = ele.text;
        currImgInfo.maxWidth = width;
        currImgInfo.top = top;
        currImgInfo.left = left;
        currImgInfo.fill = ele.fill;
        currImgInfo.fontSize = fontSize;
        currImgInfo.fontFamily = ele.fontFamily || "Inter";
        currImgInfo.underline = ele.underline ? true : false;
        currImgInfo.bold = ele.fontWeight && ele.fontWeight === "bold" ? true : false;
        currImgInfo.italic = ele.fontStyle && ele.fontStyle === "italic" ? true : false;
      }
      generatedImageObj[currIndex].info = currImgInfo;
      setSelectedReelObj((prev) => ({ ...prev, reel: generatedImageObj }));

      return { generatedImageObj };
    } catch (error) {
      console.log("#### error --->", error);
      return { generatedImageObj: [...reel] };
    }
  };

  const handleSetTextIntoImage = async (inputStr, reelObj, index) => {
    try {
      const imageInfo = reelObj[index];
      const context = canvasRef.current.getContext("2d");
      const image = await loadImage(imageInfo.imageUrl);

      context.canvas.width = 512;
      context.canvas.height = 512;
      context.clearRect(0, 0, 512, 512);
      context.drawImage(image, 0, 0, 512, 512);

      if (fabricRef && fabricRef.current) {
        fabricRef.current.clear();
      }

      const element = document.getElementById("canvas-div");
      if (element.hasChildNodes()) {
        element.removeChild(element.children[0]);

        const canvas = document.createElement("canvas");
        canvas.id = "c";
        canvas.width = 512;
        canvas.height = 512;
        element.appendChild(canvas);
      }

      fabricRef.current = new fabric.Canvas("c");
      let { maxWidth, top, left, fill, fontSize, fontFamily, underline, bold, italic } = imageInfo.info || {};

      const textBox = new fabric.Textbox(inputStr, {
        borderColor: "#8b3dff", cornerColor: "#ffffff", cornerStrokeColor: "#ffffff",
        left: left || 40, top: top || 440, width: maxWidth || 450, fontSize: fontSize || 24,
        fill: fill || "#ffffff", fontFamily: fontFamily || "Inter", underline: underline || false,
        fontWeight: bold ? "bold" : "normal", fontStyle: italic ? "italic" : "normal",
        textShadow: "2px 2px #FF0000", id: inputStr,
      });
      textBox.setControlsVisibility({ mt: false, mb: false });
      fabricRef.current.add(textBox);
      fabricRef.current.setActiveObject(textBox);

      function updateFontSize() {
        let fontSize = textBox.fontSize;
        fontSize *= textBox.scaleX;
        fontSize = Math.round(fontSize);
        setTextInfo((prev) => ({ ...prev, fontSize }));
      }
      textBox.on("modified", updateFontSize);

      setTextInfo((prev) => ({
        ...prev, fill: fill || "#ffffff", fontFamily: fontFamily || "Inter", fontSize: fontSize || 24,
        bold: bold || false, italic: italic || false, underline: underline || false,
      }));
      setIsDisabledText(false);

      fabricRef.current.getObjects()[0].on("mousedown", function (obj) {
        setIsDisabledText(false);
      });

      fabricRef.current.on("selection:cleared", () => {
        setIsDisabledText(true);
      });
    } catch (error) {
      toast.error("Something went wrong. Please try again!", { id: "Toast-01" });
    }
  };

  const handleSelectImageForEditText = async (reel, index, isDownload = false) => {
    setChangeImgLoader(true);
    let selectedObjReel = [...reel];

    if (selectedReelObj.currentImage >= 0 && canvasRef && canvasRef.current) {
      if (isDownload) {
        await new Promise(resolve => setTimeout(resolve, 200));
        await handleSetTextIntoImage(selectedObjReel[index].text, selectedObjReel, index);
        await new Promise(resolve => setTimeout(resolve, 500));
      }

      const imageIndex = isDownload ? index : selectedReelObj.currentImage;
      const getObj = await handleSetImageAndReelText(imageIndex, selectedObjReel);
      if (getObj && getObj.generatedImageObj) {
        selectedObjReel = [...getObj.generatedImageObj];
      }
    }

    setChangeImgLoader(false);
    if (selectedReelObj && selectedReelObj.currentImage === index && !isDownload) {
      return selectedObjReel;
    }

    setSelectedReelObj((prev) => ({ ...prev, currentImage: index }));
    const inputStr = selectedObjReel[index].text;

    setAudioSrc(null);
    if (selectedObjReel[index] && selectedObjReel[index].audio) {
      setTimeout(() => {
        setAudioSrc(selectedObjReel[index].audio);
      }, 1000);
    }

    setUserAudioSrc(null);
    if (selectedObjReel[index] && selectedObjReel[index].userAudio) {
      setTimeout(() => {
        setUserAudioSrc(selectedObjReel[index].userAudio);
      }, 1000);
    }

    await new Promise(resolve => setTimeout(resolve, 100));
    await handleSetTextIntoImage(inputStr, selectedObjReel, index);
    return selectedObjReel;
  };

  const handleChangeImgTextInfo = async (key, val) => {
    setTextInfo((prev) => ({ ...prev, [key]: val }));
    const activeObject = fabricRef.current ? fabricRef.current.getActiveObject() : null;
    if (activeObject) {
      if (key === "italic") {
        activeObject.set("fontStyle", (val ? key : "normal"));
      } else if (key === "bold") {
        activeObject.set("fontWeight", (val ? key : "normal"));
      } else {
        activeObject.set(key, val);
      }
      fabricRef.current.renderAll();
    }
  };

  const handleDownloadReelVideo = async () => {
    try {
      const selectedObjReel = instagramReelInfo.selectedReel ? { ...instagramReelInfo.selectedReel } : {};
      selectedObjReel.script = selectedReelObj.reel ? [...selectedReelObj.reel] : [];

      if (selectedReelObj.currentImage >= 0 && canvasRef && canvasRef.current) {
        const getObj = await handleSetImageAndReelText(selectedReelObj.currentImage, selectedObjReel.script);
        if (getObj && getObj.generatedImageObj) {
          selectedObjReel.script = [...getObj.generatedImageObj];
        }
      }

      dispatch(setLoader(true));
      const payload = {
        reelId: instagramReelInfo && instagramReelInfo.id ? instagramReelInfo.id : 0,
        selectedReel: selectedObjReel || {},
      };

      const response = await invokeAxiosPost(`/instagram-reel/get-or-generate/generateReelVideo?type=generate-reel-video`, payload);
      dispatch(setLoader(false));
      if (response && response.success === 1 && response.data && response.data.videoLink) {
        setInstagramReelInfo((prev) => ({ ...prev, ...response.data }));
        setVideoModal({ open: true, video: response.data.videoLink });
        toast.success("Reel video generated successfully.", { id: "Toast-01" });
      } else {
        toast.error("Reels not generated, Please try again.", { id: "Toast-01" });
      }
    } catch (error) {
      console.log("#### error --->", error);
      dispatch(setLoader(false));
    }
  };

  const [uploadFileDragging, setUploadFileDragging] = useState(null);
  const [uploadFileError, setUploadFileError] = useState(null);

  const FileUploaderCustomUi = useMemo(() => (
    <div className={` position-relative ${uploadFileDragging ? "active" : ""}`}>
      <BsCloudUploadFill className="uploadIcon" />
      <h4 className="fw-normal form-label file-upload-header">
        <strong>Choose a file</strong> or drag it here
      </h4>
      <p className="m-0 file-upload-pera">
        Supported formats: MP3, MPEG <br /> Maximum file size: 5MB.
      </p>
      {uploadFileError ? (
        <React.Fragment>
          <h5 className="fw-normal text-danger d-flex align-items-center">
            <BiSolidErrorCircle className="fs-4 me-2" /> {uploadFileError}
          </h5>
        </React.Fragment>
      ) : null}
    </div>
  ), [uploadFileDragging, uploadFileError]);

  const handleOnUploadAudioFile = async (file) => {
    const MAX_FILE_SIZE = 5120; // 5MB
    const fileSizeKiloBytes = file.size / 1024;
    if (fileSizeKiloBytes > MAX_FILE_SIZE) {
      toast.error("File size exceeds limit. Please upload a file no larger than 5 MB.", { id: "Toast-01" });
      return false;
    }

    const extension = file.name.split(".").pop().toLowerCase();
    if (extension !== "mpeg" && extension !== "mp3") {
      toast.error("Uploaded file is not a MP3 or MPEG, Please upload a valid MP3 or MPEG file.", { id: "Toast-01" });
      return false;
    }

    if (file.type !== "audio/mpeg" && file.type !== "audio/mp3") {
      toast.error("Uploaded file is not a MP3 or MPEG, Please upload a valid MP3 or MPEG file.", { id: "Toast-01" });
      return false;
    }

    const scriptIndex = selectedReelObj.currentImage;
    const selectedObjReel = instagramReelInfo.selectedReel ? { ...instagramReelInfo.selectedReel } : {};
    selectedObjReel.script = selectedReelObj.reel ? [...selectedReelObj.reel] : [];

    dispatch(setLoader(true));
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = async (event) => {
      try {
        const base64Data = event.target.result;
        const params = {
          audioBase64: base64Data, file_name: file.name,
          reelId: instagramReelInfo && instagramReelInfo.id ? instagramReelInfo.id : 0,
          selectedReel: selectedObjReel || {}, scriptIndex: scriptIndex || 0,
        }

        const payload = { params };

        const response = await invokeAxiosPost(`/instagram-reel/get-or-generate/uploadReelAudio?type=upload-reel-audio`, payload);
        dispatch(setLoader(false));
        if (response && response.success === 1 && response.data && response.data.scripts) {
          setInstagramReelInfo((prev) => ({ ...prev, ...response.data }));
          setSelectedReelObj((prev) => ({ ...prev, reel: response.data.scripts || [] }));
          toast.success("Audio file uploaded successfully.", { id: "Toast-01" });
        } else {
          toast.error("Reels not generated, Please try again.", { id: "Toast-01" });
        }
      } catch (error) {
        console.log("#### error --->", error);
        dispatch(setLoader(false));
      }
    }
  };

  const handleSelectOrUnselectAudio = (type, value) => {
    const finalScripts = selectedReelObj.reel && selectedReelObj.reel.length > 0 ? [...selectedReelObj.reel] : [];
    if (finalScripts && finalScripts[selectedReelObj.currentImage]) {
      const currentScript = finalScripts[selectedReelObj.currentImage] || {};
      currentScript.selectedAudioType = type && value ? type : "";
      finalScripts[selectedReelObj.currentImage] = currentScript;
    }
    setInstagramReelInfo((prev) => ({ ...prev, scripts: finalScripts }));
    setSelectedReelObj((prev) => ({ ...prev, reel: finalScripts }));
  };

  return (
    <div className={`blogWriterBlock position-relative`}>
      {selectedReelObj && !selectedReelObj.open && (
        <div className="navItemsBlock">
          <div className={classNames(`navTopButton d-flex justify-content-between flex-wrap gap-4 w-100`)}>
            <div className="d-flex align-items-end justify-content-end mb-3 w-100">
              {versionOptions && versionOptions.length > 0 && (
                <Col sm="12" md="3" className="me-auto">
                  <div className="fields">
                    <label className="fw-bold">Generated Reel History</label>
                    <Select
                      className={`custom-select`} classNamePrefix="custom-select" placeholder="Select Version"
                      onChange={(e) => handleChangeInstagramReelVersion(e.value)} menuPosition="fixed"
                      options={versionOptions} isClearable={false} isSearchable={false}
                    />
                  </div>
                </Col>
              )}
              <Row className="mx-0 mx-md-2 w-50">
                <Col sm="12" md="6" className="">
                  <div className="fields">
                    <label>Select Age Group</label>
                    <Select
                      className={`custom-select`} classNamePrefix="custom-select" placeholder="Select Age Group"
                      options={AgeGroupOptions} isClearable={false} isSearchable={false}
                      value={AgeGroupOptions.find(c => c.value === ageGroup) || ""}
                      onChange={(e) => setAgeGroup(e.value)} menuPosition="fixed"
                    />
                  </div>
                </Col>
                <Col sm="12" md="6" className="">
                  <div className="fields">
                    <label>Select Content Tone</label>
                    <Select
                      className={`custom-select`} classNamePrefix="custom-select" placeholder="Select Content Tone"
                      options={ContentTonesOptions} isClearable={false} isSearchable={false}
                      value={ContentTonesOptions.find(c => c.value === contentTone) || ""}
                      onChange={(e) => setContentTone(e.value)} menuPosition="fixed"
                    />
                  </div>
                </Col>
              </Row>
              <div className="d-flex align-items-center gap-2">
                {instagramReelInfo && instagramReelInfo.id ? (
                  <button
                    type="button" className="addlly-primary px-4" style={{ maxWidth: "fit-content", whiteSpace: "nowrap" }}
                    onClick={() => handleGenerateInstagramReels(true)} disabled={!ageGroup || !contentTone}
                  >
                    <span style={{ fontSize: "14px" }}><BsStars /> Re-Generate Reels</span>
                  </button>
                ) : (
                  <button type="button" className={"addlly-primary px-4"} onClick={() => handleGenerateInstagramReels()}>
                    <BsStars /> Generate
                  </button>
                )}
                <BsInfoCircleFill
                  className="text-decoration-none outline-0 fs-4 m-0 infoIconSvg"
                  style={{ width: "16px", minWidth: "16px", height: "16px" }} data-tooltip-id={"my-tooltip"} data-tooltip-place="bottom"
                  data-tooltip-content={`${instagramReelInfo && instagramReelInfo.id ? "Re-Generate" : "Generate"} will utilize ${userRes?.deductCredits?.["Social Media Post"] || 1} Addlly credit`}
                />
              </div>
            </div>
          </div>
        </div>
      )}

      <div className={`${selectedReelObj && selectedReelObj.open ? "h-100" : "editableTextArea d-flex overflow-hidden position-relative"}`}>
        {selectedReelObj && selectedReelObj.open ? (
          <div className="eCommerce-blog-wrapper mw-100 m-0">
            <div className="d-flex align-items-center gap-3 mb-3">
              <button type="button" className={"addlly-primary py-2 px-3"} style={{ maxWidth: "max-content" }} onClick={() => handleDownloadReelVideo()}>
                <BsStars className="fs-5" /> {instagramReelInfo?.videoLink ? "Re-Generate" : "Generate"} Video
              </button>
              {instagramReelInfo && instagramReelInfo.videoLink && (
                <button type="button" className={"addlly-primary py-2 px-4"} style={{ maxWidth: "max-content" }}
                  onClick={() => setVideoModal({ open: true, video: instagramReelInfo.videoLink })}
                >
                  <LuMonitorPlay className="fs-5" /> Play Video
                </button>
              )}
            </div>
            <div className="reel-image-editor">
              <div className="left-side-bar">
                <div className="tabs-list">
                  {[
                    { id: 1, label: "Images", icon: <IoImagesOutline /> },
                    { id: 2, label: "Text", icon: <PiTextTBold /> },
                    // { id: 3, label: "Image Change", icon: <BsImage /> },
                    { id: 4, label: "Audio", icon: <LuFileAudio /> },
                  ].map((item, index) => (
                    <div
                      className={`tab-btn ${activeTab === item.label ? "active" : ""}`} key={index}
                      onClick={() => setActiveTab(item.label)}
                    >
                      {item.icon} <p>{item.label}</p>
                    </div>
                  ))}
                </div>
                <div className="tabs-content">
                  {activeTab === "Images" ? (
                    <div className="reel-second-step p-3">
                      <div className="sub-image-wrapper border-0">
                        {selectedReelObj.reel && selectedReelObj.reel.length > 0 && selectedReelObj.reel.map((image, index) => (
                          <div className={`sub-image-div ${selectedReelObj.currentImage === index ? "active" : ""}`} key={index}
                            onClick={() => handleSelectImageForEditText(selectedReelObj.reel, index)}
                          >
                            <img src={image.imageUrl} alt="sub-img" />
                          </div>
                        ))}
                      </div>
                    </div>
                  ) : activeTab === "Text" ? (
                    <div className="p-3">
                      <div className="my-2">
                        <label>Font Style</label>
                        <div className="d-flex align-items-end gap-2">
                          <input
                            type="color" name="favcolor" className="color-picker-input" value={textInfo.fill}
                            onChange={(e) => handleChangeImgTextInfo("fill", e.target.value)} disabled={isDisabledText}
                          />
                          {[
                            { id: 1, key: "bold", icon: <FaBold /> },
                            { id: 2, key: "italic", icon: <FaItalic /> },
                            { id: 3, key: "underline", icon: <FaUnderline /> }
                          ].map((item, index) => (
                            <button
                              type="button" className={`font-style-btn ${textInfo[item.key] ? "active" : ""}`} key={index}
                              onClick={(e) => handleChangeImgTextInfo(item.key, !textInfo[item.key])} disabled={isDisabledText}
                            >
                              {item.icon}
                            </button>
                          ))}
                        </div>
                      </div>
                      <div className="my-4">
                        <label>Font Family</label>
                        <Select
                          className={`custom-select`} classNamePrefix="custom-select" placeholder="Select Font Family"
                          menuPosition="fixed" options={FontFamilyOptions} isClearable={false} isSearchable={false}
                          value={FontFamilyOptions.find(f => f.value === textInfo.fontFamily) || ""}
                          onChange={(e) => handleChangeImgTextInfo("fontFamily", e.value)}
                        />
                      </div>
                      <div className="my-4">
                        <label>Font Size</label>
                        <div className="d-flex align-items-center fs-controls">
                          <button
                            type="button" className="decrease-btn" disabled={isDisabledText}
                            onClick={(e) => handleChangeImgTextInfo("fontSize", textInfo.fontSize - 1)}
                          >
                            <FaMinus />
                          </button>
                          <p className="font-size-label">{textInfo.fontSize}</p>
                          <button
                            type="button" className="increase-btn" disabled={isDisabledText}
                            onClick={(e) => handleChangeImgTextInfo("fontSize", textInfo.fontSize + 1)}
                          >
                            <FaPlus />
                          </button>
                        </div>
                      </div>
                    </div>
                  ) : activeTab === "Audio" ? (
                    <div className="reel-second-step p-3">
                      {audioSrc && audioSrc !== "" && (
                        <div className="mb-4">
                          <div className="d-flex align-items-center gap-2 mb-2">
                            {userAudioSrc && userAudioSrc !== "" && (
                              <input
                                id="textAudio" type="checkbox" className="custom-checkbox cursor-pointer"
                                onChange={(e) => handleSelectOrUnselectAudio("text", e.target.checked)}
                                checked={(
                                  selectedReelObj.reel && selectedReelObj.reel[selectedReelObj.currentImage] &&
                                  selectedReelObj.reel[selectedReelObj.currentImage].selectedAudioType &&
                                  selectedReelObj.reel[selectedReelObj.currentImage].selectedAudioType === "text"
                                ) ? true : false}
                              />
                            )}
                            <label className="cursor-pointer m-0" htmlFor="textAudio">Generated Text Audio</label>
                          </div>
                          <audio ref={audioRef} className="w-100" controls>
                            <source src={audioSrc} type="audio/ogg" />
                            <source src={audioSrc.replace(".ogg", ".mp3")} type="audio/mpeg" />
                            Your browser does not support the audio element.
                          </audio>
                        </div>
                      )}
                      {userAudioSrc && userAudioSrc !== "" && (
                        <div className="mb-4">
                          <div className="d-flex align-items-center gap-2 mb-2">
                            <input
                              id="userAudio" type="checkbox" className="custom-checkbox cursor-pointer"
                              onChange={(e) => handleSelectOrUnselectAudio("user", e.target.checked)}
                              checked={(
                                selectedReelObj.reel && selectedReelObj.reel[selectedReelObj.currentImage] &&
                                selectedReelObj.reel[selectedReelObj.currentImage].selectedAudioType &&
                                selectedReelObj.reel[selectedReelObj.currentImage].selectedAudioType === "user"
                              ) ? true : false}
                            />
                            <label className="cursor-pointer m-0" htmlFor="userAudio">Uploaded Audio</label>
                          </div>
                          <audio ref={audioRef} className="w-100" controls>
                            <source src={userAudioSrc} type="audio/ogg" />
                            <source src={userAudioSrc.replace(".ogg", ".mp3")} type="audio/mpeg" />
                            Your browser does not support the audio element.
                          </audio>
                        </div>
                      )}
                      <div className="mb-4">
                        <label>Add Own Audio File</label>
                        <div className="fileUploadBlock">
                          <FileUploader
                            name="file" types={["mpeg", "mp3"]} maxSize={5} children={FileUploaderCustomUi}
                            multiple={false} classes={`w-100`} handleChange={handleOnUploadAudioFile}
                            onDraggingStateChange={(dragging) => setUploadFileDragging(dragging)} dropMessageStyle={{ display: "none" }}
                            onSizeError={() => { setUploadFileError("File size exceeds the allowable limit. Please upload a smaller file."); }}
                            onTypeError={() => { setUploadFileError("Invalid file type. Please upload a file with a supported file type."); }}
                          />
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="right-side-bar reel-second-step">
                <div className="canvas-content">
                  <div className="sub-div">
                    <canvas ref={canvasRef} className="main-image-wrapper" width="512" height="512" />
                    <div className="position-absolute top-0 start-0" id="canvas-div">
                      <canvas id="c" width="512" height="512"></canvas>
                    </div>
                    {changeImgLoader && (
                      <div className="main-image-wrapper overflow-hidden position-absolute top-0 start-0" style={{ height: "512px" }}>
                        <p className="placeholder-glow h-100">
                          <span className="placeholder col-12 bg-dark h-100"></span>
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : instagramReelInfo && instagramReelInfo.id && reelsData && reelsData.length > 0 ? (
          <div className="w-100 p-0">
            <div className="news-letters-templates e-com-templates p-0">
              {reelsData.map((reel, index) => (
                <div className="genrateFields p-0 mb-4">
                  <div
                    className="d-flex align-items-center shiseido-label justify-content-between py-2 px-4 mb-3 shadow-sm rounded cursor-pointer"
                    style={{ background: "linear-gradient(102.77deg, rgba(0, 0, 255, 0.05) 0%, rgba(255, 0, 0, 0.05) 121.74%)", borderColor: "#dee2e6" }}
                    onClick={() => setAccordionState((prev) => ({ ...prev, [reel.title]: !prev[reel.title] }))}
                  >
                    <h3 className="fw-normal" style={{ fontSize: "22px" }}>Reel {index + 1} : {reel.title}</h3>
                    <div className="d-flex align-items-center gap-3">
                      <button type="button" className="btn p-0" style={{ color: "var(--primary-main)" }} onClick={(e) => handleSelectInstagramReel(e, reel)}>
                        Generate Video
                      </button>
                      <button type="button" className="btn p-0" style={{ color: "var(--primary-main)" }}>
                        {accordionState[reel.title] ? <PiArrowSquareUpFill className="fs-3" /> : <PiArrowSquareDownFill className="fs-3" />}
                      </button>
                    </div>
                  </div>
                  {accordionState[reel.title] && (
                    <Row className="eCommerce-blog-wrapper mb-3 mw-100 pb-0">
                      {reel.script.map((item, index) => (
                        <Col sm="6" md="6" lg="4" className="pb-3" key={index}>
                          <div className={`h-0 card-product image-wrapper`}
                            style={{
                              boxShadow: "0 0.3125rem 0.625rem rgba(0, 0, 0, 0.1125)",
                              background: "linear-gradient(102.77deg, rgba(0, 0, 255, 0.05) 0%, rgba(255, 0, 0, 0.05) 121.74%)",
                            }}
                          >
                            {item.imageUrl && item.imageUrl !== "" ? (
                              <img src={item.imageUrl} alt={item.title} className="h-auto" />
                            ) : (
                              <p className="placeholder-glow">
                                <span className="placeholder col-12" style={{ height: "300px" }}></span>
                              </p>
                            )}
                            <div className="card-product-info p-3 pt-2 d-flex flex-column h-auto" style={{ background: "none" }}>
                              <h5 className="mb-0">Frame {index + 1}</h5>
                              <h5 className="my-3 fw-medium" style={{ "-webkit-line-clamp": "none" }}>
                                <b>Title:</b> <span className="text-muted">{item.text}</span>
                              </h5>
                              <h5 className="mt-1 fw-medium">
                                <b>Visual Cues:</b> <span className="text-muted">{item.visual_cues}</span>
                              </h5>
                            </div>
                          </div>
                        </Col>
                      ))}
                    </Row>
                  )}
                </div>
              ))}
            </div>
          </div>
        ) : (
          <div className={`toggleData notContent mw-100`}></div>
        )}
      </div>

      {videoModal && videoModal.open && (
        <Modal className="video-modal reel-video-model" centered show={videoModal.open}>
          <Modal.Body className="text-center p-0">
            <div className="close-btn" onClick={() => setVideoModal({ open: false, video: "" })} onKeyDown={() => setVideoModal({ open: false, video: "" })}>
              <MdClose />
            </div>
            <div className="d-flex">
              <iframe
                src={`${videoModal.video}?title=0&byline=0&portrait=0`}
                style={{ width: "100%", height: "80vh", borderRadius: "0.5rem", background: "#000" }}
                title="video" id="vimeo-video" frameborder="0" allow="fullscreen; picture-in-picture"
                webkitAllowFullScreen mozallowfullscreen allowFullScreen
              ></iframe>
            </div>
          </Modal.Body>
        </Modal>
      )}
    </div>
  )
}

export default InstagramReelVideoComponent;
