import React from "react";
import { dataTableIcons } from "../../Assets/Icons";
import { Link } from "react-router-dom";
import DropdownMenu from "../ui/Dropdown";
import classNames from "classnames";
import { categories, dateFilters } from "../../data";
import { RiInboxUnarchiveFill } from "react-icons/ri";
import { memo } from "react";
import { BsSearch } from "react-icons/bs";
import { ReactSVG } from "react-svg";
import { useSelector } from "react-redux";

export default memo(function TableFilters(props) {
  const userRes = useSelector(({ userRes }) => userRes);
  const { textWrap, setTextWrap, selectedRows, onCategorySelect, onDateSelect, handleArchive, setSearchFilter } = props

  return (
    <div className="viewAllBtn d-flex justify-content-center align-items-center">
      <div className="dropdownFilter d-flex justify-content-center gap-4 align-items-center">
        <div className="recent-article-search position-relative">
          <input type="search" placeholder="Type to search" onChange={(e) => setSearchFilter(e.target.value)} />
          <div className="search-icon position-absolute">
            <BsSearch />
          </div>
        </div>
        <div className="dropdownBlock d-flex flex-wrap justify-content-center gap-4">
          {selectedRows?.length ? (
            <div>
              <button onClick={handleArchive} className="d-flex justify-content-center  align-items-center deleteBtn border-none" >
                <RiInboxUnarchiveFill /> Archive
              </button>
            </div>
          ) : null}
          <div className="dropdown">
            <DropdownMenu placeholder={'All'} label={"name"} onSelect={onDateSelect} options={dateFilters} />
          </div>
          <div className="dropdown">
            <DropdownMenu placeholder={'All'} label={"name"} onSelect={onCategorySelect} options={categories} />
          </div>
        </div>
        <span className="line"> | </span>
        <div className="filterRedu  overflow-hidden">
          <button
            className={classNames("", { active: !textWrap })} onClick={() => setTextWrap(false)}
            data-tooltip-id="my-tooltip" data-tooltip-content="Unwrap Text" data-tooltip-place="bottom"
          >
            <ReactSVG src={dataTableIcons.filterIcon} alt="filterIcon" useRequestCache />
          </button>
          <button
            className={classNames("border-0", { active: textWrap })} onClick={() => setTextWrap(true)}
            data-tooltip-id="my-tooltip" data-tooltip-content="Wrap Text" data-tooltip-place="bottom"
          >
            <ReactSVG src={dataTableIcons.redoIcon} alt="redoIcon" useRequestCache />
          </button>
        </div>
      </div>
      <Link to="/history" > View All</Link>
    </div>
  );
})
