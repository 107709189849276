import React, { useEffect, useRef, useState } from "react";
import moment from "moment";
import { GoCheckCircle, GoCheckCircleFill } from "react-icons/go";
import { MdArrowBackIos, MdArrowForwardIos, MdDeleteOutline } from "react-icons/md";
import { TbRestore } from "react-icons/tb";

const CommentListComponent = (props) => {
  const {
    commentList, selectedCommentId, replyCmtText, setReplyCmtText, currentStatus, articleType, handleOnSelectCommentById,
    handleAddCommentReply, handleResolvedOrDeleteComment, handleGetAllCommentList, handleCloseSelectedComment,
  } = props;

  const replyTextRef = useRef(null);
  const replyPopupRef = useRef(null);
  const [currentComment, setCurrentComment] = useState({});

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (currentComment && currentComment.commentId && replyPopupRef.current && !replyPopupRef.current.contains(event.target)) {
        handleCloseSelectedComment();
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [currentComment]);

  useEffect(() => {
    if (selectedCommentId && selectedCommentId !== "") {
      const comment = commentList.find((comment) => comment.commentId === selectedCommentId);
      if (comment && comment.commentId) {
        const tempObj = { ...comment };
        tempObj.user_info = comment?.user_info ? JSON.parse(comment.user_info) : {};
        setCurrentComment(tempObj);
      }
    } else {
      setCurrentComment({});
      const elements = document.getElementsByClassName("comment-modal");
      for (let i = 0; i < elements.length; i++) {
        elements[i].style.display = "";
        elements[i].style.top = "";
        elements[i].style.left = "";
        elements[i].classList.remove("arrow-center");
      }
      const element2 = document.getElementsByClassName("reviewArticle");
      for (let i = 0; i < element2.length; i++) {
        element2[i].classList.add("overflow-hidden");
      }
    }
  }, [selectedCommentId, commentList])

  const handleOpenPopupCommentPopup = (event, comment) => {
    if (!comment?.commentId) { return false; }
    handleOnSelectCommentById(event, comment.commentId);

    const selectedDivRef = document.getElementById(`comment-${comment.commentId}`);
    if (selectedDivRef) {
      const parentDivRef = document.getElementsByClassName("review-article-wrapper");
      let parentRect = {};
      if (parentDivRef && parentDivRef.length > 0) {
        parentRect = parentDivRef[0].getBoundingClientRect();
      }

      const selectedRect = selectedDivRef.getBoundingClientRect();
      const top = selectedRect.top - (parentRect.top || 0);
      const left = selectedRect.left - (parentRect.left || 0) - 380;

      const element2 = document.getElementsByClassName("reviewArticle");
      for (let i = 0; i < element2.length; i++) {
        element2[i].classList.remove("overflow-hidden");
      }
      const elements = document.getElementsByClassName("comment-modal");
      for (let i = 0; i < elements.length; i++) {
        elements[i].style.display = "block";
        elements[i].style.top = top + 0 + "px";
        elements[i].style.left = left - 28 + "px";

        if (parentRect.height < selectedRect.bottom && articleType !== "Social Media Post Writer") {
          elements[i].style.top = -50 + 0 + "px";
          elements[i].classList.add("arrow-center");
        }
      }
    }
  };

  const ShowNextAndPreviousArrowButton = () => {
    if (!currentComment?.commentId) {
      return "";
    }

    let isDisabledPreviousBtn = false, isDisabledNextBtn = false, previousCommentId = "", nextCommentId = "";
    if (commentList && commentList.length > 0 && commentList[0].commentId === currentComment.commentId) {
      isDisabledPreviousBtn = true;
    }
    if (commentList && commentList.length > 0 && commentList[commentList.length - 1].commentId === currentComment.commentId) {
      isDisabledNextBtn = true;
    }

    const getCurrentCommentIndex = commentList.findIndex((ele) => ele.commentId === currentComment.commentId);
    if (getCurrentCommentIndex !== -1) {
      if (getCurrentCommentIndex !== 0) {
        previousCommentId = commentList[getCurrentCommentIndex - 1] ? commentList[getCurrentCommentIndex - 1].commentId : "";
      }
      if (getCurrentCommentIndex !== commentList.length - 1) {
        nextCommentId = commentList[getCurrentCommentIndex + 1] ? commentList[getCurrentCommentIndex + 1].commentId : "";
      }
    }

    return (
      <div className="d-flex align-items-center gap-2">
        <button
          className="btn arrow-btn p-0 border-0" disabled={isDisabledPreviousBtn}
          onClick={(e) => handleOpenPopupCommentPopup(e, { commentId: previousCommentId })}
          data-tooltip-id="my-tooltip" data-tooltip-content={"Previous Comment"} data-tooltip-place="bottom"
        >
          <MdArrowBackIos className="" />
        </button>
        <button
          className="btn arrow-btn p-0 border-0" disabled={isDisabledNextBtn}
          onClick={(e) => handleOpenPopupCommentPopup(e, { commentId: nextCommentId })}
          data-tooltip-id="my-tooltip" data-tooltip-content={"Next Comment"} data-tooltip-place="bottom"
        >
          <MdArrowForwardIos className="" />
        </button>
      </div>
    );
  };

  return (
    <React.Fragment>
      <div className="sidebar-comment-header">
        <h3 className="text-start">Comments</h3>
        <div className="comment-header-btn">
          {[
            { label: "All", value: "all" },
            { label: "Resolved", value: "resolved" },
            { label: "Deleted", value: "deleted" },
          ].map((item, index) => (
            <button
              className={`btn ${currentStatus === item.value ? "active" : ""}`} type="button"
              onClick={() => handleGetAllCommentList(item.value, true)} key={index}
            >
              {item.label}
            </button>
          ))}
        </div>
      </div>
      <div className="sidebar-comment-wrapper pe-1">
        {commentList && commentList.length > 0 ? commentList.map((comment, index) => {
          const userRes = comment?.user_info ? JSON.parse(comment.user_info) : {};
          return (
            <div key={index} className={`comment-form-history mb-3 ${comment.status + "-bg"}`} id={`comment-${comment.commentId}`}>
              <div className="user-info d-flex align-items-center mb-2">
                {userRes?.profileUrl ? (
                  <img src={userRes.profileUrl} alt="user" className="rounded-circle userIcon" />
                ) : (
                  <div className="avatar text-white">{userRes?.userName?.[0]}</div>
                )}
                <div className="ms-2">
                  <p className="mb-0">{userRes?.fullName || userRes?.userName}</p>
                  <p
                    className="mb-0" data-tooltip-id="my-tooltip" data-tooltip-place="bottom"
                    data-tooltip-content={moment(comment.createAt).format("MMM DD - hh:mm A")}
                  >
                    {moment(comment.createAt).fromNow()}
                  </p>
                </div>

                <ShowResolveAndDeleteButton comment={comment} handleResolvedOrDeleteComment={handleResolvedOrDeleteComment} />
              </div>
              <div className="addllyFormWrap comment-content" onClick={(e) => handleOpenPopupCommentPopup(e, comment)}>
                <pre className="fw-normal comment-div">{comment.comment}</pre>
                {comment.replyComments && comment.replyComments.length > 0 && (
                  <div className="replies-label">
                    {comment.replyComments.length || 0} Replies
                  </div>
                )}
              </div>
            </div>
          )
        }) : (
          <p className='d-flex align-items-center justify-content-center border py-5 px-4 rounded'>
            Comment history is not available yet
          </p>
        )}

        <div className="comment-modal" ref={replyPopupRef}>
          {currentComment && currentComment.commentId && (
            <div className="p-1">
              <div className="comment-modal-header">
                <ShowNextAndPreviousArrowButton />
                <ShowResolveAndDeleteButton comment={currentComment} handleResolvedOrDeleteComment={handleResolvedOrDeleteComment} />
              </div>
              <div className="comment-modal-body messages">
                {currentComment.replyComments && currentComment.replyComments.length > 0 && currentComment.replyComments.map((message, index) => (
                  <div key={index} className={`message ${message.senderType}`}>
                    <div className="message-header">
                      <p className="mb-0">
                        {message.senderType === "admin" ? "Reviewer" :
                          message.senderType === "user" ? (`${currentComment?.user_info?.fullName || currentComment?.user_info?.userName}`) : null}
                      </p>
                      <p
                        className="mb-0" data-tooltip-id="my-tooltip" data-tooltip-place="bottom"
                        data-tooltip-content={moment(message.createAt).format("MMM DD - hh:mm A")}
                      >
                        {moment(message.createAt).fromNow()}
                      </p>
                    </div>
                    <pre className="">{message.comment}</pre>
                  </div>
                ))}
              </div>
              {currentComment.status === "deleted" ? (
                <div className="comment-modal-footer addllyFormWrap">
                  <div className="d-flex align-items-center justify-content-between mt-2">
                    <div className="user-info">
                      {currentComment?.user_info?.profileUrl ? (
                        <img src={currentComment?.user_info.profileUrl} alt="user" className="rounded-circle userIcon" />
                      ) : (
                        <div className="avatar text-white">{currentComment?.user_info?.userName?.[0]}</div>
                      )}
                    </div>
                    <div className="d-flex align-items-center gap-2">
                      <button className="addlly-outline" type="button" onClick={handleCloseSelectedComment}>
                        Close
                      </button>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="comment-modal-footer addllyFormWrap">
                  <div className="form-group">
                    <textarea
                      name="reply" type="text" rows="3" className={`addllyForm-control rounded-3 p-2 bg-white`}
                      value={replyCmtText} onChange={(e) => setReplyCmtText(e.target.value)} ref={replyTextRef}
                      placeholder={`${currentStatus === "resolve" ? "Adding a comment will re-open this discussion..." : "Add Reply"}`}
                    />
                    <div className="d-flex align-items-center justify-content-between mt-2">
                      <div className="user-info">
                        {currentComment?.user_info?.profileUrl ? (
                          <img src={currentComment?.user_info.profileUrl} alt="user" className="rounded-circle userIcon" />
                        ) : (
                          <div className="avatar text-white">{currentComment?.user_info?.userName?.[0]}</div>
                        )}
                      </div>
                      <div className="d-flex align-items-center gap-2">
                        <button className="addlly-outline" type="button" onClick={handleCloseSelectedComment}>
                          Close
                        </button>
                        <button className="addlly-primary" type="button" onClick={handleAddCommentReply}
                          disabled={replyCmtText && replyCmtText !== "" ? false : true}
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  )
};

const ShowResolveAndDeleteButton = ({ comment, handleResolvedOrDeleteComment }) => {
  return (
    <div className="d-flex align-items-center ms-auto gap-2">
      {comment.status === "open" || comment.status === "resolved" ? (
        <React.Fragment>
          {comment.status === "resolved" ? (
            <div
              className="resolved-btn" onClick={() => handleResolvedOrDeleteComment(comment.commentId, "reopen")}
              data-tooltip-id="my-tooltip" data-tooltip-content={"Re-Open"} data-tooltip-place="bottom"
            >
              <GoCheckCircleFill className="" />
            </div>
          ) : (
            <div
              className="resolved-btn" onClick={() => handleResolvedOrDeleteComment(comment.commentId, "resolved")}
              data-tooltip-id="my-tooltip" data-tooltip-content={"Resolve"} data-tooltip-place="bottom"
            >
              <GoCheckCircle className="" />
            </div>
          )}
          <div
            className="delete-btn" onClick={() => handleResolvedOrDeleteComment(comment.commentId, "delete")}
            data-tooltip-id="my-tooltip" data-tooltip-content={"Delete"} data-tooltip-place="bottom"
          >
            <MdDeleteOutline className="" />
          </div>
        </React.Fragment>
      ) : comment.status === "deleted" ? (
        <div
          className="restore-btn" onClick={() => handleResolvedOrDeleteComment(comment.commentId, "restore")}
          data-tooltip-id="my-tooltip" data-tooltip-content={"Restore"} data-tooltip-place="bottom"
        >
          <TbRestore className="" />
        </div>
      ) : null}
    </div>
  )
}

export default CommentListComponent;
