import React, { useEffect, useMemo, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { Col, Placeholder, Row } from "react-bootstrap";
import { BsInfoCircleFill, BsStars } from "react-icons/bs";
import { FaRegImage } from "react-icons/fa";
import { FiRefreshCw } from "react-icons/fi";
import DropdownMenu from "../../ui/Dropdown";
import NoDataAvailable from "../../NoDataAvailable";
import ImageDisplayContent from "./ImageDisplayContent";
import { oneClickBlogIcon } from "../../../Assets/Icons";
import { invokeAxiosPost } from "../../../utility/invokeAxiosFunction";
import { setFinalContent, setGeneratedFullArticle, setLoader } from "../../../store/reducer";

const AIGeneratedImage = ({ props, handleSaveSelectedImage }) => {
  const { postImagesModal, setPostImagesModal, postGeneratedImages, setPostGeneratedImages, userRes } = props;
  const dispatch = useDispatch();
  const finalContent = useSelector(({ finalContent }) => finalContent);
  const generatedFullArticle = useSelector(({ generatedFullArticle }) => generatedFullArticle);

  const [isRegenerateAiImg, setIsRegenerateAiImg] = useState(false);
  const [regenerateAiImgTime, setRegenerateAiImgTime] = useState(0);
  const [selectedVersion, setSelectedVersion] = useState({});

  const [oonaImgGenerate, setOonaImgGenerate] = useState(false);
  const [promptText, setPromptText] = useState(postImagesModal?.imagePrompt || "");
  const [newAiImages, setNewAiImages] = useState(postGeneratedImages.newAiImages || []);

  const dropdown = useMemo(() => ([{ name: 'Generation 1', value: 'version1', label: 'Ver. 01/03' }, { name: 'Generation 2', value: 'version2', label: 'Ver. 02/03' }, { name: 'Generation 3', value: 'version3', label: 'Ver. 03/03' }]), [])
  const versions = useMemo(() => {
    const newDropVersion = [];
    for (let index = 0; index < postGeneratedImages?.openAiImagesVersions?.length; index++) {
      newDropVersion.push(dropdown[index]);
    };
    return newDropVersion;
  }, [postGeneratedImages?.openAiImagesVersions, dropdown]);

  useEffect(() => {
    setNewAiImages(postGeneratedImages.newAiImages || []);
  }, [postGeneratedImages])

  useEffect(() => {
    let interval;
    if (isRegenerateAiImg && regenerateAiImgTime > 0) {
      interval = setInterval(() => {
        setRegenerateAiImgTime(prevSeconds => prevSeconds - 1);
      }, 1000);
    } else {
      clearInterval(interval);
    }
    if (!selectedVersion?.name) {
      setSelectedVersion(pre => versions[versions?.length - 1]);
    }
    return () => clearInterval(interval);
  }, [isRegenerateAiImg, regenerateAiImgTime, versions, selectedVersion]);

  const handleGenerateAiGenImages = async () => {
    setPostImagesModal((prev) => ({ ...prev, aiImageGenerating: true }));
    setIsRegenerateAiImg(true);
    setRegenerateAiImgTime(120);

    // Generate stability ai images
    const payload = { ...postImagesModal.apiParams };
    const imageRes = await invokeAxiosPost("/media-library/get-or-generate/getAndGenerateStabilityAiImages", payload);
    setPostImagesModal((prev) => ({ ...prev, aiImageGenerating: false }));
    setIsRegenerateAiImg(false);
    setRegenerateAiImgTime(0);

    if (imageRes && imageRes.success === 1 && imageRes.data && imageRes.data.images && imageRes.data.images.length > 0) {
      setPostGeneratedImages((prev) => ({ ...prev, openAiImages: imageRes.data.images, openAiImgRegenLeft: imageRes.data.regenerateLeft }));
    } else {
      toast.error(imageRes?.error || "Ai image not generated, Please try again.", { id: "Toast-01" });
    }
  }

  const handleRegenerateAiGenImages = async () => {
    if (!postGeneratedImages.openAiImgRegenLeft || postGeneratedImages.openAiImgRegenLeft === 0) {
      toast.error("You have used all regenerate tire.", { id: "Toast-01" });
      return false;
    }

    setPostImagesModal((prev) => ({ ...prev, aiImageGenerating: true }));
    setIsRegenerateAiImg(true);
    setRegenerateAiImgTime(120);

    // Regenerate stability ai images
    const payload = { ...postImagesModal.apiParams, isRegenerateImg: true };
    const generateImgRes = await invokeAxiosPost("/media-library/get-or-generate/getAndGenerateStabilityAiImages", payload);
    setPostImagesModal((prev) => ({ ...prev, aiImageGenerating: false }));
    setIsRegenerateAiImg(false);
    setRegenerateAiImgTime(0);

    if (
      generateImgRes && generateImgRes.success === 1 && generateImgRes.data &&
      generateImgRes.data.images && generateImgRes.data.images.length > 0 && generateImgRes.data?.oldImages?.length
    ) {
      setPostGeneratedImages((prev) => ({
        ...prev, openAiImages: generateImgRes.data.images,
        openAiImgRegenLeft: generateImgRes.data.regenerateLeft,
        openAiImagesVersions: generateImgRes.data.oldImages
      }));
    } else {
      toast.error(generateImgRes?.error || "Something went wrong.", { id: "Toast-01" });
    }
  }

  const formatTimeMinuteAndSeconds = (time) => {
    const minutes = Math.floor(time / 60);
    const remainingSeconds = time % 60;
    return `${minutes < 10 ? '0' : ''}${minutes} : ${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
  };

  const handleVersionChange = (value) => {
    setSelectedVersion(value);
    const images = postGeneratedImages?.openAiImagesVersions?.filter((version) => version[value?.value])[0]?.[value?.value]?.images;
    setPostGeneratedImages((pre) => ({ ...pre, openAiImages: images }));
  };

  const handleSetImageAndPrompt = (imgUrl) => {
    const tabsDataDiv = document.querySelector(".tabsData");
    if (postGeneratedImages.newAiImages && postGeneratedImages.newAiImages.length > 0) {
      const imgObj = postGeneratedImages.newAiImages.find((item) => item.image === imgUrl);
      if (imgObj && imgObj.text && imgObj.text !== "") {
        setPostImagesModal((prev) => ({ ...prev, selectedImage: imgObj.image, imagePrompt: imgObj.text }));
        if (tabsDataDiv) { tabsDataDiv.scrollTo({ top: 0, behavior: "smooth" }) };
        setPromptText(imgObj.text);
      }
    }

    if (props.articleType === "1-Click Blog" && postGeneratedImages && postGeneratedImages.subType === "Short Article Images") {
      try {
        const data = generatedFullArticle?.h2_images ? JSON.parse(generatedFullArticle?.h2_images) : [];
        const imgObj = data.find(obj => obj.image === imgUrl);

        if (imgObj && imgObj.prompt && imgObj.prompt !== "") {
          setPromptText(imgObj.prompt);
          setPostImagesModal((prev) => ({ ...prev, selectedImage: imgObj.image, imagePrompt: imgObj.prompt }));
          dispatch(setGeneratedFullArticle({ ...generatedFullArticle, selectedImage: imgUrl, imagePrompt: imgObj.prompt }));
          if (tabsDataDiv) { tabsDataDiv.scrollTo({ top: 0, behavior: "smooth" }) };
        }
      } catch (error) { }
    } else if (props.articleType === "Blog Co-Pilot" && postGeneratedImages && postGeneratedImages.subType === "Article Images") {
      try {
        const data = finalContent?.h2_images ? JSON.parse(finalContent?.h2_images) : [];
        const imgObj = data.find(obj => obj.image === imgUrl);

        if (imgObj && imgObj.prompt && imgObj.prompt !== "") {
          setPromptText(imgObj.prompt);
          setPostImagesModal((prev) => ({ ...prev, selectedImage: imgObj.image, imagePrompt: imgObj.prompt }));
          dispatch(setFinalContent({ ...finalContent, selectedImage: imgUrl, imagePrompt: imgObj.prompt }));
          if (tabsDataDiv) { tabsDataDiv.scrollTo({ top: 0, behavior: "smooth" }) };
        }
      } catch (error) { }
    }
  };

  const handleGenerateImagePromptChange = async () => {
    try {
      if (!promptText || promptText === "") {
        return false;
      }

      dispatch(setLoader(true));
      const apiParams = postImagesModal.apiParams;
      const params = {
        type: apiParams.type, subType: apiParams.subType,
        imgPrompt: promptText, articleId: apiParams.articleId,
      }
      const payload = { body: params };
      const res = await invokeAxiosPost("/media-library/get-or-generate/generateAiImageUsingPrompt", payload);
      dispatch(setLoader(false));
      if (res && res.success === 1 && res.data) {
        setNewAiImages(res.data); setPromptText("");
        setPostGeneratedImages({ ...postGeneratedImages, newAiImages: res.data });
        setPostImagesModal((prev) => ({ ...prev, selectedImage: "", imagePrompt: "" }));
        dispatch(setGeneratedFullArticle({ ...generatedFullArticle, selectedImage: "", imagePrompt: "" }));
        dispatch(setFinalContent({ ...finalContent, selectedImage: "", imagePrompt: "" }));
      }
    } catch (error) {
      dispatch(setLoader(false));
      console.log(error);
    }
  };

  return (
    <div>
      {oonaImgGenerate && userRes.company === "Oona" ? (
        <div className="topButtonBlock d-flex align-items-center gap-3 justify-content-between mb-3">
          <div className="leftBtns d-flex flex-wrap gap-3 align-items-center">
            <div className="genButton d-flex align-items-center">
              <h4 className="fw-normal">Select image to generate new image ( <b>{10 - (newAiImages.length || 0)} / 10</b> )</h4>
              <BsInfoCircleFill
                type="button" className="text-decoration-none outline-0 me-2 fs-5 infoIconSvg" data-tooltip-id={"my-tooltip"}
                data-tooltip-place="top" data-tooltip-content={`You can generate new image only 10 times`}
              />
            </div>
          </div>
        </div>
      ) : (
        <div className="topButtonBlock d-flex align-items-center gap-3 justify-content-between">
          <div className="leftBtns d-flex flex-wrap gap-3 align-items-center ">
            <div className="dropdown">
              <DropdownMenu selectedOption={selectedVersion} onSelect={handleVersionChange}
                options={versions} label={'name'} placeholder={'Version history'} selectedLabel={'label'} />
            </div>
            <div className="genButton d-flex align-items-center">
              {postGeneratedImages && (postGeneratedImages.isAiImgGenerated || postGeneratedImages?.openAiImages?.length > 0) ? (
                <button type="button" className="blogButton border-0 generate-btn " onClick={handleRegenerateAiGenImages}
                  disabled={isRegenerateAiImg || !postGeneratedImages.openAiImgRegenLeft}
                >
                  <FiRefreshCw className={`fs-6 ${isRegenerateAiImg ? "svg-rotate" : ""}`} />
                  <span>{isRegenerateAiImg ? "Regenerating ..." : `Re-Generate  `}</span>
                  {regenerateAiImgTime && regenerateAiImgTime > 0 ? (
                    <span className="ms-2">{formatTimeMinuteAndSeconds(regenerateAiImgTime)}</span>
                  ) : null}
                </button>
              ) : (
                <button type="button" className="blogButton border-0" onClick={handleGenerateAiGenImages}
                  disabled={isRegenerateAiImg || postImagesModal.aiImageGenerating}
                >
                  <BsStars /> <span>{isRegenerateAiImg ? "Generating ..." : "Generate Images"}</span>
                  {regenerateAiImgTime && regenerateAiImgTime > 0 ? (
                    <span className="ms-2">{formatTimeMinuteAndSeconds(regenerateAiImgTime)}</span>
                  ) : null}
                </button>
              )}

              <BsInfoCircleFill
                type="button" className="text-decoration-none outline-0 me-2 fs-5 infoIconSvg" data-tooltip-id={"my-tooltip"}
                data-tooltip-place="top" data-tooltip-content={`You can re-generate only 3 times`}
              />
            </div>
          </div>
          <div className="selectIamgesB d-flex align-items-center gap-3">
            <p data-tooltip-id="my-tooltip" data-tooltip-place="bottom-end"
              data-tooltip-content="This feature is currently in development; generations are free, with a maximum of three regenerations allowed, and some issues may occur."
            >
              <BsInfoCircleFill className="fs-4" style={{ fill: "#0039ff" }} />
            </p>
            {postImagesModal && postImagesModal.carouselImg && postImagesModal.carouselImg.length > 0 && (
              <button type="button" className="blogButton border-0" onClick={() => handleSaveSelectedImage(postImagesModal)}>
                <FaRegImage /> Upload Images
              </button>
            )}
          </div>
        </div>
      )}

      {postImagesModal && postImagesModal.imagePrompt && postImagesModal.imagePrompt !== "" && (
        <Row className="genrateFields my-4 justify-content-start">
          <Col sm="12" className="mb-2">
            <div style={{ color: "#575b68", fontWeight: "500" }}>Selected Image</div>
          </Col>
          <Col sm="12" md="6">
            <img src={postImagesModal.selectedImage} alt="" style={{ maxWidth: "100%", borderRadius: "5px" }} />
          </Col>
          <Col sm="12" md="6" className="summeryBlock">
            <textarea
              value={promptText} onChange={(e) => setPromptText(e.target.value)} type="text" rows="5"
              className={`addllyForm-control w-100 p-2`} placeholder="Insert image prompt"
              style={{ borderWidth: "2px", borderRadius: "5px", outline: "none" }}
            />
            <button
              type="button" className="blogButton border-0 px-4 mt-2" onClick={handleGenerateImagePromptChange}
              disabled={postImagesModal?.imagePrompt === promptText ? true : false}
            >
              <BsStars /> Generate AI Image
            </button>
          </Col>
        </Row>
      )}
      {newAiImages && newAiImages.length > 0 && (
        <div className="brandchooseFile">
          <div className="fieldSetText position-relative">
            <p className="d-flex align-items-center gap-2 m-0 px-3">
              <img src={oneClickBlogIcon.glitterStar} alt="glitterStar" /> New Generated Images
            </p>
          </div>
          <div className="genrateImagesCards d-flex flex-wrap">
            {newAiImages.map((ele, index) => (
              <ImageDisplayContent
                image={ele.image} key={index} viewImgHistory={newAiImages} imageIndex={index}
                postImagesModal={postImagesModal} setPostImagesModal={setPostImagesModal}
                handleSaveSelectedImage={handleSaveSelectedImage} oonaImgGenerate={oonaImgGenerate}
                handleSetImageAndPrompt={handleSetImageAndPrompt}
              />
            ))}
          </div>
          <div className="fieldSetText position-relative mt-4">
            <p className="d-flex align-items-center gap-2 m-0 px-3">
              <img src={oneClickBlogIcon.glitterStar} alt="glitterStar" /> Old Images
            </p>
          </div>
        </div>
      )}
      {postImagesModal && postImagesModal.aiImageGenerating ? (
        <Row className=" mt-4 justify-content-start gap-3">
          {[1, 2, 3, 4, 5].map((ele, index) => (
            <div className="placeholderI" key={index}>
              <Placeholder as="p" animation="glow" className="overflow-hidden rounded-3 mb-3" style={{ height: "190px" }}>
                <Placeholder xs={12} className="h-100" />
              </Placeholder>
            </div>
          ))}
        </Row>
      ) : (
        <div className="genrateImagesCards d-flex flex-wrap">
          {(postGeneratedImages && postGeneratedImages.openAiImages && postGeneratedImages.openAiImages.length > 0) ? (
            postGeneratedImages.openAiImages.map((image, index) => (
              <ImageDisplayContent
                image={image} key={index} viewImgHistory={postGeneratedImages.openAiImages} imageIndex={index}
                postImagesModal={postImagesModal} setPostImagesModal={setPostImagesModal}
                handleSaveSelectedImage={handleSaveSelectedImage} oonaImgGenerate={oonaImgGenerate}
                handleSetImageAndPrompt={handleSetImageAndPrompt}
              />
            ))
          ) : (
            <NoDataAvailable text="Images not available yet!" />
          )}
        </div>
      )}
    </div>
  );
};

export default AIGeneratedImage;
