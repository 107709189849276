import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  step: 0,
  smartWriteStep: 1,
  seoOptBlogStep: 1,
  socialMediaPostStep: 1,
  brandVoiceStep: 1,
  brandProductImageStep: 1,
  newsletterWriterStep: 1,
  pressReleaseWriterStep: 1,
  eCommerceBlogStep: 1,
  seoOptBlogTopic: {},
  generateData: {},
  keywordTopic: [],
  semanticKeywordRes: { chatGPT: [] },
  selectSemanticKey: [],
  seoGenerateTopic: "",
  setsOfSeoOptimized: {},
  selectedTopic: "",
  selectedHeadline: "",
  googleSuggestKeyword: "",
  headline: "",
  noOfClick: 3,
  loader: false,
  password: null,
  headlineData: [],
  activeMenu: null,
  outlineRes: {},
  finalContent: "",
  username: "",
  userRes: {},
  currentPlan: {},
  userPlan: null,
  topResultsStep: 1,
  generatedFullArticle: {},
  historyData: [],
  socialMediaPostInfo: {},
  socialMediaPostHistory: {},
  socialMediaShareHistory: {},
  brandVoiceInfo: {},
  brandVoiceHistory: {},
  brandProductImageInfo: {},
  brandProductImageHistory: [],
  newsletterWriterInfo: {},
  newsletterWriterHistory: {},
  pressReleaseWriterInfo: {},
  pressReleaseWriterHistory: {},
  eCommerceBlogInfo: {},
  eCommerceBlogHistory: {},
  featuresData: [],
  pinnedFeaturesData: [],
  tatlerSeoBlogStep: 1,
  tatlerSeoBlogInfo: {},
  azgoNewsletterStep: 1,
  azgoNewsletterInfo: {},
};
export const reducer = createSlice({
  name: "ADDLLY",
  initialState,
  reducers: {
    resetReduxData: (state, { payload }) => {
      Object.keys(initialState).map((key) => {
        state[key] = initialState[key];
        return key;
      })
    },
    setStep: (state, { payload }) => {
      state.step = payload;
    },
    setSmartWriteStep: (state, { payload }) => {
      state.smartWriteStep = payload;
    },
    setSeoOptBlogStep: (state, { payload }) => {
      state.seoOptBlogStep = payload;
    },
    setSeoOptBlogTopic: (state, { payload }) => {
      state.seoOptBlogTopic = payload;
    },
    setGenerateData: (state, { payload }) => {
      state.generateData = payload;
    },
    setKeywordTopic: (state, { payload }) => {
      state.keywordTopic = payload;
    },
    updateKeywordTopic: (state, { payload: { index, txt } }) => {
      state.keywordTopic[index].keyword = txt;
    },
    setSelectedTopic: (state, { payload }) => {
      state.selectedTopic = payload;
    },
    setSelectedGoogleWebsite: (state, { payload }) => {
      state.selectedGoogleWebsite = payload;
    },
    setSelectSemanticKey: (state, { payload }) => {
      state.selectSemanticKey = payload;
    },
    setSelectedHeadline: (state, { payload }) => {
      state.selectedHeadline = payload;
    },
    setSeoGenerateTopic: (state, { payload }) => {
      state.seoGenerateTopic = payload;
    },
    setSeoOptimizedSets: (state, { payload }) => {
      state.setsOfSeoOptimized = payload;
    },
    setGoogleSuggestKeyword: (state, { payload }) => {
      state.googleSuggestKeyword = payload;
    },
    setHeadline: (state, { payload }) => {
      state.headline = payload;
    },
    setHeadlineData: (state, { payload }) => {
      state.headlineData = payload;
    },
    setNoOfClick: (state, { payload }) => {
      state.noOfClick = payload;
    },
    setLoader: (state, { payload }) => {
      state.loader = payload;
    },
    setPassword: (state, { payload }) => {
      state.password = payload;
      localStorage.setItem("password", payload);
    },
    setOutlineRes: (state, { payload }) => {
      state.outlineRes = payload;
    },
    setFinalContent: (state, { payload }) => {
      state.finalContent = payload;
    },
    setUsername: (state, { payload }) => {
      state.username = payload;
    },
    setUserRes: (state, { payload }) => {
      state.userRes = payload;
    },
    setCurrentPlan: (state, { payload }) => {
      state.currentPlan = payload;
    },
    setUserPlan: (state, { payload }) => {
      state.userPlan = payload;
    },
    setTopResultsStep: (state, { payload }) => {
      state.topResultsStep = payload;
    },
    setGeneratedFullArticle: (state, { payload }) => {
      state.generatedFullArticle = payload;

    },
    setHistoryData: (state, { payload }) => {
      state.historyData = payload;
    },
    setHistoryDataLength: (state, { payload }) => {
      state.historyDataLength = payload;
    },
    resetSEOFLow: (state) => {
      state.noOfClick = 3;
      state.keywordTopic = [];
      state.seoGenerateTopic = "";
      state.setsOfSeoOptimized = {};
      state.selectedTopic = "";
      state.selectedGoogleWebsite = [];
      state.headline = "";
      state.googleSuggestKeyword = "";
      state.seoOptBlogTopic = {};
      state.semanticKeywordRes = { chatGPT: [] };
      state.headlineData = [];
      state.selectSemanticKey = [];
      state.outlineRes = {};
      state.finalContent = "";
    },
    setActiveMenu: (state, { payload }) => {
      state.activeMenu = payload;
    },
    setSocialMediaPostStep: (state, { payload }) => {
      state.socialMediaPostStep = payload;
    },
    setSocialMediaStepsInfo: (state, { payload }) => {
      state.socialMediaPostInfo = payload;
    },
    setSocialMediaPostHistory: (state, { payload }) => {
      state.socialMediaPostHistory = payload;
    },
    setSocialMediaShareHistory: (state, { payload }) => {
      state.socialMediaShareHistory = payload;
    },
    setBrandVoiceStep: (state, { payload }) => {
      state.brandVoiceStep = payload;
    },
    setBrandVoiceInfo: (state, { payload }) => {
      state.brandVoiceInfo = payload;
    },
    setBrandVoiceHistory: (state, { payload }) => {
      state.brandVoiceHistory = payload;
    },
    setBrandProductImageStep: (state, { payload }) => {
      state.brandProductImageStep = payload;
    },
    setBrandProductImageInfo: (state, { payload }) => {
      state.brandProductImageInfo = payload;
    },
    setBrandProductImageHistory: (state, { payload }) => {
      state.brandProductImageHistory = payload;
    },
    setNewsletterWriterStep: (state, { payload }) => {
      state.newsletterWriterStep = payload;
    },
    setNewsletterWriterInfo: (state, { payload }) => {
      state.newsletterWriterInfo = payload;
    },
    setNewsletterWriterHistory: (state, { payload }) => {
      state.newsletterWriterHistory = payload;
    },
    setPressReleaseWriterStep: (state, { payload }) => {
      state.pressReleaseWriterStep = payload;
    },
    setPressReleaseWriterInfo: (state, { payload }) => {
      state.pressReleaseWriterInfo = payload;
    },
    setPressReleaseWriterHistory: (state, { payload }) => {
      state.pressReleaseWriterHistory = payload;
    },
    setECommerceBlogStep: (state, { payload }) => {
      state.eCommerceBlogStep = payload;
    },
    setECommerceBlogInfo: (state, { payload }) => {
      state.eCommerceBlogInfo = payload;
    },
    setECommerceBlogHistory: (state, { payload }) => {
      state.eCommerceBlogHistory = payload;
    },
    setFeaturesData: (state, { payload }) => {
      state.featuresData = payload;
    },
    setPinnedFeaturesData: (state, { payload }) => {
      state.pinnedFeaturesData = payload;
    },
    setTatlerSeoBlogStep: (state, { payload }) => {
      state.tatlerSeoBlogStep = payload;
    },
    setTatlerSeoBlogInfo: (state, { payload }) => {
      state.tatlerSeoBlogInfo = payload;
    },
    setAzgoNewsletterStep: (state, {payload}) => {
      state.azgoNewsletterStep = payload;
    },
    setAzgoNewsletterInfo: (state, {payload}) => {
      state.azgoNewsletterInfo = payload;
    }
  },
});

export const {
  resetReduxData, setStep, setSmartWriteStep, setSeoOptBlogStep, setSeoOptBlogTopic, setGenerateData, setKeywordTopic,
  setSelectedTopic, setSelectedGoogleWebsite, setSelectSemanticKey, setSeoGenerateTopic, setGoogleSuggestKeyword,
  setLoader, setPassword, updateKeywordTopic, setNoOfClick, setSeoOptimizedSets, setSelectedHeadline, setHeadline,
  resetSEOFLow, setHeadlineData, setActiveMenu, setOutlineRes, setFinalContent, setUserRes, setCurrentPlan, setUserPlan,
  setUsername, setTopResultsStep, setGeneratedFullArticle, setHistoryData, setHistoryDataLength, setSocialMediaPostStep,
  setSocialMediaStepsInfo, setSocialMediaPostHistory, setSocialMediaShareHistory, setBrandVoiceStep, setBrandVoiceInfo,
  setBrandVoiceHistory, setBrandProductImageStep, setBrandProductImageInfo, setBrandProductImageHistory,
  setPressReleaseWriterStep, setPressReleaseWriterInfo, setPressReleaseWriterHistory, setNewsletterWriterStep,
  setNewsletterWriterInfo, setNewsletterWriterHistory, setECommerceBlogStep, setECommerceBlogInfo, setECommerceBlogHistory,
  setFeaturesData, setPinnedFeaturesData, setTatlerSeoBlogStep, setTatlerSeoBlogInfo, setAzgoNewsletterStep, setAzgoNewsletterInfo
} = reducer.actions;

export default reducer.reducer;
