import React, { useEffect, useMemo, useState } from "react";
import { Col } from "react-bootstrap";

import { copyIcon } from "../../Assets/Icons";
import { BsInfoCircleFill } from "react-icons/bs";
import { useLocation, useSearchParams } from 'react-router-dom'
import { ImEarth } from "react-icons/im";
import classNames from "classnames";
import { useSelector } from "react-redux";
import { invokeAxiosPost } from "../../utility/invokeAxiosFunction";

const GoogleAdCopy = ({ edit, generatedFullArticle, setEdit, selectedTab, userRes, handleCopyHtmlArticleText }) => {
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const selectedSubTab = useMemo(() => (searchParams.get("subTab")), [searchParams]);
  const seoOptBlogTopic = useSelector(({ seoOptBlogTopic }) => seoOptBlogTopic);
  const googleSuggestKeyword = useSelector(({ googleSuggestKeyword }) => googleSuggestKeyword);
  const [data, setData] = useState({});

  useEffect(() => {
    handleGetCpcKeywords();
  }, [])

  const handleGetCpcKeywords = async () => {
    const endpoint = pathname.includes('/1-click-blog') ? 'cpcShortFlow' : 'cpcLongFlow';
    const params = { userId: userRes.id };

    if (pathname.includes('/1-click-blog')) {
      params.articleId = generatedFullArticle?.articleId;
      params.keyword = generatedFullArticle.keyword;
    } else {
      params.articleId = seoOptBlogTopic?.articleId ? seoOptBlogTopic.articleId : "";
      params.keyword = googleSuggestKeyword || "";
    }

    try {
      const response = await invokeAxiosPost(`/article-history/get-or-save/${endpoint}`, params);
      if (response && response.data) {
        setData(response.data);
      }
    } catch (error) {
      setData({});
    }
  }

  return (
    <React.Fragment>
      <div className={classNames("textEditerArea", { "notContent p-4": !edit?.googleAdCopy })}>
        {edit?.googleAdCopy ? (
          <div className="socialPostEditor">
            <div className="position-relative">
              <div className="custom-textarea-editor">
                <div className="innerTextcards">
                  {Array.from({ length: 3 }).map((_, index) => (
                    <div key={index + ' '} className="mb-4">
                      <p>Headline {index + 1}</p>
                      <span>
                        {edit.googleAdCopy.substring(edit.googleAdCopy.indexOf(`Headline ${index + 1}`),
                          edit.googleAdCopy.indexOf(index + 1 === 3 ? "Description 1" : `Headline ${index + 1 + 1}`)
                        ).replace(`Headline ${index + 1}: `, "")}
                      </span>
                    </div>
                  ))}
                </div>
                <div className="innerTextcards">
                  {Array.from({ length: 3 }).map((_, index) => (
                    <div key={index + ' ' + index} className="mb-4">
                      <p>Description{index + 1}</p>
                      <span>
                        {edit.googleAdCopy.substring(edit.googleAdCopy.indexOf(`Description ${index + 1}`),
                          edit.googleAdCopy.indexOf(index + 1 === 3 ? "Display Path" : `Description ${index + 1 + 1}: `)
                        ).replace(`Description ${index + 1}: `, "")}
                      </span>
                    </div>
                  ))}
                </div>
                <div className="innerTextcards">
                  <div className="mb-4">
                    <p>Slug</p>
                    <span>
                      {"https://www." + edit.googleAdCopy.substring(edit.googleAdCopy.indexOf("Display Path"),
                        edit.googleAdCopy.indexOf("Call to Action")).replace("Display Path: ", "")?.replace('www.', '')}
                    </span>
                  </div>
                  <div className="mb-4">
                    <p>Call to Action</p>
                    <span>
                      {edit.googleAdCopy.substring(edit.googleAdCopy.indexOf("Call to Action")).replace("Call to Action: ", "")}
                    </span>
                  </div>
                </div>
              </div>
              {edit?.googleAdCopy && edit?.googleAdCopy !== "" && (
                <button
                  type="button" className="copy-textarea-btn" onClick={() => handleCopyHtmlArticleText(edit?.googleAdCopy)}
                  data-tooltip-id="my-tooltip" data-tooltip-content="Copy to clipboard" data-tooltip-place="bottom"
                >
                  <img src={copyIcon} alt="copy" />
                </button>
              )}
            </div>
          </div>
        ) : (
          <div></div>
        )}
      </div>

      <div className={classNames("toggleData", /* { "notContent p-4": !edit?.googleAdCopy } */)}>
        {selectedTab === "googleAdCopy" && selectedSubTab === "postPreview" && (
          <React.Fragment>
            <div className="d-flex align-items-center justify-content-start mb-3">
              <strong className="text-center google-add-review">Google Ad Preview</strong>
              <BsInfoCircleFill
                className="text-decoration-none cursor-pointer outline-0 ms-2 fs-5 infoIconSvg"
                data-tooltip-id={"my-tooltip"} data-tooltip-place="bottom" data-tooltip-content={`Google Ad Preview`}
              />
            </div>
            {edit?.googleAdCopy && edit.googleAdCopy !== "" ? (
              <GoogleAdPreview googleAdCopy={edit.googleAdCopy} keywordCPC={data?.data?.length ? data.data : []} col={12} />
            ) : (
              <GoogleAdEmptyPreview googleAdCopy={""} keywordCPC={[]} col={12} />
            )}
          </React.Fragment>
        )}
      </div>
    </React.Fragment>
  );
};

export default GoogleAdCopy;

const GoogleAdPreview = ({ googleAdCopy, keywordCPC, col = 6 }) => {
  return (
    <Col xl={col}>
      <div className="preview-container p-0">
        {keywordCPC && keywordCPC.length > 0 && (
          <div className="table-main">
            <div className="table-header d-flex justify-content-between align-items-center mb-3">
              <p className="m-0"><span className="bold">Top Keywords</span></p>
              <p className="d-flex align-items-center gap-2 values">
                <span className="bold">CPC</span>
                <BsInfoCircleFill style={{ height: "12px" }}
                  data-tooltip-id={"my-tooltip"} data-tooltip-place="bottom" data-tooltip-content={`Cost Per Click`} />
                {/* <span>{"(Cost Per Click)"}</span> */}
              </p>
            </div>
            <div className="table-body">
              {keywordCPC.map(({ keyword, cpc }, index) => (
                <div key={keyword + "" + index} className="d-flex justify-content-between align-items-center mb-3 tableCard">
                  <span className="text-start keywords">{keyword}</span>
                  <span className="text-start values"><strong>{cpc}</strong></span>
                </div>
              ))}
            </div>
          </div>
        )}

        <div className="bg-white mt-4 sponsoredBlock rounded">
          {[1, 2, 3].map((ele) => (
            <div className="sponsoredCards" key={ele}>
              <strong className="d-block">Sponsored</strong>
              <div className="d-flex gap-3 mb-2 align-items-center position-relative ">
                <div className="grayDot rounded-5 d-flex"></div>
                <div className="position-absolute earth-icon">
                  <ImEarth className="fs-3" />
                </div>
                <div>
                  <h6 className="">
                    {googleAdCopy.substring(googleAdCopy.indexOf("Display Path"), googleAdCopy.indexOf("Call to Action")).replace("Display Path: ", "")}
                  </h6>
                  <div className="linkUrl">
                    {"https://www." + googleAdCopy.substring(googleAdCopy.indexOf("Display Path"),
                      googleAdCopy.indexOf("Call to Action")).replace("Display Path: ", "")?.replace('www.', '')}
                  </div>
                </div>
              </div>
              <h4 className="text-primary">
                {googleAdCopy.substring(
                  googleAdCopy.indexOf(`Headline ${ele}`),
                  googleAdCopy.indexOf(ele === 3 ? "Description 1" : `Headline ${ele + 1}`)
                ).replace(`Headline ${ele}: `, "")}
              </h4>
              <p className="textEllipsis">
                {googleAdCopy.substring(
                  googleAdCopy.indexOf(`Description ${ele}`),
                  googleAdCopy.indexOf(ele === 3 ? "Display Path" : `Description ${ele + 1}: `)
                ).replace(`Description ${ele}: `, "")}
              </p>
              <span className="callToAction">
                {googleAdCopy.substring(googleAdCopy.indexOf("Call to Action")).replace("Call to Action: ", "")}
              </span>
            </div>
          ))}
        </div>
      </div>
    </Col>
  );
};

const GoogleAdEmptyPreview = ({ googleAdCopy, keywordCPC, col = 6 }) => {
  return (
    <Col xl={col}>
      <div className="preview-container p-0">
        <div className="bg-white mt-4 sponsoredBlock rounded">
          <div className="sponsoredCards">
            <strong className="d-block">Sponsored</strong>
            <div className="d-flex gap-3 mb-2 align-items-center position-relative ">
              <div className="grayDot rounded-5 d-flex"></div>
              <div className="position-absolute earth-icon">
                <ImEarth className="fs-3" />
              </div>
              <div>
                <h6 className="">Addlly AI</h6>
                <div className="linkUrl">https://addlly.ai</div>
              </div>
            </div>
            <h4 className="text-primary">Addlly - Best AI Writer, Social Media & Marketing Tool</h4>
            <p className="textEllipsis">
              Addlly AI is the most advanced AI writer, social media post generator and marketing tool.
              Transform your website, blogs and social media presence with AI.
            </p>
            <span className="callToAction">More results from addlly.ai »</span>
          </div>
        </div>
      </div>
    </Col>
  );
};
