import React, { useState } from "react";
import { Alert, Modal } from "react-bootstrap";

import TwitterStep1 from "../../Assets/Images/twitter-steps/step-1.png";
import TwitterStep2 from "../../Assets/Images/twitter-steps/step-2.gif";
import TwitterStep3 from "../../Assets/Images/twitter-steps/step-3.png";
import TwitterStep4 from "../../Assets/Images/twitter-steps/step-4.png";
import TwitterStep5 from "../../Assets/Images/twitter-steps/step-5.png";
import TwitterStep6 from "../../Assets/Images/twitter-steps/step-6.gif";
import TwitterStep7 from "../../Assets/Images/twitter-steps/step-7.png";
import TwitterStep8 from "../../Assets/Images/twitter-steps/step-8.png";
import TwitterStep9 from "../../Assets/Images/twitter-steps/step-9.gif";
import TwitterStep10 from "../../Assets/Images/twitter-steps/step-10.png";
import TwitterStep11 from "../../Assets/Images/twitter-steps/step-11.png";
import TwitterStep12 from "../../Assets/Images/twitter-steps/step-12.png";

const TwitterConnectStep = () => {
  const [show, setShow] = useState(false);
  const [modalImage, setModalImage] = useState(null);

  const handleOpenModal = (e) => {
    setShow(true);
    setModalImage(e.target?.src ? e.target.src : null);
  }

  const handleCloseModal = () => {
    setShow(false);
    setModalImage(null);
  }

  return (
    <div className="twitter-step-modal">
      <div className="twitter-step-content">
        <h2>How Do I Connect Twitter Using My App?</h2>
        <p>
          After Twitter's API changes in 2023, you might opt to connect your Twitter account using your own {" "}
          <a href="https://developer.twitter.com/en/docs/apps/overview" target="_blank" rel="noreferrer">Twitter App</a>.
          In order to do this, you must have an app that you have fully set up, as per Twitter requirements.
        </p>
        <Alert variant="warning" className="my-3 p-3" style={{ background: "#ffecb599" }}>
          <h5 className="fw-normal">
            For each Twitter profile you want to connect, will have to <b>create a new app, while connected into that Twitter Profile.</b>
          </h5>
        </Alert>
        <h3 className="mb-3">How Do I Create My Own App?</h3>
        <p>
          To create your own Twitter app, you must have an active Twitter account (in the last 30 days) with a verified phone number.
          Once you did this, you can head to their <a href="https://developer.twitter.com/en" target="_blank" rel="noreferrer">Developer Platform</a>.
          Scroll all the way to the bottom, and click "Sign up for Free Account".
        </p>
        <img src={TwitterStep1} alt="step-img" onClick={handleOpenModal} onKeyDown={handleOpenModal} />

        <p> You will be asked to describe why you want to use their API. You can paste the following text:</p>
        <p>
          👉 Managing social media accounts can be time-consuming, especially when you're trying to maintain an active
          and engaging online presence across multiple platforms. That's where my app comes in. By integrating with
          various social media platforms, my app offers a one-stop-shop for managing your social media accounts, allowing you to create,
          categorize, and schedule your Tweets in advance. With this tool, you can optimize your online presence and strategy without
          having to spend hours manually posting updates. By streamlining your social media management, you can focus on other
          aspects of your business or personal life, while still maintaining a strong and consistent online presence.
        </p>
        <p> After this, agree to all terms and conditions and click "Submit".</p>
        <img src={TwitterStep2} alt="step-img" onClick={handleOpenModal} onKeyDown={handleOpenModal}/>

        <p> Twitter will, automatically, create a project and an app for you. They will have a default name, like so:</p>
        <img src={TwitterStep3} alt="step-img" onClick={handleOpenModal} onKeyDown={handleOpenModal}/>

        <p>
          If you have ever followed these steps before, you can just go to your &nbsp;
          <a href="https://developer.twitter.com/en/portal/projects-and-apps" target="_blank" rel="noreferrer">Projects and Apps here</a>.
          The next step is clicking on the Gear icon, to customize your app.
        </p>
        <img src={TwitterStep4} alt="step-img" onClick={handleOpenModal} onKeyDown={handleOpenModal}/>

        <p> In the App Settings screen, find "User authentication settings" and click "Set up":</p>
        <img src={TwitterStep5} alt="step-img" onClick={handleOpenModal} onKeyDown={handleOpenModal}/>

        <p className="mb-2"> Here, you must ensure you fill in the following information exactly:</p>
        <ul>
          <li>For <strong>App Permissions</strong> select "Read and write" or "Read and write and Direct messages"</li>
          <li>For <strong>Type of App</strong>, select "Web App, Automated App or Bot"</li>
        </ul>
        <img src={TwitterStep6} alt="step-img" onClick={handleOpenModal} onKeyDown={handleOpenModal}/>

        <p> Once all this information is filled in, scroll to the bottom and click "Save".</p>
        <img src={TwitterStep7} alt="step-img" onClick={handleOpenModal} onKeyDown={handleOpenModal}/>

        <p> You will be asked to confirm the changed permissions. Here, click "Yes" and you will be redirected to the App details screen.</p>
        <img src={TwitterStep8} alt="step-img" onClick={handleOpenModal} onKeyDown={handleOpenModal}/>

        <p> In the App Details, switch to the "Keys and tokens" tab. Find the Consumer Keys and click "Regenerate".</p>
        <img src={TwitterStep9} alt="step-img" onClick={handleOpenModal} onKeyDown={handleOpenModal}/>

        <p>
          To get your new Keys, click "Yes, regenerate". Like before, you will be asked to copy 2 different chains of letters and numbers.
          We recommend <strong style={{ "background-color": "initial" }}>saving all of these keys in a separate document,
            as you will use them later, in SocialBee</strong>. Once you have them, click "Yes, I saved them" to close the popup.
        </p>
        <p> <strong></strong></p>
        <img src={TwitterStep10} alt="step-img" onClick={handleOpenModal} onKeyDown={handleOpenModal}/>

        <p> Next, go to Authentication Tokens, to get your Access Token and Secret.</p>
        <img src={TwitterStep11} alt="step-img" onClick={handleOpenModal} onKeyDown={handleOpenModal}/>

        <p>
          You will be asked to copy 2 different chains of letters and numbers. We recommend
          <strong>saving all of these keys in the same document, as you will use them later, in SocialBee</strong>.
          Once you have them, click "Yes, I saved them" to close the popup.
        </p>
        <img src={TwitterStep12} alt="step-img" onClick={handleOpenModal} onKeyDown={handleOpenModal}/>

        <p> After saving all of these 4 keys, you can close Twitter. Your app has been successfully created.</p>
      </div>

      {show && (
        <Modal show={show} fullscreen onHide={handleCloseModal} className="">
          <Modal.Body className="d-flex align-items-center bg-dark">
            <img src={modalImage} alt="step-img" className="w-100" onClick={handleCloseModal} onKeyDown={handleOpenModal} style={{ cursor: "zoom-out" }} />
          </Modal.Body>
        </Modal>
      )}
    </div>
  )
}

export default TwitterConnectStep;
