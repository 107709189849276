import React from "react";
import { useSelector } from "react-redux";
import { articleHistoryIcons } from "../Assets/Icons";

const NoDataAvailable = ({ text = "History is not available yet!" }) => {
  const loader = useSelector(({ loader }) => loader);

  return (
    <div className="no-data-wrapper">
      <div className="d-flex justify-content-center flex-column align-items-center p-1">
        <div className="icon d-flex justify-content-center flex-column align-items-center">
          <img src={articleHistoryIcons.add} alt="add"></img>
        </div>
        <p>{loader ? "Data Loading..." : text}</p>
      </div>
    </div>
  )
}

export default NoDataAvailable;
