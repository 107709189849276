import React from "react";
import { Col, Modal, Row } from "react-bootstrap";
import { MdOutlineClose } from "react-icons/md";

const ProductViewModal = ({ viewProduct, setViewProduct }) => {

  const handleClose = () => {
    setViewProduct({ open: false, data: null });
  };

  return (
    <React.Fragment>
      {viewProduct && viewProduct.open && (
        <Modal size="xl" centered show={viewProduct && viewProduct.open} onHide={handleClose}>
          <Modal.Body>
            <div className="product-view-modal">
              <div className="close-button" type="button" onClick={handleClose} onKeyDown={handleClose}>
                <MdOutlineClose className="fs-4" />
              </div>
              <Row className="mt-5">
                <Col sm="6" className="main-image-wrapper">
                  <div className="image-wrapper" >
                    <img
                      src={viewProduct?.data && viewProduct.data.image}
                      alt={viewProduct?.data && viewProduct.data.name}
                      style={{ height: '100%', maxHeight: '80vh', width: '100%', objectFit: 'contain' }}
                    />
                  </div>
                </Col>
                <Col sm="6" className="px-5 overflow-auto" style={{ maxHeight: "80vh" }}>
                  <h3 className="fw-normal mb-3">
                    {(viewProduct?.data && viewProduct.data.name) || "-"}
                  </h3>
                  <h2 className="fw-normal mb-3">
                    {(viewProduct?.data && viewProduct.data.price) || "-"}
                  </h2>
                  <a href={viewProduct?.data && viewProduct.data.link} target="_blank" className="text-decoration-none mb-3" rel="noreferrer">
                    View Product
                  </a>
                  <hr />
                  <h5 className="mb-2">Product Information</h5>
                  <pre className="fw-normal fs-6" style={{ whiteSpace: "break-spaces" }}
                    dangerouslySetInnerHTML={{ __html: (viewProduct?.data && viewProduct.data.product_information) || "-" }}
                  ></pre>
                  <hr />
                  <h5 className="mb-2">About the Product</h5>
                  <div className="fw-normal"
                    dangerouslySetInnerHTML={{ __html: (viewProduct?.data && viewProduct.data.description) || "-" }}
                  ></div>
                </Col>
              </Row>
            </div>
          </Modal.Body>
        </Modal>
      )}
    </React.Fragment>
  )
}

export default ProductViewModal;
