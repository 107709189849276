import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import Swal from "sweetalert";
import { v4 as uuidv4 } from "uuid";
import copy from "copy-to-clipboard";
import { toast } from "react-hot-toast";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import CommentsTextEditor from "./CommentsTextEditor";
import CommentListComponent from "./CommentListComponent";
import SentReviewFormModal from "./SentReviewFormModal";
import VersionHistoryComponent from "./VersionHistoryComponent";
import { setLoader } from "../../../store/reducer";
import { invokeAxiosGet, invokeAxiosPost } from "../../../utility/invokeAxiosFunction";
const beautify_html = require("js-beautify").html;

const ReviewTextEditor = forwardRef((props, ref) => {
  const {
    articleId, articleType, articleSubType, isSelectedCurrentTab, isReviewArticle, toggleRightSideBar = false,
    handleSendArticleToReviewer, sendToEditorForm = false, setSendToEditorForm,
  } = props;

  const dispatch = useDispatch();
  const userRes = useSelector(({ userRes }) => userRes);
  const editorRef = useRef(null);

  const [commentList, setCommentList] = useState([]);
  const [editorText, setEditorText] = useState("");
  const [oldArticleText, setOldArticleText] = useState("");
  const [commentModal, setCommentModal] = useState(false);
  const [commentText, setCommentText] = useState("");
  const [replyCmtText, setReplyCmtText] = useState("");
  const [selectedCommentId, setSelectedCommentId] = useState("");
  const [currentStatus, setCurrentStatus] = useState("all");

  useEffect(() => {
    if (!isReviewArticle || isReviewArticle === 0) {
      handleGetArticleTextAndComments();
    } else if (isReviewArticle === 1 && isSelectedCurrentTab) {
      handleGetAllCommentList("all", true);
    }
  }, [])

  useImperativeHandle(ref, () => ({
    async handleSaveArticleTextAndNewVersion() {
      if (oldArticleText === editorText || currentStatus === "resolve") {
        return false;
      }

      try {
        dispatch(setLoader(true));

        let modifiedEditorText = editorText;
        if (oldArticleText && oldArticleText !== "" && /<html[^>]*>/.test(oldArticleText)) {
          const parser = new DOMParser();
          const bodyText = parser.parseFromString(modifiedEditorText, 'text/html').body.outerHTML;
          modifiedEditorText = `${oldArticleText.substring(0, oldArticleText.indexOf("<body"))} ${bodyText} </html>`
            .replaceAll("\n", " ").replaceAll("\t", " ").replace(/\s+/g, ' ').trim();
        }

        const dataObj = {
          userName: `${userRes.first_name || ""} ${userRes.last_name || ""}`,
          articleType: articleType,
          articleSubType: articleSubType,
          articleId: articleId,
          oldArticle: oldArticleText,
          newArticle: modifiedEditorText,
        };
        await invokeAxiosPost('/reviewer-editor/get-or-create/saveArticleOrPostTextAndNewVersion', dataObj);
        dispatch(setLoader(false));
        setOldArticleText(modifiedEditorText);
        setEditorText(modifiedEditorText);
        handleAddEventOnIframe();
      } catch (error) {
        toast.error("Something went wrong.");
        dispatch(setLoader(false));
      }
    }
  }));

  const handleGetArticleTextAndComments = async () => {
    setCommentList([]);
    setCurrentStatus("all");

    const data = await handleSendArticleToReviewer({ isSentToReviewer: false });
    if (data) {
      if (data.data && data.data.length > 0) {
        setCommentList(data.data);
      }

      if (data.articleText && data.articleText !== "") {
        setEditorText(data.articleText);
        setOldArticleText(data.articleText);
      }

      setTimeout(() => {
        handleAddEventOnIframe();
      }, 1000);
    }
  };

  const handleAddEventOnIframe = async () => {
    try {
      await new Promise(resolve => setTimeout(resolve, 2000));
      if (document.querySelector(".tox-edit-area") && editorRef && editorRef.current) {
        const iframe = document.querySelector(".tox-edit-area").querySelector("iframe");
        if (iframe && iframe.contentWindow && iframe.contentWindow.document && editorRef.current.selection) {
          const iframeDoc = iframe.contentWindow;
          iframeDoc.document.addEventListener("selectionchange", () => {
            const selection = iframeDoc.getSelection();
            if (selection && selection.toString().trim() !== "") {
              const range = selection.getRangeAt(0);
              const rect = range.getBoundingClientRect();
              const elements = document.getElementsByClassName("custom-tooltip");
              for (let i = 0; i < elements.length; i++) {
                elements[i].style.display = "block";
                elements[i].style.top = rect.top + 30 + "px";
                elements[i].style.left = rect.left + (rect.width / 2) + "px";
              }
              const element2 = document.getElementsByClassName("reviewArticle");
              for (let i = 0; i < element2.length; i++) {
                element2[i].classList.remove("overflow-hidden");
              }
            } else {
              handleCloseAddCommentModal();
            }
          });
        }
      }
    } catch (error) {
    }
  };

  const handleGetAllCommentList = (status, isLoader = false) => {
    setCurrentStatus(status);
    setSelectedCommentId("");
    if (isLoader) {
      setCommentList([]);
      dispatch(setLoader(true));
    }

    const queryParams = `status=${status}&articleType=${articleType}&articleSubType=${articleSubType}`;
    const url = `/reviewer-editor/get-or-create/getAllEditorCommentListByArticleId?articleId=${articleId}&${queryParams}`;
    invokeAxiosGet(url).then(async (response) => {
      if (response && response.data) {
        if (response.data.data && response.data.data.length > 0) {
          setCommentList(response.data.data);
        }

        if (response.data.articleText && response.data.articleText !== "") {
          let htmlText = response.data.articleText;
          if (status === "resolved") {
            htmlText = await removeSpanTags(htmlText);
          }

          if (response.data.articleText && response.data.articleText !== "" && /<html[^>]*>/.test(response.data.articleText)) {
            const parser = new DOMParser();
            const bodyText = parser.parseFromString(htmlText, "text/html").body.outerHTML;
            htmlText = `${response.data.articleText.substring(0, response.data.articleText.indexOf("<body"))} ${bodyText} </html>`
              .replaceAll("\n", " ").replaceAll("\t", " ").replace(/\s+/g, " ").trim();
          }

          setEditorText(htmlText);
          setOldArticleText(htmlText);
        }

        setTimeout(() => {
          handleAddEventOnIframe();
        }, 1000);
      }
      dispatch(setLoader(false));
    }).catch((error) => {
      toast.error("Something went wrong.");
      dispatch(setLoader(false));
    });
  };

  const handleRemoveFirstAndLastElement = async (string, type) => {
    return new Promise((resolve, reject) => {
      if (type === "start") {
        if (string[0] === "<") {
          const elementPattern = "<[^>]+>[A-Za-z0-9]";
          const regexPattern = new RegExp(elementPattern, "i");
          const result = string.match(regexPattern);
          if (result && result.length > 0) {
            const matchStr = result[0];
            string = string.replace(matchStr, matchStr[matchStr.length - 1]);
          }
          return handleRemoveFirstAndLastElement(string, type).then(resolve).catch(reject);
        } else {
          resolve(string);
        }
      } else {
        if (string[string.length - 1] === ">") {
          const regex = /<[^>]*>$/;
          string = string.replace(regex, "");
          return handleRemoveFirstAndLastElement(string, type).then(resolve).catch(reject);
        } else {
          resolve(string);
        }
      }
    });
  };

  const handleHighLightSelectedEditorText = async (htmlText, selectedTextStr, id) => {
    let selectedTempTextStr = await handleRemoveFirstAndLastElement(selectedTextStr, "start");
    selectedTempTextStr = await handleRemoveFirstAndLastElement(selectedTempTextStr, "end");

    return new Promise((resolve, reject) => {
      const div = document.createElement("div");
      div.innerHTML = selectedTextStr;
      const elements = div.querySelectorAll("*");
      if (elements && elements.length > 0) {
        elements.forEach(element => {
          const span = document.createElement("span");
          span.innerHTML = element.innerHTML;
          span.classList.add("selected-wrapper");
          span.setAttribute("data-group", id);
          element.innerHTML = "";
          element.appendChild(span);
        });
        let modifiedHTML = div.innerHTML;
        modifiedHTML = modifiedHTML.replace(/^(<(?!span\s)[^>]*>)+/g, "");
        modifiedHTML = modifiedHTML.replace(/(<\/((?!span).)*>$)/g, "");

        const highlightedContent = htmlText.replace(selectedTempTextStr, modifiedHTML);
        resolve(highlightedContent);
      } else {
        const highlightedContent = htmlText.replace(selectedTextStr, `<span class="selected-wrapper" data-group="${id}">${selectedTextStr}</span>`);
        resolve(highlightedContent);
      }
    });
  };

  const handleAddNewComment = async () => {
    const id = uuidv4().split("-")[0];
    if (editorRef && editorRef.current) {
      dispatch(setLoader(true));
      const htmlText = editorRef.current.getContent();
      let selectedTextStr = editorRef.current.selection.getContent();
      selectedTextStr = selectedTextStr.replace(/<([^>\s]+)[^>]*>(?:\s*(?:<br \/>|&nbsp;|&thinsp;|&ensp;|&emsp;|&#8201;|&#8194;|&#8195;)\s*)*<\/\1>/gm, "");
      let highlightedContent = await handleHighLightSelectedEditorText(htmlText, selectedTextStr, id);

      if (oldArticleText && oldArticleText !== "" && /<html[^>]*>/.test(oldArticleText)) {
        const parser = new DOMParser();
        const bodyText = parser.parseFromString(highlightedContent, "text/html").body.outerHTML;
        highlightedContent = `${oldArticleText.substring(0, oldArticleText.indexOf("<body"))} ${bodyText} </html>`
          .replaceAll("\n", " ").replaceAll("\t", " ").replace(/\s+/g, " ").trim();
      }

      const obj = {
        commentId: id,
        comment: commentText,
        selectedText: selectedTextStr,
        userId: userRes.id,
        user_info: JSON.stringify({
          fullName: `${userRes?.first_name || ""} ${userRes?.last_name || ""}`,
          userName: userRes?.username || "",
          profileUrl: userRes?.profile_url || "",
        }),
        isResolved: false,
        createAt: new Date(),
        status: "open",
      }

      try {
        const dataObj = {
          ...obj, articleType, articleSubType,
          articleId: articleId, status: "open",
          articleText: highlightedContent,
        };
        await invokeAxiosPost("/reviewer-editor/get-or-create/createArticleOrPostEditorNewComment", dataObj);
        dispatch(setLoader(false));

        setEditorText(highlightedContent);
        setCommentList((prev) => ([...prev, obj]));
        handleCloseAddCommentModal();
      } catch (error) {
        toast.error("Something went wrong.");
        dispatch(setLoader(false));
      }
    }
  };

  const handleAddCommentReply = async () => {
    const replyId = uuidv4().split("-")[0];
    const obj = {
      commentId: replyId,
      parentCommentId: selectedCommentId,
      comment: replyCmtText,
      senderType: "user",
      isResolved: false,
      createAt: new Date(),
    }

    const tempObj = [...commentList];
    const index = tempObj.findIndex((ele) => ele.commentId === selectedCommentId);
    if (index !== -1) {
      const replyCmtArray = tempObj[index].replyComments || [];
      replyCmtArray.push(obj);
      tempObj[index].replyComments = [...replyCmtArray];

      if (tempObj[index].status === "resolved") {
        tempObj[index].status = "open";
        const htmlText = editorRef.current.getContent();
        const selectedTextStr = tempObj[index].selectedText;
        let highlightedContent = await handleHighLightSelectedEditorText(htmlText, selectedTextStr, selectedCommentId);
        if (oldArticleText && oldArticleText !== "" && /<html[^>]*>/.test(oldArticleText)) {
          const parser = new DOMParser();
          const bodyText = parser.parseFromString(highlightedContent, "text/html").body.outerHTML;
          highlightedContent = `${oldArticleText.substring(0, oldArticleText.indexOf("<body"))} ${bodyText} </html>`
            .replaceAll("\n", " ").replaceAll("\t", " ").replace(/\s+/g, " ").trim();
        }

        setEditorText(highlightedContent);
        obj.articleText = highlightedContent;
      }
    }

    try {
      dispatch(setLoader(true));
      await invokeAxiosPost(`/reviewer-editor/get-or-create/createArticleOrPostEditorReplyComment?commentId=${selectedCommentId}`, obj);
      dispatch(setLoader(false));
      setCommentList([...tempObj]);
      setReplyCmtText("");

      if (currentStatus === "resolved") {
        handleGetAllCommentList("all", false);
      }
    } catch (error) {
      toast.error("Something went wrong.");
      dispatch(setLoader(false));
    }
  };

  const handleCloseAddCommentModal = () => {
    setCommentText("");
    setReplyCmtText("");
    setCommentModal(false);

    const elements = document.getElementsByClassName("custom-tooltip");
    for (let i = 0; i < elements.length; i++) {
      elements[i].classList.remove("top-arrow");
      elements[i].style.display = "";
      elements[i].style.top = "";
    }
    const elements2 = document.getElementsByClassName("arrow-content");
    elements2[0].style.left = "";
    const element2 = document.getElementsByClassName("reviewArticle");
    for (let i = 0; i < element2.length; i++) {
      element2[i].classList.add("overflow-hidden");
    }

    if (document.querySelector(".tox-edit-area")) {
      const iframe = document.querySelector(".tox-edit-area").querySelector("iframe");
      if (iframe && iframe.contentWindow && iframe.contentWindow.document) {
        const selection = iframe.contentWindow.getSelection();
        if (selection && selection.toString().trim() !== "") {
          selection.removeAllRanges();
        }
      }
    }
  };

  const handleOnSelectCommentById = async (e, id) => {
    e.stopPropagation();
    setSelectedCommentId(id);
    const iframe = document.querySelector("iframe");
    await new Promise(resolve => setTimeout(resolve, 200));
    if (iframe && iframe.contentWindow && iframe.contentWindow.document) {
      const activeSpans = iframe.contentWindow.document.querySelectorAll("span.active");
      activeSpans.forEach((span) => { span.classList.remove("active") });

      const elements = iframe.contentWindow.document.querySelectorAll(`[data-group="${id}"]`);
      elements.forEach(element => { element.classList.add("active") });

      if (elements.length === 0) {
        const index = commentList.findIndex((ele) => ele.commentId === id);
        if (index !== -1) {
          let htmlText = editorRef.current.getContent();
          if (currentStatus === "resolved") {
            htmlText = await removeSpanTags(htmlText);
          }

          const selectedTextStr = commentList[index]?.selectedText ? commentList[index].selectedText : "";
          const highlightedContent = await handleHighLightSelectedEditorText(htmlText, selectedTextStr, id);
          setEditorText(highlightedContent);

          setTimeout(() => {
            const elements = iframe.contentWindow.document.querySelectorAll(`[data-group="${id}"]`);
            elements.forEach(element => { element.classList.add("active") });
          }, 500);
        }
      }
    }
  };

  const handleCloseSelectedComment = async () => {
    setSelectedCommentId("");
    setReplyCmtText("");

    const iframe = document.querySelector("iframe");
    if (iframe && iframe.contentWindow && iframe.contentWindow.document) {
      const activeSpans = iframe.contentWindow.document.querySelectorAll("span.active");
      activeSpans.forEach((span) => { span.classList.remove("active") });

      if (currentStatus === "resolved") {
        const htmlText = editorRef.current.getContent();
        const modifiedHTML = await removeSpanTags(htmlText);
        setEditorText(modifiedHTML);
      }
    }
  };

  const handleResolvedOrDeleteComment = async (id, type) => {
    Swal({
      title: "Are you sure?",
      text: "You won't be able to revert this!", icon: "warning", dangerMode: true,
      buttons: { cancel: "Cancel", confirm: `Yes, ${type === "reopen" ? "Re-Open" : type} it!` },
    }).then(async (isConfirmed) => {
      if (isConfirmed) {
        const htmlText = editorRef.current.getContent();
        const tempDiv = document.createElement("div");
        tempDiv.innerHTML = htmlText;

        const spanElements = tempDiv.querySelectorAll(`span[data-group="${id}"]`);
        spanElements.forEach(spanElement => {
          const innerText = spanElement.innerText;
          spanElement.outerHTML = innerText;
        });

        let modifiedHTML = tempDiv.innerHTML;
        if (type === "reopen" || type === "restore") {
          const selectedTextStr = commentList.find((ele) => ele.commentId === id)?.selectedText;
          if (selectedTextStr && selectedTextStr !== "") {
            modifiedHTML = await handleHighLightSelectedEditorText(modifiedHTML, selectedTextStr, id);
          }
        }

        if (oldArticleText && oldArticleText !== "" && /<html[^>]*>/.test(oldArticleText)) {
          const parser = new DOMParser();
          const bodyText = parser.parseFromString(modifiedHTML, "text/html").body.outerHTML;
          modifiedHTML = `${oldArticleText.substring(0, oldArticleText.indexOf("<body"))} ${bodyText} </html>`
            .replaceAll("\n", " ").replaceAll("\t", " ").replace(/\s+/g, " ").trim();
        }

        const tempObj = commentList.filter((ele) => {
          if (ele.commentId === id) {
            if (type === "restore") {
              ele.status = ele.is_resolved === 1 ? "resolved" : "open";
            } else if (type === "reopen") {
              ele.status = "open";
            } else {
              ele.status = type === "delete" ? "deleted" : "resolved";
            }
          }
          return ele;
        });

        try {
          dispatch(setLoader(true));
          const obj = { type, articleText: modifiedHTML, userName: `${userRes.first_name || ""} ${userRes.last_name || ""}` };
          await invokeAxiosPost(`/reviewer-editor/get-or-create/resolveOrDeleteEditorCommentByCmtId?commentId=${id}`, obj);
          dispatch(setLoader(false));

          setEditorText(modifiedHTML);
          setCommentList([...tempObj]);
          handleGetAllCommentList("all", false);
        } catch (error) {
          toast.error("Something went wrong.");
          dispatch(setLoader(false));
        }
      }
    });
  };

  const removeSpanTags = (html) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, "text/html");
    const spans = doc.querySelectorAll("span");
    spans.forEach((span) => {
      const parent = span.parentNode;
      while (span.firstChild) {
        parent.insertBefore(span.firstChild, span);
      }
      parent.removeChild(span);
    });
    return Promise.resolve(doc.body.innerHTML);
  };

  const handleCopyHtmlArticleText = (text) => {
    if (text && text !== "") {
      const options = { indent_size: 2, space_in_empty_paren: true };
      copy(beautify_html(text, options), { format: "text/html" });
      toast.success("Text has been copied to clipboard.", { id: "Toast-01" });
    }
  };

  return (
    <Row className="review-article-wrapper w-100">
      {isSelectedCurrentTab && (
        <React.Fragment>
          <Col sm={toggleRightSideBar ? 12 : 8} className="editor-wrapper">
            <CommentsTextEditor
              oldArticleText={oldArticleText} editorText={editorText} setEditorText={setEditorText}
              editorRef={editorRef} articleType={articleType} setSelectedCommentId={setSelectedCommentId}
              handleCloseSelectedComment={handleCloseSelectedComment} commentModal={commentModal}
              setCommentModal={setCommentModal} userRes={userRes} commentText={commentText}
              setCommentText={setCommentText} handleAddNewComment={handleAddNewComment}
              handleCloseAddCommentModal={handleCloseAddCommentModal}
              handleCopyHtmlArticleText={handleCopyHtmlArticleText}
            />
          </Col>
          {!toggleRightSideBar && (
            <Col sm={4} className="">
              <CommentListComponent
                commentList={commentList} selectedCommentId={selectedCommentId} replyCmtText={replyCmtText}
                setReplyCmtText={setReplyCmtText} currentStatus={currentStatus} articleType={articleType}
                handleOnSelectCommentById={handleOnSelectCommentById} handleAddCommentReply={handleAddCommentReply}
                handleResolvedOrDeleteComment={handleResolvedOrDeleteComment} handleGetAllCommentList={handleGetAllCommentList}
                handleCloseSelectedComment={handleCloseSelectedComment}
              />
            </Col>
          )}
        </React.Fragment>
      )}

      <SentReviewFormModal
        articleType={articleType} isReviewArticle={isReviewArticle}
        handleSendArticleToReviewer={handleSendArticleToReviewer}
        sendToEditorForm={sendToEditorForm} setSendToEditorForm={setSendToEditorForm}
      />

      <VersionHistoryComponent
        articleId={articleId} articleType={articleType} articleSubType={articleSubType}
        editorText={editorText} setEditorText={setEditorText} oldArticleText={oldArticleText} userRes={userRes}
        handleCopyHtmlArticleText={handleCopyHtmlArticleText}
      />
    </Row>
  )
});

export default ReviewTextEditor;
