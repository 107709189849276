import React, { useEffect, useMemo, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useMutation, useQuery } from "@tanstack/react-query";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import Swal from "sweetalert";
import copy from "copy-to-clipboard";
import { IoIosWarning } from "react-icons/io";
import { handleGetOrGenerateAllTypeImages, removeSpecificSpecialCharacters } from "../utility/hepler";
import { invokeAxiosGet, invokeAxiosPost } from "../utility/invokeAxiosFunction";
import { setGeneratedFullArticle, setLoader, setTopResultsStep, setUserRes } from "../store/reducer";
import { articleHistoryShortFlowData, deleteOrArchiveArticle, pinArticles } from "../utility/apiService";
import { handleShareSocialMediaTypePost, sorting } from "../utility/common";
import { useCallback } from "react";
const beautify_html = require("js-beautify").html;

const validationSchema = Yup.object().shape({
  topic: Yup.string()
    .required("Topic is required")
    .min(2, "Topic must be at least 2 characters")
    .test("no-white-space", "Topic should not contain white spaces", (value) => !/\s{2,}/.test(value))
    .matches(/^(?!\s)(?!.*\s{2})[^\s](.*[^\s])?$/, "Topic should not contain multiple consecutive white spaces"),
  keywords: Yup.string()
    .required("Keyword is required")
    .min(2, "Keyword must be at least 2 characters")
    .matches(/^(?!\s)(?!.*\s{2})[^\s](.*[^\s])?$/, "Keyword should not contain multiple consecutive white spaces")
    .test("no-white-space", "Keyword should not contain white spaces", (value) => !/\s{2,}/.test(value)),
});

const SeoBlogWriterHoc = (WrappedComponents) => {
  const SeoBlogWriter = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { pathname } = useLocation()
    const { id } = useParams();
    const editorRef = useRef(null);
    const userRes = useSelector(({ userRes }) => userRes);
    const topResultsStep = useSelector(({ topResultsStep }) => topResultsStep);
    const generatedFullArticle = useSelector(({ generatedFullArticle }) => generatedFullArticle);
    const socialMediaPostInfo = useSelector(({ socialMediaPostInfo }) => socialMediaPostInfo);
    const historyFiltersType = useMemo(() => ({ categories: [], durations: [], status: [], AIModels: [], users: [], isCustomDate: false, customDate: { startDate: '', endDate: '' } }), []);

    const [historyData, setHistoryData] = useState({ data: [], totalCount: 0 });
    const [filters, setFilters] = useState(historyFiltersType);
    const [textWrap, setTextWrap] = useState(false);
    const [starred, setStarred] = useState(false);
    const [search, setSearch] = useState("");
    const [selectedRows, setSelectedRows] = useState([]);
    const [sortField, setSortField] = useState({ column: {}, direction: '' });
    const [page, setPage] = useState(1);
    const [limit] = useState(10);

    const [tab, setTab] = useState({ tab: 1, progress: 0 });
    const [show, setShow] = useState(false);
    const [active, setActive] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const [showLongArticle, setShowLongArticle] = useState(false);
    const [contentScore, setContentScore] = useState({});
    const [htmlText, setHtmlText] = useState("");
    const [finalHtmlText, setFinalHtmlText] = useState("");
    const [toggle, setToggle] = useState(false);
    const [editorKey, setEditorKey] = useState(0);
    const [edit, setEdit] = useState({});
    const [postImagesModal, setPostImagesModal] = useState({ open: false, type: "", currentTab: "AI Generated Image" });
    const [postImagesCarousel, setPostImagesCarousel] = useState({ open: false, type: "", currentTab: "AI Generated Image" });
    const [clearRow, setClearRow] = useState(false);
    const [postGeneratedImages, setPostGeneratedImages] = useState({});
    const [selectedCurrentTab, setSelectedCurrentTab] = useState(searchParams && searchParams.get("tab") ? searchParams.get("tab") : "article");
    const [selectedSubTab, setSelectedSubTab] = useState(searchParams && searchParams.get("subTab") ? searchParams.get("subTab") : "seoScore");
    const [isSeoScoreDisplay, setIsSeoScoreDisplay] = useState(generatedFullArticle?.lan === "English" ? true : false);
    const articleType = "1-Click Blog";
    const [savedModal, setSavedModal] = useState(false);
    const { register, handleSubmit, formState: { errors }, watch, getValues, setValue, reset, control } = useForm({
      defaultValues: {
        topic: "",
        keywords: "",
        aiType: "GPT 3.5",
        lan: "English",
        geoLocation: "SG",
        generateChart: false
      },
      resolver: yupResolver(validationSchema),
    });

    const searchParam = {};

    if (starred) searchParam.starred = 'starred';
    if (filters.status?.length) searchParam.status = filters.status.join(",");
    if (filters.AIModels?.length) searchParam.aiModel = filters.AIModels.join(",");
    if (filters.durations?.length) searchParam.date = filters.durations.join(",");

    if (pathname.includes("1-click-blog")) searchParam.type = 'short_flow';
    else if (pathname.includes('/blog-co-pilot')) searchParam.type = 'long_flow';
    else if (pathname.includes('/social-media-post-writer')) searchParam.type = 'social_media';
    if (filters.isCustomDate) {
      searchParam.isCustomDate = true;
      searchParam.startDate = filters.customDate.startDate;
      searchParam.endDate = filters.customDate.endDate;
    }

    const { refetch, isFetching } = useQuery({
      queryKey: [searchParam.type || "all", socialMediaPostInfo?.PostType || ""],
      queryFn: async (params) => {
        if (
          params && params.queryKey && params.queryKey[0] && params.queryKey[0] === "social_media" &&
          params.queryKey[1] && params.queryKey[1] !== ""
        ) {
          searchParam.postType = params.queryKey[1];
        } else {
          searchParam.postType = "";
        }
        const query = new URLSearchParams({ is_archived: 0, ...searchParam }).toString();
        const response = await articleHistoryShortFlowData({ userId: userRes?.id, query });
        setHistoryData(response);
        return response;
      },
    });

    const { mutate } = useMutation({
      mutationFn: (values) => {
        pinArticles({ data: values }).then(() => {
          refetch();
        });
      },
    });

    const handleSort = (column, sortDirection) => {
      setSortField(pre => ({ ...pre, column: column, direction: sortDirection }));
      setPage(1);
      const newData = sorting(historyData?.data, column?.sortField, sortDirection);
      setHistoryData(pre => ({ ...pre, data: newData }));
    };

    const handleSelectedRows = (e) => {
      setSelectedRows(e?.selectedRows?.map(({ type, id }) => ({ id, type })));
    };

    const handlePinArticle = (type, articleId, value) => {
      const data = { type, articleId, value: !value };
      mutate(data);
    };

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(() => {
      setTimeout(() => {
        setEditorKey(editorKey + 1);
      }, 500);
    }, [selectedCurrentTab])

    useEffect(() => {
      if (window !== undefined) {
        window.scrollTo(0, 0);
      }
      setFilters(historyFiltersType);

      if (pathname.includes("1-click-blog")) {
        refetch();
      }

      reset({
        ...getValues(),
        aiType: (getValues("aiType") || "GPT 3.5"),
        lan: (getValues("lan") || "English"),
        geoLocation: (getValues("geoLocation") || "SG"),
      });
    }, [pathname, historyFiltersType]);

    useEffect(() => {
      setPage(1);
      refetch();
    }, [filters, starred, refetch]);

    useEffect(() => {
      if (pathname.includes('/1-click-blog')) {
        if (userRes?.id && !id) {
          dispatch(setTopResultsStep(1));
          dispatch(setGeneratedFullArticle({}));
          setPostImagesModal({ open: false, type: "", currentTab: "AI Generated Image" });
          setPostGeneratedImages({});
        } else if (userRes?.id && id) {
          if (generatedFullArticle && generatedFullArticle.articleId) {
            handleSetEditDefaultData(generatedFullArticle, false);
          } else {
            handleGetArticleByIdAndEdit(id);
          }
        }
      }

      if (localStorage.getItem("set_topic") && localStorage.getItem("set_topic") !== "") {
        setValue("topic", localStorage.getItem("set_topic"));
        localStorage.removeItem("set_topic");
      }

      if (localStorage.getItem("set_keyword") && localStorage.getItem("set_keyword") !== "") {
        setValue("keywords", localStorage.getItem("set_keyword"));
        localStorage.removeItem("set_keyword");
      }

      if (localStorage.getItem("set_geoLocation") && localStorage.getItem("set_geoLocation") !== "") {
        setValue("geoLocation", localStorage.getItem("set_geoLocation"))
        localStorage.removeItem("set_geoLocation");
      }
    }, [id]);

    const filteredData = useMemo(() => (
      historyData?.data?.filter(item =>
        Object.values(item).some(value => typeof value === "string" && value?.split(' ').join('')?.toLowerCase().includes(search?.split(' ').join('')?.toLowerCase()))
      )
    ), [historyData, search])

    const onContinue = async (formData) => {
      try {
        if (Number(userRes.credits) < (userRes?.deductCredits?.["1-Click Blog"] || 2)) {
          toast.error("Sorry, you don't have enough credits to perform this action.", { id: "Toast-01" });
          return;
        }

        setTab({ tab: 1, progress: 0 });
        setActive(true);
        dispatch(setTopResultsStep(2));

        const topicStr = await removeSpecificSpecialCharacters(formData?.topic);
        const keywordStr = await removeSpecificSpecialCharacters(formData?.keywords);

        const payload = {
          topic: topicStr, keyword: keywordStr, user_id: userRes.id,
          aiType: formData?.aiType ? formData.aiType : "GPT 3.5",
          geoLocation: formData?.geoLocation ? formData?.geoLocation?.toLowerCase() : "sg",
          lan: formData?.lan ? formData.lan : "English",
          generateChart: formData?.generateChart ? formData.generateChart : false,
        };

        const response = await invokeAxiosPost("/1-click-blog/get-or-generate/generateFullArticle", payload);
        if (response && response.success === 1) {
          reset({ topic: "" });
          setTab((prev) => ({ ...prev, progress: 100 }));
          setTimeout(() => setActive(false), 2000);

          if (formData?.generateChart) {
            await handleSetEditDefaultData({
              ...response.data, articleId: response.data?.id, shortArticleType: "false", uploadChart: "true",
              article_html_chart: response.data?.article_html_chart ? JSON.parse(response.data?.article_html_chart)?.data : null,
            }, true);
          } else {
            await handleSetEditDefaultData({ ...response.data, articleId: response.data?.id, shortArticleType: "false" }, true);
          }

          dispatch(setTopResultsStep(3));
          dispatch(setUserRes({ ...userRes, credits: userRes.credits - (userRes?.deductCredits?.["1-Click Blog"] || 2) }));
          const scrollWrapper = document.getElementById('scroll-wrapper');
          if (scrollWrapper) { scrollWrapper.scrollTo({ top: 0, behavior: 'smooth' }); }
          navigate(`/1-click-blog/${response.data?.id}`);
        } else {
          setActive(false); dispatch(setTopResultsStep(1));
          reset({ aiType: "GPT 3.5", lan: "English", geoLocation: "SG" });
          let message = response.error;
          if (message.indexOf("Azure") !== -1 || message.indexOf("filtered") !== -1) {
            message = "OpenAI failed to generate the article due to filtering, Please rephrase or generate article for a different topic.";
          }
          toast.error(message, { id: "Toast-01" });
          refetch();
        }
      } catch (error) {
        setActive(false); dispatch(setTopResultsStep(1)); refetch();
        reset({ aiType: "GPT 3.5", lan: "English", geoLocation: "SG" });
        toast.error(error?.response?.data?.error?.message || error?.response?.data?.error || "Server is overloaded, try again after sometime.", { id: "Toast-01" });
      }
    };

    const handleGetArticleByIdAndEdit = async (id, tab) => {
      if (id) {
        dispatch(setLoader(true));
        dispatch(setGeneratedFullArticle({}));
        setSelectedSubTab("");

        invokeAxiosGet(`/article-history/get-or-save/articleHistoryGetById?id=${id}`).then(async (res) => {
          const item = res.data && res.data.data ? res.data.data : {};
          await handleSetEditDefaultData(item, true);
          dispatch(setTopResultsStep(3));
          dispatch(setLoader(false));
          setSelectedSubTab(selectedSubTab);

          const scrollWrapper = document.getElementById('scroll-wrapper');
          if (scrollWrapper) { scrollWrapper.scrollTo({ top: 0, behavior: 'smooth' }); };
          if (tab && tab === "refundRequests") {
            navigate(`/1-click-blog/${id}?tab=refundRequests`);
          } else {
            navigate(`/1-click-blog/${id}`);
          }
        }).catch((e) => {
          console.log(e, "eee");
          dispatch(setLoader(false));
          setSelectedSubTab(selectedSubTab);
        });
      }
    };

    const handleSetEditDefaultData = async (item, isSet) => {
      let setTempObj = { ...item };
      if (isSet && isSet === true) {
        setTempObj = {
          ...item, articleId: item?.id,
          linkedIn_post: item?.linkedIn_post, facebook_post: item?.facebook_post,
          twitter_post: item?.twitter_post, instagram_post: item?.instagram_post,
          instagram_reels: item?.instagram_reels, FAQschema: item?.FAQschema?.replace(/(\r\n|\n|\r)/gm, ""),
          SEO_score_long_article: item?.SEO_score_long_article ? JSON.parse(item?.SEO_score_long_article).data : "",
          popular_hashtags: item?.popular_hashtags ? JSON.parse(item.popular_hashtags) : [],
          article_html_chart: item?.article_html_chart ? JSON.parse(item?.article_html_chart)?.data : null,
        }
      }

      dispatch(setGeneratedFullArticle({ ...setTempObj }));
      setIsSeoScoreDisplay(setTempObj?.lan === "English" ? true : false);
      setEdit({
        article_html: setTempObj?.article_html,
        article_detail_html: setTempObj?.article_detail_html,
        FAQHTML: setTempObj?.FAQHTML, FAQschema: setTempObj?.FAQschema,
        linkedIn_post: setTempObj?.linkedIn_post, facebook_post: setTempObj?.facebook_post,
        twitter_post: setTempObj?.twitter_post, instagram_post: setTempObj?.instagram_post,
        instagram_reels: setTempObj?.instagram_reels, googleAdCopy: setTempObj?.googleAdCopy,
      });
      setHtmlText(setTempObj?.article_detail_html || setTempObj?.article_html || "");
      setFinalHtmlText(setTempObj?.article_detail_html || setTempObj?.article_html || "");

      if (setTempObj?.articleId && setTempObj?.outline_html !== "" && setTempObj?.lan === "English") {
        handleGenerateShortArticleSeoScore(setTempObj);

        if (setTempObj?.article_detail_html && !setTempObj?.SEO_score_long_article) {
          handleGenerateLongArticleSeoScore(setTempObj?.article_detail_html);
        }
      }

      if (setTempObj?.isShowReviewInfo && setTempObj?.isShowReviewInfo === true) {
        handleOpenReviewArticleTabInfoModal();
      }

      return item;
    }

    const handleDelete = (id, type, eType) => {
      Swal({
        title: "Are you sure?",
        text: `Do you want to ${eType} the selected item?`,
        icon: "warning", dangerMode: true,
        buttons: { cancel: "Cancel", confirm: `Yes, ${eType} it!` },
      }).then((isConfirmed) => {
        if (isConfirmed) {
          const callback = () => {
            setPage(1); refetch(); setSelectedRows([]); setClearRow(pre => !pre)
          };
          deleteOrArchiveArticle([{ id, type }], eType, callback);
        }
      });
    };

    const handleArchiveAll = () => {
      Swal({
        title: "Are you sure?",
        text: "Do you want to archive the selected item?",
        icon: "warning", dangerMode: true,
        buttons: { cancel: "Cancel", confirm: `Yes, archive ${selectedRows.length > 1 ? "all" : ""}!` },
      }).then((isConfirmed) => {
        if (isConfirmed) {
          const callback = (resp) => {
            setPage(1); refetch(); setSelectedRows([]); setClearRow(pre => !pre);
          }
          deleteOrArchiveArticle(selectedRows, 'archive', callback);
        }
      });
    };

    const handleSetHtmlElementsOnArticle = () => {
      const Obj = {
        article_html: edit.article_html, article_detail_html: edit.article_detail_html,
        FAQHTML: edit.FAQHTML, FAQschema: edit.FAQschema, googleAdCopy: edit.googleAdCopy,
        linkedIn_post: edit.linkedIn_post, facebook_post: edit.facebook_post,
        twitter_post: edit.twitter_post, instagram_post: edit.instagram_post, instagram_reels: edit.instagram_reels,
      };

      if (generatedFullArticle && generatedFullArticle.article_html && generatedFullArticle.article_html.indexOf("<html>") !== -1) {
        const htmlStr = edit.article_html.indexOf("<body>") !== -1 ? edit.article_html.substring(edit.article_html.indexOf("<body>") + 6, edit.article_html.indexOf("</body>")) : edit.article_html;
        Obj.article_html = `${generatedFullArticle.article_html.substring(0, generatedFullArticle.article_html.indexOf("<body>"))}
            <body> ${htmlStr} </body>
          </html>`.replaceAll("\n", "").replaceAll("\t", "");
      }

      if (generatedFullArticle && generatedFullArticle.article_detail_html && generatedFullArticle.article_detail_html.indexOf("<html>") !== -1) {
        const htmlStr1 = edit.article_detail_html.indexOf("<body>") !== -1 ? edit.article_detail_html.substring(edit.article_detail_html.indexOf("<body>") + 6, edit.article_detail_html.indexOf("</body>")) : edit.article_detail_html;
        Obj.article_detail_html = `${generatedFullArticle.article_detail_html.substring(0, generatedFullArticle.article_detail_html.indexOf("<body>"))}
            <body> ${htmlStr1} </body>
          </html>`.replaceAll("\n", "").replaceAll("\t", "");
      }

      return Obj;
    };

    const handleSaveArticleOrPostText = async () => {
      handleShow(true);
      dispatch(setLoader(true));
      setSelectedSubTab("");

      const updateObj = await handleSetHtmlElementsOnArticle();
      await dispatch(setGeneratedFullArticle({ ...generatedFullArticle, ...updateObj }));

      await invokeAxiosPost(`/article-history/get-or-save/articleHistoryUpdate`, {
        id: generatedFullArticle?.articleId,
        currentField: selectedCurrentTab,
        ...updateObj
      }).then(async () => {
        dispatch(setLoader(false));
        setSelectedSubTab(selectedSubTab);
        setSavedModal(true);
      }).catch(function (error) {
        dispatch(setLoader(false));
        setSelectedSubTab(selectedSubTab);
        toast.error(error?.response?.data?.error?.message || error?.response?.data?.error || "Server is overloaded, try again after sometime.", { id: "Toast-01" });
        console.error(error);
      });
    };

    const getFullData = async (name, reGenerated = 0, feedback = "") => {
      try {
        if (name === "linkedIn" || name === "facebook" || name === "twitter" || name === "instagram" || name === "instagram_reels" || name === "AdCopyWrite") {
          if (Number(userRes.credits) < (userRes?.deductCredits?.["Social Media Post"] || 1)) {
            toast.error("Sorry, you don't have enough credits to perform this action.", { id: "Toast-01" });
            return;
          }
        } else if (name === "longArticle") {
          if (Number(userRes.credits) < (userRes.deductCredits["1-Click Blog"] || 2)) {
            toast.error("Sorry, you don't have enough credits to perform this action.", { id: "Toast-01" });
            return;
          }
        } else if (name === "shortArticle") {
          if (generatedFullArticle.article_regenerate_left <= 0) {
            toast.error("Sorry, you have used all three available generations");
            return;
          }
        } else if (name === "faq") {
          if (generatedFullArticle.faq_regenerate_left <= 0) {
            toast.error("Sorry, you have used all three available generations");
            return;
          }
        }

        dispatch(setLoader(true));
        const typeOfAI = generatedFullArticle?.AI_type === "google-palm" ? "palm" : "chatGPT";
        const articleId = generatedFullArticle?.articleId ? generatedFullArticle.articleId : "";
        const payload = { id: articleId };

        if (reGenerated) {
          payload.feedback = feedback || "";
        }

        if (name === "longArticle") {
          setSelectedSubTab("");
          const updateObj = await handleSetHtmlElementsOnArticle();
          await invokeAxiosPost(`/article-history/get-or-save/articleHistoryUpdate`, { id: generatedFullArticle?.articleId, currentField: selectedCurrentTab, isRegenrated: reGenerated, ...updateObj });

          const apiResponse = await invokeAxiosPost("/1-click-blog/get-or-generate/generateArticleExpandDetails", payload);
          setSelectedSubTab(selectedSubTab);
          if (apiResponse && apiResponse.success === 0) {
            dispatch(setLoader(false));
            let message = apiResponse.error;
            if (message.indexOf("Azure") !== -1 || message.indexOf("filtered") !== -1) {
              message = "OpenAI failed to generate the article due to filtering, Please rephrase or generate article for a different topic.";
            }

            if (message.indexOf("outline failed") !== -1) {
              toast.error("Generating the long article failed. Does not deducted 1 credit from your account, Please regenerate the article.", {
                className: 'custom-toast-warning', icon: <IoIosWarning />, id: "Toast-01"
              });
            } else {
              toast.error(message, { id: "Toast-01" });
            }
            return;
          }
        } else {
          setSelectedSubTab("");
          if (name === "shortArticle") {
            await invokeAxiosPost("/seo-blog-co-pilot/get-or-generate/regenerateHeadline", {
              feedback: feedback && feedback !== "" ? feedback : "",
              articleId: generatedFullArticle?.articleId ? generatedFullArticle.articleId : "",
            });
          } else if (name === "faq") {
            await invokeAxiosPost("/1-click-blog/get-or-generate/generateFAQSchema", payload);
          } else if (name === "linkedIn" || name === "facebook" || name === "twitter" || name === "instagram" || name === "instagram_reels") {
            payload.type = name;
            await invokeAxiosPost("/1-click-blog/get-or-generate/generateSocialPostForBlog", payload);
          } else if (name === "AdCopyWrite") {
            await invokeAxiosPost("/1-click-blog/get-or-generate/generateAdCopyWrite", payload);
          } else if (name === "popular_hashtags") {
            await invokeAxiosGet(`/1-click-blog/get-or-generate?id=${articleId}`);
          }
          setSelectedSubTab(selectedSubTab);
        }

        const response = await invokeAxiosGet(`/article-history/get-or-save/articleHistoryGetById?id=${articleId}`);
        const responseData = response && response.data && response.data.data ? response.data.data : {};

        if (name === "linkedIn" || name === "facebook" || name === "twitter" || name === "instagram" || name === "instagram_reels" || name === "AdCopyWrite") {
          dispatch(setUserRes({ ...userRes, credits: userRes.credits - (userRes.deductCredits["Social Media Post"] || 1) }));
        } else if (name === "longArticle") {
          dispatch(setUserRes({ ...userRes, credits: userRes.credits - (userRes.deductCredits["1-Click Blog"] || 2) }));
        }

        await dispatch(setGeneratedFullArticle({
          ...generatedFullArticle, article_regenerate_left: responseData.article_regenerate_left,
          article_html: responseData?.article_html, article_detail_html: responseData?.article_detail_html,
          linkedIn_post: responseData?.linkedIn_post, linkedIn_post_img: responseData?.linkedIn_post_img,
          facebook_post: responseData?.facebook_post, facebook_post_img: responseData?.facebook_post_img,
          twitter_post: responseData?.twitter_post, twitter_post_img: responseData?.twitter_post_img,
          instagram_post: responseData?.instagram_post, instagram_post_img: responseData?.instagram_post_img,
          instagram_reels: responseData?.instagram_reels, faq_regenerate_left: responseData.faq_regenerate_left,
          FAQHTML: responseData?.FAQHTML, FAQschema: responseData?.FAQschema, googleAdCopy: responseData?.googleAdCopy,
          popular_hashtags: responseData?.popular_hashtags ? JSON.parse(responseData.popular_hashtags) : [],
        }));

        dispatch(setLoader(false));
        setEdit({
          ...edit, article_html: responseData?.article_html, article_detail_html: responseData?.article_detail_html,
          FAQHTML: responseData?.FAQHTML, FAQschema: responseData?.FAQschema, googleAdCopy: responseData?.googleAdCopy,
          linkedIn_post: responseData?.linkedIn_post, facebook_post: responseData?.facebook_post,
          twitter_post: responseData?.twitter_post, instagram_post: responseData?.instagram_post,
          instagram_reels: responseData?.instagram_reels,
        });

        /* if (name === "linkedIn" || name === "facebook" || name === "twitter" || name === "instagram" || name === "instagram_reels" || name === "AdCopyWrite") {
          setIsTyping({ ...isTyping, [name]: true });
        } */

        if (name === "longArticle" && isSeoScoreDisplay) {
          handleGenerateLongArticleSeoScore(responseData?.article_detail_html);
        } else if (name === "shortArticle" && isSeoScoreDisplay) {
          setContentScore({});
          handleGenerateShortArticleSeoScore(responseData);
        }
      } catch (error) {
        dispatch(setLoader(false));
        setSelectedSubTab(selectedSubTab);
        toast.error(error?.response?.data?.error?.message || error?.response?.data?.error || "Server is overloaded, try again after sometime.", { id: "Toast-01" });
        console.log(error);
      }
    };

    const handleGenerateShortArticleSeoScore = async (setTempObj) => {
      if (setTempObj?.articleId) {
        const response = await invokeAxiosPost(`/1-click-blog/get-or-generate/getSEOScore?id=${setTempObj?.articleId}&articleType=short`);
        if (response && response.success === 1 && response.data && response.data.data) {
          setContentScore(response.data.data);
        }
      }
    };

    const handleGenerateLongArticleSeoScore = async (article_detail_html) => {
      if (generatedFullArticle?.articleId) {
        const response = await invokeAxiosPost(`/1-click-blog/get-or-generate/getSEOScore?id=${generatedFullArticle?.articleId}&articleType=long`);
        if (response && response.success === 1) {
          dispatch(setGeneratedFullArticle({
            ...generatedFullArticle, SEO_score_long_article: response.data, article_detail_html
          }));
        }
      }
    };

    const handleCopyHtmlArticleText = (text) => {
      if (text && text !== "") {
        const options = { indent_size: 2, space_in_empty_paren: true };
        const modifiedText = beautify_html(text, options);
        var isPlainText = !modifiedText.match(/<[^>]+>/);
        const isCoped = copy(modifiedText, {
          debug: true,
          format: isPlainText ? 'text/plain' : 'text/html',
        });
        if (isCoped) {
          toast.success("Text has been copied to clipboard.", { id: "Toast-01" });
        }
      }
    };

    const handleGetAllImagesAndOpenModal = async (subType, html, imageTab = "") => {
      try {
        if (imageTab && imageTab !== "") {
          setPostImagesModal((prev) => ({ ...prev, currentTab: "Upload Image" }));
        }

        const params = {
          html, type: articleType, subType, userId: userRes.id,
          articleId: generatedFullArticle?.articleId ? generatedFullArticle.articleId : "",
          topic: generatedFullArticle?.topic ? generatedFullArticle.topic : "",
          keyword: generatedFullArticle?.keyword ? generatedFullArticle.keyword : "",
        };

        const queryParams = new URLSearchParams({
          type: articleType, subType, userId: userRes.id,
          articleId: generatedFullArticle?.articleId ? generatedFullArticle.articleId : "",
        }).toString();

        const paramObj = { setPostImagesModal, postGeneratedImages, setPostGeneratedImages, articleType, subType, userRes, params, queryParams };
        await handleGetOrGenerateAllTypeImages(paramObj);
        setPostImagesModal((prev) => ({ ...prev, imageHistoryLoader: false, pexelsGenerating: false, aiImageGenerating: false }));
      } catch (error) {
        setPostImagesModal((prev) => ({ ...prev, imageHistoryLoader: false, pexelsGenerating: false, aiImageGenerating: false }));
      }
    };

    const handleAutoFactChecker = async () => {
      dispatch(setLoader(true));
      const payload = {
        body: JSON.stringify({
          userId: userRes.id,
          articleId: generatedFullArticle?.articleId ? generatedFullArticle.articleId : "",
        }),
      };
      const response = await invokeAxiosPost("/1-click-blog/get-or-generate/generateArticleFactChecker", payload);
      dispatch(setLoader(false));
      if (response && response.success === 1) {
        if (response && response.data) {
          const data = JSON.parse(response.data);
          let getCurrArticle = edit?.article_html;

          for (let index = 0; index < data.length; index++) {
            const element = data[index];
            let finalText = element.textWithUrl ? element.textWithUrl.replaceAll("\n", "").replace(/\s+/g, ' ') : "";
            if (finalText && finalText.indexOf("class=") === -1) {
              finalText = finalText.replace('rel="noopener"', 'rel="noopener" class="tooltip-editor"');
            }
            const replaceText = element.text.replaceAll("\n", "").replace(/\s+/g, ' ').trim();
            getCurrArticle = getCurrArticle.replace(replaceText, finalText);
          }
          // setFactCheckHtml(getCurrArticle);
          setEdit({ ...edit, article_html: getCurrArticle });
        }
      } else {
        toast.error(response?.error, { id: "Toast-01" });
      }
    };

    const handleOpenReviewArticleTabInfoModal = async () => {
      await new Promise((resolve) => setTimeout(resolve, 10000));

      Swal({
        title: "Review Article",
        text: "Utilize our Review System as a fallback for ensuring article validity and reliability.",
        icon: "info", dangerMode: true,
        buttons: { cancel: "Not Now", confirm: `Yes, Review it!` },
      }).then((isConfirmed) => {
        if (isConfirmed) {
          setSelectedCurrentTab("reviewArticle");
          setSearchParams("tab=reviewArticle&&subTab=comments");
          dispatch(setGeneratedFullArticle({ ...generatedFullArticle, isShowReviewInfo: false }));
        } else {
          dispatch(setGeneratedFullArticle({ ...generatedFullArticle, isShowReviewInfo: false }));
        }
      });
    };

    const handleSetSelectedHashtagsOnPosts = (e, tag, type, isExist) => {
      const post = type === "fb" ? "facebook_post" : type === "tw" ? "twitter_post" : type === "ig" ? "instagram_post" : "linkedIn_post";
      const index = edit[post]?.indexOf(tag);
      if (!isExist) {
        setEdit({ ...edit, [post]: edit[post] + ` <span class="hash-tag-text">${tag}</span> ` });
      } else {
        setEdit({ ...edit, [post]: edit[post]?.replace(` <span class="hash-tag-text">${tag}</span> `, "") });
      }
    };

    const handleShareSocialMediaPost = async () => {
      if (selectedCurrentTab === "linkedIn") {
        const uploadImage = generatedFullArticle?.linkedIn_post_img || "";
        const params = {
          postType: "LinkedIn", text: edit?.linkedIn_post || "",
          articleId: generatedFullArticle?.articleId ? generatedFullArticle.articleId : "",
          keyword: generatedFullArticle?.keyword || "", userId: userRes.id, dispatch,
          image: uploadImage ? JSON.stringify({ data: uploadImage }) : "",
        };
        await handleShareSocialMediaTypePost(params);
      }
    }

    const handleSetRestoredOldVersionText = async (fieldName, text) => {
      await dispatch(setGeneratedFullArticle({ ...generatedFullArticle, [fieldName]: text }));
      setEdit({ ...edit, [fieldName]: text });
    }

    return (
      <WrappedComponents
        {...props} handleSaveArticleOrPostText={handleSaveArticleOrPostText} savedModal={savedModal} setSavedModal={setSavedModal} historyData={filteredData ?? []} userRes={userRes}
        handleAutoFactChecker={handleAutoFactChecker} loading={isFetching} filters={filters} setFilters={setFilters} isFetching={isFetching}
        textWrap={textWrap} setTextWrap={setTextWrap} starred={starred} setStarred={setStarred} setSearch={setSearch} articleType={articleType}
        selectedRows={selectedRows} setSelectedRows={handleSelectedRows} handleArchiveAll={handleArchiveAll} totalCount={historyData?.totalCount || 0}
        page={page} setPage={setPage} limit={limit} topResultsStep={topResultsStep} handleSubmit={handleSubmit} register={register} watch={watch} setValue={setValue}
        errors={errors} onContinue={onContinue} control={control} handlePinArticle={handlePinArticle} handleDelete={handleDelete}
        show={show} handleClose={handleClose} active={active} setTab={setTab} tab={tab} setToggle={setToggle} toggle={toggle}
        htmlText={htmlText} handleGetAllImagesAndOpenModal={handleGetAllImagesAndOpenModal} edit={edit} setEdit={setEdit} search={search}
        finalHtmlText={finalHtmlText} setHtmlText={setHtmlText} editorRef={editorRef} editorKey={editorKey} sortField={sortField}
        handleCopyHtmlArticleText={handleCopyHtmlArticleText} contentScore={contentScore} generatedFullArticle={generatedFullArticle}
        isSeoScoreDisplay={isSeoScoreDisplay} postImagesModal={postImagesModal} setPostImagesModal={setPostImagesModal} clearRow={clearRow}
        showLongArticle={showLongArticle} setShowLongArticle={setShowLongArticle} getFullData={getFullData} selectedSubTab={selectedSubTab}
        setSelectedSubTab={setSelectedSubTab} postGeneratedImages={postGeneratedImages} setPostGeneratedImages={setPostGeneratedImages} handleSort={handleSort}
        handleGetArticleByIdAndEdit={handleGetArticleByIdAndEdit} handleSetSelectedHashtagsOnPosts={handleSetSelectedHashtagsOnPosts} reset={reset}
        handleShareSocialMediaPost={handleShareSocialMediaPost} setSelectedCurrentTab={setSelectedCurrentTab} postImagesCarousel={postImagesCarousel}
        setPostImagesCarousel={setPostImagesCarousel} handleSetRestoredOldVersionText={handleSetRestoredOldVersionText} getHistoryShortFlowData={useCallback(() => refetch(), [refetch])}
      />
    );
  };

  return SeoBlogWriter;
};

export default SeoBlogWriterHoc;
