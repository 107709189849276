import React, { useEffect, useRef, useState, useMemo } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { FileUploader } from "react-drag-drop-files";
import Select from "react-select";
import toast from "react-hot-toast";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { ReactSVG } from "react-svg";
import { useDispatch, useSelector } from "react-redux";
import { FiMail, FiUser } from "react-icons/fi";
import { GoLink } from "react-icons/go";
import { TiBusinessCard } from "react-icons/ti";
import { CiLocationOn } from "react-icons/ci";
import { AiOutlineFilePdf, AiOutlinePhone } from "react-icons/ai";
import { BiSolidEditAlt, BiSolidErrorCircle } from "react-icons/bi";
import { BsArrowLeft, BsArrowRight, BsInfoCircleFill, BsCloudUploadFill } from "react-icons/bs";
import PressReleaseInputFields from "./PressReleaseInputFields";
import { setLoader, setPressReleaseWriterInfo, setPressReleaseWriterStep, setUserRes } from "../../store/reducer";
import { uploadPdfFileOnS3 } from "../../utility/uploadPdfFileOnS3";
import { invokeAxiosPost } from "../../utility/invokeAxiosFunction";
import { handleCustomSelectOption, supportedLanguageList, trimedInputValue } from "../../utility/hepler";
import { sideBarIcons } from "../../Assets/Icons";
import { getUserDetail } from "../../utility/apiService";
import ButtonLoader from "../ui/ButtonLoader";

const validationSchema = Yup.object().shape({
  ceo_name: Yup.string().test('ceo_name', 'CEO name should contain only letters and be between 2 and 40 characters.', function (value) {
    if (!!value) {
      const schema = Yup.string().matches(/^[a-zA-Z]+(?: [a-zA-Z]+)*$/).min(2).max(40);
      return schema.isValidSync(value);
    }
    return true;
  }),
  web_link: Yup.string().test('web_link', 'Must be a valid website link!', function (value) {
    if (!!value) {
      const schema = Yup.string().matches(/((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/).trim("white-space", "Website link should not contain white spaces");
      return schema.isValidSync(value);
    }
    return true;
  }),
  contact_name: Yup.string().test('contact_name', 'Contact name should contain only letters and be between 2 and 40 characters.', function (value) {
    if (!!value) {
      const schema = Yup.string().matches(/^[a-zA-Z]+(?: [a-zA-Z]+)*$/).min(2).max(40)
      return schema.isValidSync(value);
    }
    return true;
  }),
  contact_email: Yup.string().test('contact_email', 'Must be a valid contact email!', function (value) {
    if (!!value) {
      const schema = Yup.string().matches(/^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/).trim("white-space", "Contact email should not contain white spaces");
      return schema.isValidSync(value);
    }
    return true;
  }),
  contact_phone_number: Yup.string().test('contact_phone_number', 'Contact phone number should contain only numbers and be between 8 and 13 digits.', function (value) {
    if (!!value) {
      const schema = Yup.string().matches(/^[0-9]+$/).min(8).max(13);
      return schema.isValidSync(value);
    }
    return true;
  }),
  contact_address: Yup.string().test('contact_address', 'Contact address should contain only letters, numbers and be between 2 and 255 characters.', function (value) {
    if (!!value) {
      const schema = Yup.string().matches(/^[a-zA-Z0-9'\.\-\s\,]+$/).min(2).max(255);
      return schema.isValidSync(value);
    }
    return true;
  }),
});

const validationSchema1 = Yup.object().shape({
  link: Yup.string().test('link', 'Enter a valid link', function (value) {
    if (!!value) {
      const schema = Yup.string().url('Invalid URL format').required('URL is required');
      return schema.isValidSync(value);
    }
    return true;
  }),
});

const SecondStepPressRelease = ({ isButtonLoader, setIsButtonLoader }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id: articleId } = useParams();
  const fileInputRef = useRef(null);
  const closeButtonRef = useRef(null);

  const userRes = useSelector(({ userRes }) => userRes);
  const pressReleaseWriterInfo = useSelector(({ pressReleaseWriterInfo }) => pressReleaseWriterInfo);

  const [currentLan, setCurrentLan] = useState("English");
  const [isShowInputFields, setIsShowInputFields] = useState(false);
  const [isShowNextButton, setIsShowNextButton] = useState(pressReleaseWriterInfo.selectedCard === "Desc" ? true : false);
  const [isDisabledLinkFields, setIsDisabledLinkFields] = useState(false);
  const [isEditPressRelease, setIsEditPressRelease] = useState(false);
  const [uploadFileDragging, setUploadFileDragging] = useState(null);
  const [uploadFileError, setUploadFileError] = useState(null);
  const [uploadedFile, setUploadedFile] = useState(null);

  const { register, handleSubmit, formState: { errors }, getValues, setValue, watch, reset, control, clearErrors, trigger } = useForm({
    defaultValues: { type: "" }, resolver: yupResolver(validationSchema1),
  });

  const { register: register1, handleSubmit: handleSubmit1, formState: { errors: errors1 }, reset: reset1 } = useForm({
    defaultValues: {}, mode: "onBlur", resolver: yupResolver(validationSchema),
  });

  const pressReleaseTypeOptions = [
    { label: "Product Launch", value: "Product Launch" },
    { label: "Partnership Announcement", value: "Partnership Announcement" },
    { label: "Corporate Event Announcement", value: "Corporate Event Announcement" },
    { label: "Financial or Earnings Report", value: "Financial or Earnings Report" },
    { label: "New Executive Hire", value: "New Executive Hire" },
    { label: "Company Milestone", value: "Company Milestone" },
    { label: "Award Win", value: "Award Win" },
  ];

  const FileUploaderCustomUi = useMemo(() => (
    <div className={` position-relative ${uploadFileDragging ? "active" : ""}`}>
      <BsCloudUploadFill className="uploadIcon" />
      <h4 className="fw-normal form-label file-upload-header">
        <strong>Choose a file</strong> or drag it here
      </h4>
      <p className="m-0 file-upload-pera">
        Supported formats: pdf <br /> maximum file size: 5MB.
      </p>
      {uploadFileError ? (
        <React.Fragment>
          <h5 className="fw-normal text-danger d-flex align-items-center">
            <BiSolidErrorCircle className="fs-4 me-2" /> {uploadFileError}
          </h5>
        </React.Fragment>
      ) : uploadedFile ? (
        <h5 className="fw-normal text-primary text-center">
          {uploadedFile && uploadedFile[0].name ? uploadedFile[0].name : ""}
        </h5>
      ) : null}
    </div>
  ), [uploadedFile, uploadFileDragging, uploadFileError]);

  useEffect(() => {
    if (articleId && pressReleaseWriterInfo && pressReleaseWriterInfo.subType) {
      reset({ type: pressReleaseWriterInfo.subType });

      if (articleId && pressReleaseWriterInfo && pressReleaseWriterInfo.inputInfo) {
        const tempObj = JSON.parse(pressReleaseWriterInfo.inputInfo);
        reset({ type: pressReleaseWriterInfo.subType, ...tempObj });
      }
      setCurrentLan(pressReleaseWriterInfo.language);
      setIsShowInputFields(true); setIsShowNextButton(true);
      setIsDisabledLinkFields(true);
      if (pressReleaseWriterInfo?.link) {
        setValue("link", pressReleaseWriterInfo.link);
      }

      if (
        pressReleaseWriterInfo?.status === "completed" ||
        (pressReleaseWriterInfo?.keywords && pressReleaseWriterInfo.keywords.length > 0) ||
        (pressReleaseWriterInfo?.resText && pressReleaseWriterInfo?.resText !== "")
      ) {
        setIsEditPressRelease(true);
      }
    }
  }, [articleId])

  const handleOnSetBtnLoader = (val) => {
    setIsButtonLoader(val);
    setIsDisabledLinkFields(val);
  };

  const handleOnChangePressReleaseType = (e) => {
    const mediaContactVal = {
      companyName: (getValues("companyName") || userRes?.company || ""),
      ceoName: (getValues("ceoName") || userRes?.companyDetails?.ceo_name || ""),
      mediaContactName: (getValues("mediaContactName") || userRes?.companyDetails?.contact_name || ""),
      mediaContactEmail: (getValues("mediaContactEmail") || userRes?.companyDetails?.contact_email || ""),
      mediaContactPhone: (getValues("mediaContactPhone") || userRes?.companyDetails?.contact_phone_number || ""),
      companyWebsiteLink: (getValues("companyWebsiteLink") || userRes?.companyDetails?.web_link || ""),
      mediaContactAddress: (getValues("mediaContactAddress") || userRes?.companyDetails?.contact_address || ""),
    }

    reset(); reset({ type: e.value, ...mediaContactVal });
    if (pressReleaseWriterInfo.selectedCard === "Desc") {
      setIsShowInputFields(true);
    }
  };

  const onSubmitFormAndGenerate = async (value) => {
    const data = trimedInputValue(value);
    if (!watch("type")) { return false; }
    if (isButtonLoader) { return false; };
    if (Number(userRes.credits) < (userRes?.deductCredits?.["Press Release"] || 3)) {
      toast.error("Sorry, you don't have enough credits to perform this action.", { id: "Toast-01" });
      return;
    }

    const scrollWrapper = document.getElementById('scroll-wrapper');
    if (scrollWrapper) {
      scrollWrapper.scrollTo({ top: 0, behavior: 'smooth' });
    }

    if (articleId && pressReleaseWriterInfo && pressReleaseWriterInfo.keywords && pressReleaseWriterInfo.keywords.length > 0) {
      dispatch(setPressReleaseWriterInfo({ ...pressReleaseWriterInfo, stepDone: pressReleaseWriterInfo.stepDone < 3 ? 3 : pressReleaseWriterInfo.stepDone }));
      dispatch(setPressReleaseWriterStep(3));
    } else if (articleId && pressReleaseWriterInfo && pressReleaseWriterInfo.resText && pressReleaseWriterInfo.resText !== "") {
      dispatch(setPressReleaseWriterInfo({ ...pressReleaseWriterInfo, stepDone: pressReleaseWriterInfo.stepDone < 4 ? 4 : pressReleaseWriterInfo.stepDone }));
      dispatch(setPressReleaseWriterStep(4));
    } else {
      const tempData = { ...data };
      const reqObj = {
        id: (pressReleaseWriterInfo?.id || null),
        type: pressReleaseWriterInfo.selectedCard,
        subType: tempData.type, language: currentLan,
        userId: userRes.id
      }

      delete tempData.type; delete tempData.link;
      reqObj.inputInfo = JSON.stringify({ ...tempData });

      try {
        handleOnSetBtnLoader(true);
        const payload = { body: reqObj };
        const response = await invokeAxiosPost("/press-release/get-or-generate/generateKeywordsForPressRelease", payload);
        setIsButtonLoader(false);
        if (response && response.success === 1 && response.data && response.data.id) {
          if (
            (pressReleaseWriterInfo?.keywords && pressReleaseWriterInfo.keywords.length > 0) ||
            (pressReleaseWriterInfo?.resText && pressReleaseWriterInfo?.resText !== "")
          ) {
            setIsEditPressRelease(true);
          }
          setIsShowNextButton(true);
          setIsShowInputFields(true); setIsDisabledLinkFields(true);
          reset({ type: response.data.subType, ...JSON.parse(reqObj.inputInfo) });
          dispatch(setPressReleaseWriterInfo({
            ...pressReleaseWriterInfo, ...response.data,
            stepDone: pressReleaseWriterInfo.stepDone < 3 ? 3 : pressReleaseWriterInfo.stepDone
          }));
          dispatch(setPressReleaseWriterStep(3));
          navigate(`/press-release/${response.data?.id}`);
        } else {
          handleOnSetBtnLoader(false);
          setIsEditPressRelease(false);
          toast.error("Something went wrong, Please try again later.", { id: "Toast-01" });
        }
      } catch (error) {
        handleOnSetBtnLoader(false);
        setIsEditPressRelease(false);
        toast.error("Something went wrong, Please try again later.", { id: "Toast-01" });
      }
    }
  };

  const companyPreferenceToggleModal = () => {
    if (userRes && userRes.companyDetails) {
      reset1({ ...userRes.companyDetails });
    }
  };

  const saveCompanyPreferenceDetails = (data) => {
    const mediaContactVal = {
      ceoName: (data?.ceo_name || ""),
      mediaContactName: (data?.contact_name || ""),
      mediaContactEmail: (data?.contact_email || ""),
      mediaContactPhone: (data?.contact_phone_number || ""),
      companyWebsiteLink: (data?.web_link || ""),
      mediaContactAddress: (data?.contact_address || ""),
    };

    reset({ ...getValues(), ...mediaContactVal });
  };

  const handleGenerateBasicInputInfo = async () => {
    const result = await trigger('link');
    if (!result) {
      return false;
    }

    if (isEditPressRelease || !watch("type") || !watch("link")) {
      return false;
    }

    if (isButtonLoader) { return false; };
    if (Number(userRes.credits) < (userRes?.deductCredits?.["Press Release"] || 3)) {
      toast.error("Sorry, you don't have enough credits to perform this action.", { id: "Toast-01" });
      return;
    }

    const paramData = {
      type: pressReleaseWriterInfo.selectedCard,
      subType: getValues("type"),
      link: getValues("link"),
      language: currentLan,
      userId: userRes.id,
    }

    try {
      const payload = { body: paramData };

      if (pressReleaseWriterInfo.selectedCard && pressReleaseWriterInfo.selectedCard === "PDF") {
        handleOnSetBtnLoader(true);
        const response = await invokeAxiosPost("/press-release/get-or-generate/generateTextForPressRelease", payload);
        setIsButtonLoader(false);
        if (response && response.success === 1 && response.data && response.data.resText) {
          setIsShowNextButton(true); setIsShowInputFields(true); setIsDisabledLinkFields(true);
          dispatch(setPressReleaseWriterInfo({
            ...pressReleaseWriterInfo, ...response.data,
            stepDone: pressReleaseWriterInfo.stepDone < 4 ? 4 : pressReleaseWriterInfo.stepDone
          }));
          dispatch(setPressReleaseWriterStep(4));
          dispatch(setUserRes({ ...userRes, credits: userRes.credits - (userRes?.deductCredits?.["Press Release"] || 3) }));
          dispatch(setLoader(false));
          navigate(`/press-release/${response.data?.id}`);
        } else {
          toast.error("Press release not generated, Please try again.", { id: "Toast-01" });
        }
      } else {
        dispatch(setLoader(true));
        const response = await invokeAxiosPost("/press-release/get-or-generate/generateUserInputInfoForPressRelease", payload);
        dispatch(setLoader(false));
        if (response && response.success === 1 && response.data && response.data.inputInfo) {
          setIsShowNextButton(true); setIsShowInputFields(true); setIsDisabledLinkFields(true);
          dispatch(setPressReleaseWriterInfo({ ...pressReleaseWriterInfo, ...response.data }));
          reset({ ...getValues(), ...JSON.parse(response.data.inputInfo) });
          navigate(`/press-release/${response.data?.id}`);
        } else {
          toast.error(response?.error ? response?.error : "Something went wrong, Please try again later.", { id: "Toast-01" });
        }
      }
    } catch (error) {
      handleOnSetBtnLoader(false);
      setIsEditPressRelease(false); dispatch(setLoader(false));
      toast.error("Something went wrong, Please try again later.", { id: "Toast-01" });
    }
  };

  const handleOnUploadPdfFile = async (files) => {
    const file = files[0];
    setUploadedFile(files);
    const MAX_FILE_SIZE = 6144; // 6 MB
    const fileSizeKiloBytes = file.size / 1024;
    if (fileSizeKiloBytes > MAX_FILE_SIZE) {
      toast.error("File size exceeds limit. Please upload a file no larger than 5 MB.", { id: "Toast-01" });
      if (fileInputRef.current) { fileInputRef.current.value = null; }
      setValue("link", "");
      return false;
    }

    const extension = file.name.split('.').pop().toLowerCase();
    if (extension !== 'pdf') {
      toast.error("Uploaded file is not a PDF, Please upload a valid PDF file.", { id: "Toast-01" });
      if (fileInputRef.current) { fileInputRef.current.value = null; }
      setValue("link", "");
      return false;
    }

    if (file.type !== 'application/pdf') {
      toast.error("Uploaded file is not a PDF, Please upload a valid PDF file.", { id: "Toast-01" });
      if (fileInputRef.current) { fileInputRef.current.value = null; }
      setValue("link", "");
      return false;
    }

    dispatch(setLoader(true));
    setUploadFileError(null);
    const filePath = await uploadPdfFileOnS3(file, "pressRelease");
    dispatch(setLoader(false));

    if (filePath && filePath !== "") {
      clearErrors("link");
      setValue("link", filePath);
      setIsDisabledLinkFields(true);
      return filePath;
    } else {
      toast.error("File not uploaded successfully, please try again.", { id: "Toast-01" });
      setValue("link", "");
      return false;
    }
  };

  const handleOnSelectPdfFileForDescType = async (event) => {
    if (isEditPressRelease) { return false; };

    const getPdfLink = await handleOnUploadPdfFile(event);
    if (getPdfLink && getPdfLink !== "") {
      handleGenerateBasicInputInfo();
    }
  };

  const onSubmitCompanyPreferences = (value) => {
    const data = trimedInputValue(value);
    const companyPref = {
      id: userRes.id,
      companyDetails: { ...data }
    };

    dispatch(setLoader(true));
    invokeAxiosPost(`/user-api/get-or-create/userUpdate`, companyPref).then((res) => {
      dispatch(setLoader(false));
      dispatch(getUserDetail(userRes?.id));
      toast.success("Company preferences updated successfully.");
      saveCompanyPreferenceDetails(data);

      if (closeButtonRef.current) {
        closeButtonRef.current.click();
      }
    }).catch((error) => {
      dispatch(setLoader(false));
      toast.error("Something went wrong.", { id: "Toast-01" });
      console.log(error);
    });
  };


  return (
    <div className="addllyFormWrap">
      <form onSubmit={handleSubmit(onSubmitFormAndGenerate)}>
        <div className="selectBlock d-flex flex-wrap gap-3 align-items-center justify-content-between mx-2 mx-md-auto">
          <div className="">
            <b>Enter details to generate a press release</b>
          </div>
          <div className="nxtGenBtn d-flex flex-wrap gap-3 align-items-center align-items-center gap-2">
            <button
              type="button" className="d-flex align-items-center gap-2 bg-transparent border-0 selectBbutton"
              onClick={async () => {
                // dispatch(setPressReleaseWriterStep(0)); dispatch(setPressReleaseWriterInfo({}));
                // dispatch(setPressReleaseWriterHistory({})); 
                dispatch(setPressReleaseWriterStep(1));
                // navigate("/press-release");
              }}
            >
              <BsArrowLeft className="" /> Previous
            </button>
            <span style={{ opacity: 0.1 }}>|</span>
            {!isShowNextButton ? (
              <button
                type="button" className="d-flex align-items-center gap-2 bg-transparent border-0 selectBbutton"
                disabled={isEditPressRelease || !watch("type") || !watch("link") || isButtonLoader} onClick={handleGenerateBasicInputInfo}
              >
                {pressReleaseWriterInfo.selectedCard !== "PDF" ? "Select Keywords" : "Generate Press Release"} <BsArrowRight className="" />
              </button>
            ) : (
              <button
                type="submit" className="d-flex align-items-center gap-2 bg-transparent border-0 selectBbutton"
                disabled={!watch("type") || isButtonLoader}
              >
                {pressReleaseWriterInfo?.selectedCard !== "PDF" ? "Select Keywords" : "Generate Press Release"} <BsArrowRight className="" />
              </button>
            )}
          </div>
        </div>
        <Row className="genrateFields">
          <Col sm="12" md="6" className="chooseModel">
            <div className="fields">
              <div className="form-group mb-4">
                <label>Press Release Type <span className="text-danger fs-6 ms-1">*</span></label>
                <Controller
                  control={control} name="type" {...register("type")}
                  render={({ field: { onChange, value, name, ref } }) => (
                    <Select
                      className={`custom-select ${errors.type ? "is-invalid" : ""}`}
                      classNamePrefix="custom-select" placeholder="Select Type"
                      options={pressReleaseTypeOptions} isClearable={false} inputRef={ref}
                      value={pressReleaseTypeOptions.find(c => c.value === value) || ""}
                      onChange={(e) => { onChange(e.value); handleOnChangePressReleaseType(e); }}
                      isDisabled={isDisabledLinkFields} isSearchable={false}
                    />
                  )}
                />
                <div className="invalid-feedback">{errors.type?.message}</div>
              </div>
            </div>
          </Col>
          <Col sm="12" md="6">
            <div className="fields languageField mb-4">
              <label htmlFor="">Choose a Language <span className="text-danger fs-6 ms-1">*</span></label>
              <Select
                className={`custom-select ${errors.language ? "is-invalid" : ""}`}
                classNamePrefix="custom-select" placeholder="Select Language"
                options={supportedLanguageList} isClearable={false} isSearchable={false}
                value={supportedLanguageList.find(c => c.value === currentLan) || ""}
                onChange={(e) => setCurrentLan(e.value)} getOptionLabel={handleCustomSelectOption}
                isDisabled={isDisabledLinkFields}
              />
            </div>
          </Col>

          {watch("type") && watch("type") !== "" && (
            <React.Fragment>
              {pressReleaseWriterInfo.selectedCard === "Link" ? (
                <Col sm="12">
                  <div className="form-group mb-4">
                    <label>Need a post crafted around your website link? <span className="text-danger fs-6 ms-1">*</span></label>
                    <BsInfoCircleFill
                      className="infoIconSvg" data-tooltip-id="my-tooltip" data-tooltip-place="bottom-start"
                      data-tooltip-content="Enter website URL to generate a personalized press release based on its content."
                    />
                    <input
                      name="link" type="text" placeholder="Enter URL" {...register("link")}
                      className={`addllyForm-control ${errors.link ? "is-invalid" : ""}`}
                      disabled={isDisabledLinkFields}
                    />
                    <div className="invalid-feedback">{errors.link?.message}</div>
                  </div>
                </Col>
              ) : pressReleaseWriterInfo.selectedCard === "PDF" ? (
                <Col sm="12" md="12">
                  <div className="form-group">
                    <label>Upload your PDF <span className="text-danger fs-6 ms-1">*</span></label>
                    <BsInfoCircleFill
                      className="infoIconSvg" data-tooltip-id="my-tooltip" data-tooltip-place="bottom-start"
                      data-tooltip-content="Upload a PDF to generate a press-release based on its content."
                    />
                  </div>
                  <div className="mb-3">
                    <div className="fileUploadBlock">
                      <FileUploader
                        name="formFile" ref={fileInputRef} types={["pdf"]} maxSize={5} children={FileUploaderCustomUi}
                        multiple={true} classes={`w-100`} handleChange={handleOnUploadPdfFile} isDisabled={isDisabledLinkFields}
                        onDraggingStateChange={(dragging) => setUploadFileDragging(dragging)} dropMessageStyle={{ display: "none" }}
                        onSizeError={() => { setUploadFileError("File size exceeds the allowable limit. Please upload a smaller file."); }}
                        onTypeError={() => { setUploadFileError("Invalid file type. Please upload a file with a supported file type."); }}
                      />
                    </div>
                    {watch("link") && watch("link") !== "" && (
                      <div className="d-flex align-items-center history-table mt-2">
                        <AiOutlineFilePdf className="fs-5 me-3" style={{ minWidth: "24px" }} />
                        <a href={watch("link")} target="_blank" rel="noreferrer"
                          className="text-decoration-none text-width" style={{ maxWidth: "100%" }}
                        >
                          {watch("link").split("/")[watch("link").split("/").length - 1]}
                        </a>
                      </div>
                    )}
                    <div className="invalid-feedback">{errors.link?.message ? "PDF is required" : ""}</div>
                  </div>
                </Col>
              ) : (
                <Col sm="12" md="12">
                  <div className="form-group">
                    <label>Upload PDF for populate below fields</label>
                    <BsInfoCircleFill
                      className="infoIconSvg" data-tooltip-id="my-tooltip" data-tooltip-place="bottom-start"
                      data-tooltip-content="Upload a PDF with all necessary information to auto-populate form fields."
                    />
                  </div>
                  <div className="mb-3">
                    <div className="fileUploadBlock">
                      <FileUploader
                        name="formFile" ref={fileInputRef} types={["pdf"]} maxSize={5} children={FileUploaderCustomUi}
                        multiple={true} classes={`w-100`} handleChange={handleOnSelectPdfFileForDescType} disabled={isEditPressRelease}
                        onDraggingStateChange={(dragging) => setUploadFileDragging(dragging)} dropMessageStyle={{ display: "none" }}
                        onSizeError={() => { setUploadFileError("File size exceeds the allowable limit. Please upload a smaller file."); }}
                        onTypeError={() => { setUploadFileError("Invalid file type. Please upload a file with a supported file type."); }}
                      />
                    </div>
                    {watch("link") && watch("link") !== "" && (
                      <div className="d-flex align-items-center history-table mt-2">
                        <AiOutlineFilePdf className="fs-5 me-3" style={{ minWidth: "24px" }} />
                        <a href={watch("link")} target="_blank" rel="noreferrer"
                          className="text-decoration-none text-width" style={{ maxWidth: "100%" }}
                        >
                          {watch("link").split("/")[watch("link").split("/").length - 1]}
                        </a>
                      </div>
                    )}
                    <div className="invalid-feedback">{errors.link?.message ? "PDF is required" : ""}</div>
                  </div>
                </Col>
              )}
            </React.Fragment>
          )}

          {isShowInputFields && isShowInputFields === true && PressReleaseInputFields && pressReleaseWriterInfo.selectedCard !== "PDF" && (
            <React.Fragment>
              <div className="partitionDiv"></div>
              <div className="mb-3"><b>Enter details for {watch("type")}</b></div>
              {PressReleaseInputFields[watch("type")] && PressReleaseInputFields[watch("type")].map((ele, index) => (
                <Col sm="6" lg={ele.col ? ele.col : "4"} className="" key={index}>
                  <div className="form-group mb-3">
                    {ele.required ? <> <label>{ele.label}</label><span className="text-danger fs-6 ms-1">*</span></> : <label>{ele.label}</label>}
                    {ele.type === "textarea" ? (
                      <textarea
                        name={ele.name} type="text" {...register(ele.name, { required: ele.required ? `${ele.label} is required` : false })}
                        rows={4} style={{ resize: "none" }} placeholder={ele.placeholder} disabled={isEditPressRelease}
                        className={`addllyForm-control h-auto px-3 py-2 ${errors[ele.name] ? "is-invalid" : ""}`}
                      />
                    ) : ele.type === "date" ? (
                      <input
                        name={ele.name} type="date" {...register(ele.name)}
                        className={`addllyForm-control position-relative px-4 ${errors[ele.name] ? "is-invalid" : ""}`}
                        style={{ height: "48px", color: watch(ele.name) ? "" : "#BFC1C6" }}
                        disabled={isEditPressRelease}
                      />
                    ) : (
                      <input
                        name={ele.name} type="text" placeholder={ele.placeholder} {...register(ele.name)}
                        className={`addllyForm-control px-3 ${errors[ele.name] ? "is-invalid" : ""}`}
                        disabled={isEditPressRelease}
                      />
                    )}
                    <div className="invalid-feedback m-0">{errors[ele.name]?.message}</div>
                  </div>
                </Col>
              ))}

              <div className="partitionDiv"></div>
              <div className="d-flex flex-wrap align-items-center justify-content-between" style={{ marginBottom: "8px" }}>
                <b>Media Contact Info
                  <BsInfoCircleFill
                    className="infoIconSvg" data-tooltip-id="my-tooltip" data-tooltip-place="bottom-start"
                    data-tooltip-content="Provide media contact information. This will serve as the Call-to-Action (CTA) for the press release."
                  />
                </b>
                <button type="button" className="btn d-flex align-items-center gap-2" style={{ color: "#0039FF" }} onClick={companyPreferenceToggleModal}
                  data-tooltip-id="my-tooltip" data-tooltip-content={"Edit Contact Info"} data-tooltip-place="bottom"
                  data-bs-toggle="offcanvas" data-bs-target="#companyPreferenceTab" aria-controls="companyPreferenceTab"
                >
                  <BiSolidEditAlt /> Add / Update Defaults
                </button>
              </div>

              {PressReleaseInputFields["Media Contact Info"] && PressReleaseInputFields["Media Contact Info"].map((ele, index) => (
                <Col sm="6" lg={ele.col ? ele.col : "4"} className="" key={index}>
                  <div className="form-group mb-3">
                    <label>{ele.label}</label>
                    <input
                      name={ele.name} type="text" placeholder={ele.placeholder} {...register(ele.name)}
                      className={`addllyForm-control px-3 ${errors[ele.name] ? "is-invalid" : ""}`}
                      disabled={isEditPressRelease}
                    />
                    <div className="invalid-feedback">{errors[ele.name]?.message}</div>
                  </div>
                </Col>
              ))}
            </React.Fragment>
          )}
        </Row>
        <div className="buttonDiv gap-0">
          {!isShowNextButton ? (
            <Button
              type={"button"} className="button" onClick={handleGenerateBasicInputInfo}
              disabled={isEditPressRelease || !watch("type") || !watch("link") || isButtonLoader}
            >
              {isButtonLoader ? (
                <React.Fragment>
                  Generating {pressReleaseWriterInfo?.selectedCard === "PDF" && "Press Release"} <ButtonLoader />
                </React.Fragment>
              ) : <>{pressReleaseWriterInfo?.selectedCard !== "PDF" ? "Select Keywords" : "Generate Press Release"} <BsArrowRight /></>}
            </Button>
          ) : (
            <Button type={"submit"} className="button" disabled={!watch("type") || isButtonLoader}>
              {isButtonLoader ? <>Generating Keywords <ButtonLoader /></> : <>{pressReleaseWriterInfo?.selectedCard !== "PDF" ? "Select Keywords" : "Generate Press Release"} <BsArrowRight /></>}
            </Button>
          )}
          {!articleId && (
            <BsInfoCircleFill
              className="infoIconSvg" data-tooltip-id="my-tooltip" data-tooltip-place="right"
              data-tooltip-content={`Will Utilize ${userRes?.deductCredits?.["Press Release"] || 3} Addlly Credits`}
            />
          )}
        </div>
      </form>

      <div className="unselectable">
        <div className="verionBlock">
          <div className="offcanvas offcanvas-end" style={{ maxWidth: "500px", overflow: "scroll" }} tabIndex="-1" id="companyPreferenceTab" aria-labelledby="companyPreferenceLabel">
            <div className="verpopup py-4">
              <div className="offcanvas-header pb-4 mb-4">
                <h5 className="offcanvas-title d-flex align-items-center gap-2" id="companyPreferenceLabel">
                  <ReactSVG src={sideBarIcons.DescribeNews} /> Default media contact details
                </h5>
                <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas"
                  aria-label="Close" onClick={companyPreferenceToggleModal} ref={closeButtonRef}
                ></button>
              </div>
              <div className="offcanvas-body">
                <form onSubmit={handleSubmit1(onSubmitCompanyPreferences)}>
                  <Row className="genrateFields p-0">
                    {[
                      { label: "Company’s CEO", name: "ceo_name", placeholder: "Enter CEO’s name", icon: <FiUser /> },
                      { label: "Company’s Website", name: "web_link", placeholder: "Enter website URL", icon: <GoLink /> },
                      { label: "Contact Name", name: "contact_name", placeholder: "Enter Name", icon: <TiBusinessCard /> },
                      { label: "Contact Email", name: "contact_email", placeholder: "Enter Email", icon: <FiMail /> },
                      { label: "Contact Phone Number", name: "contact_phone_number", placeholder: "Enter Phone Number", icon: <AiOutlinePhone /> },
                      { label: "Contact Address", name: "contact_address", placeholder: "Enter Contact Address", icon: <CiLocationOn />, type: "textarea" },
                    ].map((ele, index) => (
                      <Col sm={12} className="mb-4" key={index}>
                        <div className="form-group">
                          <label className="d-flex align-items-center gap-2">
                            {ele.icon} {ele.label}
                          </label>
                          {ele.type && ele.type === "textarea" ? (
                            <textarea
                              name={ele.name} type="text" {...register1(ele.name)} rows="3"
                              className={`addllyForm-control h-100`} placeholder={ele.placeholder}
                              disabled={isEditPressRelease}
                            />
                          ) : (
                            <input
                              name={ele.name} type="text" placeholder={ele.placeholder} {...register1(ele.name)}
                              className={`addllyForm-control px-3 ${errors1[ele.name] ? "is-invalid" : ""}`}
                              disabled={isEditPressRelease}
                            />
                          )}
                          <div className="invalid-feedback">{errors1[ele.name]?.message}</div>
                        </div>
                      </Col>
                    ))}
                  </Row>
                  <div className="d-flex">
                    <button type="submit" className="addlly-primary" style={{ maxWidth: "fit-content" }}>
                      Save Details
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
};

export default SecondStepPressRelease;
