import React, { Fragment } from "react";

export default function CreditsCard({ userRes }) {
  const { credits } = userRes;

  return (
    <Fragment>
      <div className="innerCardBlock d-flex justify-content-between mb-0">
        <div className="d-flex gap-2 align-items-center ">
          <h4 className={credits < 1 ? "text-danger" : ""}>{credits}</h4>
          <h6>Addlly Credits Left</h6>
        </div>
      </div>
      {/* <div className="buttonUpgrade d-flex">
        <CustomButton onClick={() => navigate("/plans")}>
          <img src={sideBarIcons.emptyWallet} alt="wallet_icon" /> View Plans
        </CustomButton>
      </div> */}
    </Fragment>
  );
}
