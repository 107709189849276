import React, { useMemo, useState } from "react";
import classNames from "classnames";
import { ReactSVG } from "react-svg";
import MenuItem from "@mui/material/MenuItem";
import { Link } from "react-router-dom";
import { TfiTrash } from "react-icons/tfi";
import { RiInboxUnarchiveFill } from "react-icons/ri";
import { IoEyeOutline } from "react-icons/io5";
import TableLoader from "../ui/TableLoader";
import DropdownMenu from "../ui/Menu";
import Filters from "../commonComponents/Filters";
import ArticlePreview from "../commonComponents/ArticlePreview";
import CustomDataTable from "../ui/DataTable";
import { SortArrow, tableArticleStatusHandle, tableDateFormatter } from "../../utility/common";
import NewsletterAndPressReleaseHoc from "../../Hoc/NewsletterAndPressReleaseHoc";
import { articleHistoryIcons, dashboardIcon, dataTableIcons } from "../../Assets/Icons";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const NewslettersHistory = (props) => {
  const {
    historyData, textWrap, setTextWrap, starred, setStarred, setSearch, search, loading, filters, setFilters, selectedRows, clearRow,
    setSelectedRows, handleArchiveAll, getArticleAndPostHistoryData, totalCount, setPage, limit, page, isFetching,
    handlePinArticle, handleDelete, handleSort, sortField, userRes, getHistoryShortFlowData, handleGetDetailsById,
  } = props;
  const [article, setArticle] = useState({ id: '', type: '' });
  const [showOffset, setShowOffset] = useState(false);

  const handleOpenArticle = (id, type) => {
    setArticle(pre => ({ ...pre, id: id, type }));
    setShowOffset(true);
  }

  const columns = useMemo(() => {
    const tempColumns = [
      {
        id: "title",
        name: <div className="d-flex">Article Details {SortArrow(sortField, "title")}</div>,
        selector: (row) => (
          <div className=" d-flex align-items-center gap-2" onClick={() => handleTableRowClick(row)}>
            <div className={classNames("tableValues", { showFullDes: textWrap })}>
              <OverlayTrigger placement="bottom" flip overlay={<Tooltip id="tooltip">{row.topic}</Tooltip>}>
                <h6 >{row.topic || "-"}</h6>
              </OverlayTrigger>
            </div>
            <OverlayTrigger placement="bottom" flip overlay={<Tooltip style={{ zIndex: 9999 }} id="tooltip">
              {row.is_pinned ? "Remove from Starred" : "Add to Starred"}
            </Tooltip>}>
              <img className={classNames({ 'hover-star': !starred })} onClick={(e) => { e.stopPropagation(); handlePinArticle(row.type, row.id, row?.is_pinned) }}
                src={row.is_pinned ? dashboardIcon.starFill : dashboardIcon.star}
                alt="star" loading="lazy" />
            </OverlayTrigger>
          </div>
        ),
        sortField: "topic",
        sortable: true,
      },
      {
        id: "CTA",
        name: <div className="d-flex">CTA {SortArrow(sortField, "CTA")}</div>,
        selector: (row) => (
          <div className={classNames("tableValues", { showFullDes: textWrap })} onClick={() => handleTableRowClick(row)}>
            {row.cta ? (
              <h6 className="w-100" data-tooltip-id="my-tooltip" data-tooltip-content={row.cta} data-tooltip-place="bottom">{row.cta || "-"}</h6>
            ) : "-"}
          </div>
        ),
        sortable: true,
        sortField: "cta",
      },
      {
        id: "Language",
        name: <div className="d-flex"> Language {SortArrow(sortField, "Language")}</div>,
        selector: (row) => row?.language || "-",
        sortable: true,
        sortField: "language",
      },
      {
        id: "created_at",
        name: <div className="d-flex">Created On {SortArrow(sortField, "created_at")}</div>,
        selector: (row) => tableDateFormatter(row?.created_at),
        sortable: true,
        sortField: "created_at",
      },
      {
        id: "Status",
        name: <div className="d-flex recentArticleHistoryStatus">Status {SortArrow(sortField, "Status")}</div>,
        selector: (row) => tableArticleStatusHandle(row, handleTableRowClick),
        sortable: true,
        sortField: "status",
      },
      {
        id: "sort",
        name: 'Action',
        width: '60px',
        selector: (row) => {
          return (
            <div className="popup">
              <DropdownMenu>
                {row.status !== "pending" && row.status !== "notAcceptable" && (
                  <MenuItem onClick={() => handleOpenArticle(row.id, row?.type)}><IoEyeOutline /> Preview article</MenuItem>
                )}
                {row.status !== "notAcceptable" && (
                  <MenuItem onClick={() => handleGetDetailsById(row.id, true)}><ReactSVG src={dataTableIcons.pencil} /> Edit</MenuItem>
                )}
                {row.is_archived && row.is_archived === 1 ? (
                  <MenuItem onClick={() => handleDelete(row?.id, row?.type, "delete")}><TfiTrash /> Delete</MenuItem>
                ) : (
                  <MenuItem onClick={() => handleDelete(row?.id, row?.type, "archive")}><RiInboxUnarchiveFill /> Archive</MenuItem>
                )}
              </DropdownMenu>
            </div>
          );
        },
      },
    ];

    if (userRes && userRes.role && userRes.role === "OrgAdmin") {
      tempColumns.splice(3, 0, {
        id: "created_by",
        name: <div className="d-flex">Created By {SortArrow(sortField, "created_by")}</div>,
        selector: (row) => (
          <div className="user-info-column">
            {row?.profileUrl && row?.profileUrl !== "" ? (
              <img src={row?.profileUrl} alt="user" className="userIcon" />
            ) : (
              <div className="avatar">{row?.username?.[0]}</div>
            )}
            <span>{row?.username || ""}</span>
          </div>
        ),
        sortable: true,
        sortField: "username",
      });
    }

    return tempColumns;
  }, [handleDelete, handlePinArticle, sortField, textWrap])

  const handleTableRowClick = (row) => {
    if (row.status === "completed") {
      handleOpenArticle(row.id, row?.type);
    } else if (row.status === "notAcceptable") {
    } else {
      handleGetDetailsById(row.id, true);
    }
  };

  return (
    <React.Fragment>
      <div className=" d-flex justify-content-between gap-2 mb-4">
        <span className="mb-2">Recent History</span>
        <Link to="/history" className="">
          View All
        </Link>
      </div>
      <div className="filters">
        <Filters
          starred={starred} setStarred={setStarred} textWrap={textWrap} setSearchFilter={setSearch}
          setTextWrap={setTextWrap} setFilters={setFilters} filters={filters} selectedRows={selectedRows}
          getArticleAndPostHistoryData={getArticleAndPostHistoryData} setSelectedRows={setSelectedRows}
          handleArchiveAll={handleArchiveAll} setPage={setPage} isFetching={isFetching}
        />
      </div>

      <div className="historyTableBlock mt-2">
        <CustomDataTable
          progressPending={loading} progressComponent={<TableLoader limit={limit} />}
          columns={columns} totalCount={totalCount} pagination={true} data={historyData}
          itemsPerPage={limit} selectableRows={true} noDataComponent={<NoDataFound search={search} />}
          onSelectedRowsChange={setSelectedRows} setPage={setPage} page={page} onSort={handleSort}
          handleTableRowClick={handleTableRowClick} clearSelectedRows={clearRow}
        />
      </div>
      <ArticlePreview
        userRes={userRes} articleId={article.id} articleType={article.type} show={showOffset}
        setShow={setShowOffset} getHistoryData={getHistoryShortFlowData}
        handleNavigateArticleOrPostWriter={(articleId) => handleGetDetailsById(articleId, true)}
      />
    </React.Fragment>
  )
}

export default NewsletterAndPressReleaseHoc(NewslettersHistory);

const NoDataFound = ({ search }) => {
  return (
    <div className="withoutData d-flex justify-content-center align-items-center">
      {!search ? <div className="genrateNewBlock d-flex justify-content-center flex-column align-items-center">
        <div className="icon d-flex justify-content-center flex-column align-items-center">
          <img src={articleHistoryIcons.add} alt="add"></img>
        </div>
        <p>Newsletter you create will be shown here</p>
      </div> : <span>Oops... Search not found.</span>}
    </div>
  );
};
