import React from "react";
import { useSelector } from "react-redux";
import GIFLoader from "../Assets/Gif/addlly-primary-loader.gif";

const Loader = () => {
  const loader = useSelector(({ loader }) => loader);

  return (
    <React.Fragment>
      {loader && (
        <div className="custom-loader">
          <img src={GIFLoader} alt="loading..." />
        </div>
      )}
    </React.Fragment>
  );
};

export default Loader;
