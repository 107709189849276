import React, { useState } from 'react'
import { ReactSVG } from 'react-svg'
import { authenticateIcon } from '../Assets/Icons'
import { Col, Row } from 'react-bootstrap'
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import classNames from 'classnames'
import { useDispatch } from 'react-redux'
import toast from 'react-hot-toast'
import { setLoader } from '../store/reducer'
import axios from 'axios'
const ResetPassword = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const [newPass, setNewPass] = useState('');
    const [confirmPass, setConfirmPass] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [typePassword, setTypePassword] = useState([]);
    const { state } = useLocation();

    const handleNewPass = (event) => setNewPass(event.target.value);

    const handleConfirmPass = (event) => setConfirmPass(event.target.value);

    const handleVerifyPassword = () => {
        if (!/.{8,}/.test(newPass)) {
            setPasswordError("Password must be at least 8 characters long.");
            return false;
        }
        if (!/[a-z]/.test(newPass)) {
            setPasswordError("Password must contain at least one lowercase letter.");
            return false;
        }
        if (!/[A-Z]/.test(newPass)) {
            setPasswordError("Password must contain at least one uppercase letter.");
            return false;
        }
        if (!/\d/.test(newPass)) {
            setPasswordError("Password must contain at least one digit.");
            return false;
        }
        if (!/[@$!%*?&]/.test(newPass)) {
            setPasswordError("Password must contain at least one special character (@$!%*?&).");
            return false;
        } else {
            setPasswordError('');
            handleChangePassword();
        }
    };

    const handleChangePassword = async () => {
        if (newPass !== confirmPass) {
            setPasswordError('New Password and Confirm Password do not match.');
            return false;
        }

        if (passwordError === '') {
            dispatch(setLoader(true));
            axios.post(`${process.env.REACT_APP_API_URL}/user/changePassword`, {
                email: state?.email, otp: state?.otp, passW: newPass
            }).then((res) => {
                dispatch(setLoader(false));

                if (res.data.data.success === 1) {
                    toast.success('Password changed successfully', { id: "Toast-01" });
                    setNewPass('');
                    setConfirmPass('');
                    navigate('/')
                } else {
                    toast.error(res.data.data.message, { id: "Toast-01" });
                }
            }).catch((error) => {
                dispatch(setLoader(false));
                toast.error(error?.response?.data?.error || "Email Address Not Found", { id: "Toast-01" });
            });
        }
    };

    const handleShowOrHidePassword = (no) => {
        const typePasswordArray = [...typePassword];
        if (typePasswordArray.includes(no)) {
            typePasswordArray.splice(typePassword.indexOf(no), 1);
        } else {
            typePasswordArray.push(no);
        }
        setTypePassword(typePasswordArray);
    }
    return (
        <div className=' Reset-password maingenrateBlock'>
            <div className="logos">
                <ReactSVG src={authenticateIcon.AddllyBlueLogo} alt="addlly logo" />
            </div>
            <div className='d-flex align-items-center justify-content-center password-section flex-column'>
                <h5>Reset your password</h5>
                <form className=''>
                    <div className="genrateFields">
                        <Row className="m-0">
                            <Col xl={12} className=" p-0 mb-3">
                                <div className="fields"  >
                                    <label className=" color-light-text p-0">New Password  <sup>*</sup></label>
                                    <div className=" inputField">
                                        <input
                                            name="newPassword" type={!typePassword.includes(1) ? "password" : "text"}
                                            className={`w-100 `} value={newPass} onChange={handleNewPass}
                                            placeholder="Enter password"
                                        />
                                        {passwordError && passwordError != "" && <label className='alert alert-danger text-danger w-100 px-3 py-1 my-1'> {passwordError}</label>}
                                        {typePassword.includes(1) ? (
                                            <AiOutlineEyeInvisible size={16} onClick={() => handleShowOrHidePassword(1)} color="#50506A" />
                                        ) : (
                                            <AiOutlineEye size={16} onClick={() => handleShowOrHidePassword(1)} color="#50506A" />
                                        )}
                                    </div>
                                </div>
                            </Col>
                            <Col xl={12} className=" p-0 ">
                                <div className="fields"  >
                                    <label className=" color-light-text p-0">Confirm Password <sup>*</sup></label>
                                    <div className=" inputField">
                                        <input
                                            name="confirmPassword" type={!typePassword.includes(2) ? "password" : "text"}
                                            className={`w-100 `} value={confirmPass} onChange={handleConfirmPass}
                                            placeholder="Enter password again"
                                        />

                                        {typePassword.includes(2) ? (
                                            <AiOutlineEyeInvisible size={16} color="#50506A" onClick={() => handleShowOrHidePassword(2)} />
                                        ) : (
                                            <AiOutlineEye size={16} color="#50506A" onClick={() => handleShowOrHidePassword(2)} />
                                        )}

                                    </div>
                                </div>
                            </Col>

                        </Row>
                    </div>
                    <div className="authenticate-button d-flex align-items-center mt-4">
                        <button type='button' disabled={!confirmPass || !newPass} className={classNames("w-100 justify-content-center")} onClick={handleVerifyPassword}>
                            Reset Password
                        </button>
                    </div>
                    <span>Remember password? <Link to={"/"}>Login</Link></span>
                </form>
            </div>
        </div>
    )

}

export default ResetPassword