import React, { useState } from "react";
import AllPolicyModal from "./Modal/AllPolicyModal";

const Footer = () => {
  const [policyModal, setPolicyModal] = useState(null);

  const handleClose = () => {
    setPolicyModal(null);
  }

  return (
    <React.Fragment>
      {/* <footer className="footer-content">
        <div className="d-flex align-items-center justify-content-around flex-wrap">
          <h6 className="">Copyright © {new Date().getFullYear()} Addlly.AI All rights reserved</h6>
          <div className="d-flex align-items-center ms-5">
            {window.location.href && window.location.href.indexOf("/plans") !== -1 ? (
              <React.Fragment>
                <h6 className="footer-title me-4" onClick={() => setPolicyModal("cancellationPolicy")} onKeyDown={() => setPolicyModal("cancellationPolicy")}>Cancellation Policy</h6>
                <h6 className="footer-title" onClick={() => setPolicyModal("refundPolicy")} onKeyDown={() => setPolicyModal("refundPolicy")}>Refund Policy</h6>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <h6 className="footer-title me-4" onClick={() => setPolicyModal("termsAndConditions")} onKeyDown={() => setPolicyModal("termsAndConditions")}>Terms of service</h6>
                <h6 className="footer-title me-4" onClick={() => setPolicyModal("privacyPolicy")} onKeyDown={() => setPolicyModal("privacyPolicy")}>Privacy policy</h6>
                <h6 className="footer-title me-4" onClick={() => setPolicyModal("dataProcessingAgreement")} onKeyDown={() => setPolicyModal("dataProcessingAgreement")}>Data processing agreement</h6>
              </React.Fragment>
            )}
          </div>
        </div>
      </footer>

      {policyModal && policyModal !== "" && (
        <AllPolicyModal type={policyModal} handleClose={handleClose} />
      )} */}
    </React.Fragment>
  )
}

export default Footer;
