import React, { useEffect, useState } from 'react'
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import { FaMicrophone, FaMicrophoneSlash } from "react-icons/fa";

const SpeechToText = ({ name, setValue }) => {
  const { transcript, listening, browserSupportsSpeechRecognition } = useSpeechRecognition();
  const [checkName, setCheckName] = useState("");

  useEffect(() => {
    if (transcript && checkName === name) {
      setValue(name, transcript);
      setCheckName("");
    }
  }, [listening])

  if (!browserSupportsSpeechRecognition) {
    return "";
  }

  const handleStartListening = () => {
    setCheckName(name);
    SpeechRecognition.startListening();
  }

  const handleStopListening = () => {
    setCheckName(name);
    SpeechRecognition.stopListening();
  }

  return (
    <React.Fragment>
      <div className={`custom-mic-icon ${listening && checkName === name ? "active" : ""}`}>
        {listening && checkName === name ? (
          <FaMicrophone onClick={handleStopListening} />
        ) : (
          <FaMicrophoneSlash onClick={handleStartListening} />
        )}
      </div>
      <div className={`custom-mic-icon circles ${listening && checkName === name ? "active" : ""}`}>
        <div className="circle1"></div>
        <div className="circle2"></div>
        <div className="circle3"></div>
      </div>
    </React.Fragment>
  )
}

export default SpeechToText;
