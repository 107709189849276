import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa6";
import { resetSEOFLow, setHistoryData, setHistoryDataLength, setSeoOptBlogStep } from "../../store/reducer";

const SeoOptimizedBlogHead = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const seoOptBlogStep = useSelector(({ seoOptBlogStep }) => seoOptBlogStep);
  const seoOptBlogTopic = useSelector(({ seoOptBlogTopic }) => seoOptBlogTopic);
  const isShowLinks = seoOptBlogTopic && seoOptBlogTopic.generateLink !== 0 ? true : false;
  const [isMobileView, setIsMobileView] = useState(false);
  const [isMobileHead, setIsMobileHead] = useState(false);
  const stepArr = [
    { "step": 1, "label": "Select Type" },
    { "step": 2, "label": "Enter Topic" },
    { "step": 3, "label": "Select Links" },
    { "step": 4, "label": "Select Keywords" },
    { "step": 5, "label": "SEO Optimisation" },
    { "step": 6, "label": "Select Outlines" },
    { "step": 7, "label": "Final Article" }
  ];

  useEffect(() => {
    function mobileViewUpdate() {
      const viewportWidth = window.innerWidth;
      if (viewportWidth <= 770) {
        setIsMobileView(true);
      } else {
        setIsMobileView(false);
      }
    }
  }, []);

  useEffect(() => {
    const handleResize = () => {
      const parentDiv = document.getElementsByClassName('AIWriterBlog')[0];
      if (parentDiv) {
        const parentRect = parentDiv.getBoundingClientRect();
        const parentWidth = parentRect.width;
        const childDiv = document.getElementsByClassName('AIWriterStepper')[0];
        if (childDiv) {
          const childRect = childDiv.getBoundingClientRect();
          const childWidth = childRect.width;
          if (parentWidth <= childWidth) {
            setIsMobileHead(true);
          } else {
            setIsMobileHead(false);
          }
        } else {
          console.log('childDiv not found');
        }
      } else {
        console.log('parentDiv not found');
      }
    };

    handleResize();

    window.addEventListener('resize', handleResize);
    window.addEventListener('load', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('load', handleResize);
    };
  }, []);

  const handleOnChangeStep = async (no) => {
    if (no >= 1 && no <= 7) {
      if (seoOptBlogTopic?.stepDone && no <= seoOptBlogTopic?.stepDone) {
        dispatch(setSeoOptBlogStep(no));
        // if (no === 1) {
        //   dispatch(resetSEOFLow());
        //   dispatch(setHistoryData([]));
        //   dispatch(setHistoryDataLength(0));
        //   dispatch(setSeoOptBlogStep(1));
        //   navigate('/blog-co-pilot');
        // }
      }
    }
  }

  return (
    <div className="AIWriterBlogHead">
      <div className="AIWriterBlogHeadTop justify-content-center">
        <div className="AIWriterStepper justify-content-center">
          {isMobileHead ? (
            <ul>
              <li
                className={`${seoOptBlogStep === stepArr[seoOptBlogStep - 1].step ? "" : ""} ${stepArr[seoOptBlogStep - 1].step < seoOptBlogStep ? "complete" : ""} ${stepArr[seoOptBlogStep - 1].step <= seoOptBlogTopic?.stepDone ? "is-clickable" : ""}`}
                onClick={() => handleOnChangeStep(stepArr[seoOptBlogStep - 1].step - 1)}
              >
                <span className="d-flex justify-content-center align-items-center">
                  <FaAngleLeft />
                </span>
                <p>Previous</p>
              </li>
              <li
                className={`${seoOptBlogStep === stepArr[seoOptBlogStep - 1].step ? "active" : ""} ${stepArr[seoOptBlogStep - 1].step < seoOptBlogStep ? "complete" : ""} ${stepArr[seoOptBlogStep - 1].step <= seoOptBlogTopic?.stepDone ? "is-clickable" : ""}`}
              >
                <span className="d-flex justify-content-center align-items-center">
                  {!isShowLinks && stepArr[seoOptBlogStep - 1].step > 3 ? `0${stepArr[seoOptBlogStep - 1].step - 1}` : `0${stepArr[seoOptBlogStep - 1].step}`}
                </span>
                <p>{stepArr[seoOptBlogStep - 1].label}</p>
              </li>
              <li
                className={`${seoOptBlogStep === stepArr[seoOptBlogStep - 1].step ? "" : ""} ${stepArr[seoOptBlogStep - 1].step < seoOptBlogStep ? "complete" : ""} ${stepArr[seoOptBlogStep - 1].step <= seoOptBlogTopic?.stepDone ? "is-clickable" : ""}`}
                onClick={() => handleOnChangeStep(stepArr[seoOptBlogStep - 1].step + 1)}
              >
                <span className="d-flex justify-content-center align-items-center">
                  <FaAngleRight />
                </span>
                <p className={``}>Next</p>
              </li>
            </ul>
          ) : (
            <ul>
              {stepArr && stepArr.map((step, index) => (
                (!isShowLinks && step.step === 3) ? null : (
                  <li
                    className={`${seoOptBlogStep === step.step ? "active" : ""} ${step.step < seoOptBlogStep ? "complete" : ""} ${step.step <= seoOptBlogTopic?.stepDone ? "is-clickable" : ""}`}
                    onClick={() => handleOnChangeStep(step.step)} onKeyDown={() => handleOnChangeStep(step.step)} key={index}
                  >
                    <span className="d-flex justify-content-center align-items-center">
                      {!isShowLinks && step.step > 3 ? `0${step.step - 1}` : `0${step.step}`}
                    </span>
                    <p className={``}>{step.label}</p>
                  </li>
                )
              ))}
            </ul>
          )}
        </div>
      </div>
    </div>
  );
};

export default SeoOptimizedBlogHead;
