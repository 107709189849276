import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FileUploader } from "react-drag-drop-files";
import { BiSolidErrorCircle } from "react-icons/bi";
import { BsCloudUploadFill, BsInfoCircleFill, BsTrashFill } from "react-icons/bs";
import { HiOutlineUpload } from "react-icons/hi";
import { setLoader } from "../../../store/reducer";
import { FaRegImage } from "react-icons/fa";
import classNames from "classnames";
import ImageDisplayContent from "./ImageDisplayContent";
import NoDataAvailable from "../../NoDataAvailable";
import { invokeAxiosPost } from "../../../utility/invokeAxiosFunction";

const UploadImage = ({ props, handleSaveSelectedImage }) => {
  const dispatch = useDispatch();
  const userRes = useSelector(({ userRes }) => userRes);

  const { postImagesModal, setPostGeneratedImages, setPostImagesModal, postGeneratedImages, articleId = 0 } = props;
  const mediaFileTypes = ["JPG", "PNG", "JPEG"];
  const [activeTab, setActiveTab] = useState("Upload from computer");
  const [uploadFiles, setUploadFiles] = useState({ files: [], previewUrl: [] });
  const [uploadFileDragging, setUploadFileDragging] = useState(null);
  const [uploadFileError, setUploadFileError] = useState(null);

  const handleOnSelectImageFile = async (files) => {
    if (!files || files?.length === 0) {
      return false;
    }

    const allFiles = [...uploadFiles.files], previewUrl = [...uploadFiles.previewUrl];
    for (let index = 0; index < files.length; index++) {
      const element = files[index];
      allFiles.push(element);
      previewUrl.push(URL.createObjectURL(element));
    }

    setUploadFiles({ files: allFiles, previewUrl: previewUrl });
    setUploadFileError(null);
  };

  const handleOnUploadSelectedImageFile = async () => {
    if (uploadFiles.files && uploadFiles.files.length === 0) {
      return false;
    }

    const uploadSingleFile = (index) => {
      if (uploadFiles.files && (uploadFiles.files.length <= index)) {
        setUploadFiles({ files: [], previewUrl: [] });
        setUploadFileDragging(null); setUploadFileError(null);
        dispatch(setLoader(false)); setActiveTab("Gallery");
        return false;
      }

      const file = uploadFiles.files[index];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        invokeAxiosPost(`/media-library/get-or-generate/saveUploadedImageData`, {
          userId: userRes.id, articleId: articleId,
          imageBase64: reader.result,
          file_name: file.name,
          type: postImagesModal.type,
          subType: postImagesModal.subType
        }).then((response) => {
          if (response && response.data && response.data.images && response.data.images.length > 0) {
            setPostGeneratedImages((prev) => ({ ...prev, imageHistory: response.data.images }));
          }
          uploadSingleFile(index + 1);
        }).catch((error) => {
          uploadSingleFile(uploadFiles.files.length + 2);
          dispatch(setLoader(false));
        });
      };
    }

    dispatch(setLoader(true));
    uploadSingleFile(0);
  };

  const FileUploaderCustomUi = (
    <div
      className={`fileUploadBlock position-relative ${uploadFileDragging ? "active" : ""}`}
      style={uploadFiles && uploadFiles.files && uploadFiles.files.length > 0 ? { height: "400px" } : {}}
    >
      <BsCloudUploadFill className="uploadIcon" />
      <h4 className="form-label">
        <strong>Choose a file</strong> or drag it here
      </h4>
      <p className="m-0 image-types">
        Supported formats: jpg, jpeg, png; maximum file size: 5MB.
      </p>
      {uploadFileError && uploadFileError !== "" ? (
        <React.Fragment>
          <hr className="w-100 mt-4" />
          <h5 className="fw-normal text-danger d-flex align-items-center">
            <BiSolidErrorCircle className="fs-4 me-2" /> {uploadFileError}
          </h5>
        </React.Fragment>
      ) : null}
    </div>
  );

  return (
    <div className="uploadImageB">
      <div className="d-flex header flex-wrap justify-content-between align-items-center gap-3 mb-4">
        <div className="d-flex tabsClick ">
          {['Upload from computer', 'Gallery'].map((name, index) => (
            <button onClick={() => setActiveTab(name)} className={classNames({ active: name === activeTab })} key={index + name}>
              {name}
              {name === 'Gallery' ? <span className="total-images-circle">
                <span className="total-images">
                  {postGeneratedImages?.imageHistory?.length < 10 ?
                    `0${postGeneratedImages?.imageHistory?.length || 0}`
                    : (postGeneratedImages?.imageHistory?.length || "00")}
                </span>
              </span> : null}
            </button>
          ))}
        </div>
        <div className="selectIamgesB d-flex align-items-center">
          {activeTab === "Upload from computer" ? (
            <button
              type="button" className="blogButton border-0" onClick={handleOnUploadSelectedImageFile}
              disabled={uploadFiles && uploadFiles.previewUrl && uploadFiles.previewUrl.length > 0 ? false : true}
            >
              <HiOutlineUpload className="fs-5" /> <span>Upload Images</span>
            </button>
          ) : activeTab === "Gallery" ? (
            <React.Fragment>
              {postImagesModal && postImagesModal.carouselImg && postImagesModal.carouselImg.length > 0 && (
                <button type="button" className="blogButton border-0" onClick={() => handleSaveSelectedImage(postImagesModal)}>
                  <FaRegImage /> Upload Images
                </button>
              )}
            </React.Fragment>
          ) : null}
        </div>
      </div>
      {activeTab === 'Gallery' ? (
        <div className="genrateImagesCards d-flex flex-wrap">
          {(postGeneratedImages?.imageHistory && postGeneratedImages?.imageHistory?.length > 0) ? (postGeneratedImages?.imageHistory?.map((images, index) => (
            <ImageDisplayContent
              image={images} imageIndex={index} viewImgHistory={postGeneratedImages?.imageHistory}
              postImagesModal={postImagesModal} setPostImagesModal={setPostImagesModal}
              handleSaveSelectedImage={handleSaveSelectedImage}
            />
          ))) : (
            <NoDataAvailable text="Images Not Available." />
          )}
        </div>
      ) : (
        <React.Fragment>
          <div className="brandchooseFile ai model pe-2 overflow-auto" style={{ height: "calc(100vh - 275px)" }}>
            <div className={classNames("d-flex gap-3 flex-wrap genrateButton_ genrateImagesCards ", { adddUploadedImages: !!uploadFiles.previewUrl.length })}>
              <div className="brandAi-filUploader chooseCard">
                <FileUploader
                  name="file" types={mediaFileTypes} maxSize={5} children={FileUploaderCustomUi}
                  multiple={true} classes={`w-100`} handleChange={handleOnSelectImageFile}
                  onDraggingStateChange={(dragging) => setUploadFileDragging(dragging)} dropMessageStyle={{ display: "none" }}
                  onSizeError={() => setUploadFileError("File size exceeds the allowable limit. Please upload a smaller file.")}
                  onTypeError={() => setUploadFileError("Invalid file type. Please upload a file with a supported file type.")}
                />
              </div>
              {uploadFiles && uploadFiles.previewUrl && uploadFiles.previewUrl.length > 0 && uploadFiles.previewUrl.map((file, index) => (
                <div className="image-preview-content chooseCard position-relative card">
                  <img src={file} alt="Uploaded-img" />
                  <div className="remove-btn">
                    <button className="btn" type="button" onClick={(e) => {
                      setUploadFiles({
                        files: uploadFiles.files.filter((e1, i1) => i1 !== index),
                        previewUrl: uploadFiles.previewUrl.filter((e1, i1) => i1 !== index),
                      })
                    }}>
                      <BsTrashFill />
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

export default UploadImage;
