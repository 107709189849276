import React, { useEffect, useState } from "react";
import ReactFlagsSelect from "react-flags-select";
import Select from "react-select";
import ReactQuill from "react-quill";
import * as Yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { FiCheck } from "react-icons/fi";
import { LuShoppingCart } from "react-icons/lu";
import { MdOutlineClose } from "react-icons/md";
import { GoArrowLeft } from "react-icons/go";
import { IoIosArrowDown, IoIosArrowUp, IoMdLink } from "react-icons/io";
import { BsArrowLeft, BsArrowRight, BsEyeFill, BsInfoCircleFill } from "react-icons/bs";
import { Col, Dropdown, DropdownButton, InputGroup, ListGroup, Row } from "react-bootstrap";
import { AiTypeOptions } from "../../data";
import ProductViewModal from "./ProductViewModal";
import { invokeAxiosGet, invokeAxiosPost } from "../../utility/invokeAxiosFunction";
import { handleCustomSelectOption, supportedLanguageList } from "../../utility/hepler";
import { setLoader, setECommerceBlogInfo, setECommerceBlogStep, setUserRes } from "../../store/reducer";
import { handleSetLanguageAndGeoLocation } from "../../utility/common";
import "react-quill/dist/quill.snow.css";

const currencyForPrice = [
  { "name": "United States Dollar", "symbol": "$", "code": "USD" },
  { "name": "Euro", "symbol": "€", "code": "EUR" },
  { "name": "British Pound Sterling", "symbol": "£", "code": "GBP" },
  { "name": "Japanese Yen", "symbol": "¥", "code": "JPY" },
  { "name": "Australian Dollar", "symbol": "A$", "code": "AUD" },
  { "name": "Canadian Dollar", "symbol": "C$", "code": "CAD" },
  { "name": "Swiss Franc", "symbol": "CHF", "code": "CHF" },
  { "name": "Chinese Yuan", "symbol": "¥", "code": "CNY" },
  { "name": "Indian Rupee", "symbol": "₹", "code": "INR" },
  { "name": "Russian Ruble", "symbol": "₽", "code": "RUB" },
  { "name": "Brazilian Real", "symbol": "R$", "code": "BRL" },
  { "name": "South Korean Won", "symbol": "₩", "code": "KRW" },
  { "name": "Mexican Peso", "symbol": "Mex$", "code": "MXN" },
  { "name": "South African Rand", "symbol": "R", "code": "ZAR" },
  { "name": "Singapore Dollar", "symbol": "S$", "code": "SGD" },
  { "name": "New Zealand Dollar", "symbol": "NZ$", "code": "NZD" },
  { "name": "Hong Kong Dollar", "symbol": "HK$", "code": "HKD" },
  { "name": "Swedish Krona", "symbol": "kr", "code": "SEK" },
  { "name": "Turkish Lira", "symbol": "₺", "code": "TRY" }
]
const ContentTonesOptions = [
  { label: "Professional", value: "Professional" },
  { label: "Casual", value: "Casual" },
  { label: "Straightforward", value: "Straightforward" },
  { label: "Confident", value: "Confident" },
  { label: "Friendly", value: "Friendly" },
];

const generateTypeOptions = [
  { label: "Direct", value: "direct" },
  { label: "Chain of thought", value: "expandedFlow" }
];

const validationSchema = Yup.object().shape({
  productTitle: Yup.string().required("Topic is required").min(2, "Topic must be at least 2 characters").matches(/^(?!\s)(?!.*\s{2})[^\s](.*[^\s])?$/, "Topic should not contain multiple consecutive white spaces"),
  productUrl: Yup.string().url('Invalid URL').required('Product URL is required'),
  productImageUrl: Yup.string().url('Invalid URL').required('Product image URL is required'),
  productInfo: Yup.string().required("Product Information is required").test(
    "no-consecutive-spaces",
    "Product Information should not contain multiple consecutive white spaces",
    (value) => {
      if (!value) return true;
      return !/\s{2,}/.test(value);
    }
  ),
  productDesc: Yup.string().required("Product Description is required").test(
    "no-consecutive-spaces",
    "Product Description should not contain multiple consecutive white spaces",
    (value) => {
      if (!value) return true;
      return !/\s{2,}/.test(value);
    }
  ),
  productPrice: Yup.number()
    .typeError('Price must be a number') // Displayed when input is not a number
    .required('Price is required') // Displayed when input is empty
    .positive('Price must be positive') // Displayed when input is negative
    .test(
      'is-decimal',
      'Price must be an integer or decimal number',
      (value) => value === undefined || /^\d+(\.\d+)?$/.test(value)
    ),

  productCategory: Yup.string().required("Product Category is required").min(2, "Product category must be at least 2 characters"),
  otherCategory: Yup.string().when('productCategory', (productCategory) => {
    if (productCategory[0] === 'Other') {
      return Yup.string().required("Other Category is required").min(2, "Other category must be at least 2 characters").matches(/^(?!\s)(?!.*\s{2})[^\s](.*[^\s])?$/, "Topic should not contain multiple consecutive white spaces")
    } else {
      return Yup.string().optional()
    }
  }),
});

const FirstStepECommerceBlog = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userRes = useSelector(({ userRes }) => userRes);
  const loader = useSelector(({ loader }) => loader);

  const eCommerceBlogInfo = useSelector(({ eCommerceBlogInfo }) => eCommerceBlogInfo);
  const selectedProductsName = eCommerceBlogInfo?.selectedProducts?.map(({ name }) => name);

  const [aiType, setAiType] = useState(eCommerceBlogInfo?.AI_type || "Claude 3.5 Sonnet");
  const [promotionText, setPromotionTextText] = useState("");
  const [geoLocation, setGeoLocation] = useState(eCommerceBlogInfo?.geoLocation || "SG");
  const [contentTone, setContentTone] = useState(eCommerceBlogInfo?.content_tone || "");
  const [generateType, setGenerateType] = useState("direct");
  const [language, setLanguage] = useState(eCommerceBlogInfo?.language || "English");
  const [categoryList, setCategoryList] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [allProductList, setAllProductList] = useState([]);
  const [productList, setProductList] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(selectedProductsName || []);
  const [viewProduct, setViewProduct] = useState({ open: false, data: null });
  const [productBtn, setProductBtn] = useState(false);
  const [productUrlModel, setProductUrlModel] = useState({ open: false, url: null });
  const [selectedCurrency, setSelectedCurrency] = useState();
  const [otherCategory, setOtherCategory] = useState();
  const [descError, setDescError] = useState(false);
  const [isDisabledInput, setIsDisabledInput] = useState(eCommerceBlogInfo?.articleId ? true : false);
  const [showSelectList, setShowSelectList] = useState([true, false]);
  const [showArticleSpecs, setShowArticleSpecs] = useState(false);

  useEffect(() => {
    historyFetchApi();
  }, []);

  useEffect(() => {
    const location = eCommerceBlogInfo?.geoLocation;
    setGeoLocation(location?.toUpperCase() || "SG");
    setPromotionTextText(eCommerceBlogInfo?.promotionText || "");
    setAiType(eCommerceBlogInfo?.AI_type || "Claude 3.5 Sonnet");
    setLanguage(eCommerceBlogInfo?.language || "English");
    setSelectedCompany(""); setSelectedCategory("");
    setContentTone(eCommerceBlogInfo?.content_tone || "");
    setGenerateType(eCommerceBlogInfo?.generate_type || "direct");
    setIsDisabledInput(eCommerceBlogInfo?.articleId ? true : false);
    setSelectedProduct(selectedProductsName || []);
  }, [eCommerceBlogInfo?.articleId])

  const historyFetchApi = () => {
    if (productList.length === 0) {
      dispatch(setLoader(true));
      invokeAxiosGet(`/e-commerce/get-or-generate/getProductCatalogProductList`).then((res) => {
        dispatch(setLoader(false));
        const productRes = res.data && res.data.data ? res.data.data : [];
        setOtherCategory(res.data.otherCategoryList);
        setDescError(false);
        setSelectedCurrency("S$");

        if (productRes && productRes.length > 0) {
          setAllProductList(productRes);
          if (userRes.username === "Shiseido_elixir") {
            handleSelectCategory("Custom", "All", productRes);
          }
          if (res.data.isCustom) {
            setSelectedCompany("Custom");
            let lastCurrencyLength = res.data.data.length - 1;
            let setLastRecordCurrencyCode = res.data.data[lastCurrencyLength].price.split(" ")[0];
            if (setLastRecordCurrencyCode !== "") {
              const getIndex = currencyForPrice.findIndex((item) => item.symbol === setLastRecordCurrencyCode);
              if (getIndex !== -1) {
                setSelectedCurrency(setLastRecordCurrencyCode);
              }
            }
          }
        }

        if (res.data.isCustom) {
          setSelectedCompany("Custom");
        }

        const categoryRes = res.data && res.data.categoryList ? res.data.categoryList : [];
        if (categoryRes && categoryRes.length > 0) {
          setCategoryList(categoryRes);
          if (categoryRes.length >= 1) {
            setValue("productCategory", categoryRes[1]?.category || "");
          }
        } else {
          setValue("productCategory", "Other");
        }
      }).catch((e) => {
        dispatch(setLoader(false));
      });
    }
  };

  const { register, handleSubmit, formState: { errors }, control, reset, watch, setValue, clearErrors } = useForm({
    defaultValues: { productTitle: "", productUrl: "", productImageUrl: "", productInfo: "", productDesc: "", productPrice: "", productCategory: "", otherCategory: "" },
    resolver: yupResolver(validationSchema),
  });

  const handleSelectCategory = (company, category, data) => {
    let filteredProducts = [];
    if (company === "Custom") {
      setSelectedCompany(company);
      setSelectedCategory(category);
      filteredProducts = data.filter(element => {
        return element.company === company && (category === "All" || element.category === category) && element.user_id === userRes.id;
      });
    } else {
      setSelectedCompany(company);
      setSelectedCategory(category);
      filteredProducts = data.filter(element => {
        return element.company === company && element.category === category;
      });
    }

    setProductList(filteredProducts);
  };

  const handleSelectOrDeselect = (product, isCheck) => {
    if (selectedProduct.length >= 3 && isCheck) {
      toast.error("Select at least 1 and at most 3 Products.");
      return false;
    }

    if (selectedProduct && selectedProduct.includes(product.name)) {
      const newArray = selectedProduct.filter((item1) => item1 !== product.name);
      setSelectedProduct([...newArray]);
    } else {
      setSelectedProduct([...selectedProduct, product.name]);
    }
  };

  const handleAddProductByUrl = async () => {
    const validUrl = new URL(productUrlModel.url);
    if (validUrl) {
      try {
        dispatch(setLoader(true));
        const payload = {
          customProductUrl: productUrlModel.url.trim(),
          userId: userRes.id,
        };

        const response = await invokeAxiosPost("/e-commerce/get-or-generate/createCustomProductByUserId", payload);
        dispatch(setLoader(false));
        if (response && response.success === 1) {
          setSelectedCompany("");
          setSelectedCategory("");
          setProductBtn(false);
          setProductUrlModel(false);
          historyFetchApi();
          toast.success("Product added successfully!", { id: "Toast-01" });
        } else {
          toast.error("Invalid URL please try with different one.", { id: "Toast-01" });
        }
      } catch (error) {
        dispatch(setLoader(false));
        toast.error(error?.response?.data?.error?.message || error?.response?.data?.error || "Server is overloaded, try again after sometime.", { id: "Toast-01" });
      }
    } else {
      toast.error("Invalid URL please try with different one.", { id: "Toast-01" });
    }
  }

  const handleCreateNewProduct = (value) => {
    const { productTitle, productUrl, productImageUrl, productInfo, productDesc } = value;
    const productCategory = value.otherCategory ? value.otherCategory : value.productCategory;
    const productPrice = `${selectedCurrency || "S$"} ${value.productPrice}`;

    try {
      const parser = new DOMParser();
      const bodyText = parser.parseFromString(productDesc, 'text/html').body.innerText;
      if (bodyText !== '') {
        setDescError(false);
        if (!descError) {
          if (value) {
            dispatch(setLoader(true));
            invokeAxiosPost(`/e-commerce/get-or-generate/createCustomProductByUserId`, {
              productTitle, productUrl, productImageUrl, productInfo, productDesc,
              productPrice, productCategory, userId: userRes.id,
            }).then((res) => {
              toast.success("Product add successfully!");
              dispatch(setLoader(false)); reset({ productTitle: "", productUrl: "", productImageUrl: "", productInfo: "", productPrice: "" })
              historyFetchApi();
            });
          }
        }
      } else {
        setDescError(true);
      }
    } catch (error) {
      dispatch(setLoader(false));
      toast.error('Product not added! Please try again.');
    }
  };

  const handleChange = (value) => {
    const parser = new DOMParser();
    const bodyText = parser.parseFromString(value, "text/html").body.innerText;
    if (bodyText === "" && !loader) {
      setDescError(true);
    } else {
      setDescError(false);
    }
  };

  const handleGenerateTopicsAndSummary = async () => {
    if (eCommerceBlogInfo.stepDone > 1) {
      return dispatch(setECommerceBlogStep(2))
    }

    if (Number(userRes.credits) < (userRes?.deductCredits?.["E-Commerce Blog"] || 3)) {
      toast.error("Sorry, you don't have enough credits to perform this action.", { id: "Toast-01" });
      return false;
    }

    const selectedFinalProducts = [];
    for (let index = 0; index < selectedProduct.length; index++) {
      const element = selectedProduct[index];
      const product = allProductList.find((item) => item.name === element);
      selectedFinalProducts.push(product);
    }

    try {
      dispatch(setLoader(true));
      const payload = {
        selectedProduct: selectedFinalProducts,
        geoLocation: geoLocation?.toLowerCase() || "sg",
        promotionText: promotionText || "",
        aiType: aiType ? aiType : "Claude 3.5 Sonnet", language,
        user_id: userRes.id, content_tone: contentTone || "",
        generateType: generateType
      };

      const response = await invokeAxiosPost("/e-commerce/get-or-generate/generateProductCatalogTopicSets", payload);
      dispatch(setLoader(false));
      if (response && response.success === 1 && response.data && response.data.allTopics && response.data.allTopics.length > 0) {
        setSelectedProduct([]); dispatch(setECommerceBlogStep(2));
        dispatch(setECommerceBlogInfo({ ...eCommerceBlogInfo, ...response.data, stepDone: 2 }));
        dispatch(setUserRes({ ...userRes, credits: userRes.credits - (userRes?.deductCredits?.["E-Commerce Blog"] || 3) }));
        navigate(`/e-commerce-blog/${response.data?.articleId}`);
        const scrollWrapper = document.getElementById("scroll-wrapper");
        if (scrollWrapper) { scrollWrapper.scrollTo({ top: 0, behavior: "smooth" }); }
      } else {
        toast.error("Topics not generated, Please try again.", { id: "Toast-01" });
      }
    } catch (error) {
      dispatch(setLoader(false));
      toast.error(error?.response?.data?.error?.message || error?.response?.data?.error || "Server is overloaded, try again after sometime.", { id: "Toast-01" });
    }
  };

  return (
    <div className="addllyFormWrap maingenrateBlock p-0 mx-3">
      <div className="selectBlock seoselected d-flex flex-wrap align-items-center justify-content-between">
        <div>
          <b>Select Products</b> <span>(Select any 1)</span>
        </div>
        <button
          type="button" disabled={selectedProduct && selectedProduct.length > 0 ? false : true}
          onClick={() => handleGenerateTopicsAndSummary()} className="d-flex align-items-center gap-2 bg-transparent border-0 selectBbutton"
        >
          Start <BsArrowRight />
        </button>
      </div>
      <Row className={`eCommerce-blog-wrapper mt-3 ${productBtn && 'pb-5'}`}>
        <Col sm="12" lg="3" className="side-menu p-0">
          <div className="sideBarInner">
            {userRes.username !== "Shiseido_elixir" && (
              <div className="">
                <button
                  className={`w-100 py-2 addNewProduct d-flex align-items-center justify-content-center gap-2 mb-3`} disabled={isDisabledInput}
                  style={{ ...isDisabledInput && ({ backgroundColor: "#bfc1c6" }), backgroundColor: "#E6EBFF", color: 'blue' }}
                  onClick={() => { setProductBtn(true); setSelectedCompany(""); setSelectedCategory(""); setProductList([]); setProductUrlModel(false); }}
                >
                  Add own product <LuShoppingCart size={22} />
                </button>
                <button
                  className={`w-100 py-2 addNewProduct d-flex align-items-center justify-content-center gap-2 mb-4`} disabled={isDisabledInput}
                  style={{ ...isDisabledInput && ({ backgroundColor: "#bfc1c6" }), backgroundColor: "#E6EBFF", color: 'blue' }}
                  onClick={() => {
                    setProductUrlModel({ open: true, url: null }); setProductBtn(false); setSelectedCompany("");
                    setSelectedCategory(""); setProductList([]);
                  }}
                >
                  Add product using URL <IoMdLink size={22} />
                </button>
              </div>
            )}
            {selectedCompany && (selectedCompany !== "" && selectedCompany !== 'Custom') ? (
              <React.Fragment>
                <div className="d-flex align-items-center justify-content-between companyBackbutton backButton">
                  <label >{selectedCompany} Category's</label>
                  <button className="btn text-primary p-0" onClick={() => { setSelectedCompany(""); setSelectedCategory(""); setProductList([]); }}>
                    <GoArrowLeft />
                  </button>
                </div>
                <ListGroup className="mb-3">
                  {otherCategory && otherCategory.length > 0 && otherCategory.find((category) => category.company === selectedCompany)?.categories?.map((category, index) => (
                    <ListGroup.Item
                      disabled={isDisabledInput} action active={selectedCategory === category} key={index}
                      onClick={() => { handleSelectCategory(selectedCompany, category, allProductList); setProductBtn(false); setProductUrlModel(false); }}
                    >
                      {category}
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              </React.Fragment>
            ) : otherCategory && otherCategory.length > 0 ? (
              <React.Fragment>
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "top" }}>
                  <label>Select Type</label>
                  <button className="btn text-primary p-0" onClick={() => setShowSelectList((pre) => ([!pre[0], pre[1]]))}>
                    {showSelectList[0] ? <IoIosArrowUp /> : <IoIosArrowDown />}
                  </button>
                </div>

                {showSelectList[0] && (
                  <ListGroup className="mb-3">
                    {otherCategory && otherCategory.length > 0 && otherCategory.map((category, index) => (
                      <ListGroup.Item
                        active={selectedCompany === category.company} disabled={isDisabledInput} action key={index}
                        onClick={() => { handleSelectCategory(category.company, category.categories[0], allProductList); setProductBtn(false); setProductUrlModel(false); }}
                      >
                        {category.company}
                      </ListGroup.Item>
                    ))}
                  </ListGroup>
                )}
              </React.Fragment>
            ) : null}

            {categoryList && categoryList.length > 0 && (
              <React.Fragment>
                {otherCategory.length !== 0 ? (
                  <React.Fragment>
                    <div className="d-flex align-items-center justify-content-between">
                      <label>Own Category's</label>
                      <button className="btn text-primary p-0" onClick={() => setShowSelectList((pre) => ([pre[0], !pre[1]]))}>
                        {showSelectList[1] ? <IoIosArrowUp /> : <IoIosArrowDown />}
                      </button>
                    </div>
                    {showSelectList[1] && (
                      <ListGroup>
                        {categoryList && categoryList.length > 0 && categoryList.map((categoryItem, index) => (
                          <ListGroup.Item
                            active={selectedCategory === categoryItem.category} key={index} disabled={isDisabledInput}
                            action onClick={() => { handleSelectCategory(categoryItem.company, categoryItem.category, allProductList); setProductBtn(false); setProductUrlModel(false); }}
                          >
                            {categoryItem.category}
                          </ListGroup.Item>
                        ))}
                      </ListGroup>
                    )}
                  </React.Fragment>
                ) : (
                  <ListGroup className="mt-3">
                    {categoryList && categoryList.length > 0 && categoryList.map((categoryItem, index) => (
                      <ListGroup.Item
                        active={selectedCategory === categoryItem.category} key={index} disabled={isDisabledInput}
                        action onClick={() => { handleSelectCategory(categoryItem.company, categoryItem.category, allProductList); setProductBtn(false); setProductUrlModel(false); }}
                      >
                        {categoryItem.category}
                      </ListGroup.Item>
                    ))}
                  </ListGroup>
                )}
              </React.Fragment>
            )}

            {selectedProduct && selectedProduct.length > 0 && (
              <div className=" selected-products mt-3">
                <div className="d-flex justify-content-between align-content-center mb-3">
                  <label className="m-0">Selected Product</label>
                  <button className="btn  p-0 " onClick={() => setSelectedProduct([])} disabled={isDisabledInput}>
                    Clear All
                  </button>
                </div>
                <ol>
                  {selectedProduct.map((product, index) => (
                    <li className="mb-1" key={index}>{product}</li>
                  ))}
                </ol>
              </div>
            )}
          </div>
        </Col>
        {productBtn ? (
          <Col sm="12" md="9" className="genrateFields EcomAddProd">
            <form onSubmit={handleSubmit(handleCreateNewProduct)}>
              <Row className="ps-1 fields" style={{ marginBottom: 0 }}>
                <Col sm="12" md="6" className="p-0 px-md-2 ">
                  <div className="form-group mb-3">
                    <label>Product Title</label> <span className="required-astrick text-danger mx-1">*</span>
                    <input
                      name="productTitle" placeholder="Enter your product title" type="text" {...register("productTitle")}
                      className={`addllyForm-control ${errors.productTitle ? "is-invalid" : ""}`}
                    />
                    <div className="invalid-feedback">{errors.productTitle?.message}</div>
                  </div>
                </Col>
                <Col sm="12" md="6" className="p-0 px-md-2 ">
                  <div className="form-group mb-3">
                    <div className="d-flex align-items-center justify-content-between">
                      <label> {watch("productCategory") === "Other" ? "Other Category" : "Choose your Category"}{" "} <span className="required-astrick text-danger mx-1 m-0">*</span> </label>
                      {watch("productCategory") === "Other" && (
                        <button
                          type="button" className="btn text-primary p-0"
                          onClick={() => { setValue("productCategory", categoryList.length >= 1 ? categoryList[1]?.category : ""); setValue("otherCategory", ""); }}
                        >
                          <GoArrowLeft />
                        </button>
                      )}
                    </div>
                    {watch("productCategory") === "Other" ? (
                      <React.Fragment>
                        <input
                          name="otherCategory" placeholder="Enter your custom category" type="text" {...register("otherCategory")}
                          className={`addllyForm-control ${errors.otherCategory ? "is-invalid" : ""}`}
                        />
                        <div className="invalid-feedback">{errors?.otherCategory?.message}</div>
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <Controller
                          name="productCategory" control={control}
                          defaultValue={categoryList[1] && categoryList[1].category ? categoryList[1]?.category : "Other"} rules={{ required: "Please select a category" }}
                          render={({ field }) => (
                            <Select
                              isSearchable={false} className={`custom-select ${errors.productCategory ? "is-invalid" : ""}`}
                              classNamePrefix={`custom-select`} placeholder="Select category"
                              options={[
                                ...categoryList.filter((item) => item.category !== "All").map((item) => ({
                                  value: item.category,
                                  label: item.category,
                                })),
                                { value: "Other", label: "Add Custom Category" },
                              ]}
                              isClearable={false} menuPosition="fixed"
                              value={{ value: field.value, label: field.value }}
                              onChange={(selectedOption) => { field.onChange(selectedOption.value); }}
                            />
                          )}
                        />
                        <div className="invalid-feedback">{errors?.productCategory?.message}</div>
                      </React.Fragment>
                    )}
                  </div>
                </Col>
                <Col sm="12" md="6" lg="4" className="p-0 px-md-2 ">
                  <div className="form-group mb-3">
                    <label>Product Link</label> <span className="required-astrick text-danger mx-1">*</span>
                    <input
                      name="productUrl" placeholder="Enter your product URL" type="text" {...register("productUrl")}
                      className={`addllyForm-control  ${errors.productUrl ? "is-invalid" : ""}`}
                    />
                    <div className="invalid-feedback">{errors.productUrl?.message}</div>
                  </div>
                </Col>
                <Col sm="12" md="6" lg="4" className="p-0 px-md-2">
                  <div className="form-group mb-3">
                    <label>Product Image Link</label> <span className="required-astrick text-danger mx-1">*</span>
                    <input
                      name="productImageUrl" placeholder="Enter your product image URL" type="text" {...register("productImageUrl")}
                      className={`addllyForm-control ${errors.productImageUrl ? "is-invalid" : ""}`}
                    />
                    <div className="invalid-feedback">{errors.productImageUrl?.message}</div>
                  </div>
                </Col>
                <Col sm="12" md="6" lg="4" className="p-0 px-md-2">
                  <div className="form-group mb-3">
                    <label>Product Price</label> <span className="required-astrick text-danger mx-1">*</span>
                    <InputGroup>
                      <div className="d-flex w-100">
                        <DropdownButton variant="outline-secondary" title={selectedCurrency ? `${selectedCurrency}` : "S$"} id="input-group-dropdown-1">
                          <div style={{ height: "200px", overflow: "auto" }} >
                            {currencyForPrice.map((item) => (
                              <Dropdown.Item key={item.code} active={item.symbol === selectedCurrency} onClick={() => setSelectedCurrency(item.symbol)}>
                                {item.symbol} - {item.name}
                              </Dropdown.Item>
                            ))}
                          </div>
                        </DropdownButton>
                        <input
                          name="productPrice" placeholder="Enter product price" type="text" {...register("productPrice")}
                          className={`p-10 addllyForm-control ${errors.productPrice ? "is-invalid" : ""}`}
                          style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                        />
                      </div>
                    </InputGroup>
                    <div className="invalid">{errors.productPrice?.message}</div>
                  </div>
                </Col>
                <Col sm="12" md="12" className="p-0 px-md-2">
                  <div className="form-group mb-3">
                    <label>Product Information</label> <span className="required-astrick text-danger mx-1">*</span>
                    <textarea
                      name="productInfo" placeholder="Enter your product information" type="text" {...register("productInfo")}
                      className={`addllyForm-control h-auto ${errors.productInfo ? "is-invalid" : ""}`}
                    />
                    <div className="invalid-feedback">{errors.productInfo?.message}</div>
                  </div>
                </Col>
                <Col sm="12" md="12" className="mb-0 p-0 px-md-2">
                  <div className="position-relative form-group">
                    <div className="form-group mb-3">
                      <label>Product Description</label> <span className="required-astrick text-danger mx-1">*</span>
                      <Controller
                        name="productDesc" {...register("productDesc")} control={control}
                        render={({ field }) => (
                          <div>
                            <ReactQuill
                              {...field} theme="snow" onChange={(value) => { field.onChange(value); handleChange(value); }}
                              onBlur={(value) => field.onBlur(value)} className={` h-auto ${errors.productDesc ? "is-invalid" : ""}`}
                            />
                            {descError ? (
                              <div className="text-italic text-danger">Product Description is required</div>
                            ) : (<div className="invalid-feedback">{errors.productDesc?.message}</div>)}
                          </div>
                        )}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
              <div className="d-flex align-items-start justify-content-center align-items-center flex-row gap-2 pt-0">
                <div className="d-flex align-items-center gap-3">
                  <button type="button" onClick={() => setProductBtn(false)} className="addlly-outline px-3">
                    <span> {" "} <BsArrowLeft /> Previous </span>
                  </button>
                  {productBtn && (
                    <input
                      type="submit" disabled={Object.keys(errors).length !== 0 || descError}
                      className="addlly-primary" style={{ whiteSpace: "nowrap" }} value="Add own product"
                    />
                  )}
                </div>
              </div>
            </form>
          </Col>
        ) : productUrlModel && productUrlModel.open ? (
          <Col sm="12" md="9" className="genrateFields EcomAddProd">
            <Row className="ps-1 fields" style={{ marginBottom: 0 }}>
              <Col sm="12" className="p-0 px-md-2 ">
                <div className="form-group mb-3">
                  <label>Product Url</label> <span className="required-astrick text-danger mx-1">*</span>
                  <input
                    name="productUrl" placeholder="Enter your product url" type="text"
                    className={`addllyForm-control`} value={productUrlModel.url}
                    onChange={(e) => setProductUrlModel({ open: true, url: e.target.value })}
                  />
                </div>
              </Col>
            </Row>
            <div className="d-flex align-items-center justify-content-center gap-3 mt-3">
              <button type="button" onClick={() => setProductUrlModel(false)} className="addlly-outline px-3">
                <span> <BsArrowLeft /> Previous </span>
              </button>
              <button type="button" className="addlly-primary" onClick={() => handleAddProductByUrl()} style={{ whiteSpace: "nowrap" }}>
                Add product
              </button>
            </div>
          </Col>
        ) : (
          <Col sm="12" lg="9" className="genrateFields">
            <Row className="ps-4">
              {userRes.username === "Shiseido_elixir" ? (
                <React.Fragment>
                  <div className="d-flex align-items-center justify-content-between">
                    <label><b>Article Specifications</b></label>
                    <button className="btn text-primary p-0" onClick={() => setShowArticleSpecs((pre) => (!pre))}>
                      {showArticleSpecs ? <IoIosArrowUp /> : <IoIosArrowDown />}
                    </button>
                  </div>
                  {showArticleSpecs && (
                    <React.Fragment>
                      <Col sm="12" md="12" className="addllyFormWrap mb-2 p-0 px-md-2">
                        <div className="form-group fields">
                          <label>Theme ( Describe your promotion text )</label>
                          <textarea
                            type="text" className="addllyForm-control addedOutline w-100 h-auto"
                            placeholder="Insert your theme text here" rows={2} disabled={isDisabledInput}
                            value={promotionText} onChange={(e) => setPromotionTextText(e.target.value)}
                          />
                        </div>
                      </Col>
                      <Col sm="12" md="6" className="mb-4  p-0 px-md-2">
                        <div className="position-relative languageDrop fields">
                          <label>Select your geo-location</label>
                          <ReactFlagsSelect
                            className={"reactFlag p-0 w-100"} searchable={true} selected={geoLocation}
                            onSelect={(val) => setGeoLocation(val)} disabled={isDisabledInput}
                          />
                          <div className="arrowIcon">
                            <IoIosArrowDown className="downArrowIcon" />
                          </div>
                        </div>
                      </Col>
                      <Col sm="12" md="6" className="mb-3 p-0 px-md-2">
                        <div className="fields">
                          <label>Choose your AI Model</label>
                          <Select isSearchable={false}
                            className={`custom-select`} classNamePrefix="custom-select" placeholder="Select AI Type"
                            options={AiTypeOptions} isClearable={false} defaultValue={AiTypeOptions[0]}
                            value={AiTypeOptions.find((c) => c.value === aiType)}
                            onChange={(e) => setAiType(e.value)} menuPosition="fixed" isDisabled={isDisabledInput}
                          />
                        </div>
                      </Col>
                      <Col sm="12" md="6" className="mb-3  p-0 px-md-2">
                        <div className="fields">
                          <label>Select Content Tone</label>
                          <Select
                            className={`custom-select`} classNamePrefix="custom-select" placeholder="Select Content Tone"
                            options={ContentTonesOptions} isClearable={false} isSearchable={false}
                            value={ContentTonesOptions.find((c) => c.value === contentTone) || ""}
                            onChange={(e) => setContentTone(e.value)} menuPosition="fixed" isDisabled={isDisabledInput}
                          />
                        </div>
                      </Col>
                      <Col sm="12" md="6" className="mb-3  p-0 px-md-2">
                        <div className="fields form-group">
                          <label>Choose a Language</label>
                          <Select
                            className={`custom-select`} classNamePrefix="custom-select" placeholder="Select Language"
                            options={supportedLanguageList} isClearable={false} isSearchable={false}
                            getOptionLabel={handleCustomSelectOption} menuPosition="fixed" isDisabled={isDisabledInput}
                            value={supportedLanguageList.find(c => c.value === language) || ""}
                            onChange={(event) => {
                              setLanguage(event.value);
                              handleSetLanguageAndGeoLocation(event, () => { }, setGeoLocation);
                            }}
                          />
                        </div>
                      </Col>
                    </React.Fragment>
                  )}
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <Col sm="12" md="12" className="addllyFormWrap mb-2 p-0 px-md-2">
                    <div className="form-group fields">
                      <label>Theme ( Describe your promotion text )</label>
                      <textarea
                        type="text" className="addllyForm-control addedOutline w-100 h-auto"
                        placeholder="Insert your theme text here" rows={2} disabled={isDisabledInput}
                        value={promotionText} onChange={(e) => setPromotionTextText(e.target.value)}
                      />
                    </div>
                  </Col>
                  <Col sm="12" md="6" className="mb-4  p-0 px-md-2">
                    <div className="position-relative languageDrop fields">
                      <label>Select your geo-location</label>
                      <ReactFlagsSelect
                        className={"reactFlag p-0 w-100"} searchable={true} selected={geoLocation}
                        onSelect={(val) => setGeoLocation(val)} disabled={isDisabledInput}
                      />
                      <div className="arrowIcon">
                        <IoIosArrowDown className="downArrowIcon" />
                      </div>
                    </div>
                  </Col>
                  <Col sm="12" md="6" className="mb-3 p-0 px-md-2">
                    <div className="fields">
                      <label>Choose your AI Model</label>
                      <Select isSearchable={false}
                        className={`custom-select`} classNamePrefix="custom-select" placeholder="Select AI Type"
                        options={AiTypeOptions} isClearable={false} isDisabled={isDisabledInput}
                        value={AiTypeOptions.find((c) => c.value === aiType)}
                        onChange={(e) => setAiType(e.value)} menuPosition="fixed"
                      />
                    </div>
                  </Col>
                  <Col sm="12" md="6" className="mb-3  p-0 px-md-2">
                    <div className="fields">
                      <label>Select Content Tone</label>
                      <Select
                        className={`custom-select`} classNamePrefix="custom-select" placeholder="Select Content Tone"
                        options={ContentTonesOptions} isClearable={false} isSearchable={false}
                        value={ContentTonesOptions.find((c) => c.value === contentTone) || ""}
                        onChange={(e) => setContentTone(e.value)} menuPosition="fixed" isDisabled={isDisabledInput}
                      />
                    </div>
                  </Col>
                  <Col sm="12" md="6" className="mb-3  p-0 px-md-2">
                    <div className="fields form-group">
                      <label>Choose a Language</label>
                      <Select
                        className={`custom-select`} classNamePrefix="custom-select" placeholder="Select Language"
                        options={supportedLanguageList} isClearable={false} isSearchable={false}
                        getOptionLabel={handleCustomSelectOption} menuPosition="fixed" isDisabled={isDisabledInput}
                        value={supportedLanguageList.find(c => c.value === language) || ""}
                        onChange={(event) => {
                          setLanguage(event.value);
                          handleSetLanguageAndGeoLocation(event, () => { }, setGeoLocation);
                        }}
                      />
                    </div>
                  </Col>
                </React.Fragment>
              )}
              {userRes.username !== "Shiseido_elixir" && (
                <React.Fragment>
                  <Col sm="12" md="6" className="mb-3 p-0 px-md-2">
                    <div className="fields">
                      <label>Select Generate Type</label>
                      <Select
                        className={`custom-select`} classNamePrefix="custom-select" placeholder="Select Generate Type"
                        options={generateTypeOptions} isClearable={false} isSearchable={false}
                        value={generateTypeOptions.find((c) => c.value === generateType) || ""}
                        onChange={(e) => setGenerateType(e.value)} menuPosition="fixed" isDisabled={isDisabledInput}
                      />
                    </div>
                  </Col>
                  <Col sm="12" md="6" className="mb-3 p-0 px-md-2"></Col>
                </React.Fragment>
              )}
              {productList && productList.length > 0 && (
                <Col sm="12" className="mb-3  p-0 px-md-2">
                  <h4 className="mb-0">Select product to generate article ( Max 5 Products ) </h4>
                </Col>
              )}
              {productList && productList.length > 0 ? productList.map((product, index) => (
                <Col sm="6" md="6" lg="4" xxl="4" className="pb-3" key={index}>
                  <div className={`image-wrapper shadow-sm border border-1 ${selectedProduct && selectedProduct.includes(product.name) ? "active" : ""}`} >
                    <div className="w-100" style={{ background: 'white' }}>
                      <img src={product.image} alt={product.name} />
                    </div>
                    <div className="card-product-info">
                      <p>{product.name}</p>
                    </div>
                    <div className="card-button">
                      <div className="sub-div gap-2">
                        <button
                          type="button" className="btn bg-white text-primary"
                          onClick={() => setViewProduct({ open: true, data: product })}
                          data-tooltip-id={"my-tooltip"} data-tooltip-place="bottom" data-tooltip-content={`View Details`}
                        >
                          <BsEyeFill className="fs-5" />
                        </button>

                        <button
                          type="button" className={`btn bg-white text-primary ${selectedProduct && selectedProduct.includes(product.name) && "btn-primary"}`}
                          onClick={() => handleSelectOrDeselect(product, selectedProduct.includes(product.name) ? false : true)}
                          data-tooltip-id={"my-tooltip"} data-tooltip-place="bottom" data-tooltip-content={selectedProduct.includes(product.name) ? "Unselect Product" : "Select Product"}
                        >
                          {selectedProduct && selectedProduct.includes(product.name) ? (
                            <MdOutlineClose className="fs-5" />
                          ) : (
                            <FiCheck className="fs-5" />
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                </Col>
              )) : null}
              {eCommerceBlogInfo?.selectedProducts && eCommerceBlogInfo.selectedProducts.length > 0 ? eCommerceBlogInfo.selectedProducts.map((product, index) => (
                <Col sm="6" md="6" lg="4" xxl="4" className="pb-3" key={index}>
                  <div className={`image-wrapper ${selectedProduct && selectedProduct.includes(product.name) ? "active" : ""}`}>
                    <img src={product.image} alt={product.name} />
                    <div className="card-product-info">
                      <p>{product.name}</p>
                    </div>
                    <div className="card-button">
                      <div className="sub-div gap-2">
                        <button
                          type="button" className="btn bg-white text-primary" onClick={() => setViewProduct({ open: true, data: product })}
                          data-tooltip-id={"my-tooltip"} data-tooltip-place="bottom" data-tooltip-content={`View Details`}
                        >
                          <BsEyeFill className='fs-5' />
                        </button>
                      </div>
                    </div>
                  </div>
                </Col>
              )) : null}
            </Row>
          </Col>
        )}
      </Row>

      {!productBtn && !productUrlModel.open && (
        <div className="d-flex align-items-start justify-content-center  align-items-center flex-row gap-2 bottomButton">
          <div className='d-flex align-items-center gap-2'>
            <button
              disabled={selectedProduct && selectedProduct.length > 0 ? false : true} type="button"
              onClick={() => handleGenerateTopicsAndSummary()} className="addlly-primary" style={{ whiteSpace: "nowrap" }}
            >
              <span>Start <BsArrowRight /></span>
            </button>
            <BsInfoCircleFill
              className="text-decoration-none cursor-pointer outline-0 infoIconSvg" data-tooltip-id="my-tooltip" data-tooltip-place="right"
              data-tooltip-content={`Will Utilize ${userRes?.deductCredits?.["E-Commerce Blog"] || "3"} Addlly Credits`}
            />
          </div>
        </div>
      )}

      {viewProduct && viewProduct.open && (
        <ProductViewModal viewProduct={viewProduct} setViewProduct={setViewProduct} />
      )}
    </div>
  );
};

export default FirstStepECommerceBlog;
