import React, { useEffect, useMemo, useState, useRef } from "react";
import { useSelector } from "react-redux";
import { ListGroup, Spinner } from "react-bootstrap";
import classNames from "classnames";
import DatePicker from 'react-date-picker';
import { BsCalendarCheck, BsSearch } from "react-icons/bs";
import { IoCloseOutline } from "react-icons/io5";
import { RiInboxUnarchiveFill } from "react-icons/ri";
import { articleHistoryIcons } from "../../Assets/Icons";
import { getDate } from "../../utility/hepler";
import { AIModel, Duration, status } from "../../data";
import { Offcanvas } from "react-bootstrap";

const Filters = (props) => {
  const { starred, setStarred, textWrap, setTextWrap, setSearchFilter, setFilters, filters, selectedRows, handleArchiveAll, isFetching } = props;

  return (
    <div className="historyTopBar d-flex justify-content-between bg-white   align-items-center gap-x-3 flex-wrap ">
      <div className="d-flex justify-content-lg-between flex-wrap align-items-center searchBarLeft leftSearchBarH" >
        <div className="buttonTab overflow-hidden">
          <button disabled={isFetching || !starred} onClick={() => setStarred(false)} className={classNames({ active: !starred })}>
            {isFetching && !starred ? <Spinner size="sm" /> : 'All'}
          </button>
          <button
            disabled={isFetching || starred}
            onClick={() => setStarred(true)}
            className={classNames({ active: starred })}
          >
            {isFetching && starred ? <Spinner size="sm" /> : 'Starred'}
          </button>
        </div>
        <span className="line"></span>

        <div className="filterRedu rounded-pill overflow-hidden">
          <button
            className={classNames({ active: !textWrap })} onClick={() => setTextWrap(false)}
            data-tooltip-id="my-tooltip" data-tooltip-content="Unwrap Text" data-tooltip-place="bottom"
          >
            <img src={articleHistoryIcons.filterIcon} alt="filterIcon" />
          </button>

          <button
            className={classNames({ active: textWrap })} onClick={() => setTextWrap(true)}
            data-tooltip-id="my-tooltip" data-tooltip-content="Wrap Text" data-tooltip-place="bottom"
          >
            <img src={articleHistoryIcons.redoIcon} alt="redoIcon" />
          </button>
        </div>
        <div className="genrateNewFilter">
          <div className="searchField position-relative">
            <input type="search" placeholder="Type to search" onChange={(e) => setSearchFilter(e.target.value)} />
            <div className="searchIcon position-absolute">
              <BsSearch />
            </div>
          </div>
        </div>
        <span className="line"></span>
        <FilterComponent setFilters={setFilters} filters={filters} isFetching={isFetching} />
      </div>

      <div className="filterGenrate d-flex justify-content-between gap-3">
        <div className="buttonsBlock d-flex align-items-center justify-content-between gap-3">
          {selectedRows?.length ? (
            <button onClick={() => handleArchiveAll("archive")} className="d-flex justify-content-center align-items-center deleteBtn"
              data-tooltip-id="my-tooltip" data-tooltip-place="bottom" data-tooltip-content="Archive selections"
            >
              <RiInboxUnarchiveFill />
            </button>
          ) : null}
        </div>
      </div>
    </div>
  );
};

const FilterComponent = ({ setFilters, filters, isFetching }) => {
  const userRes = useSelector(({ userRes }) => userRes);
  const [isOpen, setIsOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const ref = useRef();

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1139);
    };

    handleResize();

    window.addEventListener('resize', handleResize);
    window.addEventListener('load', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('load', handleResize);
    };
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (isOpen && ref.current && !ref.current.contains(event.target)) {
        if (!isMobile) {
          setIsOpen(false);
        }
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);

  const handleFilters = (e, array = []) => {
    const { value, checked, name } = e.target;
    if (value === 'all') {
      if (checked) {
        const newObj = { ...filters };
        newObj[name] = array?.map((item) => (item?.value !== 'all' && item?.value))?.filter((item) => item !== 'Custom');
        newObj.isCustomDate = false;
        setFilters(newObj);
      } else {
        const newObject = { ...filters };
        newObject[name] = [];
        newObject.isCustomDate = false;
        setFilters(newObject);
      }
    } else {
      if (checked) {
        const newObj = { ...filters };
        const filteredArray = newObj[name].filter(item => item !== 'Custom');
        newObj[name] = filteredArray;
        newObj[name].push(value);
        newObj.isCustomDate = false;
        setFilters(newObj);
      } else {
        const newObject = { ...filters };
        const newData = newObject[name]?.filter((data) => data !== value);
        const data = { ...newObject, [name]: newData, isCustomDate: false, customDate: { startDate: '', endDate: '' } };
        setFilters(data);
      }
    }
  };

  const handleClearFilter = () => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      categories: [],
      durations: [],
      status: [],
      AIModels: [],
      users: [],
      customDate: { startDate: '', endDate: '' }, isCustomDate: false
    }));
    setDate({ startDate: '', endDate: '' })
    setCustomDate(false);
  };

  const filtersCount = useMemo(() => (Object.values(filters).reduce((value, currentValue) => (value += currentValue.length), 0)), [filters]);
  const [customDate, setCustomDate] = useState(false);
  const [date, setDate] = useState({ startDate: '', endDate: '' });

  const handleCustomDuration = (e, type) => {
    const { checked, name } = e.target;
    if (type === 'Custom' && checked) {
      setCustomDate(pre => !pre);
      const newObj = { ...filters };
      newObj[name] = Duration?.map((item) => (item?.value !== 'all' && item?.value))?.filter((item) => item === 'Custom');
      newObj.isCustomDate = false;
      setFilters(newObj);
    } else {
      setCustomDate(false);
      handleFilters(e, Duration)
      setDate({ startDate: '', endDate: '' })
    }
  }

  const handleCustomDurationSubmit = () => {
    if (!date.startDate || !date.endDate) return false;
    let formatStartDate;
    let formatEndDate;
    const year = date.startDate.getFullYear().toString();

    const month = String(date.startDate.getMonth() + 1).padStart(2, '0');
    const day = String(date.startDate.getDate()).padStart(2, '0');
    formatStartDate = `${year}-${month}-${day}`;

    const years = date.endDate.getFullYear().toString();
    const months = String(date.endDate.getMonth() + 1).padStart(2, '0');
    const days = String(date.endDate.getDate()).padStart(2, '0');
    formatEndDate = `${years}-${months}-${days}`;
    formatStartDate = `${year}-${month}-${day}`;

    if (year.length === 4 && years.length === 4) {
      const newObject = { ...filters };
      newObject['customDate'].startDate = formatStartDate;
      newObject['customDate'].endDate = formatEndDate;
      newObject.isCustomDate = true;
      setFilters(newObject);
    }
  }

  const handleDateChange = (name, dates) => {
    const isValidDate = !isNaN(new Date(dates).getTime());
    if (isValidDate) {
      if (name === 'startDate') {
        setDate(prev => ({ ...prev, startDate: dates }));
      } else if (name === 'endDate') {
        setDate(prev => ({ ...prev, endDate: dates }));
      }
    } else {
      return false;
    }
  }

  return (
    <div ref={ref}>
      <div className="position-relative">
        <button
          onClick={() => setIsOpen(!isOpen)}
          className="position-relative ps-2 d-flex align-items-center  gap-2 blogButton border-0"
          variant="warning"
        >
          <img src={articleHistoryIcons.filter} alt="filter" /> Filters
          {filtersCount ? (
            <span className="selectedFilterCount text-white">
              {filtersCount < 10 ? "0" + filtersCount : filtersCount}
            </span>
          ) : null}
        </button>
        {isOpen && isMobile ? (
          <Offcanvas placement="end" show={isOpen} onHide={() => { }}>
            <Offcanvas.Header className="justify-content-between align-items-center p-0 border-0">
              <div className="d-flex align-item-center  gap-3">
                <h4>Filters</h4>
              </div>
              <button type="button" className="btn-close text-reset p-0" onClick={() => setIsOpen(false)} ></button>
            </Offcanvas.Header>
            <Offcanvas.Body className="cardBody p-0">
              <div className="d-flex w-full justify-content-end align-items-center popUpHeader">
                <span onClick={handleClearFilter} className="fw-bold cursor-pointer text-primary px-3" style={{ fontSize: '12px' }}>
                  CLEAR ALL
                </span>
              </div>
              <div
                className={classNames("filterItemsBox d-flex flex-column gap-3 ", {
                  adminUser: userRes?.role === "Admin",
                })}
              >
                <div className="itemsFilter mb-3">
                  <h6 className="filterHeader my-1">Duration  <span className="blueButton  mx-1">{filters.durations.filter(item => item !== false).length} selected</span>{" "} </h6>
                  <ListGroup>
                    {Duration.map(({ name, value }, index) => (
                      <div key={index + " " + name} className="itemsListsPoints d-flex align-items-center gap-2 my-1">
                        <div xs="2">
                          <input
                            checked={value === "all"
                              ? filters.durations?.length === (Duration.length - 1)
                              : value === 'Custom'
                                ? customDate
                                : filters.durations.some((duration) => duration === value)}

                            onChange={(e) => handleCustomDuration(e, value)}
                            id={`durations${index}`}
                            value={getDate(value)}
                            type="checkbox"
                            className="cursor-pointer"
                            name="durations"
                          />
                        </div>
                        <div>
                          <label htmlFor={`durations${index}`} className="text-black">{name}
                            {value === 'all' && !filters.durations?.length ? <span className="default-filter">{` (Default)`}</span> : null}</label>
                        </div>
                      </div>
                    ))}
                    {customDate ?
                      <div>
                        <div>
                          {new Date(date.startDate) >= new Date(date.endDate) ? <span className="invalid">You can't set end date less then or equals to start date</span> : null}
                        </div>
                        <div className="duration-date-filter">
                          <div className="start-date fields">
                            {date.startDate ? (
                              <DatePicker onChange={(e) => handleDateChange("startDate", e)}
                                id="startDate" value={date.startDate}
                                clearIcon={null} className="w-100 text-muted"
                                calendarIcon={<BsCalendarCheck className="text-muted" />}
                                showLeadingZeros={true} dayPlaceholder="dd"
                                monthPlaceholder="mm" yearPlaceholder="yyyy"
                              />
                            ) : (
                              <div className="d-flex align-items-center justify-space-between"
                                onClick={() => setDate(prev => ({ ...prev, startDate: new Date() }))}
                              >
                                <input className="ps-0 w-90 text-muted" type="text" value="Start Date" readOnly />
                                <BsCalendarCheck className="text-muted" style={{ width: '32px' }} />
                              </div>
                            )}
                          </div>
                          <div className="end-date fields">
                            {date.endDate ? (
                              <DatePicker onChange={(e) => handleDateChange("endDate", e)}
                                id="endDate" value={date.endDate}
                                clearIcon={null} className="w-100 text-muted"
                                calendarIcon={<BsCalendarCheck className="text-muted" />}
                                showLeadingZeros={true} dayPlaceholder="dd"
                                monthPlaceholder="mm" yearPlaceholder="yyyy"
                              />
                            ) : (
                              <div className="d-flex align-items-center justify-space-between cursor-pointer"
                                onClick={() => setDate(prev => ({ ...prev, endDate: new Date() }))}
                              >
                                <input className="ps-0 w-90 text-muted" type="text" value="End Date" readOnly />
                                <BsCalendarCheck className="text-muted" style={{ width: '32px' }} />
                              </div>
                            )}
                          </div>
                        </div>
                        {isMobile ? (
                          <div className="submit-date-filter mt-2">
                            <div className="historyTopBar p-0">
                              <div className="searchBarLeft">
                                <button disabled={!date.startDate || !date.endDate || new Date(date.startDate) >= new Date(date.endDate)}
                                  onClick={handleCustomDurationSubmit} type="button" className="blogButton border-0 " >
                                  {isFetching ? <Spinner /> : 'Apply'}
                                </button>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div className="submit-date-filter mt-2">
                            <button disabled={!date.startDate || !date.endDate || new Date(date.startDate) >= new Date(date.endDate)}
                              onClick={handleCustomDurationSubmit} type="button" className="blogButton border-0 " >
                              {isFetching ? <Spinner /> : 'Apply'}
                            </button>
                          </div>
                        )}
                      </div>
                      : (null)
                    }
                  </ListGroup>
                </div>
                <div className="itemsFilter">
                  <h6 className="filterHeader mb-3">Status  <span className="blueButton  mx-1">{filters.status.filter(item => item != false).length} selected</span>{" "}</h6>
                  <ListGroup>
                    {status.map(({ name, value }, index) => (
                      <div key={index + " " + value} className="itemsListsPoints d-flex align-items-center gap-2 my-1">
                        <div xs="2">
                          <input
                            checked={value === "all" ? filters.status?.length === (status.length) : filters.status.some((status) => status === value)}
                            onChange={(e) => handleFilters(e, status)}
                            id={`status${index}`}
                            name="status"
                            value={value}
                            type="checkbox"
                            className="cursor-pointer"
                          />
                        </div>
                        <div>
                          <label htmlFor={`status${index}`} className="text-black">{name}
                            {value === 'all' && !filters.status?.length ? <span className="default-filter">{` (Default)`}</span> : null}
                          </label>
                        </div>
                      </div>
                    ))}
                  </ListGroup>
                </div>
                <div className="itemsFilter searchBlockArea pe-2">
                  <h6 className="filterHeader mb-3">AI Model  <span className="blueButton  mx-1">{filters.AIModels?.filter(item => item != false).length} selected</span>{" "} </h6>
                  <ListGroup className="m-0">
                    {AIModel.map(({ name, value }, index) => (
                      <div key={index + " " + value} className="itemsListsPoints d-flex align-items-center gap-2 my-1">
                        <div xs="2">
                          <input
                            checked={value === "all" ? filters.AIModels?.length === (AIModel.length) : filters.AIModels.some((AIModel) => AIModel === value)}
                            value={value}
                            onChange={(e) => handleFilters(e, AIModel)}
                            id={`AIModels${index}`}
                            name="AIModels"
                            type="checkbox"
                            className="cursor-pointer"
                          />
                        </div>
                        <div>
                          <label htmlFor={`AIModels${index}`} className="text-black">{name}
                            {value === 'all' && !filters.AIModels?.length ? <span className="default-filter">{` (Default)`}</span> : null}
                          </label>
                        </div>
                      </div>
                    ))}
                  </ListGroup>
                </div>
              </div>
            </Offcanvas.Body>
          </Offcanvas>
        ) : isOpen ? (
          <div className="filterPopUpH ">
            <div className="popUpMain">
              <div className="d-flex w-full justify-content-between align-items-center popUpHeader">
                <h5 className="filterHeader">Filter</h5>
                <span onClick={handleClearFilter} className="fw-bold cursor-pointer">
                  <IoCloseOutline />
                  CLEAR ALL
                </span>
              </div>
              <div
                className={classNames("filterItemsBox d-flex ", {
                  adminUser: userRes?.role === "Admin",
                })}
              >
                <div className="itemsFilter">
                  <h6 className="filterHeader">Duration  <span className="blueButton  mx-1">{filters.durations.filter(item => item !== false).length} selected</span>{" "} </h6>
                  <ListGroup>
                    {Duration.map(({ name, value }, index) => (
                      <div key={index + " " + name} className="itemsListsPoints d-flex align-items-center gap-2">
                        <div xs="2">
                          <input
                            checked={value === "all"
                              ? filters.durations?.length === (Duration.length - 1)
                              : value === 'Custom'
                                ? customDate
                                : filters.durations.some((duration) => duration === value)}

                            onChange={(e) => handleCustomDuration(e, value)}
                            id={`durations${index}`}
                            value={getDate(value)}
                            type="checkbox"
                            className="cursor-pointer"
                            name="durations"
                          />
                        </div>
                        <div>
                          <label htmlFor={`durations${index}`} className="text-black">{name}
                            {value === 'all' && !filters.durations?.length ? <span className="default-filter">{` (Default)`}</span> : null}</label>
                        </div>
                      </div>
                    ))}
                    {customDate ?
                      <div>
                        <div>
                          {new Date(date.startDate) >= new Date(date.endDate) ? <span className="invalid">You can't set end date less then or equals to start date</span> : null}
                        </div>
                        <div className="duration-date-filter">
                          <div className="start-date fields">
                            {date.startDate ? (
                              <DatePicker onChange={(e) => handleDateChange("startDate", e)}
                                id="startDate" value={date.startDate}
                                clearIcon={null} className="w-100 text-muted"
                                calendarIcon={<BsCalendarCheck className="text-muted" />}
                                showLeadingZeros={true} dayPlaceholder="dd"
                                monthPlaceholder="mm" yearPlaceholder="yyyy"
                              />
                            ) : (
                              <div className="d-flex align-items-center justify-space-between"
                                onClick={() => setDate(prev => ({ ...prev, startDate: new Date() }))}
                              >
                                <input className="ps-0 w-90 text-muted" type="text" value="Start Date" readOnly />
                                <BsCalendarCheck className="text-muted" style={{ width: '32px' }} />
                              </div>
                            )}
                          </div>
                          <div className="end-date fields">
                            {date.endDate ? (
                              <DatePicker onChange={(e) => handleDateChange("endDate", e)}
                                id="endDate" value={date.endDate}
                                clearIcon={null} className="w-100 text-muted"
                                calendarIcon={<BsCalendarCheck className="text-muted" />}
                                showLeadingZeros={true} dayPlaceholder="dd"
                                monthPlaceholder="mm" yearPlaceholder="yyyy"
                              />
                            ) : (
                              <div className="d-flex align-items-center justify-space-between cursor-pointer"
                                onClick={() => setDate(prev => ({ ...prev, endDate: new Date() }))}
                              >
                                <input className="ps-0 w-90 text-muted" type="text" value="End Date" readOnly />
                                <BsCalendarCheck className="text-muted" style={{ width: '32px' }} />
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="submit-date-filter mt-2">
                          <button disabled={!date.startDate || !date.endDate || new Date(date.startDate) >= new Date(date.endDate)}
                            onClick={handleCustomDurationSubmit} type="button" className="blogButton border-0 " >
                            {isFetching ? <Spinner /> : 'Apply'}
                          </button>
                        </div>
                      </div>
                      : (null)
                    }
                  </ListGroup>
                </div>
                <div className="itemsFilter">
                  <h6 className="filterHeader">Status  <span className="blueButton  mx-1">{filters.status.filter(item => item != false).length} selected</span>{" "}</h6>
                  <ListGroup>
                    {status.map(({ name, value }, index) => (
                      <div key={index + " " + value} className="itemsListsPoints d-flex align-items-center gap-2">
                        <div xs="2">
                          <input
                            checked={value === "all" ? filters.status?.length === (status.length) : filters.status.some((status) => status === value)}
                            onChange={(e) => handleFilters(e, status)}
                            id={`status${index}`}
                            name="status"
                            value={value}
                            type="checkbox"
                            className="cursor-pointer"
                          />
                        </div>
                        <div>
                          <label htmlFor={`status${index}`} className="text-black">{name}
                            {value === 'all' && !filters.status?.length ? <span className="default-filter">{` (Default)`}</span> : null}
                          </label>
                        </div>
                      </div>
                    ))}
                  </ListGroup>
                </div>
                <div className="itemsFilter searchBlockArea pe-2">
                  <h6 className="filterHeader">AI Model  <span className="blueButton  mx-1">{filters.AIModels.filter(item => item != false).length} selected</span>{" "} </h6>
                  <ListGroup className="m-0">
                    {AIModel.map(({ name, value }, index) => (
                      <div key={index + " " + value} className="itemsListsPoints d-flex align-items-center gap-2">
                        <div xs="2">
                          <input
                            checked={value === "all" ? filters.AIModels?.length === (AIModel.length) : filters.AIModels.some((AIModel) => AIModel === value)}
                            value={value}
                            onChange={(e) => handleFilters(e, AIModel)}
                            id={`AIModels${index}`}
                            name="AIModels"
                            type="checkbox"
                            className="cursor-pointer"
                          />
                        </div>
                        <div>
                          <label htmlFor={`AIModels${index}`} className="text-black">{name}
                            {value === 'all' && !filters.AIModels?.length ? <span className="default-filter">{` (Default)`}</span> : null}
                          </label>
                        </div>
                      </div>
                    ))}
                  </ListGroup>
                </div>
                {/* {userRes?.role && userRes?.role === "Admin" ? (
                  <div className="itemsFilter searchBlockArea pe-2">
                    <h6 className="mb-3">
                      Users<span className="blueButton mx-1">{filters.users.length} selected</span>{" "}
                    </h6>
                    <div className="searchField position-relative">
                      <input
                        onChange={(e) => setSearch(e.target.value)}
                        type="search"
                        placeholder="Search by title or content"
                        className="bg-light"
                      />
                      <div className="searchIcon position-absolute">
                        <BsSearch />
                      </div>
                    </div>
                    <ListGroup className="border mt-2 p-3">
                      {users
                        .filter((user) => user.name.toLowerCase().includes(search.toLowerCase()))
                        .map(({ name, email }, index) => (
                          <div key={index + " " + name} className="itemsListsPoints d-flex align-items-start gap-2">
                            <div xs="2">
                              <input
                                checked={filters.users.some((user) => user === name)}
                                onChange={handleFilters}
                                id="users"
                                value={name}
                                type="checkbox"
                                className="cursor-pointer"
                              />
                            </div>
                            <div className="lh-1 mb-3">
                              <p className="text-black">{name}</p>
                              <span>{email}</span>
                            </div>
                          </div>
                        ))}
                    </ListGroup>
                  </div>
                ) : null} */}
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default Filters;
