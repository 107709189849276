import React, { useEffect, useState } from "react";
import { ReactSVG } from "react-svg";
import { IoIosWifi } from "react-icons/io";
import { FaSignal } from "react-icons/fa6";
import { RxAvatar } from "react-icons/rx";
import { IoBatteryFull } from "react-icons/io5";
import { BsStars, BsUpload } from "react-icons/bs";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import { oneClickBlogIcon } from "../../Assets/Icons";
import GIFLoader from "../../Assets/Gif/8080laoder.gif";
import classNames from "classnames";

const ImageLoader = () => {
  return (
    <div className="custom-post-loader">
      <img src={GIFLoader} alt="loading..." />
    </div>
  );
};

const TwitterPostPreview = (props) => {
  const { setUploadFileModal, userRes, text, isUploadImage = false } = props;
  const [previewImg, setPreviewImg] = useState("");
  const [carouselImages, setCarouselImages] = useState([]);
  const [carouselIndex, setCarouselIndex] = useState(0);
  const [isShowFullPost, setIsShowFullPost] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");

  useEffect(() => {
    if (!props.preview_img) {
      setLoaded(true);
    } else if (props.preview_img && props.preview_img !== "") {
      try {
        const imageData = JSON.parse(props.preview_img);
        if (imageData.type === "url") {
          setPreviewImg(imageData.data);
        } else if (imageData.type === "carousel") {
          setPreviewImg(imageData.data[0]);
          setCarouselImages(imageData.data);
        }
      } catch (error) {
        setPreviewImg(props.preview_img);
      }
    }
  }, [props.preview_img]);

  useState(() => {
    const formatDate = (date) => {
      const year = date.getFullYear();
      const month = date.toLocaleString("en-US", { month: "long" });
      const day = date.getDate();
      const hours = date.getHours();
      const minutes = date.getMinutes();
      const ampm = hours >= 12 ? "PM" : "AM";
      const formattedHours = hours % 12 || 12;
      const formattedMinutes = minutes < 10 ? "0" + minutes : minutes;
      const formattedDateString = `${formattedHours}:${formattedMinutes}${ampm} - ${month} ${day}, ${year}`;
      const formattedTimeString = `${date.getHours()}:${formattedMinutes}`;
      setTime(formattedTimeString);
      return formattedDateString;
    };

    const date = new Date();
    const formattedDate = formatDate(date);
    setDate(formattedDate);
  }, []);

  return (
    <div className="twitterMediaView text-center position-relative">
      <ReactSVG src={oneClickBlogIcon.mobilescreen} />
      <div className="mediaLayout">
        <div className="topBar d-flex justify-content-between align-items-center ">
          <div className="signalTime d-flex justify-content-between align-items-center">
            <div className="signal position-relative">
              <span>5g</span>
              <FaSignal />
            </div>
            <div className="timeDate">
              <p className="m-0">{time}</p>
            </div>
          </div>
          <div className="chargeing d-flex justify-content-between align-items-center gap-1">
            <IoIosWifi />
            <IoBatteryFull />
          </div>
        </div>
        <div className="twitterBody">
          <div className="header">
            <div className="profileIcon">
              {userRes?.profile_url ? (
                <img src={userRes?.profile_url} alt="profile_pic" className="w-100" />
              ) : (
                <div className=" bg-white border-1 solid rounded-pill" id="dropdown-basic">
                  <RxAvatar className="svg" />
                </div>
              )}
            </div>
            <div>
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                <g clip-path="url(#clip0_6433_16807)">
                  <path
                    d="M11.8616 8.46864L19.147 0H17.4206L11.0947 7.3532L6.04225 0H0.214844L7.85515 11.1193L0.214844 20H1.94134L8.62162 12.2348L13.9574 20H19.7848L11.8612 8.46864H11.8616ZM9.49695 11.2173L8.72283 10.1101L2.56342 1.29967H5.21521L10.1859 8.40994L10.9601 9.51718L17.4214 18.7594H14.7696L9.49695 11.2177V11.2173Z"
                    fill="black"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_6433_16807">
                    <rect width="20" height="20" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </div>
          </div>
          <div className="navigationBar">
            <div className="d-flex justify-content-center align-items-center firstNavItem navItem">For you</div>
            <div className="d-flex justify-content-center align-items-center navItem">Following</div>
            <div className="d-flex justify-content-center align-items-center navItem">Subscribed</div>
          </div>
          <div className="twitterScroll w-100">
            <div className="d-flex align-items-start tweetCard">
              <div className="d-flex justify-content-between align-items-start w-100 nameCard">
                <div className="d-flex align-items-center profileDetails">
                  <div className="profileIcon">
                    {userRes?.profile_url ? (
                      <img src={userRes?.profile_url} alt="profile_pic" className="w-100" />
                    ) : (
                      <div className=" bg-white border-1 solid rounded-pill" id="dropdown-basic">
                        <RxAvatar className="svg" />
                      </div>
                    )}
                  </div>
                  <div className="userName">
                    <div className="userVerifiedName">
                      {userRes?.username}
                      <span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="11" height="11" viewBox="0 0 11 11" fill="none">
                          <g clip-path="url(#clip0_6435_1150)">
                            <path
                              d="M4.35183 1.31921C4.87787 0.731603 5.79786 0.731603 6.3239 1.31921L6.37444 1.37567C6.64253 1.67514 7.0322 1.83654 7.43353 1.81435L7.50918 1.81017C8.29665 1.76664 8.94718 2.41717 8.90365 3.20463L8.89946 3.28029C8.87728 3.68162 9.03868 4.07128 9.33815 4.33938L9.39461 4.38992C9.98221 4.91596 9.98221 5.83594 9.39461 6.36199L9.33815 6.41253C9.03868 6.68062 8.87728 7.07028 8.89946 7.47161L8.90365 7.54727C8.94718 8.33474 8.29665 8.98527 7.50918 8.94173L7.43353 8.93755C7.0322 8.91536 6.64253 9.07677 6.37444 9.37624L6.3239 9.43269C5.79786 10.0203 4.87787 10.0203 4.35183 9.43269L4.30129 9.37624C4.0332 9.07677 3.64353 8.91536 3.24221 8.93755L3.16655 8.94173C2.37908 8.98527 1.72855 8.33474 1.77209 7.54727L1.77627 7.47161C1.79845 7.07028 1.63705 6.68062 1.33758 6.41253L1.28112 6.36199C0.693517 5.83594 0.693517 4.91596 1.28112 4.38992L1.33758 4.33938C1.63705 4.07128 1.79845 3.68162 1.77627 3.28029L1.77209 3.20463C1.72855 2.41717 2.37908 1.76664 3.16655 1.81017L3.24221 1.81435C3.64353 1.83654 4.0332 1.67514 4.30129 1.37567L4.35183 1.31921Z"
                              fill="#1DA1F2"
                            />
                            <path
                              d="M3.61914 5.37619L4.7654 6.52245L6.77136 3.94336"
                              stroke="white"
                              stroke-width="0.859696"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_6435_1150">
                              <rect
                                width="10.2072"
                                height="10.2072"
                                fill="white"
                                transform="translate(0.234375 0.272461)"
                              />
                            </clipPath>
                          </defs>
                        </svg>
                      </span>
                    </div>
                    <div className="uniqueUserId">@{userRes?.username}</div>
                  </div>
                </div>
                <div>
                  <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
                    <path
                      d="M11.9871 8.07292C12.3187 8.07292 12.5876 7.8041 12.5876 7.47249C12.5876 7.14089 12.3187 6.87207 11.9871 6.87207C11.6555 6.87207 11.3867 7.14089 11.3867 7.47249C11.3867 7.8041 11.6555 8.07292 11.9871 8.07292Z"
                      stroke="#8698A9"
                      stroke-width="1.20085"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M7.78402 8.07292C8.11562 8.07292 8.38444 7.8041 8.38444 7.47249C8.38444 7.14089 8.11562 6.87207 7.78402 6.87207C7.45241 6.87207 7.18359 7.14089 7.18359 7.47249C7.18359 7.8041 7.45241 8.07292 7.78402 8.07292Z"
                      stroke="#8698A9"
                      stroke-width="1.20085"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M3.58089 8.07292C3.9125 8.07292 4.18131 7.8041 4.18131 7.47249C4.18131 7.14089 3.9125 6.87207 3.58089 6.87207C3.24929 6.87207 2.98047 7.14089 2.98047 7.47249C2.98047 7.8041 3.24929 8.07292 3.58089 8.07292Z"
                      stroke="#8698A9"
                      stroke-width="1.20085"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
              </div>
              <div className="mainContent postedText w-100">
                <div className="post-desc d-flex align-items-end">
                  <pre
                    className="m-0"
                    style={isShowFullPost ? { display: "block", paddingRight: "0px" } : {}}
                    dangerouslySetInnerHTML={{ __html: text }}
                  />
                  <span
                    className={classNames("more-btn", { "d-none": !text })}
                    onClick={() => setIsShowFullPost(!isShowFullPost)}
                  >
                    {isShowFullPost ? "show less" : "see more"}
                  </span>
                </div>
                <div className="genrateAiBlock position-relative">
                  {carouselImages && carouselImages.length > 0 ? (
                    <div
                      className={`img-wrapper carousel-wrapper position-relative ${!previewImg ? "bg-dark opacity-75" : ""}`}
                    >
                      <div className="slides" id="scroll-div">
                        {carouselImages.map((image, index) => (
                          <div className="" key={index}>
                            <img
                              src={carouselImages[index]}
                              alt="Post pictures"
                              onLoad={() => setLoaded(true)}
                              onError={() => setLoaded(true)}
                              style={{ maxWidth: "100%" }}
                            />
                          </div>
                        ))}
                      </div>
                      <div className="arrow-button">
                        {carouselIndex !== 0 && (
                          <div
                            className="left-arrow-button"
                            type="button"
                            onClick={() => {
                              setCarouselIndex(carouselIndex - 1);
                              const content = document.querySelector("#scroll-div");
                              content.scrollLeft -= 300;
                            }}
                            onKeyDown={() => { }}
                          >
                            <MdKeyboardArrowLeft className="fs-3" />
                          </div>
                        )}
                        {carouselIndex + 1 !== carouselImages.length && (
                          <div
                            className="right-arrow-button"
                            type="button"
                            onClick={() => {
                              setCarouselIndex(carouselIndex + 1);
                              const content = document.querySelector("#scroll-div");
                              content.scrollLeft += 300;
                            }}
                            onKeyDown={() => { }}
                          >
                            <MdKeyboardArrowRight className="fs-3" />
                          </div>
                        )}
                      </div>
                    </div>
                  ) : previewImg ? (
                    <img
                      src={previewImg}
                      alt="Post pictures"
                      onLoad={() => setLoaded(true)}
                      onError={() => setLoaded(true)}
                      className=" object-fit-cover w-100 h-100 "
                    />
                  ) : null}
                  {!loaded && previewImg && <ImageLoader />}
                  {text ? (
                    isUploadImage && !previewImg ? (
                      <div className="fieldUpload position-relative">
                        <input className="form-control border-0 shadow" type="buttton" id="formFileMultiple" multiple />
                        <button className="blogButton border-0 bg-white" onClick={setUploadFileModal}>
                          <BsStars /> Generate AI Image
                          <div className="dot">
                            <div className="circle"></div>
                          </div>
                        </button>
                      </div>
                    ) : isUploadImage && previewImg ? (
                      <button type="button" onClick={() => setUploadFileModal(true)} className="addlly-primary image-upload-btn" >
                        <BsUpload />
                      </button>
                    ) : null
                  ) : null}
                </div>
              </div>
              <div className="postDateTime">
                {date}-X for {userRes.username}
              </div>
              <div className="engagementCounter">
                <div className="engagementCounterInner d-flex gap-3">
                  <span>0 Retweets</span>
                  <span>0 Quote Tweets</span>
                  <span>0 Likes</span>
                </div>
                <hr className="w-100 m-0" style={{ borderWidth: "0.24px", borderColor: "#C9D7E4" }}></hr>
                <div className="userEngagement">
                  <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
                    <path
                      d="M6.41164 9.43038H6.74937V9.09264H6.41164V9.43038ZM6.41164 11.1049H6.0739V11.6955L6.58288 11.3961L6.41164 11.1049ZM9.66055 9.19382L9.52729 8.88349L9.50769 8.8919L9.48931 8.90272L9.66055 9.19382ZM3.81821 6.49937C3.81821 5.06715 4.97925 3.9061 6.41148 3.9061V3.23062C4.6062 3.23062 3.14273 4.69409 3.14273 6.49937H3.81821ZM6.41148 9.09264C4.97926 9.09264 3.81821 7.93159 3.81821 6.49937H3.14273C3.14273 8.30465 4.6062 9.76812 6.41148 9.76812V9.09264ZM6.41164 9.09264H6.41148V9.76812H6.41164V9.09264ZM6.74937 11.1049V9.43038H6.0739V11.1049H6.74937ZM9.48931 8.90272L6.2404 10.8138L6.58288 11.3961L9.83179 9.48493L9.48931 8.90272ZM11.0983 6.49937C11.0983 7.56803 10.4519 8.48647 9.52729 8.88349L9.79381 9.50416C10.9576 9.0044 11.7738 7.84758 11.7738 6.49937H11.0983ZM8.50506 3.9061C9.93728 3.9061 11.0983 5.06715 11.0983 6.49937H11.7738C11.7738 4.69409 10.3103 3.23062 8.50506 3.23062V3.9061ZM6.41148 3.9061H8.50506V3.23062H6.41148V3.9061Z"
                      fill="#8698A9"
                    />
                  </svg>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 16 15" fill="none">
                    <path
                      d="M3.6084 5.3733L4.86875 3.88379M4.86875 3.88379L6.12911 5.3733M4.86875 3.88379V8.98738C4.86875 9.73349 5.4736 10.3383 6.2197 10.3383H9.06994"
                      stroke="#8698A9"
                      stroke-width="0.675476"
                      stroke-linecap="round"
                    />
                    <path
                      d="M12.5459 8.84838L11.2855 10.3379M11.2855 10.3379L10.0252 8.84838M11.2855 10.3379V5.2343C11.2855 4.48819 10.6807 3.88335 9.93459 3.88335H7.08436"
                      stroke="#8698A9"
                      stroke-width="0.675476"
                      stroke-linecap="round"
                    />
                  </svg>
                  <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
                    <g clip-path="url(#clip0_6433_16961)">
                      <path
                        d="M5.50663 8.5304C5.18561 8.77253 4.74299 8.77253 4.42197 8.5304L3.36362 7.73215C1.53173 5.89849 1.32031 5.27827 1.32031 3.79799C1.32031 2.58471 2.26804 1.73438 3.36362 1.73438C3.98255 1.73438 4.57658 2.05242 4.9643 2.55108C5.35202 2.05242 5.94605 1.73438 6.56498 1.73438C7.66056 1.73438 8.52539 2.58471 8.52539 3.79799C8.52539 5.27827 8.39687 5.89849 6.56498 7.73215L5.50663 8.5304Z"
                        stroke="#8698A9"
                        stroke-width="0.675476"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_6433_16961">
                        <rect width="9.00634" height="9.00634" fill="white" transform="translate(0.418945 0.833984)" />
                      </clipPath>
                    </defs>
                  </svg>
                  <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
                    <path
                      d="M3.71484 7.84668V10.0082C3.71484 10.5056 4.11807 10.9088 4.61548 10.9088H10.4696C10.967 10.9088 11.3702 10.5056 11.3702 10.0082V7.84668"
                      stroke="#8698A9"
                      stroke-width="0.675476"
                      stroke-linecap="round"
                    />
                    <path
                      d="M5.24609 5.80511L7.54271 3.76367M7.54271 3.76367L9.83933 5.80511M7.54271 3.76367V8.35691"
                      stroke="#8698A9"
                      stroke-width="0.675476"
                      stroke-linecap="round"
                    />
                  </svg>
                </div>
                <hr className="w-100 m-0" style={{ borderWidth: "0.24px", borderColor: "#C9D7E4" }}></hr>
              </div>
            </div>
            <hr className="w-100 m-0" style={{ borderWidth: "2px" }}></hr>
            <div className="d-flex align-items-start tweetCard">
              <div className="d-flex justify-content-between align-items-start w-100 nameCard">
                <div className="d-flex align-items-center profileDetails">
                  <div className="profileIcon">
                    {userRes?.profile_url ? (
                      <img src={userRes?.profile_url} alt="profile_pic" className="w-100" />
                    ) : (
                      <div className=" bg-white border-1 solid rounded-pill" id="dropdown-basic">
                        <RxAvatar className="svg" />
                      </div>
                    )}
                  </div>
                  <div className="userName">
                    <div className="userVerifiedName">
                      {userRes?.username}
                      <span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="11" height="11" viewBox="0 0 11 11" fill="none">
                          <g clip-path="url(#clip0_6435_1150)">
                            <path
                              d="M4.35183 1.31921C4.87787 0.731603 5.79786 0.731603 6.3239 1.31921L6.37444 1.37567C6.64253 1.67514 7.0322 1.83654 7.43353 1.81435L7.50918 1.81017C8.29665 1.76664 8.94718 2.41717 8.90365 3.20463L8.89946 3.28029C8.87728 3.68162 9.03868 4.07128 9.33815 4.33938L9.39461 4.38992C9.98221 4.91596 9.98221 5.83594 9.39461 6.36199L9.33815 6.41253C9.03868 6.68062 8.87728 7.07028 8.89946 7.47161L8.90365 7.54727C8.94718 8.33474 8.29665 8.98527 7.50918 8.94173L7.43353 8.93755C7.0322 8.91536 6.64253 9.07677 6.37444 9.37624L6.3239 9.43269C5.79786 10.0203 4.87787 10.0203 4.35183 9.43269L4.30129 9.37624C4.0332 9.07677 3.64353 8.91536 3.24221 8.93755L3.16655 8.94173C2.37908 8.98527 1.72855 8.33474 1.77209 7.54727L1.77627 7.47161C1.79845 7.07028 1.63705 6.68062 1.33758 6.41253L1.28112 6.36199C0.693517 5.83594 0.693517 4.91596 1.28112 4.38992L1.33758 4.33938C1.63705 4.07128 1.79845 3.68162 1.77627 3.28029L1.77209 3.20463C1.72855 2.41717 2.37908 1.76664 3.16655 1.81017L3.24221 1.81435C3.64353 1.83654 4.0332 1.67514 4.30129 1.37567L4.35183 1.31921Z"
                              fill="#1DA1F2"
                            />
                            <path
                              d="M3.61914 5.37619L4.7654 6.52245L6.77136 3.94336"
                              stroke="white"
                              stroke-width="0.859696"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_6435_1150">
                              <rect
                                width="10.2072"
                                height="10.2072"
                                fill="white"
                                transform="translate(0.234375 0.272461)"
                              />
                            </clipPath>
                          </defs>
                        </svg>
                      </span>
                    </div>
                    <div className="uniqueUserId">@{userRes?.username}</div>
                  </div>
                </div>
                <div>
                  <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
                    <path
                      d="M11.9871 8.07292C12.3187 8.07292 12.5876 7.8041 12.5876 7.47249C12.5876 7.14089 12.3187 6.87207 11.9871 6.87207C11.6555 6.87207 11.3867 7.14089 11.3867 7.47249C11.3867 7.8041 11.6555 8.07292 11.9871 8.07292Z"
                      stroke="#8698A9"
                      stroke-width="1.20085"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M7.78402 8.07292C8.11562 8.07292 8.38444 7.8041 8.38444 7.47249C8.38444 7.14089 8.11562 6.87207 7.78402 6.87207C7.45241 6.87207 7.18359 7.14089 7.18359 7.47249C7.18359 7.8041 7.45241 8.07292 7.78402 8.07292Z"
                      stroke="#8698A9"
                      stroke-width="1.20085"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M3.58089 8.07292C3.9125 8.07292 4.18131 7.8041 4.18131 7.47249C4.18131 7.14089 3.9125 6.87207 3.58089 6.87207C3.24929 6.87207 2.98047 7.14089 2.98047 7.47249C2.98047 7.8041 3.24929 8.07292 3.58089 8.07292Z"
                      stroke="#8698A9"
                      stroke-width="1.20085"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
              </div>
              <div style={{ height: "100px", width: "100%", backgroundColor: "#EEE" }}></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TwitterPostPreview;
