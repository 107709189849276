import { AxiosApi } from './axios';

const handleError = (error) => {
	if (error.response) {
		if (error.response.status === 400) {
			return error.response.data.error || "The request was invalid. Please check your input.";
		}
		return error.response.data.error || "An error occurred while processing your request.";
	} else if (error.request) {
		return "No response received from the server. Please check your network connection.";
	} else {
		return `Error: ${error.message}`;
	}
};

export const invokeAxiosGet = async (url) => {
	try {
		const response = await AxiosApi.get(url);
		if (response && response.status === 200) {
			return { success: 1, data: response.data };
		} else {
			return { success: 0, error: "Something went wrong!" };
		}
	} catch (error) {
		console.error('Error during GET request:', error);
		return { success: 0, error: handleError(error) };
	}
};

export const invokeAxiosPost = async (url, data) => {
	try {
		const response = await AxiosApi.post(url, data);
		if (response && response.status === 200) {
			return { success: 1, data: response.data };
		} else {
			return { success: 0, error: "Something went wrong!" };
		}
	} catch (error) {
		console.error('Error during POST request:', error);
		return { success: 0, error: handleError(error) };
	}
};
