const endPointes = {
    pinnedFeature: `/features/get-or-add/pinnedFeatures`,
    allFeatures: `/features/get-or-add/features`,
    addToPinnedList: '/features/get-or-add/addPinnedFeatures',
    removeFromPinned: '/features/get-or-add/deletePinnedFeatures',
    articleHistory: '/article-history/get-or-save/articleHistoryGetByUserId',
    archive: '/article-history/get-or-save/archiveArticleHistory',
    restoreArticle: '/article-history/get-or-save/restoreArticleHistory',
    deleteArticleHistory: '/article-history/get-or-save/deleteArticleHistory',
    ArticleHistory: '/article-history/get-or-save/deleteArticleHistory',
    articleHistoryShortFlow: '/api/articleHistory/list-by-user/',
    articleHistoryById: '/article-history/get-or-save/articleHistoryGetById',
    pinArticle: '/article-history/get-or-save/pinArticleHistory'
};

export { endPointes };
