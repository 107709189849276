import React, { useState } from 'react'
import { ReactSVG } from 'react-svg'
import { authenticateIcon } from '../Assets/Icons'
import { Col, Row } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom'
import classNames from 'classnames'
import { BsArrowRight } from 'react-icons/bs'
import ReCAPTCHA from 'react-google-recaptcha'
import toast from 'react-hot-toast'
import { setLoader } from '../store/reducer'
import axios from 'axios'
import { useDispatch } from 'react-redux'
import { invokeAxiosPost } from '../utility/invokeAxiosFunction'

const ForgotPassword = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [otp, setOtp] = useState('');
    const [otpRequested, setOtpRequested] = useState(false);
    const [reCaptcha, setReCaptcha] = useState(null);
    const [error, setError] = useState({ isError: false, message: '' });
    const handleEmailChange = (event) => setEmail(event.target.value);
    const handleOtpChange = (event) => setOtp(event.target.value);

    const handleRequestOtp = () => {
        if (!reCaptcha) {
            return false;
        }
        setError({ isError: false, message: '' });

        if (email === '') {
            // toast.error('Please enter a valid Email Address', { id: "Toast-01" });
            setError(pre => ({ ...pre, isError: true, message: 'Please enter a valid Email Address' }));
        } else {
            dispatch(setLoader(true));
            invokeAxiosPost('/user-api/get-or-create/forgotPassword', {
                email: email,
            }).then((res) => {
                dispatch(setLoader(false));
                if (res.data.data.success === 1) {
                    toast.success("OTP has been Sent to " + email, { id: "Toast-01" });
                    setOtpRequested(true);
                    setError({ isError: false, message: '' });
                } else {
                    // toast.error(res.data.data.message, { id: "Toast-01" });
                    setError(pre => ({ ...pre, isError: true, message: res.data.data.message }));
                }

            }).catch((error) => {
                dispatch(setLoader(false));
                // toast.error(error?.response?.data?.error || "Email Address Not Found", { id: "Toast-01" });
                setError(pre => ({ ...pre, isError: true, message: error?.response?.data?.error || "Email Address Not Found" }));
            });
        }
    };

    const handleCheckOtp = () => {
        setError({ isError: false, message: '' });

        if (otp === '') {
            // toast.error('Please enter a valid OTP', { id: "Toast-01" });
            setError(pre => ({ ...pre, isError: true, message: 'Please enter a valid OTP' }));
        } else {
            dispatch(setLoader(true));
            axios.post(`${process.env.REACT_APP_API_URL}/user/forgotpassword`, { email, otp }).then((res) => {
                dispatch(setLoader(false));
                if (res.data.success === 1) {
                    toast.success('OTP Verification Successful', { id: "Toast-01" });
                    navigate(`/reset-password?email=${encodeURIComponent(email)}`, { state: { otp, email } });
                } else {
                    // toast.error('Incorrect OTP, Please try again', { id: "Toast-01" });
                    setError(pre => ({ ...pre, isError: true, message: 'Incorrect OTP, Please try again' }));
                }
            }).catch((error) => {
                dispatch(setLoader(false));
                // toast.error(error?.response?.data?.error || "Email Address Not Found", { id: "Toast-01" });
                setError(pre => ({ ...pre, isError: true, message: error?.response?.data?.error || "Email Address Not Found" }));
            });
        }
    };


    return (
        <div className='Reset-password maingenrateBlock'>
            <div className="logos">
                <ReactSVG src={authenticateIcon.AddllyBlueLogo} alt="addlly logo" />
            </div>

            <div className='d-flex align-items-center justify-content-center password-section flex-column'>
                {!otpRequested &&
                    <React.Fragment>
                        <h5>Forgot Password?</h5>
                        <form className=''>
                            <div className="genrateFields">
                                <Row className="m-0">
                                    <Col xl={12} className=" p-0 ">
                                        <div className="fields"  >
                                            <label className=" color-light-text p-0">Email  <sup>*</sup></label>
                                            <div className=" inputField">
                                                <input
                                                    name="email"
                                                    className={`w-100 ${error.isError ? "is-invalid" : ""}`}
                                                    placeholder="Enter Email"
                                                    type="email"
                                                    value={email} onChange={handleEmailChange}
                                                />
                                                {error.isError ? <Row>
                                                    <div className="invalid">{error.message}</div>
                                                </Row> : null}
                                                <div className="my-3">
                                                    <ReCAPTCHA
                                                        sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY}
                                                        onChange={(e) => setReCaptcha(e)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>

                            </div>
                            <div className="authenticate-button  d-flex align-items-center">
                                <button type='button' disabled={!reCaptcha || !email}
                                    onClick={() => reCaptcha ? handleRequestOtp() : null} className={classNames("w-100 justify-content-center")}>
                                    Send reset password link <BsArrowRight />
                                </button>
                            </div>
                            <span>Remember password? <Link to={"/"}>Login</Link></span>
                        </form>
                    </React.Fragment>
                }

                {otpRequested && (
                    <React.Fragment>
                        <div className="genrateFields">
                            <Row className="m-0">
                                <Col xl={12} className=" p-0 ">
                                    <div className="fields">
                                        <label className="color-light-text p-0">Please enter OTP sent to <b >{email}</b></label>
                                        <div className="inputField">
                                            <input
                                                type="text" className={classNames("addllyForm-control mb-2 w-100", { 'is-invalid': error.isError })} placeholder="OTP"
                                                value={otp} onChange={handleOtpChange}
                                            />
                                            {error.isError ? <Row>
                                                <div className="invalid mb-2">{error.message}</div>
                                            </Row> : null}
                                            <div className="authenticate-button  d-flex align-items-center">
                                                <button type='button'
                                                    disabled={!otp}
                                                    onClick={handleCheckOtp} className={classNames("w-100 justify-content-center")}>
                                                    Submit OTP
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </React.Fragment>
                )}
            </div>
        </div>
    )
}

export default ForgotPassword

