import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import Heading from "../Components/ui/Title";
import NewslettersHeader from "../Components/NewslettersComponents/NewslettersHeader";
import NewslettersHistory from "../Components/NewslettersComponents/NewslettersHistory";
import FirstStepNewsletters from "../Components/NewslettersComponents/FirstStepNewsletters";
import SecondStepNewsletters from "../Components/NewslettersComponents/SecondStepNewsletters";
import ThirdStepNewsletters from "../Components/NewslettersComponents/ThirdStepNewsletters";
import { setLoader, setNewsletterWriterInfo, setNewsletterWriterStep } from "../store/reducer";
import toast from "react-hot-toast";
import { invokeAxiosGet } from "../utility/invokeAxiosFunction";

const Newsletters = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const newsletterWriterStep = useSelector(({ newsletterWriterStep }) => newsletterWriterStep);
  const { id } = useParams();

  const [searchParams] = useSearchParams();
  const isFullScreen = searchParams.get("full-screen");

  useEffect(() => {
    if (id && id !== "") {
      handleGetDetailsById(id, false);
    } else {
      dispatch(setNewsletterWriterInfo({}));
      dispatch(setNewsletterWriterStep(1));
      navigate("/newsletters");
    }
  }, [id]);

  const handleGetDetailsById = async (id, isSetStep = false) => {
    try {
      dispatch(setLoader(true));
      const res = await invokeAxiosGet(`/news-letters/get-or-generate/getNewsletterDetailByNewsId?id=${id}`);
      if (res && res.data && res.data.resText) {
        dispatch(setNewsletterWriterStep(3));
        dispatch(setNewsletterWriterInfo({ ...res.data, stepDone: 3 }));
        navigate(`/newsletters/${res.data?.id}`);
        const scrollWrapper = document.getElementById('scroll-wrapper');
        if (scrollWrapper) { scrollWrapper.scrollTo({ top: 0, behavior: 'smooth' }); }
      } else {
        toast.error("Newsletter content not generated.", { id: "Toast-01" });
      }
      dispatch(setLoader(false));
    } catch (error) {
      toast.error("Something went wrong, Please try again later.", { id: "Toast-01" });
      dispatch(setLoader(false));
    }
  }

  const steps = useMemo(() => [
    <FirstStepNewsletters />,
    <SecondStepNewsletters />,
    <ThirdStepNewsletters />,
  ], []);

  return (
    <React.Fragment>
      {!isFullScreen && (
        <div className="oneClickHead d-flex flex-column pt-4">
          <div className="">
            <Heading className="fw-bolder mainHeading" size="3">Newsletter Builder</Heading>
            <p>Create high-converting newsletters with easy templates and live links to your website.</p>
          </div>
        </div>
      )}
      <div className={`stepWraper ${isFullScreen ? "mt-0" : "mt-4"}`}>
        <div className="border-radius-12 bg-white AIWriterBlog AISocialMediaWriter SocialMediaWriter maingenrateBlock p-0 m-0">
          <NewslettersHeader />
          {steps[newsletterWriterStep - 1]}
        </div>
      </div>

      {(newsletterWriterStep === 1 && !id) && (
        <div className=" d-flex gap-2 flex-column tableHeading mt-5">
          <div className="mt-2">
            <NewslettersHistory handleGetDetailsById={handleGetDetailsById} />
          </div>
        </div>
      )}
    </React.Fragment>
  )
}

export default Newsletters;
