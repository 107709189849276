import React, { useEffect, useState } from "react";
import Select from "react-select";
import Swal from "sweetalert";
import { useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import { Modal } from "react-bootstrap";
import { MdClose } from "react-icons/md";

const SentReviewFormModal = (props) => {
  const navigate = useNavigate();
  const userRes = useSelector(({ userRes }) => userRes);
  const { articleType, isReviewArticle, handleSendArticleToReviewer, sendToEditorForm, setSendToEditorForm } = props;

  const [deductCredits, setDeductCredits] = useState(0);
  const { register, formState: { errors }, getValues, setValue, watch, control, setError, clearErrors, reset } = useForm({});

  /* const editorsToLookAtOptions = [
    { label: "1 Click Blog (2 Addlly credits)", value: "1 Click Blog", isDisabled: articleType !== "1-Click Blog" ? true : false },
    { label: "Blog Co-Pilot (3 Addlly credits)", value: "Blog Co-Pilot", isDisabled: articleType !== "Blog Co-Pilot" ? true : false },
    { label: "Social Media Post (1 Addlly credit)", value: "Social Media Post", isDisabled: articleType !== "Social Media Post Writer" ? true : false },
    // { label: "Full Editorial Review (2 credits per review)", value: "Full Editorial Review" },
  ]; */

  const likeUsToLookAtOptions = [
    { label: "General proofreading", value: "General proofreading" },
    { label: "Brand tonality", value: "Brand tonality" },
    { label: "Change/Add Images", value: "Change/Add Images" },
    { label: `Improve ${articleType === "Social Media Post Writer" ? "Hashtags" : "SEO"}`, value: `Improve ${articleType === "Social Media Post Writer" ? "Hashtags" : "SEO"}` },
    { label: "Special requests", value: "Special requests" },
  ];

  useEffect(() => {
    if (!isReviewArticle || isReviewArticle === 0) {
      let tempCredit = 0;
      if (articleType === "1-Click Blog") {
        tempCredit += (userRes?.deductCredits?.["Review System - 1-Click Blog"] || 4);
        setValue("editorsToLookAt", "1 Click Blog");
      } else if (articleType === "Blog Co-Pilot") {
        tempCredit += (userRes?.deductCredits?.["Review System - Blog Co-Pilot"] || 8);
        setValue("editorsToLookAt", "Blog Co-Pilot");
      } else if (articleType === "Social Media Post Writer") {
        tempCredit += (userRes?.deductCredits?.["Review System - Social Media Post"] || 4);
        setValue("editorsToLookAt", "Social Media Post");
      } else if (articleType === "NewsLetters") {
        tempCredit += (userRes?.deductCredits?.["Review System - NewsLetters"] || 8);
        setValue("editorsToLookAt", "NewsLetters");
      } else if (articleType === "Press Release") {
        tempCredit += (userRes?.deductCredits?.["Review System - Press Release"] || 8);
        setValue("editorsToLookAt", "Press Release");
      }

      if (tempCredit < 0) {
        tempCredit = 0;
      }
      setDeductCredits(tempCredit);
    }
  }, [])

  const handleCheckFormValidation = () => {
    const data = getValues();
    let isValidated = true;
    const validationObj = {
      editorsToLookAt: "What would you like our editors to look at is required",
      likeUsToLookAt: "What would you like us to look a is required",
    };

    Object.keys(validationObj).map((key) => {
      if (data[key] && data[key] !== "" && data[key].length > 0) {
        clearErrors(key);
      } else {
        isValidated = false;
        setError(key, { type: "manual", message: validationObj[key] });
      }

      return key;
    });

    return isValidated;
  };

  const onSubmitReviewForm = async () => {
    const isValidated = await handleCheckFormValidation();
    if (!isValidated) {
      return false;
    }

    if (Number(userRes.credits) === 0) {
      Swal({
        title: "Oops...",
        text: "You have used all your addlly credits.",
        icon: "error", dangerMode: true,
        buttons: { cancel: "cancel", confirm: "Buy more credits" },
      }).then((isConfirmed) => {
        if (isConfirmed) {
          navigate("/buy-more-credits");
        }
      });
      return false;
    }

    if (Number(userRes.credits) < deductCredits) {
      toast.error("You don't have enough credits to perform this action.", { id: "Toast-01" });
      return false;
    }

    const data = getValues();
    data.deductCredits = deductCredits;
    data.likeUsToLookAt = data.likeUsToLookAt.map(item => item.value).join(", ");
    data.isSentToReviewer = true;

    const results = await handleSendArticleToReviewer(data);
    if (results.success === 1) {
      setSendToEditorForm(false);
      reset({});
      clearErrors();
    }
  };

  return (
    <React.Fragment>
      {sendToEditorForm && sendToEditorForm === true && (
        <Modal className="note-modal" centered show={sendToEditorForm} onHide={() => { setSendToEditorForm(false); reset({}); clearErrors(); }}>
          <Modal.Body className="position-relative p-4">
            <div className="d-flex align-items-center justify-content-between">
              <h3 className="fw-normal">Request for Editing</h3>
              <MdClose className="fs-3 text-primary cursor-pointer" onClick={() => { setSendToEditorForm(false); reset({}); clearErrors(); }} />
            </div>
            <hr />
            <div className="addllyFormWrap">
              <div className="fields form-group mb-4">
                <label>What would you like us to look at?</label>
                <Controller
                  control={control} name="likeUsToLookAt" {...register("likeUsToLookAt")}
                  render={({ field: { onChange, value, name, ref } }) => (
                    <Select
                      className={`custom-select ${errors.likeUsToLookAt ? "is-invalid" : ""}`} isSearchable={false}
                      classNamePrefix="custom-select" placeholder="Select like us to look at"
                      options={likeUsToLookAtOptions} isMulti isClearable={true} inputRef={ref}
                      value={value ? likeUsToLookAtOptions.find(c => c.value === value.value) : []}
                      onChange={(e) => { onChange(e); setValue("specifyLikeUsToLookAt", ""); handleCheckFormValidation(); }}
                    />
                  )}
                />
                <div className="invalid-feedback">{errors.likeUsToLookAt?.message}</div>
              </div>
              {watch("likeUsToLookAt") && watch("likeUsToLookAt").length > 0 && (
                <div className="form-group mb-4">
                  <label>Please specify what would you like us to look at?</label>
                  <textarea
                    name="specifyLikeUsToLookAt" type="text" {...register("specifyLikeUsToLookAt")}
                    rows="4" placeholder="Please specify like us to look at"
                    className={`addllyForm-control h-auto rounded-3 ${errors.topic ? "is-invalid" : ""}`}
                  />
                </div>
              )}
              <div className="text-end">
                <button type="button" className="addlly-primary ms-auto" onClick={onSubmitReviewForm}
                  disabled={deductCredits === 0 || !watch("likeUsToLookAt") || !watch("likeUsToLookAt") ? true : false}
                >
                  <span>Send for Review</span>
                </button>
                {deductCredits > 0 && !userRes?.organization_id && (
                  <p className="m-2 mb-0 text-primary">
                    Request for Editing ( Will utilize {deductCredits} Addlly Credit )
                  </p>
                )}
              </div>
            </div>
          </Modal.Body>
        </Modal>
      )}
    </React.Fragment>
  )
}

export default SentReviewFormModal;
