import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import SkeletonLoader from "./SkeletonLoader";

const TableLoader = ({ limit }) => {
  const userRes = useSelector(({ userRes }) => userRes);
  const [isAdminRole, setIsAdminRole] = useState(false);

  useEffect(() => {
    if (userRes && userRes.role && userRes.role === "OrgAdmin") {
      setIsAdminRole(true);
    }
  }, [])

  return (
    <div className="d-flex flex-column w-100">
      {Array.from({ length: limit }).map((_, index) => (
        <div key={index + ' ' + index} className="d-flex w-100 skeltonLoaderBlock align-items-center">
          <div className="checkBoxSkelton">
            <input type="checkbox" disabled name="" id="" className="form-check-input" />
          </div>
          <div className="tableSkelton">
            <div className="skelt">
              <SkeletonLoader />
              <SkeletonLoader />
            </div>
          </div>
          {isAdminRole && (
            <div className="tableSkelton user-info">
              <div className="skelt d-flex align-items-center gap-2">
                <SkeletonLoader />
                <SkeletonLoader />
              </div>
            </div>
          )}
          <div className="tableSkelton">
            <div className="skelt">
              <SkeletonLoader />
            </div>
          </div>
          <div className="tableSkelton">
            <div className="skelt">
              <SkeletonLoader />
            </div>
          </div>
          <div className="tableSkelton ">
            <div className="skelt">
              <SkeletonLoader />
            </div>
          </div>
          <div className="tableSkelton status">
            <div className="skelt d-flex gap-2">
              <SkeletonLoader />
              <SkeletonLoader />
            </div>
          </div>
          <div className="tableSkelton ">
            <div className="threeDots">
              <SkeletonLoader />
              <SkeletonLoader />
              <SkeletonLoader />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default TableLoader;
