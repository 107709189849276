import { memo } from "react";
import { cardsDetails } from "../../data";
import Heading from "../ui/Title";
import FeatureCard from "./FeatureCard";


const PinnedFeatures = (props) => {

  const { handleNavigate, filteredPinnedFeatures, handleRemovePinnedFeature, handlePinnedFeature, isLoadingCards } = props

  return filteredPinnedFeatures?.length ? (
    <div className="writer-option-box text-center dashboardInfoBlock mb-5">
      <Heading size={5} className={"fw-bold"}>Pinned Features</Heading>
      <div className="cardsBlock d-flex flex-wrap pinnedCardBlock">
        {filteredPinnedFeatures.map(({ title, is_new, description, id, to, type, value }, index) => (
          <FeatureCard
            id={id}
            key={title + " " + index}
            icon={cardsDetails.filter((card) => card.title === title)[0]?.icon}
            title={title}
            showNew={is_new}
            to={to}
            callback={handleNavigate}
            value={value}
            type={type}
            description={description}
            pinned={true}
            handleRemovePinnedFeature={handleRemovePinnedFeature}
            handlePinnedFeature={handlePinnedFeature}
            isLoading={isLoadingCards}
          />
        ))}
      </div>
    </div>
  ) : null;
};

export default memo(PinnedFeatures);
