import React from "react";
import ReactDOM from "react-dom/client";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";
import { Provider } from "react-redux";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';

import App from "./App";
import { store } from "./store";
import reportWebVitals from "./reportWebVitals";

import "bootstrap/dist/css/bootstrap.min.css";
import "./Assets/scss/style.scss";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

let persistor = persistStore(store);
const queryClient = new QueryClient();

const msalInstance = new PublicClientApplication({
  auth: {
    clientId: process.env.REACT_APP_AZURE_CLIENT_ID,
    authority: "https://login.microsoftonline.com/612cab25-c693-4bf4-b37f-854ca2981dd5",
    redirectUri: "https://azure-staging.addlly.ai/"
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: false,
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
      }
    }
  }
});

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <QueryClientProvider client={queryClient}>
          <MsalProvider instance={msalInstance}>
            <App />
          </MsalProvider>
        </QueryClientProvider>
      </PersistGate>
    </Provider>
  </GoogleOAuthProvider>
);

reportWebVitals();
