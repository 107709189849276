import React, { useState } from "react";
import { toast } from "react-hot-toast";
import { Button, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { BsArrowRight, BsEyeFill } from "react-icons/bs";
import { GrClose } from "react-icons/gr";
import { FaArrowRightLong, FaCaretLeft, FaCaretRight } from "react-icons/fa6";
import { setNewsletterWriterHistory, setNewsletterWriterInfo, setNewsletterWriterStep } from "../../store/reducer";

const newsletterTemplates = [
  {
    id: 1,
    name: "template_1",
    tag: "New",
    image: "/NewsLetterTemplates/template-1.jpg"
  },
  {
    id: 2,
    name: "template_2",
    tag: "Trending",
    image: "/NewsLetterTemplates/template-2.jpg"
  },
  {
    id: 3,
    name: "template_3",
    image: "/NewsLetterTemplates/template-3.jpg"
  },
  {
    id: 4,
    name: "template_4",
    image: "/NewsLetterTemplates/template-4.jpg"
  },
  {
    id: 5,
    name: "template_5",
    tag: "New",
    image: "/NewsLetterTemplates/template-5.jpg"
  },
];

const FirstStepNewsletters = () => {
  const dispatch = useDispatch();
  const userRes = useSelector(({ userRes }) => userRes);
  const newsletterWriterInfo = useSelector(({ newsletterWriterInfo }) => newsletterWriterInfo);
  const [selectedTemplate, setSelectedTemplate] = useState(newsletterWriterInfo?.template || "");
  const [viewImgModal, setViewImgModal] = useState({ open: false, img: "", index: 0 });

  const handleNextStep = (templateName) => {
    if (Number(userRes.credits) < (userRes?.deductCredits?.["NewsLetters"] || 3)) {
      toast.error("Sorry, you don't have enough credits to perform this action.", { id: "Toast-01" });
      return false;
    }

    if (templateName && templateName !== "") {
      dispatch(setNewsletterWriterHistory({}));
      setViewImgModal({ open: false, img: "", index: 0 });
      dispatch(setNewsletterWriterInfo({ template: templateName, stepDone: newsletterWriterInfo?.stepDone > 2 ? newsletterWriterInfo.stepDone : 2 }));
      dispatch(setNewsletterWriterStep(2));
    } else {
      toast.error("Please any template for generate article.", { id: "Toast-01" });
    }
  };

  const handleSelectPreviewTemplate = (index) => {
    const tempObj = newsletterTemplates[index];
    if (tempObj.name && tempObj.name !== "") {
      setSelectedTemplate(tempObj.name);
      handleNextStep(tempObj.name);
    }
  };

  const handleOnChangeViewImage = (index) => {
    if (newsletterTemplates && newsletterTemplates.length > 0) {
      if (index !== -1 && newsletterTemplates.length >= index) {
        if (newsletterTemplates[index] && newsletterTemplates[index] !== "" && newsletterTemplates[index].image) {
          setViewImgModal((prev) => ({ ...prev, open: true, img: newsletterTemplates[index].image, index }));
        } else {
          setViewImgModal((prev) => ({ ...prev, open: true, img: newsletterTemplates[index], index }));
        }
      }
    }
  };

  return (
    <div className="addllyFormWrap">
      <div className="selectBlock d-flex flex-wrap align-items-center justify-content-between mx-2 mx-md-auto">
        <div>
          <b>Select Template</b> <span>(Select any 1)</span>
        </div>
        <button
          type="button" className="d-flex align-items-center gap-2 bg-transparent border-0 selectBbutton"
          onClick={() => handleNextStep(selectedTemplate)} disabled={!selectedTemplate || selectedTemplate === "" || newsletterWriterInfo.stepDone > 2}
        >
          Describe Topic <BsArrowRight />
        </button>
      </div>
      <div className={`news-letters-templates ${newsletterWriterInfo?.stepDone > 2 ? "not-selectable" : ""}`}>
        <div className="template-wrapper">
          {newsletterTemplates && newsletterTemplates.length > 0 && newsletterTemplates.map((template, index) => (
            <div sm={12} md={4} lg={3} onClick={() => setSelectedTemplate(template.name)} className={`image-main-wrapper ${selectedTemplate === template.name ? "active" : ""}`} key={index}>
              <div className="template-header">
                <div className={`radioBtn`} >
                  <div className={`radioBtnIn`}></div>
                </div>
                {template.tag && template.tag !== "" && (
                  <div className="template-tags">{template.tag}</div>
                )}
              </div>
              <div className="image-content">
                <img src={template.image} alt={template.name} />
                <div className="img-btn-wrapper">
                  <div className="btn-sub-div">
                    <button
                      type="button" className="btn bg-white text-primary"
                      onClick={(e) => setViewImgModal({ open: true, img: template.image, index: index })}
                      data-tooltip-id={"my-tooltip"} data-tooltip-place="bottom" data-tooltip-content={`View Template`}
                    >
                      <BsEyeFill className='fs-5' />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="buttonDiv ">
        <Button
          className="button" onClick={() => handleNextStep(selectedTemplate)}
          disabled={!selectedTemplate || selectedTemplate === "" || newsletterWriterInfo.stepDone > 2}
        >
          Describe Topic <FaArrowRightLong className="ms-2" />
        </Button>
      </div>

      {viewImgModal && viewImgModal.open && (
        <Modal centered fullscreen className="image-history-modal" backdrop="static"
          show={viewImgModal.open} onHide={() => setViewImgModal({ open: false, img: "" })}
        >
          <Modal.Body className="">
            <div className="image-content">
              <button className="btn arrow-btn"
                onClick={() => handleOnChangeViewImage(viewImgModal.index - 1)}
                disabled={viewImgModal.index !== 0 ? false : true}
              >
                <FaCaretLeft className="" />
              </button>
              <div className="image-view-wrapper">
                <img src={viewImgModal.img} alt="" style={{ maxHeight: "75vh" }} />
              </div>
              <button className="btn arrow-btn" onClick={() => handleOnChangeViewImage(viewImgModal.index + 1)}
                disabled={viewImgModal.index !== (newsletterTemplates.length - 1) ? false : true}
              >
                <FaCaretRight className="" />
              </button>
              <button className="addlly-primary template-btn" onClick={() => handleSelectPreviewTemplate(viewImgModal.index)}>
                Use this template <BsArrowRight className="" />
              </button>
              <label className="img-label"><span className="activeSliderCount">0{viewImgModal.index + 1}</span> / 0{newsletterTemplates.length}</label>
            </div>
            <button className="btn close-btn" onClick={() => setViewImgModal({ open: false, img: "", index: 0 })}>
              <GrClose className="fs-4" />
            </button>
          </Modal.Body>
        </Modal>
      )}
    </div>
  )
}

export default FirstStepNewsletters;
